.help-error {
    user-select: none;
    pointer-events: none;
    min-height: 18px;
    display: block;
    font-size: 12px;
    animation: fade-in 0.3s ease-out 1;
    will-change: opacity;
    color: #fd847b !important;
}
.custom-input-field {
    position: inherit;
    margin: 0em 0em 0em 1.5em;
    .input-field{
        margin: 0!important;
    }
    .prefix{
        max-width: 1rem!important;
        font-size: 1rem!important;
        top: 1.2rem!important;
    }
    label{
        margin-left:2em!important;
    }
}
.input-field {
    position: relative;
    margin: 1rem 0 1.2rem;

    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='url'],
    input[type='time'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='tel'],
    input[type='number'],
    input[type='search'],
    textarea {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #9e9e9e;
        border-radius: 0;
        outline: none;
        height: 3rem;
        width: 100%;
        font-size: 16px;
        padding: 0;
        box-shadow: none;
        box-sizing: content-box;
        transition: box-shadow 0.3s, border 0.3s;

        &:read-only {
            border-bottom: none;
            margin-bottom: 0;
        }

        &.invalid {
            border-bottom: 1px solid #f44336 !important;
            box-shadow: 0 1px 0 0 #f44336 !important;

            + label {
                color: #f44336 !important;
            }
        }
    }

    > label {
        color: #9e9e9e;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.9rem;
        cursor: text;
        transition: transform 0.2s ease-out, color 0.2s ease-out;
        transform-origin: 0% 100%;
        text-align: initial;
        transform: translateY(12px);
        pointer-events: none;

        &.active {
            transform: translateY(-14px) scale(0.8);
            transform-origin: 0 0;
        }
    }
    .custom{
        padding:0;
        margin:0;
        height: 2.4em!important;
        width:100%!important;
    }
    .custom.link{
        cursor:text;
    }
    .prefix {
        position: absolute;
        width: 2.3rem;
        font-size: 1rem;
        transition: color 0.2s;
        top: 0.6rem;

        ~ input[type],
        ~ textarea,
        ~ label,
        ~ .validate ~ label,
        ~ .help-error {
            margin-left: 2.3rem;
            width: calc(100% - 2.3rem);
        }

        &.invalid {
            color: #f44336;
        }
    }

    .btn-plus,
    .btn-minus {
        position: absolute;
        width: 2rem;
        font-size: 1rem;
        right: 0;
        transition: color 0.2s;
        padding-left: 0;
        padding-right: 0;
        top: 0.6rem;
    }

    .btn-minus {
        right: auto;
        left: 0;
    }

    &.number-field {
        input[type],
        textarea,
        label,
        .validate ~ label,
        .help-error {
            margin: 0 2.5rem !important;
            width: calc(100% - 5rem) !important;
        }
    }

    :disabled{
        cursor:no-drop;
    }
}

.message {
    animation: fade-in 0.5s ease-out;
}
.check-group-questions{
    margin:0;
}
.check-group {
    display: flex;
    padding:0.4em 0em 0.6em 0em;

    &.disabled {
        opacity: 0.4;
        pointer-events: none;
        user-select: none;
    }

    &.full .checkbox {
        width: 100%;

        label:before {
            width: 40px;
            height: 40px;
        }
    }
}

.check-group-permissions{
    flex-wrap: wrap;
    padding: 0em 0.6em 1.3em 0em;
    float: left;
    width: 25%;
}

.checkbox {
    width: 100%;
    flex: 1 1 auto;
    background-color: rgba(255, 255, 255, 0.2);
    display: block;
    position: relative;

    &:not(.noui) label:after {
        width: 32px;
        height: 32px;
        content: '';
        border: 2px solid #d1d7dc;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
    }

    label {
        padding: 12px 15px;
        margin: 0;
        width: 100%;
        display: block;
        text-align: left;
        color: #fff;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: color 200ms ease-in;
        overflow: hidden;

        &:before {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            content: '';
            background-color: #4e008b;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale3d(1, 1, 1);
            transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
            opacity: 0;
            z-index: -1;
        }
    }

    input:checked ~ label {
        color: #fff;

        &:before {
            transform: translate(-50%, -50%) scale3d(56, 56, 1);
            opacity: 1;
        }

        &:after {
            background-color: #00a887;
            border-color: #00a887;
        }
    }

    input {
        width: 32px;
        height: 32px;
        order: 1;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        visibility: hidden;
    }
}

input.switch {
    font-size: 30px;
    appearance: none;
    width: 2.2em;
    height: 1.1em;
    background: #ddd;
    border-radius: 3em;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
    margin-left: 15px;
    flex: 0 0 auto;

    &:checked {
        background: #a228ff;

        &:after {
            left: calc(100% - 1.1em);
        }
    }

    &:after {
        position: absolute;
        content: '';
        width: 1.1em;
        height: 1.1em;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
        transform: scale(0.7);
        left: 0;
        transition: all 0.2s ease-in-out;
    }
}
input.switch.analise, input.switch.estudo, input.switch.laudo{
    font-size:1.2em!important;
    background-color: #ff3d4e;
    &:checked {
        background: #558b2f;

        &:after {
            left: calc(100% - 1.1em);
        }
    }
    
}

input.switch.mini{
    font-size: 1.2em!important;
    vertical-align:text-top;
}

input.single-checkbox {
    vertical-align: text-top;
    font-size: 1em;
    width: 1.2em;
    height: 1.2em;
    background: #ddd!important;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;

    &:checked {
        background: #a228ff!important;
    }
}

.form-group {
    .react-select__control {
        background-color: #404258;
        color: #bdbdc7;
        border: 1px solid #a6a9ae;
        cursor:pointer!important;
    }

    .react-select__input,
    .react-select__placeholder,
    .react-select__single-value,
    .react-select__menu-notice--no-options {
        color: #bdbdc7;
    }

    .react-select__menu {
        background-color: #404258;
        color: #bdbdc7;
        z-index: 20;
    }

    .react-select__option--is-focused,
    .react-select__multi-value {
        background-color: #4c5294;
        color: #fff;
        cursor:pointer;
    }

    .react-select__multi-value__label,
    .react-select__input input {
        color: #fff;
    }
}

@include desktop() {
    .checkbox {
        width: auto;
    }
}
