.modal.show {
    display: block;
}

.modal .modal-content .modal-header .close {
    font-family: "Font Awesome 5 Free";

    &:before {
        content: "\f00d";
    }
}
