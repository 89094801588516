:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #009da0;
    --secondary: #868e96;
    --success: #00a887;
    --info: #1cbcd8;
    --warning: #ff8d60;
    --danger: #ff586b;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
}

*,
:after,
:before {
    box-sizing: border-box;
}
html {
    line-height: 1.15;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
    width: device-width;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}
body {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #f5f7fa;
}
[tabindex='-1']:focus {
    outline: 0 !important;
}
hr {
    box-sizing: initial;
    height: 0;
    overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.75rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}
address {
    font-style: normal;
    line-height: inherit;
}
address,
dl,
ol,
ul {
    margin-bottom: 1rem;
}
dl,
ol,
ul {
    margin-top: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}
dt {
    font-weight: 700;
}
dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}
blockquote {
    margin: 0 0 1rem;
}
dfn {
    font-style: italic;
}
b,
strong {
    font-weight: bold;
}
small {
    font-size: 80%;
}
sub,
sup {
    position: relative;
    font-size: 65%;
    line-height: 0;
    vertical-align: initial;
}
sub {
    bottom: -0.5em;
}
sup {
    top: -1.5em;
}
a {
    text-decoration: none;
    background-color: initial;
    -webkit-text-decoration-skip: objects;
}
a:hover {
    color: #005254;
    text-decoration: underline;
}
a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
    outline: 0;
}
code,
kbd,
pre,
samp {
    font-size: 1em;
}
pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}
figure {
    margin: 0 0 1rem;
}
img {
    border-style: none;
}
img,
svg {
    vertical-align: middle;
}
svg {
    overflow: hidden;
}
table {
    border-collapse: collapse;
}
caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}
th {
    text-align: inherit;
    font-weight:500;
}


label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
button {
    border-radius: 0;
}
button:focus {
    outline: 0px
}
input.custom{
    margin-left:1.7rem!important;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
[type='reset'],
[type='submit'],
button,
html [type='button'] {
    appearance: button;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
    appearance: listbox;
}
textarea {
    overflow: auto;
    resize: vertical;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}
progress {
    vertical-align: initial;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}
[type='search'] {
    outline-offset: -2px;
    appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    appearance: none;
}
::-webkit-file-upload-button {
    font: inherit;
    appearance: button;
}
output {
    display: inline-block;
}
summary {
    display: list-item;
    cursor: pointer;
}
template {
    display: none;
}
[hidden] {
    display: none !important;
}
.uppercase{
    text-transform:uppercase;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.75rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}
.normatiza {
    background-color: #4E008B!important;
    color: white!important;
}
.lead {
    font-size: 1rem;
    font-weight: 300;
}
.column-gap-1{
    column-gap:10px;
}
.display-none{
    display:none;
}
.display-1 {
    font-size: 6rem;
}
.display-1,
.display-2 {
    font-weight: 300;
    line-height: 1.2;
}
.display-2 {
    font-size: 5.5rem;
}
.display-3 {
    font-size: 4.5rem;
}
.display-3,
.display-4 {
    font-weight: 300;
    line-height: 1.2;
}
.display-4 {
    font-size: 3.5rem;
}
.display-5 {
    font-size: 2.5rem;
}
.display-6 {
    font-size: 2rem;
}
.display-7 {
    font-size: 1rem;
}
.display-8 {
    font-size: 0.7rem;
}
.display-9 {
    font-size: 0.6rem;
}
hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
    font-size: 80%;
    font-weight: 400;
}
.mark,
mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}
.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.list-inline-item {
    display: inline-block;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}
.initialism {
    font-size: 90%;
    text-transform: uppercase;
}
.blockquote {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
}
.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}
.blockquote-footer:before {
    content: '\2014   \A0';
}
.img-fluid,
.img-thumbnail {
    max-width: 100%;
    height: auto;
}
.img-thumbnail {
    padding: 0.25rem;
    background-color: #f5f7fa;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}
.figure {
    display: inline-block;
}
.figure-img {
    margin-bottom: 0.75rem;
    line-height: 1;
}
.figure-caption {
    font-size: 90%;
    color: #6c757d;
}
code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
}
a > code {
    color: inherit;
}
kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}
kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}
pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}
.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.question {
    background-color: #333546;
    padding: 1em;
    margin-bottom:1em;
}
.question h5 {
    font-size: 1.3em;
    line-height: 2em;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-2-5,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.col.nospace{
    padding:0.2em 0.4em;
}
.col.nospace.action{
    background-color:#222225;
    color:#fff;
}
.action{
    color:#fff!important;
}
.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}
.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}
.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}
.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}
.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}
.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}
.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}
.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-first {
    order: -1;
}
.order-last {
    order: 13;
}
.order-0 {
    order: 0;
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.order-3 {
    order: 3;
}
.order-4 {
    order: 4;
}
.order-5 {
    order: 5;
}
.order-6 {
    order: 6;
}
.order-7 {
    order: 7;
}
.order-8 {
    order: 8;
}
.order-9 {
    order: 9;
}
.order-10 {
    order: 10;
}
.order-11 {
    order: 11;
}
.order-12 {
    order: 12;
}
.offset-1 {
    margin-left: 8.33333%;
}
.offset-2 {
    margin-left: 16.66667%;
}
.offset-3 {
    margin-left: 25%;
}
.offset-4 {
    margin-left: 33.33333%;
}
.offset-5 {
    margin-left: 41.66667%;
}
.offset-6 {
    margin-left: 50%;
}
.offset-7 {
    margin-left: 58.33333%;
}
.offset-8 {
    margin-left: 66.66667%;
}
.offset-9 {
    margin-left: 75%;
}
.offset-10 {
    margin-left: 83.33333%;
}
.offset-11 {
    margin-left: 91.66667%;
}
.no-padding{
    padding:0;
}
.no-gutter {
    margin-right: 0;
    margin-left: 0;
}
.no-gutter > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-sm-first {
        order: -1;
    }
    .order-sm-last {
        order: 13;
    }
    .order-sm-0 {
        order: 0;
    }
    .order-sm-1 {
        order: 1;
    }
    .order-sm-2 {
        order: 2;
    }
    .order-sm-3 {
        order: 3;
    }
    .order-sm-4 {
        order: 4;
    }
    .order-sm-5 {
        order: 5;
    }
    .order-sm-6 {
        order: 6;
    }
    .order-sm-7 {
        order: 7;
    }
    .order-sm-8 {
        order: 8;
    }
    .order-sm-9 {
        order: 9;
    }
    .order-sm-10 {
        order: 10;
    }
    .order-sm-11 {
        order: 11;
    }
    .order-sm-12 {
        order: 12;
    }
    .offset-sm-0 {
        margin-left: 0;
    }
    .offset-sm-1 {
        margin-left: 8.33333%;
    }
    .offset-sm-2 {
        margin-left: 16.66667%;
    }
    .offset-sm-3 {
        margin-left: 25%;
    }
    .offset-sm-4 {
        margin-left: 33.33333%;
    }
    .offset-sm-5 {
        margin-left: 41.66667%;
    }
    .offset-sm-6 {
        margin-left: 50%;
    }
    .offset-sm-7 {
        margin-left: 58.33333%;
    }
    .offset-sm-8 {
        margin-left: 66.66667%;
    }
    .offset-sm-9 {
        margin-left: 75%;
    }
    .offset-sm-10 {
        margin-left: 83.33333%;
    }
    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}
@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-md-2-5 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-md-first {
        order: -1;
    }
    .order-md-last {
        order: 13;
    }
    .order-md-0 {
        order: 0;
    }
    .order-md-1 {
        order: 1;
    }
    .order-md-2 {
        order: 2;
    }
    .order-md-3 {
        order: 3;
    }
    .order-md-4 {
        order: 4;
    }
    .order-md-5 {
        order: 5;
    }
    .order-md-6 {
        order: 6;
    }
    .order-md-7 {
        order: 7;
    }
    .order-md-8 {
        order: 8;
    }
    .order-md-9 {
        order: 9;
    }
    .order-md-10 {
        order: 10;
    }
    .order-md-11 {
        order: 11;
    }
    .order-md-12 {
        order: 12;
    }
    .offset-md-0 {
        margin-left: 0;
    }
    .offset-md-1 {
        margin-left: 8.33333%;
    }
    .offset-md-2 {
        margin-left: 16.66667%;
    }
    .offset-md-3 {
        margin-left: 25%;
    }
    .offset-md-4 {
        margin-left: 33.33333%;
    }
    .offset-md-5 {
        margin-left: 41.66667%;
    }
    .offset-md-6 {
        margin-left: 50%;
    }
    .offset-md-7 {
        margin-left: 58.33333%;
    }
    .offset-md-8 {
        margin-left: 66.66667%;
    }
    .offset-md-9 {
        margin-left: 75%;
    }
    .offset-md-10 {
        margin-left: 83.33333%;
    }
    .offset-md-11 {
        margin-left: 91.66667%;
    }
}
@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-lg-first {
        order: -1;
    }
    .order-lg-last {
        order: 13;
    }
    .order-lg-0 {
        order: 0;
    }
    .order-lg-1 {
        order: 1;
    }
    .order-lg-2 {
        order: 2;
    }
    .order-lg-3 {
        order: 3;
    }
    .order-lg-4 {
        order: 4;
    }
    .order-lg-5 {
        order: 5;
    }
    .order-lg-6 {
        order: 6;
    }
    .order-lg-7 {
        order: 7;
    }
    .order-lg-8 {
        order: 8;
    }
    .order-lg-9 {
        order: 9;
    }
    .order-lg-10 {
        order: 10;
    }
    .order-lg-11 {
        order: 11;
    }
    .order-lg-12 {
        order: 12;
    }
    .offset-lg-0 {
        margin-left: 0;
    }
    .offset-lg-1 {
        margin-left: 8.33333%;
    }
    .offset-lg-2 {
        margin-left: 16.66667%;
    }
    .offset-lg-3 {
        margin-left: 25%;
    }
    .offset-lg-4 {
        margin-left: 33.33333%;
    }
    .offset-lg-5 {
        margin-left: 41.66667%;
    }
    .offset-lg-6 {
        margin-left: 50%;
    }
    .offset-lg-7 {
        margin-left: 58.33333%;
    }
    .offset-lg-8 {
        margin-left: 66.66667%;
    }
    .offset-lg-9 {
        margin-left: 75%;
    }
    .offset-lg-10 {
        margin-left: 83.33333%;
    }
    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}
@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-xl-first {
        order: -1;
    }
    .order-xl-last {
        order: 13;
    }
    .order-xl-0 {
        order: 0;
    }
    .order-xl-1 {
        order: 1;
    }
    .order-xl-2 {
        order: 2;
    }
    .order-xl-3 {
        order: 3;
    }
    .order-xl-4 {
        order: 4;
    }
    .order-xl-5 {
        order: 5;
    }
    .order-xl-6 {
        order: 6;
    }
    .order-xl-7 {
        order: 7;
    }
    .order-xl-8 {
        order: 8;
    }
    .order-xl-9 {
        order: 9;
    }
    .order-xl-10 {
        order: 10;
    }
    .order-xl-11 {
        order: 11;
    }
    .order-xl-12 {
        order: 12;
    }
    .offset-xl-0 {
        margin-left: 0;
    }
    .offset-xl-1 {
        margin-left: 8.33333%;
    }
    .offset-xl-2 {
        margin-left: 16.66667%;
    }
    .offset-xl-3 {
        margin-left: 25%;
    }
    .offset-xl-4 {
        margin-left: 33.33333%;
    }
    .offset-xl-5 {
        margin-left: 41.66667%;
    }
    .offset-xl-6 {
        margin-left: 50%;
    }
    .offset-xl-7 {
        margin-left: 58.33333%;
    }
    .offset-xl-8 {
        margin-left: 66.66667%;
    }
    .offset-xl-9 {
        margin-left: 75%;
    }
    .offset-xl-10 {
        margin-left: 83.33333%;
    }
    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}
.table {
    width: 100%;
    margin-bottom: 1.5rem;
    background-color: initial;
}
.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
    border-top: 2px solid #dee2e6;
}
.table .table {
    background-color: #f5f7fa;
}
.table-sm td,
.table-sm th {
    padding: 0.3rem;
}
.table-bordered,
.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6;
}
.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
    background-color: #b8e4e4;
}
.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #a6dddd;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
    background-color: #dddfe2;
}
.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: #cfd2d6;
}
.table-success,
.table-success > td,
.table-success > th {
    background-color: #bbeedb;
}
.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #a7e9d0;
}
.table-info,
.table-info > td,
.table-info > th {
    background-color: #bfecf4;
}
.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #a9e6f0;
}
.table-warning,
.table-warning > td,
.table-warning > th {
    background-color: #ffdfd2;
}
.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #ffcdb9;
}
.table-danger,
.table-danger > td,
.table-danger > th {
    background-color: #ffd0d6;
}
.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #ffb7c0;
}
.table-light,
.table-light > td,
.table-light > th {
    background-color: #fdfdfe;
}
.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
    background-color: #c6c8ca;
}
.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #b9bbbe;
}
.table-active,
.table-active > td,
.table-active > th,
.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
}
.table .tfoot-dark td {
    color: #f5f7fa;
    background-color: #212529;
    border-color: #32383e;
}
.table .thead-dark th {
    color: #f5f7fa;
    background-color: #212529;
    border-color: #32383e;
}
.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.table-dark {
    color: #f5f7fa;
    background-color: #212529;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #32383e;
}
.table-dark.table-bordered {
    border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: hsla(0, 0%, 100%, 0.05);
}
.table-dark.table-hover tbody tr:hover {
    background-color: hsla(0, 0%, 100%, 0.075);
}
@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}
@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-md > .table-bordered {
        border: 0;
    }
}
@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}
@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
    border: 0;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}
.form-control::-ms-expand {
    background-color: initial;
    border: 0;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #21fbff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 160, 0.25);
}
.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}
select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}
.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}
.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}
.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}
.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}
.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: initial;
    border: solid transparent;
    border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
}
.form-control-sm {
    height: calc(1.8125rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.form-control-lg {
    height: calc(2.875rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size],
textarea.form-control {
    height: auto;
}
.form-group {
    margin-bottom: 1rem;
}
.form-text {
    display: block;
    margin-top: 0.25rem;
}
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.form-row > .col,
.form-row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
}
.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}
.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
}
.form-check-label {
    margin-bottom: 0;
}
.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}
.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #00a887;
}
.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(12, 194, 126, 0.9);
    border-radius: 0.25rem;
}
.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
    border-color: #00a887;
}
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #00a887;
    box-shadow: 0 0 0 0.2rem rgba(12, 194, 126, 0.25);
}
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
    display: block;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
    color: #00a887;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #00a887;
}
.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
    background-color: #59f5ba;
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label:before,
.was-validated
    .custom-control-input:valid:checked
    ~ .custom-control-label:before {
    background-color: #11f09d;
}
.custom-control-input.is-valid:focus ~ .custom-control-label:before,
.was-validated
    .custom-control-input:valid:focus
    ~ .custom-control-label:before {
    box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.2rem rgba(12, 194, 126, 0.25);
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #00a887;
}
.custom-file-input.is-valid ~ .custom-file-label:after,
.was-validated .custom-file-input:valid ~ .custom-file-label:after {
    border-color: inherit;
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(12, 194, 126, 0.25);
}
.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ff586b;
}
.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(255, 88, 107, 0.9);
    border-radius: 0.25rem;
}
.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
    border-color: #ff586b;
}
.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #ff586b;
    box-shadow: 0 0 0 0.2rem rgba(255, 88, 107, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
    display: block;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
    color: #ff586b;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #ff586b;
}
.custom-control-input.is-invalid ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid ~ .custom-control-label:before {
    background-color: #ffd8dc;
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
    display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label:before,
.was-validated
    .custom-control-input:invalid:checked
    ~ .custom-control-label:before {
    background-color: #ff8b98;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label:before,
.was-validated
    .custom-control-input:invalid:focus
    ~ .custom-control-label:before {
    box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.2rem rgba(255, 88, 107, 0.25);
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #ff586b;
}
.custom-file-input.is-invalid ~ .custom-file-label:after,
.was-validated .custom-file-input:invalid ~ .custom-file-label:after {
    border-color: inherit;
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
    display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(255, 88, 107, 0.25);
}
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.form-inline .form-check {
    width: 100%;
}
@media (min-width: 576px) {
    .form-inline label {
        justify-content: center;
    }
    .form-inline .form-group,
    .form-inline label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    .form-inline .form-group {
        flex: 0 0 auto;
        flex-flow: row wrap;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto;
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }
    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}
.btn {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    border-radius: 3px;
    padding: 0.6em 1.1em;
    font-weight: 500;
    border: 1px solid transparent;
    line-height: 15px;
    text-transform: uppercase;
    transition: .5s!important;
}
.no-btn{
    border:0!important;
    transition:none!important;
    span{
        text-transform:initial!important;
        font-size:0.9em!important;
    }
}
.no-btn:focus{
    box-shadow:none!important;
}
@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:focus,
.btn:hover {
    text-decoration: none;
}
.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 0, 139, 0.03);
}
.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
    cursor:not-allowed;
}

.btn-colapse{
    background-color: transparent !important;
    border-color: transparent !important;
    :focus{
        box-shadow: 0 0 0 0.2rem rgba(78, 0, 139, 0.03);
        outline:0px;
    }
}
.btn-cep {
    font-size: 0.8em;
    padding: 0.4em;
    border-radius: 0;
}
.btn-word{
    background-color:#2C7CD5!important;
    border-color:#2C7CD5!important;
}.btn-word:hover{
    background-color:#225d9f!important;
    border-color:#225d9f!important;
}.btn-word:disabled{
    background-color: #616161!important;
    border-color: #616161!important;
}
.btn-pdf{
    background-color:#E2574C!important;
    border-color:#E2574C!important;
    :focus{
        box-shadow: 0 0 0 0.2rem rgba(78, 0, 139, 0.03)!important;
        outline:0px!important;
    }
}.btn-pdf:hover{
    background-color:#b0372e!important;
    border-color:#b0372e!important;
}.btn-pdf:disabled{
    background-color: #616161!important;
    border-color: #616161!important;
}
.btn-primary {
    color: #fff;
    background-color: #00a887;
    border-color: #00a887;
}
.btn-primary:hover {
    color: #fff;
    background-color: #039074;
    border-color: #039074;
}
.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 0, 139, 0.03);
}
.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #006b6d;
    border-color: #006b6d;
    opacity: 0.3;
    cursor:not-allowed;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #006b6d;
    border-color: #005e60;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 160, 0.5);
}
.btn-secondary {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
}
.btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
    cursor:no-drop;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #666e76;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}
.btn-success {
    color: #fff;
    background-color: #00a887;
    border-color: #00a887;
}
.btn-success:hover {
    color: #fff;
    background-color: #008f73;
    border-color: #008f73;
}
.btn-success.focus,
.btn-success:focus {
    color: #fff;
}
.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #00a887;
    border-color: #00a887;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #00765e;
    border-color: #00765e;
}

.btn-info {
    color: #fff;
    background-color: #1cbcd8;
    border-color: #1cbcd8;
}
.btn-info:hover {
    color: #fff;
    background-color: #189fb6;
    border-color: #1695ab;
}
.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 188, 216, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #616161!important;
    border-color: #616161!important;
    opacity: 0.3;
    cursor:not-allowed;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1695ab;
    border-color: #158ba0;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 188, 216, 0.5);
}
.btn-warning {
    color: #212529;
    background-color: #ff8d60;
    border-color: #ff8d60;
}
.btn-warning:hover {
    color: #fff;
    background-color: #ff723a;
    border-color: #ff682d;
}
.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 141, 96, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #ff8d60;
    border-color: #ff8d60;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ff682d;
    border-color: #ff5f20;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 141, 96, 0.5);
}
.btn-danger {
    color: #fff;
    background-color: #ff586b;
    border-color: #ff586b;
}
.btn-danger:hover {
    color: #fff;
    background-color: #ff3249;
    border-color: #ff253e;
}
.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 88, 107, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #ff586b;
    border-color: #ff586b;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff253e;
    border-color: #ff1833;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 88, 107, 0.5);
}
.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    color:#000!important;
}
.btn-light.disabled,
.btn-light:disabled {
    color: #fff;
    background-color: #616161!important;
    border-color: #616161!important;
    opacity: 0.3;
    cursor:not-allowed;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-normatiza-primary {
    color:#4c078c;
    background-image: none;
    border-color: #4c078c;
    background-color: initial;
    transition: all 0.4s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}
.btn-outline-normatiza-primary:hover {
    color: #fff;
    background: linear-gradient(130deg, #4c078c 25%, #6f00aa 100%);
    border-color: #5c049a;
}.btn-outline-normatiza-primary:hover:disabled {
    background: transparent;
}
.btn-outline-normatiza-primary.focus,
.btn-outline-normatiza-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 0, 139, 0.18);
    color: #4c078c;
}
.btn-outline-normatiza-primary.disabled,
.btn-outline-normatiza-primary:disabled {
    color: #4c078c;
    background-color: initial;
    cursor:auto;
}
.btn-outline-normatiza-primary:not(:disabled):not(.disabled).active,
.btn-outline-normatiza-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-normatiza-primary.dropdown-toggle {
    color: #fff;
    background-color: #4c078c;
    border-color: #4c078c;
}
.btn-outline-primary-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-normatiza-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 160, 0.5);
}

.btn-outline-normatiza-secondary {
    background-image: none;
    border-color: #00a887;
    color: #00a887;
    background-color: initial;
}
.btn-outline-normatiza-secondary:hover {
    color: #fff;
    background-color: #00a887;
    border-color: #c5c5c5  !important;
}
.btn-outline-normatiza-secondary.focus,
.btn-outline-normatiza-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(197, 197, 197, 0.21);
}
.btn-outline-normatiza-secondary.disabled,
.btn-outline-normatiza-secondary:disabled {
    color: #00a887;
    background-color: initial;
    cursor:no-drop;
}
.btn-outline-normatiza-secondary:not(:disabled):not(.disabled).active,
.btn-outline-normatiza-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-normatiza-secondary.dropdown-toggle {
    color: #fff;
    background-color: #00a887;
    border-color: #00a887;
}
.btn-outline-normatiza-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-normatiza-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-normatiza-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 160, 0.5);
}
.btn-outline-primary {
    background-image: none;
    border-color: #009da0;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #009da0;
    border-color: #009da0;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 160, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #009da0;
    background-color: initial;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #009da0;
    border-color: #009da0;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 160, 0.5);
}
.btn-outline-secondary {
    color: #868e96;
    background-color: initial;
    background-image: none;
    border-color: #868e96;
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #868e96;
    background-color: initial;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}
.btn-outline-success {
    background-image: none;
    border-color: #00a887;
}
.btn-outline-success:hover {
    color: #fff;
    background-color: #00a887;
    border-color: #00a887;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(12, 194, 126, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #00a887;
    background-color: initial;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00a887;
    border-color: #00a887;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(12, 194, 126, 0.5);
}
.btn-outline-info {
    background-image: none;
    border-color: #1cbcd8;
}
.btn-outline-info:hover {
    color: #fff;
    background-color: #1cbcd8;
    border-color: #1cbcd8;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 188, 216, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #1cbcd8;
    background-color: initial;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #1cbcd8;
    border-color: #1cbcd8;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 188, 216, 0.5);
}
.btn-outline-warning {
    background-image: none;
    border-color: #ff8d60;
}
.btn-outline-warning:hover {
    color: #212529;
    background-color: #ff8d60;
    border-color: #ff8d60;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 141, 96, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ff8d60;
    background-color: initial;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff8d60;
    border-color: #ff8d60;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 141, 96, 0.5);
}
.btn-outline-danger {
    background-image: none;
    border-color: #ff586b;
}
.btn-outline-danger:hover {
    color: #fff;
    background-color: #ff586b;
    border-color: #ff586b;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 88, 107, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #ff586b;
    background-color: initial;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff586b;
    border-color: #ff586b;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 88, 107, 0.5);
}
.btn-outline-light {
    color: #f8f9fa;
    background-color: initial;
    background-image: none;
    border-color: #f8f9fa;
}
.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: initial;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
    color: #343a40;
    background-color: initial;
    background-image: none;
    border-color: #343a40;
}
.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: initial;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
    font-weight: 400;
    color: #009da0;
    background-color: initial;
}
.btn-link:hover {
    color: #005254;
    background-color: initial;
}
.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
    text-decoration: underline;
    border-color: transparent;
}
.btn-link.focus,
.btn-link:focus {
    box-shadow: none;
}
.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d;
    pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
    padding: 0.25em 1.1em;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 2px;
}
.btn-block {
    display: block;
    width: 100%;
}

input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
    width: 100%;
}
.fade {
    transition: opacity 0.05s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}
.fade:not(.show) {
    opacity: 0;
}
.collapse:not(.show) {
    display: none;
}
.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative;
}
.dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty:after {
    margin-left: 0;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}
.dropdown-menu-right {
    right: 0;
    left: auto;
}
.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty:after {
    margin-left: 0;
}
.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}
.dropright .dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty:after {
    margin-left: 0;
}
.dropright .dropdown-toggle:after {
    vertical-align: 0;
}
.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}
.dropleft .dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    display: none;
}
.dropleft .dropdown-toggle:before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty:after {
    margin-left: 0;
}
.dropleft .dropdown-toggle:before {
    vertical-align: 0;
}
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
    right: auto;
    bottom: auto;
}
.dropdown-account{
    align-items: center;
    font-weight: 400;
    padding: 0.1em 1.2em;
    display: flex;
    flex-direction: row;
    .name{
        clear: both;
        text-align: inherit;
        background-color: initial;
        border: 0;
        width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .email{
        color: rgb(195, 207, 217);
        clear: both;
        text-align: inherit;
        background-color: initial;
        border: 0;
        width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span{
            font-size:0.8em;
        }
    }
    .id{
        color: rgb(195, 207, 217);
        clear: both;
        text-align: inherit;
        background-color: initial;
        border: 0;
        width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span{
            font-size:0.8em;
            .id-code{
                background-color: #19191d;
                font-size: inherit;
                cursor: help;
                padding: 0rem 0.4rem;
                user-select:text!important;
            }
        }

    }
}
.dropdown-divider {
    background-color: #44475a;
    height: 1px;
    margin: 6px 0px 7px;
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: initial;
    border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #00a887;
}
.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #009da0;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    background-color: initial;
}
.dropdown-menu.show {
    display: block;
}
.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
}
.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}
.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
    position: relative;
    flex: 0 1 auto;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
    z-index: 1;
}
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group,
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
    margin-left: -1px;
}
.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.btn-toolbar .input-group {
    width: auto;
}
.btn-group > .btn:first-child {
    margin-left: 0;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}
.dropdown-toggle-split:after,
.dropright .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
    margin-left: 0;
}
.dropleft .dropdown-toggle-split:before {
    margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}
.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
    width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
}
.btn-group-toggle > .btn-group > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn input[type='radio'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
    margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
    z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .custom-file {
    display: flex;
    align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label:after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
    display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
    margin-left: -1px;
}
.input-group-prepend {
    margin-right: -1px;
}
.input-group-append {
    margin-left: -1px;
}
.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.input-group-text input[type='checkbox'],
.input-group-text input[type='radio'] {
    margin-top: 0;
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
    height: calc(2.875rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
    height: calc(1.8125rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.custom-control {
    position: relative;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}
.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    background-color: #009da0;
}
.custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.2rem rgba(0, 157, 160, 0.25);
}
.custom-control-input:active ~ .custom-control-label:before {
    color: #fff;
    background-color: #54fcff;
}
.custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label:before {
    background-color: #e9ecef;
}
.custom-control-label {
    position: relative;
    margin-bottom: 0;
}
.custom-control-label:before {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6;
}
.custom-control-label:after,
.custom-control-label:before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
}
.custom-control-label:after {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 50% 50%;
}
.custom-checkbox .custom-control-label:before {
    border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background-color: #009da0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label:before {
    background-color: #009da0;
}
.custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label:before {
    background-color: rgba(0, 157, 160, 0.5);
}
.custom-checkbox
    .custom-control-input:disabled:indeterminate
    ~ .custom-control-label:before {
    background-color: rgba(0, 157, 160, 0.5);
}
.custom-radio .custom-control-label:before {
    border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
    background-color: #009da0;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label:before {
    background-color: rgba(0, 157, 160, 0.5);
}
.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
        url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
        no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.custom-select:focus {
    border-color: #21fbff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 251, 255, 0.5);
}
.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size='1']) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}
.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}
.custom-select::-ms-expand {
    opacity: 0;
}
.custom-select-sm {
    height: calc(1.8125rem + 2px);
    font-size: 75%;
}
.custom-select-lg,
.custom-select-sm {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.custom-select-lg {
    height: calc(2.875rem + 2px);
    font-size: 125%;
}
.custom-file {
    display: inline-block;
    margin-bottom: 0;
}
.custom-file,
.custom-file-input {
    position: relative;
    width: 100%;
    height: calc(2.25rem + 2px);
}
.custom-file-input {
    z-index: 2;
    margin: 0;
    opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
    border-color: #21fbff;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 160, 0.25);
}
.custom-file-input:focus ~ .custom-file-label:after {
    border-color: #21fbff;
}
.custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label:after {
    content: 'Browse';
}
.custom-file-label {
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.custom-file-label,
.custom-file-label:after {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
}
.custom-file-label:after {
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    content: 'Browse';
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
    width: 100%;
    padding-left: 0;
    background-color: initial;
    appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.custom-range:focus {
    outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.2rem rgba(0, 157, 160, 0.25);
}
.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.2rem rgba(0, 157, 160, 0.25);
}
.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.2rem rgba(0, 157, 160, 0.25);
}
.custom-range::-moz-focus-outer {
    border: 0;
}
.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #009da0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none;
    }
}
.custom-range::-webkit-slider-thumb:active {
    background-color: #54fcff;
}
.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #009da0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none;
    }
}
.custom-range::-moz-range-thumb:active {
    background-color: #54fcff;
}
.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}
.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #009da0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none;
    }
}
.custom-range::-ms-thumb:active {
    background-color: #54fcff;
}
.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: initial;
    border-color: transparent;
    border-width: 0.5rem;
}
.custom-range::-ms-fill-lower,
.custom-range::-ms-fill-upper {
    background-color: #dee2e6;
    border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
    margin-right: 15px;
}
.custom-control-label:before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label:before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-link {
    display: block;
    padding: .5rem 1rem;
  padding-bottom: 20px;
    color: #bdbdc7;
}
.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
    border-bottom:2px solid #008F73;
    color:#f5f7fa;
}
.nav-link.disabled {
    color: #6c757d;
}
.nav-tabs {
    border-bottom: 2px solid #616279;
}
.nav-tabs .nav-item {
    margin-bottom: -1px;
}
.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: initial;
    cursor:no-drop;
    border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #f5f7fa;
    border-color: #dee2e6 #dee2e6 #f5f7fa;
}
.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.nav-pills .nav-link {
    border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background-color: #009da0;
}
.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}
.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}
.tab-content > .tab-pane {
    display: none;
}
.tab-content > .active {
    display: block;
}
.navbar {
    position: relative;
    padding: 0.75rem 0;
}
.navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 0;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}
.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: initial;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat 50%;
    background-size: 100% 100%;
}
@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}
@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}
@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
}
.navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand-lg .navbar-toggler {
    display: none;
}
.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0;
}
.navbar-expand .navbar-nav {
    flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
    flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand .navbar-toggler {
    display: none;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: hsla(0, 0%, 100%, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
    color: #fff;
}
.navbar-dark .navbar-toggler {
    color: hsla(0, 0%, 100%, 0.5);
    border-color: hsla(0, 0%, 100%, 0.1);
}
.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
    color: hsla(0, 0%, 100%, 0.5);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: initial;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    .client-list{
        background-color: #20212b;
        box-shadow:0px 6px 7px 0#20212ba8;
        width: 100%;
    }
    .card-footer.client-list{
        background-color: #121317;
    }
}
.card.client-list:hover {
    box-shadow: 0px 6px 7px 0 #20212bc2;
}
.card > hr {
    margin-right: 0;
    margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.card-body {
    flex: 1 1 auto;
    padding: 1em 1.25em 1.2em 2rem;
    p{
        letter-spacing: -0.5px;
        font-weight: 500;
    }
    h2{
        letter-spacing:-0.5px;
        font-weight: 500;
        font-size: 1.35em;
        color:white;
    }
    h3{
        letter-spacing:-0.5px;
        font-size: 1.1em;
        color:white;
        line-height:2em;
    }
    
}
.card-title {
    margin-bottom: 0.75rem;
}
.card-subtitle {
    margin-top: -0.375rem;
}
.card-subtitle,
.card-text:last-child {
    margin-bottom: 0;
}
.card-link:hover {
    text-decoration: none;
}
.card-link + .card-link {
    margin-left: 1.25rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}
.card-footer {
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
    margin-bottom: -0.75rem;
    border-bottom: 0;
}
.card-header-pills,
.card-header-tabs {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}
.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}
.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}
.card-img-top {
    max-height: 5rem;
    height: 5rem;
    min-width: 5rem;
    background-color:#fff;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
    display: flex;
    flex-direction: column;
}
.card-deck .card {
    margin-bottom: 15px;
}
@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}
.card-group {
    display: flex;
    flex-direction: column;
}
.card-group > .card {
    margin-bottom: 15px;
}
@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }
    .card-group > .card {
        flex: 1 0;
        margin-bottom: 0;
    }
    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group > .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:first-child .card-header,
    .card-group > .card:first-child .card-img-top {
        border-top-right-radius: 0;
    }
    .card-group > .card:first-child .card-footer,
    .card-group > .card:first-child .card-img-bottom {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:last-child .card-header,
    .card-group > .card:last-child .card-img-top {
        border-top-left-radius: 0;
    }
    .card-group > .card:last-child .card-footer,
    .card-group > .card:last-child .card-img-bottom {
        border-bottom-left-radius: 0;
    }
    .card-group > .card:only-child {
        border-radius: 0.25rem;
    }
    .card-group > .card:only-child .card-header,
    .card-group > .card:only-child .card-img-top {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .card-group > .card:only-child .card-footer,
    .card-group > .card:only-child .card-img-bottom {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child),
    .card-group
        > .card:not(:first-child):not(:last-child):not(:only-child)
        .card-footer,
    .card-group
        > .card:not(:first-child):not(:last-child):not(:only-child)
        .card-header,
    .card-group
        > .card:not(:first-child):not(:last-child):not(:only-child)
        .card-img-bottom,
    .card-group
        > .card:not(:first-child):not(:last-child):not(:only-child)
        .card-img-top {
        border-radius: 0;
    }
}
.card-columns .card {
    margin-bottom: 2rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 4;
           -moz-column-count: 4;
                column-count: 4;
        -webkit-column-gap: 0.8rem;
           -moz-column-gap: 0.8rem;
                column-gap: 0.8rem;
        orphans: 1 !important;
        widows:1 !important;
      }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}
.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}
.accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item:before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: '/';
}
.breadcrumb-item + .breadcrumb-item:hover:before {
    text-decoration: underline;
    text-decoration: none;
}
.breadcrumb-item.active {
    color: #6c757d;
}
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}
.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #009da0;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.page-link:hover {
    z-index: 2;
    color: #005254;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 157, 160, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #009da0;
    border-color: #009da0;
}
.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}
.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: initial;
    border-radius: 0;
}
.badge:empty {
    display: none;
}
.btn .badge {
    position: relative;
    top: -1px;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
.badge-primary {
    color: #fff;
}
.badge-primary[href]:focus,
.badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #006b6d;
}
.badge-secondary {
    color: #fff;
    background-color: #868e96;
}
.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #6c757d;
}
.badge-success {
    color: #fff;
}
.badge-success[href]:focus,
.badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #09925f;
}
.badge-info {
    color: #fff;
}
.badge-info[href]:focus,
.badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1695ab;
}
.badge-warning {
    color: #212529;
}
.badge-warning[href]:focus,
.badge-warning[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #ff682d;
}
.badge-danger {
    color: #fff;
}
.badge-danger[href]:focus,
.badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #ff253e;
}
.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}
.badge-light[href]:focus,
.badge-light[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5;
}
.badge-dark {
    color: #fff;
    background-color: #343a40;
}
.badge-dark[href]:focus,
.badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124;
}
.jumbotron {
    padding: 1em;
    background-color: #e9ecef;
    border-radius: 2px;
}
@media (min-width: 576px) {
    .jumbotron {
        padding: 1.5em 2em 1.5em 2em;
    }
}
.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}
.jumbotron.normatiza {
    background-color: #4E008B;
    background-image:linear-gradient(130deg, #4c078c 25%, #6f00aa 100%);
    color: white;
    h1{
        font-weight: 400;
        font-size: 2.5em;
        letter-spacing: -0.5px;
    }
}
.alert-remove{
    h6{
        font-size: 1.2em;
        font-weight:600;
        line-height: 1.5em;
    }
    h5{
        font-size: 1.2em;
        font-weight:600;
        line-height: 1.5em;
    }
    p{
        font-weight:100;
        font-size:1.1em;
    }
    li{
        line-height: 1.5em;
    }
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 0px solid transparent;
    border-radius: 2px;
    box-shadow: 0 5px 8px -6px rgba(0,0,0,.2);
        -webkit-box-shadow: 0 5px 8px -6px rgba(0,0,0,.2);
        -moz-box-shadow: 0 5px 8px -6px rgba(0,0,0,.2);
    font-size: 0.9em;
    line-height: 1.8!important;
}
.alert-heading {
    color: inherit;
}
.alert-link {
    font-weight: 700;
}
.alert-dismissible {
    padding-right: 4rem;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}
.alert-normatiza{
    border-left: 5px solid #ececee;
    color: #ececee;
    background-color: #61656e;
}
.alert-primary {
    color: #005253;
    background-color: #ccebec;
    border-color: #b8e4e4;
}
.alert-primary hr {
    border-top-color: #a6dddd;
}
.alert-primary .alert-link {
    color: #002020;
}
.alert-secondary {
    color: #464a4e;
    background-color: #e7e8ea;
    border-color: #dddfe2;
}
.alert-secondary hr {
    border-top-color: #cfd2d6;
}
.alert-secondary .alert-link {
    color: #2e3133;
}
.alert-success {
    color: #066542;
    background-color: #cef3e5;
    border-color: #bbeedb;
}
.alert-success hr {
    border-top-color: #a7e9d0;
}
.alert-success .alert-link {
    color: #033523;
}
.alert-info {
    color: #0f6270;
    background-color: #d2f2f7;
    border-color: #bfecf4;
}
.alert-info hr {
    border-top-color: #a9e6f0;
}
.alert-info .alert-link {
    color: #093b43;
}
.alert-warning {
    color: #854932;
    background-color: #ffe8df;
    border-color: #ffdfd2;
}
.alert-warning hr {
    border-top-color: #ffcdb9;
}
.alert-warning .alert-link {
    color: #603524;
}
.alert-danger {
    border-left: 5px solid #fff;
    color: #fff;
    background-color: #d4473a;
    display: block;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 2px;
    line-height: 1.6 !important;
    text-align:center;
}
.alert-danger hr {
    border-top-color: #ffb7c0;
}
.alert-danger .alert-link {
    color: #5f2128;
}
.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}
.alert-light hr {
    border-top-color: #ececf6;
}
.alert-light .alert-link {
    color: #686868;
}
.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}
.alert-dark hr {
    border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
    color: #040505;
}
@keyframes progress-bar-stripes {
    0% {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}
.progress-bar.bg-success.no-progress, .progress.no-progress{
    background-color:transparent !important;
}
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #009da0;
    transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}
.progress-bar-striped {
    background-image: -webkit-linear-gradient(
        45deg,
        hsla(0, 0%, 100%, 0.15) 25%,
        transparent 0,
        transparent 50%,
        hsla(0, 0%, 100%, 0.15) 0,
        hsla(0, 0%, 100%, 0.15) 75%,
        transparent 0,
        transparent
    );
    background-image: linear-gradient(
        45deg,
        hsla(0, 0%, 100%, 0.15) 25%,
        transparent 0,
        transparent 50%,
        hsla(0, 0%, 100%, 0.15) 0,
        hsla(0, 0%, 100%, 0.15) 75%,
        transparent 0,
        transparent
    );
    background-size: 1rem 1rem;
}
.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}
.media {
    display: flex;
    align-items: flex-start;
}
.media-body {
    flex: 1 1;
}
.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}
.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}
.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.list-group-item:focus,
.list-group-item:hover {
    z-index: 1;
    text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    background-color: #fff;
}
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #009da0;
    border-color: #009da0;
}
.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
}
.list-group-item-primary {
    color: #005253;
    background-color: #b8e4e4;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #005253;
    background-color: #a6dddd;
}
.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #005253;
    border-color: #005253;
}
.list-group-item-secondary {
    color: #464a4e;
    background-color: #dddfe2;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #464a4e;
    background-color: #cfd2d6;
}
.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #464a4e;
    border-color: #464a4e;
}
.list-group-item-success {
    color: #066542;
    background-color: #bbeedb;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #066542;
    background-color: #a7e9d0;
}
.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #066542;
    border-color: #066542;
}
.list-group-item-info {
    color: #0f6270;
    background-color: #bfecf4;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #0f6270;
    background-color: #a9e6f0;
}
.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0f6270;
    border-color: #0f6270;
}
.list-group-item-warning {
    color: #854932;
    background-color: #ffdfd2;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #854932;
    background-color: #ffcdb9;
}
.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #854932;
    border-color: #854932;
}
.list-group-item-danger {
    color: #852e38;
    background-color: #ffd0d6;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #852e38;
    background-color: #ffb7c0;
}
.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #852e38;
    border-color: #852e38;
}
.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #818182;
    background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}
.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #1b1e21;
    background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}
.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
}
button.close {
    padding: 0;
    background-color: initial;
    border: 0;
    appearance: none;
}
.modal-open {
    overflow: hidden;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}
.modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transform: translateY(-25%);
}
@media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}
.modal.show .modal-dialog {
    transform: translate(0);
}
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}
.modal-dialog-centered:before {
    display: block;
    height: calc(100vh - 1rem);
    content: '';
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: 0.5;
}
.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-weight:200;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    text-align: center;
    font-size: 1.1em;
}
.modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}
.modal-footer > :not(:first-child) {
    margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
    margin-right: 0.25rem;
}
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered:before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}
@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }
}
.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.775rem;
    word-wrap: break-word;
    opacity: 0;
}
.tooltip.show {
    opacity: 1;
    transition: 0s!important;
}
.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
    transform: translate(6px, 0px);
}
.tooltip .arrow:before {
    position: absolute;
    content: '';
    border-color: transparent;
    border-style: solid;
}
#tooltip-bottom-start{
    .arrow{
        width: 0.3em!important;
        transform: translate(5px, 0px)!important;
    }
}
.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top {
    padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^='top'] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0;
}
.bs-tooltip-auto[x-placement^='top'] .arrow:before,
.bs-tooltip-top .arrow:before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #19191D;
}
.bs-tooltip-auto[x-placement^='right'],
.bs-tooltip-right {
    padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-auto[x-placement^='right'] .arrow:before,
.bs-tooltip-right .arrow:before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #19191D;
}
.bs-tooltip-auto[x-placement^='bottom'],
.bs-tooltip-bottom {
    padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow:before,
.bs-tooltip-bottom .arrow:before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #19191D;
}
.bs-tooltip-auto[x-placement^='left'],
.bs-tooltip-left {
    padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-auto[x-placement^='left'] .arrow:before,
.bs-tooltip-left .arrow:before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #19191D;
}
.tooltip-inner {
    max-width: 300px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #19191D;
    border-radius: 1px;
}
.popover {
    top: 0;
    left: 0;
    z-index: 1060;
    max-width: 276px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}
.popover,
.popover .arrow {
    position: absolute;
    display: block;
}
.popover .arrow {
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}
.popover .arrow:after,
.popover .arrow:before {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
}
.bs-popover-auto[x-placement^='top'],
.bs-popover-top {
    margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^='top'] .arrow,
.bs-popover-top .arrow {
    bottom: calc(-0.5rem + -1px);
}
.bs-popover-auto[x-placement^='top'] .arrow:after,
.bs-popover-auto[x-placement^='top'] .arrow:before,
.bs-popover-top .arrow:after,
.bs-popover-top .arrow:before {
    border-width: 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^='top'] .arrow:before,
.bs-popover-top .arrow:before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^='top'] .arrow:after,
.bs-popover-top .arrow:after {
    bottom: 1px;
    border-top-color: #fff;
}
.bs-popover-auto[x-placement^='right'],
.bs-popover-right {
    margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^='right'] .arrow,
.bs-popover-right .arrow {
    left: calc(-0.5rem + -1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^='right'] .arrow:after,
.bs-popover-auto[x-placement^='right'] .arrow:before,
.bs-popover-right .arrow:after,
.bs-popover-right .arrow:before {
    border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^='right'] .arrow:before,
.bs-popover-right .arrow:before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^='right'] .arrow:after,
.bs-popover-right .arrow:after {
    left: 1px;
    border-right-color: #fff;
}
.bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom {
    margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^='bottom'] .arrow,
.bs-popover-bottom .arrow {
    top: calc(-0.5rem + -1px);
}
.bs-popover-auto[x-placement^='bottom'] .arrow:after,
.bs-popover-auto[x-placement^='bottom'] .arrow:before,
.bs-popover-bottom .arrow:after,
.bs-popover-bottom .arrow:before {
    border-width: 0 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^='bottom'] .arrow:before,
.bs-popover-bottom .arrow:before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^='bottom'] .arrow:after,
.bs-popover-bottom .arrow:after {
    top: 1px;
    border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^='bottom'] .popover-header:before,
.bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: '';
    border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^='left'],
.bs-popover-left {
    margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^='left'] .arrow,
.bs-popover-left .arrow {
    right: calc(-0.5rem + -1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^='left'] .arrow:after,
.bs-popover-auto[x-placement^='left'] .arrow:before,
.bs-popover-left .arrow:after,
.bs-popover-left .arrow:before {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^='left'] .arrow:before,
.bs-popover-left .arrow:before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^='left'] .arrow:after,
.bs-popover-left .arrow:after {
    right: 1px;
    border-left-color: #fff;
}
.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
    display: none;
}
.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}
.carousel {
    position: relative;
}
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
    .carousel-item-next,
    .carousel-item-prev,
    .carousel-item.active {
        transition: none;
    }
}
.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0;
}
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translateX(0);
}
@supports (
    (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        transform: translateZ(0);
    }
}
.active.carousel-item-right,
.carousel-item-next {
    transform: translateX(100%);
}
@supports (
    (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
    .active.carousel-item-right,
    .carousel-item-next {
        transform: translate3d(100%, 0, 0);
    }
}
.active.carousel-item-left,
.carousel-item-prev {
    transform: translateX(-100%);
}
@supports (
    (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
    .active.carousel-item-left,
    .carousel-item-prev {
        transform: translate3d(-100%, 0, 0);
    }
}
.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-property: opacity;
    transition-property: opacity;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
    opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    opacity: 0;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
    transform: translateX(0);
}
@supports (
    (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev,
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active {
        transform: translateZ(0);
    }
}
.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}
.carousel-control-prev {
    left: 0;
}
.carousel-control-next {
    right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat 50%;
    background-size: 100% 100%;
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}
.carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: hsla(0, 0%, 100%, 0.5);
}
.carousel-indicators li:before {
    top: -10px;
}
.carousel-indicators li:after,
.carousel-indicators li:before {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: '';
}
.carousel-indicators li:after {
    bottom: -10px;
}
.carousel-indicators .active {
    background-color: #fff;
}
.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}
.align-baseline {
    vertical-align: initial !important;
}
.align-top {
    vertical-align: top !important;
}
.align-middle {
    vertical-align: middle !important;
}
.align-bottom {
    vertical-align: bottom !important;
}
.align-text-bottom {
    vertical-align: text-bottom !important;
}
.align-text-top {
    vertical-align: text-top !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #006b6d !important;
}
.bg-secondary {
    background-color: #868e96 !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #6c757d !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #09925f !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #1695ab !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #ff682d !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #ff253e !important;
}
.bg-light {
    background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5 !important;
}
.bg-dark {
    background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124 !important;
}
.bg-transparent {
    background-color: initial !important;
}
.border {
    border: 1px solid #dee2e6 !important;
}
.border-top {
    border-top: 1px solid #dee2e6 !important;
}
.border-right {
    border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
    border-left: 1px solid #dee2e6 !important;
}
.border-0 {
    border: 0 !important;
}
.border-top-0 {
    border-top: 0 !important;
}
.border-right-0 {
    border-right: 0 !important;
}
.border-bottom-0 {
    border-bottom: 0 !important;
}
.border-left-0 {
    border-left: 0 !important;
}
.border-primary {
    border-color: #009da0 !important;
}
.border-secondary {
    border-color: #868e96 !important;
}
.border-success {
    border-color: #00a887 !important;
}
.border-info {
    border-color: #1cbcd8 !important;
}
.border-warning {
    border-color: #ff8d60 !important;
}
.border-danger {
    border-color: #ff586b !important;
}
.border-light {
    border-color: #f8f9fa !important;
}
.border-dark {
    border-color: #343a40 !important;
}
.border-white {
    border-color: #fff !important;
}
.rounded {
    border-radius: 0.25rem !important;
}
.rounded-top {
    border-top-left-radius: 0.25rem !important;
}
.rounded-right,
.rounded-top {
    border-top-right-radius: 0.25rem !important;
}
.rounded-bottom,
.rounded-right {
    border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom,
.rounded-left {
    border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
    border-top-left-radius: 0.25rem !important;
}
.rounded-circle {
    border-radius: 50% !important;
}
.rounded-0 {
    border-radius: 0 !important;
}
.clearfix:after {
    display: block;
    clear: both;
    content: '';
}
.d-none {
    display: none !important;
}
.d-inline {
    display: inline !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-block {
    display: block !important;
}
.d-table {
    display: table !important;
}
.d-table-row {
    display: table-row !important;
}
.d-table-cell {
    display: table-cell !important;
}
.d-flex {
    display: flex !important;
}
.d-inline-flex {
    display: inline-flex !important;
}
@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}
@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
}
@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}
@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}
@media print {
    .modal-dialog {
        margin-top: 0;
        padding-top: 0;
        max-width: 60%;
    }
    #root{
        display:none !important;
    }
    .modal-header .close, .modal-footer{
        display:none !important;
    }
    .d-print-none{
        display: none !important;
    }
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: flex !important;
    }
    .d-print-inline-flex {
        display: inline-flex !important;
    }
}
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.embed-responsive:before {
    display: block;
    content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.embed-responsive-21by9:before {
    padding-top: 42.85714%;
}
.embed-responsive-16by9:before {
    padding-top: 56.25%;
}
.embed-responsive-4by3:before {
    padding-top: 75%;
}
.embed-responsive-1by1:before {
    padding-top: 100%;
}
.flex-row {
    flex-direction: row !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-row-reverse {
    flex-direction: row-reverse !important;
}
.flex-column-reverse {
    flex-direction: column-reverse !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.flex-nowrap {
    flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}
.flex-fill {
    flex: 1 1 auto !important;
}
.flex-grow-0 {
    flex-grow: 0 !important;
}
.flex-grow-1 {
    flex-grow: 1 !important;
}
.flex-shrink-0 {
    flex-shrink: 0 !important;
}
.flex-shrink-1 {
    flex-shrink: 1 !important;
}
.justify-content-start {
    justify-content: flex-start !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-around {
    justify-content: space-around !important;
}
.align-items-start {
    align-items: flex-start !important;
}
.align-items-end {
    align-items: flex-end !important;
}
.align-items-center {
    align-items: center !important;
}
.align-items-baseline {
    align-items: baseline !important;
}
.align-items-stretch {
    align-items: stretch !important;
}
.align-content-start {
    align-content: flex-start !important;
}
.align-content-end {
    align-content: flex-end !important;
}
.align-content-center {
    align-content: center !important;
}
.align-content-between {
    align-content: space-between !important;
}
.align-content-around {
    align-content: space-around !important;
}
.align-content-stretch {
    align-content: stretch !important;
}
.align-self-auto {
    align-self: auto !important;
}
.align-self-start {
    align-self: flex-start !important;
}
.align-self-end {
    align-self: flex-end !important;
}
.align-self-center {
    align-self: center !important;
}
.align-self-baseline {
    align-self: baseline !important;
}
.align-self-stretch {
    align-self: stretch !important;
}
@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }
    .flex-sm-column {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
        flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        justify-content: center !important;
    }
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        align-items: center !important;
    }
    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
    .align-content-sm-start {
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        align-content: center !important;
    }
    .align-content-sm-between {
        align-content: space-between !important;
    }
    .align-content-sm-around {
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        align-self: auto !important;
    }
    .align-self-sm-start {
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        align-self: center !important;
    }
    .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}
@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }
    .flex-md-column {
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
        flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .align-items-md-end {
        align-items: flex-end !important;
    }
    .align-items-md-center {
        align-items: center !important;
    }
    .align-items-md-baseline {
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        align-items: stretch !important;
    }
    .align-content-md-start {
        align-content: flex-start !important;
    }
    .align-content-md-end {
        align-content: flex-end !important;
    }
    .align-content-md-center {
        align-content: center !important;
    }
    .align-content-md-between {
        align-content: space-between !important;
    }
    .align-content-md-around {
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        align-content: stretch !important;
    }
    .align-self-md-auto {
        align-self: auto !important;
    }
    .align-self-md-start {
        align-self: flex-start !important;
    }
    .align-self-md-end {
        align-self: flex-end !important;
    }
    .align-self-md-center {
        align-self: center !important;
    }
    .align-self-md-baseline {
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        align-self: stretch !important;
    }
}
@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }
    .flex-lg-column {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
        flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        justify-content: center !important;
    }
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        align-items: center !important;
    }
    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
    .align-content-lg-start {
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        align-content: center !important;
    }
    .align-content-lg-between {
        align-content: space-between !important;
    }
    .align-content-lg-around {
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        align-self: auto !important;
    }
    .align-self-lg-start {
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        align-self: center !important;
    }
    .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}
@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }
    .flex-xl-column {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        align-items: center !important;
    }
    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
    .align-content-xl-start {
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        align-content: center !important;
    }
    .align-content-xl-between {
        align-content: space-between !important;
    }
    .align-content-xl-around {
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        align-self: auto !important;
    }
    .align-self-xl-start {
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        align-self: center !important;
    }
    .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}
.float-left, #float-left {
    float: left !important;
}
.float-right {
    float: right !important;
}
.float-none {
    float: none !important;
}
@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }
    .float-sm-right {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
}
@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }
    .float-md-right {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
}
@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }
    .float-lg-right {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
}
@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }
    .float-xl-right {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
}
.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}
.fixed-top {
    top: 0;
}
.fixed-bottom,
.fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}
.fixed-bottom {
    bottom: 0;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}
.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
    box-shadow: none !important;
}
.w-25 {
    width: 25% !important;
}
.w-50 {
    width: 50% !important;
}
.w-75 {
    width: 75% !important;
}
.w-auto {
    width: auto !important;
}
.h-25 {
    height: 25% !important;
}
.h-50 {
    height: 50% !important;
}
.h-75 {
    height: 75% !important;
}
.h-auto {
    height: auto !important;
}
.mw-100 {
    max-width: 100% !important;
}
.mh-100 {
    max-height: 100% !important;
}
.m-0 {
    margin: 0 !important;
}
.mt-0,
.my-0 {
    margin-top: 0 !important;
}
.mr-0,
.mx-0 {
    margin-right: 0 !important;
}
.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
    margin-left: 0 !important;
}
.m-1 {
    margin: 0.375rem !important;
}
.mt-1,
.my-1 {
    margin-top: 0.375rem !important;
}
.mr-1,
#mr-1,
.mx-1 {
    margin-right: 0.375rem !important;
}
.mb-1,
.my-1 {
    margin-bottom: 0.375rem !important;
}
.ml-1,
.mx-1 {
    margin-left: 0.375rem !important;
}
.m-2 {
    margin: 0.75rem !important;
}
.mt-2,
.my-2 {
    margin-top: 0.75rem !important;
}
.mtn-1{
    margin-top: -0.375rem !important;
}.mbn-1{
    margin-bottom: -0.375rem !important;
}
.mtn-2{
    margin-top: -0.75rem !important;
}.mbn-2{
    margin-bottom: -0.75rem !important;
}
.mtn-3{
    margin-top: -1rem !important;
}.mbn-3{
    margin-bottom: -1rem !important;
}
.mtn-4{
    margin-top: -2.1rem !important;
}.mbn-4{
    margin-bottom: -2.1rem !important;
}
.mr-2,
.mx-2 {
    margin-right: 0.75rem !important;
}
.mb-2,
.my-2 {
    margin-bottom: 0.75rem !important;
}
.ml-2,
.mx-2 {
    margin-left: 0.75rem !important;
}
.m-3 {
    margin: 1.5rem !important;
}
.mt-3,
.my-3 {
    margin-top: 1.5rem !important;
}
.mr-3,
.mx-3 {
    margin-right: 1.5rem !important;
}
.mb-3,
.my-3 {
    margin-bottom: 1.5rem !important;
}
.ml-3,
.mx-3 {
    margin-left: 1.5rem !important;
}
.m-4 {
    margin: 2.25rem !important;
}
.mt-4,
.my-4 {
    margin-top: 2.25rem !important;
}
.mr-4,
.mx-4 {
    margin-right: 2.25rem !important;
}
.mb-4,
.my-4 {
    margin-bottom: 2.25rem !important;
}
.ml-4,
.mx-4 {
    margin-left: 2.25rem !important;
}
.m-5 {
    margin: 4.5rem !important;
}
.mt-5,
.my-5 {
    margin-top: 4.5rem !important;
}
.mr-5,
.mx-5 {
    margin-right: 4.5rem !important;
}
.mb-5,
.my-5 {
    margin-bottom: 4.5rem !important;
}
.ml-5,
.mx-5 {
    margin-left: 4.5rem !important;
}
.p-0 {
    padding: 0 !important;
}
.pt-0,
.py-0 {
    padding-top: 0 !important;
}
.pr-0,
.px-0 {
    padding-right: 0 !important;
}
.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
    padding-left: 0 !important;
}
.p-1 {
    padding: 0.375rem !important;
}
.pt-1,
.py-1 {
    padding-top: 0.375rem !important;
}
.pr-1,
.px-1 {
    padding-right: 0.375rem !important;
}
.pb-1,
.py-1 {
    padding-bottom: 0.375rem !important;
}
.pl-1,
.px-1 {
    padding-left: 0.375rem !important;
}
.p-2 {
    padding: 0.75rem !important;
}
.pt-2,
.py-2 {
    padding-top: 0.75rem !important;
}
.pr-2,
.px-2 {
    padding-right: 0.75rem !important;
}
.pb-2,
.py-2 {
    padding-bottom: 1rem !important;
}
.pl-2,
.px-2 {
    padding-left: 0.75rem !important;
}
.p-3 {
    padding: 1.5rem !important;
}
.pt-3,
.py-3 {
    padding-top: 1.5rem !important;
}
.pr-3,
.px-3 {
    padding-right: 1.5rem !important;
}
.pb-3,
.py-3 {
    padding-bottom: 1.5rem !important;
}
.pl-3,
.px-3 {
    padding-left: 1.5rem !important;
}
.p-4 {
    padding: 2.25rem !important;
}
.pt-4,
.py-4 {
    padding-top: 2.25rem !important;
}
.pr-4,
.px-4 {
    padding-right: 2.25rem !important;
}
.pb-4,
.py-4 {
    padding-bottom: 2.25rem !important;
}
.pb-45{
    padding-bottom: 3rem !important;
}
.pl-4,
.px-4 {
    padding-left: 2.25rem !important;
}
.p-5 {
    padding: 4.5rem !important;
}
.pt-5,
.py-5 {
    padding-top: 4.5rem !important;
}
.pr-5,
.px-5 {
    padding-right: 4.5rem !important;
}
.pb-5,
.py-5 {
    padding-bottom: 4.5rem !important;
}
.pl-5,
.px-5 {
    padding-left: 4.5rem !important;
}
.m-auto {
    margin: auto !important;
}
.mt-auto,
.my-auto {
    margin-top: auto !important;
}
.mr-auto,
.mx-auto {
    margin-right: auto !important;
}
.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
    margin-left: auto !important;
}
@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }
    .m-sm-1 {
        margin: 0.375rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.375rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.375rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.375rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.375rem !important;
    }
    .m-sm-2 {
        margin: 0.75rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.75rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.75rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.75rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.75rem !important;
    }
    .m-sm-3 {
        margin: 1.5rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1.5rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1.5rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1.5rem !important;
    }
    .m-sm-4 {
        margin: 2.25rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 2.25rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 2.25rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 2.25rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 2.25rem !important;
    }
    .m-sm-5 {
        margin: 4.5rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 4.5rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 4.5rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 4.5rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 4.5rem !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }
    .p-sm-1 {
        padding: 0.375rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.375rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.375rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.375rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.375rem !important;
    }
    .p-sm-2 {
        padding: 0.75rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.75rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.75rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.75rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.75rem !important;
    }
    .p-sm-3 {
        padding: 1.5rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1.5rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1.5rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1.5rem !important;
    }
    .p-sm-4 {
        padding: 2.25rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 2.25rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 2.25rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 2.25rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 2.25rem !important;
    }
    .p-sm-5 {
        padding: 4.5rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 4.5rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 4.5rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 4.5rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 4.5rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }
    .m-md-1 {
        margin: 0.375rem !important;
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: 0.375rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.375rem !important;
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.375rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.375rem !important;
    }
    .m-md-2 {
        margin: 0.75rem !important;
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: 0.75rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.75rem !important;
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.75rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.75rem !important;
    }
    .m-md-3 {
        margin: 1.5rem !important;
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1.5rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1.5rem !important;
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1.5rem !important;
    }
    .m-md-4 {
        margin: 2.25rem !important;
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 2.25rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 2.25rem !important;
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 2.25rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 2.25rem !important;
    }
    .m-md-5 {
        margin: 4.5rem !important;
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 4.5rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 4.5rem !important;
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 4.5rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 4.5rem !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }
    .p-md-1 {
        padding: 0.375rem !important;
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: 0.375rem !important;
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: 0.375rem !important;
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.375rem !important;
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: 0.375rem !important;
    }
    .p-md-2 {
        padding: 0.75rem !important;
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: 0.75rem !important;
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: 0.75rem !important;
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.75rem !important;
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: 0.75rem !important;
    }
    .p-md-3 {
        padding: 1.5rem !important;
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1.5rem !important;
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1.5rem !important;
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1.5rem !important;
    }
    .p-md-4 {
        padding: 2.25rem !important;
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 2.25rem !important;
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 2.25rem !important;
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 2.25rem !important;
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 2.25rem !important;
    }
    .p-md-5 {
        padding: 4.5rem !important;
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 4.5rem !important;
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 4.5rem !important;
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 4.5rem !important;
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 4.5rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }
    .m-lg-1 {
        margin: 0.375rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.375rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.375rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.375rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.375rem !important;
    }
    .m-lg-2 {
        margin: 0.75rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.75rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.75rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.75rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.75rem !important;
    }
    .m-lg-3 {
        margin: 1.5rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1.5rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1.5rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1.5rem !important;
    }
    .m-lg-4 {
        margin: 2.25rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 2.25rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 2.25rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 2.25rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 2.25rem !important;
    }
    .m-lg-5 {
        margin: 4.5rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 4.5rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 4.5rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 4.5rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 4.5rem !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }
    .p-lg-1 {
        padding: 0.375rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.375rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.375rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.375rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.375rem !important;
    }
    .p-lg-2 {
        padding: 0.75rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.75rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.75rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.75rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.75rem !important;
    }
    .p-lg-3 {
        padding: 1.5rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1.5rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1.5rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1.5rem !important;
    }
    .p-lg-4 {
        padding: 2.25rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 2.25rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 2.25rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 2.25rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 2.25rem !important;
    }
    .p-lg-5 {
        padding: 4.5rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 4.5rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 4.5rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 4.5rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 4.5rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }
    .m-xl-1 {
        margin: 0.375rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.375rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.375rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.375rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.375rem !important;
    }
    .m-xl-2 {
        margin: 0.75rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.75rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.75rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.75rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.75rem !important;
    }
    .m-xl-3 {
        margin: 1.5rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1.5rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1.5rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1.5rem !important;
    }
    .m-xl-4 {
        margin: 2.25rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 2.25rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 2.25rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 2.25rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 2.25rem !important;
    }
    .m-xl-5 {
        margin: 4.5rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 4.5rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 4.5rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 4.5rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 4.5rem !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }
    .p-xl-1 {
        padding: 0.375rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.375rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.375rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.375rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.375rem !important;
    }
    .p-xl-2 {
        padding: 0.75rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.75rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.75rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.75rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.75rem !important;
    }
    .p-xl-3 {
        padding: 1.5rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1.5rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1.5rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1.5rem !important;
    }
    .p-xl-4 {
        padding: 2.25rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 2.25rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 2.25rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 2.25rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 2.25rem !important;
    }
    .p-xl-5 {
        padding: 4.5rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 4.5rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 4.5rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 4.5rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 4.5rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}
.text-justify {
    text-align: justify !important;
}
.text-nowrap {
    white-space: nowrap !important;
}
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
.text-center {
    text-align: center !important;
}
@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }
    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}
@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}
@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}
@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }
    .text-xl-right {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}
.text-lowercase {
    text-transform: lowercase !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.text-capitalize {
    text-transform: capitalize !important;
}
.font-weight-light {
    font-weight: 300 !important;
}
.font-weight-normal {
    font-weight: 400 !important;
}
.font-weight-bold {
    font-weight: 700 !important;
}
.font-italic {
    font-style: italic !important;
}
.text-white {
    color: #fff !important;
}
a.text-primary:focus,
a.text-primary:hover {
    color: #006b6d !important;
}
.text-secondary {
    color: #868e96 !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
    color: #6c757d !important;
}
a.text-success:focus,
a.text-success:hover {
    color: #09925f !important;
}
a.text-info:focus,
a.text-info:hover {
    color: #1695ab !important;
}
a.text-warning:focus,
a.text-warning:hover {
    color: #ff682d !important;
}
a.text-danger:focus,
a.text-danger:hover {
    color: #ff253e !important;
}
.text-light {
    color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
    color: #dae0e5 !important;
}
.text-dark {
    color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
    color: #1d2124 !important;
}
.text-body {
    color: #212529 !important;
}
.text-muted {
    color: #6c757d !important;
}
.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
    color: hsla(0, 0%, 100%, 0.5) !important;
}
.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: initial;
    border: 0;
}
.visible {
    visibility: visible !important;
}
.invisible {
    visibility: hidden !important;
}
@media print {
    *,
    :after,
    :before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    abbr[title]:after {
        content: ' (' attr(title) ')';
    }
    pre {
        white-space: pre-wrap !important;
    }
    blockquote,
    pre {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    img,
    tr {
        page-break-inside: avoid;
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    @page {
        size: a3;
    }
    .container,
    body {
        min-width: 992px !important;
    }
    .navbar {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered td,
    .table-bordered th {
        border: 1px solid #dee2e6 !important;
    }
    .table-dark {
        color: inherit;
    }
    .table-dark tbody + tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        border-color: #dee2e6;
    }
    .table .thead-dark th {
        color: inherit;
        border-color: #dee2e6;
    }
}
.card {
    font-size: 1rem;
    font-weight: 300;
}
[dir='rtl'] .ct-label.ct-horizontal.ct-end {
    align-items: flex-end;
    justify-content: flex-end;
}
.btn.gradient-indigo-light-blue.active,
.btn.gradient-indigo-light-blue:active,
.gradient-indigo-light-blue {
    background-image: -webkit-linear-gradient(45deg, #c5cae9, #b3e5fc);
    background-image: linear-gradient(45deg, #c5cae9, #b3e5fc);
    background-repeat: repeat-x;
}
.btn.gradient-light-blue-indigo.active,
.btn.gradient-light-blue-indigo:active,
.gradient-light-blue-indigo {
    background-image: -webkit-linear-gradient(45deg, #b3e5fc, #9fa8da);
    background-image: linear-gradient(45deg, #b3e5fc, #9fa8da);
    background-repeat: repeat-x;
}
.btn.gradient-yellow-green.active,
.btn.gradient-yellow-green:active,
.gradient-yellow-green {
    background-image: -webkit-linear-gradient(45deg, #ffff8d, #b9f6ca);
    background-image: linear-gradient(45deg, #ffff8d, #b9f6ca);
    background-repeat: repeat-x;
}
.btn.gradient-orange-deep-orange.active,
.btn.gradient-orange-deep-orange:active,
.gradient-orange-deep-orange {
    background-image: -webkit-linear-gradient(45deg, #ffe0b2, #ffccbc);
    background-image: linear-gradient(45deg, #ffe0b2, #ffccbc);
    background-repeat: repeat-x;
}
.btn.gradient-deep-purple-purple.active,
.btn.gradient-deep-purple-purple:active,
.gradient-deep-purple-purple {
    background-image: -webkit-linear-gradient(45deg, #d1c4e9, #f3e5f5);
    background-image: linear-gradient(45deg, #d1c4e9, #f3e5f5);
    background-repeat: repeat-x;
}
.btn.gradient-red-pink.active,
.btn.gradient-red-pink:active,
.gradient-red-pink {
    background-image: -webkit-linear-gradient(45deg, #ff5252, #f48fb1);
    background-image: linear-gradient(45deg, #ff5252, #f48fb1);
    background-repeat: repeat-x;
}
.btn.gradient-light-green-amber.active,
.btn.gradient-light-green-amber:active,
.gradient-light-green-amber {
    background-image: -webkit-linear-gradient(45deg, #c5e1a5, #fff8e1);
    background-image: linear-gradient(45deg, #c5e1a5, #fff8e1);
    background-repeat: repeat-x;
}
.btn.gradient-amber-amber.active,
.btn.gradient-amber-amber:active,
.gradient-amber-amber {
    background-image: -webkit-linear-gradient(45deg, #ff6f00, #ffca28);
    background-image: linear-gradient(45deg, #ff6f00, #ffca28);
    background-repeat: repeat-x;
}
.btn.gradient-purple-pink.active,
.btn.gradient-purple-pink:active,
.gradient-purple-pink {
    background-image: -webkit-linear-gradient(45deg, #ea80fc, #fce4ec);
    background-image: linear-gradient(45deg, #ea80fc, #fce4ec);
    background-repeat: repeat-x;
}
.btn.gradient-indigo-dark-blue.active,
.btn.gradient-indigo-dark-blue:active,
.gradient-indigo-dark-blue {
    background-image: -webkit-linear-gradient(45deg, #3949ab, #4fc3f7);
    background-image: linear-gradient(45deg, #3949ab, #4fc3f7);
    background-repeat: repeat-x;
}
.btn.gradient-teal-cyan.active,
.btn.gradient-teal-cyan:active,
.gradient-teal-cyan {
    background-image: -webkit-linear-gradient(45deg, #e0f2f1, #00e5ff);
    background-image: linear-gradient(45deg, #e0f2f1, #00e5ff);
    background-repeat: repeat-x;
}
.btn.gradient-blue-grey-blue-grey.active,
.btn.gradient-blue-grey-blue-grey:active,
.gradient-blue-grey-blue-grey {
    background-image: -webkit-linear-gradient(45deg, #cfd8dc, #546e7a);
    background-image: linear-gradient(45deg, #cfd8dc, #546e7a);
    background-repeat: repeat-x;
}
.btn.gradient-cyan-dark-green.active,
.btn.gradient-cyan-dark-green:active,
.gradient-cyan-dark-green {
    background-image: -webkit-linear-gradient(45deg, #0097a7, #b2ff59);
    background-image: linear-gradient(45deg, #0097a7, #b2ff59);
    background-repeat: repeat-x;
}
.btn.gradient-orange-amber.active,
.btn.gradient-orange-amber:active,
.gradient-orange-amber {
    background-image: -webkit-linear-gradient(45deg, #e65100, #ffa000);
    background-image: linear-gradient(45deg, #e65100, #ffa000);
    background-repeat: repeat-x;
}
.btn.gradient-indigo-blue.active,
.btn.gradient-indigo-blue:active,
.gradient-indigo-blue {
    background-image: -webkit-linear-gradient(45deg, #303f9f, #1976d2);
    background-image: linear-gradient(45deg, #303f9f, #1976d2);
    background-repeat: repeat-x;
}
.btn.gradient-brown-brown.active,
.btn.gradient-brown-brown:active,
.gradient-brown-brown {
    background-image: -webkit-linear-gradient(45deg, #6d4c41, #d7ccc8);
    background-image: linear-gradient(45deg, #6d4c41, #d7ccc8);
    background-repeat: repeat-x;
}
.btn.gradient-blue-grey-blue.active,
.btn.gradient-blue-grey-blue:active,
.gradient-blue-grey-blue {
    background-image: -webkit-linear-gradient(45deg, #263238, #2979ff);
    background-image: linear-gradient(45deg, #263238, #2979ff);
    background-repeat: repeat-x;
}
.btn.gradient-purple-deep-orange.active,
.btn.gradient-purple-deep-orange:active,
.gradient-purple-deep-orange {
    background-image: -webkit-linear-gradient(45deg, #8e24aa, #ff6e40);
    background-image: linear-gradient(45deg, #8e24aa, #ff6e40);
    background-repeat: repeat-x;
}
.btn.gradient-green-teal.active,
.btn.gradient-green-teal:active,
.gradient-green-teal {
    background-image: -webkit-linear-gradient(45deg, #43a047, #1de9b6);
    background-image: linear-gradient(45deg, #43a047, #1de9b6);
    background-repeat: repeat-x;
}
.btn.gradient-purple-light-blue.active,
.btn.gradient-purple-light-blue:active,
.gradient-purple-light-blue {
    background-image: -webkit-linear-gradient(45deg, #e040fb, #4fc3f7);
    background-image: linear-gradient(45deg, #e040fb, #4fc3f7);
    background-repeat: repeat-x;
}
.btn.gradient-cyan-cyan.active,
.btn.gradient-cyan-cyan:active,
.gradient-cyan-cyan {
    background-image: -webkit-linear-gradient(45deg, #18ffff, #00e5ff);
    background-image: linear-gradient(45deg, #18ffff, #00e5ff);
    background-repeat: repeat-x;
}
.btn.gradient-yellow-teal.active,
.btn.gradient-yellow-teal:active,
.gradient-yellow-teal {
    background-image: -webkit-linear-gradient(45deg, #fff9c4, #64ffda);
    background-image: linear-gradient(45deg, #fff9c4, #64ffda);
    background-repeat: repeat-x;
}
.btn.gradient-purple-deep-purple.active,
.btn.gradient-purple-deep-purple:active,
.gradient-purple-deep-purple {
    background-image: -webkit-linear-gradient(45deg, #7b1fa2, #7c4dff);
    background-image: linear-gradient(45deg, #7b1fa2, #7c4dff);
    background-repeat: repeat-x;
}
.btn.gradient-cyan-light-green.active,
.btn.gradient-cyan-light-green:active,
.gradient-cyan-light-green {
    background-image: -webkit-linear-gradient(45deg, #00e5ff, #ccff90);
    background-image: linear-gradient(45deg, #00e5ff, #ccff90);
    background-repeat: repeat-x;
}
.btn.gradient-purple-amber.active,
.btn.gradient-purple-amber:active,
.gradient-purple-amber {
    background-image: -webkit-linear-gradient(45deg, #d500f9, #ffa000);
    background-image: linear-gradient(45deg, #d500f9, #ffa000);
    background-repeat: repeat-x;
}
.btn.gradient-indigo-purple.active,
.btn.gradient-indigo-purple:active,
.gradient-indigo-purple {
    background-image: -webkit-linear-gradient(45deg, #303f9f, #7b1fa2);
    background-image: linear-gradient(45deg, #303f9f, #7b1fa2);
    background-repeat: repeat-x;
}
.btn.gradient-deep-purple-blue.active,
.btn.gradient-deep-purple-blue:active,
.gradient-deep-purple-blue {
    background-image: -webkit-linear-gradient(45deg, #6200ea, #1976d2);
    background-image: linear-gradient(45deg, #6200ea, #1976d2);
    background-repeat: repeat-x;
}
.btn.gradient-deep-orange-orange.active,
.btn.gradient-deep-orange-orange:active,
.gradient-deep-orange-orange {
    background-image: -webkit-linear-gradient(45deg, #bf360c, #f57c00);
    background-image: linear-gradient(45deg, #bf360c, #f57c00);
    background-repeat: repeat-x;
}
.btn.gradient-light-blue-cyan.active,
.btn.gradient-light-blue-cyan:active,
.gradient-light-blue-cyan {
    background-image: -webkit-linear-gradient(45deg, #0288d1, #26c6da);
    background-image: linear-gradient(45deg, #0288d1, #26c6da);
    background-repeat: repeat-x;
}
.btn.gradient-blue-indigo.active,
.btn.gradient-blue-indigo:active,
.gradient-blue-indigo {
    background-image: -webkit-linear-gradient(45deg, #2962ff, #3949ab);
    background-image: linear-gradient(45deg, #2962ff, #3949ab);
    background-repeat: repeat-x;
}
.btn.gradient-strawberry.active,
.btn.gradient-strawberry:active,
.gradient-strawberry {
    background-image: -webkit-linear-gradient(45deg, #fe0b46, #ffab96);
    background-image: linear-gradient(45deg, #fe0b46, #ffab96);
    background-repeat: repeat-x;
}
.btn.gradient-blueberry.active,
.btn.gradient-blueberry:active,
.gradient-blueberry {
    background-image: -webkit-linear-gradient(45deg, #6078ea, #17ead9);
    background-image: linear-gradient(45deg, #6078ea, #17ead9);
    background-repeat: repeat-x;
}
.btn.gradient-mint.active,
.btn.gradient-mint:active,
.gradient-mint {
    background-image: -webkit-linear-gradient(45deg, #23bcbb, #45e994);
    background-image: linear-gradient(45deg, #23bcbb, #45e994);
    background-repeat: repeat-x;
}
.btn.gradient-orange.active,
.btn.gradient-orange:active,
.gradient-orange {
    background-image: -webkit-linear-gradient(45deg, #c471f3, #f671cd);
    background-image: linear-gradient(45deg, #c471f3, #f671cd);
    background-repeat: repeat-x;
}
.btn.gradient-pomegranate.active,
.btn.gradient-pomegranate:active,
.gradient-pomegranate {
    background-image: -webkit-linear-gradient(45deg, #9b3cb7, #ff396f);
    background-image: linear-gradient(45deg, #9b3cb7, #ff396f);
    background-repeat: repeat-x;
}
.btn.gradient-green-tea.active,
.btn.gradient-green-tea:active,
.gradient-green-tea {
    background-image: -webkit-linear-gradient(45deg, #004b91, #78cc37);
    background-image: linear-gradient(45deg, #004b91, #78cc37);
    background-repeat: repeat-x;
}
.btn.gradient-blackberry.active,
.btn.gradient-blackberry:active,
.gradient-blackberry {
    background-image: -webkit-linear-gradient(45deg, #843cf7, #38b8f2);
    background-image: linear-gradient(45deg, #843cf7, #38b8f2);
    background-repeat: repeat-x;
}
.btn.gradient-plum.active,
.btn.gradient-plum:active,
.gradient-plum {
    background-image: -webkit-linear-gradient(45deg, #e35c67, #381ce2);
    background-image: linear-gradient(45deg, #e35c67, #381ce2);
    background-repeat: repeat-x;
}
.btn.gradient-passion-fruit.active,
.btn.gradient-passion-fruit:active,
.gradient-passion-fruit {
    background-image: -webkit-linear-gradient(45deg, #8137f7, #f6ab3e);
    background-image: linear-gradient(45deg, #8137f7, #f6ab3e);
    background-repeat: repeat-x;
}
.btn.gradient-sublime-vivid.active,
.btn.gradient-sublime-vivid:active,
.gradient-sublime-vivid {
    background-image: -webkit-linear-gradient(45deg, #fc466b, #3f5efb);
    background-image: linear-gradient(45deg, #fc466b, #3f5efb);
    background-repeat: repeat-x;
}
.btn.gradient-king-yna.active,
.btn.gradient-king-yna:active,
.gradient-king-yna {
    background-image: -webkit-linear-gradient(45deg, #1a2a6c, #b21f1f);
    background-image: linear-gradient(45deg, #1a2a6c, #b21f1f);
    background-repeat: repeat-x;
}
.btn.gradient-summer.active,
.btn.gradient-summer:active,
.gradient-summer {
    background-image: -webkit-linear-gradient(45deg, #22c1c3, #fdbb2d);
    background-image: linear-gradient(45deg, #22c1c3, #fdbb2d);
    background-repeat: repeat-x;
}
.btn.gradient-crystal-clear.active,
.btn.gradient-crystal-clear:active,
.gradient-crystal-clear {
    background-image: -webkit-linear-gradient(45deg, #159957, #155799);
    background-image: linear-gradient(45deg, #159957, #155799);
    background-repeat: repeat-x;
}
.btn.gradient-celestial.active,
.btn.gradient-celestial:active,
.gradient-celestial {
    background-image: -webkit-linear-gradient(45deg, #c33764, #1d2671);
    background-image: linear-gradient(45deg, #c33764, #1d2671);
    background-repeat: repeat-x;
}
.btn.gradient-mini.active,
.btn.gradient-mini:active,
.gradient-mini {
    background-image: -webkit-linear-gradient(45deg, #30e8bf, #ff8235);
    background-image: linear-gradient(45deg, #30e8bf, #ff8235);
    background-repeat: repeat-x;
}
.btn.gradient-ibiza-sunset.active,
.btn.gradient-ibiza-sunset:active,
.gradient-ibiza-sunset {
    background-image: -webkit-linear-gradient(45deg, #ee0979, #ff6a00);
    background-image: linear-gradient(45deg, #ee0979, #ff6a00);
    background-repeat: repeat-x;
}
.btn.gradient-dawn.active,
.btn.gradient-dawn:active,
.gradient-dawn {
    background-image: -webkit-linear-gradient(45deg, #f3904f, #3b4371);
    background-image: linear-gradient(45deg, #f3904f, #3b4371);
    background-repeat: repeat-x;
}
.btn.gradient-brady-brady-fun-fun.active,
.btn.gradient-brady-brady-fun-fun:active,
.gradient-brady-brady-fun-fun {
    background-image: -webkit-linear-gradient(45deg, #00c3ff, #ffff1c);
    background-image: linear-gradient(45deg, #00c3ff, #ffff1c);
    background-repeat: repeat-x;
}
.btn.gradient-cosmic-fusion.active,
.btn.gradient-cosmic-fusion:active,
.gradient-cosmic-fusion {
    background-image: -webkit-linear-gradient(45deg, #f0c, #339);
    background-image: linear-gradient(45deg, #f0c, #339);
    background-repeat: repeat-x;
}
.btn.gradient-nepal.active,
.btn.gradient-nepal:active,
.gradient-nepal {
    background-image: -webkit-linear-gradient(45deg, #de6161, #2657eb);
    background-image: linear-gradient(45deg, #de6161, #2657eb);
    background-repeat: repeat-x;
}
.btn.gradient-love-couple.active,
.btn.gradient-love-couple:active,
.gradient-love-couple {
    background-image: -webkit-linear-gradient(45deg, #3a6186, #89253e);
    background-image: linear-gradient(45deg, #3a6186, #89253e);
    background-repeat: repeat-x;
}
.btn.gradient-sunset.active,
.btn.gradient-sunset:active,
.gradient-sunset {
    background-image: -webkit-linear-gradient(45deg, #0b486b, #f56217);
    background-image: linear-gradient(45deg, #0b486b, #f56217);
    background-repeat: repeat-x;
}
.btn.gradient-grapefruit-sunset.active,
.btn.gradient-grapefruit-sunset:active,
.gradient-grapefruit-sunset {
    background-image: -webkit-linear-gradient(45deg, #e96443, #904e95);
    background-image: linear-gradient(45deg, #e96443, #904e95);
    background-repeat: repeat-x;
}
.btn.gradient-politics.active,
.btn.gradient-politics:active,
.gradient-politics {
    background-image: -webkit-linear-gradient(45deg, #2196f3, #f44336);
    background-image: linear-gradient(45deg, #2196f3, #f44336);
    background-repeat: repeat-x;
}
.btn.gradient-red-ocean.active,
.btn.gradient-red-ocean:active,
.gradient-red-ocean {
    background-image: -webkit-linear-gradient(45deg, #1d4350, #a43931);
    background-image: linear-gradient(45deg, #1d4350, #a43931);
    background-repeat: repeat-x;
}
.btn.gradient-back-to-earth.active,
.btn.gradient-back-to-earth:active,
.gradient-back-to-earth {
    background-image: -webkit-linear-gradient(45deg, #20cae4, #1ce5b7);
    background-image: linear-gradient(45deg, #20cae4, #1ce5b7);
    background-repeat: repeat-x;
}
.btn.gradient-flickr.active,
.btn.gradient-flickr:active,
.gradient-flickr {
    background-image: -webkit-linear-gradient(45deg, #33001b, #ff0084);
    background-image: linear-gradient(45deg, #33001b, #ff0084);
    background-repeat: repeat-x;
}
.btn.gradient-virgin-america.active,
.btn.gradient-virgin-america:active,
.gradient-virgin-america {
    background-image: -webkit-linear-gradient(45deg, #7b4397, #dc2430);
    background-image: linear-gradient(45deg, #7b4397, #dc2430);
    background-repeat: repeat-x;
}
.btn.gradient-purple-bliss.active,
.btn.gradient-purple-bliss:active,
.gradient-purple-bliss {
    background-image: -webkit-linear-gradient(45deg, #360033, #0b8793);
    background-image: linear-gradient(45deg, #360033, #0b8793);
    background-repeat: repeat-x;
}
.btn.gradient-man-of-steel.active,
.btn.gradient-man-of-steel:active,
.gradient-man-of-steel {
    background-image: -webkit-linear-gradient(45deg, #780206, #061161);
    background-image: linear-gradient(45deg, #780206, #061161);
    background-repeat: repeat-x;
}
.btn.gradient-shdow-night.active,
.btn.gradient-shdow-night:active,
.gradient-shdow-night {
    background-image: -webkit-linear-gradient(45deg, #000, #53346d);
    background-image: linear-gradient(45deg, #000, #53346d);
    background-repeat: repeat-x;
}
.btn.gradient-dracula.active,
.btn.gradient-dracula:active,
.gradient-dracula {
    background-image: -webkit-linear-gradient(45deg, #dc2424, #4a569d);
    background-image: linear-gradient(45deg, #dc2424, #4a569d);
    background-repeat: repeat-x;
}
.btn.gradient-bloody-mary.active,
.btn.gradient-bloody-mary:active,
.gradient-bloody-mary {
    background-image: -webkit-linear-gradient(45deg, #ff512f, #dd2476);
    background-image: linear-gradient(45deg, #ff512f, #dd2476);
    background-repeat: repeat-x;
}
.btn.gradient-harmonic-energy.active,
.btn.gradient-harmonic-energy:active,
.gradient-harmonic-energy {
    background-image: -webkit-linear-gradient(45deg, #16a085, #f4d03f);
    background-image: linear-gradient(45deg, #16a085, #f4d03f);
    background-repeat: repeat-x;
}
.btn.gradient-purple-love.active,
.btn.gradient-purple-love:active,
.gradient-purple-love {
    background-image: -webkit-linear-gradient(45deg, #cc2b5e, #753a88);
    background-image: linear-gradient(45deg, #cc2b5e, #753a88);
    background-repeat: repeat-x;
}

.bg-white {
    background-color: #fff !important;
    h6{
        color: #5b6987!important;
        text-transform: uppercase;
    }
    p{
        color: #5b6987;
        text-transform: initial;
    }
}
.bg-white .card-footer,
.bg-white .card-header {
    background-color: initial;
}
.toast-white {
    background-color: #fff;
}
.alert-white {
    border-color: #fff !important;
    background-color: #fff !important;
    color: #a6a6a6 !important;
}
.alert-white .alert-link {
    color: #919191 !important;
}
.border-white {
    border-color: #fff;
}
.border-top-white {
    border-top-color: #fff;
}
.border-bottom-white {
    border-bottom-color: #fff;
}
[dir='ltr'] .border-left-white {
    border-left-color: #fff;
}
[dir='ltr'] .border-right-white,
[dir='rtl'] .border-left-white {
    border-right-color: #fff;
}
[dir='rtl'] .border-right-white {
    border-left-color: #fff;
}
.badge-white {
    background-color: #fff;
}
.panel-white {
    border-color: #fff;
}
.panel-white .panel-heading {
    color: #fff;
    border-color: #fff;
    background-color: #fff;
}
.bg-white.tag-glow,
.border-white.tag-glow {
    box-shadow: 0 0 10px #fff;
}
.overlay-white {
    background: #fff;
    background: hsla(0, 0%, 100%, 0.8);
}
.card.card-outline-white {
    border: 1px solid #fff;
    background-color: initial;
}
.card.card-outline-white .card-footer,
.card.card-outline-white .card-header {
    background-color: initial;
}
.btn-white.btn-flat {
    background-color: initial !important;
    color: #fff;
    border: none;
}
.btn-white.btn-fab,
.btn-white.btn-raised {
    background-color: #fff !important;
    color: #fff !important;
    border-color: #fff;
}
.btn-white.btn-fab.active,
.btn-white.btn-raised.active {
    background-color: #ededed !important;
    border-color: #ededed !important;
}
.btn-group-raised .btn-white {
    background-color: #fff !important;
    color: #fff !important;
}
.btn-outline-white {
    border: 1px solid #fff;
    background-color: initial;
    color: #fff;
    box-shadow: none !important;
}
.btn-outline-white:focus {
    background-color: initial !important;
    color: #fff !important;
    box-shadow: transparent !important;
}
.btn-outline-white.active {
    background-color: #fff !important;
    color: #fff !important;
}
.btn-outline-white:hover {
    background-color: #f7f7f7 !important;
    color: #fff !important;
}
.btn-outline-white:hover svg {
    color: #fff !important;
}
.btn-outline-white.btn-fab,
.btn-outline-white.btn-raised {
    border: 1px solid #fff;
    background-color: initial;
    color: #fff;
    box-shadow: none !important;
}
.btn-outline-white.btn-fab:focus,
.btn-outline-white.btn-raised:focus {
    background-color: initial;
}
.btn-outline-white.btn-fab.active,
.btn-outline-white.btn-raised.active {
    background-color: #fff !important;
    color: #fff !important;
}
.btn-outline-white.btn-fab:hover,
.btn-outline-white.btn-raised:hover {
    background-color: #f7f7f7 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-white {
    background-color: #fff;
}
.progress .progress-bar.progress-bar-white[value]::-webkit-progress-value {
    background-color: #fff;
}
.progress .progress-bar.progress-bar-white[value]::-moz-progress-bar {
    background-color: #fff;
}
.progress .progress-bar.progress-bar-white[value]::-ms-fill {
    background-color: #fff;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-white .progress-bar {
        background-color: #fff;
    }
}
input:focus ~ .bg-white {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff !important;
}
.black {
    color: #000 !important;
}
.bg-black {
    background-color: #000 !important;
}
.bg-black .card-footer,
.bg-black .card-header {
    background-color: initial;
}
.toast-black {
    background-color: #000;
}
.alert-black {
    border-color: #000 !important;
    background-color: #121212 !important;
}
.alert-black,
.alert-black .alert-link {
    color: #000 !important;
}
.border-black {
    border-color: #000;
}
.border-top-black {
    border-top-color: #000;
}
.border-bottom-black {
    border-bottom-color: #000;
}
[dir='ltr'] .border-left-black {
    border-left-color: #000;
}
[dir='ltr'] .border-right-black,
[dir='rtl'] .border-left-black {
    border-right-color: #000;
}
[dir='rtl'] .border-right-black {
    border-left-color: #000;
}
.badge-black {
    background-color: #000;
}
.panel-black {
    border-color: #000;
}
.panel-black .panel-heading {
    color: #fff;
    border-color: #000;
    background-color: #0d0d0d;
}
.bg-black.tag-glow,
.border-black.tag-glow {
    box-shadow: 0 0 10px #000;
}
.overlay-black {
    background: #000;
    background: rgba(0, 0, 0, 0.8);
}
.card.card-outline-black {
    border: 1px solid #000;
    background-color: initial;
}
.card.card-outline-black .card-footer,
.card.card-outline-black .card-header {
    background-color: initial;
}
.btn-black.btn-flat {
    background-color: initial !important;
    color: #000;
    border: none;
}
.btn-black.btn-fab,
.btn-black.btn-raised {
    background-color: #000 !important;
    color: #fff !important;
    border-color: #000;
}
.btn-black.btn-fab.active,
.btn-black.btn-raised.active {
    background-color: #000 !important;
    border-color: #000 !important;
}
.btn-group-raised .btn-black {
    background-color: #000 !important;
    color: #fff !important;
}
.btn-outline-black {
    border: 1px solid #000;
    background-color: initial;
    color: #000;
    box-shadow: none !important;
}
.btn-outline-black:focus {
    background-color: initial !important;
    color: #000 !important;
    box-shadow: transparent !important;
}
.btn-outline-black.active,
.btn-outline-black:hover {
    background-color: #000 !important;
    color: #fff !important;
}
.btn-outline-black:hover svg {
    color: #fff !important;
}
.btn-outline-black.btn-fab,
.btn-outline-black.btn-raised {
    border: 1px solid #000;
    background-color: initial;
    color: #000;
    box-shadow: none !important;
}
.btn-outline-black.btn-fab:focus,
.btn-outline-black.btn-raised:focus {
    background-color: initial;
}
.btn-outline-black.btn-fab.active,
.btn-outline-black.btn-fab:hover,
.btn-outline-black.btn-raised.active,
.btn-outline-black.btn-raised:hover {
    background-color: #000 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-black {
    background-color: #000;
}
.progress .progress-bar.progress-bar-black[value]::-webkit-progress-value {
    background-color: #000;
}
.progress .progress-bar.progress-bar-black[value]::-moz-progress-bar {
    background-color: #000;
}
.progress .progress-bar.progress-bar-black[value]::-ms-fill {
    background-color: #000;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-black .progress-bar {
        background-color: #000;
    }
}
input:focus ~ .bg-black {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000 !important;
}
.primary.lighten-5 {
    color: #e0f3f4 !important;
}
.bg-primary.bg-lighten-5,
.btn-primary.btn-lighten-5 {
    background-color: #e0f3f4 !important;
}
.btn-primary.btn-lighten-5 {
    border-color: #008b8e !important;
}
.btn-primary.btn-lighten-5:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-lighten-5:active,
.btn-primary.btn-lighten-5:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-lighten-5 {
    border-color: #e0f3f4 !important;
    color: #e0f3f4 !important;
}
.btn-outline-primary.btn-outline-lighten-5:hover {
    background-color: #e0f3f4 !important;
}
.progress-primary.progress-lighten-5 {
    background-color: #e0f3f4;
}
.progress-primary.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e0f3f4;
}
.progress-primary.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e0f3f4;
}
.progress-primary.progress-lighten-5[value]::-ms-fill {
    background-color: #e0f3f4;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-lighten-5 .progress-bar {
        background-color: #e0f3f4;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0f3f4 !important;
}
.border-primary.border-lighten-5 {
    border: 1px solid #e0f3f4 !important;
}
.border-top-primary.border-top-lighten-5 {
    border-top: 1px solid #e0f3f4 !important;
}
.border-bottom-primary.border-bottom-lighten-5 {
    border-bottom: 1px solid #e0f3f4 !important;
}
[dir='ltr'] .border-left-primary.border-left-lighten-5 {
    border-left: 1px solid #e0f3f4 !important;
}
[dir='ltr'] .border-right-primary.border-right-lighten-5,
[dir='rtl'] .border-left-primary.border-left-lighten-5 {
    border-right: 1px solid #e0f3f4 !important;
}
[dir='rtl'] .border-right-primary.border-right-lighten-5 {
    border-left: 1px solid #e0f3f4 !important;
}
.overlay-primary.overlay-lighten-5 {
    background: #e0f3f4;
    background: rgba(224, 243, 244, 0.8);
}
.primary.lighten-4 {
    color: #b3e2e3 !important;
}
.bg-primary.bg-lighten-4,
.btn-primary.btn-lighten-4 {
    background-color: #b3e2e3 !important;
}
.btn-primary.btn-lighten-4 {
    border-color: #008b8e !important;
}
.btn-primary.btn-lighten-4:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-lighten-4:active,
.btn-primary.btn-lighten-4:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-lighten-4 {
    border-color: #b3e2e3 !important;
    color: #b3e2e3 !important;
}
.btn-outline-primary.btn-outline-lighten-4:hover {
    background-color: #b3e2e3 !important;
}
.progress-primary.progress-lighten-4 {
    background-color: #b3e2e3;
}
.progress-primary.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b3e2e3;
}
.progress-primary.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b3e2e3;
}
.progress-primary.progress-lighten-4[value]::-ms-fill {
    background-color: #b3e2e3;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-lighten-4 .progress-bar {
        background-color: #b3e2e3;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b3e2e3 !important;
}
.border-primary.border-lighten-4 {
    border: 1px solid #b3e2e3 !important;
}
.border-top-primary.border-top-lighten-4 {
    border-top: 1px solid #b3e2e3 !important;
}
.border-bottom-primary.border-bottom-lighten-4 {
    border-bottom: 1px solid #b3e2e3 !important;
}
[dir='ltr'] .border-left-primary.border-left-lighten-4 {
    border-left: 1px solid #b3e2e3 !important;
}
[dir='ltr'] .border-right-primary.border-right-lighten-4,
[dir='rtl'] .border-left-primary.border-left-lighten-4 {
    border-right: 1px solid #b3e2e3 !important;
}
[dir='rtl'] .border-right-primary.border-right-lighten-4 {
    border-left: 1px solid #b3e2e3 !important;
}
.overlay-primary.overlay-lighten-4 {
    background: #b3e2e3;
    background: rgba(179, 226, 227, 0.8);
}
.primary.lighten-3 {
    color: #80ced0 !important;
}
.bg-primary.bg-lighten-3,
.btn-primary.btn-lighten-3 {
    background-color: #80ced0 !important;
}
.btn-primary.btn-lighten-3 {
    border-color: #008b8e !important;
}
.btn-primary.btn-lighten-3:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-lighten-3:active,
.btn-primary.btn-lighten-3:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-lighten-3 {
    border-color: #80ced0 !important;
    color: #80ced0 !important;
}
.btn-outline-primary.btn-outline-lighten-3:hover {
    background-color: #80ced0 !important;
}
.progress-primary.progress-lighten-3 {
    background-color: #80ced0;
}
.progress-primary.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #80ced0;
}
.progress-primary.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #80ced0;
}
.progress-primary.progress-lighten-3[value]::-ms-fill {
    background-color: #80ced0;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-lighten-3 .progress-bar {
        background-color: #80ced0;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80ced0 !important;
}
.border-primary.border-lighten-3 {
    border: 1px solid #80ced0 !important;
}
.border-top-primary.border-top-lighten-3 {
    border-top: 1px solid #80ced0 !important;
}
.border-bottom-primary.border-bottom-lighten-3 {
    border-bottom: 1px solid #80ced0 !important;
}
[dir='ltr'] .border-left-primary.border-left-lighten-3 {
    border-left: 1px solid #80ced0 !important;
}
[dir='ltr'] .border-right-primary.border-right-lighten-3,
[dir='rtl'] .border-left-primary.border-left-lighten-3 {
    border-right: 1px solid #80ced0 !important;
}
[dir='rtl'] .border-right-primary.border-right-lighten-3 {
    border-left: 1px solid #80ced0 !important;
}
.overlay-primary.overlay-lighten-3 {
    background: #80ced0;
    background: rgba(128, 206, 208, 0.8);
}
.primary.lighten-2 {
    color: #4dbabd !important;
}
.bg-primary.bg-lighten-2,
.btn-primary.btn-lighten-2 {
    background-color: #4dbabd !important;
}
.btn-primary.btn-lighten-2 {
    border-color: #008b8e !important;
}
.btn-primary.btn-lighten-2:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-lighten-2:active,
.btn-primary.btn-lighten-2:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-lighten-2 {
    border-color: #4dbabd !important;
    color: #4dbabd !important;
}
.btn-outline-primary.btn-outline-lighten-2:hover {
    background-color: #4dbabd !important;
}
.progress-primary.progress-lighten-2 {
    background-color: #4dbabd;
}
.progress-primary.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4dbabd;
}
.progress-primary.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4dbabd;
}
.progress-primary.progress-lighten-2[value]::-ms-fill {
    background-color: #4dbabd;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-lighten-2 .progress-bar {
        background-color: #4dbabd;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4dbabd !important;
}
.border-primary.border-lighten-2 {
    border: 1px solid #4dbabd !important;
}
.border-top-primary.border-top-lighten-2 {
    border-top: 1px solid #4dbabd !important;
}
.border-bottom-primary.border-bottom-lighten-2 {
    border-bottom: 1px solid #4dbabd !important;
}
[dir='ltr'] .border-left-primary.border-left-lighten-2 {
    border-left: 1px solid #4dbabd !important;
}
[dir='ltr'] .border-right-primary.border-right-lighten-2,
[dir='rtl'] .border-left-primary.border-left-lighten-2 {
    border-right: 1px solid #4dbabd !important;
}
[dir='rtl'] .border-right-primary.border-right-lighten-2 {
    border-left: 1px solid #4dbabd !important;
}
.overlay-primary.overlay-lighten-2 {
    background: #4dbabd;
    background: rgba(77, 186, 189, 0.8);
}
.primary.lighten-1 {
    color: #26acae !important;
}
.bg-primary.bg-lighten-1,
.btn-primary.btn-lighten-1 {
    background-color: #26acae !important;
}
.btn-primary.btn-lighten-1 {
    border-color: #008b8e !important;
}
.btn-primary.btn-lighten-1:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-lighten-1:active,
.btn-primary.btn-lighten-1:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-lighten-1 {
    border-color: #26acae !important;
    color: #26acae !important;
}
.btn-outline-primary.btn-outline-lighten-1:hover {
    background-color: #26acae !important;
}
.progress-primary.progress-lighten-1 {
    background-color: #26acae;
}
.progress-primary.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #26acae;
}
.progress-primary.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #26acae;
}
.progress-primary.progress-lighten-1[value]::-ms-fill {
    background-color: #26acae;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-lighten-1 .progress-bar {
        background-color: #26acae;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26acae !important;
}
.border-primary.border-lighten-1 {
    border: 1px solid #26acae !important;
}
.border-top-primary.border-top-lighten-1 {
    border-top: 1px solid #26acae !important;
}
.border-bottom-primary.border-bottom-lighten-1 {
    border-bottom: 1px solid #26acae !important;
}
[dir='ltr'] .border-left-primary.border-left-lighten-1 {
    border-left: 1px solid #26acae !important;
}
[dir='ltr'] .border-right-primary.border-right-lighten-1,
[dir='rtl'] .border-left-primary.border-left-lighten-1 {
    border-right: 1px solid #26acae !important;
}
[dir='rtl'] .border-right-primary.border-right-lighten-1 {
    border-left: 1px solid #26acae !important;
}
.overlay-primary.overlay-lighten-1 {
    background: #26acae;
    background: rgba(38, 172, 174, 0.8);
}
.primary {
    color: #009da0 !important;
}
.bg-primary {
    background-color: #009da0 !important;
}
.bg-primary .card-footer,
.bg-primary .card-header {
    background-color: initial;
}
.toast-primary {
    background-color: #009da0;
}
.alert-primary {
    border-color: #009da0 !important;
    background-color: #00c0c4 !important;
}
.alert-primary,
.alert-primary .alert-link {
    color: #000 !important;
}
.border-primary {
    border-color: #009da0;
}
.border-top-primary {
    border-top-color: #009da0;
}
.border-bottom-primary {
    border-bottom-color: #009da0;
}
[dir='ltr'] .border-left-primary {
    border-left-color: #009da0;
}
[dir='ltr'] .border-right-primary,
[dir='rtl'] .border-left-primary {
    border-right-color: #009da0;
}
[dir='rtl'] .border-right-primary {
    border-left-color: #009da0;
}
.badge-primary {
    background-color: #009da0;
}
.panel-primary {
    border-color: #009da0;
}
.panel-primary .panel-heading {
    color: #fff;
    border-color: #009da0;
    background-color: #00b6ba;
}
.bg-primary.tag-glow,
.border-primary.tag-glow {
    box-shadow: 0 0 10px #009da0;
}
.overlay-primary {
    background: #009da0;
    background: rgba(0, 157, 160, 0.8);
}
.card.card-outline-primary {
    border: 1px solid #009da0;
    background-color: initial;
}
.card.card-outline-primary .card-footer,
.card.card-outline-primary .card-header {
    background-color: initial;
}
.btn-primary.btn-flat {
    background-color: initial !important;
    color: #009da0;
    border: none;
}
.btn-primary.btn-fab,
.btn-primary.btn-raised {
    background-color: #009da0 !important;
    color: #fff !important;
    border-color: #009da0;
}
.btn-primary.btn-fab.active,
.btn-primary.btn-raised.active {
    background-color: #007a7c !important;
    border-color: #007a7c !important;
}
.btn-group-raised .btn-primary {
    background-color: #009da0 !important;
    color: #fff !important;
}
.btn-outline-primary {
    border: 1px solid #009da0;
    background-color: initial;
    color: #009da0;
    box-shadow: none !important;
}
.btn-outline-primary:focus {
    background-color: initial !important;
    color: #009da0 !important;
    box-shadow: transparent !important;
}
.btn-outline-primary.active {
    background-color: #009da0 !important;
    color: #fff !important;
}
.btn-outline-primary:hover {
    background-color: #008e91 !important;
    color: #fff !important;
}
.btn-outline-primary:hover svg {
    color: #fff !important;
}
.btn-outline-primary.btn-fab,
.btn-outline-primary.btn-raised {
    border: 1px solid #009da0;
    background-color: initial;
    color: #009da0;
    box-shadow: none !important;
}
.btn-outline-primary.btn-fab:focus,
.btn-outline-primary.btn-raised:focus {
    background-color: initial;
}
.btn-outline-primary.btn-fab.active,
.btn-outline-primary.btn-raised.active {
    background-color: #009da0 !important;
    color: #fff !important;
}
.btn-outline-primary.btn-fab:hover,
.btn-outline-primary.btn-raised:hover {
    background-color: #008e91 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-primary {
    background-color: #009da0;
}
.progress .progress-bar.progress-bar-primary[value]::-webkit-progress-value {
    background-color: #009da0;
}
.progress .progress-bar.progress-bar-primary[value]::-moz-progress-bar {
    background-color: #009da0;
}
.progress .progress-bar.progress-bar-primary[value]::-ms-fill {
    background-color: #009da0;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-primary .progress-bar {
        background-color: #009da0;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009da0 !important;
}
.primary.darken-1 {
    color: #009598 !important;
}
.bg-primary.bg-darken-1,
.btn-primary.btn-darken-1 {
    background-color: #009598 !important;
}
.btn-primary.btn-darken-1 {
    border-color: #008b8e !important;
}
.btn-primary.btn-darken-1:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-darken-1:active,
.btn-primary.btn-darken-1:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-darken-1 {
    border-color: #009598 !important;
    color: #009598 !important;
}
.btn-outline-primary.btn-outline-darken-1:hover {
    background-color: #009598 !important;
}
.progress-primary.progress-darken-1 {
    background-color: #009598;
}
.progress-primary.progress-darken-1[value]::-webkit-progress-value {
    background-color: #009598;
}
.progress-primary.progress-darken-1[value]::-moz-progress-bar {
    background-color: #009598;
}
.progress-primary.progress-darken-1[value]::-ms-fill {
    background-color: #009598;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-darken-1 .progress-bar {
        background-color: #009598;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009598 !important;
}
.border-primary.border-darken-1 {
    border: 1px solid #009598 !important;
}
.border-top-primary.border-top-darken-1 {
    border-top: 1px solid #009598 !important;
}
.border-bottom-primary.border-bottom-darken-1 {
    border-bottom: 1px solid #009598 !important;
}
[dir='ltr'] .border-left-primary.border-left-darken-1 {
    border-left: 1px solid #009598 !important;
}
[dir='ltr'] .border-right-primary.border-right-darken-1,
[dir='rtl'] .border-left-primary.border-left-darken-1 {
    border-right: 1px solid #009598 !important;
}
[dir='rtl'] .border-right-primary.border-right-darken-1 {
    border-left: 1px solid #009598 !important;
}
.overlay-primary.overlay-darken-1 {
    background: #009598;
    background: rgba(0, 149, 152, 0.8);
}
.primary.darken-2 {
    color: #008b8e !important;
}
.bg-primary.bg-darken-2,
.btn-primary.btn-darken-2 {
    background-color: #008b8e !important;
}
.btn-primary.btn-darken-2 {
    border-color: #008b8e !important;
}
.btn-primary.btn-darken-2:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-darken-2:active,
.btn-primary.btn-darken-2:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-darken-2 {
    border-color: #008b8e !important;
    color: #008b8e !important;
}
.btn-outline-primary.btn-outline-darken-2:hover {
    background-color: #008b8e !important;
}
.progress-primary.progress-darken-2 {
    background-color: #008b8e;
}
.progress-primary.progress-darken-2[value]::-webkit-progress-value {
    background-color: #008b8e;
}
.progress-primary.progress-darken-2[value]::-moz-progress-bar {
    background-color: #008b8e;
}
.progress-primary.progress-darken-2[value]::-ms-fill {
    background-color: #008b8e;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-darken-2 .progress-bar {
        background-color: #008b8e;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #008b8e !important;
}
.border-primary.border-darken-2 {
    border: 1px solid #008b8e !important;
}
.border-top-primary.border-top-darken-2 {
    border-top: 1px solid #008b8e !important;
}
.border-bottom-primary.border-bottom-darken-2 {
    border-bottom: 1px solid #008b8e !important;
}
[dir='ltr'] .border-left-primary.border-left-darken-2 {
    border-left: 1px solid #008b8e !important;
}
[dir='ltr'] .border-right-primary.border-right-darken-2,
[dir='rtl'] .border-left-primary.border-left-darken-2 {
    border-right: 1px solid #008b8e !important;
}
[dir='rtl'] .border-right-primary.border-right-darken-2 {
    border-left: 1px solid #008b8e !important;
}
.overlay-primary.overlay-darken-2 {
    background: #008b8e;
    background: rgba(0, 139, 142, 0.8);
}
.primary.darken-3 {
    color: #008184 !important;
}
.bg-primary.bg-darken-3 {
    background-color: #008184 !important;
}
.btn-primary.btn-darken-3,
.btn-primary.btn-darken-3:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-darken-3:active,
.btn-primary.btn-darken-3:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-darken-3 {
    border-color: #008184 !important;
    color: #008184 !important;
}
.btn-outline-primary.btn-outline-darken-3:hover {
    background-color: #008184 !important;
}
.progress-primary.progress-darken-3 {
    background-color: #008184;
}
.progress-primary.progress-darken-3[value]::-webkit-progress-value {
    background-color: #008184;
}
.progress-primary.progress-darken-3[value]::-moz-progress-bar {
    background-color: #008184;
}
.progress-primary.progress-darken-3[value]::-ms-fill {
    background-color: #008184;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-darken-3 .progress-bar {
        background-color: #008184;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #008184 !important;
}
.border-primary.border-darken-3 {
    border: 1px solid #008184 !important;
}
.border-top-primary.border-top-darken-3 {
    border-top: 1px solid #008184 !important;
}
.border-bottom-primary.border-bottom-darken-3 {
    border-bottom: 1px solid #008184 !important;
}
[dir='ltr'] .border-left-primary.border-left-darken-3 {
    border-left: 1px solid #008184 !important;
}
[dir='ltr'] .border-right-primary.border-right-darken-3,
[dir='rtl'] .border-left-primary.border-left-darken-3 {
    border-right: 1px solid #008184 !important;
}
[dir='rtl'] .border-right-primary.border-right-darken-3 {
    border-left: 1px solid #008184 !important;
}
.overlay-primary.overlay-darken-3 {
    background: #008184;
    background: rgba(0, 129, 132, 0.8);
}
.primary.darken-4 {
    color: #006f73 !important;
}
.bg-primary.bg-darken-4,
.btn-primary.btn-darken-4 {
    background-color: #006f73 !important;
}
.btn-primary.btn-darken-4 {
    border-color: #008b8e !important;
}
.btn-primary.btn-darken-4:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-darken-4:active,
.btn-primary.btn-darken-4:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-darken-4 {
    border-color: #006f73 !important;
    color: #006f73 !important;
}
.btn-outline-primary.btn-outline-darken-4:hover {
    background-color: #006f73 !important;
}
.progress-primary.progress-darken-4 {
    background-color: #006f73;
}
.progress-primary.progress-darken-4[value]::-webkit-progress-value {
    background-color: #006f73;
}
.progress-primary.progress-darken-4[value]::-moz-progress-bar {
    background-color: #006f73;
}
.progress-primary.progress-darken-4[value]::-ms-fill {
    background-color: #006f73;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-darken-4 .progress-bar {
        background-color: #006f73;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006f73 !important;
}
.border-primary.border-darken-4 {
    border: 1px solid #006f73 !important;
}
.border-top-primary.border-top-darken-4 {
    border-top: 1px solid #006f73 !important;
}
.border-bottom-primary.border-bottom-darken-4 {
    border-bottom: 1px solid #006f73 !important;
}
[dir='ltr'] .border-left-primary.border-left-darken-4 {
    border-left: 1px solid #006f73 !important;
}
[dir='ltr'] .border-right-primary.border-right-darken-4,
[dir='rtl'] .border-left-primary.border-left-darken-4 {
    border-right: 1px solid #006f73 !important;
}
[dir='rtl'] .border-right-primary.border-right-darken-4 {
    border-left: 1px solid #006f73 !important;
}
.overlay-primary.overlay-darken-4 {
    background: #006f73;
    background: rgba(0, 111, 115, 0.8);
}
.primary.accent-1 {
    color: #a1fbff !important;
}
.bg-primary.bg-accent-1,
.btn-primary.btn-accent-1 {
    background-color: #a1fbff !important;
}
.btn-primary.btn-accent-1 {
    border-color: #008b8e !important;
}
.btn-primary.btn-accent-1:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-accent-1:active,
.btn-primary.btn-accent-1:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-accent-1 {
    border-color: #a1fbff !important;
    color: #a1fbff !important;
}
.btn-outline-primary.btn-outline-accent-1:hover {
    background-color: #a1fbff !important;
}
.progress-primary.progress-accent-1 {
    background-color: #a1fbff;
}
.progress-primary.progress-accent-1[value]::-webkit-progress-value {
    background-color: #a1fbff;
}
.progress-primary.progress-accent-1[value]::-moz-progress-bar {
    background-color: #a1fbff;
}
.progress-primary.progress-accent-1[value]::-ms-fill {
    background-color: #a1fbff;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-accent-1 .progress-bar {
        background-color: #a1fbff;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a1fbff !important;
}
.border-primary.border-accent-1 {
    border: 1px solid #a1fbff !important;
}
.border-top-primary.border-top-accent-1 {
    border-top: 1px solid #a1fbff !important;
}
.border-bottom-primary.border-bottom-accent-1 {
    border-bottom: 1px solid #a1fbff !important;
}
[dir='ltr'] .border-left-primary.border-left-accent-1 {
    border-left: 1px solid #a1fbff !important;
}
[dir='ltr'] .border-right-primary.border-right-accent-1,
[dir='rtl'] .border-left-primary.border-left-accent-1 {
    border-right: 1px solid #a1fbff !important;
}
[dir='rtl'] .border-right-primary.border-right-accent-1 {
    border-left: 1px solid #a1fbff !important;
}
.overlay-primary.overlay-accent-1 {
    background: #a1fbff;
    background: rgba(161, 251, 255, 0.8);
}
.primary.accent-2 {
    color: #6ef9ff !important;
}
.bg-primary.bg-accent-2,
.btn-primary.btn-accent-2 {
    background-color: #6ef9ff !important;
}
.btn-primary.btn-accent-2 {
    border-color: #008b8e !important;
}
.btn-primary.btn-accent-2:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-accent-2:active,
.btn-primary.btn-accent-2:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-accent-2 {
    border-color: #6ef9ff !important;
    color: #6ef9ff !important;
}
.btn-outline-primary.btn-outline-accent-2:hover {
    background-color: #6ef9ff !important;
}
.progress-primary.progress-accent-2 {
    background-color: #6ef9ff;
}
.progress-primary.progress-accent-2[value]::-webkit-progress-value {
    background-color: #6ef9ff;
}
.progress-primary.progress-accent-2[value]::-moz-progress-bar {
    background-color: #6ef9ff;
}
.progress-primary.progress-accent-2[value]::-ms-fill {
    background-color: #6ef9ff;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-accent-2 .progress-bar {
        background-color: #6ef9ff;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6ef9ff !important;
}
.border-primary.border-accent-2 {
    border: 1px solid #6ef9ff !important;
}
.border-top-primary.border-top-accent-2 {
    border-top: 1px solid #6ef9ff !important;
}
.border-bottom-primary.border-bottom-accent-2 {
    border-bottom: 1px solid #6ef9ff !important;
}
[dir='ltr'] .border-left-primary.border-left-accent-2 {
    border-left: 1px solid #6ef9ff !important;
}
[dir='ltr'] .border-right-primary.border-right-accent-2,
[dir='rtl'] .border-left-primary.border-left-accent-2 {
    border-right: 1px solid #6ef9ff !important;
}
[dir='rtl'] .border-right-primary.border-right-accent-2 {
    border-left: 1px solid #6ef9ff !important;
}
.overlay-primary.overlay-accent-2 {
    background: #6ef9ff;
    background: rgba(110, 249, 255, 0.8);
}
.primary.accent-3 {
    color: #3bf7ff !important;
}
.bg-primary.bg-accent-3,
.btn-primary.btn-accent-3 {
    background-color: #3bf7ff !important;
}
.btn-primary.btn-accent-3 {
    border-color: #008b8e !important;
}
.btn-primary.btn-accent-3:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-accent-3:active,
.btn-primary.btn-accent-3:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-accent-3 {
    border-color: #3bf7ff !important;
    color: #3bf7ff !important;
}
.btn-outline-primary.btn-outline-accent-3:hover {
    background-color: #3bf7ff !important;
}
.progress-primary.progress-accent-3 {
    background-color: #3bf7ff;
}
.progress-primary.progress-accent-3[value]::-webkit-progress-value {
    background-color: #3bf7ff;
}
.progress-primary.progress-accent-3[value]::-moz-progress-bar {
    background-color: #3bf7ff;
}
.progress-primary.progress-accent-3[value]::-ms-fill {
    background-color: #3bf7ff;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-accent-3 .progress-bar {
        background-color: #3bf7ff;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3bf7ff !important;
}
.border-primary.border-accent-3 {
    border: 1px solid #3bf7ff !important;
}
.border-top-primary.border-top-accent-3 {
    border-top: 1px solid #3bf7ff !important;
}
.border-bottom-primary.border-bottom-accent-3 {
    border-bottom: 1px solid #3bf7ff !important;
}
[dir='ltr'] .border-left-primary.border-left-accent-3 {
    border-left: 1px solid #3bf7ff !important;
}
[dir='ltr'] .border-right-primary.border-right-accent-3,
[dir='rtl'] .border-left-primary.border-left-accent-3 {
    border-right: 1px solid #3bf7ff !important;
}
[dir='rtl'] .border-right-primary.border-right-accent-3 {
    border-left: 1px solid #3bf7ff !important;
}
.overlay-primary.overlay-accent-3 {
    background: #3bf7ff;
    background: rgba(59, 247, 255, 0.8);
}
.primary.accent-4 {
    color: #22f6ff !important;
}
.bg-primary.bg-accent-4,
.btn-primary.btn-accent-4 {
    background-color: #22f6ff !important;
}
.btn-primary.btn-accent-4 {
    border-color: #008b8e !important;
}
.btn-primary.btn-accent-4:hover {
    border-color: #008b8e !important;
    background-color: #008184 !important;
}
.btn-primary.btn-accent-4:active,
.btn-primary.btn-accent-4:focus {
    border-color: #008184 !important;
    background-color: #006f73 !important;
}
.btn-outline-primary.btn-outline-accent-4 {
    border-color: #22f6ff !important;
    color: #22f6ff !important;
}
.btn-outline-primary.btn-outline-accent-4:hover {
    background-color: #22f6ff !important;
}
.progress-primary.progress-accent-4 {
    background-color: #22f6ff;
}
.progress-primary.progress-accent-4[value]::-webkit-progress-value {
    background-color: #22f6ff;
}
.progress-primary.progress-accent-4[value]::-moz-progress-bar {
    background-color: #22f6ff;
}
.progress-primary.progress-accent-4[value]::-ms-fill {
    background-color: #22f6ff;
}
@media screen and (min-width: 0\0) {
    .progress-primary.progress-accent-4 .progress-bar {
        background-color: #22f6ff;
    }
}
input:focus ~ .bg-primary {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #22f6ff !important;
}
.border-primary.border-accent-4 {
    border: 1px solid #22f6ff !important;
}
.border-top-primary.border-top-accent-4 {
    border-top: 1px solid #22f6ff !important;
}
.border-bottom-primary.border-bottom-accent-4 {
    border-bottom: 1px solid #22f6ff !important;
}
[dir='ltr'] .border-left-primary.border-left-accent-4 {
    border-left: 1px solid #22f6ff !important;
}
[dir='ltr'] .border-right-primary.border-right-accent-4,
[dir='rtl'] .border-left-primary.border-left-accent-4 {
    border-right: 1px solid #22f6ff !important;
}
[dir='rtl'] .border-right-primary.border-right-accent-4 {
    border-left: 1px solid #22f6ff !important;
}
.overlay-primary.overlay-accent-4 {
    background: #22f6ff;
    background: rgba(34, 246, 255, 0.8);
}
.success.lighten-5 {
    color: #e2f8f0 !important;
}
.bg-success.bg-lighten-5,
.btn-success.btn-lighten-5 {
    background-color: #e2f8f0 !important;
}
.btn-success.btn-lighten-5 {
    border-color: #08b46b !important;
}
.btn-success.btn-lighten-5:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-lighten-5:active,
.btn-success.btn-lighten-5:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-lighten-5 {
    border-color: #e2f8f0 !important;
    color: #e2f8f0 !important;
}
.btn-outline-success.btn-outline-lighten-5:hover {
    background-color: #e2f8f0 !important;
}
.progress-success.progress-lighten-5 {
    background-color: #e2f8f0;
}
.progress-success.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e2f8f0;
}
.progress-success.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e2f8f0;
}
.progress-success.progress-lighten-5[value]::-ms-fill {
    background-color: #e2f8f0;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-lighten-5 .progress-bar {
        background-color: #e2f8f0;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e2f8f0 !important;
}
.border-success.border-lighten-5 {
    border: 1px solid #e2f8f0 !important;
}
.border-top-success.border-top-lighten-5 {
    border-top: 1px solid #e2f8f0 !important;
}
.border-bottom-success.border-bottom-lighten-5 {
    border-bottom: 1px solid #e2f8f0 !important;
}
[dir='ltr'] .border-left-success.border-left-lighten-5 {
    border-left: 1px solid #e2f8f0 !important;
}
[dir='ltr'] .border-right-success.border-right-lighten-5,
[dir='rtl'] .border-left-success.border-left-lighten-5 {
    border-right: 1px solid #e2f8f0 !important;
}
[dir='rtl'] .border-right-success.border-right-lighten-5 {
    border-left: 1px solid #e2f8f0 !important;
}
.overlay-success.overlay-lighten-5 {
    background: #e2f8f0;
    background: rgba(226, 248, 240, 0.8);
}
.success.lighten-4 {
    color: #b6edd8 !important;
}
.bg-success.bg-lighten-4,
.btn-success.btn-lighten-4 {
    background-color: #b6edd8 !important;
}
.btn-success.btn-lighten-4 {
    border-color: #08b46b !important;
}
.btn-success.btn-lighten-4:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-lighten-4:active,
.btn-success.btn-lighten-4:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-lighten-4 {
    border-color: #b6edd8 !important;
    color: #b6edd8 !important;
}
.btn-outline-success.btn-outline-lighten-4:hover {
    background-color: #b6edd8 !important;
}
.progress-success.progress-lighten-4 {
    background-color: #b6edd8;
}
.progress-success.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b6edd8;
}
.progress-success.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b6edd8;
}
.progress-success.progress-lighten-4[value]::-ms-fill {
    background-color: #b6edd8;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-lighten-4 .progress-bar {
        background-color: #b6edd8;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b6edd8 !important;
}
.border-success.border-lighten-4 {
    border: 1px solid #b6edd8 !important;
}
.border-top-success.border-top-lighten-4 {
    border-top: 1px solid #b6edd8 !important;
}
.border-bottom-success.border-bottom-lighten-4 {
    border-bottom: 1px solid #b6edd8 !important;
}
[dir='ltr'] .border-left-success.border-left-lighten-4 {
    border-left: 1px solid #b6edd8 !important;
}
[dir='ltr'] .border-right-success.border-right-lighten-4,
[dir='rtl'] .border-left-success.border-left-lighten-4 {
    border-right: 1px solid #b6edd8 !important;
}
[dir='rtl'] .border-right-success.border-right-lighten-4 {
    border-left: 1px solid #b6edd8 !important;
}
.overlay-success.overlay-lighten-4 {
    background: #b6edd8;
    background: rgba(182, 237, 216, 0.8);
}
.success.lighten-3 {
    color: #86e1bf !important;
}
.bg-success.bg-lighten-3,
.btn-success.btn-lighten-3 {
    background-color: #86e1bf !important;
}
.btn-success.btn-lighten-3 {
    border-color: #08b46b !important;
}
.btn-success.btn-lighten-3:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-lighten-3:active,
.btn-success.btn-lighten-3:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-lighten-3 {
    border-color: #86e1bf !important;
    color: #86e1bf !important;
}
.btn-outline-success.btn-outline-lighten-3:hover {
    background-color: #86e1bf !important;
}
.progress-success.progress-lighten-3 {
    background-color: #86e1bf;
}
.progress-success.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #86e1bf;
}
.progress-success.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #86e1bf;
}
.progress-success.progress-lighten-3[value]::-ms-fill {
    background-color: #86e1bf;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-lighten-3 .progress-bar {
        background-color: #86e1bf;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #86e1bf !important;
}
.border-success.border-lighten-3 {
    border: 1px solid #86e1bf !important;
}
.border-top-success.border-top-lighten-3 {
    border-top: 1px solid #86e1bf !important;
}
.border-bottom-success.border-bottom-lighten-3 {
    border-bottom: 1px solid #86e1bf !important;
}
[dir='ltr'] .border-left-success.border-left-lighten-3 {
    border-left: 1px solid #86e1bf !important;
}
[dir='ltr'] .border-right-success.border-right-lighten-3,
[dir='rtl'] .border-left-success.border-left-lighten-3 {
    border-right: 1px solid #86e1bf !important;
}
[dir='rtl'] .border-right-success.border-right-lighten-3 {
    border-left: 1px solid #86e1bf !important;
}
.overlay-success.overlay-lighten-3 {
    background: #86e1bf;
    background: rgba(134, 225, 191, 0.8);
}
.success.lighten-2 {
    color: #55d4a5 !important;
}
.bg-success.bg-lighten-2,
.btn-success.btn-lighten-2 {
    background-color: #55d4a5 !important;
}
.btn-success.btn-lighten-2 {
    border-color: #08b46b !important;
}
.btn-success.btn-lighten-2:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-lighten-2:active,
.btn-success.btn-lighten-2:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-lighten-2 {
    border-color: #55d4a5 !important;
    color: #55d4a5 !important;
}
.btn-outline-success.btn-outline-lighten-2:hover {
    background-color: #55d4a5 !important;
}
.progress-success.progress-lighten-2 {
    background-color: #55d4a5;
}
.progress-success.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #55d4a5;
}
.progress-success.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #55d4a5;
}
.progress-success.progress-lighten-2[value]::-ms-fill {
    background-color: #55d4a5;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-lighten-2 .progress-bar {
        background-color: #55d4a5;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #55d4a5 !important;
}
.border-success.border-lighten-2 {
    border: 1px solid #55d4a5 !important;
}
.border-top-success.border-top-lighten-2 {
    border-top: 1px solid #55d4a5 !important;
}
.border-bottom-success.border-bottom-lighten-2 {
    border-bottom: 1px solid #55d4a5 !important;
}
[dir='ltr'] .border-left-success.border-left-lighten-2 {
    border-left: 1px solid #55d4a5 !important;
}
[dir='ltr'] .border-right-success.border-right-lighten-2,
[dir='rtl'] .border-left-success.border-left-lighten-2 {
    border-right: 1px solid #55d4a5 !important;
}
[dir='rtl'] .border-right-success.border-right-lighten-2 {
    border-left: 1px solid #55d4a5 !important;
}
.overlay-success.overlay-lighten-2 {
    background: #55d4a5;
    background: rgba(85, 212, 165, 0.8);
}
.success.lighten-1 {
    color: #30cb91 !important;
}
.bg-success.bg-lighten-1,
.btn-success.btn-lighten-1 {
    background-color: #30cb91 !important;
}
.btn-success.btn-lighten-1 {
    border-color: #08b46b !important;
}
.btn-success.btn-lighten-1:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-lighten-1:active,
.btn-success.btn-lighten-1:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-lighten-1 {
    border-color: #30cb91 !important;
    color: #30cb91 !important;
}
.btn-outline-success.btn-outline-lighten-1:hover {
    background-color: #30cb91 !important;
}
.progress-success.progress-lighten-1 {
    background-color: #30cb91;
}
.progress-success.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #30cb91;
}
.progress-success.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #30cb91;
}
.progress-success.progress-lighten-1[value]::-ms-fill {
    background-color: #30cb91;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-lighten-1 .progress-bar {
        background-color: #30cb91;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #30cb91 !important;
}
.border-success.border-lighten-1 {
    border: 1px solid #30cb91 !important;
}
.border-top-success.border-top-lighten-1 {
    border-top: 1px solid #30cb91 !important;
}
.border-bottom-success.border-bottom-lighten-1 {
    border-bottom: 1px solid #30cb91 !important;
}
[dir='ltr'] .border-left-success.border-left-lighten-1 {
    border-left: 1px solid #30cb91 !important;
}
[dir='ltr'] .border-right-success.border-right-lighten-1,
[dir='rtl'] .border-left-success.border-left-lighten-1 {
    border-right: 1px solid #30cb91 !important;
}
[dir='rtl'] .border-right-success.border-right-lighten-1 {
    border-left: 1px solid #30cb91 !important;
}
.overlay-success.overlay-lighten-1 {
    background: #30cb91;
    background: rgba(48, 203, 145, 0.8);
}
.success {
    color: #00a887 !important;
}
.bg-success {
    background-color: #00a887 !important;
}
.bg-success .card-footer,
.bg-success .card-header {
    background-color: initial;
}
.toast-success {
    background-color: #00a887;
}
.alert-success {
    border-color: #00a887 !important;
    background-color: #0ee494 !important;
    color: #021a11 !important;
}
.alert-success .alert-link {
    color: #000 !important;
}
.border-success {
    border-color: #00a887;
}
.border-top-success {
    border-top-color: #00a887;
}
.border-bottom-success {
    border-bottom-color: #00a887;
}
[dir='ltr'] .border-left-success {
    border-left-color: #00a887;
}
[dir='ltr'] .border-right-success,
[dir='rtl'] .border-left-success {
    border-right-color: #00a887;
}
[dir='rtl'] .border-right-success {
    border-left-color: #00a887;
}
.badge-success {
    background-color: #00a887;
}
.panel-success {
    border-color: #00a887;
}
.panel-success .panel-heading {
    color: #fff;
    border-color: #00a887;
    background-color: #0dda8e;
}
.bg-success.tag-glow,
.border-success.tag-glow {
    box-shadow: 0 0 10px #00a887;
}
.overlay-success {
    background: #00a887;
    background: rgba(12, 194, 126, 0.8);
}
.card.card-outline-success {
    border: 1px solid #00a887;
    background-color: initial;
}
.card.card-outline-success .card-footer,
.card.card-outline-success .card-header {
    background-color: initial;
}
.btn-success.btn-flat {
    background-color: initial !important;
    color: #00a887;
    border: none;
}
.btn-success.btn-fab,
.btn-success.btn-raised {
    background-color: #00a887 !important;
    color: #fff !important;
    border-color: #00a887;
}
.btn-success.btn-fab.active,
.btn-success.btn-raised.active {
    background-color: #0aa068 !important;
    border-color: #0aa068 !important;
}
.btn-group-raised .btn-success {
    background-color: #00a887 !important;
    color: #fff !important;
}
.btn-outline-success {
    border: 1px solid #00a887;
    background-color: initial;
    color: #00a887;
    box-shadow: none !important;
}
.btn-outline-success:focus {
    background-color: initial !important;
    color: #00a887 !important;
    box-shadow: transparent !important;
}
.btn-outline-success.active {
    background-color: #00a887 !important;
    color: #fff !important;
}
.btn-outline-success:hover {
    background-color: #0bb475 !important;
    color: #fff !important;
}
.btn-outline-success:hover svg {
    color: #fff !important;
}
.btn-outline-success.btn-fab,
.btn-outline-success.btn-raised {
    border: 1px solid #00a887;
    background-color: initial;
    color: #00a887;
    box-shadow: none !important;
}
.btn-outline-success.btn-fab:focus,
.btn-outline-success.btn-raised:focus {
    background-color: initial;
}
.btn-outline-success.btn-fab.active,
.btn-outline-success.btn-raised.active {
    background-color: #00a887 !important;
    color: #fff !important;
}
.btn-outline-success.btn-fab:hover,
.btn-outline-success.btn-raised:hover {
    background-color: #0bb475 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-success {
    background-color: #00a887;
}
.progress .progress-bar.progress-bar-success[value]::-webkit-progress-value {
    background-color: #00a887;
}
.progress .progress-bar.progress-bar-success[value]::-moz-progress-bar {
    background-color: #00a887;
}
.progress .progress-bar.progress-bar-success[value]::-ms-fill {
    background-color: #00a887;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-success .progress-bar {
        background-color: #00a887;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00a887 !important;
}
.success.darken-1 {
    color: #0abc76 !important;
}
.bg-success.bg-darken-1,
.btn-success.btn-darken-1 {
    background-color: #0abc76 !important;
}
.btn-success.btn-darken-1 {
    border-color: #08b46b !important;
}
.btn-success.btn-darken-1:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-darken-1:active,
.btn-success.btn-darken-1:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-darken-1 {
    border-color: #0abc76 !important;
    color: #0abc76 !important;
}
.btn-outline-success.btn-outline-darken-1:hover {
    background-color: #0abc76 !important;
}
.progress-success.progress-darken-1 {
    background-color: #0abc76;
}
.progress-success.progress-darken-1[value]::-webkit-progress-value {
    background-color: #0abc76;
}
.progress-success.progress-darken-1[value]::-moz-progress-bar {
    background-color: #0abc76;
}
.progress-success.progress-darken-1[value]::-ms-fill {
    background-color: #0abc76;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-darken-1 .progress-bar {
        background-color: #0abc76;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0abc76 !important;
}
.border-success.border-darken-1 {
    border: 1px solid #0abc76 !important;
}
.border-top-success.border-top-darken-1 {
    border-top: 1px solid #0abc76 !important;
}
.border-bottom-success.border-bottom-darken-1 {
    border-bottom: 1px solid #0abc76 !important;
}
[dir='ltr'] .border-left-success.border-left-darken-1 {
    border-left: 1px solid #0abc76 !important;
}
[dir='ltr'] .border-right-success.border-right-darken-1,
[dir='rtl'] .border-left-success.border-left-darken-1 {
    border-right: 1px solid #0abc76 !important;
}
[dir='rtl'] .border-right-success.border-right-darken-1 {
    border-left: 1px solid #0abc76 !important;
}
.overlay-success.overlay-darken-1 {
    background: #0abc76;
    background: rgba(10, 188, 118, 0.8);
}
.success.darken-2 {
    color: #08b46b !important;
}
.bg-success.bg-darken-2,
.btn-success.btn-darken-2 {
    background-color: #08b46b !important;
}
.btn-success.btn-darken-2 {
    border-color: #08b46b !important;
}
.btn-success.btn-darken-2:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-darken-2:active,
.btn-success.btn-darken-2:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-darken-2 {
    border-color: #08b46b !important;
    color: #08b46b !important;
}
.btn-outline-success.btn-outline-darken-2:hover {
    background-color: #08b46b !important;
}
.progress-success.progress-darken-2 {
    background-color: #08b46b;
}
.progress-success.progress-darken-2[value]::-webkit-progress-value {
    background-color: #08b46b;
}
.progress-success.progress-darken-2[value]::-moz-progress-bar {
    background-color: #08b46b;
}
.progress-success.progress-darken-2[value]::-ms-fill {
    background-color: #08b46b;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-darken-2 .progress-bar {
        background-color: #08b46b;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #08b46b !important;
}
.border-success.border-darken-2 {
    border: 1px solid #08b46b !important;
}
.border-top-success.border-top-darken-2 {
    border-top: 1px solid #08b46b !important;
}
.border-bottom-success.border-bottom-darken-2 {
    border-bottom: 1px solid #08b46b !important;
}
[dir='ltr'] .border-left-success.border-left-darken-2 {
    border-left: 1px solid #08b46b !important;
}
[dir='ltr'] .border-right-success.border-right-darken-2,
[dir='rtl'] .border-left-success.border-left-darken-2 {
    border-right: 1px solid #08b46b !important;
}
[dir='rtl'] .border-right-success.border-right-darken-2 {
    border-left: 1px solid #08b46b !important;
}
.overlay-success.overlay-darken-2 {
    background: #08b46b;
    background: rgba(8, 180, 107, 0.8);
}
.success.darken-3 {
    color: #06ac61 !important;
}
.bg-success.bg-darken-3 {
    background-color: #06ac61 !important;
}
.btn-success.btn-darken-3,
.btn-success.btn-darken-3:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-darken-3:active,
.btn-success.btn-darken-3:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-darken-3 {
    border-color: #06ac61 !important;
    color: #06ac61 !important;
}
.btn-outline-success.btn-outline-darken-3:hover {
    background-color: #06ac61 !important;
}
.progress-success.progress-darken-3 {
    background-color: #06ac61;
}
.progress-success.progress-darken-3[value]::-webkit-progress-value {
    background-color: #06ac61;
}
.progress-success.progress-darken-3[value]::-moz-progress-bar {
    background-color: #06ac61;
}
.progress-success.progress-darken-3[value]::-ms-fill {
    background-color: #06ac61;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-darken-3 .progress-bar {
        background-color: #06ac61;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #06ac61 !important;
}
.border-success.border-darken-3 {
    border: 1px solid #06ac61 !important;
}
.border-top-success.border-top-darken-3 {
    border-top: 1px solid #06ac61 !important;
}
.border-bottom-success.border-bottom-darken-3 {
    border-bottom: 1px solid #06ac61 !important;
}
[dir='ltr'] .border-left-success.border-left-darken-3 {
    border-left: 1px solid #06ac61 !important;
}
[dir='ltr'] .border-right-success.border-right-darken-3,
[dir='rtl'] .border-left-success.border-left-darken-3 {
    border-right: 1px solid #06ac61 !important;
}
[dir='rtl'] .border-right-success.border-right-darken-3 {
    border-left: 1px solid #06ac61 !important;
}
.overlay-success.overlay-darken-3 {
    background: #06ac61;
    background: rgba(6, 172, 97, 0.8);
}
.success.darken-4 {
    color: #039f4e !important;
}
.bg-success.bg-darken-4,
.btn-success.btn-darken-4 {
    background-color: #039f4e !important;
}
.btn-success.btn-darken-4 {
    border-color: #08b46b !important;
}
.btn-success.btn-darken-4:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-darken-4:active,
.btn-success.btn-darken-4:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-darken-4 {
    border-color: #039f4e !important;
    color: #039f4e !important;
}
.btn-outline-success.btn-outline-darken-4:hover {
    background-color: #039f4e !important;
}
.progress-success.progress-darken-4 {
    background-color: #039f4e;
}
.progress-success.progress-darken-4[value]::-webkit-progress-value {
    background-color: #039f4e;
}
.progress-success.progress-darken-4[value]::-moz-progress-bar {
    background-color: #039f4e;
}
.progress-success.progress-darken-4[value]::-ms-fill {
    background-color: #039f4e;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-darken-4 .progress-bar {
        background-color: #039f4e;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #039f4e !important;
}
.border-success.border-darken-4 {
    border: 1px solid #039f4e !important;
}
.border-top-success.border-top-darken-4 {
    border-top: 1px solid #039f4e !important;
}
.border-bottom-success.border-bottom-darken-4 {
    border-bottom: 1px solid #039f4e !important;
}
.overlay-success.overlay-darken-4 {
    background: #039f4e;
    background: rgba(3, 159, 78, 0.8);
}
.success.accent-1 {
    color: #c9ffe0 !important;
}
.bg-success.bg-accent-1,
.btn-success.btn-accent-1 {
    background-color: #c9ffe0 !important;
}
.btn-success.btn-accent-1 {
    border-color: #08b46b !important;
}
.btn-success.btn-accent-1:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-accent-1:active,
.btn-success.btn-accent-1:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-accent-1 {
    border-color: #c9ffe0 !important;
    color: #c9ffe0 !important;
}
.btn-outline-success.btn-outline-accent-1:hover {
    background-color: #c9ffe0 !important;
}
.progress-success.progress-accent-1 {
    background-color: #c9ffe0;
}
.progress-success.progress-accent-1[value]::-webkit-progress-value {
    background-color: #c9ffe0;
}
.progress-success.progress-accent-1[value]::-moz-progress-bar {
    background-color: #c9ffe0;
}
.progress-success.progress-accent-1[value]::-ms-fill {
    background-color: #c9ffe0;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-accent-1 .progress-bar {
        background-color: #c9ffe0;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c9ffe0 !important;
}
.border-success.border-accent-1 {
    border: 1px solid #c9ffe0 !important;
}
.border-top-success.border-top-accent-1 {
    border-top: 1px solid #c9ffe0 !important;
}
.border-bottom-success.border-bottom-accent-1 {
    border-bottom: 1px solid #c9ffe0 !important;
}
.overlay-success.overlay-accent-1 {
    background: #c9ffe0;
    background: rgba(201, 255, 224, 0.8);
}
.success.accent-2 {
    color: #96ffc3 !important;
}
.bg-success.bg-accent-2,
.btn-success.btn-accent-2 {
    background-color: #96ffc3 !important;
}
.btn-success.btn-accent-2 {
    border-color: #08b46b !important;
}
.btn-success.btn-accent-2:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-accent-2:active,
.btn-success.btn-accent-2:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-accent-2 {
    border-color: #96ffc3 !important;
    color: #96ffc3 !important;
}
.btn-outline-success.btn-outline-accent-2:hover {
    background-color: #96ffc3 !important;
}
.progress-success.progress-accent-2 {
    background-color: #96ffc3;
}
.progress-success.progress-accent-2[value]::-webkit-progress-value {
    background-color: #96ffc3;
}
.progress-success.progress-accent-2[value]::-moz-progress-bar {
    background-color: #96ffc3;
}
.progress-success.progress-accent-2[value]::-ms-fill {
    background-color: #96ffc3;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-accent-2 .progress-bar {
        background-color: #96ffc3;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #96ffc3 !important;
}
.border-success.border-accent-2 {
    border: 1px solid #96ffc3 !important;
}
.border-top-success.border-top-accent-2 {
    border-top: 1px solid #96ffc3 !important;
}
.border-bottom-success.border-bottom-accent-2 {
    border-bottom: 1px solid #96ffc3 !important;
}
.overlay-success.overlay-accent-2 {
    background: #96ffc3;
    background: rgba(150, 255, 195, 0.8);
}
.success.accent-3 {
    color: #63ffa5 !important;
}
.bg-success.bg-accent-3,
.btn-success.btn-accent-3 {
    background-color: #63ffa5 !important;
}
.btn-success.btn-accent-3 {
    border-color: #08b46b !important;
}
.btn-success.btn-accent-3:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-accent-3:active,
.btn-success.btn-accent-3:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-accent-3 {
    border-color: #63ffa5 !important;
    color: #63ffa5 !important;
}
.btn-outline-success.btn-outline-accent-3:hover {
    background-color: #63ffa5 !important;
}
.progress-success.progress-accent-3 {
    background-color: #63ffa5;
}
.progress-success.progress-accent-3[value]::-webkit-progress-value {
    background-color: #63ffa5;
}
.progress-success.progress-accent-3[value]::-moz-progress-bar {
    background-color: #63ffa5;
}
.progress-success.progress-accent-3[value]::-ms-fill {
    background-color: #63ffa5;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-accent-3 .progress-bar {
        background-color: #63ffa5;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #63ffa5 !important;
}
.border-success.border-accent-3 {
    border: 1px solid #63ffa5 !important;
}
.border-top-success.border-top-accent-3 {
    border-top: 1px solid #63ffa5 !important;
}
.border-bottom-success.border-bottom-accent-3 {
    border-bottom: 1px solid #63ffa5 !important;
}
.overlay-success.overlay-accent-3 {
    background: #63ffa5;
    background: rgba(99, 255, 165, 0.8);
}
.success.accent-4 {
    color: #4aff96 !important;
}
.bg-success.bg-accent-4,
.btn-success.btn-accent-4 {
    background-color: #4aff96 !important;
}
.btn-success.btn-accent-4 {
    border-color: #08b46b !important;
}
.btn-success.btn-accent-4:hover {
    border-color: #08b46b !important;
    background-color: #06ac61 !important;
}
.btn-success.btn-accent-4:active,
.btn-success.btn-accent-4:focus {
    border-color: #06ac61 !important;
    background-color: #039f4e !important;
}
.btn-outline-success.btn-outline-accent-4 {
    border-color: #4aff96 !important;
    color: #4aff96 !important;
}
.btn-outline-success.btn-outline-accent-4:hover {
    background-color: #4aff96 !important;
}
.progress-success.progress-accent-4 {
    background-color: #4aff96;
}
.progress-success.progress-accent-4[value]::-webkit-progress-value {
    background-color: #4aff96;
}
.progress-success.progress-accent-4[value]::-moz-progress-bar {
    background-color: #4aff96;
}
.progress-success.progress-accent-4[value]::-ms-fill {
    background-color: #4aff96;
}
@media screen and (min-width: 0\0) {
    .progress-success.progress-accent-4 .progress-bar {
        background-color: #4aff96;
    }
}
input:focus ~ .bg-success {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4aff96 !important;
}
.border-success.border-accent-4 {
    border: 1px solid #4aff96 !important;
}
.border-top-success.border-top-accent-4 {
    border-top: 1px solid #4aff96 !important;
}
.border-bottom-success.border-bottom-accent-4 {
    border-bottom: 1px solid #4aff96 !important;
}
.overlay-success.overlay-accent-4 {
    background: #4aff96;
    background: rgba(74, 255, 150, 0.8);
}
.info.lighten-5 {
    color: #e4f7fa !important;
}
.bg-info.bg-lighten-5,
.btn-info.btn-lighten-5 {
    background-color: #e4f7fa !important;
}
.btn-info.btn-lighten-5,
.btn-info.btn-lighten-5:hover {
    border-color: #14adce !important;
}
.btn-info.btn-lighten-5:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-lighten-5:active,
.btn-info.btn-lighten-5:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-lighten-5 {
    border-color: #e4f7fa !important;
    color: #e4f7fa !important;
}
.btn-outline-info.btn-outline-lighten-5:hover {
    background-color: #e4f7fa !important;
}
.progress-info.progress-lighten-5 {
    background-color: #e4f7fa;
}
.progress-info.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e4f7fa;
}
.progress-info.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e4f7fa;
}
.progress-info.progress-lighten-5[value]::-ms-fill {
    background-color: #e4f7fa;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-lighten-5 .progress-bar {
        background-color: #e4f7fa;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e4f7fa !important;
}
.border-info.border-lighten-5 {
    border: 1px solid #e4f7fa !important;
}
.border-top-info.border-top-lighten-5 {
    border-top: 1px solid #e4f7fa !important;
}
.border-bottom-info.border-bottom-lighten-5 {
    border-bottom: 1px solid #e4f7fa !important;
}
.overlay-info.overlay-lighten-5 {
    background: #e4f7fa;
    background: rgba(228, 247, 250, 0.8);
}
.info.lighten-4 {
    color: #bbebf3 !important;
}
.bg-info.bg-lighten-4,
.btn-info.btn-lighten-4 {
    background-color: #bbebf3 !important;
}
.btn-info.btn-lighten-4,
.btn-info.btn-lighten-4:hover {
    border-color: #14adce !important;
}
.btn-info.btn-lighten-4:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-lighten-4:active,
.btn-info.btn-lighten-4:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-lighten-4 {
    border-color: #bbebf3 !important;
    color: #bbebf3 !important;
}
.btn-outline-info.btn-outline-lighten-4:hover {
    background-color: #bbebf3 !important;
}
.progress-info.progress-lighten-4 {
    background-color: #bbebf3;
}
.progress-info.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #bbebf3;
}
.progress-info.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #bbebf3;
}
.progress-info.progress-lighten-4[value]::-ms-fill {
    background-color: #bbebf3;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-lighten-4 .progress-bar {
        background-color: #bbebf3;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bbebf3 !important;
}
.border-info.border-lighten-4 {
    border: 1px solid #bbebf3 !important;
}
.border-top-info.border-top-lighten-4 {
    border-top: 1px solid #bbebf3 !important;
}
.border-bottom-info.border-bottom-lighten-4 {
    border-bottom: 1px solid #bbebf3 !important;
}
.overlay-info.overlay-lighten-4 {
    background: #bbebf3;
    background: rgba(187, 235, 243, 0.8);
}
.info.lighten-3 {
    color: #8edeec !important;
}
.bg-info.bg-lighten-3,
.btn-info.btn-lighten-3 {
    background-color: #8edeec !important;
}
.btn-info.btn-lighten-3,
.btn-info.btn-lighten-3:hover {
    border-color: #14adce !important;
}
.btn-info.btn-lighten-3:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-lighten-3:active,
.btn-info.btn-lighten-3:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-lighten-3 {
    border-color: #8edeec !important;
    color: #8edeec !important;
}
.btn-outline-info.btn-outline-lighten-3:hover {
    background-color: #8edeec !important;
}
.progress-info.progress-lighten-3 {
    background-color: #8edeec;
}
.progress-info.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #8edeec;
}
.progress-info.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #8edeec;
}
.progress-info.progress-lighten-3[value]::-ms-fill {
    background-color: #8edeec;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-lighten-3 .progress-bar {
        background-color: #8edeec;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8edeec !important;
}
.border-info.border-lighten-3 {
    border: 1px solid #8edeec !important;
}
.border-top-info.border-top-lighten-3 {
    border-top: 1px solid #8edeec !important;
}
.border-bottom-info.border-bottom-lighten-3 {
    border-bottom: 1px solid #8edeec !important;
}
.overlay-info.overlay-lighten-3 {
    background: #8edeec;
    background: rgba(142, 222, 236, 0.8);
}
.info.lighten-2 {
    color: #60d0e4 !important;
}
.bg-info.bg-lighten-2,
.btn-info.btn-lighten-2 {
    background-color: #60d0e4 !important;
}
.btn-info.btn-lighten-2,
.btn-info.btn-lighten-2:hover {
    border-color: #14adce !important;
}
.btn-info.btn-lighten-2:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-lighten-2:active,
.btn-info.btn-lighten-2:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-lighten-2 {
    border-color: #60d0e4 !important;
    color: #60d0e4 !important;
}
.btn-outline-info.btn-outline-lighten-2:hover {
    background-color: #60d0e4 !important;
}
.progress-info.progress-lighten-2 {
    background-color: #60d0e4;
}
.progress-info.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #60d0e4;
}
.progress-info.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #60d0e4;
}
.progress-info.progress-lighten-2[value]::-ms-fill {
    background-color: #60d0e4;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-lighten-2 .progress-bar {
        background-color: #60d0e4;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #60d0e4 !important;
}
.border-info.border-lighten-2 {
    border: 1px solid #60d0e4 !important;
}
.border-top-info.border-top-lighten-2 {
    border-top: 1px solid #60d0e4 !important;
}
.border-bottom-info.border-bottom-lighten-2 {
    border-bottom: 1px solid #60d0e4 !important;
}
.overlay-info.overlay-lighten-2 {
    background: #60d0e4;
    background: rgba(96, 208, 228, 0.8);
}
.info.lighten-1 {
    color: #3ec6de !important;
}
.bg-info.bg-lighten-1,
.btn-info.btn-lighten-1 {
    background-color: #3ec6de !important;
}
.btn-info.btn-lighten-1,
.btn-info.btn-lighten-1:hover {
    border-color: #14adce !important;
}
.btn-info.btn-lighten-1:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-lighten-1:active,
.btn-info.btn-lighten-1:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-lighten-1 {
    border-color: #3ec6de !important;
    color: #3ec6de !important;
}
.btn-outline-info.btn-outline-lighten-1:hover {
    background-color: #3ec6de !important;
}
.progress-info.progress-lighten-1 {
    background-color: #3ec6de;
}
.progress-info.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #3ec6de;
}
.progress-info.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #3ec6de;
}
.progress-info.progress-lighten-1[value]::-ms-fill {
    background-color: #3ec6de;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-lighten-1 .progress-bar {
        background-color: #3ec6de;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3ec6de !important;
}
.border-info.border-lighten-1 {
    border: 1px solid #3ec6de !important;
}
.border-top-info.border-top-lighten-1 {
    border-top: 1px solid #3ec6de !important;
}
.border-bottom-info.border-bottom-lighten-1 {
    border-bottom: 1px solid #3ec6de !important;
}
.overlay-info.overlay-lighten-1 {
    background: #3ec6de;
    background: rgba(62, 198, 222, 0.8);
}
.info {
    color: #1cbcd8 !important;
}
.bg-info {
    background-color: #1cbcd8 !important;
}
.bg-info .card-footer,
.bg-info .card-header {
    background-color: initial;
}
.toast-info {
    background-color: #1cbcd8;
}
.alert-info {
    border-color: #1cbcd8 !important;
    background-color: #33cae5 !important;
    color: #08323a !important;
}
.alert-info .alert-link {
    color: #031316 !important;
}
.border-info {
    border-color: #1cbcd8;
}
.border-top-info {
    border-top-color: #1cbcd8;
}
.border-bottom-info {
    border-bottom-color: #1cbcd8;
}
.badge-info {
    background-color: #1cbcd8;
}
.panel-info {
    border-color: #1cbcd8;
}
.panel-info .panel-heading {
    color: #fff;
    border-color: #1cbcd8;
    background-color: #2ac8e3;
}
.bg-info.tag-glow,
.border-info.tag-glow {
    box-shadow: 0 0 10px #1cbcd8;
}
.overlay-info {
    background: #1cbcd8;
    background: rgba(28, 188, 216, 0.8);
}
.card.card-outline-info {
    border: 1px solid #1cbcd8;
}
.card.card-outline-info,
.card.card-outline-info .card-footer,
.card.card-outline-info .card-header {
    background-color: initial;
}
.btn-info.btn-flat {
    background-color: initial !important;
    color: #1cbcd8;
    border: none;
}
.btn-info.btn-fab,
.btn-info.btn-raised {
    background-color: #1cbcd8 !important;
    color: #fff !important;
    border-color: #1cbcd8;
}
.btn-info.btn-fab.active,
.btn-info.btn-raised.active {
    background-color: #18a0b8 !important;
    border-color: #18a0b8 !important;
}
.btn-group-raised .btn-info {
    background-color: #1cbcd8 !important;
    color: #fff !important;
}
.btn-outline-info {
    border: 1px solid #1cbcd8;
    background-color: initial;
    color: #1cbcd8;
    box-shadow: none !important;
}
.btn-outline-info:focus {
    background-color: initial !important;
    color: #1cbcd8 !important;
    box-shadow: transparent !important;
}
.btn-outline-info.active {
    background-color: #1cbcd8 !important;
    color: #fff !important;
}
.btn-outline-info:hover {
    background-color: #1ab0ca !important;
    color: #fff !important;
}
.btn-outline-info:hover svg {
    color: #fff !important;
}
.btn-outline-info.btn-fab,
.btn-outline-info.btn-raised {
    border: 1px solid #1cbcd8;
    background-color: initial;
    color: #1cbcd8;
    box-shadow: none !important;
}
.btn-outline-info.btn-fab:focus,
.btn-outline-info.btn-raised:focus {
    background-color: initial;
}
.btn-outline-info.btn-fab.active,
.btn-outline-info.btn-raised.active {
    background-color: #1cbcd8 !important;
    color: #fff !important;
}
.btn-outline-info.btn-fab:hover,
.btn-outline-info.btn-raised:hover {
    background-color: #1ab0ca !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-info {
    background-color: #1cbcd8;
}
.progress .progress-bar.progress-bar-info[value]::-webkit-progress-value {
    background-color: #1cbcd8;
}
.progress .progress-bar.progress-bar-info[value]::-moz-progress-bar {
    background-color: #1cbcd8;
}
.progress .progress-bar.progress-bar-info[value]::-ms-fill {
    background-color: #1cbcd8;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-info .progress-bar {
        background-color: #1cbcd8;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1cbcd8 !important;
}
.info.darken-1 {
    color: #19b6d4 !important;
}
.bg-info.bg-darken-1,
.btn-info.btn-darken-1 {
    background-color: #19b6d4 !important;
}
.btn-info.btn-darken-1,
.btn-info.btn-darken-1:hover {
    border-color: #14adce !important;
}
.btn-info.btn-darken-1:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-darken-1:active,
.btn-info.btn-darken-1:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-darken-1 {
    border-color: #19b6d4 !important;
    color: #19b6d4 !important;
}
.btn-outline-info.btn-outline-darken-1:hover {
    background-color: #19b6d4 !important;
}
.progress-info.progress-darken-1 {
    background-color: #19b6d4;
}
.progress-info.progress-darken-1[value]::-webkit-progress-value {
    background-color: #19b6d4;
}
.progress-info.progress-darken-1[value]::-moz-progress-bar {
    background-color: #19b6d4;
}
.progress-info.progress-darken-1[value]::-ms-fill {
    background-color: #19b6d4;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-darken-1 .progress-bar {
        background-color: #19b6d4;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #19b6d4 !important;
}
.border-info.border-darken-1 {
    border: 1px solid #19b6d4 !important;
}
.border-top-info.border-top-darken-1 {
    border-top: 1px solid #19b6d4 !important;
}
.border-bottom-info.border-bottom-darken-1 {
    border-bottom: 1px solid #19b6d4 !important;
}
.overlay-info.overlay-darken-1 {
    background: #19b6d4;
    background: rgba(25, 182, 212, 0.8);
}
.info.darken-2 {
    color: #14adce !important;
}
.bg-info.bg-darken-2,
.btn-info.btn-darken-2 {
    background-color: #14adce !important;
}
.btn-info.btn-darken-2,
.btn-info.btn-darken-2:hover {
    border-color: #14adce !important;
}
.btn-info.btn-darken-2:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-darken-2:active,
.btn-info.btn-darken-2:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-darken-2 {
    border-color: #14adce !important;
    color: #14adce !important;
}
.btn-outline-info.btn-outline-darken-2:hover {
    background-color: #14adce !important;
}
.progress-info.progress-darken-2 {
    background-color: #14adce;
}
.progress-info.progress-darken-2[value]::-webkit-progress-value {
    background-color: #14adce;
}
.progress-info.progress-darken-2[value]::-moz-progress-bar {
    background-color: #14adce;
}
.progress-info.progress-darken-2[value]::-ms-fill {
    background-color: #14adce;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-darken-2 .progress-bar {
        background-color: #14adce;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #14adce !important;
}
.border-info.border-darken-2 {
    border: 1px solid #14adce !important;
}
.border-top-info.border-top-darken-2 {
    border-top: 1px solid #14adce !important;
}
.border-bottom-info.border-bottom-darken-2 {
    border-bottom: 1px solid #14adce !important;
}
.overlay-info.overlay-darken-2 {
    background: #14adce;
    background: rgba(20, 173, 206, 0.8);
}
.info.darken-3 {
    color: #11a5c8 !important;
}
.bg-info.bg-darken-3 {
    background-color: #11a5c8 !important;
}
.btn-info.btn-darken-3,
.btn-info.btn-darken-3:hover {
    border-color: #14adce !important;
    background-color: #11a5c8 !important;
}
.btn-info.btn-darken-3:active,
.btn-info.btn-darken-3:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-darken-3 {
    border-color: #11a5c8 !important;
    color: #11a5c8 !important;
}
.btn-outline-info.btn-outline-darken-3:hover {
    background-color: #11a5c8 !important;
}
.progress-info.progress-darken-3 {
    background-color: #11a5c8;
}
.progress-info.progress-darken-3[value]::-webkit-progress-value {
    background-color: #11a5c8;
}
.progress-info.progress-darken-3[value]::-moz-progress-bar {
    background-color: #11a5c8;
}
.progress-info.progress-darken-3[value]::-ms-fill {
    background-color: #11a5c8;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-darken-3 .progress-bar {
        background-color: #11a5c8;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #11a5c8 !important;
}
.border-info.border-darken-3 {
    border: 1px solid #11a5c8 !important;
}
.border-top-info.border-top-darken-3 {
    border-top: 1px solid #11a5c8 !important;
}
.border-bottom-info.border-bottom-darken-3 {
    border-bottom: 1px solid #11a5c8 !important;
}
.overlay-info.overlay-darken-3 {
    background: #11a5c8;
    background: rgba(17, 165, 200, 0.8);
}
.info.darken-4 {
    color: #0997bf !important;
}
.bg-info.bg-darken-4,
.btn-info.btn-darken-4 {
    background-color: #0997bf !important;
}
.btn-info.btn-darken-4,
.btn-info.btn-darken-4:hover {
    border-color: #14adce !important;
}
.btn-info.btn-darken-4:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-darken-4:active,
.btn-info.btn-darken-4:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-darken-4 {
    border-color: #0997bf !important;
    color: #0997bf !important;
}
.btn-outline-info.btn-outline-darken-4:hover {
    background-color: #0997bf !important;
}
.progress-info.progress-darken-4 {
    background-color: #0997bf;
}
.progress-info.progress-darken-4[value]::-webkit-progress-value {
    background-color: #0997bf;
}
.progress-info.progress-darken-4[value]::-moz-progress-bar {
    background-color: #0997bf;
}
.progress-info.progress-darken-4[value]::-ms-fill {
    background-color: #0997bf;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-darken-4 .progress-bar {
        background-color: #0997bf;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0997bf !important;
}
.border-info.border-darken-4 {
    border: 1px solid #0997bf !important;
}
.border-top-info.border-top-darken-4 {
    border-top: 1px solid #0997bf !important;
}
.border-bottom-info.border-bottom-darken-4 {
    border-bottom: 1px solid #0997bf !important;
}
.overlay-info.overlay-darken-4 {
    background: #0997bf;
    background: rgba(9, 151, 191, 0.8);
}
.info.accent-1 {
    color: #eafaff !important;
}
.bg-info.bg-accent-1,
.btn-info.btn-accent-1 {
    background-color: #eafaff !important;
}
.btn-info.btn-accent-1,
.btn-info.btn-accent-1:hover {
    border-color: #14adce !important;
}
.btn-info.btn-accent-1:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-accent-1:active,
.btn-info.btn-accent-1:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-accent-1 {
    border-color: #eafaff !important;
    color: #eafaff !important;
}
.btn-outline-info.btn-outline-accent-1:hover {
    background-color: #eafaff !important;
}
.progress-info.progress-accent-1 {
    background-color: #eafaff;
}
.progress-info.progress-accent-1[value]::-webkit-progress-value {
    background-color: #eafaff;
}
.progress-info.progress-accent-1[value]::-moz-progress-bar {
    background-color: #eafaff;
}
.progress-info.progress-accent-1[value]::-ms-fill {
    background-color: #eafaff;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-accent-1 .progress-bar {
        background-color: #eafaff;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eafaff !important;
}
.border-info.border-accent-1 {
    border: 1px solid #eafaff !important;
}
.border-top-info.border-top-accent-1 {
    border-top: 1px solid #eafaff !important;
}
.border-bottom-info.border-bottom-accent-1 {
    border-bottom: 1px solid #eafaff !important;
}
.overlay-info.overlay-accent-1 {
    background: #eafaff;
    background: rgba(234, 250, 255, 0.8);
}
.info.accent-2 {
    color: #b7edff !important;
}
.bg-info.bg-accent-2,
.btn-info.btn-accent-2 {
    background-color: #b7edff !important;
}
.btn-info.btn-accent-2,
.btn-info.btn-accent-2:hover {
    border-color: #14adce !important;
}
.btn-info.btn-accent-2:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-accent-2:active,
.btn-info.btn-accent-2:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-accent-2 {
    border-color: #b7edff !important;
    color: #b7edff !important;
}
.btn-outline-info.btn-outline-accent-2:hover {
    background-color: #b7edff !important;
}
.progress-info.progress-accent-2 {
    background-color: #b7edff;
}
.progress-info.progress-accent-2[value]::-webkit-progress-value {
    background-color: #b7edff;
}
.progress-info.progress-accent-2[value]::-moz-progress-bar {
    background-color: #b7edff;
}
.progress-info.progress-accent-2[value]::-ms-fill {
    background-color: #b7edff;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-accent-2 .progress-bar {
        background-color: #b7edff;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b7edff !important;
}
.border-info.border-accent-2 {
    border: 1px solid #b7edff !important;
}
.border-top-info.border-top-accent-2 {
    border-top: 1px solid #b7edff !important;
}
.border-bottom-info.border-bottom-accent-2 {
    border-bottom: 1px solid #b7edff !important;
}
.overlay-info.overlay-accent-2 {
    background: #b7edff;
    background: rgba(183, 237, 255, 0.8);
}
.info.accent-3 {
    color: #84e1ff !important;
}
.bg-info.bg-accent-3,
.btn-info.btn-accent-3 {
    background-color: #84e1ff !important;
}
.btn-info.btn-accent-3,
.btn-info.btn-accent-3:hover {
    border-color: #14adce !important;
}
.btn-info.btn-accent-3:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-accent-3:active,
.btn-info.btn-accent-3:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-accent-3 {
    border-color: #84e1ff !important;
    color: #84e1ff !important;
}
.btn-outline-info.btn-outline-accent-3:hover {
    background-color: #84e1ff !important;
}
.progress-info.progress-accent-3 {
    background-color: #84e1ff;
}
.progress-info.progress-accent-3[value]::-webkit-progress-value {
    background-color: #84e1ff;
}
.progress-info.progress-accent-3[value]::-moz-progress-bar {
    background-color: #84e1ff;
}
.progress-info.progress-accent-3[value]::-ms-fill {
    background-color: #84e1ff;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-accent-3 .progress-bar {
        background-color: #84e1ff;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #84e1ff !important;
}
.border-info.border-accent-3 {
    border: 1px solid #84e1ff !important;
}
.border-top-info.border-top-accent-3 {
    border-top: 1px solid #84e1ff !important;
}
.border-bottom-info.border-bottom-accent-3 {
    border-bottom: 1px solid #84e1ff !important;
}
.overlay-info.overlay-accent-3 {
    background: #84e1ff;
    background: rgba(132, 225, 255, 0.8);
}
.info.accent-4 {
    color: #6adaff !important;
}
.bg-info.bg-accent-4,
.btn-info.btn-accent-4 {
    background-color: #6adaff !important;
}
.btn-info.btn-accent-4,
.btn-info.btn-accent-4:hover {
    border-color: #14adce !important;
}
.btn-info.btn-accent-4:hover {
    background-color: #11a5c8 !important;
}
.btn-info.btn-accent-4:active,
.btn-info.btn-accent-4:focus {
    border-color: #11a5c8 !important;
    background-color: #0997bf !important;
}
.btn-outline-info.btn-outline-accent-4 {
    border-color: #6adaff !important;
    color: #6adaff !important;
}
.btn-outline-info.btn-outline-accent-4:hover {
    background-color: #6adaff !important;
}
.progress-info.progress-accent-4 {
    background-color: #6adaff;
}
.progress-info.progress-accent-4[value]::-webkit-progress-value {
    background-color: #6adaff;
}
.progress-info.progress-accent-4[value]::-moz-progress-bar {
    background-color: #6adaff;
}
.progress-info.progress-accent-4[value]::-ms-fill {
    background-color: #6adaff;
}
@media screen and (min-width: 0\0) {
    .progress-info.progress-accent-4 .progress-bar {
        background-color: #6adaff;
    }
}
input:focus ~ .bg-info {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6adaff !important;
}
.border-info.border-accent-4 {
    border: 1px solid #6adaff !important;
}
.border-top-info.border-top-accent-4 {
    border-top: 1px solid #6adaff !important;
}
.border-bottom-info.border-bottom-accent-4 {
    border-bottom: 1px solid #6adaff !important;
}
.overlay-info.overlay-accent-4 {
    background: #6adaff;
    background: rgba(106, 218, 255, 0.8);
}
.warning.lighten-5 {
    color: #fff1ec !important;
}
.bg-warning.bg-lighten-5,
.btn-warning.btn-lighten-5 {
    background-color: #fff1ec !important;
}
.btn-warning.btn-lighten-5 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-lighten-5:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-lighten-5:active,
.btn-warning.btn-lighten-5:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-lighten-5 {
    border-color: #fff1ec !important;
    color: #fff1ec !important;
}
.btn-outline-warning.btn-outline-lighten-5:hover {
    background-color: #fff1ec !important;
}
.progress-warning.progress-lighten-5 {
    background-color: #fff1ec;
}
.progress-warning.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fff1ec;
}
.progress-warning.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fff1ec;
}
.progress-warning.progress-lighten-5[value]::-ms-fill {
    background-color: #fff1ec;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-lighten-5 .progress-bar {
        background-color: #fff1ec;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff1ec !important;
}
.border-warning.border-lighten-5 {
    border: 1px solid #fff1ec !important;
}
.border-top-warning.border-top-lighten-5 {
    border-top: 1px solid #fff1ec !important;
}
.border-bottom-warning.border-bottom-lighten-5 {
    border-bottom: 1px solid #fff1ec !important;
}
.overlay-warning.overlay-lighten-5 {
    background: #fff1ec;
    background: rgba(255, 241, 236, 0.8);
}
.warning.lighten-4 {
    color: #ffddcf !important;
}
.bg-warning.bg-lighten-4,
.btn-warning.btn-lighten-4 {
    background-color: #ffddcf !important;
}
.btn-warning.btn-lighten-4 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-lighten-4:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-lighten-4:active,
.btn-warning.btn-lighten-4:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-lighten-4 {
    border-color: #ffddcf !important;
    color: #ffddcf !important;
}
.btn-outline-warning.btn-outline-lighten-4:hover {
    background-color: #ffddcf !important;
}
.progress-warning.progress-lighten-4 {
    background-color: #ffddcf;
}
.progress-warning.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffddcf;
}
.progress-warning.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffddcf;
}
.progress-warning.progress-lighten-4[value]::-ms-fill {
    background-color: #ffddcf;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-lighten-4 .progress-bar {
        background-color: #ffddcf;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffddcf !important;
}
.border-warning.border-lighten-4 {
    border: 1px solid #ffddcf !important;
}
.border-top-warning.border-top-lighten-4 {
    border-top: 1px solid #ffddcf !important;
}
.border-bottom-warning.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffddcf !important;
}
.overlay-warning.overlay-lighten-4 {
    background: #ffddcf;
    background: rgba(255, 221, 207, 0.8);
}
.warning.lighten-3 {
    color: #ffc6b0 !important;
}
.bg-warning.bg-lighten-3,
.btn-warning.btn-lighten-3 {
    background-color: #ffc6b0 !important;
}
.btn-warning.btn-lighten-3 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-lighten-3:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-lighten-3:active,
.btn-warning.btn-lighten-3:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-lighten-3 {
    border-color: #ffc6b0 !important;
    color: #ffc6b0 !important;
}
.btn-outline-warning.btn-outline-lighten-3:hover {
    background-color: #ffc6b0 !important;
}
.progress-warning.progress-lighten-3 {
    background-color: #ffc6b0;
}
.progress-warning.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffc6b0;
}
.progress-warning.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffc6b0;
}
.progress-warning.progress-lighten-3[value]::-ms-fill {
    background-color: #ffc6b0;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-lighten-3 .progress-bar {
        background-color: #ffc6b0;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffc6b0 !important;
}
.border-warning.border-lighten-3 {
    border: 1px solid #ffc6b0 !important;
}
.border-top-warning.border-top-lighten-3 {
    border-top: 1px solid #ffc6b0 !important;
}
.border-bottom-warning.border-bottom-lighten-3 {
    border-bottom: 1px solid #ffc6b0 !important;
}
.overlay-warning.overlay-lighten-3 {
    background: #ffc6b0;
    background: rgba(255, 198, 176, 0.8);
}
.warning.lighten-2 {
    color: #ffaf90 !important;
}
.bg-warning.bg-lighten-2,
.btn-warning.btn-lighten-2 {
    background-color: #ffaf90 !important;
}
.btn-warning.btn-lighten-2 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-lighten-2:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-lighten-2:active,
.btn-warning.btn-lighten-2:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-lighten-2 {
    border-color: #ffaf90 !important;
    color: #ffaf90 !important;
}
.btn-outline-warning.btn-outline-lighten-2:hover {
    background-color: #ffaf90 !important;
}
.progress-warning.progress-lighten-2 {
    background-color: #ffaf90;
}
.progress-warning.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ffaf90;
}
.progress-warning.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ffaf90;
}
.progress-warning.progress-lighten-2[value]::-ms-fill {
    background-color: #ffaf90;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-lighten-2 .progress-bar {
        background-color: #ffaf90;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffaf90 !important;
}
.border-warning.border-lighten-2 {
    border: 1px solid #ffaf90 !important;
}
.border-top-warning.border-top-lighten-2 {
    border-top: 1px solid #ffaf90 !important;
}
.border-bottom-warning.border-bottom-lighten-2 {
    border-bottom: 1px solid #ffaf90 !important;
}
.overlay-warning.overlay-lighten-2 {
    background: #ffaf90;
    background: rgba(255, 175, 144, 0.8);
}
.warning.lighten-1 {
    color: #ff9e78 !important;
}
.bg-warning.bg-lighten-1,
.btn-warning.btn-lighten-1 {
    background-color: #ff9e78 !important;
}
.btn-warning.btn-lighten-1 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-lighten-1:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-lighten-1:active,
.btn-warning.btn-lighten-1:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-lighten-1 {
    border-color: #ff9e78 !important;
    color: #ff9e78 !important;
}
.btn-outline-warning.btn-outline-lighten-1:hover {
    background-color: #ff9e78 !important;
}
.progress-warning.progress-lighten-1 {
    background-color: #ff9e78;
}
.progress-warning.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ff9e78;
}
.progress-warning.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ff9e78;
}
.progress-warning.progress-lighten-1[value]::-ms-fill {
    background-color: #ff9e78;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-lighten-1 .progress-bar {
        background-color: #ff9e78;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9e78 !important;
}
.border-warning.border-lighten-1 {
    border: 1px solid #ff9e78 !important;
}
.border-top-warning.border-top-lighten-1 {
    border-top: 1px solid #ff9e78 !important;
}
.border-bottom-warning.border-bottom-lighten-1 {
    border-bottom: 1px solid #ff9e78 !important;
}
.overlay-warning.overlay-lighten-1 {
    background: #ff9e78;
    background: rgba(255, 158, 120, 0.8);
}
.warning {
    color: #ff8d60 !important;
}
.online{
    color: #558b2f;
}
.bg-warning {
    background-color: #ff8d60 !important;
}
.bg-warning .card-footer,
.bg-warning .card-header {
    background-color: initial;
}
.toast-warning {
    background-color: #ff8d60;
}
.alert-warning {
    border-color: #ff8d60 !important;
    background-color: #ffa784 !important;
    color: #ad3100 !important;
}
.alert-warning .alert-link {
    color: #842500 !important;
}
.border-warning {
    border-color: #ff8d60;
}
.border-top-warning {
    border-top-color: #ff8d60;
}
.border-bottom-warning {
    border-bottom-color: #ff8d60;
}
.badge-warning {
    background-color: #ff8d60;
}
.panel-warning {
    border-color: #ff8d60;
}
.panel-warning .panel-heading {
    color: #fff;
    border-color: #ff8d60;
    background-color: #ff9f7a;
}
.bg-warning.tag-glow,
.border-warning.tag-glow {
    box-shadow: 0 0 10px #ff8d60;
}
.overlay-warning {
    background: #ff8d60;
    background: rgba(255, 141, 96, 0.8);
}
.card.card-outline-warning {
    border: 1px solid #ff8d60;
    background-color: initial;
}
.card.card-outline-warning .card-footer,
.card.card-outline-warning .card-header {
    background-color: initial;
}
.btn-warning.btn-flat {
    background-color: initial !important;
    color: #ff8d60;
    border: none;
}
.btn-warning.btn-fab,
.btn-warning.btn-raised {
    background-color: #ff8d60 !important;
    color: #fff !important;
    border-color: #ff8d60;
}
.btn-warning.btn-fab.active,
.btn-warning.btn-raised.active {
    background-color: #ff733c !important;
    border-color: #ff733c !important;
}
.btn-group-raised .btn-warning {
    background-color: #ff8d60 !important;
    color: #fff !important;
}
.btn-outline-warning {
    border: 1px solid #ff8d60;
    background-color: initial;
    color: #ff8d60;
    box-shadow: none !important;
}
.btn-outline-warning:focus {
    background-color: initial !important;
    color: #ff8d60 !important;
    box-shadow: transparent !important;
}
.btn-outline-warning.active {
    background-color: #ff8d60 !important;
    color: #fff !important;
}
.btn-outline-warning:hover {
    background-color: #ff8251 !important;
    color: #fff !important;
}
.btn-outline-warning:hover svg {
    color: #fff !important;
}
.btn-outline-warning.btn-fab,
.btn-outline-warning.btn-raised {
    border: 1px solid #ff8d60;
    background-color: initial;
    color: #ff8d60;
    box-shadow: none !important;
}
.btn-outline-warning.btn-fab:focus,
.btn-outline-warning.btn-raised:focus {
    background-color: initial;
}
.btn-outline-warning.btn-fab.active,
.btn-outline-warning.btn-raised.active {
    background-color: #ff8d60 !important;
    color: #fff !important;
}
.btn-outline-warning.btn-fab:hover,
.btn-outline-warning.btn-raised:hover {
    background-color: #ff8251 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-warning {
    background-color: #ff8d60;
}
.progress .progress-bar.progress-bar-warning[value]::-webkit-progress-value {
    background-color: #ff8d60;
}
.progress .progress-bar.progress-bar-warning[value]::-moz-progress-bar {
    background-color: #ff8d60;
}
.progress .progress-bar.progress-bar-warning[value]::-ms-fill {
    background-color: #ff8d60;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-warning .progress-bar {
        background-color: #ff8d60;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8d60 !important;
}
.warning.darken-1 {
    color: #ff8558 !important;
}
.bg-warning.bg-darken-1,
.btn-warning.btn-darken-1 {
    background-color: #ff8558 !important;
}
.btn-warning.btn-darken-1 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-darken-1:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-darken-1:active,
.btn-warning.btn-darken-1:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-darken-1 {
    border-color: #ff8558 !important;
    color: #ff8558 !important;
}
.btn-outline-warning.btn-outline-darken-1:hover {
    background-color: #ff8558 !important;
}
.progress-warning.progress-darken-1 {
    background-color: #ff8558;
}
.progress-warning.progress-darken-1[value]::-webkit-progress-value {
    background-color: #ff8558;
}
.progress-warning.progress-darken-1[value]::-moz-progress-bar {
    background-color: #ff8558;
}
.progress-warning.progress-darken-1[value]::-ms-fill {
    background-color: #ff8558;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-darken-1 .progress-bar {
        background-color: #ff8558;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8558 !important;
}
.border-warning.border-darken-1 {
    border: 1px solid #ff8558 !important;
}
.border-top-warning.border-top-darken-1 {
    border-top: 1px solid #ff8558 !important;
}
.border-bottom-warning.border-bottom-darken-1 {
    border-bottom: 1px solid #ff8558 !important;
}
.overlay-warning.overlay-darken-1 {
    background: #ff8558;
    background: rgba(255, 133, 88, 0.8);
}
.warning.darken-2 {
    color: #ff7a4e !important;
}
.bg-warning.bg-darken-2,
.btn-warning.btn-darken-2 {
    background-color: #ff7a4e !important;
}
.btn-warning.btn-darken-2 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-darken-2:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-darken-2:active,
.btn-warning.btn-darken-2:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-darken-2 {
    border-color: #ff7a4e !important;
    color: #ff7a4e !important;
}
.btn-outline-warning.btn-outline-darken-2:hover {
    background-color: #ff7a4e !important;
}
.progress-warning.progress-darken-2 {
    background-color: #ff7a4e;
}
.progress-warning.progress-darken-2[value]::-webkit-progress-value {
    background-color: #ff7a4e;
}
.progress-warning.progress-darken-2[value]::-moz-progress-bar {
    background-color: #ff7a4e;
}
.progress-warning.progress-darken-2[value]::-ms-fill {
    background-color: #ff7a4e;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-darken-2 .progress-bar {
        background-color: #ff7a4e;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff7a4e !important;
}
.border-warning.border-darken-2 {
    border: 1px solid #ff7a4e !important;
}
.border-top-warning.border-top-darken-2 {
    border-top: 1px solid #ff7a4e !important;
}
.border-bottom-warning.border-bottom-darken-2 {
    border-bottom: 1px solid #ff7a4e !important;
}
.overlay-warning.overlay-darken-2 {
    background: #ff7a4e;
    background: rgba(255, 122, 78, 0.8);
}
.warning.darken-3 {
    color: #ff7044 !important;
}
.bg-warning.bg-darken-3 {
    background-color: #ff7044 !important;
}
.btn-warning.btn-darken-3,
.btn-warning.btn-darken-3:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-darken-3:active,
.btn-warning.btn-darken-3:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-darken-3 {
    border-color: #ff7044 !important;
    color: #ff7044 !important;
}
.btn-outline-warning.btn-outline-darken-3:hover {
    background-color: #ff7044 !important;
}
.progress-warning.progress-darken-3 {
    background-color: #ff7044;
}
.progress-warning.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ff7044;
}
.progress-warning.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ff7044;
}
.progress-warning.progress-darken-3[value]::-ms-fill {
    background-color: #ff7044;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-darken-3 .progress-bar {
        background-color: #ff7044;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff7044 !important;
}
.border-warning.border-darken-3 {
    border: 1px solid #ff7044 !important;
}
.border-top-warning.border-top-darken-3 {
    border-top: 1px solid #ff7044 !important;
}
.border-bottom-warning.border-bottom-darken-3 {
    border-bottom: 1px solid #ff7044 !important;
}
.overlay-warning.overlay-darken-3 {
    background: #ff7044;
    background: rgba(255, 112, 68, 0.8);
}
.warning.darken-4 {
    color: #ff5d33 !important;
}
.bg-warning.bg-darken-4,
.btn-warning.btn-darken-4 {
    background-color: #ff5d33 !important;
}
.btn-warning.btn-darken-4 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-darken-4:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-darken-4:active,
.btn-warning.btn-darken-4:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-darken-4 {
    border-color: #ff5d33 !important;
    color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-darken-4:hover {
    background-color: #ff5d33 !important;
}
.progress-warning.progress-darken-4 {
    background-color: #ff5d33;
}
.progress-warning.progress-darken-4[value]::-webkit-progress-value {
    background-color: #ff5d33;
}
.progress-warning.progress-darken-4[value]::-moz-progress-bar {
    background-color: #ff5d33;
}
.progress-warning.progress-darken-4[value]::-ms-fill {
    background-color: #ff5d33;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-darken-4 .progress-bar {
        background-color: #ff5d33;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff5d33 !important;
}
.border-warning.border-darken-4 {
    border: 1px solid #ff5d33 !important;
}
.border-top-warning.border-top-darken-4 {
    border-top: 1px solid #ff5d33 !important;
}
.border-bottom-warning.border-bottom-darken-4 {
    border-bottom: 1px solid #ff5d33 !important;
}
.overlay-warning.overlay-darken-4 {
    background: #ff5d33;
    background: rgba(255, 93, 51, 0.8);
}
.warning.accent-1 {
    color: #fff !important;
}
.bg-warning.bg-accent-1,
.btn-warning.btn-accent-1 {
    background-color: #fff !important;
}
.btn-warning.btn-accent-1 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-accent-1:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-accent-1:active,
.btn-warning.btn-accent-1:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-accent-1 {
    border-color: #fff !important;
    color: #fff !important;
}
.btn-outline-warning.btn-outline-accent-1:hover {
    background-color: #fff !important;
}
.progress-warning.progress-accent-1 {
    background-color: #fff;
}
.progress-warning.progress-accent-1[value]::-webkit-progress-value {
    background-color: #fff;
}
.progress-warning.progress-accent-1[value]::-moz-progress-bar {
    background-color: #fff;
}
.progress-warning.progress-accent-1[value]::-ms-fill {
    background-color: #fff;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-accent-1 .progress-bar {
        background-color: #fff;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff !important;
}
.border-warning.border-accent-1 {
    border: 1px solid #fff !important;
}
.border-top-warning.border-top-accent-1 {
    border-top: 1px solid #fff !important;
}
.border-bottom-warning.border-bottom-accent-1 {
    border-bottom: 1px solid #fff !important;
}
.overlay-warning.overlay-accent-1 {
    background: #fff;
    background: hsla(0, 0%, 100%, 0.8);
}
.warning.accent-2 {
    color: #ffefec !important;
}
.bg-warning.bg-accent-2,
.btn-warning.btn-accent-2 {
    background-color: #ffefec !important;
}
.btn-warning.btn-accent-2 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-accent-2:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-accent-2:active,
.btn-warning.btn-accent-2:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-accent-2 {
    border-color: #ffefec !important;
    color: #ffefec !important;
}
.btn-outline-warning.btn-outline-accent-2:hover {
    background-color: #ffefec !important;
}
.progress-warning.progress-accent-2 {
    background-color: #ffefec;
}
.progress-warning.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ffefec;
}
.progress-warning.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ffefec;
}
.progress-warning.progress-accent-2[value]::-ms-fill {
    background-color: #ffefec;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-accent-2 .progress-bar {
        background-color: #ffefec;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffefec !important;
}
.border-warning.border-accent-2 {
    border: 1px solid #ffefec !important;
}
.border-top-warning.border-top-accent-2 {
    border-top: 1px solid #ffefec !important;
}
.border-bottom-warning.border-bottom-accent-2 {
    border-bottom: 1px solid #ffefec !important;
}
.overlay-warning.overlay-accent-2 {
    background: #ffefec;
    background: rgba(255, 239, 236, 0.8);
}
.warning.accent-3 {
    color: #ffe4de !important;
}
.bg-warning.bg-accent-3,
.btn-warning.btn-accent-3 {
    background-color: #ffe4de !important;
}
.btn-warning.btn-accent-3 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-accent-3:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-accent-3:active,
.btn-warning.btn-accent-3:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-accent-3 {
    border-color: #ffe4de !important;
    color: #ffe4de !important;
}
.btn-outline-warning.btn-outline-accent-3:hover {
    background-color: #ffe4de !important;
}
.progress-warning.progress-accent-3 {
    background-color: #ffe4de;
}
.progress-warning.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ffe4de;
}
.progress-warning.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ffe4de;
}
.progress-warning.progress-accent-3[value]::-ms-fill {
    background-color: #ffe4de;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-accent-3 .progress-bar {
        background-color: #ffe4de;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe4de !important;
}
.border-warning.border-accent-3 {
    border: 1px solid #ffe4de !important;
}
.border-top-warning.border-top-accent-3 {
    border-top: 1px solid #ffe4de !important;
}
.border-bottom-warning.border-bottom-accent-3 {
    border-bottom: 1px solid #ffe4de !important;
}
.overlay-warning.overlay-accent-3 {
    background: #ffe4de;
    background: rgba(255, 228, 222, 0.8);
}
.warning.accent-4 {
    color: #ffcfc4 !important;
}
.bg-warning.bg-accent-4,
.btn-warning.btn-accent-4 {
    background-color: #ffcfc4 !important;
}
.btn-warning.btn-accent-4 {
    border-color: #ff7a4e !important;
}
.btn-warning.btn-accent-4:hover {
    border-color: #ff7a4e !important;
    background-color: #ff7044 !important;
}
.btn-warning.btn-accent-4:active,
.btn-warning.btn-accent-4:focus {
    border-color: #ff7044 !important;
    background-color: #ff5d33 !important;
}
.btn-outline-warning.btn-outline-accent-4 {
    border-color: #ffcfc4 !important;
    color: #ffcfc4 !important;
}
.btn-outline-warning.btn-outline-accent-4:hover {
    background-color: #ffcfc4 !important;
}
.progress-warning.progress-accent-4 {
    background-color: #ffcfc4;
}
.progress-warning.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ffcfc4;
}
.progress-warning.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ffcfc4;
}
.progress-warning.progress-accent-4[value]::-ms-fill {
    background-color: #ffcfc4;
}
@media screen and (min-width: 0\0) {
    .progress-warning.progress-accent-4 .progress-bar {
        background-color: #ffcfc4;
    }
}
input:focus ~ .bg-warning {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffcfc4 !important;
}
.border-warning.border-accent-4 {
    border: 1px solid #ffcfc4 !important;
}
.border-top-warning.border-top-accent-4 {
    border-top: 1px solid #ffcfc4 !important;
}
.border-bottom-warning.border-bottom-accent-4 {
    border-bottom: 1px solid #ffcfc4 !important;
}
.overlay-warning.overlay-accent-4 {
    background: #ffcfc4;
    background: rgba(255, 207, 196, 0.8);
}
.danger.lighten-5 {
    color: #ffebed !important;
}
.bg-danger.bg-lighten-5,
.btn-danger.btn-lighten-5 {
    background-color: #ffebed !important;
}
.btn-danger.btn-lighten-5 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-lighten-5:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-lighten-5:active,
.btn-danger.btn-lighten-5:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-lighten-5 {
    border-color: #ffebed !important;
    color: #ffebed !important;
}
.btn-outline-danger.btn-outline-lighten-5:hover {
    background-color: #ffebed !important;
}
.progress-danger.progress-lighten-5 {
    background-color: #ffebed;
}
.progress-danger.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #ffebed;
}
.progress-danger.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #ffebed;
}
.progress-danger.progress-lighten-5[value]::-ms-fill {
    background-color: #ffebed;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-lighten-5 .progress-bar {
        background-color: #ffebed;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffebed !important;
}
.border-danger.border-lighten-5 {
    border: 1px solid #ffebed !important;
}
.border-top-danger.border-top-lighten-5 {
    border-top: 1px solid #ffebed !important;
}
.border-bottom-danger.border-bottom-lighten-5 {
    border-bottom: 1px solid #ffebed !important;
}
.overlay-danger.overlay-lighten-5 {
    background: #ffebed;
    background: rgba(255, 235, 237, 0.8);
}
.danger.lighten-4 {
    color: #ffcdd3 !important;
}
.bg-danger.bg-lighten-4,
.btn-danger.btn-lighten-4 {
    background-color: #ffcdd3 !important;
}
.btn-danger.btn-lighten-4 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-lighten-4:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-lighten-4:active,
.btn-danger.btn-lighten-4:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-lighten-4 {
    border-color: #ffcdd3 !important;
    color: #ffcdd3 !important;
}
.btn-outline-danger.btn-outline-lighten-4:hover {
    background-color: #ffcdd3 !important;
}
.progress-danger.progress-lighten-4 {
    background-color: #ffcdd3;
}
.progress-danger.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffcdd3;
}
.progress-danger.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffcdd3;
}
.progress-danger.progress-lighten-4[value]::-ms-fill {
    background-color: #ffcdd3;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-lighten-4 .progress-bar {
        background-color: #ffcdd3;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffcdd3 !important;
}
.border-danger.border-lighten-4 {
    border: 1px solid #ffcdd3 !important;
}
.border-top-danger.border-top-lighten-4 {
    border-top: 1px solid #ffcdd3 !important;
}
.border-bottom-danger.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffcdd3 !important;
}
.overlay-danger.overlay-lighten-4 {
    background: #ffcdd3;
    background: rgba(255, 205, 211, 0.8);
}
.danger.lighten-3 {
    color: #ffacb5 !important;
}
.bg-danger.bg-lighten-3,
.btn-danger.btn-lighten-3 {
    background-color: #ffacb5 !important;
}
.btn-danger.btn-lighten-3 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-lighten-3:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-lighten-3:active,
.btn-danger.btn-lighten-3:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-lighten-3 {
    border-color: #ffacb5 !important;
    color: #ffacb5 !important;
}
.btn-outline-danger.btn-outline-lighten-3:hover {
    background-color: #ffacb5 !important;
}
.progress-danger.progress-lighten-3 {
    background-color: #ffacb5;
}
.progress-danger.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffacb5;
}
.progress-danger.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffacb5;
}
.progress-danger.progress-lighten-3[value]::-ms-fill {
    background-color: #ffacb5;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-lighten-3 .progress-bar {
        background-color: #ffacb5;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffacb5 !important;
}
.border-danger.border-lighten-3 {
    border: 1px solid #ffacb5 !important;
}
.border-top-danger.border-top-lighten-3 {
    border-top: 1px solid #ffacb5 !important;
}
.border-bottom-danger.border-bottom-lighten-3 {
    border-bottom: 1px solid #ffacb5 !important;
}
.overlay-danger.overlay-lighten-3 {
    background: #ffacb5;
    background: rgba(255, 172, 181, 0.8);
}
.danger.lighten-2 {
    color: #ff8a97 !important;
}
.bg-danger.bg-lighten-2,
.btn-danger.btn-lighten-2 {
    background-color: #ff8a97 !important;
}
.btn-danger.btn-lighten-2 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-lighten-2:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-lighten-2:active,
.btn-danger.btn-lighten-2:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-lighten-2 {
    border-color: #ff8a97 !important;
    color: #ff8a97 !important;
}
.btn-outline-danger.btn-outline-lighten-2:hover {
    background-color: #ff8a97 !important;
}
.progress-danger.progress-lighten-2 {
    background-color: #ff8a97;
}
.progress-danger.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ff8a97;
}
.progress-danger.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ff8a97;
}
.progress-danger.progress-lighten-2[value]::-ms-fill {
    background-color: #ff8a97;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-lighten-2 .progress-bar {
        background-color: #ff8a97;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8a97 !important;
}
.border-danger.border-lighten-2 {
    border: 1px solid #ff8a97 !important;
}
.border-top-danger.border-top-lighten-2 {
    border-top: 1px solid #ff8a97 !important;
}
.border-bottom-danger.border-bottom-lighten-2 {
    border-bottom: 1px solid #ff8a97 !important;
}
.overlay-danger.overlay-lighten-2 {
    background: #ff8a97;
    background: rgba(255, 138, 151, 0.8);
}
.danger.lighten-1 {
    color: #ff7181 !important;
}
.bg-danger.bg-lighten-1,
.btn-danger.btn-lighten-1 {
    background-color: #ff7181 !important;
}
.btn-danger.btn-lighten-1 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-lighten-1:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-lighten-1:active,
.btn-danger.btn-lighten-1:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-lighten-1 {
    border-color: #ff7181 !important;
    color: #ff7181 !important;
}
.btn-outline-danger.btn-outline-lighten-1:hover {
    background-color: #ff7181 !important;
}
.progress-danger.progress-lighten-1 {
    background-color: #ff7181;
}
.progress-danger.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ff7181;
}
.progress-danger.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ff7181;
}
.progress-danger.progress-lighten-1[value]::-ms-fill {
    background-color: #ff7181;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-lighten-1 .progress-bar {
        background-color: #ff7181;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff7181 !important;
}
.border-danger.border-lighten-1 {
    border: 1px solid #ff7181 !important;
}
.border-top-danger.border-top-lighten-1 {
    border-top: 1px solid #ff7181 !important;
}
.border-bottom-danger.border-bottom-lighten-1 {
    border-bottom: 1px solid #ff7181 !important;
}
[dir='ltr'] .border-left-danger.border-left-lighten-1 {
    border-left: 1px solid #ff7181 !important;
}
[dir='ltr'] .border-right-danger.border-right-lighten-1,
[dir='rtl'] .border-left-danger.border-left-lighten-1 {
    border-right: 1px solid #ff7181 !important;
}
[dir='rtl'] .border-right-danger.border-right-lighten-1 {
    border-left: 1px solid #ff7181 !important;
}
.overlay-danger.overlay-lighten-1 {
    background: #ff7181;
    background: rgba(255, 113, 129, 0.8);
}
.danger {
    color: #ff586b !important;
}
.bg-danger {
    background-color: #ff586b !important;
}
.bg-danger .card-footer,
.bg-danger .card-header {
    background-color: initial;
}
.toast-danger {
    background-color: #ff586b;
}
.alert-danger .alert-link {
    color: #7c000e !important;
}
.border-danger {
    border-color: #ff586b;
}
.border-top-danger {
    border-top-color: #ff586b;
}
.border-bottom-danger {
    border-bottom-color: #ff586b;
}
[dir='ltr'] .border-left-danger {
    border-left-color: #ff586b;
}
[dir='ltr'] .border-right-danger,
[dir='rtl'] .border-left-danger {
    border-right-color: #ff586b;
}
[dir='rtl'] .border-right-danger {
    border-left-color: #ff586b;
}
.badge-danger {
    background-color: #ff586b;
}
.panel-danger {
    border-color: #ff586b;
}
.panel-danger .panel-heading {
    color: #fff;
    border-color: #ff586b;
    background-color: #ff7282;
}
.bg-danger.tag-glow,
.border-danger.tag-glow {
    box-shadow: 0 0 10px #ff586b;
}
.overlay-danger {
    background: #ff586b;
    background: rgba(255, 88, 107, 0.8);
}
.card.card-outline-danger {
    border: 1px solid #ff586b;
    background-color: initial;
}
.card.card-outline-danger .card-footer,
.card.card-outline-danger .card-header {
    background-color: initial;
}
.btn-danger.btn-flat {
    background-color: initial !important;
    color: #ff586b;
    border: none;
}
.btn-danger.btn-fab,
.btn-danger.btn-raised {
    background-color: #ff586b !important;
    color: #fff !important;
    border-color: #ff586b;
}
.btn-danger.btn-fab.active,
.btn-danger.btn-raised.active {
    background-color: #ff344b !important;
    border-color: #ff344b !important;
}
.btn-group-raised .btn-danger {
    background-color: #ff586b !important;
    color: #fff !important;
}
.btn-outline-danger {
    border: 1px solid #ff586b;
    background-color: initial;
    color: #ff586b;
    box-shadow: none !important;
}
.btn-outline-danger:focus {
    background-color: initial !important;
    color: #ff586b !important;
    box-shadow: transparent !important;
}
.btn-outline-danger.active {
    background-color: #ff586b !important;
    color: #fff !important;
}
.btn-outline-danger:hover {
    background-color: #ff495d !important;
    color: #fff !important;
}
.btn-outline-danger:hover svg {
    color: #fff !important;
}
.btn-outline-danger.btn-fab,
.btn-outline-danger.btn-raised {
    border: 1px solid #ff586b;
    background-color: initial;
    color: #ff586b;
    box-shadow: none !important;
}
.btn-outline-danger.btn-fab:focus,
.btn-outline-danger.btn-raised:focus {
    background-color: initial;
}
.btn-outline-danger.btn-fab.active,
.btn-outline-danger.btn-raised.active {
    background-color: #ff586b !important;
    color: #fff !important;
}
.btn-outline-danger.btn-fab:hover,
.btn-outline-danger.btn-raised:hover {
    background-color: #ff495d !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-danger {
    background-color: #ff586b;
}
.progress .progress-bar.progress-bar-danger[value]::-webkit-progress-value {
    background-color: #ff586b;
}
.progress .progress-bar.progress-bar-danger[value]::-moz-progress-bar {
    background-color: #ff586b;
}
.progress .progress-bar.progress-bar-danger[value]::-ms-fill {
    background-color: #ff586b;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-danger .progress-bar {
        background-color: #ff586b;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff586b !important;
}
.danger.darken-1 {
    color: #ff5063 !important;
}
.bg-danger.bg-darken-1,
.btn-danger.btn-darken-1 {
    background-color: #ff5063 !important;
}
.btn-danger.btn-darken-1 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-darken-1:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-darken-1:active,
.btn-danger.btn-darken-1:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-darken-1 {
    border-color: #ff5063 !important;
    color: #ff5063 !important;
}
.btn-outline-danger.btn-outline-darken-1:hover {
    background-color: #ff5063 !important;
}
.progress-danger.progress-darken-1 {
    background-color: #ff5063;
}
.progress-danger.progress-darken-1[value]::-webkit-progress-value {
    background-color: #ff5063;
}
.progress-danger.progress-darken-1[value]::-moz-progress-bar {
    background-color: #ff5063;
}
.progress-danger.progress-darken-1[value]::-ms-fill {
    background-color: #ff5063;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-darken-1 .progress-bar {
        background-color: #ff5063;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff5063 !important;
}
.border-danger.border-darken-1 {
    border: 1px solid #ff5063 !important;
}
.border-top-danger.border-top-darken-1 {
    border-top: 1px solid #ff5063 !important;
}
.border-bottom-danger.border-bottom-darken-1 {
    border-bottom: 1px solid #ff5063 !important;
}
[dir='ltr'] .border-left-danger.border-left-darken-1 {
    border-left: 1px solid #ff5063 !important;
}
[dir='ltr'] .border-right-danger.border-right-darken-1,
[dir='rtl'] .border-left-danger.border-left-darken-1 {
    border-right: 1px solid #ff5063 !important;
}
[dir='rtl'] .border-right-danger.border-right-darken-1 {
    border-left: 1px solid #ff5063 !important;
}
.overlay-danger.overlay-darken-1 {
    background: #ff5063;
    background: rgba(255, 80, 99, 0.8);
}
.danger.darken-2 {
    color: #ff4758 !important;
}
.bg-danger.bg-darken-2,
.btn-danger.btn-darken-2 {
    background-color: #ff4758 !important;
}
.btn-danger.btn-darken-2 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-darken-2:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-darken-2:active,
.btn-danger.btn-darken-2:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-darken-2 {
    border-color: #ff4758 !important;
    color: #ff4758 !important;
}
.btn-outline-danger.btn-outline-darken-2:hover {
    background-color: #ff4758 !important;
}
.progress-danger.progress-darken-2 {
    background-color: #ff4758;
}
.progress-danger.progress-darken-2[value]::-webkit-progress-value {
    background-color: #ff4758;
}
.progress-danger.progress-darken-2[value]::-moz-progress-bar {
    background-color: #ff4758;
}
.progress-danger.progress-darken-2[value]::-ms-fill {
    background-color: #ff4758;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-darken-2 .progress-bar {
        background-color: #ff4758;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff4758 !important;
}
.border-danger.border-darken-2 {
    border: 1px solid #ff4758 !important;
}
.border-top-danger.border-top-darken-2 {
    border-top: 1px solid #ff4758 !important;
}
.border-bottom-danger.border-bottom-darken-2 {
    border-bottom: 1px solid #ff4758 !important;
}
[dir='ltr'] .border-left-danger.border-left-darken-2 {
    border-left: 1px solid #ff4758 !important;
}
[dir='ltr'] .border-right-danger.border-right-darken-2,
[dir='rtl'] .border-left-danger.border-left-darken-2 {
    border-right: 1px solid #ff4758 !important;
}
[dir='rtl'] .border-right-danger.border-right-darken-2 {
    border-left: 1px solid #ff4758 !important;
}
.overlay-danger.overlay-darken-2 {
    background: #ff4758;
    background: rgba(255, 71, 88, 0.8);
}
.danger.darken-3 {
    color: #ff3d4e !important;
}
.bg-danger.bg-darken-3 {
    background-color: #ff3d4e !important;
}
.btn-danger.btn-darken-3,
.btn-danger.btn-darken-3:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-darken-3:active,
.btn-danger.btn-darken-3:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-darken-3 {
    border-color: #ff3d4e !important;
    color: #ff3d4e !important;
}
.btn-outline-danger.btn-outline-darken-3:hover {
    background-color: #ff3d4e !important;
}
.progress-danger.progress-darken-3 {
    background-color: #ff3d4e;
}
.progress-danger.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ff3d4e;
}
.progress-danger.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ff3d4e;
}
.progress-danger.progress-darken-3[value]::-ms-fill {
    background-color: #ff3d4e;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-darken-3 .progress-bar {
        background-color: #ff3d4e;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff3d4e !important;
}
.border-danger.border-darken-3 {
    border: 1px solid #ff3d4e !important;
}
.border-top-danger.border-top-darken-3 {
    border-top: 1px solid #ff3d4e !important;
}
.border-bottom-danger.border-bottom-darken-3 {
    border-bottom: 1px solid #ff3d4e !important;
}
[dir='ltr'] .border-left-danger.border-left-darken-3 {
    border-left: 1px solid #ff3d4e !important;
}
[dir='ltr'] .border-right-danger.border-right-darken-3,
[dir='rtl'] .border-left-danger.border-left-darken-3 {
    border-right: 1px solid #ff3d4e !important;
}
[dir='rtl'] .border-right-danger.border-right-darken-3 {
    border-left: 1px solid #ff3d4e !important;
}
.overlay-danger.overlay-darken-3 {
    background: #ff3d4e;
    background: rgba(255, 61, 78, 0.8);
}
.danger.darken-4 {
    color: #ff2d3c !important;
}
.bg-danger.bg-darken-4,
.btn-danger.btn-darken-4 {
    background-color: #ff2d3c !important;
}
.btn-danger.btn-darken-4 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-darken-4:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-darken-4:active,
.btn-danger.btn-darken-4:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-darken-4 {
    border-color: #ff2d3c !important;
    color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-darken-4:hover {
    background-color: #ff2d3c !important;
}
.progress-danger.progress-darken-4 {
    background-color: #ff2d3c;
}
.progress-danger.progress-darken-4[value]::-webkit-progress-value {
    background-color: #ff2d3c;
}
.progress-danger.progress-darken-4[value]::-moz-progress-bar {
    background-color: #ff2d3c;
}
.progress-danger.progress-darken-4[value]::-ms-fill {
    background-color: #ff2d3c;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-darken-4 .progress-bar {
        background-color: #ff2d3c;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff2d3c !important;
}
.border-danger.border-darken-4 {
    border: 1px solid #ff2d3c !important;
}
.border-top-danger.border-top-darken-4 {
    border-top: 1px solid #ff2d3c !important;
}
.border-bottom-danger.border-bottom-darken-4 {
    border-bottom: 1px solid #ff2d3c !important;
}
[dir='ltr'] .border-left-danger.border-left-darken-4 {
    border-left: 1px solid #ff2d3c !important;
}
[dir='ltr'] .border-right-danger.border-right-darken-4,
[dir='rtl'] .border-left-danger.border-left-darken-4 {
    border-right: 1px solid #ff2d3c !important;
}
[dir='rtl'] .border-right-danger.border-right-darken-4 {
    border-left: 1px solid #ff2d3c !important;
}
.overlay-danger.overlay-darken-4 {
    background: #ff2d3c;
    background: rgba(255, 45, 60, 0.8);
}
.danger.accent-1 {
    color: #fff !important;
}
.bg-danger.bg-accent-1,
.btn-danger.btn-accent-1 {
    background-color: #fff !important;
}
.btn-danger.btn-accent-1 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-accent-1:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-accent-1:active,
.btn-danger.btn-accent-1:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-accent-1 {
    border-color: #fff !important;
    color: #fff !important;
}
.btn-outline-danger.btn-outline-accent-1:hover {
    background-color: #fff !important;
}
.progress-danger.progress-accent-1 {
    background-color: #fff;
}
.progress-danger.progress-accent-1[value]::-webkit-progress-value {
    background-color: #fff;
}
.progress-danger.progress-accent-1[value]::-moz-progress-bar {
    background-color: #fff;
}
.progress-danger.progress-accent-1[value]::-ms-fill {
    background-color: #fff;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-accent-1 .progress-bar {
        background-color: #fff;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff !important;
}
.border-danger.border-accent-1 {
    border: 1px solid #fff !important;
}
.border-top-danger.border-top-accent-1 {
    border-top: 1px solid #fff !important;
}
.border-bottom-danger.border-bottom-accent-1 {
    border-bottom: 1px solid #fff !important;
}
[dir='ltr'] .border-left-danger.border-left-accent-1 {
    border-left: 1px solid #fff !important;
}
[dir='ltr'] .border-right-danger.border-right-accent-1,
[dir='rtl'] .border-left-danger.border-left-accent-1 {
    border-right: 1px solid #fff !important;
}
[dir='rtl'] .border-right-danger.border-right-accent-1 {
    border-left: 1px solid #fff !important;
}
.overlay-danger.overlay-accent-1 {
    background: #fff;
    background: hsla(0, 0%, 100%, 0.8);
}
.danger.accent-2 {
    color: #fbebed !important;
}
.bg-danger.bg-accent-2,
.btn-danger.btn-accent-2 {
    background-color: #fbebed !important;
}
.btn-danger.btn-accent-2 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-accent-2:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-accent-2:active,
.btn-danger.btn-accent-2:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-accent-2 {
    border-color: #fbebed !important;
    color: #fbebed !important;
}
.btn-outline-danger.btn-outline-accent-2:hover {
    background-color: #fbebed !important;
}
.progress-danger.progress-accent-2 {
    background-color: #fbebed;
}
.progress-danger.progress-accent-2[value]::-webkit-progress-value {
    background-color: #fbebed;
}
.progress-danger.progress-accent-2[value]::-moz-progress-bar {
    background-color: #fbebed;
}
.progress-danger.progress-accent-2[value]::-ms-fill {
    background-color: #fbebed;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-accent-2 .progress-bar {
        background-color: #fbebed;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fbebed !important;
}
.border-danger.border-accent-2 {
    border: 1px solid #fbebed !important;
}
.border-top-danger.border-top-accent-2 {
    border-top: 1px solid #fbebed !important;
}
.border-bottom-danger.border-bottom-accent-2 {
    border-bottom: 1px solid #fbebed !important;
}
[dir='ltr'] .border-left-danger.border-left-accent-2 {
    border-left: 1px solid #fbebed !important;
}
[dir='ltr'] .border-right-danger.border-right-accent-2,
[dir='rtl'] .border-left-danger.border-left-accent-2 {
    border-right: 1px solid #fbebed !important;
}
[dir='rtl'] .border-right-danger.border-right-accent-2 {
    border-left: 1px solid #fbebed !important;
}
.overlay-danger.overlay-accent-2 {
    background: #fbebed;
    background: rgba(251, 235, 237, 0.8);
}
.danger.accent-3 {
    color: #ffd9db !important;
}
.bg-danger.bg-accent-3,
.btn-danger.btn-accent-3 {
    background-color: #ffd9db !important;
}
.btn-danger.btn-accent-3 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-accent-3:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-accent-3:active,
.btn-danger.btn-accent-3:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-accent-3 {
    border-color: #ffd9db !important;
    color: #ffd9db !important;
}
.btn-outline-danger.btn-outline-accent-3:hover {
    background-color: #ffd9db !important;
}
.progress-danger.progress-accent-3 {
    background-color: #ffd9db;
}
.progress-danger.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ffd9db;
}
.progress-danger.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ffd9db;
}
.progress-danger.progress-accent-3[value]::-ms-fill {
    background-color: #ffd9db;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-accent-3 .progress-bar {
        background-color: #ffd9db;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd9db !important;
}
.border-danger.border-accent-3 {
    border: 1px solid #ffd9db !important;
}
.border-top-danger.border-top-accent-3 {
    border-top: 1px solid #ffd9db !important;
}
.border-bottom-danger.border-bottom-accent-3 {
    border-bottom: 1px solid #ffd9db !important;
}
[dir='ltr'] .border-left-danger.border-left-accent-3 {
    border-left: 1px solid #ffd9db !important;
}
[dir='ltr'] .border-right-danger.border-right-accent-3,
[dir='rtl'] .border-left-danger.border-left-accent-3 {
    border-right: 1px solid #ffd9db !important;
}
[dir='rtl'] .border-right-danger.border-right-accent-3 {
    border-left: 1px solid #ffd9db !important;
}
.overlay-danger.overlay-accent-3 {
    background: #ffd9db;
    background: rgba(255, 217, 219, 0.8);
}
.danger.accent-4 {
    color: #ffbfc3 !important;
}
.bg-danger.bg-accent-4,
.btn-danger.btn-accent-4 {
    background-color: #ffbfc3 !important;
}
.btn-danger.btn-accent-4 {
    border-color: #ff4758 !important;
}
.btn-danger.btn-accent-4:hover {
    border-color: #ff4758 !important;
    background-color: #ff3d4e !important;
}
.btn-danger.btn-accent-4:active,
.btn-danger.btn-accent-4:focus {
    border-color: #ff3d4e !important;
    background-color: #ff2d3c !important;
}
.btn-outline-danger.btn-outline-accent-4 {
    border-color: #ffbfc3 !important;
    color: #ffbfc3 !important;
}
.btn-outline-danger.btn-outline-accent-4:hover {
    background-color: #ffbfc3 !important;
}
.progress-danger.progress-accent-4 {
    background-color: #ffbfc3;
}
.progress-danger.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ffbfc3;
}
.progress-danger.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ffbfc3;
}
.progress-danger.progress-accent-4[value]::-ms-fill {
    background-color: #ffbfc3;
}
@media screen and (min-width: 0\0) {
    .progress-danger.progress-accent-4 .progress-bar {
        background-color: #ffbfc3;
    }
}
input:focus ~ .bg-danger {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffbfc3 !important;
}
.border-danger.border-accent-4 {
    border: 1px solid #ffbfc3 !important;
}
.border-top-danger.border-top-accent-4 {
    border-top: 1px solid #ffbfc3 !important;
}
.border-bottom-danger.border-bottom-accent-4 {
    border-bottom: 1px solid #ffbfc3 !important;
}
[dir='ltr'] .border-left-danger.border-left-accent-4 {
    border-left: 1px solid #ffbfc3 !important;
}
[dir='ltr'] .border-right-danger.border-right-accent-4,
[dir='rtl'] .border-left-danger.border-left-accent-4 {
    border-right: 1px solid #ffbfc3 !important;
}
[dir='rtl'] .border-right-danger.border-right-accent-4 {
    border-left: 1px solid #ffbfc3 !important;
}
.overlay-danger.overlay-accent-4 {
    background: #ffbfc3;
    background: rgba(255, 191, 195, 0.8);
}
.red.lighten-5 {
    color: #ffebee !important;
}
.bg-red.bg-lighten-5,
.btn-red.btn-lighten-5 {
    background-color: #ffebee !important;
}
.btn-red.btn-lighten-5,
.btn-red.btn-lighten-5:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-lighten-5:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-5:active,
.btn-red.btn-lighten-5:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-lighten-5 {
    border-color: #ffebee !important;
    color: #ffebee !important;
}
.btn-outline-red.btn-outline-lighten-5:hover {
    background-color: #ffebee !important;
}
.progress-red.progress-lighten-5 {
    background-color: #ffebee;
}
.progress-red.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #ffebee;
}
.progress-red.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #ffebee;
}
.progress-red.progress-lighten-5[value]::-ms-fill {
    background-color: #ffebee;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-lighten-5 .progress-bar {
        background-color: #ffebee;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffebee !important;
}
.border-red.border-lighten-5 {
    border: 1px solid #ffebee !important;
}
.border-top-red.border-top-lighten-5 {
    border-top: 1px solid #ffebee !important;
}
.border-bottom-red.border-bottom-lighten-5 {
    border-bottom: 1px solid #ffebee !important;
}
[dir='ltr'] .border-left-red.border-left-lighten-5 {
    border-left: 1px solid #ffebee !important;
}
[dir='ltr'] .border-right-red.border-right-lighten-5,
[dir='rtl'] .border-left-red.border-left-lighten-5 {
    border-right: 1px solid #ffebee !important;
}
[dir='rtl'] .border-right-red.border-right-lighten-5 {
    border-left: 1px solid #ffebee !important;
}
.overlay-red.overlay-lighten-5 {
    background: #ffebee;
    background: rgba(255, 235, 238, 0.8);
}
.red.lighten-4 {
    color: #ffcdd2 !important;
}
.bg-red.bg-lighten-4,
.btn-red.btn-lighten-4 {
    background-color: #ffcdd2 !important;
}
.btn-red.btn-lighten-4,
.btn-red.btn-lighten-4:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-lighten-4:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-4:active,
.btn-red.btn-lighten-4:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-lighten-4 {
    border-color: #ffcdd2 !important;
    color: #ffcdd2 !important;
}
.btn-outline-red.btn-outline-lighten-4:hover {
    background-color: #ffcdd2 !important;
}
.progress-red.progress-lighten-4 {
    background-color: #ffcdd2;
}
.progress-red.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffcdd2;
}
.progress-red.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffcdd2;
}
.progress-red.progress-lighten-4[value]::-ms-fill {
    background-color: #ffcdd2;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-lighten-4 .progress-bar {
        background-color: #ffcdd2;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffcdd2 !important;
}
.border-red.border-lighten-4 {
    border: 1px solid #ffcdd2 !important;
}
.border-top-red.border-top-lighten-4 {
    border-top: 1px solid #ffcdd2 !important;
}
.border-bottom-red.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffcdd2 !important;
}
[dir='ltr'] .border-left-red.border-left-lighten-4 {
    border-left: 1px solid #ffcdd2 !important;
}
[dir='ltr'] .border-right-red.border-right-lighten-4,
[dir='rtl'] .border-left-red.border-left-lighten-4 {
    border-right: 1px solid #ffcdd2 !important;
}
[dir='rtl'] .border-right-red.border-right-lighten-4 {
    border-left: 1px solid #ffcdd2 !important;
}
.overlay-red.overlay-lighten-4 {
    background: #ffcdd2;
    background: rgba(255, 205, 210, 0.8);
}
.red.lighten-3 {
    color: #ef9a9a !important;
}
.bg-red.bg-lighten-3,
.btn-red.btn-lighten-3 {
    background-color: #ef9a9a !important;
}
.btn-red.btn-lighten-3,
.btn-red.btn-lighten-3:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-lighten-3:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-3:active,
.btn-red.btn-lighten-3:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-lighten-3 {
    border-color: #ef9a9a !important;
    color: #ef9a9a !important;
}
.btn-outline-red.btn-outline-lighten-3:hover {
    background-color: #ef9a9a !important;
}
.progress-red.progress-lighten-3 {
    background-color: #ef9a9a;
}
.progress-red.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ef9a9a;
}
.progress-red.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ef9a9a;
}
.progress-red.progress-lighten-3[value]::-ms-fill {
    background-color: #ef9a9a;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-lighten-3 .progress-bar {
        background-color: #ef9a9a;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef9a9a !important;
}
.border-red.border-lighten-3 {
    border: 1px solid #ef9a9a !important;
}
.border-top-red.border-top-lighten-3 {
    border-top: 1px solid #ef9a9a !important;
}
.border-bottom-red.border-bottom-lighten-3 {
    border-bottom: 1px solid #ef9a9a !important;
}
[dir='ltr'] .border-left-red.border-left-lighten-3 {
    border-left: 1px solid #ef9a9a !important;
}
[dir='ltr'] .border-right-red.border-right-lighten-3,
[dir='rtl'] .border-left-red.border-left-lighten-3 {
    border-right: 1px solid #ef9a9a !important;
}
[dir='rtl'] .border-right-red.border-right-lighten-3 {
    border-left: 1px solid #ef9a9a !important;
}
.overlay-red.overlay-lighten-3 {
    background: #ef9a9a;
    background: rgba(239, 154, 154, 0.8);
}
.red.lighten-2 {
    color: #e57373 !important;
}
.bg-red.bg-lighten-2,
.btn-red.btn-lighten-2 {
    background-color: #e57373 !important;
}
.btn-red.btn-lighten-2,
.btn-red.btn-lighten-2:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-lighten-2:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-2:active,
.btn-red.btn-lighten-2:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-lighten-2 {
    border-color: #e57373 !important;
    color: #e57373 !important;
}
.btn-outline-red.btn-outline-lighten-2:hover {
    background-color: #e57373 !important;
}
.progress-red.progress-lighten-2 {
    background-color: #e57373;
}
.progress-red.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #e57373;
}
.progress-red.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #e57373;
}
.progress-red.progress-lighten-2[value]::-ms-fill {
    background-color: #e57373;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-lighten-2 .progress-bar {
        background-color: #e57373;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e57373 !important;
}
.border-red.border-lighten-2 {
    border: 1px solid #e57373 !important;
}
.border-top-red.border-top-lighten-2 {
    border-top: 1px solid #e57373 !important;
}
.border-bottom-red.border-bottom-lighten-2 {
    border-bottom: 1px solid #e57373 !important;
}
[dir='ltr'] .border-left-red.border-left-lighten-2 {
    border-left: 1px solid #e57373 !important;
}
[dir='ltr'] .border-right-red.border-right-lighten-2,
[dir='rtl'] .border-left-red.border-left-lighten-2 {
    border-right: 1px solid #e57373 !important;
}
[dir='rtl'] .border-right-red.border-right-lighten-2 {
    border-left: 1px solid #e57373 !important;
}
.overlay-red.overlay-lighten-2 {
    background: #e57373;
    background: rgba(229, 115, 115, 0.8);
}
.red.lighten-1 {
    color: #ef5350 !important;
}
.bg-red.bg-lighten-1,
.btn-red.btn-lighten-1 {
    background-color: #ef5350 !important;
}
.btn-red.btn-lighten-1,
.btn-red.btn-lighten-1:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-lighten-1:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-lighten-1:active,
.btn-red.btn-lighten-1:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-lighten-1 {
    border-color: #ef5350 !important;
    color: #ef5350 !important;
}
.btn-outline-red.btn-outline-lighten-1:hover {
    background-color: #ef5350 !important;
}
.progress-red.progress-lighten-1 {
    background-color: #ef5350;
}
.progress-red.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ef5350;
}
.progress-red.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ef5350;
}
.progress-red.progress-lighten-1[value]::-ms-fill {
    background-color: #ef5350;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-lighten-1 .progress-bar {
        background-color: #ef5350;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef5350 !important;
}
.border-red.border-lighten-1 {
    border: 1px solid #ef5350 !important;
}
.border-top-red.border-top-lighten-1 {
    border-top: 1px solid #ef5350 !important;
}
.border-bottom-red.border-bottom-lighten-1 {
    border-bottom: 1px solid #ef5350 !important;
}
[dir='ltr'] .border-left-red.border-left-lighten-1 {
    border-left: 1px solid #ef5350 !important;
}
[dir='ltr'] .border-right-red.border-right-lighten-1,
[dir='rtl'] .border-left-red.border-left-lighten-1 {
    border-right: 1px solid #ef5350 !important;
}
[dir='rtl'] .border-right-red.border-right-lighten-1 {
    border-left: 1px solid #ef5350 !important;
}
.overlay-red.overlay-lighten-1 {
    background: #ef5350;
    background: rgba(239, 83, 80, 0.8);
}
.red {
    color: #f44336 !important;
}
.bg-red {
    background-color: #f44336 !important;
}
.bg-red .card-footer,
.bg-red .card-header {
    background-color: initial;
}
.toast-red {
    background-color: #f44336;
}
.alert-red {
    border-color: #f44336 !important;
    background-color: #f66358 !important;
    color: #710e06 !important;
}
.alert-red .alert-link {
    color: #4b0904 !important;
}
.border-red {
    border-color: #f44336;
}
.border-top-red {
    border-top-color: #f44336;
}
.border-bottom-red {
    border-bottom-color: #f44336;
}
[dir='ltr'] .border-left-red {
    border-left-color: #f44336;
}
[dir='ltr'] .border-right-red,
[dir='rtl'] .border-left-red {
    border-right-color: #f44336;
}
[dir='rtl'] .border-right-red {
    border-left-color: #f44336;
}
.badge-red {
    background-color: #f44336;
}
.panel-red {
    border-color: #f44336;
}
.panel-red .panel-heading {
    color: #fff;
    border-color: #f44336;
    background-color: #f55a4e;
}
.bg-red.tag-glow,
.border-red.tag-glow {
    box-shadow: 0 0 10px #f44336;
}
.overlay-red {
    background: #f44336;
    background: rgba(244, 67, 54, 0.8);
}
.card.card-outline-red {
    border: 1px solid #f44336;
}
.card.card-outline-red,
.card.card-outline-red .card-footer,
.card.card-outline-red .card-header {
    background-color: initial;
}
.btn-red.btn-flat {
    background-color: initial !important;
    color: #f44336;
    border: none;
}
.btn-red.btn-fab,
.btn-red.btn-raised {
    background-color: #f44336 !important;
    color: #fff !important;
    border-color: #f44336;
}
.btn-red.btn-fab.active,
.btn-red.btn-raised.active {
    background-color: #f22314 !important;
    border-color: #f22314 !important;
}
.btn-group-raised .btn-red {
    background-color: #f44336 !important;
    color: #fff !important;
}
.btn-outline-red {
    border: 1px solid #f44336;
    background-color: initial;
    color: #f44336;
    box-shadow: none !important;
}
.btn-outline-red:focus {
    background-color: initial !important;
    color: #f44336 !important;
    box-shadow: transparent !important;
}
.btn-outline-red.active {
    background-color: #f44336 !important;
    color: #fff !important;
}
.btn-outline-red:hover {
    background-color: #f33527 !important;
    color: #fff !important;
}
.btn-outline-red:hover svg {
    color: #fff !important;
}
.btn-outline-red.btn-fab,
.btn-outline-red.btn-raised {
    border: 1px solid #f44336;
    background-color: initial;
    color: #f44336;
    box-shadow: none !important;
}
.btn-outline-red.btn-fab:focus,
.btn-outline-red.btn-raised:focus {
    background-color: initial;
}
.btn-outline-red.btn-fab.active,
.btn-outline-red.btn-raised.active {
    background-color: #f44336 !important;
    color: #fff !important;
}
.btn-outline-red.btn-fab:hover,
.btn-outline-red.btn-raised:hover {
    background-color: #f33527 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-red {
    background-color: #f44336;
}
.progress .progress-bar.progress-bar-red[value]::-webkit-progress-value {
    background-color: #f44336;
}
.progress .progress-bar.progress-bar-red[value]::-moz-progress-bar {
    background-color: #f44336;
}
.progress .progress-bar.progress-bar-red[value]::-ms-fill {
    background-color: #f44336;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-red .progress-bar {
        background-color: #f44336;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f44336 !important;
}
.red.darken-1 {
    color: #e53935 !important;
}
.bg-red.bg-darken-1,
.btn-red.btn-darken-1 {
    background-color: #e53935 !important;
}
.btn-red.btn-darken-1,
.btn-red.btn-darken-1:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-darken-1:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-darken-1:active,
.btn-red.btn-darken-1:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-darken-1 {
    border-color: #e53935 !important;
    color: #e53935 !important;
}
.btn-outline-red.btn-outline-darken-1:hover {
    background-color: #e53935 !important;
}
.progress-red.progress-darken-1 {
    background-color: #e53935;
}
.progress-red.progress-darken-1[value]::-webkit-progress-value {
    background-color: #e53935;
}
.progress-red.progress-darken-1[value]::-moz-progress-bar {
    background-color: #e53935;
}
.progress-red.progress-darken-1[value]::-ms-fill {
    background-color: #e53935;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-darken-1 .progress-bar {
        background-color: #e53935;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e53935 !important;
}
.border-red.border-darken-1 {
    border: 1px solid #e53935 !important;
}
.border-top-red.border-top-darken-1 {
    border-top: 1px solid #e53935 !important;
}
.border-bottom-red.border-bottom-darken-1 {
    border-bottom: 1px solid #e53935 !important;
}
[dir='ltr'] .border-left-red.border-left-darken-1 {
    border-left: 1px solid #e53935 !important;
}
[dir='ltr'] .border-right-red.border-right-darken-1,
[dir='rtl'] .border-left-red.border-left-darken-1 {
    border-right: 1px solid #e53935 !important;
}
[dir='rtl'] .border-right-red.border-right-darken-1 {
    border-left: 1px solid #e53935 !important;
}
.overlay-red.overlay-darken-1 {
    background: #e53935;
    background: rgba(229, 57, 53, 0.8);
}
.red.darken-2 {
    color: #d32f2f !important;
}
.bg-red.bg-darken-2,
.btn-red.btn-darken-2 {
    background-color: #d32f2f !important;
}
.btn-red.btn-darken-2,
.btn-red.btn-darken-2:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-darken-2:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-darken-2:active,
.btn-red.btn-darken-2:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-darken-2 {
    border-color: #d32f2f !important;
    color: #d32f2f !important;
}
.btn-outline-red.btn-outline-darken-2:hover {
    background-color: #d32f2f !important;
}
.progress-red.progress-darken-2 {
    background-color: #d32f2f;
}
.progress-red.progress-darken-2[value]::-webkit-progress-value {
    background-color: #d32f2f;
}
.progress-red.progress-darken-2[value]::-moz-progress-bar {
    background-color: #d32f2f;
}
.progress-red.progress-darken-2[value]::-ms-fill {
    background-color: #d32f2f;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-darken-2 .progress-bar {
        background-color: #d32f2f;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d32f2f !important;
}
.border-red.border-darken-2 {
    border: 1px solid #d32f2f !important;
}
.border-top-red.border-top-darken-2 {
    border-top: 1px solid #d32f2f !important;
}
.border-bottom-red.border-bottom-darken-2 {
    border-bottom: 1px solid #d32f2f !important;
}
[dir='ltr'] .border-left-red.border-left-darken-2 {
    border-left: 1px solid #d32f2f !important;
}
[dir='ltr'] .border-right-red.border-right-darken-2,
[dir='rtl'] .border-left-red.border-left-darken-2 {
    border-right: 1px solid #d32f2f !important;
}
[dir='rtl'] .border-right-red.border-right-darken-2 {
    border-left: 1px solid #d32f2f !important;
}
.overlay-red.overlay-darken-2 {
    background: #d32f2f;
    background: rgba(211, 47, 47, 0.8);
}
.red.darken-3 {
    color: #c62828 !important;
}
.bg-red.bg-darken-3 {
    background-color: #c62828 !important;
}
.btn-red.btn-darken-3,
.btn-red.btn-darken-3:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important;
}
.btn-red.btn-darken-3:active,
.btn-red.btn-darken-3:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-darken-3 {
    border-color: #c62828 !important;
    color: #c62828 !important;
}
.btn-outline-red.btn-outline-darken-3:hover {
    background-color: #c62828 !important;
}
.progress-red.progress-darken-3 {
    background-color: #c62828;
}
.progress-red.progress-darken-3[value]::-webkit-progress-value {
    background-color: #c62828;
}
.progress-red.progress-darken-3[value]::-moz-progress-bar {
    background-color: #c62828;
}
.progress-red.progress-darken-3[value]::-ms-fill {
    background-color: #c62828;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-darken-3 .progress-bar {
        background-color: #c62828;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c62828 !important;
}
.border-red.border-darken-3 {
    border: 1px solid #c62828 !important;
}
.border-top-red.border-top-darken-3 {
    border-top: 1px solid #c62828 !important;
}
.border-bottom-red.border-bottom-darken-3 {
    border-bottom: 1px solid #c62828 !important;
}
[dir='ltr'] .border-left-red.border-left-darken-3 {
    border-left: 1px solid #c62828 !important;
}
[dir='ltr'] .border-right-red.border-right-darken-3,
[dir='rtl'] .border-left-red.border-left-darken-3 {
    border-right: 1px solid #c62828 !important;
}
[dir='rtl'] .border-right-red.border-right-darken-3 {
    border-left: 1px solid #c62828 !important;
}
.overlay-red.overlay-darken-3 {
    background: #c62828;
    background: rgba(198, 40, 40, 0.8);
}
.red.darken-4 {
    color: #b71c1c !important;
}
.bg-red.bg-darken-4,
.btn-red.btn-darken-4 {
    background-color: #b71c1c !important;
}
.btn-red.btn-darken-4,
.btn-red.btn-darken-4:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-darken-4:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-darken-4:active,
.btn-red.btn-darken-4:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-darken-4 {
    border-color: #b71c1c !important;
    color: #b71c1c !important;
}
.btn-outline-red.btn-outline-darken-4:hover {
    background-color: #b71c1c !important;
}
.progress-red.progress-darken-4 {
    background-color: #b71c1c;
}
.progress-red.progress-darken-4[value]::-webkit-progress-value {
    background-color: #b71c1c;
}
.progress-red.progress-darken-4[value]::-moz-progress-bar {
    background-color: #b71c1c;
}
.progress-red.progress-darken-4[value]::-ms-fill {
    background-color: #b71c1c;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-darken-4 .progress-bar {
        background-color: #b71c1c;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b71c1c !important;
}
.border-red.border-darken-4 {
    border: 1px solid #b71c1c !important;
}
.border-top-red.border-top-darken-4 {
    border-top: 1px solid #b71c1c !important;
}
.border-bottom-red.border-bottom-darken-4 {
    border-bottom: 1px solid #b71c1c !important;
}
[dir='ltr'] .border-left-red.border-left-darken-4 {
    border-left: 1px solid #b71c1c !important;
}
[dir='ltr'] .border-right-red.border-right-darken-4,
[dir='rtl'] .border-left-red.border-left-darken-4 {
    border-right: 1px solid #b71c1c !important;
}
[dir='rtl'] .border-right-red.border-right-darken-4 {
    border-left: 1px solid #b71c1c !important;
}
.overlay-red.overlay-darken-4 {
    background: #b71c1c;
    background: rgba(183, 28, 28, 0.8);
}
.red.accent-1 {
    color: #ff8a80 !important;
}
.bg-red.bg-accent-1,
.btn-red.btn-accent-1 {
    background-color: #ff8a80 !important;
}
.btn-red.btn-accent-1,
.btn-red.btn-accent-1:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-accent-1:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-accent-1:active,
.btn-red.btn-accent-1:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-accent-1 {
    border-color: #ff8a80 !important;
    color: #ff8a80 !important;
}
.btn-outline-red.btn-outline-accent-1:hover {
    background-color: #ff8a80 !important;
}
.progress-red.progress-accent-1 {
    background-color: #ff8a80;
}
.progress-red.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ff8a80;
}
.progress-red.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ff8a80;
}
.progress-red.progress-accent-1[value]::-ms-fill {
    background-color: #ff8a80;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-accent-1 .progress-bar {
        background-color: #ff8a80;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8a80 !important;
}
.border-red.border-accent-1 {
    border: 1px solid #ff8a80 !important;
}
.border-top-red.border-top-accent-1 {
    border-top: 1px solid #ff8a80 !important;
}
.border-bottom-red.border-bottom-accent-1 {
    border-bottom: 1px solid #ff8a80 !important;
}
[dir='ltr'] .border-left-red.border-left-accent-1 {
    border-left: 1px solid #ff8a80 !important;
}
[dir='ltr'] .border-right-red.border-right-accent-1,
[dir='rtl'] .border-left-red.border-left-accent-1 {
    border-right: 1px solid #ff8a80 !important;
}
[dir='rtl'] .border-right-red.border-right-accent-1 {
    border-left: 1px solid #ff8a80 !important;
}
.overlay-red.overlay-accent-1 {
    background: #ff8a80;
    background: rgba(255, 138, 128, 0.8);
}
.red.accent-2 {
    color: #ff5252 !important;
}
.bg-red.bg-accent-2,
.btn-red.btn-accent-2 {
    background-color: #ff5252 !important;
}
.btn-red.btn-accent-2,
.btn-red.btn-accent-2:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-accent-2:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-accent-2:active,
.btn-red.btn-accent-2:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-accent-2 {
    border-color: #ff5252 !important;
    color: #ff5252 !important;
}
.btn-outline-red.btn-outline-accent-2:hover {
    background-color: #ff5252 !important;
}
.progress-red.progress-accent-2 {
    background-color: #ff5252;
}
.progress-red.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff5252;
}
.progress-red.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff5252;
}
.progress-red.progress-accent-2[value]::-ms-fill {
    background-color: #ff5252;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-accent-2 .progress-bar {
        background-color: #ff5252;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff5252 !important;
}
.border-red.border-accent-2 {
    border: 1px solid #ff5252 !important;
}
.border-top-red.border-top-accent-2 {
    border-top: 1px solid #ff5252 !important;
}
.border-bottom-red.border-bottom-accent-2 {
    border-bottom: 1px solid #ff5252 !important;
}
[dir='ltr'] .border-left-red.border-left-accent-2 {
    border-left: 1px solid #ff5252 !important;
}
[dir='ltr'] .border-right-red.border-right-accent-2,
[dir='rtl'] .border-left-red.border-left-accent-2 {
    border-right: 1px solid #ff5252 !important;
}
[dir='rtl'] .border-right-red.border-right-accent-2 {
    border-left: 1px solid #ff5252 !important;
}
.overlay-red.overlay-accent-2 {
    background: #ff5252;
    background: rgba(255, 82, 82, 0.8);
}
.red.accent-3 {
    color: #ff1744 !important;
}
.bg-red.bg-accent-3,
.btn-red.btn-accent-3 {
    background-color: #ff1744 !important;
}
.btn-red.btn-accent-3,
.btn-red.btn-accent-3:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-accent-3:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-accent-3:active,
.btn-red.btn-accent-3:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-accent-3 {
    border-color: #ff1744 !important;
    color: #ff1744 !important;
}
.btn-outline-red.btn-outline-accent-3:hover {
    background-color: #ff1744 !important;
}
.progress-red.progress-accent-3 {
    background-color: #ff1744;
}
.progress-red.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ff1744;
}
.progress-red.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ff1744;
}
.progress-red.progress-accent-3[value]::-ms-fill {
    background-color: #ff1744;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-accent-3 .progress-bar {
        background-color: #ff1744;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff1744 !important;
}
.border-red.border-accent-3 {
    border: 1px solid #ff1744 !important;
}
.border-top-red.border-top-accent-3 {
    border-top: 1px solid #ff1744 !important;
}
.border-bottom-red.border-bottom-accent-3 {
    border-bottom: 1px solid #ff1744 !important;
}
[dir='ltr'] .border-left-red.border-left-accent-3 {
    border-left: 1px solid #ff1744 !important;
}
[dir='ltr'] .border-right-red.border-right-accent-3,
[dir='rtl'] .border-left-red.border-left-accent-3 {
    border-right: 1px solid #ff1744 !important;
}
[dir='rtl'] .border-right-red.border-right-accent-3 {
    border-left: 1px solid #ff1744 !important;
}
.overlay-red.overlay-accent-3 {
    background: #ff1744;
    background: rgba(255, 23, 68, 0.8);
}
.red.accent-4 {
    color: #d50000 !important;
}
.bg-red.bg-accent-4,
.btn-red.btn-accent-4 {
    background-color: #d50000 !important;
}
.btn-red.btn-accent-4,
.btn-red.btn-accent-4:hover {
    border-color: #d32f2f !important;
}
.btn-red.btn-accent-4:hover {
    background-color: #c62828 !important;
}
.btn-red.btn-accent-4:active,
.btn-red.btn-accent-4:focus {
    border-color: #c62828 !important;
    background-color: #b71c1c !important;
}
.btn-outline-red.btn-outline-accent-4 {
    border-color: #d50000 !important;
    color: #d50000 !important;
}
.btn-outline-red.btn-outline-accent-4:hover {
    background-color: #d50000 !important;
}
.progress-red.progress-accent-4 {
    background-color: #d50000;
}
.progress-red.progress-accent-4[value]::-webkit-progress-value {
    background-color: #d50000;
}
.progress-red.progress-accent-4[value]::-moz-progress-bar {
    background-color: #d50000;
}
.progress-red.progress-accent-4[value]::-ms-fill {
    background-color: #d50000;
}
@media screen and (min-width: 0\0) {
    .progress-red.progress-accent-4 .progress-bar {
        background-color: #d50000;
    }
}
input:focus ~ .bg-red {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d50000 !important;
}
.border-red.border-accent-4 {
    border: 1px solid #d50000 !important;
}
.border-top-red.border-top-accent-4 {
    border-top: 1px solid #d50000 !important;
}
.border-bottom-red.border-bottom-accent-4 {
    border-bottom: 1px solid #d50000 !important;
}
[dir='ltr'] .border-left-red.border-left-accent-4 {
    border-left: 1px solid #d50000 !important;
}
[dir='ltr'] .border-right-red.border-right-accent-4,
[dir='rtl'] .border-left-red.border-left-accent-4 {
    border-right: 1px solid #d50000 !important;
}
[dir='rtl'] .border-right-red.border-right-accent-4 {
    border-left: 1px solid #d50000 !important;
}
.overlay-red.overlay-accent-4 {
    background: #d50000;
    background: rgba(213, 0, 0, 0.8);
}
.pink.lighten-5 {
    color: #fce4ec !important;
}
.bg-pink.bg-lighten-5,
.btn-pink.btn-lighten-5 {
    background-color: #fce4ec !important;
}
.btn-pink.btn-lighten-5,
.btn-pink.btn-lighten-5:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-lighten-5:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-5:active,
.btn-pink.btn-lighten-5:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-lighten-5 {
    border-color: #fce4ec !important;
    color: #fce4ec !important;
}
.btn-outline-pink.btn-outline-lighten-5:hover {
    background-color: #fce4ec !important;
}
.progress-pink.progress-lighten-5 {
    background-color: #fce4ec;
}
.progress-pink.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fce4ec;
}
.progress-pink.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fce4ec;
}
.progress-pink.progress-lighten-5[value]::-ms-fill {
    background-color: #fce4ec;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-lighten-5 .progress-bar {
        background-color: #fce4ec;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fce4ec !important;
}
.border-pink.border-lighten-5 {
    border: 1px solid #fce4ec !important;
}
.border-top-pink.border-top-lighten-5 {
    border-top: 1px solid #fce4ec !important;
}
.border-bottom-pink.border-bottom-lighten-5 {
    border-bottom: 1px solid #fce4ec !important;
}
[dir='ltr'] .border-left-pink.border-left-lighten-5 {
    border-left: 1px solid #fce4ec !important;
}
[dir='ltr'] .border-right-pink.border-right-lighten-5,
[dir='rtl'] .border-left-pink.border-left-lighten-5 {
    border-right: 1px solid #fce4ec !important;
}
[dir='rtl'] .border-right-pink.border-right-lighten-5 {
    border-left: 1px solid #fce4ec !important;
}
.overlay-pink.overlay-lighten-5 {
    background: #fce4ec;
    background: rgba(252, 228, 236, 0.8);
}
.pink.lighten-4 {
    color: #f8bbd0 !important;
}
.bg-pink.bg-lighten-4,
.btn-pink.btn-lighten-4 {
    background-color: #f8bbd0 !important;
}
.btn-pink.btn-lighten-4,
.btn-pink.btn-lighten-4:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-lighten-4:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-4:active,
.btn-pink.btn-lighten-4:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-lighten-4 {
    border-color: #f8bbd0 !important;
    color: #f8bbd0 !important;
}
.btn-outline-pink.btn-outline-lighten-4:hover {
    background-color: #f8bbd0 !important;
}
.progress-pink.progress-lighten-4 {
    background-color: #f8bbd0;
}
.progress-pink.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #f8bbd0;
}
.progress-pink.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #f8bbd0;
}
.progress-pink.progress-lighten-4[value]::-ms-fill {
    background-color: #f8bbd0;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-lighten-4 .progress-bar {
        background-color: #f8bbd0;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f8bbd0 !important;
}
.border-pink.border-lighten-4 {
    border: 1px solid #f8bbd0 !important;
}
.border-top-pink.border-top-lighten-4 {
    border-top: 1px solid #f8bbd0 !important;
}
.border-bottom-pink.border-bottom-lighten-4 {
    border-bottom: 1px solid #f8bbd0 !important;
}
[dir='ltr'] .border-left-pink.border-left-lighten-4 {
    border-left: 1px solid #f8bbd0 !important;
}
[dir='ltr'] .border-right-pink.border-right-lighten-4,
[dir='rtl'] .border-left-pink.border-left-lighten-4 {
    border-right: 1px solid #f8bbd0 !important;
}
[dir='rtl'] .border-right-pink.border-right-lighten-4 {
    border-left: 1px solid #f8bbd0 !important;
}
.overlay-pink.overlay-lighten-4 {
    background: #f8bbd0;
    background: rgba(248, 187, 208, 0.8);
}
.pink.lighten-3 {
    color: #f48fb1 !important;
}
.bg-pink.bg-lighten-3,
.btn-pink.btn-lighten-3 {
    background-color: #f48fb1 !important;
}
.btn-pink.btn-lighten-3,
.btn-pink.btn-lighten-3:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-lighten-3:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-3:active,
.btn-pink.btn-lighten-3:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-lighten-3 {
    border-color: #f48fb1 !important;
    color: #f48fb1 !important;
}
.btn-outline-pink.btn-outline-lighten-3:hover {
    background-color: #f48fb1 !important;
}
.progress-pink.progress-lighten-3 {
    background-color: #f48fb1;
}
.progress-pink.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #f48fb1;
}
.progress-pink.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #f48fb1;
}
.progress-pink.progress-lighten-3[value]::-ms-fill {
    background-color: #f48fb1;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-lighten-3 .progress-bar {
        background-color: #f48fb1;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f48fb1 !important;
}
.border-pink.border-lighten-3 {
    border: 1px solid #f48fb1 !important;
}
.border-top-pink.border-top-lighten-3 {
    border-top: 1px solid #f48fb1 !important;
}
.border-bottom-pink.border-bottom-lighten-3 {
    border-bottom: 1px solid #f48fb1 !important;
}
[dir='ltr'] .border-left-pink.border-left-lighten-3 {
    border-left: 1px solid #f48fb1 !important;
}
[dir='ltr'] .border-right-pink.border-right-lighten-3,
[dir='rtl'] .border-left-pink.border-left-lighten-3 {
    border-right: 1px solid #f48fb1 !important;
}
[dir='rtl'] .border-right-pink.border-right-lighten-3 {
    border-left: 1px solid #f48fb1 !important;
}
.overlay-pink.overlay-lighten-3 {
    background: #f48fb1;
    background: rgba(244, 143, 177, 0.8);
}
.pink.lighten-2 {
    color: #f06292 !important;
}
.bg-pink.bg-lighten-2,
.btn-pink.btn-lighten-2 {
    background-color: #f06292 !important;
}
.btn-pink.btn-lighten-2,
.btn-pink.btn-lighten-2:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-lighten-2:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-2:active,
.btn-pink.btn-lighten-2:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-lighten-2 {
    border-color: #f06292 !important;
    color: #f06292 !important;
}
.btn-outline-pink.btn-outline-lighten-2:hover {
    background-color: #f06292 !important;
}
.progress-pink.progress-lighten-2 {
    background-color: #f06292;
}
.progress-pink.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #f06292;
}
.progress-pink.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #f06292;
}
.progress-pink.progress-lighten-2[value]::-ms-fill {
    background-color: #f06292;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-lighten-2 .progress-bar {
        background-color: #f06292;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f06292 !important;
}
.border-pink.border-lighten-2 {
    border: 1px solid #f06292 !important;
}
.border-top-pink.border-top-lighten-2 {
    border-top: 1px solid #f06292 !important;
}
.border-bottom-pink.border-bottom-lighten-2 {
    border-bottom: 1px solid #f06292 !important;
}
[dir='ltr'] .border-left-pink.border-left-lighten-2 {
    border-left: 1px solid #f06292 !important;
}
[dir='ltr'] .border-right-pink.border-right-lighten-2,
[dir='rtl'] .border-left-pink.border-left-lighten-2 {
    border-right: 1px solid #f06292 !important;
}
[dir='rtl'] .border-right-pink.border-right-lighten-2 {
    border-left: 1px solid #f06292 !important;
}
.overlay-pink.overlay-lighten-2 {
    background: #f06292;
    background: rgba(240, 98, 146, 0.8);
}
.pink.lighten-1 {
    color: #ec407a !important;
}
.bg-pink.bg-lighten-1,
.btn-pink.btn-lighten-1 {
    background-color: #ec407a !important;
}
.btn-pink.btn-lighten-1,
.btn-pink.btn-lighten-1:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-lighten-1:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-lighten-1:active,
.btn-pink.btn-lighten-1:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-lighten-1 {
    border-color: #ec407a !important;
    color: #ec407a !important;
}
.btn-outline-pink.btn-outline-lighten-1:hover {
    background-color: #ec407a !important;
}
.progress-pink.progress-lighten-1 {
    background-color: #ec407a;
}
.progress-pink.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ec407a;
}
.progress-pink.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ec407a;
}
.progress-pink.progress-lighten-1[value]::-ms-fill {
    background-color: #ec407a;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-lighten-1 .progress-bar {
        background-color: #ec407a;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ec407a !important;
}
.border-pink.border-lighten-1 {
    border: 1px solid #ec407a !important;
}
.border-top-pink.border-top-lighten-1 {
    border-top: 1px solid #ec407a !important;
}
.border-bottom-pink.border-bottom-lighten-1 {
    border-bottom: 1px solid #ec407a !important;
}
[dir='ltr'] .border-left-pink.border-left-lighten-1 {
    border-left: 1px solid #ec407a !important;
}
[dir='ltr'] .border-right-pink.border-right-lighten-1,
[dir='rtl'] .border-left-pink.border-left-lighten-1 {
    border-right: 1px solid #ec407a !important;
}
[dir='rtl'] .border-right-pink.border-right-lighten-1 {
    border-left: 1px solid #ec407a !important;
}
.overlay-pink.overlay-lighten-1 {
    background: #ec407a;
    background: rgba(236, 64, 122, 0.8);
}
.pink {
    color: #e91e63 !important;
}
.bg-pink {
    background-color: #e91e63 !important;
}
.bg-pink .card-footer,
.bg-pink .card-header {
    background-color: initial;
}
.toast-pink {
    background-color: #e91e63;
}
.alert-pink {
    border-color: #e91e63 !important;
    background-color: #ec3f7a !important;
    color: #4d081f !important;
}
.alert-pink .alert-link {
    color: #280410 !important;
}
.border-pink {
    border-color: #e91e63;
}
.border-top-pink {
    border-top-color: #e91e63;
}
.border-bottom-pink {
    border-bottom-color: #e91e63;
}
[dir='ltr'] .border-left-pink {
    border-left-color: #e91e63;
}
[dir='ltr'] .border-right-pink,
[dir='rtl'] .border-left-pink {
    border-right-color: #e91e63;
}
[dir='rtl'] .border-right-pink {
    border-left-color: #e91e63;
}
.badge-pink {
    background-color: #e91e63;
}
.panel-pink {
    border-color: #e91e63;
}
.panel-pink .panel-heading {
    color: #fff;
    border-color: #e91e63;
    background-color: #eb3573;
}
.bg-pink.tag-glow,
.border-pink.tag-glow {
    box-shadow: 0 0 10px #e91e63;
}
.overlay-pink {
    background: #e91e63;
    background: rgba(233, 30, 99, 0.8);
}
.card.card-outline-pink {
    border: 1px solid #e91e63;
}
.card.card-outline-pink,
.card.card-outline-pink .card-footer,
.card.card-outline-pink .card-header {
    background-color: initial;
}
.btn-pink.btn-flat {
    background-color: initial !important;
    color: #e91e63;
    border: none;
}
.btn-pink.btn-fab,
.btn-pink.btn-raised {
    background-color: #e91e63 !important;
    color: #fff !important;
    border-color: #e91e63;
}
.btn-pink.btn-fab.active,
.btn-pink.btn-raised.active {
    background-color: #cf1454 !important;
    border-color: #cf1454 !important;
}
.btn-group-raised .btn-pink {
    background-color: #e91e63 !important;
    color: #fff !important;
}
.btn-outline-pink {
    border: 1px solid #e91e63;
    background-color: initial;
    color: #e91e63;
    box-shadow: none !important;
}
.btn-outline-pink:focus {
    background-color: initial !important;
    color: #e91e63 !important;
    box-shadow: transparent !important;
}
.btn-outline-pink.active {
    background-color: #e91e63 !important;
    color: #fff !important;
}
.btn-outline-pink:hover {
    background-color: #e2165b !important;
    color: #fff !important;
}
.btn-outline-pink:hover svg {
    color: #fff !important;
}
.btn-outline-pink.btn-fab,
.btn-outline-pink.btn-raised {
    border: 1px solid #e91e63;
    background-color: initial;
    color: #e91e63;
    box-shadow: none !important;
}
.btn-outline-pink.btn-fab:focus,
.btn-outline-pink.btn-raised:focus {
    background-color: initial;
}
.btn-outline-pink.btn-fab.active,
.btn-outline-pink.btn-raised.active {
    background-color: #e91e63 !important;
    color: #fff !important;
}
.btn-outline-pink.btn-fab:hover,
.btn-outline-pink.btn-raised:hover {
    background-color: #e2165b !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-pink {
    background-color: #e91e63;
}
.progress .progress-bar.progress-bar-pink[value]::-webkit-progress-value {
    background-color: #e91e63;
}
.progress .progress-bar.progress-bar-pink[value]::-moz-progress-bar {
    background-color: #e91e63;
}
.progress .progress-bar.progress-bar-pink[value]::-ms-fill {
    background-color: #e91e63;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-pink .progress-bar {
        background-color: #e91e63;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e91e63 !important;
}
.pink.darken-1 {
    color: #d81b60 !important;
}
.bg-pink.bg-darken-1,
.btn-pink.btn-darken-1 {
    background-color: #d81b60 !important;
}
.btn-pink.btn-darken-1,
.btn-pink.btn-darken-1:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-darken-1:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-1:active,
.btn-pink.btn-darken-1:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-darken-1 {
    border-color: #d81b60 !important;
    color: #d81b60 !important;
}
.btn-outline-pink.btn-outline-darken-1:hover {
    background-color: #d81b60 !important;
}
.progress-pink.progress-darken-1 {
    background-color: #d81b60;
}
.progress-pink.progress-darken-1[value]::-webkit-progress-value {
    background-color: #d81b60;
}
.progress-pink.progress-darken-1[value]::-moz-progress-bar {
    background-color: #d81b60;
}
.progress-pink.progress-darken-1[value]::-ms-fill {
    background-color: #d81b60;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-darken-1 .progress-bar {
        background-color: #d81b60;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d81b60 !important;
}
.border-pink.border-darken-1 {
    border: 1px solid #d81b60 !important;
}
.border-top-pink.border-top-darken-1 {
    border-top: 1px solid #d81b60 !important;
}
.border-bottom-pink.border-bottom-darken-1 {
    border-bottom: 1px solid #d81b60 !important;
}
[dir='ltr'] .border-left-pink.border-left-darken-1 {
    border-left: 1px solid #d81b60 !important;
}
[dir='ltr'] .border-right-pink.border-right-darken-1,
[dir='rtl'] .border-left-pink.border-left-darken-1 {
    border-right: 1px solid #d81b60 !important;
}
[dir='rtl'] .border-right-pink.border-right-darken-1 {
    border-left: 1px solid #d81b60 !important;
}
.overlay-pink.overlay-darken-1 {
    background: #d81b60;
    background: rgba(216, 27, 96, 0.8);
}
.pink.darken-2 {
    color: #c2185b !important;
}
.bg-pink.bg-darken-2,
.btn-pink.btn-darken-2 {
    background-color: #c2185b !important;
}
.btn-pink.btn-darken-2,
.btn-pink.btn-darken-2:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-darken-2:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-2:active,
.btn-pink.btn-darken-2:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-darken-2 {
    border-color: #c2185b !important;
    color: #c2185b !important;
}
.btn-outline-pink.btn-outline-darken-2:hover {
    background-color: #c2185b !important;
}
.progress-pink.progress-darken-2 {
    background-color: #c2185b;
}
.progress-pink.progress-darken-2[value]::-webkit-progress-value {
    background-color: #c2185b;
}
.progress-pink.progress-darken-2[value]::-moz-progress-bar {
    background-color: #c2185b;
}
.progress-pink.progress-darken-2[value]::-ms-fill {
    background-color: #c2185b;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-darken-2 .progress-bar {
        background-color: #c2185b;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c2185b !important;
}
.border-pink.border-darken-2 {
    border: 1px solid #c2185b !important;
}
.border-top-pink.border-top-darken-2 {
    border-top: 1px solid #c2185b !important;
}
.border-bottom-pink.border-bottom-darken-2 {
    border-bottom: 1px solid #c2185b !important;
}
[dir='ltr'] .border-left-pink.border-left-darken-2 {
    border-left: 1px solid #c2185b !important;
}
[dir='ltr'] .border-right-pink.border-right-darken-2,
[dir='rtl'] .border-left-pink.border-left-darken-2 {
    border-right: 1px solid #c2185b !important;
}
[dir='rtl'] .border-right-pink.border-right-darken-2 {
    border-left: 1px solid #c2185b !important;
}
.overlay-pink.overlay-darken-2 {
    background: #c2185b;
    background: rgba(194, 24, 91, 0.8);
}
.pink.darken-3 {
    color: #ad1457 !important;
}
.bg-pink.bg-darken-3 {
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-3,
.btn-pink.btn-darken-3:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-3:active,
.btn-pink.btn-darken-3:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-darken-3 {
    border-color: #ad1457 !important;
    color: #ad1457 !important;
}
.btn-outline-pink.btn-outline-darken-3:hover {
    background-color: #ad1457 !important;
}
.progress-pink.progress-darken-3 {
    background-color: #ad1457;
}
.progress-pink.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ad1457;
}
.progress-pink.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ad1457;
}
.progress-pink.progress-darken-3[value]::-ms-fill {
    background-color: #ad1457;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-darken-3 .progress-bar {
        background-color: #ad1457;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ad1457 !important;
}
.border-pink.border-darken-3 {
    border: 1px solid #ad1457 !important;
}
.border-top-pink.border-top-darken-3 {
    border-top: 1px solid #ad1457 !important;
}
.border-bottom-pink.border-bottom-darken-3 {
    border-bottom: 1px solid #ad1457 !important;
}
[dir='ltr'] .border-left-pink.border-left-darken-3 {
    border-left: 1px solid #ad1457 !important;
}
[dir='ltr'] .border-right-pink.border-right-darken-3,
[dir='rtl'] .border-left-pink.border-left-darken-3 {
    border-right: 1px solid #ad1457 !important;
}
[dir='rtl'] .border-right-pink.border-right-darken-3 {
    border-left: 1px solid #ad1457 !important;
}
.overlay-pink.overlay-darken-3 {
    background: #ad1457;
    background: rgba(173, 20, 87, 0.8);
}
.pink.darken-4 {
    color: #880e4f !important;
}
.bg-pink.bg-darken-4,
.btn-pink.btn-darken-4 {
    background-color: #880e4f !important;
}
.btn-pink.btn-darken-4,
.btn-pink.btn-darken-4:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-darken-4:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-darken-4:active,
.btn-pink.btn-darken-4:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-darken-4 {
    border-color: #880e4f !important;
    color: #880e4f !important;
}
.btn-outline-pink.btn-outline-darken-4:hover {
    background-color: #880e4f !important;
}
.progress-pink.progress-darken-4 {
    background-color: #880e4f;
}
.progress-pink.progress-darken-4[value]::-webkit-progress-value {
    background-color: #880e4f;
}
.progress-pink.progress-darken-4[value]::-moz-progress-bar {
    background-color: #880e4f;
}
.progress-pink.progress-darken-4[value]::-ms-fill {
    background-color: #880e4f;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-darken-4 .progress-bar {
        background-color: #880e4f;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #880e4f !important;
}
.border-pink.border-darken-4 {
    border: 1px solid #880e4f !important;
}
.border-top-pink.border-top-darken-4 {
    border-top: 1px solid #880e4f !important;
}
.border-bottom-pink.border-bottom-darken-4 {
    border-bottom: 1px solid #880e4f !important;
}
[dir='ltr'] .border-left-pink.border-left-darken-4 {
    border-left: 1px solid #880e4f !important;
}
[dir='ltr'] .border-right-pink.border-right-darken-4,
[dir='rtl'] .border-left-pink.border-left-darken-4 {
    border-right: 1px solid #880e4f !important;
}
[dir='rtl'] .border-right-pink.border-right-darken-4 {
    border-left: 1px solid #880e4f !important;
}
.overlay-pink.overlay-darken-4 {
    background: #880e4f;
    background: rgba(136, 14, 79, 0.8);
}
.pink.accent-1 {
    color: #ff80ab !important;
}
.bg-pink.bg-accent-1,
.btn-pink.btn-accent-1 {
    background-color: #ff80ab !important;
}
.btn-pink.btn-accent-1,
.btn-pink.btn-accent-1:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-accent-1:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-1:active,
.btn-pink.btn-accent-1:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-accent-1 {
    border-color: #ff80ab !important;
    color: #ff80ab !important;
}
.btn-outline-pink.btn-outline-accent-1:hover {
    background-color: #ff80ab !important;
}
.progress-pink.progress-accent-1 {
    background-color: #ff80ab;
}
.progress-pink.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ff80ab;
}
.progress-pink.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ff80ab;
}
.progress-pink.progress-accent-1[value]::-ms-fill {
    background-color: #ff80ab;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-accent-1 .progress-bar {
        background-color: #ff80ab;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff80ab !important;
}
.border-pink.border-accent-1 {
    border: 1px solid #ff80ab !important;
}
.border-top-pink.border-top-accent-1 {
    border-top: 1px solid #ff80ab !important;
}
.border-bottom-pink.border-bottom-accent-1 {
    border-bottom: 1px solid #ff80ab !important;
}
[dir='ltr'] .border-left-pink.border-left-accent-1 {
    border-left: 1px solid #ff80ab !important;
}
[dir='ltr'] .border-right-pink.border-right-accent-1,
[dir='rtl'] .border-left-pink.border-left-accent-1 {
    border-right: 1px solid #ff80ab !important;
}
[dir='rtl'] .border-right-pink.border-right-accent-1 {
    border-left: 1px solid #ff80ab !important;
}
.overlay-pink.overlay-accent-1 {
    background: #ff80ab;
    background: rgba(255, 128, 171, 0.8);
}
.pink.accent-2 {
    color: #ff4081 !important;
}
.bg-pink.bg-accent-2,
.btn-pink.btn-accent-2 {
    background-color: #ff4081 !important;
}
.btn-pink.btn-accent-2,
.btn-pink.btn-accent-2:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-accent-2:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-2:active,
.btn-pink.btn-accent-2:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-accent-2 {
    border-color: #ff4081 !important;
    color: #ff4081 !important;
}
.btn-outline-pink.btn-outline-accent-2:hover {
    background-color: #ff4081 !important;
}
.progress-pink.progress-accent-2 {
    background-color: #ff4081;
}
.progress-pink.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff4081;
}
.progress-pink.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff4081;
}
.progress-pink.progress-accent-2[value]::-ms-fill {
    background-color: #ff4081;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-accent-2 .progress-bar {
        background-color: #ff4081;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff4081 !important;
}
.border-pink.border-accent-2 {
    border: 1px solid #ff4081 !important;
}
.border-top-pink.border-top-accent-2 {
    border-top: 1px solid #ff4081 !important;
}
.border-bottom-pink.border-bottom-accent-2 {
    border-bottom: 1px solid #ff4081 !important;
}
[dir='ltr'] .border-left-pink.border-left-accent-2 {
    border-left: 1px solid #ff4081 !important;
}
[dir='ltr'] .border-right-pink.border-right-accent-2,
[dir='rtl'] .border-left-pink.border-left-accent-2 {
    border-right: 1px solid #ff4081 !important;
}
[dir='rtl'] .border-right-pink.border-right-accent-2 {
    border-left: 1px solid #ff4081 !important;
}
.overlay-pink.overlay-accent-2 {
    background: #ff4081;
    background: rgba(255, 64, 129, 0.8);
}
.pink.accent-3 {
    color: #f50057 !important;
}
.bg-pink.bg-accent-3,
.btn-pink.btn-accent-3 {
    background-color: #f50057 !important;
}
.btn-pink.btn-accent-3,
.btn-pink.btn-accent-3:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-accent-3:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-3:active,
.btn-pink.btn-accent-3:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-accent-3 {
    border-color: #f50057 !important;
    color: #f50057 !important;
}
.btn-outline-pink.btn-outline-accent-3:hover {
    background-color: #f50057 !important;
}
.progress-pink.progress-accent-3 {
    background-color: #f50057;
}
.progress-pink.progress-accent-3[value]::-webkit-progress-value {
    background-color: #f50057;
}
.progress-pink.progress-accent-3[value]::-moz-progress-bar {
    background-color: #f50057;
}
.progress-pink.progress-accent-3[value]::-ms-fill {
    background-color: #f50057;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-accent-3 .progress-bar {
        background-color: #f50057;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f50057 !important;
}
.border-pink.border-accent-3 {
    border: 1px solid #f50057 !important;
}
.border-top-pink.border-top-accent-3 {
    border-top: 1px solid #f50057 !important;
}
.border-bottom-pink.border-bottom-accent-3 {
    border-bottom: 1px solid #f50057 !important;
}
[dir='ltr'] .border-left-pink.border-left-accent-3 {
    border-left: 1px solid #f50057 !important;
}
[dir='ltr'] .border-right-pink.border-right-accent-3,
[dir='rtl'] .border-left-pink.border-left-accent-3 {
    border-right: 1px solid #f50057 !important;
}
[dir='rtl'] .border-right-pink.border-right-accent-3 {
    border-left: 1px solid #f50057 !important;
}
.overlay-pink.overlay-accent-3 {
    background: #f50057;
    background: rgba(245, 0, 87, 0.8);
}
.pink.accent-4 {
    color: #c51162 !important;
}
.bg-pink.bg-accent-4,
.btn-pink.btn-accent-4 {
    background-color: #c51162 !important;
}
.btn-pink.btn-accent-4,
.btn-pink.btn-accent-4:hover {
    border-color: #c2185b !important;
}
.btn-pink.btn-accent-4:hover {
    background-color: #ad1457 !important;
}
.btn-pink.btn-accent-4:active,
.btn-pink.btn-accent-4:focus {
    border-color: #ad1457 !important;
    background-color: #880e4f !important;
}
.btn-outline-pink.btn-outline-accent-4 {
    border-color: #c51162 !important;
    color: #c51162 !important;
}
.btn-outline-pink.btn-outline-accent-4:hover {
    background-color: #c51162 !important;
}
.progress-pink.progress-accent-4 {
    background-color: #c51162;
}
.progress-pink.progress-accent-4[value]::-webkit-progress-value {
    background-color: #c51162;
}
.progress-pink.progress-accent-4[value]::-moz-progress-bar {
    background-color: #c51162;
}
.progress-pink.progress-accent-4[value]::-ms-fill {
    background-color: #c51162;
}
@media screen and (min-width: 0\0) {
    .progress-pink.progress-accent-4 .progress-bar {
        background-color: #c51162;
    }
}
input:focus ~ .bg-pink {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c51162 !important;
}
.border-pink.border-accent-4 {
    border: 1px solid #c51162 !important;
}
.border-top-pink.border-top-accent-4 {
    border-top: 1px solid #c51162 !important;
}
.border-bottom-pink.border-bottom-accent-4 {
    border-bottom: 1px solid #c51162 !important;
}
[dir='ltr'] .border-left-pink.border-left-accent-4 {
    border-left: 1px solid #c51162 !important;
}
[dir='ltr'] .border-right-pink.border-right-accent-4,
[dir='rtl'] .border-left-pink.border-left-accent-4 {
    border-right: 1px solid #c51162 !important;
}
[dir='rtl'] .border-right-pink.border-right-accent-4 {
    border-left: 1px solid #c51162 !important;
}
.overlay-pink.overlay-accent-4 {
    background: #c51162;
    background: rgba(197, 17, 98, 0.8);
}
.purple.lighten-5 {
    color: #f3e5f5 !important;
}
.bg-purple.bg-lighten-5,
.btn-purple.btn-lighten-5 {
    background-color: #f3e5f5 !important;
}
.btn-purple.btn-lighten-5 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-lighten-5:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-5:active,
.btn-purple.btn-lighten-5:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-lighten-5 {
    border-color: #f3e5f5 !important;
    color: #f3e5f5 !important;
}
.btn-outline-purple.btn-outline-lighten-5:hover {
    background-color: #f3e5f5 !important;
}
.progress-purple.progress-lighten-5 {
    background-color: #f3e5f5;
}
.progress-purple.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #f3e5f5;
}
.progress-purple.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #f3e5f5;
}
.progress-purple.progress-lighten-5[value]::-ms-fill {
    background-color: #f3e5f5;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-lighten-5 .progress-bar {
        background-color: #f3e5f5;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f3e5f5 !important;
}
.border-purple.border-lighten-5 {
    border: 1px solid #f3e5f5 !important;
}
.border-top-purple.border-top-lighten-5 {
    border-top: 1px solid #f3e5f5 !important;
}
.border-bottom-purple.border-bottom-lighten-5 {
    border-bottom: 1px solid #f3e5f5 !important;
}
[dir='ltr'] .border-left-purple.border-left-lighten-5 {
    border-left: 1px solid #f3e5f5 !important;
}
[dir='ltr'] .border-right-purple.border-right-lighten-5,
[dir='rtl'] .border-left-purple.border-left-lighten-5 {
    border-right: 1px solid #f3e5f5 !important;
}
[dir='rtl'] .border-right-purple.border-right-lighten-5 {
    border-left: 1px solid #f3e5f5 !important;
}
.overlay-purple.overlay-lighten-5 {
    background: #f3e5f5;
    background: rgba(243, 229, 245, 0.8);
}
.purple.lighten-4 {
    color: #e1bee7 !important;
}
.bg-purple.bg-lighten-4,
.btn-purple.btn-lighten-4 {
    background-color: #e1bee7 !important;
}
.btn-purple.btn-lighten-4 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-lighten-4:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-4:active,
.btn-purple.btn-lighten-4:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-lighten-4 {
    border-color: #e1bee7 !important;
    color: #e1bee7 !important;
}
.btn-outline-purple.btn-outline-lighten-4:hover {
    background-color: #e1bee7 !important;
}
.progress-purple.progress-lighten-4 {
    background-color: #e1bee7;
}
.progress-purple.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #e1bee7;
}
.progress-purple.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #e1bee7;
}
.progress-purple.progress-lighten-4[value]::-ms-fill {
    background-color: #e1bee7;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-lighten-4 .progress-bar {
        background-color: #e1bee7;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e1bee7 !important;
}
.border-purple.border-lighten-4 {
    border: 1px solid #e1bee7 !important;
}
.border-top-purple.border-top-lighten-4 {
    border-top: 1px solid #e1bee7 !important;
}
.border-bottom-purple.border-bottom-lighten-4 {
    border-bottom: 1px solid #e1bee7 !important;
}
[dir='ltr'] .border-left-purple.border-left-lighten-4 {
    border-left: 1px solid #e1bee7 !important;
}
[dir='ltr'] .border-right-purple.border-right-lighten-4,
[dir='rtl'] .border-left-purple.border-left-lighten-4 {
    border-right: 1px solid #e1bee7 !important;
}
[dir='rtl'] .border-right-purple.border-right-lighten-4 {
    border-left: 1px solid #e1bee7 !important;
}
.overlay-purple.overlay-lighten-4 {
    background: #e1bee7;
    background: rgba(225, 190, 231, 0.8);
}
.purple.lighten-3 {
    color: #ce93d8 !important;
}
.bg-purple.bg-lighten-3,
.btn-purple.btn-lighten-3 {
    background-color: #ce93d8 !important;
}
.btn-purple.btn-lighten-3 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-lighten-3:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-3:active,
.btn-purple.btn-lighten-3:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-lighten-3 {
    border-color: #ce93d8 !important;
    color: #ce93d8 !important;
}
.btn-outline-purple.btn-outline-lighten-3:hover {
    background-color: #ce93d8 !important;
}
.progress-purple.progress-lighten-3 {
    background-color: #ce93d8;
}
.progress-purple.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ce93d8;
}
.progress-purple.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ce93d8;
}
.progress-purple.progress-lighten-3[value]::-ms-fill {
    background-color: #ce93d8;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-lighten-3 .progress-bar {
        background-color: #ce93d8;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ce93d8 !important;
}
.border-purple.border-lighten-3 {
    border: 1px solid #ce93d8 !important;
}
.border-top-purple.border-top-lighten-3 {
    border-top: 1px solid #ce93d8 !important;
}
.border-bottom-purple.border-bottom-lighten-3 {
    border-bottom: 1px solid #ce93d8 !important;
}
[dir='ltr'] .border-left-purple.border-left-lighten-3 {
    border-left: 1px solid #ce93d8 !important;
}
[dir='ltr'] .border-right-purple.border-right-lighten-3,
[dir='rtl'] .border-left-purple.border-left-lighten-3 {
    border-right: 1px solid #ce93d8 !important;
}
[dir='rtl'] .border-right-purple.border-right-lighten-3 {
    border-left: 1px solid #ce93d8 !important;
}
.overlay-purple.overlay-lighten-3 {
    background: #ce93d8;
    background: rgba(206, 147, 216, 0.8);
}
.purple.lighten-2 {
    color: #ba68c8 !important;
}
.bg-purple.bg-lighten-2,
.btn-purple.btn-lighten-2 {
    background-color: #ba68c8 !important;
}
.btn-purple.btn-lighten-2 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-lighten-2:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-2:active,
.btn-purple.btn-lighten-2:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-lighten-2 {
    border-color: #ba68c8 !important;
    color: #ba68c8 !important;
}
.btn-outline-purple.btn-outline-lighten-2:hover {
    background-color: #ba68c8 !important;
}
.progress-purple.progress-lighten-2 {
    background-color: #ba68c8;
}
.progress-purple.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ba68c8;
}
.progress-purple.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ba68c8;
}
.progress-purple.progress-lighten-2[value]::-ms-fill {
    background-color: #ba68c8;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-lighten-2 .progress-bar {
        background-color: #ba68c8;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ba68c8 !important;
}
.border-purple.border-lighten-2 {
    border: 1px solid #ba68c8 !important;
}
.border-top-purple.border-top-lighten-2 {
    border-top: 1px solid #ba68c8 !important;
}
.border-bottom-purple.border-bottom-lighten-2 {
    border-bottom: 1px solid #ba68c8 !important;
}
[dir='ltr'] .border-left-purple.border-left-lighten-2 {
    border-left: 1px solid #ba68c8 !important;
}
[dir='ltr'] .border-right-purple.border-right-lighten-2,
[dir='rtl'] .border-left-purple.border-left-lighten-2 {
    border-right: 1px solid #ba68c8 !important;
}
[dir='rtl'] .border-right-purple.border-right-lighten-2 {
    border-left: 1px solid #ba68c8 !important;
}
.overlay-purple.overlay-lighten-2 {
    background: #ba68c8;
    background: rgba(186, 104, 200, 0.8);
}
.purple.lighten-1 {
    color: #ab47bc !important;
}
.bg-purple.bg-lighten-1,
.btn-purple.btn-lighten-1 {
    background-color: #ab47bc !important;
}
.btn-purple.btn-lighten-1 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-lighten-1:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-lighten-1:active,
.btn-purple.btn-lighten-1:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-lighten-1 {
    border-color: #ab47bc !important;
    color: #ab47bc !important;
}
.btn-outline-purple.btn-outline-lighten-1:hover {
    background-color: #ab47bc !important;
}
.progress-purple.progress-lighten-1 {
    background-color: #ab47bc;
}
.progress-purple.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ab47bc;
}
.progress-purple.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ab47bc;
}
.progress-purple.progress-lighten-1[value]::-ms-fill {
    background-color: #ab47bc;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-lighten-1 .progress-bar {
        background-color: #ab47bc;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ab47bc !important;
}
.border-purple.border-lighten-1 {
    border: 1px solid #ab47bc !important;
}
.border-top-purple.border-top-lighten-1 {
    border-top: 1px solid #ab47bc !important;
}
.border-bottom-purple.border-bottom-lighten-1 {
    border-bottom: 1px solid #ab47bc !important;
}
[dir='ltr'] .border-left-purple.border-left-lighten-1 {
    border-left: 1px solid #ab47bc !important;
}
[dir='ltr'] .border-right-purple.border-right-lighten-1,
[dir='rtl'] .border-left-purple.border-left-lighten-1 {
    border-right: 1px solid #ab47bc !important;
}
[dir='rtl'] .border-right-purple.border-right-lighten-1 {
    border-left: 1px solid #ab47bc !important;
}
.overlay-purple.overlay-lighten-1 {
    background: #ab47bc;
    background: rgba(171, 71, 188, 0.8);
}
.purple {
    color: #9c27b0 !important;
}
.bg-purple {
    background-color: #9c27b0 !important;
}
.bg-purple .card-footer,
.bg-purple .card-header {
    background-color: initial;
}
.toast-purple {
    background-color: #9c27b0;
}
.alert-purple {
    border-color: #9c27b0 !important;
    background-color: #b62dcd !important;
    color: #1a071e !important;
}
.alert-purple .alert-link {
    color: #000 !important;
}
.border-purple {
    border-color: #9c27b0;
}
.border-top-purple {
    border-top-color: #9c27b0;
}
.border-bottom-purple {
    border-bottom-color: #9c27b0;
}
[dir='ltr'] .border-left-purple {
    border-left-color: #9c27b0;
}
[dir='ltr'] .border-right-purple,
[dir='rtl'] .border-left-purple {
    border-right-color: #9c27b0;
}
[dir='rtl'] .border-right-purple {
    border-left-color: #9c27b0;
}
.badge-purple {
    background-color: #9c27b0;
}
.panel-purple {
    border-color: #9c27b0;
}
.panel-purple .panel-heading {
    color: #fff;
    border-color: #9c27b0;
    background-color: #af2cc5;
}
.bg-purple.tag-glow,
.border-purple.tag-glow {
    box-shadow: 0 0 10px #9c27b0;
}
.overlay-purple {
    background: #9c27b0;
    background: rgba(156, 39, 176, 0.8);
}
.card.card-outline-purple {
    border: 1px solid #9c27b0;
    background-color: initial;
}
.card.card-outline-purple .card-footer,
.card.card-outline-purple .card-header {
    background-color: initial;
}
.btn-purple.btn-flat {
    background-color: initial !important;
    color: #9c27b0;
    border: none;
}
.btn-purple.btn-fab,
.btn-purple.btn-raised {
    background-color: #9c27b0 !important;
    color: #fff !important;
    border-color: #9c27b0;
}
.btn-purple.btn-fab.active,
.btn-purple.btn-raised.active {
    background-color: #822193 !important;
    border-color: #822193 !important;
}
.btn-group-raised .btn-purple {
    background-color: #9c27b0 !important;
    color: #fff !important;
}
.btn-outline-purple {
    border: 1px solid #9c27b0;
    background-color: initial;
    color: #9c27b0;
    box-shadow: none !important;
}
.btn-outline-purple:focus {
    background-color: initial !important;
    color: #9c27b0 !important;
    box-shadow: transparent !important;
}
.btn-outline-purple.active {
    background-color: #9c27b0 !important;
    color: #fff !important;
}
.btn-outline-purple:hover {
    background-color: #9124a3 !important;
    color: #fff !important;
}
.btn-outline-purple:hover svg {
    color: #fff !important;
}
.btn-outline-purple.btn-fab,
.btn-outline-purple.btn-raised {
    border: 1px solid #9c27b0;
    background-color: initial;
    color: #9c27b0;
    box-shadow: none !important;
}
.btn-outline-purple.btn-fab:focus,
.btn-outline-purple.btn-raised:focus {
    background-color: initial;
}
.btn-outline-purple.btn-fab.active,
.btn-outline-purple.btn-raised.active {
    background-color: #9c27b0 !important;
    color: #fff !important;
}
.btn-outline-purple.btn-fab:hover,
.btn-outline-purple.btn-raised:hover {
    background-color: #9124a3 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-purple {
    background-color: #9c27b0;
}
.progress .progress-bar.progress-bar-purple[value]::-webkit-progress-value {
    background-color: #9c27b0;
}
.progress .progress-bar.progress-bar-purple[value]::-moz-progress-bar {
    background-color: #9c27b0;
}
.progress .progress-bar.progress-bar-purple[value]::-ms-fill {
    background-color: #9c27b0;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-purple .progress-bar {
        background-color: #9c27b0;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9c27b0 !important;
}
.purple.darken-1 {
    color: #8e24aa !important;
}
.bg-purple.bg-darken-1,
.btn-purple.btn-darken-1 {
    background-color: #8e24aa !important;
}
.btn-purple.btn-darken-1 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-darken-1:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-1:active,
.btn-purple.btn-darken-1:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-darken-1 {
    border-color: #8e24aa !important;
    color: #8e24aa !important;
}
.btn-outline-purple.btn-outline-darken-1:hover {
    background-color: #8e24aa !important;
}
.progress-purple.progress-darken-1 {
    background-color: #8e24aa;
}
.progress-purple.progress-darken-1[value]::-webkit-progress-value {
    background-color: #8e24aa;
}
.progress-purple.progress-darken-1[value]::-moz-progress-bar {
    background-color: #8e24aa;
}
.progress-purple.progress-darken-1[value]::-ms-fill {
    background-color: #8e24aa;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-darken-1 .progress-bar {
        background-color: #8e24aa;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8e24aa !important;
}
.border-purple.border-darken-1 {
    border: 1px solid #8e24aa !important;
}
.border-top-purple.border-top-darken-1 {
    border-top: 1px solid #8e24aa !important;
}
.border-bottom-purple.border-bottom-darken-1 {
    border-bottom: 1px solid #8e24aa !important;
}
.overlay-purple.overlay-darken-1 {
    background: #8e24aa;
    background: rgba(142, 36, 170, 0.8);
}
.purple.darken-2 {
    color: #7b1fa2 !important;
}
.bg-purple.bg-darken-2,
.btn-purple.btn-darken-2 {
    background-color: #7b1fa2 !important;
}
.btn-purple.btn-darken-2 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-darken-2:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-2:active,
.btn-purple.btn-darken-2:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-darken-2 {
    border-color: #7b1fa2 !important;
    color: #7b1fa2 !important;
}
.btn-outline-purple.btn-outline-darken-2:hover {
    background-color: #7b1fa2 !important;
}
.progress-purple.progress-darken-2 {
    background-color: #7b1fa2;
}
.progress-purple.progress-darken-2[value]::-webkit-progress-value {
    background-color: #7b1fa2;
}
.progress-purple.progress-darken-2[value]::-moz-progress-bar {
    background-color: #7b1fa2;
}
.progress-purple.progress-darken-2[value]::-ms-fill {
    background-color: #7b1fa2;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-darken-2 .progress-bar {
        background-color: #7b1fa2;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7b1fa2 !important;
}
.border-purple.border-darken-2 {
    border: 1px solid #7b1fa2 !important;
}
.border-top-purple.border-top-darken-2 {
    border-top: 1px solid #7b1fa2 !important;
}
.border-bottom-purple.border-bottom-darken-2 {
    border-bottom: 1px solid #7b1fa2 !important;
}
.overlay-purple.overlay-darken-2 {
    background: #7b1fa2;
    background: rgba(123, 31, 162, 0.8);
}
.purple.darken-3 {
    color: #6a1b9a !important;
}
.bg-purple.bg-darken-3 {
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-3,
.btn-purple.btn-darken-3:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-3:active,
.btn-purple.btn-darken-3:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-darken-3 {
    border-color: #6a1b9a !important;
    color: #6a1b9a !important;
}
.btn-outline-purple.btn-outline-darken-3:hover {
    background-color: #6a1b9a !important;
}
.progress-purple.progress-darken-3 {
    background-color: #6a1b9a;
}
.progress-purple.progress-darken-3[value]::-webkit-progress-value {
    background-color: #6a1b9a;
}
.progress-purple.progress-darken-3[value]::-moz-progress-bar {
    background-color: #6a1b9a;
}
.progress-purple.progress-darken-3[value]::-ms-fill {
    background-color: #6a1b9a;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-darken-3 .progress-bar {
        background-color: #6a1b9a;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6a1b9a !important;
}
.border-purple.border-darken-3 {
    border: 1px solid #6a1b9a !important;
}
.border-top-purple.border-top-darken-3 {
    border-top: 1px solid #6a1b9a !important;
}
.border-bottom-purple.border-bottom-darken-3 {
    border-bottom: 1px solid #6a1b9a !important;
}
.overlay-purple.overlay-darken-3 {
    background: #6a1b9a;
    background: rgba(106, 27, 154, 0.8);
}
.purple.darken-4 {
    color: #4a148c !important;
}
.bg-purple.bg-darken-4,
.btn-purple.btn-darken-4 {
    background-color: #4a148c !important;
}
.btn-purple.btn-darken-4 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-darken-4:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-darken-4:active,
.btn-purple.btn-darken-4:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-darken-4 {
    border-color: #4a148c !important;
    color: #4a148c !important;
}
.btn-outline-purple.btn-outline-darken-4:hover {
    background-color: #4a148c !important;
}
.progress-purple.progress-darken-4 {
    background-color: #4a148c;
}
.progress-purple.progress-darken-4[value]::-webkit-progress-value {
    background-color: #4a148c;
}
.progress-purple.progress-darken-4[value]::-moz-progress-bar {
    background-color: #4a148c;
}
.progress-purple.progress-darken-4[value]::-ms-fill {
    background-color: #4a148c;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-darken-4 .progress-bar {
        background-color: #4a148c;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4a148c !important;
}
.border-purple.border-darken-4 {
    border: 1px solid #4a148c !important;
}
.border-top-purple.border-top-darken-4 {
    border-top: 1px solid #4a148c !important;
}
.border-bottom-purple.border-bottom-darken-4 {
    border-bottom: 1px solid #4a148c !important;
}
.overlay-purple.overlay-darken-4 {
    background: #4a148c;
    background: rgba(74, 20, 140, 0.8);
}
.purple.accent-1 {
    color: #ea80fc !important;
}
.bg-purple.bg-accent-1,
.btn-purple.btn-accent-1 {
    background-color: #ea80fc !important;
}
.btn-purple.btn-accent-1 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-accent-1:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-1:active,
.btn-purple.btn-accent-1:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-accent-1 {
    border-color: #ea80fc !important;
    color: #ea80fc !important;
}
.btn-outline-purple.btn-outline-accent-1:hover {
    background-color: #ea80fc !important;
}
.progress-purple.progress-accent-1 {
    background-color: #ea80fc;
}
.progress-purple.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ea80fc;
}
.progress-purple.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ea80fc;
}
.progress-purple.progress-accent-1[value]::-ms-fill {
    background-color: #ea80fc;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-accent-1 .progress-bar {
        background-color: #ea80fc;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ea80fc !important;
}
.border-purple.border-accent-1 {
    border: 1px solid #ea80fc !important;
}
.border-top-purple.border-top-accent-1 {
    border-top: 1px solid #ea80fc !important;
}
.border-bottom-purple.border-bottom-accent-1 {
    border-bottom: 1px solid #ea80fc !important;
}
.overlay-purple.overlay-accent-1 {
    background: #ea80fc;
    background: rgba(234, 128, 252, 0.8);
}
.purple.accent-2 {
    color: #e040fb !important;
}
.bg-purple.bg-accent-2,
.btn-purple.btn-accent-2 {
    background-color: #e040fb !important;
}
.btn-purple.btn-accent-2 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-accent-2:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-2:active,
.btn-purple.btn-accent-2:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-accent-2 {
    border-color: #e040fb !important;
    color: #e040fb !important;
}
.btn-outline-purple.btn-outline-accent-2:hover {
    background-color: #e040fb !important;
}
.progress-purple.progress-accent-2 {
    background-color: #e040fb;
}
.progress-purple.progress-accent-2[value]::-webkit-progress-value {
    background-color: #e040fb;
}
.progress-purple.progress-accent-2[value]::-moz-progress-bar {
    background-color: #e040fb;
}
.progress-purple.progress-accent-2[value]::-ms-fill {
    background-color: #e040fb;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-accent-2 .progress-bar {
        background-color: #e040fb;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e040fb !important;
}
.border-purple.border-accent-2 {
    border: 1px solid #e040fb !important;
}
.border-top-purple.border-top-accent-2 {
    border-top: 1px solid #e040fb !important;
}
.border-bottom-purple.border-bottom-accent-2 {
    border-bottom: 1px solid #e040fb !important;
}
.overlay-purple.overlay-accent-2 {
    background: #e040fb;
    background: rgba(224, 64, 251, 0.8);
}
.purple.accent-3 {
    color: #d500f9 !important;
}
.bg-purple.bg-accent-3,
.btn-purple.btn-accent-3 {
    background-color: #d500f9 !important;
}
.btn-purple.btn-accent-3 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-accent-3:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-3:active,
.btn-purple.btn-accent-3:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-accent-3 {
    border-color: #d500f9 !important;
    color: #d500f9 !important;
}
.btn-outline-purple.btn-outline-accent-3:hover {
    background-color: #d500f9 !important;
}
.progress-purple.progress-accent-3 {
    background-color: #d500f9;
}
.progress-purple.progress-accent-3[value]::-webkit-progress-value {
    background-color: #d500f9;
}
.progress-purple.progress-accent-3[value]::-moz-progress-bar {
    background-color: #d500f9;
}
.progress-purple.progress-accent-3[value]::-ms-fill {
    background-color: #d500f9;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-accent-3 .progress-bar {
        background-color: #d500f9;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d500f9 !important;
}
.border-purple.border-accent-3 {
    border: 1px solid #d500f9 !important;
}
.border-top-purple.border-top-accent-3 {
    border-top: 1px solid #d500f9 !important;
}
.border-bottom-purple.border-bottom-accent-3 {
    border-bottom: 1px solid #d500f9 !important;
}
.overlay-purple.overlay-accent-3 {
    background: #d500f9;
    background: rgba(213, 0, 249, 0.8);
}
.purple.accent-4 {
    color: #a0f !important;
}
.bg-purple.bg-accent-4,
.btn-purple.btn-accent-4 {
    background-color: #a0f !important;
}
.btn-purple.btn-accent-4 {
    border-color: #7b1fa2 !important;
}
.btn-purple.btn-accent-4:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important;
}
.btn-purple.btn-accent-4:active,
.btn-purple.btn-accent-4:focus {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important;
}
.btn-outline-purple.btn-outline-accent-4 {
    border-color: #a0f !important;
    color: #a0f !important;
}
.btn-outline-purple.btn-outline-accent-4:hover {
    background-color: #a0f !important;
}
.progress-purple.progress-accent-4 {
    background-color: #a0f;
}
.progress-purple.progress-accent-4[value]::-webkit-progress-value {
    background-color: #a0f;
}
.progress-purple.progress-accent-4[value]::-moz-progress-bar {
    background-color: #a0f;
}
.progress-purple.progress-accent-4[value]::-ms-fill {
    background-color: #a0f;
}
@media screen and (min-width: 0\0) {
    .progress-purple.progress-accent-4 .progress-bar {
        background-color: #a0f;
    }
}
input:focus ~ .bg-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a0f !important;
}
.border-purple.border-accent-4 {
    border: 1px solid #a0f !important;
}
.border-top-purple.border-top-accent-4 {
    border-top: 1px solid #a0f !important;
}
.border-bottom-purple.border-bottom-accent-4 {
    border-bottom: 1px solid #a0f !important;
}
.overlay-purple.overlay-accent-4 {
    background: #a0f;
    background: rgba(170, 0, 255, 0.8);
}
.deep-purple.lighten-5 {
    color: #ede7f6 !important;
}
.bg-deep-purple.bg-lighten-5,
.btn-deep-purple.btn-lighten-5 {
    background-color: #ede7f6 !important;
}
.btn-deep-purple.btn-lighten-5 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-lighten-5:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-5:active,
.btn-deep-purple.btn-lighten-5:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-5 {
    border-color: #ede7f6 !important;
    color: #ede7f6 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-5:hover {
    background-color: #ede7f6 !important;
}
.progress-deep-purple.progress-lighten-5 {
    background-color: #ede7f6;
}
.progress-deep-purple.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #ede7f6;
}
.progress-deep-purple.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #ede7f6;
}
.progress-deep-purple.progress-lighten-5[value]::-ms-fill {
    background-color: #ede7f6;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-lighten-5 .progress-bar {
        background-color: #ede7f6;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ede7f6 !important;
}
.border-deep-purple.border-lighten-5 {
    border: 1px solid #ede7f6 !important;
}
.border-top-deep-purple.border-top-lighten-5 {
    border-top: 1px solid #ede7f6 !important;
}
.border-bottom-deep-purple.border-bottom-lighten-5 {
    border-bottom: 1px solid #ede7f6 !important;
}
.overlay-deep-purple.overlay-lighten-5 {
    background: #ede7f6;
    background: rgba(237, 231, 246, 0.8);
}
.deep-purple.lighten-4 {
    color: #d1c4e9 !important;
}
.bg-deep-purple.bg-lighten-4,
.btn-deep-purple.btn-lighten-4 {
    background-color: #d1c4e9 !important;
}
.btn-deep-purple.btn-lighten-4 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-lighten-4:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-4:active,
.btn-deep-purple.btn-lighten-4:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-4 {
    border-color: #d1c4e9 !important;
    color: #d1c4e9 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-4:hover {
    background-color: #d1c4e9 !important;
}
.progress-deep-purple.progress-lighten-4 {
    background-color: #d1c4e9;
}
.progress-deep-purple.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #d1c4e9;
}
.progress-deep-purple.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #d1c4e9;
}
.progress-deep-purple.progress-lighten-4[value]::-ms-fill {
    background-color: #d1c4e9;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-lighten-4 .progress-bar {
        background-color: #d1c4e9;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d1c4e9 !important;
}
.border-deep-purple.border-lighten-4 {
    border: 1px solid #d1c4e9 !important;
}
.border-top-deep-purple.border-top-lighten-4 {
    border-top: 1px solid #d1c4e9 !important;
}
.border-bottom-deep-purple.border-bottom-lighten-4 {
    border-bottom: 1px solid #d1c4e9 !important;
}
.overlay-deep-purple.overlay-lighten-4 {
    background: #d1c4e9;
    background: rgba(209, 196, 233, 0.8);
}
.deep-purple.lighten-3 {
    color: #b39ddb !important;
}
.bg-deep-purple.bg-lighten-3,
.btn-deep-purple.btn-lighten-3 {
    background-color: #b39ddb !important;
}
.btn-deep-purple.btn-lighten-3 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-lighten-3:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-3:active,
.btn-deep-purple.btn-lighten-3:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-3 {
    border-color: #b39ddb !important;
    color: #b39ddb !important;
}
.btn-outline-deep-purple.btn-outline-lighten-3:hover {
    background-color: #b39ddb !important;
}
.progress-deep-purple.progress-lighten-3 {
    background-color: #b39ddb;
}
.progress-deep-purple.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #b39ddb;
}
.progress-deep-purple.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #b39ddb;
}
.progress-deep-purple.progress-lighten-3[value]::-ms-fill {
    background-color: #b39ddb;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-lighten-3 .progress-bar {
        background-color: #b39ddb;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b39ddb !important;
}
.border-deep-purple.border-lighten-3 {
    border: 1px solid #b39ddb !important;
}
.border-top-deep-purple.border-top-lighten-3 {
    border-top: 1px solid #b39ddb !important;
}
.border-bottom-deep-purple.border-bottom-lighten-3 {
    border-bottom: 1px solid #b39ddb !important;
}
.overlay-deep-purple.overlay-lighten-3 {
    background: #b39ddb;
    background: rgba(179, 157, 219, 0.8);
}
.deep-purple.lighten-2 {
    color: #9575cd !important;
}
.bg-deep-purple.bg-lighten-2,
.btn-deep-purple.btn-lighten-2 {
    background-color: #9575cd !important;
}
.btn-deep-purple.btn-lighten-2 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-lighten-2:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-2:active,
.btn-deep-purple.btn-lighten-2:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-2 {
    border-color: #9575cd !important;
    color: #9575cd !important;
}
.btn-outline-deep-purple.btn-outline-lighten-2:hover {
    background-color: #9575cd !important;
}
.progress-deep-purple.progress-lighten-2 {
    background-color: #9575cd;
}
.progress-deep-purple.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #9575cd;
}
.progress-deep-purple.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #9575cd;
}
.progress-deep-purple.progress-lighten-2[value]::-ms-fill {
    background-color: #9575cd;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-lighten-2 .progress-bar {
        background-color: #9575cd;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9575cd !important;
}
.border-deep-purple.border-lighten-2 {
    border: 1px solid #9575cd !important;
}
.border-top-deep-purple.border-top-lighten-2 {
    border-top: 1px solid #9575cd !important;
}
.border-bottom-deep-purple.border-bottom-lighten-2 {
    border-bottom: 1px solid #9575cd !important;
}
.overlay-deep-purple.overlay-lighten-2 {
    background: #9575cd;
    background: rgba(149, 117, 205, 0.8);
}
.deep-purple.lighten-1 {
    color: #7e57c2 !important;
}
.bg-deep-purple.bg-lighten-1,
.btn-deep-purple.btn-lighten-1 {
    background-color: #7e57c2 !important;
}
.btn-deep-purple.btn-lighten-1 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-lighten-1:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-lighten-1:active,
.btn-deep-purple.btn-lighten-1:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-1 {
    border-color: #7e57c2 !important;
    color: #7e57c2 !important;
}
.btn-outline-deep-purple.btn-outline-lighten-1:hover {
    background-color: #7e57c2 !important;
}
.progress-deep-purple.progress-lighten-1 {
    background-color: #7e57c2;
}
.progress-deep-purple.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #7e57c2;
}
.progress-deep-purple.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #7e57c2;
}
.progress-deep-purple.progress-lighten-1[value]::-ms-fill {
    background-color: #7e57c2;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-lighten-1 .progress-bar {
        background-color: #7e57c2;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7e57c2 !important;
}
.border-deep-purple.border-lighten-1 {
    border: 1px solid #7e57c2 !important;
}
.border-top-deep-purple.border-top-lighten-1 {
    border-top: 1px solid #7e57c2 !important;
}
.border-bottom-deep-purple.border-bottom-lighten-1 {
    border-bottom: 1px solid #7e57c2 !important;
}
.overlay-deep-purple.overlay-lighten-1 {
    background: #7e57c2;
    background: rgba(126, 87, 194, 0.8);
}
.deep-purple {
    color: #673ab7 !important;
}
.bg-deep-purple {
    background-color: #673ab7 !important;
}
.bg-deep-purple .card-footer,
.bg-deep-purple .card-header {
    background-color: initial;
}
.toast-deep-purple {
    background-color: #673ab7;
}
.alert-deep-purple {
    border-color: #673ab7 !important;
    background-color: #794ec7 !important;
    color: #1b0f2f !important;
}
.alert-deep-purple .alert-link {
    color: #090510 !important;
}
.border-deep-purple {
    border-color: #673ab7;
}
.border-top-deep-purple {
    border-top-color: #673ab7;
}
.border-bottom-deep-purple {
    border-bottom-color: #673ab7;
}
.badge-deep-purple {
    background-color: #673ab7;
}
.panel-deep-purple {
    border-color: #673ab7;
}
.panel-deep-purple .panel-heading {
    color: #fff;
    border-color: #673ab7;
    background-color: #7446c4;
}
.bg-deep-purple.tag-glow,
.border-deep-purple.tag-glow {
    box-shadow: 0 0 10px #673ab7;
}
.overlay-deep-purple {
    background: #673ab7;
    background: rgba(103, 58, 183, 0.8);
}
.card.card-outline-deep-purple {
    border: 1px solid #673ab7;
    background-color: initial;
}
.card.card-outline-deep-purple .card-footer,
.card.card-outline-deep-purple .card-header {
    background-color: initial;
}
.btn-deep-purple.btn-flat {
    background-color: initial !important;
    color: #673ab7;
    border: none;
}
.btn-deep-purple.btn-fab,
.btn-deep-purple.btn-raised {
    background-color: #673ab7 !important;
    color: #fff !important;
    border-color: #673ab7;
}
.btn-deep-purple.btn-fab.active,
.btn-deep-purple.btn-raised.active {
    background-color: #58319c !important;
    border-color: #58319c !important;
}
.btn-group-raised .btn-deep-purple {
    background-color: #673ab7 !important;
    color: #fff !important;
}
.btn-outline-deep-purple {
    border: 1px solid #673ab7;
    background-color: initial;
    color: #673ab7;
    box-shadow: none !important;
}
.btn-outline-deep-purple:focus {
    background-color: initial !important;
    color: #673ab7 !important;
    box-shadow: transparent !important;
}
.btn-outline-deep-purple.active {
    background-color: #673ab7 !important;
    color: #fff !important;
}
.btn-outline-deep-purple:hover {
    background-color: #6036ab !important;
    color: #fff !important;
}
.btn-outline-deep-purple:hover svg {
    color: #fff !important;
}
.btn-outline-deep-purple.btn-fab,
.btn-outline-deep-purple.btn-raised {
    border: 1px solid #673ab7;
    background-color: initial;
    color: #673ab7;
    box-shadow: none !important;
}
.btn-outline-deep-purple.btn-fab:focus,
.btn-outline-deep-purple.btn-raised:focus {
    background-color: initial;
}
.btn-outline-deep-purple.btn-fab.active,
.btn-outline-deep-purple.btn-raised.active {
    background-color: #673ab7 !important;
    color: #fff !important;
}
.btn-outline-deep-purple.btn-fab:hover,
.btn-outline-deep-purple.btn-raised:hover {
    background-color: #6036ab !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-deep-purple {
    background-color: #673ab7;
}
.progress
    .progress-bar.progress-bar-deep-purple[value]::-webkit-progress-value {
    background-color: #673ab7;
}
.progress .progress-bar.progress-bar-deep-purple[value]::-moz-progress-bar {
    background-color: #673ab7;
}
.progress .progress-bar.progress-bar-deep-purple[value]::-ms-fill {
    background-color: #673ab7;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-deep-purple .progress-bar {
        background-color: #673ab7;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #673ab7 !important;
}
.deep-purple.darken-1 {
    color: #5e35b1 !important;
}
.bg-deep-purple.bg-darken-1,
.btn-deep-purple.btn-darken-1 {
    background-color: #5e35b1 !important;
}
.btn-deep-purple.btn-darken-1 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-darken-1:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-1:active,
.btn-deep-purple.btn-darken-1:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-darken-1 {
    border-color: #5e35b1 !important;
    color: #5e35b1 !important;
}
.btn-outline-deep-purple.btn-outline-darken-1:hover {
    background-color: #5e35b1 !important;
}
.progress-deep-purple.progress-darken-1 {
    background-color: #5e35b1;
}
.progress-deep-purple.progress-darken-1[value]::-webkit-progress-value {
    background-color: #5e35b1;
}
.progress-deep-purple.progress-darken-1[value]::-moz-progress-bar {
    background-color: #5e35b1;
}
.progress-deep-purple.progress-darken-1[value]::-ms-fill {
    background-color: #5e35b1;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-darken-1 .progress-bar {
        background-color: #5e35b1;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5e35b1 !important;
}
.border-deep-purple.border-darken-1 {
    border: 1px solid #5e35b1 !important;
}
.border-top-deep-purple.border-top-darken-1 {
    border-top: 1px solid #5e35b1 !important;
}
.border-bottom-deep-purple.border-bottom-darken-1 {
    border-bottom: 1px solid #5e35b1 !important;
}
.overlay-deep-purple.overlay-darken-1 {
    background: #5e35b1;
    background: rgba(94, 53, 177, 0.8);
}
.deep-purple.darken-2 {
    color: #512da8 !important;
}
.bg-deep-purple.bg-darken-2,
.btn-deep-purple.btn-darken-2 {
    background-color: #512da8 !important;
}
.btn-deep-purple.btn-darken-2 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-darken-2:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-2:active,
.btn-deep-purple.btn-darken-2:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-darken-2 {
    border-color: #512da8 !important;
    color: #512da8 !important;
}
.btn-outline-deep-purple.btn-outline-darken-2:hover {
    background-color: #512da8 !important;
}
.progress-deep-purple.progress-darken-2 {
    background-color: #512da8;
}
.progress-deep-purple.progress-darken-2[value]::-webkit-progress-value {
    background-color: #512da8;
}
.progress-deep-purple.progress-darken-2[value]::-moz-progress-bar {
    background-color: #512da8;
}
.progress-deep-purple.progress-darken-2[value]::-ms-fill {
    background-color: #512da8;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-darken-2 .progress-bar {
        background-color: #512da8;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #512da8 !important;
}
.border-deep-purple.border-darken-2 {
    border: 1px solid #512da8 !important;
}
.border-top-deep-purple.border-top-darken-2 {
    border-top: 1px solid #512da8 !important;
}
.border-bottom-deep-purple.border-bottom-darken-2 {
    border-bottom: 1px solid #512da8 !important;
}
.overlay-deep-purple.overlay-darken-2 {
    background: #512da8;
    background: rgba(81, 45, 168, 0.8);
}
.deep-purple.darken-3 {
    color: #4527a0 !important;
}
.bg-deep-purple.bg-darken-3 {
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-3,
.btn-deep-purple.btn-darken-3:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-3:active,
.btn-deep-purple.btn-darken-3:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-darken-3 {
    border-color: #4527a0 !important;
    color: #4527a0 !important;
}
.btn-outline-deep-purple.btn-outline-darken-3:hover {
    background-color: #4527a0 !important;
}
.progress-deep-purple.progress-darken-3 {
    background-color: #4527a0;
}
.progress-deep-purple.progress-darken-3[value]::-webkit-progress-value {
    background-color: #4527a0;
}
.progress-deep-purple.progress-darken-3[value]::-moz-progress-bar {
    background-color: #4527a0;
}
.progress-deep-purple.progress-darken-3[value]::-ms-fill {
    background-color: #4527a0;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-darken-3 .progress-bar {
        background-color: #4527a0;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4527a0 !important;
}
.border-deep-purple.border-darken-3 {
    border: 1px solid #4527a0 !important;
}
.border-top-deep-purple.border-top-darken-3 {
    border-top: 1px solid #4527a0 !important;
}
.border-bottom-deep-purple.border-bottom-darken-3 {
    border-bottom: 1px solid #4527a0 !important;
}
.overlay-deep-purple.overlay-darken-3 {
    background: #4527a0;
    background: rgba(69, 39, 160, 0.8);
}
.deep-purple.darken-4 {
    color: #311b92 !important;
}
.bg-deep-purple.bg-darken-4,
.btn-deep-purple.btn-darken-4 {
    background-color: #311b92 !important;
}
.btn-deep-purple.btn-darken-4 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-darken-4:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-darken-4:active,
.btn-deep-purple.btn-darken-4:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-darken-4 {
    border-color: #311b92 !important;
    color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-darken-4:hover {
    background-color: #311b92 !important;
}
.progress-deep-purple.progress-darken-4 {
    background-color: #311b92;
}
.progress-deep-purple.progress-darken-4[value]::-webkit-progress-value {
    background-color: #311b92;
}
.progress-deep-purple.progress-darken-4[value]::-moz-progress-bar {
    background-color: #311b92;
}
.progress-deep-purple.progress-darken-4[value]::-ms-fill {
    background-color: #311b92;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-darken-4 .progress-bar {
        background-color: #311b92;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #311b92 !important;
}
.border-deep-purple.border-darken-4 {
    border: 1px solid #311b92 !important;
}
.border-top-deep-purple.border-top-darken-4 {
    border-top: 1px solid #311b92 !important;
}
.border-bottom-deep-purple.border-bottom-darken-4 {
    border-bottom: 1px solid #311b92 !important;
}
.overlay-deep-purple.overlay-darken-4 {
    background: #311b92;
    background: rgba(49, 27, 146, 0.8);
}
.deep-purple.accent-1 {
    color: #b388ff !important;
}
.bg-deep-purple.bg-accent-1,
.btn-deep-purple.btn-accent-1 {
    background-color: #b388ff !important;
}
.btn-deep-purple.btn-accent-1 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-accent-1:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-accent-1:active,
.btn-deep-purple.btn-accent-1:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-accent-1 {
    border-color: #b388ff !important;
    color: #b388ff !important;
}
.btn-outline-deep-purple.btn-outline-accent-1:hover {
    background-color: #b388ff !important;
}
.progress-deep-purple.progress-accent-1 {
    background-color: #b388ff;
}
.progress-deep-purple.progress-accent-1[value]::-webkit-progress-value {
    background-color: #b388ff;
}
.progress-deep-purple.progress-accent-1[value]::-moz-progress-bar {
    background-color: #b388ff;
}
.progress-deep-purple.progress-accent-1[value]::-ms-fill {
    background-color: #b388ff;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-accent-1 .progress-bar {
        background-color: #b388ff;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b388ff !important;
}
.border-deep-purple.border-accent-1 {
    border: 1px solid #b388ff !important;
}
.border-top-deep-purple.border-top-accent-1 {
    border-top: 1px solid #b388ff !important;
}
.border-bottom-deep-purple.border-bottom-accent-1 {
    border-bottom: 1px solid #b388ff !important;
}
.overlay-deep-purple.overlay-accent-1 {
    background: #b388ff;
    background: rgba(179, 136, 255, 0.8);
}
.deep-purple.accent-2 {
    color: #7c4dff !important;
}
.bg-deep-purple.bg-accent-2,
.btn-deep-purple.btn-accent-2 {
    background-color: #7c4dff !important;
}
.btn-deep-purple.btn-accent-2 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-accent-2:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-accent-2:active,
.btn-deep-purple.btn-accent-2:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-accent-2 {
    border-color: #7c4dff !important;
    color: #7c4dff !important;
}
.btn-outline-deep-purple.btn-outline-accent-2:hover {
    background-color: #7c4dff !important;
}
.progress-deep-purple.progress-accent-2 {
    background-color: #7c4dff;
}
.progress-deep-purple.progress-accent-2[value]::-webkit-progress-value {
    background-color: #7c4dff;
}
.progress-deep-purple.progress-accent-2[value]::-moz-progress-bar {
    background-color: #7c4dff;
}
.progress-deep-purple.progress-accent-2[value]::-ms-fill {
    background-color: #7c4dff;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-accent-2 .progress-bar {
        background-color: #7c4dff;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7c4dff !important;
}
.border-deep-purple.border-accent-2 {
    border: 1px solid #7c4dff !important;
}
.border-top-deep-purple.border-top-accent-2 {
    border-top: 1px solid #7c4dff !important;
}
.border-bottom-deep-purple.border-bottom-accent-2 {
    border-bottom: 1px solid #7c4dff !important;
}
.overlay-deep-purple.overlay-accent-2 {
    background: #7c4dff;
    background: rgba(124, 77, 255, 0.8);
}
.deep-purple.accent-3 {
    color: #651fff !important;
}
.bg-deep-purple.bg-accent-3,
.btn-deep-purple.btn-accent-3 {
    background-color: #651fff !important;
}
.btn-deep-purple.btn-accent-3 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-accent-3:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-accent-3:active,
.btn-deep-purple.btn-accent-3:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-accent-3 {
    border-color: #651fff !important;
    color: #651fff !important;
}
.btn-outline-deep-purple.btn-outline-accent-3:hover {
    background-color: #651fff !important;
}
.progress-deep-purple.progress-accent-3 {
    background-color: #651fff;
}
.progress-deep-purple.progress-accent-3[value]::-webkit-progress-value {
    background-color: #651fff;
}
.progress-deep-purple.progress-accent-3[value]::-moz-progress-bar {
    background-color: #651fff;
}
.progress-deep-purple.progress-accent-3[value]::-ms-fill {
    background-color: #651fff;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-accent-3 .progress-bar {
        background-color: #651fff;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #651fff !important;
}
.border-deep-purple.border-accent-3 {
    border: 1px solid #651fff !important;
}
.border-top-deep-purple.border-top-accent-3 {
    border-top: 1px solid #651fff !important;
}
.border-bottom-deep-purple.border-bottom-accent-3 {
    border-bottom: 1px solid #651fff !important;
}
.overlay-deep-purple.overlay-accent-3 {
    background: #651fff;
    background: rgba(101, 31, 255, 0.8);
}
.deep-purple.accent-4 {
    color: #6200ea !important;
}
.bg-deep-purple.bg-accent-4,
.btn-deep-purple.btn-accent-4 {
    background-color: #6200ea !important;
}
.btn-deep-purple.btn-accent-4 {
    border-color: #512da8 !important;
}
.btn-deep-purple.btn-accent-4:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important;
}
.btn-deep-purple.btn-accent-4:active,
.btn-deep-purple.btn-accent-4:focus {
    border-color: #4527a0 !important;
    background-color: #311b92 !important;
}
.btn-outline-deep-purple.btn-outline-accent-4 {
    border-color: #6200ea !important;
    color: #6200ea !important;
}
.btn-outline-deep-purple.btn-outline-accent-4:hover {
    background-color: #6200ea !important;
}
.progress-deep-purple.progress-accent-4 {
    background-color: #6200ea;
}
.progress-deep-purple.progress-accent-4[value]::-webkit-progress-value {
    background-color: #6200ea;
}
.progress-deep-purple.progress-accent-4[value]::-moz-progress-bar {
    background-color: #6200ea;
}
.progress-deep-purple.progress-accent-4[value]::-ms-fill {
    background-color: #6200ea;
}
@media screen and (min-width: 0\0) {
    .progress-deep-purple.progress-accent-4 .progress-bar {
        background-color: #6200ea;
    }
}
input:focus ~ .bg-deep-purple {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6200ea !important;
}
.border-deep-purple.border-accent-4 {
    border: 1px solid #6200ea !important;
}
.border-top-deep-purple.border-top-accent-4 {
    border-top: 1px solid #6200ea !important;
}
.border-bottom-deep-purple.border-bottom-accent-4 {
    border-bottom: 1px solid #6200ea !important;
}
[dir='ltr'] .border-left-deep-purple.border-left-accent-4 {
    border-left: 1px solid #6200ea !important;
}
[dir='ltr'] .border-right-deep-purple.border-right-accent-4,
[dir='rtl'] .border-left-deep-purple.border-left-accent-4 {
    border-right: 1px solid #6200ea !important;
}
[dir='rtl'] .border-right-deep-purple.border-right-accent-4 {
    border-left: 1px solid #6200ea !important;
}
.overlay-deep-purple.overlay-accent-4 {
    background: #6200ea;
    background: rgba(98, 0, 234, 0.8);
}
.indigo.lighten-5 {
    color: #e8eaf6 !important;
}
.bg-indigo.bg-lighten-5,
.btn-indigo.btn-lighten-5 {
    background-color: #e8eaf6 !important;
}
.btn-indigo.btn-lighten-5 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-lighten-5:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-5:active,
.btn-indigo.btn-lighten-5:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-lighten-5 {
    border-color: #e8eaf6 !important;
    color: #e8eaf6 !important;
}
.btn-outline-indigo.btn-outline-lighten-5:hover {
    background-color: #e8eaf6 !important;
}
.progress-indigo.progress-lighten-5 {
    background-color: #e8eaf6;
}
.progress-indigo.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e8eaf6;
}
.progress-indigo.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e8eaf6;
}
.progress-indigo.progress-lighten-5[value]::-ms-fill {
    background-color: #e8eaf6;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-lighten-5 .progress-bar {
        background-color: #e8eaf6;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e8eaf6 !important;
}
.border-indigo.border-lighten-5 {
    border: 1px solid #e8eaf6 !important;
}
.border-top-indigo.border-top-lighten-5 {
    border-top: 1px solid #e8eaf6 !important;
}
.border-bottom-indigo.border-bottom-lighten-5 {
    border-bottom: 1px solid #e8eaf6 !important;
}
[dir='ltr'] .border-left-indigo.border-left-lighten-5 {
    border-left: 1px solid #e8eaf6 !important;
}
[dir='ltr'] .border-right-indigo.border-right-lighten-5,
[dir='rtl'] .border-left-indigo.border-left-lighten-5 {
    border-right: 1px solid #e8eaf6 !important;
}
[dir='rtl'] .border-right-indigo.border-right-lighten-5 {
    border-left: 1px solid #e8eaf6 !important;
}
.overlay-indigo.overlay-lighten-5 {
    background: #e8eaf6;
    background: rgba(232, 234, 246, 0.8);
}
.indigo.lighten-4 {
    color: #c5cae9 !important;
}
.bg-indigo.bg-lighten-4,
.btn-indigo.btn-lighten-4 {
    background-color: #c5cae9 !important;
}
.btn-indigo.btn-lighten-4 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-lighten-4:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-4:active,
.btn-indigo.btn-lighten-4:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-lighten-4 {
    border-color: #c5cae9 !important;
    color: #c5cae9 !important;
}
.btn-outline-indigo.btn-outline-lighten-4:hover {
    background-color: #c5cae9 !important;
}
.progress-indigo.progress-lighten-4 {
    background-color: #c5cae9;
}
.progress-indigo.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #c5cae9;
}
.progress-indigo.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #c5cae9;
}
.progress-indigo.progress-lighten-4[value]::-ms-fill {
    background-color: #c5cae9;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-lighten-4 .progress-bar {
        background-color: #c5cae9;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c5cae9 !important;
}
.border-indigo.border-lighten-4 {
    border: 1px solid #c5cae9 !important;
}
.border-top-indigo.border-top-lighten-4 {
    border-top: 1px solid #c5cae9 !important;
}
.border-bottom-indigo.border-bottom-lighten-4 {
    border-bottom: 1px solid #c5cae9 !important;
}
[dir='ltr'] .border-left-indigo.border-left-lighten-4 {
    border-left: 1px solid #c5cae9 !important;
}
[dir='ltr'] .border-right-indigo.border-right-lighten-4,
[dir='rtl'] .border-left-indigo.border-left-lighten-4 {
    border-right: 1px solid #c5cae9 !important;
}
[dir='rtl'] .border-right-indigo.border-right-lighten-4 {
    border-left: 1px solid #c5cae9 !important;
}
.overlay-indigo.overlay-lighten-4 {
    background: #c5cae9;
    background: rgba(197, 202, 233, 0.8);
}
.indigo.lighten-3 {
    color: #9fa8da !important;
}
.bg-indigo.bg-lighten-3,
.btn-indigo.btn-lighten-3 {
    background-color: #9fa8da !important;
}
.btn-indigo.btn-lighten-3 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-lighten-3:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-3:active,
.btn-indigo.btn-lighten-3:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-lighten-3 {
    border-color: #9fa8da !important;
    color: #9fa8da !important;
}
.btn-outline-indigo.btn-outline-lighten-3:hover {
    background-color: #9fa8da !important;
}
.progress-indigo.progress-lighten-3 {
    background-color: #9fa8da;
}
.progress-indigo.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #9fa8da;
}
.progress-indigo.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #9fa8da;
}
.progress-indigo.progress-lighten-3[value]::-ms-fill {
    background-color: #9fa8da;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-lighten-3 .progress-bar {
        background-color: #9fa8da;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9fa8da !important;
}
.border-indigo.border-lighten-3 {
    border: 1px solid #9fa8da !important;
}
.border-top-indigo.border-top-lighten-3 {
    border-top: 1px solid #9fa8da !important;
}
.border-bottom-indigo.border-bottom-lighten-3 {
    border-bottom: 1px solid #9fa8da !important;
}
[dir='ltr'] .border-left-indigo.border-left-lighten-3 {
    border-left: 1px solid #9fa8da !important;
}
[dir='ltr'] .border-right-indigo.border-right-lighten-3,
[dir='rtl'] .border-left-indigo.border-left-lighten-3 {
    border-right: 1px solid #9fa8da !important;
}
[dir='rtl'] .border-right-indigo.border-right-lighten-3 {
    border-left: 1px solid #9fa8da !important;
}
.overlay-indigo.overlay-lighten-3 {
    background: #9fa8da;
    background: rgba(159, 168, 218, 0.8);
}
.indigo.lighten-2 {
    color: #7986cb !important;
}
.bg-indigo.bg-lighten-2,
.btn-indigo.btn-lighten-2 {
    background-color: #7986cb !important;
}
.btn-indigo.btn-lighten-2 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-lighten-2:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-2:active,
.btn-indigo.btn-lighten-2:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-lighten-2 {
    border-color: #7986cb !important;
    color: #7986cb !important;
}
.btn-outline-indigo.btn-outline-lighten-2:hover {
    background-color: #7986cb !important;
}
.progress-indigo.progress-lighten-2 {
    background-color: #7986cb;
}
.progress-indigo.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #7986cb;
}
.progress-indigo.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #7986cb;
}
.progress-indigo.progress-lighten-2[value]::-ms-fill {
    background-color: #7986cb;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-lighten-2 .progress-bar {
        background-color: #7986cb;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7986cb !important;
}
.border-indigo.border-lighten-2 {
    border: 1px solid #7986cb !important;
}
.border-top-indigo.border-top-lighten-2 {
    border-top: 1px solid #7986cb !important;
}
.border-bottom-indigo.border-bottom-lighten-2 {
    border-bottom: 1px solid #7986cb !important;
}
[dir='ltr'] .border-left-indigo.border-left-lighten-2 {
    border-left: 1px solid #7986cb !important;
}
[dir='ltr'] .border-right-indigo.border-right-lighten-2,
[dir='rtl'] .border-left-indigo.border-left-lighten-2 {
    border-right: 1px solid #7986cb !important;
}
[dir='rtl'] .border-right-indigo.border-right-lighten-2 {
    border-left: 1px solid #7986cb !important;
}
.overlay-indigo.overlay-lighten-2 {
    background: #7986cb;
    background: rgba(121, 134, 203, 0.8);
}
.indigo.lighten-1 {
    color: #5c6bc0 !important;
}
.bg-indigo.bg-lighten-1,
.btn-indigo.btn-lighten-1 {
    background-color: #5c6bc0 !important;
}
.btn-indigo.btn-lighten-1 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-lighten-1:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-lighten-1:active,
.btn-indigo.btn-lighten-1:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-lighten-1 {
    border-color: #5c6bc0 !important;
    color: #5c6bc0 !important;
}
.btn-outline-indigo.btn-outline-lighten-1:hover {
    background-color: #5c6bc0 !important;
}
.progress-indigo.progress-lighten-1 {
    background-color: #5c6bc0;
}
.progress-indigo.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #5c6bc0;
}
.progress-indigo.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #5c6bc0;
}
.progress-indigo.progress-lighten-1[value]::-ms-fill {
    background-color: #5c6bc0;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-lighten-1 .progress-bar {
        background-color: #5c6bc0;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5c6bc0 !important;
}
.border-indigo.border-lighten-1 {
    border: 1px solid #5c6bc0 !important;
}
.border-top-indigo.border-top-lighten-1 {
    border-top: 1px solid #5c6bc0 !important;
}
.border-bottom-indigo.border-bottom-lighten-1 {
    border-bottom: 1px solid #5c6bc0 !important;
}
[dir='ltr'] .border-left-indigo.border-left-lighten-1 {
    border-left: 1px solid #5c6bc0 !important;
}
[dir='ltr'] .border-right-indigo.border-right-lighten-1,
[dir='rtl'] .border-left-indigo.border-left-lighten-1 {
    border-right: 1px solid #5c6bc0 !important;
}
[dir='rtl'] .border-right-indigo.border-right-lighten-1 {
    border-left: 1px solid #5c6bc0 !important;
}
.overlay-indigo.overlay-lighten-1 {
    background: #5c6bc0;
    background: rgba(92, 107, 192, 0.8);
}
.indigo {
    color: #3f51b5 !important;
}
.bg-indigo {
    background-color: #3f51b5 !important;
}
.bg-indigo .card-footer,
.bg-indigo .card-header {
    background-color: initial;
}
.toast-indigo {
    background-color: #3f51b5;
}
.alert-indigo {
    border-color: #3f51b5 !important;
    background-color: #5465c4 !important;
    color: #111631 !important;
}
.alert-indigo .alert-link {
    color: #060812 !important;
}
.border-indigo {
    border-color: #3f51b5;
}
.border-top-indigo {
    border-top-color: #3f51b5;
}
.border-bottom-indigo {
    border-bottom-color: #3f51b5;
}
[dir='ltr'] .border-left-indigo {
    border-left-color: #3f51b5;
}
[dir='ltr'] .border-right-indigo,
[dir='rtl'] .border-left-indigo {
    border-right-color: #3f51b5;
}
[dir='rtl'] .border-right-indigo {
    border-left-color: #3f51b5;
}
.badge-indigo {
    background-color: #3f51b5;
}
.panel-indigo {
    border-color: #3f51b5;
}
.panel-indigo .panel-heading {
    color: #fff;
    border-color: #3f51b5;
    background-color: #4d5ec1;
}
.bg-indigo.tag-glow,
.border-indigo.tag-glow {
    box-shadow: 0 0 10px #3f51b5;
}
.overlay-indigo {
    background: #3f51b5;
    background: rgba(63, 81, 181, 0.8);
}
.card.card-outline-indigo {
    border: 1px solid #3f51b5;
    background-color: initial;
}
.card.card-outline-indigo .card-footer,
.card.card-outline-indigo .card-header {
    background-color: initial;
}
.btn-indigo.btn-flat {
    background-color: initial !important;
    color: #3f51b5;
    border: none;
}
.btn-indigo.btn-fab,
.btn-indigo.btn-raised {
    background-color: #3f51b5 !important;
    color: #fff !important;
    border-color: #3f51b5;
}
.btn-indigo.btn-fab.active,
.btn-indigo.btn-raised.active {
    background-color: #36459b !important;
    border-color: #36459b !important;
}
.btn-group-raised .btn-indigo {
    background-color: #3f51b5 !important;
    color: #fff !important;
}
.btn-outline-indigo {
    border: 1px solid #3f51b5;
    background-color: initial;
    color: #3f51b5;
    box-shadow: none !important;
}
.btn-outline-indigo:focus {
    background-color: initial !important;
    color: #3f51b5 !important;
    box-shadow: transparent !important;
}
.btn-outline-indigo.active {
    background-color: #3f51b5 !important;
    color: #fff !important;
}
.btn-outline-indigo:hover {
    background-color: #3b4caa !important;
    color: #fff !important;
}
.btn-outline-indigo:hover svg {
    color: #fff !important;
}
.btn-outline-indigo.btn-fab,
.btn-outline-indigo.btn-raised {
    border: 1px solid #3f51b5;
    background-color: initial;
    color: #3f51b5;
    box-shadow: none !important;
}
.btn-outline-indigo.btn-fab:focus,
.btn-outline-indigo.btn-raised:focus {
    background-color: initial;
}
.btn-outline-indigo.btn-fab.active,
.btn-outline-indigo.btn-raised.active {
    background-color: #3f51b5 !important;
    color: #fff !important;
}
.btn-outline-indigo.btn-fab:hover,
.btn-outline-indigo.btn-raised:hover {
    background-color: #3b4caa !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-indigo {
    background-color: #3f51b5;
}
.progress .progress-bar.progress-bar-indigo[value]::-webkit-progress-value {
    background-color: #3f51b5;
}
.progress .progress-bar.progress-bar-indigo[value]::-moz-progress-bar {
    background-color: #3f51b5;
}
.progress .progress-bar.progress-bar-indigo[value]::-ms-fill {
    background-color: #3f51b5;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-indigo .progress-bar {
        background-color: #3f51b5;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3f51b5 !important;
}
.indigo.darken-1 {
    color: #3949ab !important;
}
.bg-indigo.bg-darken-1,
.btn-indigo.btn-darken-1 {
    background-color: #3949ab !important;
}
.btn-indigo.btn-darken-1 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-darken-1:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-1:active,
.btn-indigo.btn-darken-1:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-darken-1 {
    border-color: #3949ab !important;
    color: #3949ab !important;
}
.btn-outline-indigo.btn-outline-darken-1:hover {
    background-color: #3949ab !important;
}
.progress-indigo.progress-darken-1 {
    background-color: #3949ab;
}
.progress-indigo.progress-darken-1[value]::-webkit-progress-value {
    background-color: #3949ab;
}
.progress-indigo.progress-darken-1[value]::-moz-progress-bar {
    background-color: #3949ab;
}
.progress-indigo.progress-darken-1[value]::-ms-fill {
    background-color: #3949ab;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-darken-1 .progress-bar {
        background-color: #3949ab;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3949ab !important;
}
.border-indigo.border-darken-1 {
    border: 1px solid #3949ab !important;
}
.border-top-indigo.border-top-darken-1 {
    border-top: 1px solid #3949ab !important;
}
.border-bottom-indigo.border-bottom-darken-1 {
    border-bottom: 1px solid #3949ab !important;
}
[dir='ltr'] .border-left-indigo.border-left-darken-1 {
    border-left: 1px solid #3949ab !important;
}
[dir='ltr'] .border-right-indigo.border-right-darken-1,
[dir='rtl'] .border-left-indigo.border-left-darken-1 {
    border-right: 1px solid #3949ab !important;
}
[dir='rtl'] .border-right-indigo.border-right-darken-1 {
    border-left: 1px solid #3949ab !important;
}
.overlay-indigo.overlay-darken-1 {
    background: #3949ab;
    background: rgba(57, 73, 171, 0.8);
}
.indigo.darken-2 {
    color: #303f9f !important;
}
.bg-indigo.bg-darken-2,
.btn-indigo.btn-darken-2 {
    background-color: #303f9f !important;
}
.btn-indigo.btn-darken-2 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-darken-2:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-2:active,
.btn-indigo.btn-darken-2:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-darken-2 {
    border-color: #303f9f !important;
    color: #303f9f !important;
}
.btn-outline-indigo.btn-outline-darken-2:hover {
    background-color: #303f9f !important;
}
.progress-indigo.progress-darken-2 {
    background-color: #303f9f;
}
.progress-indigo.progress-darken-2[value]::-webkit-progress-value {
    background-color: #303f9f;
}
.progress-indigo.progress-darken-2[value]::-moz-progress-bar {
    background-color: #303f9f;
}
.progress-indigo.progress-darken-2[value]::-ms-fill {
    background-color: #303f9f;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-darken-2 .progress-bar {
        background-color: #303f9f;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #303f9f !important;
}
.border-indigo.border-darken-2 {
    border: 1px solid #303f9f !important;
}
.border-top-indigo.border-top-darken-2 {
    border-top: 1px solid #303f9f !important;
}
.border-bottom-indigo.border-bottom-darken-2 {
    border-bottom: 1px solid #303f9f !important;
}
[dir='ltr'] .border-left-indigo.border-left-darken-2 {
    border-left: 1px solid #303f9f !important;
}
[dir='ltr'] .border-right-indigo.border-right-darken-2,
[dir='rtl'] .border-left-indigo.border-left-darken-2 {
    border-right: 1px solid #303f9f !important;
}
[dir='rtl'] .border-right-indigo.border-right-darken-2 {
    border-left: 1px solid #303f9f !important;
}
.overlay-indigo.overlay-darken-2 {
    background: #303f9f;
    background: rgba(48, 63, 159, 0.8);
}
.indigo.darken-3 {
    color: #283593 !important;
}
.bg-indigo.bg-darken-3 {
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-3,
.btn-indigo.btn-darken-3:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-3:active,
.btn-indigo.btn-darken-3:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-darken-3 {
    border-color: #283593 !important;
    color: #283593 !important;
}
.btn-outline-indigo.btn-outline-darken-3:hover {
    background-color: #283593 !important;
}
.progress-indigo.progress-darken-3 {
    background-color: #283593;
}
.progress-indigo.progress-darken-3[value]::-webkit-progress-value {
    background-color: #283593;
}
.progress-indigo.progress-darken-3[value]::-moz-progress-bar {
    background-color: #283593;
}
.progress-indigo.progress-darken-3[value]::-ms-fill {
    background-color: #283593;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-darken-3 .progress-bar {
        background-color: #283593;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #283593 !important;
}
.border-indigo.border-darken-3 {
    border: 1px solid #283593 !important;
}
.border-top-indigo.border-top-darken-3 {
    border-top: 1px solid #283593 !important;
}
.border-bottom-indigo.border-bottom-darken-3 {
    border-bottom: 1px solid #283593 !important;
}
[dir='ltr'] .border-left-indigo.border-left-darken-3 {
    border-left: 1px solid #283593 !important;
}
[dir='ltr'] .border-right-indigo.border-right-darken-3,
[dir='rtl'] .border-left-indigo.border-left-darken-3 {
    border-right: 1px solid #283593 !important;
}
[dir='rtl'] .border-right-indigo.border-right-darken-3 {
    border-left: 1px solid #283593 !important;
}
.overlay-indigo.overlay-darken-3 {
    background: #283593;
    background: rgba(40, 53, 147, 0.8);
}
.indigo.darken-4 {
    color: #1a237e !important;
}
.bg-indigo.bg-darken-4,
.btn-indigo.btn-darken-4 {
    background-color: #1a237e !important;
}
.btn-indigo.btn-darken-4 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-darken-4:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-darken-4:active,
.btn-indigo.btn-darken-4:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-darken-4 {
    border-color: #1a237e !important;
    color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-darken-4:hover {
    background-color: #1a237e !important;
}
.progress-indigo.progress-darken-4 {
    background-color: #1a237e;
}
.progress-indigo.progress-darken-4[value]::-webkit-progress-value {
    background-color: #1a237e;
}
.progress-indigo.progress-darken-4[value]::-moz-progress-bar {
    background-color: #1a237e;
}
.progress-indigo.progress-darken-4[value]::-ms-fill {
    background-color: #1a237e;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-darken-4 .progress-bar {
        background-color: #1a237e;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1a237e !important;
}
.border-indigo.border-darken-4 {
    border: 1px solid #1a237e !important;
}
.border-top-indigo.border-top-darken-4 {
    border-top: 1px solid #1a237e !important;
}
.border-bottom-indigo.border-bottom-darken-4 {
    border-bottom: 1px solid #1a237e !important;
}
[dir='ltr'] .border-left-indigo.border-left-darken-4 {
    border-left: 1px solid #1a237e !important;
}
[dir='ltr'] .border-right-indigo.border-right-darken-4,
[dir='rtl'] .border-left-indigo.border-left-darken-4 {
    border-right: 1px solid #1a237e !important;
}
[dir='rtl'] .border-right-indigo.border-right-darken-4 {
    border-left: 1px solid #1a237e !important;
}
.overlay-indigo.overlay-darken-4 {
    background: #1a237e;
    background: rgba(26, 35, 126, 0.8);
}
.indigo.accent-1 {
    color: #8c9eff !important;
}
.bg-indigo.bg-accent-1,
.btn-indigo.btn-accent-1 {
    background-color: #8c9eff !important;
}
.btn-indigo.btn-accent-1 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-accent-1:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-accent-1:active,
.btn-indigo.btn-accent-1:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-accent-1 {
    border-color: #8c9eff !important;
    color: #8c9eff !important;
}
.btn-outline-indigo.btn-outline-accent-1:hover {
    background-color: #8c9eff !important;
}
.progress-indigo.progress-accent-1 {
    background-color: #8c9eff;
}
.progress-indigo.progress-accent-1[value]::-webkit-progress-value {
    background-color: #8c9eff;
}
.progress-indigo.progress-accent-1[value]::-moz-progress-bar {
    background-color: #8c9eff;
}
.progress-indigo.progress-accent-1[value]::-ms-fill {
    background-color: #8c9eff;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-accent-1 .progress-bar {
        background-color: #8c9eff;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8c9eff !important;
}
.border-indigo.border-accent-1 {
    border: 1px solid #8c9eff !important;
}
.border-top-indigo.border-top-accent-1 {
    border-top: 1px solid #8c9eff !important;
}
.border-bottom-indigo.border-bottom-accent-1 {
    border-bottom: 1px solid #8c9eff !important;
}
[dir='ltr'] .border-left-indigo.border-left-accent-1 {
    border-left: 1px solid #8c9eff !important;
}
[dir='ltr'] .border-right-indigo.border-right-accent-1,
[dir='rtl'] .border-left-indigo.border-left-accent-1 {
    border-right: 1px solid #8c9eff !important;
}
[dir='rtl'] .border-right-indigo.border-right-accent-1 {
    border-left: 1px solid #8c9eff !important;
}
.overlay-indigo.overlay-accent-1 {
    background: #8c9eff;
    background: rgba(140, 158, 255, 0.8);
}
.indigo.accent-2 {
    color: #536dfe !important;
}
.bg-indigo.bg-accent-2,
.btn-indigo.btn-accent-2 {
    background-color: #536dfe !important;
}
.btn-indigo.btn-accent-2 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-accent-2:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-accent-2:active,
.btn-indigo.btn-accent-2:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-accent-2 {
    border-color: #536dfe !important;
    color: #536dfe !important;
}
.btn-outline-indigo.btn-outline-accent-2:hover {
    background-color: #536dfe !important;
}
.progress-indigo.progress-accent-2 {
    background-color: #536dfe;
}
.progress-indigo.progress-accent-2[value]::-webkit-progress-value {
    background-color: #536dfe;
}
.progress-indigo.progress-accent-2[value]::-moz-progress-bar {
    background-color: #536dfe;
}
.progress-indigo.progress-accent-2[value]::-ms-fill {
    background-color: #536dfe;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-accent-2 .progress-bar {
        background-color: #536dfe;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #536dfe !important;
}
.border-indigo.border-accent-2 {
    border: 1px solid #536dfe !important;
}
.border-top-indigo.border-top-accent-2 {
    border-top: 1px solid #536dfe !important;
}
.border-bottom-indigo.border-bottom-accent-2 {
    border-bottom: 1px solid #536dfe !important;
}
[dir='ltr'] .border-left-indigo.border-left-accent-2 {
    border-left: 1px solid #536dfe !important;
}
[dir='ltr'] .border-right-indigo.border-right-accent-2,
[dir='rtl'] .border-left-indigo.border-left-accent-2 {
    border-right: 1px solid #536dfe !important;
}
[dir='rtl'] .border-right-indigo.border-right-accent-2 {
    border-left: 1px solid #536dfe !important;
}
.overlay-indigo.overlay-accent-2 {
    background: #536dfe;
    background: rgba(83, 109, 254, 0.8);
}
.indigo.accent-3 {
    color: #3d5afe !important;
}
.bg-indigo.bg-accent-3,
.btn-indigo.btn-accent-3 {
    background-color: #3d5afe !important;
}
.btn-indigo.btn-accent-3 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-accent-3:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-accent-3:active,
.btn-indigo.btn-accent-3:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-accent-3 {
    border-color: #3d5afe !important;
    color: #3d5afe !important;
}
.btn-outline-indigo.btn-outline-accent-3:hover {
    background-color: #3d5afe !important;
}
.progress-indigo.progress-accent-3 {
    background-color: #3d5afe;
}
.progress-indigo.progress-accent-3[value]::-webkit-progress-value {
    background-color: #3d5afe;
}
.progress-indigo.progress-accent-3[value]::-moz-progress-bar {
    background-color: #3d5afe;
}
.progress-indigo.progress-accent-3[value]::-ms-fill {
    background-color: #3d5afe;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-accent-3 .progress-bar {
        background-color: #3d5afe;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3d5afe !important;
}
.border-indigo.border-accent-3 {
    border: 1px solid #3d5afe !important;
}
.border-top-indigo.border-top-accent-3 {
    border-top: 1px solid #3d5afe !important;
}
.border-bottom-indigo.border-bottom-accent-3 {
    border-bottom: 1px solid #3d5afe !important;
}
[dir='ltr'] .border-left-indigo.border-left-accent-3 {
    border-left: 1px solid #3d5afe !important;
}
[dir='ltr'] .border-right-indigo.border-right-accent-3,
[dir='rtl'] .border-left-indigo.border-left-accent-3 {
    border-right: 1px solid #3d5afe !important;
}
[dir='rtl'] .border-right-indigo.border-right-accent-3 {
    border-left: 1px solid #3d5afe !important;
}
.overlay-indigo.overlay-accent-3 {
    background: #3d5afe;
    background: rgba(61, 90, 254, 0.8);
}
.indigo.accent-4 {
    color: #304ffe !important;
}
.bg-indigo.bg-accent-4,
.btn-indigo.btn-accent-4 {
    background-color: #304ffe !important;
}
.btn-indigo.btn-accent-4 {
    border-color: #303f9f !important;
}
.btn-indigo.btn-accent-4:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important;
}
.btn-indigo.btn-accent-4:active,
.btn-indigo.btn-accent-4:focus {
    border-color: #283593 !important;
    background-color: #1a237e !important;
}
.btn-outline-indigo.btn-outline-accent-4 {
    border-color: #304ffe !important;
    color: #304ffe !important;
}
.btn-outline-indigo.btn-outline-accent-4:hover {
    background-color: #304ffe !important;
}
.progress-indigo.progress-accent-4 {
    background-color: #304ffe;
}
.progress-indigo.progress-accent-4[value]::-webkit-progress-value {
    background-color: #304ffe;
}
.progress-indigo.progress-accent-4[value]::-moz-progress-bar {
    background-color: #304ffe;
}
.progress-indigo.progress-accent-4[value]::-ms-fill {
    background-color: #304ffe;
}
@media screen and (min-width: 0\0) {
    .progress-indigo.progress-accent-4 .progress-bar {
        background-color: #304ffe;
    }
}
input:focus ~ .bg-indigo {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #304ffe !important;
}
.border-indigo.border-accent-4 {
    border: 1px solid #304ffe !important;
}
.border-top-indigo.border-top-accent-4 {
    border-top: 1px solid #304ffe !important;
}
.border-bottom-indigo.border-bottom-accent-4 {
    border-bottom: 1px solid #304ffe !important;
}
[dir='ltr'] .border-left-indigo.border-left-accent-4 {
    border-left: 1px solid #304ffe !important;
}
[dir='ltr'] .border-right-indigo.border-right-accent-4,
[dir='rtl'] .border-left-indigo.border-left-accent-4 {
    border-right: 1px solid #304ffe !important;
}
[dir='rtl'] .border-right-indigo.border-right-accent-4 {
    border-left: 1px solid #304ffe !important;
}
.overlay-indigo.overlay-accent-4 {
    background: #304ffe;
    background: rgba(48, 79, 254, 0.8);
}
.blue.lighten-5 {
    color: #e3f2fd !important;
}
.bg-blue.bg-lighten-5,
.btn-blue.btn-lighten-5 {
    background-color: #e3f2fd !important;
}
.btn-blue.btn-lighten-5,
.btn-blue.btn-lighten-5:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-lighten-5:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-5:active,
.btn-blue.btn-lighten-5:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-lighten-5 {
    border-color: #e3f2fd !important;
    color: #e3f2fd !important;
}
.btn-outline-blue.btn-outline-lighten-5:hover {
    background-color: #e3f2fd !important;
}
.progress-blue.progress-lighten-5 {
    background-color: #e3f2fd;
}
.progress-blue.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e3f2fd;
}
.progress-blue.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e3f2fd;
}
.progress-blue.progress-lighten-5[value]::-ms-fill {
    background-color: #e3f2fd;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-lighten-5 .progress-bar {
        background-color: #e3f2fd;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e3f2fd !important;
}
.border-blue.border-lighten-5 {
    border: 1px solid #e3f2fd !important;
}
.border-top-blue.border-top-lighten-5 {
    border-top: 1px solid #e3f2fd !important;
}
.border-bottom-blue.border-bottom-lighten-5 {
    border-bottom: 1px solid #e3f2fd !important;
}
[dir='ltr'] .border-left-blue.border-left-lighten-5 {
    border-left: 1px solid #e3f2fd !important;
}
[dir='ltr'] .border-right-blue.border-right-lighten-5,
[dir='rtl'] .border-left-blue.border-left-lighten-5 {
    border-right: 1px solid #e3f2fd !important;
}
[dir='rtl'] .border-right-blue.border-right-lighten-5 {
    border-left: 1px solid #e3f2fd !important;
}
.overlay-blue.overlay-lighten-5 {
    background: #e3f2fd;
    background: rgba(227, 242, 253, 0.8);
}
.blue.lighten-4 {
    color: #bbdefb !important;
}
.bg-blue.bg-lighten-4,
.btn-blue.btn-lighten-4 {
    background-color: #bbdefb !important;
}
.btn-blue.btn-lighten-4,
.btn-blue.btn-lighten-4:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-lighten-4:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-4:active,
.btn-blue.btn-lighten-4:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-lighten-4 {
    border-color: #bbdefb !important;
    color: #bbdefb !important;
}
.btn-outline-blue.btn-outline-lighten-4:hover {
    background-color: #bbdefb !important;
}
.progress-blue.progress-lighten-4 {
    background-color: #bbdefb;
}
.progress-blue.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #bbdefb;
}
.progress-blue.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #bbdefb;
}
.progress-blue.progress-lighten-4[value]::-ms-fill {
    background-color: #bbdefb;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-lighten-4 .progress-bar {
        background-color: #bbdefb;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bbdefb !important;
}
.border-blue.border-lighten-4 {
    border: 1px solid #bbdefb !important;
}
.border-top-blue.border-top-lighten-4 {
    border-top: 1px solid #bbdefb !important;
}
.border-bottom-blue.border-bottom-lighten-4 {
    border-bottom: 1px solid #bbdefb !important;
}
[dir='ltr'] .border-left-blue.border-left-lighten-4 {
    border-left: 1px solid #bbdefb !important;
}
[dir='ltr'] .border-right-blue.border-right-lighten-4,
[dir='rtl'] .border-left-blue.border-left-lighten-4 {
    border-right: 1px solid #bbdefb !important;
}
[dir='rtl'] .border-right-blue.border-right-lighten-4 {
    border-left: 1px solid #bbdefb !important;
}
.overlay-blue.overlay-lighten-4 {
    background: #bbdefb;
    background: rgba(187, 222, 251, 0.8);
}
.blue.lighten-3 {
    color: #90caf9 !important;
}
.bg-blue.bg-lighten-3,
.btn-blue.btn-lighten-3 {
    background-color: #90caf9 !important;
}
.btn-blue.btn-lighten-3,
.btn-blue.btn-lighten-3:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-lighten-3:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-3:active,
.btn-blue.btn-lighten-3:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-lighten-3 {
    border-color: #90caf9 !important;
    color: #90caf9 !important;
}
.btn-outline-blue.btn-outline-lighten-3:hover {
    background-color: #90caf9 !important;
}
.progress-blue.progress-lighten-3 {
    background-color: #90caf9;
}
.progress-blue.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #90caf9;
}
.progress-blue.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #90caf9;
}
.progress-blue.progress-lighten-3[value]::-ms-fill {
    background-color: #90caf9;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-lighten-3 .progress-bar {
        background-color: #90caf9;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90caf9 !important;
}
.border-blue.border-lighten-3 {
    border: 1px solid #90caf9 !important;
}
.border-top-blue.border-top-lighten-3 {
    border-top: 1px solid #90caf9 !important;
}
.border-bottom-blue.border-bottom-lighten-3 {
    border-bottom: 1px solid #90caf9 !important;
}
[dir='ltr'] .border-left-blue.border-left-lighten-3 {
    border-left: 1px solid #90caf9 !important;
}
[dir='ltr'] .border-right-blue.border-right-lighten-3,
[dir='rtl'] .border-left-blue.border-left-lighten-3 {
    border-right: 1px solid #90caf9 !important;
}
[dir='rtl'] .border-right-blue.border-right-lighten-3 {
    border-left: 1px solid #90caf9 !important;
}
.overlay-blue.overlay-lighten-3 {
    background: #90caf9;
    background: rgba(144, 202, 249, 0.8);
}
.blue.lighten-2 {
    color: #64b5f6 !important;
}
.bg-blue.bg-lighten-2,
.btn-blue.btn-lighten-2 {
    background-color: #64b5f6 !important;
}
.btn-blue.btn-lighten-2,
.btn-blue.btn-lighten-2:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-lighten-2:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-2:active,
.btn-blue.btn-lighten-2:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-lighten-2 {
    border-color: #64b5f6 !important;
    color: #64b5f6 !important;
}
.btn-outline-blue.btn-outline-lighten-2:hover {
    background-color: #64b5f6 !important;
}
.progress-blue.progress-lighten-2 {
    background-color: #64b5f6;
}
.progress-blue.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #64b5f6;
}
.progress-blue.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #64b5f6;
}
.progress-blue.progress-lighten-2[value]::-ms-fill {
    background-color: #64b5f6;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-lighten-2 .progress-bar {
        background-color: #64b5f6;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64b5f6 !important;
}
.border-blue.border-lighten-2 {
    border: 1px solid #64b5f6 !important;
}
.border-top-blue.border-top-lighten-2 {
    border-top: 1px solid #64b5f6 !important;
}
.border-bottom-blue.border-bottom-lighten-2 {
    border-bottom: 1px solid #64b5f6 !important;
}
[dir='ltr'] .border-left-blue.border-left-lighten-2 {
    border-left: 1px solid #64b5f6 !important;
}
[dir='ltr'] .border-right-blue.border-right-lighten-2,
[dir='rtl'] .border-left-blue.border-left-lighten-2 {
    border-right: 1px solid #64b5f6 !important;
}
[dir='rtl'] .border-right-blue.border-right-lighten-2 {
    border-left: 1px solid #64b5f6 !important;
}
.overlay-blue.overlay-lighten-2 {
    background: #64b5f6;
    background: rgba(100, 181, 246, 0.8);
}
.blue.lighten-1 {
    color: #42a5f5 !important;
}
.bg-blue.bg-lighten-1,
.btn-blue.btn-lighten-1 {
    background-color: #42a5f5 !important;
}
.btn-blue.btn-lighten-1,
.btn-blue.btn-lighten-1:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-lighten-1:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-lighten-1:active,
.btn-blue.btn-lighten-1:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-lighten-1 {
    border-color: #42a5f5 !important;
    color: #42a5f5 !important;
}
.btn-outline-blue.btn-outline-lighten-1:hover {
    background-color: #42a5f5 !important;
}
.progress-blue.progress-lighten-1 {
    background-color: #42a5f5;
}
.progress-blue.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #42a5f5;
}
.progress-blue.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #42a5f5;
}
.progress-blue.progress-lighten-1[value]::-ms-fill {
    background-color: #42a5f5;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-lighten-1 .progress-bar {
        background-color: #42a5f5;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #42a5f5 !important;
}
.border-blue.border-lighten-1 {
    border: 1px solid #42a5f5 !important;
}
.border-top-blue.border-top-lighten-1 {
    border-top: 1px solid #42a5f5 !important;
}
.border-bottom-blue.border-bottom-lighten-1 {
    border-bottom: 1px solid #42a5f5 !important;
}
[dir='ltr'] .border-left-blue.border-left-lighten-1 {
    border-left: 1px solid #42a5f5 !important;
}
[dir='ltr'] .border-right-blue.border-right-lighten-1,
[dir='rtl'] .border-left-blue.border-left-lighten-1 {
    border-right: 1px solid #42a5f5 !important;
}
[dir='rtl'] .border-right-blue.border-right-lighten-1 {
    border-left: 1px solid #42a5f5 !important;
}
.overlay-blue.overlay-lighten-1 {
    background: #42a5f5;
    background: rgba(66, 165, 245, 0.8);
}
.blue {
    color: #2196f3 !important;
}
.bg-blue {
    background-color: #2196f3 !important;
}
.bg-blue .card-footer,
.bg-blue .card-header {
    background-color: initial;
}
.toast-blue {
    background-color: #2196f3;
}
.alert-blue {
    border-color: #2196f3 !important;
    background-color: #43a6f5 !important;
    color: #05365d !important;
}
.alert-blue .alert-link {
    color: #031f36 !important;
}
.border-blue {
    border-color: #2196f3;
}
.border-top-blue {
    border-top-color: #2196f3;
}
.border-bottom-blue {
    border-bottom-color: #2196f3;
}
[dir='ltr'] .border-left-blue {
    border-left-color: #2196f3;
}
[dir='ltr'] .border-right-blue,
[dir='rtl'] .border-left-blue {
    border-right-color: #2196f3;
}
[dir='rtl'] .border-right-blue {
    border-left-color: #2196f3;
}
.badge-blue {
    background-color: #2196f3;
}
.panel-blue {
    border-color: #2196f3;
}
.panel-blue .panel-heading {
    color: #fff;
    border-color: #2196f3;
    background-color: #39a1f4;
}
.bg-blue.tag-glow,
.border-blue.tag-glow {
    box-shadow: 0 0 10px #2196f3;
}
.overlay-blue {
    background: #2196f3;
    background: rgba(33, 150, 243, 0.8);
}
.card.card-outline-blue {
    border: 1px solid #2196f3;
}
.card.card-outline-blue,
.card.card-outline-blue .card-footer,
.card.card-outline-blue .card-header {
    background-color: initial;
}
.btn-blue.btn-flat {
    background-color: initial !important;
    color: #2196f3;
    border: none;
}
.btn-blue.btn-fab,
.btn-blue.btn-raised {
    background-color: #2196f3 !important;
    color: #fff !important;
    border-color: #2196f3;
}
.btn-blue.btn-fab.active,
.btn-blue.btn-raised.active {
    background-color: #0c84e4 !important;
    border-color: #0c84e4 !important;
}
.btn-group-raised .btn-blue {
    background-color: #2196f3 !important;
    color: #fff !important;
}
.btn-outline-blue {
    border: 1px solid #2196f3;
    background-color: initial;
    color: #2196f3;
    box-shadow: none !important;
}
.btn-outline-blue:focus {
    background-color: initial !important;
    color: #2196f3 !important;
    box-shadow: transparent !important;
}
.btn-outline-blue.active {
    background-color: #2196f3 !important;
    color: #fff !important;
}
.btn-outline-blue:hover {
    background-color: #128ff2 !important;
    color: #fff !important;
}
.btn-outline-blue:hover svg {
    color: #fff !important;
}
.btn-outline-blue.btn-fab,
.btn-outline-blue.btn-raised {
    border: 1px solid #2196f3;
    background-color: initial;
    color: #2196f3;
    box-shadow: none !important;
}
.btn-outline-blue.btn-fab:focus,
.btn-outline-blue.btn-raised:focus {
    background-color: initial;
}
.btn-outline-blue.btn-fab.active,
.btn-outline-blue.btn-raised.active {
    background-color: #2196f3 !important;
    color: #fff !important;
}
.btn-outline-blue.btn-fab:hover,
.btn-outline-blue.btn-raised:hover {
    background-color: #128ff2 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-blue {
    background-color: #2196f3;
}
.progress .progress-bar.progress-bar-blue[value]::-webkit-progress-value {
    background-color: #2196f3;
}
.progress .progress-bar.progress-bar-blue[value]::-moz-progress-bar {
    background-color: #2196f3;
}
.progress .progress-bar.progress-bar-blue[value]::-ms-fill {
    background-color: #2196f3;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-blue .progress-bar {
        background-color: #2196f3;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2196f3 !important;
}
.blue.darken-1 {
    color: #1e88e5 !important;
}
.bg-blue.bg-darken-1,
.btn-blue.btn-darken-1 {
    background-color: #1e88e5 !important;
}
.btn-blue.btn-darken-1,
.btn-blue.btn-darken-1:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-darken-1:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-1:active,
.btn-blue.btn-darken-1:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-darken-1 {
    border-color: #1e88e5 !important;
    color: #1e88e5 !important;
}
.btn-outline-blue.btn-outline-darken-1:hover {
    background-color: #1e88e5 !important;
}
.progress-blue.progress-darken-1 {
    background-color: #1e88e5;
}
.progress-blue.progress-darken-1[value]::-webkit-progress-value {
    background-color: #1e88e5;
}
.progress-blue.progress-darken-1[value]::-moz-progress-bar {
    background-color: #1e88e5;
}
.progress-blue.progress-darken-1[value]::-ms-fill {
    background-color: #1e88e5;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-darken-1 .progress-bar {
        background-color: #1e88e5;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1e88e5 !important;
}
.border-blue.border-darken-1 {
    border: 1px solid #1e88e5 !important;
}
.border-top-blue.border-top-darken-1 {
    border-top: 1px solid #1e88e5 !important;
}
.border-bottom-blue.border-bottom-darken-1 {
    border-bottom: 1px solid #1e88e5 !important;
}
[dir='ltr'] .border-left-blue.border-left-darken-1 {
    border-left: 1px solid #1e88e5 !important;
}
[dir='ltr'] .border-right-blue.border-right-darken-1,
[dir='rtl'] .border-left-blue.border-left-darken-1 {
    border-right: 1px solid #1e88e5 !important;
}
[dir='rtl'] .border-right-blue.border-right-darken-1 {
    border-left: 1px solid #1e88e5 !important;
}
.overlay-blue.overlay-darken-1 {
    background: #1e88e5;
    background: rgba(30, 136, 229, 0.8);
}
.blue.darken-2 {
    color: #1976d2 !important;
}
.bg-blue.bg-darken-2,
.btn-blue.btn-darken-2 {
    background-color: #1976d2 !important;
}
.btn-blue.btn-darken-2,
.btn-blue.btn-darken-2:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-darken-2:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-2:active,
.btn-blue.btn-darken-2:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-darken-2 {
    border-color: #1976d2 !important;
    color: #1976d2 !important;
}
.btn-outline-blue.btn-outline-darken-2:hover {
    background-color: #1976d2 !important;
}
.progress-blue.progress-darken-2 {
    background-color: #1976d2;
}
.progress-blue.progress-darken-2[value]::-webkit-progress-value {
    background-color: #1976d2;
}
.progress-blue.progress-darken-2[value]::-moz-progress-bar {
    background-color: #1976d2;
}
.progress-blue.progress-darken-2[value]::-ms-fill {
    background-color: #1976d2;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-darken-2 .progress-bar {
        background-color: #1976d2;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1976d2 !important;
}
.border-blue.border-darken-2 {
    border: 1px solid #1976d2 !important;
}
.border-top-blue.border-top-darken-2 {
    border-top: 1px solid #1976d2 !important;
}
.border-bottom-blue.border-bottom-darken-2 {
    border-bottom: 1px solid #1976d2 !important;
}
[dir='ltr'] .border-left-blue.border-left-darken-2 {
    border-left: 1px solid #1976d2 !important;
}
[dir='ltr'] .border-right-blue.border-right-darken-2,
[dir='rtl'] .border-left-blue.border-left-darken-2 {
    border-right: 1px solid #1976d2 !important;
}
[dir='rtl'] .border-right-blue.border-right-darken-2 {
    border-left: 1px solid #1976d2 !important;
}
.overlay-blue.overlay-darken-2 {
    background: #1976d2;
    background: rgba(25, 118, 210, 0.8);
}
.blue.darken-3 {
    color: #1565c0 !important;
}
.bg-blue.bg-darken-3 {
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-3,
.btn-blue.btn-darken-3:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-3:active,
.btn-blue.btn-darken-3:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-darken-3 {
    border-color: #1565c0 !important;
    color: #1565c0 !important;
}
.btn-outline-blue.btn-outline-darken-3:hover {
    background-color: #1565c0 !important;
}
.progress-blue.progress-darken-3 {
    background-color: #1565c0;
}
.progress-blue.progress-darken-3[value]::-webkit-progress-value {
    background-color: #1565c0;
}
.progress-blue.progress-darken-3[value]::-moz-progress-bar {
    background-color: #1565c0;
}
.progress-blue.progress-darken-3[value]::-ms-fill {
    background-color: #1565c0;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-darken-3 .progress-bar {
        background-color: #1565c0;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1565c0 !important;
}
.border-blue.border-darken-3 {
    border: 1px solid #1565c0 !important;
}
.border-top-blue.border-top-darken-3 {
    border-top: 1px solid #1565c0 !important;
}
.border-bottom-blue.border-bottom-darken-3 {
    border-bottom: 1px solid #1565c0 !important;
}
[dir='ltr'] .border-left-blue.border-left-darken-3 {
    border-left: 1px solid #1565c0 !important;
}
[dir='ltr'] .border-right-blue.border-right-darken-3,
[dir='rtl'] .border-left-blue.border-left-darken-3 {
    border-right: 1px solid #1565c0 !important;
}
[dir='rtl'] .border-right-blue.border-right-darken-3 {
    border-left: 1px solid #1565c0 !important;
}
.overlay-blue.overlay-darken-3 {
    background: #1565c0;
    background: rgba(21, 101, 192, 0.8);
}
.blue.darken-4 {
    color: #0d47a1 !important;
}
.bg-blue.bg-darken-4,
.btn-blue.btn-darken-4 {
    background-color: #0d47a1 !important;
}
.btn-blue.btn-darken-4,
.btn-blue.btn-darken-4:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-darken-4:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-darken-4:active,
.btn-blue.btn-darken-4:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-darken-4 {
    border-color: #0d47a1 !important;
    color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-darken-4:hover {
    background-color: #0d47a1 !important;
}
.progress-blue.progress-darken-4 {
    background-color: #0d47a1;
}
.progress-blue.progress-darken-4[value]::-webkit-progress-value {
    background-color: #0d47a1;
}
.progress-blue.progress-darken-4[value]::-moz-progress-bar {
    background-color: #0d47a1;
}
.progress-blue.progress-darken-4[value]::-ms-fill {
    background-color: #0d47a1;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-darken-4 .progress-bar {
        background-color: #0d47a1;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0d47a1 !important;
}
.border-blue.border-darken-4 {
    border: 1px solid #0d47a1 !important;
}
.border-top-blue.border-top-darken-4 {
    border-top: 1px solid #0d47a1 !important;
}
.border-bottom-blue.border-bottom-darken-4 {
    border-bottom: 1px solid #0d47a1 !important;
}
[dir='ltr'] .border-left-blue.border-left-darken-4 {
    border-left: 1px solid #0d47a1 !important;
}
[dir='ltr'] .border-right-blue.border-right-darken-4,
[dir='rtl'] .border-left-blue.border-left-darken-4 {
    border-right: 1px solid #0d47a1 !important;
}
[dir='rtl'] .border-right-blue.border-right-darken-4 {
    border-left: 1px solid #0d47a1 !important;
}
.overlay-blue.overlay-darken-4 {
    background: #0d47a1;
    background: rgba(13, 71, 161, 0.8);
}
.blue.accent-1 {
    color: #82b1ff !important;
}
.bg-blue.bg-accent-1,
.btn-blue.btn-accent-1 {
    background-color: #82b1ff !important;
}
.btn-blue.btn-accent-1,
.btn-blue.btn-accent-1:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-accent-1:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-1:active,
.btn-blue.btn-accent-1:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-accent-1 {
    border-color: #82b1ff !important;
    color: #82b1ff !important;
}
.btn-outline-blue.btn-outline-accent-1:hover {
    background-color: #82b1ff !important;
}
.progress-blue.progress-accent-1 {
    background-color: #82b1ff;
}
.progress-blue.progress-accent-1[value]::-webkit-progress-value {
    background-color: #82b1ff;
}
.progress-blue.progress-accent-1[value]::-moz-progress-bar {
    background-color: #82b1ff;
}
.progress-blue.progress-accent-1[value]::-ms-fill {
    background-color: #82b1ff;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-accent-1 .progress-bar {
        background-color: #82b1ff;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #82b1ff !important;
}
.border-blue.border-accent-1 {
    border: 1px solid #82b1ff !important;
}
.border-top-blue.border-top-accent-1 {
    border-top: 1px solid #82b1ff !important;
}
.border-bottom-blue.border-bottom-accent-1 {
    border-bottom: 1px solid #82b1ff !important;
}
[dir='ltr'] .border-left-blue.border-left-accent-1 {
    border-left: 1px solid #82b1ff !important;
}
[dir='ltr'] .border-right-blue.border-right-accent-1,
[dir='rtl'] .border-left-blue.border-left-accent-1 {
    border-right: 1px solid #82b1ff !important;
}
[dir='rtl'] .border-right-blue.border-right-accent-1 {
    border-left: 1px solid #82b1ff !important;
}
.overlay-blue.overlay-accent-1 {
    background: #82b1ff;
    background: rgba(130, 177, 255, 0.8);
}
.blue.accent-2 {
    color: #448aff !important;
}
.bg-blue.bg-accent-2,
.btn-blue.btn-accent-2 {
    background-color: #448aff !important;
}
.btn-blue.btn-accent-2,
.btn-blue.btn-accent-2:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-accent-2:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-2:active,
.btn-blue.btn-accent-2:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-accent-2 {
    border-color: #448aff !important;
    color: #448aff !important;
}
.btn-outline-blue.btn-outline-accent-2:hover {
    background-color: #448aff !important;
}
.progress-blue.progress-accent-2 {
    background-color: #448aff;
}
.progress-blue.progress-accent-2[value]::-webkit-progress-value {
    background-color: #448aff;
}
.progress-blue.progress-accent-2[value]::-moz-progress-bar {
    background-color: #448aff;
}
.progress-blue.progress-accent-2[value]::-ms-fill {
    background-color: #448aff;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-accent-2 .progress-bar {
        background-color: #448aff;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #448aff !important;
}
.border-blue.border-accent-2 {
    border: 1px solid #448aff !important;
}
.border-top-blue.border-top-accent-2 {
    border-top: 1px solid #448aff !important;
}
.border-bottom-blue.border-bottom-accent-2 {
    border-bottom: 1px solid #448aff !important;
}
[dir='ltr'] .border-left-blue.border-left-accent-2 {
    border-left: 1px solid #448aff !important;
}
[dir='ltr'] .border-right-blue.border-right-accent-2,
[dir='rtl'] .border-left-blue.border-left-accent-2 {
    border-right: 1px solid #448aff !important;
}
[dir='rtl'] .border-right-blue.border-right-accent-2 {
    border-left: 1px solid #448aff !important;
}
.overlay-blue.overlay-accent-2 {
    background: #448aff;
    background: rgba(68, 138, 255, 0.8);
}
.blue.accent-3 {
    color: #2979ff !important;
}
.bg-blue.bg-accent-3,
.btn-blue.btn-accent-3 {
    background-color: #2979ff !important;
}
.btn-blue.btn-accent-3,
.btn-blue.btn-accent-3:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-accent-3:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-3:active,
.btn-blue.btn-accent-3:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-accent-3 {
    border-color: #2979ff !important;
    color: #2979ff !important;
}
.btn-outline-blue.btn-outline-accent-3:hover {
    background-color: #2979ff !important;
}
.progress-blue.progress-accent-3 {
    background-color: #2979ff;
}
.progress-blue.progress-accent-3[value]::-webkit-progress-value {
    background-color: #2979ff;
}
.progress-blue.progress-accent-3[value]::-moz-progress-bar {
    background-color: #2979ff;
}
.progress-blue.progress-accent-3[value]::-ms-fill {
    background-color: #2979ff;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-accent-3 .progress-bar {
        background-color: #2979ff;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2979ff !important;
}
.border-blue.border-accent-3 {
    border: 1px solid #2979ff !important;
}
.border-top-blue.border-top-accent-3 {
    border-top: 1px solid #2979ff !important;
}
.border-bottom-blue.border-bottom-accent-3 {
    border-bottom: 1px solid #2979ff !important;
}
[dir='ltr'] .border-left-blue.border-left-accent-3 {
    border-left: 1px solid #2979ff !important;
}
[dir='ltr'] .border-right-blue.border-right-accent-3,
[dir='rtl'] .border-left-blue.border-left-accent-3 {
    border-right: 1px solid #2979ff !important;
}
[dir='rtl'] .border-right-blue.border-right-accent-3 {
    border-left: 1px solid #2979ff !important;
}
.overlay-blue.overlay-accent-3 {
    background: #2979ff;
    background: rgba(41, 121, 255, 0.8);
}
.blue.accent-4 {
    color: #2962ff !important;
}
.bg-blue.bg-accent-4,
.btn-blue.btn-accent-4 {
    background-color: #2962ff !important;
}
.btn-blue.btn-accent-4,
.btn-blue.btn-accent-4:hover {
    border-color: #1976d2 !important;
}
.btn-blue.btn-accent-4:hover {
    background-color: #1565c0 !important;
}
.btn-blue.btn-accent-4:active,
.btn-blue.btn-accent-4:focus {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important;
}
.btn-outline-blue.btn-outline-accent-4 {
    border-color: #2962ff !important;
    color: #2962ff !important;
}
.btn-outline-blue.btn-outline-accent-4:hover {
    background-color: #2962ff !important;
}
.progress-blue.progress-accent-4 {
    background-color: #2962ff;
}
.progress-blue.progress-accent-4[value]::-webkit-progress-value {
    background-color: #2962ff;
}
.progress-blue.progress-accent-4[value]::-moz-progress-bar {
    background-color: #2962ff;
}
.progress-blue.progress-accent-4[value]::-ms-fill {
    background-color: #2962ff;
}
@media screen and (min-width: 0\0) {
    .progress-blue.progress-accent-4 .progress-bar {
        background-color: #2962ff;
    }
}
input:focus ~ .bg-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2962ff !important;
}
.border-blue.border-accent-4 {
    border: 1px solid #2962ff !important;
}
.border-top-blue.border-top-accent-4 {
    border-top: 1px solid #2962ff !important;
}
.border-bottom-blue.border-bottom-accent-4 {
    border-bottom: 1px solid #2962ff !important;
}
[dir='ltr'] .border-left-blue.border-left-accent-4 {
    border-left: 1px solid #2962ff !important;
}
[dir='ltr'] .border-right-blue.border-right-accent-4,
[dir='rtl'] .border-left-blue.border-left-accent-4 {
    border-right: 1px solid #2962ff !important;
}
[dir='rtl'] .border-right-blue.border-right-accent-4 {
    border-left: 1px solid #2962ff !important;
}
.overlay-blue.overlay-accent-4 {
    background: #2962ff;
    background: rgba(41, 98, 255, 0.8);
}
.light-blue.lighten-5 {
    color: #e1f5fe !important;
}
.bg-light-blue.bg-lighten-5,
.btn-light-blue.btn-lighten-5 {
    background-color: #e1f5fe !important;
}
.btn-light-blue.btn-lighten-5 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-lighten-5:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-5:active,
.btn-light-blue.btn-lighten-5:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-lighten-5 {
    border-color: #e1f5fe !important;
    color: #e1f5fe !important;
}
.btn-outline-light-blue.btn-outline-lighten-5:hover {
    background-color: #e1f5fe !important;
}
.progress-light-blue.progress-lighten-5 {
    background-color: #e1f5fe;
}
.progress-light-blue.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e1f5fe;
}
.progress-light-blue.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e1f5fe;
}
.progress-light-blue.progress-lighten-5[value]::-ms-fill {
    background-color: #e1f5fe;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-lighten-5 .progress-bar {
        background-color: #e1f5fe;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e1f5fe !important;
}
.border-light-blue.border-lighten-5 {
    border: 1px solid #e1f5fe !important;
}
.border-top-light-blue.border-top-lighten-5 {
    border-top: 1px solid #e1f5fe !important;
}
.border-bottom-light-blue.border-bottom-lighten-5 {
    border-bottom: 1px solid #e1f5fe !important;
}
[dir='ltr'] .border-left-light-blue.border-left-lighten-5 {
    border-left: 1px solid #e1f5fe !important;
}
[dir='ltr'] .border-right-light-blue.border-right-lighten-5,
[dir='rtl'] .border-left-light-blue.border-left-lighten-5 {
    border-right: 1px solid #e1f5fe !important;
}
[dir='rtl'] .border-right-light-blue.border-right-lighten-5 {
    border-left: 1px solid #e1f5fe !important;
}
.overlay-light-blue.overlay-lighten-5 {
    background: #e1f5fe;
    background: rgba(225, 245, 254, 0.8);
}
.light-blue.lighten-4 {
    color: #b3e5fc !important;
}
.bg-light-blue.bg-lighten-4,
.btn-light-blue.btn-lighten-4 {
    background-color: #b3e5fc !important;
}
.btn-light-blue.btn-lighten-4 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-lighten-4:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-4:active,
.btn-light-blue.btn-lighten-4:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-lighten-4 {
    border-color: #b3e5fc !important;
    color: #b3e5fc !important;
}
.btn-outline-light-blue.btn-outline-lighten-4:hover {
    background-color: #b3e5fc !important;
}
.progress-light-blue.progress-lighten-4 {
    background-color: #b3e5fc;
}
.progress-light-blue.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b3e5fc;
}
.progress-light-blue.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b3e5fc;
}
.progress-light-blue.progress-lighten-4[value]::-ms-fill {
    background-color: #b3e5fc;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-lighten-4 .progress-bar {
        background-color: #b3e5fc;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b3e5fc !important;
}
.border-light-blue.border-lighten-4 {
    border: 1px solid #b3e5fc !important;
}
.border-top-light-blue.border-top-lighten-4 {
    border-top: 1px solid #b3e5fc !important;
}
.border-bottom-light-blue.border-bottom-lighten-4 {
    border-bottom: 1px solid #b3e5fc !important;
}
[dir='ltr'] .border-left-light-blue.border-left-lighten-4 {
    border-left: 1px solid #b3e5fc !important;
}
[dir='ltr'] .border-right-light-blue.border-right-lighten-4,
[dir='rtl'] .border-left-light-blue.border-left-lighten-4 {
    border-right: 1px solid #b3e5fc !important;
}
[dir='rtl'] .border-right-light-blue.border-right-lighten-4 {
    border-left: 1px solid #b3e5fc !important;
}
.overlay-light-blue.overlay-lighten-4 {
    background: #b3e5fc;
    background: rgba(179, 229, 252, 0.8);
}
.light-blue.lighten-3 {
    color: #81d4fa !important;
}
.bg-light-blue.bg-lighten-3,
.btn-light-blue.btn-lighten-3 {
    background-color: #81d4fa !important;
}
.btn-light-blue.btn-lighten-3 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-lighten-3:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-3:active,
.btn-light-blue.btn-lighten-3:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-lighten-3 {
    border-color: #81d4fa !important;
    color: #81d4fa !important;
}
.btn-outline-light-blue.btn-outline-lighten-3:hover {
    background-color: #81d4fa !important;
}
.progress-light-blue.progress-lighten-3 {
    background-color: #81d4fa;
}
.progress-light-blue.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #81d4fa;
}
.progress-light-blue.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #81d4fa;
}
.progress-light-blue.progress-lighten-3[value]::-ms-fill {
    background-color: #81d4fa;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-lighten-3 .progress-bar {
        background-color: #81d4fa;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #81d4fa !important;
}
.border-light-blue.border-lighten-3 {
    border: 1px solid #81d4fa !important;
}
.border-top-light-blue.border-top-lighten-3 {
    border-top: 1px solid #81d4fa !important;
}
.border-bottom-light-blue.border-bottom-lighten-3 {
    border-bottom: 1px solid #81d4fa !important;
}
[dir='ltr'] .border-left-light-blue.border-left-lighten-3 {
    border-left: 1px solid #81d4fa !important;
}
[dir='ltr'] .border-right-light-blue.border-right-lighten-3,
[dir='rtl'] .border-left-light-blue.border-left-lighten-3 {
    border-right: 1px solid #81d4fa !important;
}
[dir='rtl'] .border-right-light-blue.border-right-lighten-3 {
    border-left: 1px solid #81d4fa !important;
}
.overlay-light-blue.overlay-lighten-3 {
    background: #81d4fa;
    background: rgba(129, 212, 250, 0.8);
}
.light-blue.lighten-2 {
    color: #4fc3f7 !important;
}
.bg-light-blue.bg-lighten-2,
.btn-light-blue.btn-lighten-2 {
    background-color: #4fc3f7 !important;
}
.btn-light-blue.btn-lighten-2 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-lighten-2:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-2:active,
.btn-light-blue.btn-lighten-2:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-lighten-2 {
    border-color: #4fc3f7 !important;
    color: #4fc3f7 !important;
}
.btn-outline-light-blue.btn-outline-lighten-2:hover {
    background-color: #4fc3f7 !important;
}
.progress-light-blue.progress-lighten-2 {
    background-color: #4fc3f7;
}
.progress-light-blue.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4fc3f7;
}
.progress-light-blue.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4fc3f7;
}
.progress-light-blue.progress-lighten-2[value]::-ms-fill {
    background-color: #4fc3f7;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-lighten-2 .progress-bar {
        background-color: #4fc3f7;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4fc3f7 !important;
}
.border-light-blue.border-lighten-2 {
    border: 1px solid #4fc3f7 !important;
}
.border-top-light-blue.border-top-lighten-2 {
    border-top: 1px solid #4fc3f7 !important;
}
.border-bottom-light-blue.border-bottom-lighten-2 {
    border-bottom: 1px solid #4fc3f7 !important;
}
[dir='ltr'] .border-left-light-blue.border-left-lighten-2 {
    border-left: 1px solid #4fc3f7 !important;
}
[dir='ltr'] .border-right-light-blue.border-right-lighten-2,
[dir='rtl'] .border-left-light-blue.border-left-lighten-2 {
    border-right: 1px solid #4fc3f7 !important;
}
[dir='rtl'] .border-right-light-blue.border-right-lighten-2 {
    border-left: 1px solid #4fc3f7 !important;
}
.overlay-light-blue.overlay-lighten-2 {
    background: #4fc3f7;
    background: rgba(79, 195, 247, 0.8);
}
.light-blue.lighten-1 {
    color: #29b6f6 !important;
}
.bg-light-blue.bg-lighten-1,
.btn-light-blue.btn-lighten-1 {
    background-color: #29b6f6 !important;
}
.btn-light-blue.btn-lighten-1 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-lighten-1:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-lighten-1:active,
.btn-light-blue.btn-lighten-1:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-lighten-1 {
    border-color: #29b6f6 !important;
    color: #29b6f6 !important;
}
.btn-outline-light-blue.btn-outline-lighten-1:hover {
    background-color: #29b6f6 !important;
}
.progress-light-blue.progress-lighten-1 {
    background-color: #29b6f6;
}
.progress-light-blue.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #29b6f6;
}
.progress-light-blue.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #29b6f6;
}
.progress-light-blue.progress-lighten-1[value]::-ms-fill {
    background-color: #29b6f6;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-lighten-1 .progress-bar {
        background-color: #29b6f6;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #29b6f6 !important;
}
.border-light-blue.border-lighten-1 {
    border: 1px solid #29b6f6 !important;
}
.border-top-light-blue.border-top-lighten-1 {
    border-top: 1px solid #29b6f6 !important;
}
.border-bottom-light-blue.border-bottom-lighten-1 {
    border-bottom: 1px solid #29b6f6 !important;
}
[dir='ltr'] .border-left-light-blue.border-left-lighten-1 {
    border-left: 1px solid #29b6f6 !important;
}
[dir='ltr'] .border-right-light-blue.border-right-lighten-1,
[dir='rtl'] .border-left-light-blue.border-left-lighten-1 {
    border-right: 1px solid #29b6f6 !important;
}
[dir='rtl'] .border-right-light-blue.border-right-lighten-1 {
    border-left: 1px solid #29b6f6 !important;
}
.overlay-light-blue.overlay-lighten-1 {
    background: #29b6f6;
    background: rgba(41, 182, 246, 0.8);
}
.light-blue {
    color: #03a9f4 !important;
}
.bg-light-blue {
    background-color: #03a9f4 !important;
}
.bg-light-blue .card-footer,
.bg-light-blue .card-header {
    background-color: initial;
}
.toast-light-blue {
    background-color: #03a9f4;
}
.alert-light-blue {
    border-color: #03a9f4 !important;
    background-color: #1eb7fc !important;
    color: #012f44 !important;
}
.alert-light-blue .alert-link {
    color: #00131b !important;
}
.border-light-blue {
    border-color: #03a9f4;
}
.border-top-light-blue {
    border-top-color: #03a9f4;
}
.border-bottom-light-blue {
    border-bottom-color: #03a9f4;
}
[dir='ltr'] .border-left-light-blue {
    border-left-color: #03a9f4;
}
[dir='ltr'] .border-right-light-blue,
[dir='rtl'] .border-left-light-blue {
    border-right-color: #03a9f4;
}
[dir='rtl'] .border-right-light-blue {
    border-left-color: #03a9f4;
}
.badge-light-blue {
    background-color: #03a9f4;
}
.panel-light-blue {
    border-color: #03a9f4;
}
.panel-light-blue .panel-heading {
    color: #fff;
    border-color: #03a9f4;
    background-color: #14b4fc;
}
.bg-light-blue.tag-glow,
.border-light-blue.tag-glow {
    box-shadow: 0 0 10px #03a9f4;
}
.overlay-light-blue {
    background: #03a9f4;
    background: rgba(3, 169, 244, 0.8);
}
.card.card-outline-light-blue {
    border: 1px solid #03a9f4;
    background-color: initial;
}
.card.card-outline-light-blue .card-footer,
.card.card-outline-light-blue .card-header {
    background-color: initial;
}
.btn-light-blue.btn-flat {
    background-color: initial !important;
    color: #03a9f4;
    border: none;
}
.btn-light-blue.btn-fab,
.btn-light-blue.btn-raised {
    background-color: #03a9f4 !important;
    color: #fff !important;
    border-color: #03a9f4;
}
.btn-light-blue.btn-fab.active,
.btn-light-blue.btn-raised.active {
    background-color: #0391d1 !important;
    border-color: #0391d1 !important;
}
.btn-group-raised .btn-light-blue {
    background-color: #03a9f4 !important;
    color: #fff !important;
}
.btn-outline-light-blue {
    border: 1px solid #03a9f4;
    background-color: initial;
    color: #03a9f4;
    box-shadow: none !important;
}
.btn-outline-light-blue:focus {
    background-color: initial !important;
    color: #03a9f4 !important;
    box-shadow: transparent !important;
}
.btn-outline-light-blue.active {
    background-color: #03a9f4 !important;
    color: #fff !important;
}
.btn-outline-light-blue:hover {
    background-color: #039fe5 !important;
    color: #fff !important;
}
.btn-outline-light-blue:hover svg {
    color: #fff !important;
}
.btn-outline-light-blue.btn-fab,
.btn-outline-light-blue.btn-raised {
    border: 1px solid #03a9f4;
    background-color: initial;
    color: #03a9f4;
    box-shadow: none !important;
}
.btn-outline-light-blue.btn-fab:focus,
.btn-outline-light-blue.btn-raised:focus {
    background-color: initial;
}
.btn-outline-light-blue.btn-fab.active,
.btn-outline-light-blue.btn-raised.active {
    background-color: #03a9f4 !important;
    color: #fff !important;
}
.btn-outline-light-blue.btn-fab:hover,
.btn-outline-light-blue.btn-raised:hover {
    background-color: #039fe5 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-light-blue {
    background-color: #03a9f4;
}
.progress .progress-bar.progress-bar-light-blue[value]::-webkit-progress-value {
    background-color: #03a9f4;
}
.progress .progress-bar.progress-bar-light-blue[value]::-moz-progress-bar {
    background-color: #03a9f4;
}
.progress .progress-bar.progress-bar-light-blue[value]::-ms-fill {
    background-color: #03a9f4;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-light-blue .progress-bar {
        background-color: #03a9f4;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #03a9f4 !important;
}
.light-blue.darken-1 {
    color: #039be5 !important;
}
.bg-light-blue.bg-darken-1,
.btn-light-blue.btn-darken-1 {
    background-color: #039be5 !important;
}
.btn-light-blue.btn-darken-1 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-darken-1:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-1:active,
.btn-light-blue.btn-darken-1:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-darken-1 {
    border-color: #039be5 !important;
    color: #039be5 !important;
}
.btn-outline-light-blue.btn-outline-darken-1:hover {
    background-color: #039be5 !important;
}
.progress-light-blue.progress-darken-1 {
    background-color: #039be5;
}
.progress-light-blue.progress-darken-1[value]::-webkit-progress-value {
    background-color: #039be5;
}
.progress-light-blue.progress-darken-1[value]::-moz-progress-bar {
    background-color: #039be5;
}
.progress-light-blue.progress-darken-1[value]::-ms-fill {
    background-color: #039be5;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-darken-1 .progress-bar {
        background-color: #039be5;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #039be5 !important;
}
.border-light-blue.border-darken-1 {
    border: 1px solid #039be5 !important;
}
.border-top-light-blue.border-top-darken-1 {
    border-top: 1px solid #039be5 !important;
}
.border-bottom-light-blue.border-bottom-darken-1 {
    border-bottom: 1px solid #039be5 !important;
}
[dir='ltr'] .border-left-light-blue.border-left-darken-1 {
    border-left: 1px solid #039be5 !important;
}
[dir='ltr'] .border-right-light-blue.border-right-darken-1,
[dir='rtl'] .border-left-light-blue.border-left-darken-1 {
    border-right: 1px solid #039be5 !important;
}
[dir='rtl'] .border-right-light-blue.border-right-darken-1 {
    border-left: 1px solid #039be5 !important;
}
.overlay-light-blue.overlay-darken-1 {
    background: #039be5;
    background: rgba(3, 155, 229, 0.8);
}
.light-blue.darken-2 {
    color: #0288d1 !important;
}
.bg-light-blue.bg-darken-2,
.btn-light-blue.btn-darken-2 {
    background-color: #0288d1 !important;
}
.btn-light-blue.btn-darken-2 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-darken-2:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-2:active,
.btn-light-blue.btn-darken-2:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-darken-2 {
    border-color: #0288d1 !important;
    color: #0288d1 !important;
}
.btn-outline-light-blue.btn-outline-darken-2:hover {
    background-color: #0288d1 !important;
}
.progress-light-blue.progress-darken-2 {
    background-color: #0288d1;
}
.progress-light-blue.progress-darken-2[value]::-webkit-progress-value {
    background-color: #0288d1;
}
.progress-light-blue.progress-darken-2[value]::-moz-progress-bar {
    background-color: #0288d1;
}
.progress-light-blue.progress-darken-2[value]::-ms-fill {
    background-color: #0288d1;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-darken-2 .progress-bar {
        background-color: #0288d1;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0288d1 !important;
}
.border-light-blue.border-darken-2 {
    border: 1px solid #0288d1 !important;
}
.border-top-light-blue.border-top-darken-2 {
    border-top: 1px solid #0288d1 !important;
}
.border-bottom-light-blue.border-bottom-darken-2 {
    border-bottom: 1px solid #0288d1 !important;
}
[dir='ltr'] .border-left-light-blue.border-left-darken-2 {
    border-left: 1px solid #0288d1 !important;
}
[dir='ltr'] .border-right-light-blue.border-right-darken-2,
[dir='rtl'] .border-left-light-blue.border-left-darken-2 {
    border-right: 1px solid #0288d1 !important;
}
[dir='rtl'] .border-right-light-blue.border-right-darken-2 {
    border-left: 1px solid #0288d1 !important;
}
.overlay-light-blue.overlay-darken-2 {
    background: #0288d1;
    background: rgba(2, 136, 209, 0.8);
}
.light-blue.darken-3 {
    color: #0277bd !important;
}
.bg-light-blue.bg-darken-3 {
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-3,
.btn-light-blue.btn-darken-3:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-3:active,
.btn-light-blue.btn-darken-3:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-darken-3 {
    border-color: #0277bd !important;
    color: #0277bd !important;
}
.btn-outline-light-blue.btn-outline-darken-3:hover {
    background-color: #0277bd !important;
}
.progress-light-blue.progress-darken-3 {
    background-color: #0277bd;
}
.progress-light-blue.progress-darken-3[value]::-webkit-progress-value {
    background-color: #0277bd;
}
.progress-light-blue.progress-darken-3[value]::-moz-progress-bar {
    background-color: #0277bd;
}
.progress-light-blue.progress-darken-3[value]::-ms-fill {
    background-color: #0277bd;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-darken-3 .progress-bar {
        background-color: #0277bd;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0277bd !important;
}
.border-light-blue.border-darken-3 {
    border: 1px solid #0277bd !important;
}
.border-top-light-blue.border-top-darken-3 {
    border-top: 1px solid #0277bd !important;
}
.border-bottom-light-blue.border-bottom-darken-3 {
    border-bottom: 1px solid #0277bd !important;
}
[dir='ltr'] .border-left-light-blue.border-left-darken-3 {
    border-left: 1px solid #0277bd !important;
}
[dir='ltr'] .border-right-light-blue.border-right-darken-3,
[dir='rtl'] .border-left-light-blue.border-left-darken-3 {
    border-right: 1px solid #0277bd !important;
}
[dir='rtl'] .border-right-light-blue.border-right-darken-3 {
    border-left: 1px solid #0277bd !important;
}
.overlay-light-blue.overlay-darken-3 {
    background: #0277bd;
    background: rgba(2, 119, 189, 0.8);
}
.light-blue.darken-4 {
    color: #01579b !important;
}
.bg-light-blue.bg-darken-4,
.btn-light-blue.btn-darken-4 {
    background-color: #01579b !important;
}
.btn-light-blue.btn-darken-4 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-darken-4:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-darken-4:active,
.btn-light-blue.btn-darken-4:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-darken-4 {
    border-color: #01579b !important;
    color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-darken-4:hover {
    background-color: #01579b !important;
}
.progress-light-blue.progress-darken-4 {
    background-color: #01579b;
}
.progress-light-blue.progress-darken-4[value]::-webkit-progress-value {
    background-color: #01579b;
}
.progress-light-blue.progress-darken-4[value]::-moz-progress-bar {
    background-color: #01579b;
}
.progress-light-blue.progress-darken-4[value]::-ms-fill {
    background-color: #01579b;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-darken-4 .progress-bar {
        background-color: #01579b;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #01579b !important;
}
.border-light-blue.border-darken-4 {
    border: 1px solid #01579b !important;
}
.border-top-light-blue.border-top-darken-4 {
    border-top: 1px solid #01579b !important;
}
.border-bottom-light-blue.border-bottom-darken-4 {
    border-bottom: 1px solid #01579b !important;
}
[dir='ltr'] .border-left-light-blue.border-left-darken-4 {
    border-left: 1px solid #01579b !important;
}
[dir='ltr'] .border-right-light-blue.border-right-darken-4,
[dir='rtl'] .border-left-light-blue.border-left-darken-4 {
    border-right: 1px solid #01579b !important;
}
[dir='rtl'] .border-right-light-blue.border-right-darken-4 {
    border-left: 1px solid #01579b !important;
}
.overlay-light-blue.overlay-darken-4 {
    background: #01579b;
    background: rgba(1, 87, 155, 0.8);
}
.light-blue.accent-1 {
    color: #80d8ff !important;
}
.bg-light-blue.bg-accent-1,
.btn-light-blue.btn-accent-1 {
    background-color: #80d8ff !important;
}
.btn-light-blue.btn-accent-1 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-accent-1:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-accent-1:active,
.btn-light-blue.btn-accent-1:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-accent-1 {
    border-color: #80d8ff !important;
    color: #80d8ff !important;
}
.btn-outline-light-blue.btn-outline-accent-1:hover {
    background-color: #80d8ff !important;
}
.progress-light-blue.progress-accent-1 {
    background-color: #80d8ff;
}
.progress-light-blue.progress-accent-1[value]::-webkit-progress-value {
    background-color: #80d8ff;
}
.progress-light-blue.progress-accent-1[value]::-moz-progress-bar {
    background-color: #80d8ff;
}
.progress-light-blue.progress-accent-1[value]::-ms-fill {
    background-color: #80d8ff;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-accent-1 .progress-bar {
        background-color: #80d8ff;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80d8ff !important;
}
.border-light-blue.border-accent-1 {
    border: 1px solid #80d8ff !important;
}
.border-top-light-blue.border-top-accent-1 {
    border-top: 1px solid #80d8ff !important;
}
.border-bottom-light-blue.border-bottom-accent-1 {
    border-bottom: 1px solid #80d8ff !important;
}
[dir='ltr'] .border-left-light-blue.border-left-accent-1 {
    border-left: 1px solid #80d8ff !important;
}
[dir='ltr'] .border-right-light-blue.border-right-accent-1,
[dir='rtl'] .border-left-light-blue.border-left-accent-1 {
    border-right: 1px solid #80d8ff !important;
}
[dir='rtl'] .border-right-light-blue.border-right-accent-1 {
    border-left: 1px solid #80d8ff !important;
}
.overlay-light-blue.overlay-accent-1 {
    background: #80d8ff;
    background: rgba(128, 216, 255, 0.8);
}
.light-blue.accent-2 {
    color: #40c4ff !important;
}
.bg-light-blue.bg-accent-2,
.btn-light-blue.btn-accent-2 {
    background-color: #40c4ff !important;
}
.btn-light-blue.btn-accent-2 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-accent-2:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-accent-2:active,
.btn-light-blue.btn-accent-2:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-accent-2 {
    border-color: #40c4ff !important;
    color: #40c4ff !important;
}
.btn-outline-light-blue.btn-outline-accent-2:hover {
    background-color: #40c4ff !important;
}
.progress-light-blue.progress-accent-2 {
    background-color: #40c4ff;
}
.progress-light-blue.progress-accent-2[value]::-webkit-progress-value {
    background-color: #40c4ff;
}
.progress-light-blue.progress-accent-2[value]::-moz-progress-bar {
    background-color: #40c4ff;
}
.progress-light-blue.progress-accent-2[value]::-ms-fill {
    background-color: #40c4ff;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-accent-2 .progress-bar {
        background-color: #40c4ff;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #40c4ff !important;
}
.border-light-blue.border-accent-2 {
    border: 1px solid #40c4ff !important;
}
.border-top-light-blue.border-top-accent-2 {
    border-top: 1px solid #40c4ff !important;
}
.border-bottom-light-blue.border-bottom-accent-2 {
    border-bottom: 1px solid #40c4ff !important;
}
[dir='ltr'] .border-left-light-blue.border-left-accent-2 {
    border-left: 1px solid #40c4ff !important;
}
[dir='ltr'] .border-right-light-blue.border-right-accent-2,
[dir='rtl'] .border-left-light-blue.border-left-accent-2 {
    border-right: 1px solid #40c4ff !important;
}
[dir='rtl'] .border-right-light-blue.border-right-accent-2 {
    border-left: 1px solid #40c4ff !important;
}
.overlay-light-blue.overlay-accent-2 {
    background: #40c4ff;
    background: rgba(64, 196, 255, 0.8);
}
.light-blue.accent-3 {
    color: #00b0ff !important;
}
.bg-light-blue.bg-accent-3,
.btn-light-blue.btn-accent-3 {
    background-color: #00b0ff !important;
}
.btn-light-blue.btn-accent-3 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-accent-3:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-accent-3:active,
.btn-light-blue.btn-accent-3:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-accent-3 {
    border-color: #00b0ff !important;
    color: #00b0ff !important;
}
.btn-outline-light-blue.btn-outline-accent-3:hover {
    background-color: #00b0ff !important;
}
.progress-light-blue.progress-accent-3 {
    background-color: #00b0ff;
}
.progress-light-blue.progress-accent-3[value]::-webkit-progress-value {
    background-color: #00b0ff;
}
.progress-light-blue.progress-accent-3[value]::-moz-progress-bar {
    background-color: #00b0ff;
}
.progress-light-blue.progress-accent-3[value]::-ms-fill {
    background-color: #00b0ff;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-accent-3 .progress-bar {
        background-color: #00b0ff;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00b0ff !important;
}
.border-light-blue.border-accent-3 {
    border: 1px solid #00b0ff !important;
}
.border-top-light-blue.border-top-accent-3 {
    border-top: 1px solid #00b0ff !important;
}
.border-bottom-light-blue.border-bottom-accent-3 {
    border-bottom: 1px solid #00b0ff !important;
}
[dir='ltr'] .border-left-light-blue.border-left-accent-3 {
    border-left: 1px solid #00b0ff !important;
}
[dir='ltr'] .border-right-light-blue.border-right-accent-3,
[dir='rtl'] .border-left-light-blue.border-left-accent-3 {
    border-right: 1px solid #00b0ff !important;
}
[dir='rtl'] .border-right-light-blue.border-right-accent-3 {
    border-left: 1px solid #00b0ff !important;
}
.overlay-light-blue.overlay-accent-3 {
    background: #00b0ff;
    background: rgba(0, 176, 255, 0.8);
}
.light-blue.accent-4 {
    color: #0091ea !important;
}
.bg-light-blue.bg-accent-4,
.btn-light-blue.btn-accent-4 {
    background-color: #0091ea !important;
}
.btn-light-blue.btn-accent-4 {
    border-color: #0288d1 !important;
}
.btn-light-blue.btn-accent-4:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important;
}
.btn-light-blue.btn-accent-4:active,
.btn-light-blue.btn-accent-4:focus {
    border-color: #0277bd !important;
    background-color: #01579b !important;
}
.btn-outline-light-blue.btn-outline-accent-4 {
    border-color: #0091ea !important;
    color: #0091ea !important;
}
.btn-outline-light-blue.btn-outline-accent-4:hover {
    background-color: #0091ea !important;
}
.progress-light-blue.progress-accent-4 {
    background-color: #0091ea;
}
.progress-light-blue.progress-accent-4[value]::-webkit-progress-value {
    background-color: #0091ea;
}
.progress-light-blue.progress-accent-4[value]::-moz-progress-bar {
    background-color: #0091ea;
}
.progress-light-blue.progress-accent-4[value]::-ms-fill {
    background-color: #0091ea;
}
@media screen and (min-width: 0\0) {
    .progress-light-blue.progress-accent-4 .progress-bar {
        background-color: #0091ea;
    }
}
input:focus ~ .bg-light-blue {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0091ea !important;
}
.border-light-blue.border-accent-4 {
    border: 1px solid #0091ea !important;
}
.border-top-light-blue.border-top-accent-4 {
    border-top: 1px solid #0091ea !important;
}
.border-bottom-light-blue.border-bottom-accent-4 {
    border-bottom: 1px solid #0091ea !important;
}
[dir='ltr'] .border-left-light-blue.border-left-accent-4 {
    border-left: 1px solid #0091ea !important;
}
[dir='ltr'] .border-right-light-blue.border-right-accent-4,
[dir='rtl'] .border-left-light-blue.border-left-accent-4 {
    border-right: 1px solid #0091ea !important;
}
[dir='rtl'] .border-right-light-blue.border-right-accent-4 {
    border-left: 1px solid #0091ea !important;
}
.overlay-light-blue.overlay-accent-4 {
    background: #0091ea;
    background: rgba(0, 145, 234, 0.8);
}
.cyan.lighten-5 {
    color: #e0f7fa !important;
}
.bg-cyan.bg-lighten-5,
.btn-cyan.btn-lighten-5 {
    background-color: #e0f7fa !important;
}
.btn-cyan.btn-lighten-5,
.btn-cyan.btn-lighten-5:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-lighten-5:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-5:active,
.btn-cyan.btn-lighten-5:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-lighten-5 {
    border-color: #e0f7fa !important;
    color: #e0f7fa !important;
}
.btn-outline-cyan.btn-outline-lighten-5:hover {
    background-color: #e0f7fa !important;
}
.progress-cyan.progress-lighten-5 {
    background-color: #e0f7fa;
}
.progress-cyan.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e0f7fa;
}
.progress-cyan.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e0f7fa;
}
.progress-cyan.progress-lighten-5[value]::-ms-fill {
    background-color: #e0f7fa;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-lighten-5 .progress-bar {
        background-color: #e0f7fa;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0f7fa !important;
}
.border-cyan.border-lighten-5 {
    border: 1px solid #e0f7fa !important;
}
.border-top-cyan.border-top-lighten-5 {
    border-top: 1px solid #e0f7fa !important;
}
.border-bottom-cyan.border-bottom-lighten-5 {
    border-bottom: 1px solid #e0f7fa !important;
}
[dir='ltr'] .border-left-cyan.border-left-lighten-5 {
    border-left: 1px solid #e0f7fa !important;
}
[dir='ltr'] .border-right-cyan.border-right-lighten-5,
[dir='rtl'] .border-left-cyan.border-left-lighten-5 {
    border-right: 1px solid #e0f7fa !important;
}
[dir='rtl'] .border-right-cyan.border-right-lighten-5 {
    border-left: 1px solid #e0f7fa !important;
}
.overlay-cyan.overlay-lighten-5 {
    background: #e0f7fa;
    background: rgba(224, 247, 250, 0.8);
}
.cyan.lighten-4 {
    color: #b2ebf2 !important;
}
.bg-cyan.bg-lighten-4,
.btn-cyan.btn-lighten-4 {
    background-color: #b2ebf2 !important;
}
.btn-cyan.btn-lighten-4,
.btn-cyan.btn-lighten-4:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-lighten-4:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-4:active,
.btn-cyan.btn-lighten-4:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-lighten-4 {
    border-color: #b2ebf2 !important;
    color: #b2ebf2 !important;
}
.btn-outline-cyan.btn-outline-lighten-4:hover {
    background-color: #b2ebf2 !important;
}
.progress-cyan.progress-lighten-4 {
    background-color: #b2ebf2;
}
.progress-cyan.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b2ebf2;
}
.progress-cyan.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b2ebf2;
}
.progress-cyan.progress-lighten-4[value]::-ms-fill {
    background-color: #b2ebf2;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-lighten-4 .progress-bar {
        background-color: #b2ebf2;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b2ebf2 !important;
}
.border-cyan.border-lighten-4 {
    border: 1px solid #b2ebf2 !important;
}
.border-top-cyan.border-top-lighten-4 {
    border-top: 1px solid #b2ebf2 !important;
}
.border-bottom-cyan.border-bottom-lighten-4 {
    border-bottom: 1px solid #b2ebf2 !important;
}
[dir='ltr'] .border-left-cyan.border-left-lighten-4 {
    border-left: 1px solid #b2ebf2 !important;
}
[dir='ltr'] .border-right-cyan.border-right-lighten-4,
[dir='rtl'] .border-left-cyan.border-left-lighten-4 {
    border-right: 1px solid #b2ebf2 !important;
}
[dir='rtl'] .border-right-cyan.border-right-lighten-4 {
    border-left: 1px solid #b2ebf2 !important;
}
.overlay-cyan.overlay-lighten-4 {
    background: #b2ebf2;
    background: rgba(178, 235, 242, 0.8);
}
.cyan.lighten-3 {
    color: #80deea !important;
}
.bg-cyan.bg-lighten-3,
.btn-cyan.btn-lighten-3 {
    background-color: #80deea !important;
}
.btn-cyan.btn-lighten-3,
.btn-cyan.btn-lighten-3:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-lighten-3:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-3:active,
.btn-cyan.btn-lighten-3:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-lighten-3 {
    border-color: #80deea !important;
    color: #80deea !important;
}
.btn-outline-cyan.btn-outline-lighten-3:hover {
    background-color: #80deea !important;
}
.progress-cyan.progress-lighten-3 {
    background-color: #80deea;
}
.progress-cyan.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #80deea;
}
.progress-cyan.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #80deea;
}
.progress-cyan.progress-lighten-3[value]::-ms-fill {
    background-color: #80deea;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-lighten-3 .progress-bar {
        background-color: #80deea;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80deea !important;
}
.border-cyan.border-lighten-3 {
    border: 1px solid #80deea !important;
}
.border-top-cyan.border-top-lighten-3 {
    border-top: 1px solid #80deea !important;
}
.border-bottom-cyan.border-bottom-lighten-3 {
    border-bottom: 1px solid #80deea !important;
}
[dir='ltr'] .border-left-cyan.border-left-lighten-3 {
    border-left: 1px solid #80deea !important;
}
[dir='ltr'] .border-right-cyan.border-right-lighten-3,
[dir='rtl'] .border-left-cyan.border-left-lighten-3 {
    border-right: 1px solid #80deea !important;
}
[dir='rtl'] .border-right-cyan.border-right-lighten-3 {
    border-left: 1px solid #80deea !important;
}
.overlay-cyan.overlay-lighten-3 {
    background: #80deea;
    background: rgba(128, 222, 234, 0.8);
}
.cyan.lighten-2 {
    color: #4dd0e1 !important;
}
.bg-cyan.bg-lighten-2,
.btn-cyan.btn-lighten-2 {
    background-color: #4dd0e1 !important;
}
.btn-cyan.btn-lighten-2,
.btn-cyan.btn-lighten-2:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-lighten-2:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-2:active,
.btn-cyan.btn-lighten-2:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-lighten-2 {
    border-color: #4dd0e1 !important;
    color: #4dd0e1 !important;
}
.btn-outline-cyan.btn-outline-lighten-2:hover {
    background-color: #4dd0e1 !important;
}
.progress-cyan.progress-lighten-2 {
    background-color: #4dd0e1;
}
.progress-cyan.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4dd0e1;
}
.progress-cyan.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4dd0e1;
}
.progress-cyan.progress-lighten-2[value]::-ms-fill {
    background-color: #4dd0e1;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-lighten-2 .progress-bar {
        background-color: #4dd0e1;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4dd0e1 !important;
}
.border-cyan.border-lighten-2 {
    border: 1px solid #4dd0e1 !important;
}
.border-top-cyan.border-top-lighten-2 {
    border-top: 1px solid #4dd0e1 !important;
}
.border-bottom-cyan.border-bottom-lighten-2 {
    border-bottom: 1px solid #4dd0e1 !important;
}
[dir='ltr'] .border-left-cyan.border-left-lighten-2 {
    border-left: 1px solid #4dd0e1 !important;
}
[dir='ltr'] .border-right-cyan.border-right-lighten-2,
[dir='rtl'] .border-left-cyan.border-left-lighten-2 {
    border-right: 1px solid #4dd0e1 !important;
}
[dir='rtl'] .border-right-cyan.border-right-lighten-2 {
    border-left: 1px solid #4dd0e1 !important;
}
.overlay-cyan.overlay-lighten-2 {
    background: #4dd0e1;
    background: rgba(77, 208, 225, 0.8);
}
.cyan.lighten-1 {
    color: #26c6da !important;
}
.bg-cyan.bg-lighten-1,
.btn-cyan.btn-lighten-1 {
    background-color: #26c6da !important;
}
.btn-cyan.btn-lighten-1,
.btn-cyan.btn-lighten-1:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-lighten-1:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-lighten-1:active,
.btn-cyan.btn-lighten-1:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-lighten-1 {
    border-color: #26c6da !important;
    color: #26c6da !important;
}
.btn-outline-cyan.btn-outline-lighten-1:hover {
    background-color: #26c6da !important;
}
.progress-cyan.progress-lighten-1 {
    background-color: #26c6da;
}
.progress-cyan.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #26c6da;
}
.progress-cyan.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #26c6da;
}
.progress-cyan.progress-lighten-1[value]::-ms-fill {
    background-color: #26c6da;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-lighten-1 .progress-bar {
        background-color: #26c6da;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26c6da !important;
}
.border-cyan.border-lighten-1 {
    border: 1px solid #26c6da !important;
}
.border-top-cyan.border-top-lighten-1 {
    border-top: 1px solid #26c6da !important;
}
.border-bottom-cyan.border-bottom-lighten-1 {
    border-bottom: 1px solid #26c6da !important;
}
[dir='ltr'] .border-left-cyan.border-left-lighten-1 {
    border-left: 1px solid #26c6da !important;
}
[dir='ltr'] .border-right-cyan.border-right-lighten-1,
[dir='rtl'] .border-left-cyan.border-left-lighten-1 {
    border-right: 1px solid #26c6da !important;
}
[dir='rtl'] .border-right-cyan.border-right-lighten-1 {
    border-left: 1px solid #26c6da !important;
}
.overlay-cyan.overlay-lighten-1 {
    background: #26c6da;
    background: rgba(38, 198, 218, 0.8);
}
.cyan {
    color: #00bcd4 !important;
}
.bg-cyan {
    background-color: #00bcd4 !important;
}
.bg-cyan .card-footer,
.bg-cyan .card-header {
    background-color: initial;
}
.toast-cyan {
    background-color: #00bcd4;
}
.alert-cyan {
    border-color: #00bcd4 !important;
    background-color: #00dcf8 !important;
    color: #001e22 !important;
}
.alert-cyan .alert-link {
    color: #000 !important;
}
.border-cyan {
    border-color: #00bcd4;
}
.border-top-cyan {
    border-top-color: #00bcd4;
}
.border-bottom-cyan {
    border-bottom-color: #00bcd4;
}
[dir='ltr'] .border-left-cyan {
    border-left-color: #00bcd4;
}
[dir='ltr'] .border-right-cyan,
[dir='rtl'] .border-left-cyan {
    border-right-color: #00bcd4;
}
[dir='rtl'] .border-right-cyan {
    border-left-color: #00bcd4;
}
.badge-cyan {
    background-color: #00bcd4;
}
.panel-cyan {
    border-color: #00bcd4;
}
.panel-cyan .panel-heading {
    color: #fff;
    border-color: #00bcd4;
    background-color: #00d3ee;
}
.bg-cyan.tag-glow,
.border-cyan.tag-glow {
    box-shadow: 0 0 10px #00bcd4;
}
.overlay-cyan {
    background: #00bcd4;
    background: rgba(0, 188, 212, 0.8);
}
.card.card-outline-cyan {
    border: 1px solid #00bcd4;
}
.card.card-outline-cyan,
.card.card-outline-cyan .card-footer,
.card.card-outline-cyan .card-header {
    background-color: initial;
}
.btn-cyan.btn-flat {
    background-color: initial !important;
    color: #00bcd4;
    border: none;
}
.btn-cyan.btn-fab,
.btn-cyan.btn-raised {
    background-color: #00bcd4 !important;
    color: #fff !important;
    border-color: #00bcd4;
}
.btn-cyan.btn-fab.active,
.btn-cyan.btn-raised.active {
    background-color: #009cb0 !important;
    border-color: #009cb0 !important;
}
.btn-group-raised .btn-cyan {
    background-color: #00bcd4 !important;
    color: #fff !important;
}
.btn-outline-cyan {
    border: 1px solid #00bcd4;
    background-color: initial;
    color: #00bcd4;
    box-shadow: none !important;
}
.btn-outline-cyan:focus {
    background-color: initial !important;
    color: #00bcd4 !important;
    box-shadow: transparent !important;
}
.btn-outline-cyan.active {
    background-color: #00bcd4 !important;
    color: #fff !important;
}
.btn-outline-cyan:hover {
    background-color: #00aec5 !important;
    color: #fff !important;
}
.btn-outline-cyan:hover svg {
    color: #fff !important;
}
.btn-outline-cyan.btn-fab,
.btn-outline-cyan.btn-raised {
    border: 1px solid #00bcd4;
    background-color: initial;
    color: #00bcd4;
    box-shadow: none !important;
}
.btn-outline-cyan.btn-fab:focus,
.btn-outline-cyan.btn-raised:focus {
    background-color: initial;
}
.btn-outline-cyan.btn-fab.active,
.btn-outline-cyan.btn-raised.active {
    background-color: #00bcd4 !important;
    color: #fff !important;
}
.btn-outline-cyan.btn-fab:hover,
.btn-outline-cyan.btn-raised:hover {
    background-color: #00aec5 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-cyan {
    background-color: #00bcd4;
}
.progress .progress-bar.progress-bar-cyan[value]::-webkit-progress-value {
    background-color: #00bcd4;
}
.progress .progress-bar.progress-bar-cyan[value]::-moz-progress-bar {
    background-color: #00bcd4;
}
.progress .progress-bar.progress-bar-cyan[value]::-ms-fill {
    background-color: #00bcd4;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-cyan .progress-bar {
        background-color: #00bcd4;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00bcd4 !important;
}
.cyan.darken-1 {
    color: #00acc1 !important;
}
.bg-cyan.bg-darken-1,
.btn-cyan.btn-darken-1 {
    background-color: #00acc1 !important;
}
.btn-cyan.btn-darken-1,
.btn-cyan.btn-darken-1:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-darken-1:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-1:active,
.btn-cyan.btn-darken-1:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-darken-1 {
    border-color: #00acc1 !important;
    color: #00acc1 !important;
}
.btn-outline-cyan.btn-outline-darken-1:hover {
    background-color: #00acc1 !important;
}
.progress-cyan.progress-darken-1 {
    background-color: #00acc1;
}
.progress-cyan.progress-darken-1[value]::-webkit-progress-value {
    background-color: #00acc1;
}
.progress-cyan.progress-darken-1[value]::-moz-progress-bar {
    background-color: #00acc1;
}
.progress-cyan.progress-darken-1[value]::-ms-fill {
    background-color: #00acc1;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-darken-1 .progress-bar {
        background-color: #00acc1;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00acc1 !important;
}
.border-cyan.border-darken-1 {
    border: 1px solid #00acc1 !important;
}
.border-top-cyan.border-top-darken-1 {
    border-top: 1px solid #00acc1 !important;
}
.border-bottom-cyan.border-bottom-darken-1 {
    border-bottom: 1px solid #00acc1 !important;
}
[dir='ltr'] .border-left-cyan.border-left-darken-1 {
    border-left: 1px solid #00acc1 !important;
}
[dir='ltr'] .border-right-cyan.border-right-darken-1,
[dir='rtl'] .border-left-cyan.border-left-darken-1 {
    border-right: 1px solid #00acc1 !important;
}
[dir='rtl'] .border-right-cyan.border-right-darken-1 {
    border-left: 1px solid #00acc1 !important;
}
.overlay-cyan.overlay-darken-1 {
    background: #00acc1;
    background: rgba(0, 172, 193, 0.8);
}
.cyan.darken-2 {
    color: #0097a7 !important;
}
.bg-cyan.bg-darken-2,
.btn-cyan.btn-darken-2 {
    background-color: #0097a7 !important;
}
.btn-cyan.btn-darken-2,
.btn-cyan.btn-darken-2:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-darken-2:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-2:active,
.btn-cyan.btn-darken-2:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-darken-2 {
    border-color: #0097a7 !important;
    color: #0097a7 !important;
}
.btn-outline-cyan.btn-outline-darken-2:hover {
    background-color: #0097a7 !important;
}
.progress-cyan.progress-darken-2 {
    background-color: #0097a7;
}
.progress-cyan.progress-darken-2[value]::-webkit-progress-value {
    background-color: #0097a7;
}
.progress-cyan.progress-darken-2[value]::-moz-progress-bar {
    background-color: #0097a7;
}
.progress-cyan.progress-darken-2[value]::-ms-fill {
    background-color: #0097a7;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-darken-2 .progress-bar {
        background-color: #0097a7;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0097a7 !important;
}
.border-cyan.border-darken-2 {
    border: 1px solid #0097a7 !important;
}
.border-top-cyan.border-top-darken-2 {
    border-top: 1px solid #0097a7 !important;
}
.border-bottom-cyan.border-bottom-darken-2 {
    border-bottom: 1px solid #0097a7 !important;
}
[dir='ltr'] .border-left-cyan.border-left-darken-2 {
    border-left: 1px solid #0097a7 !important;
}
[dir='ltr'] .border-right-cyan.border-right-darken-2,
[dir='rtl'] .border-left-cyan.border-left-darken-2 {
    border-right: 1px solid #0097a7 !important;
}
[dir='rtl'] .border-right-cyan.border-right-darken-2 {
    border-left: 1px solid #0097a7 !important;
}
.overlay-cyan.overlay-darken-2 {
    background: #0097a7;
    background: rgba(0, 151, 167, 0.8);
}
.cyan.darken-3 {
    color: #00838f !important;
}
.bg-cyan.bg-darken-3 {
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-3,
.btn-cyan.btn-darken-3:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-3:active,
.btn-cyan.btn-darken-3:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-darken-3 {
    border-color: #00838f !important;
    color: #00838f !important;
}
.btn-outline-cyan.btn-outline-darken-3:hover {
    background-color: #00838f !important;
}
.progress-cyan.progress-darken-3 {
    background-color: #00838f;
}
.progress-cyan.progress-darken-3[value]::-webkit-progress-value {
    background-color: #00838f;
}
.progress-cyan.progress-darken-3[value]::-moz-progress-bar {
    background-color: #00838f;
}
.progress-cyan.progress-darken-3[value]::-ms-fill {
    background-color: #00838f;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-darken-3 .progress-bar {
        background-color: #00838f;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00838f !important;
}
.border-cyan.border-darken-3 {
    border: 1px solid #00838f !important;
}
.border-top-cyan.border-top-darken-3 {
    border-top: 1px solid #00838f !important;
}
.border-bottom-cyan.border-bottom-darken-3 {
    border-bottom: 1px solid #00838f !important;
}
[dir='ltr'] .border-left-cyan.border-left-darken-3 {
    border-left: 1px solid #00838f !important;
}
[dir='ltr'] .border-right-cyan.border-right-darken-3,
[dir='rtl'] .border-left-cyan.border-left-darken-3 {
    border-right: 1px solid #00838f !important;
}
[dir='rtl'] .border-right-cyan.border-right-darken-3 {
    border-left: 1px solid #00838f !important;
}
.overlay-cyan.overlay-darken-3 {
    background: #00838f;
    background: rgba(0, 131, 143, 0.8);
}
.cyan.darken-4 {
    color: #006064 !important;
}
.bg-cyan.bg-darken-4,
.btn-cyan.btn-darken-4 {
    background-color: #006064 !important;
}
.btn-cyan.btn-darken-4,
.btn-cyan.btn-darken-4:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-darken-4:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-darken-4:active,
.btn-cyan.btn-darken-4:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-darken-4 {
    border-color: #006064 !important;
    color: #006064 !important;
}
.btn-outline-cyan.btn-outline-darken-4:hover {
    background-color: #006064 !important;
}
.progress-cyan.progress-darken-4 {
    background-color: #006064;
}
.progress-cyan.progress-darken-4[value]::-webkit-progress-value {
    background-color: #006064;
}
.progress-cyan.progress-darken-4[value]::-moz-progress-bar {
    background-color: #006064;
}
.progress-cyan.progress-darken-4[value]::-ms-fill {
    background-color: #006064;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-darken-4 .progress-bar {
        background-color: #006064;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006064 !important;
}
.border-cyan.border-darken-4 {
    border: 1px solid #006064 !important;
}
.border-top-cyan.border-top-darken-4 {
    border-top: 1px solid #006064 !important;
}
.border-bottom-cyan.border-bottom-darken-4 {
    border-bottom: 1px solid #006064 !important;
}
[dir='ltr'] .border-left-cyan.border-left-darken-4 {
    border-left: 1px solid #006064 !important;
}
[dir='ltr'] .border-right-cyan.border-right-darken-4,
[dir='rtl'] .border-left-cyan.border-left-darken-4 {
    border-right: 1px solid #006064 !important;
}
[dir='rtl'] .border-right-cyan.border-right-darken-4 {
    border-left: 1px solid #006064 !important;
}
.overlay-cyan.overlay-darken-4 {
    background: #006064;
    background: rgba(0, 96, 100, 0.8);
}
.cyan.accent-1 {
    color: #84ffff !important;
}
.bg-cyan.bg-accent-1,
.btn-cyan.btn-accent-1 {
    background-color: #84ffff !important;
}
.btn-cyan.btn-accent-1,
.btn-cyan.btn-accent-1:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-accent-1:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-1:active,
.btn-cyan.btn-accent-1:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-accent-1 {
    border-color: #84ffff !important;
    color: #84ffff !important;
}
.btn-outline-cyan.btn-outline-accent-1:hover {
    background-color: #84ffff !important;
}
.progress-cyan.progress-accent-1 {
    background-color: #84ffff;
}
.progress-cyan.progress-accent-1[value]::-webkit-progress-value {
    background-color: #84ffff;
}
.progress-cyan.progress-accent-1[value]::-moz-progress-bar {
    background-color: #84ffff;
}
.progress-cyan.progress-accent-1[value]::-ms-fill {
    background-color: #84ffff;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-accent-1 .progress-bar {
        background-color: #84ffff;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #84ffff !important;
}
.border-cyan.border-accent-1 {
    border: 1px solid #84ffff !important;
}
.border-top-cyan.border-top-accent-1 {
    border-top: 1px solid #84ffff !important;
}
.border-bottom-cyan.border-bottom-accent-1 {
    border-bottom: 1px solid #84ffff !important;
}
[dir='ltr'] .border-left-cyan.border-left-accent-1 {
    border-left: 1px solid #84ffff !important;
}
[dir='ltr'] .border-right-cyan.border-right-accent-1,
[dir='rtl'] .border-left-cyan.border-left-accent-1 {
    border-right: 1px solid #84ffff !important;
}
[dir='rtl'] .border-right-cyan.border-right-accent-1 {
    border-left: 1px solid #84ffff !important;
}
.overlay-cyan.overlay-accent-1 {
    background: #84ffff;
    background: rgba(132, 255, 255, 0.8);
}
.cyan.accent-2 {
    color: #18ffff !important;
}
.bg-cyan.bg-accent-2,
.btn-cyan.btn-accent-2 {
    background-color: #18ffff !important;
}
.btn-cyan.btn-accent-2,
.btn-cyan.btn-accent-2:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-accent-2:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-2:active,
.btn-cyan.btn-accent-2:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-accent-2 {
    border-color: #18ffff !important;
    color: #18ffff !important;
}
.btn-outline-cyan.btn-outline-accent-2:hover {
    background-color: #18ffff !important;
}
.progress-cyan.progress-accent-2 {
    background-color: #18ffff;
}
.progress-cyan.progress-accent-2[value]::-webkit-progress-value {
    background-color: #18ffff;
}
.progress-cyan.progress-accent-2[value]::-moz-progress-bar {
    background-color: #18ffff;
}
.progress-cyan.progress-accent-2[value]::-ms-fill {
    background-color: #18ffff;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-accent-2 .progress-bar {
        background-color: #18ffff;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #18ffff !important;
}
.border-cyan.border-accent-2 {
    border: 1px solid #18ffff !important;
}
.border-top-cyan.border-top-accent-2 {
    border-top: 1px solid #18ffff !important;
}
.border-bottom-cyan.border-bottom-accent-2 {
    border-bottom: 1px solid #18ffff !important;
}
[dir='ltr'] .border-left-cyan.border-left-accent-2 {
    border-left: 1px solid #18ffff !important;
}
[dir='ltr'] .border-right-cyan.border-right-accent-2,
[dir='rtl'] .border-left-cyan.border-left-accent-2 {
    border-right: 1px solid #18ffff !important;
}
[dir='rtl'] .border-right-cyan.border-right-accent-2 {
    border-left: 1px solid #18ffff !important;
}
.overlay-cyan.overlay-accent-2 {
    background: #18ffff;
    background: rgba(24, 255, 255, 0.8);
}
.cyan.accent-3 {
    color: #00e5ff !important;
}
.bg-cyan.bg-accent-3,
.btn-cyan.btn-accent-3 {
    background-color: #00e5ff !important;
}
.btn-cyan.btn-accent-3,
.btn-cyan.btn-accent-3:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-accent-3:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-3:active,
.btn-cyan.btn-accent-3:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-accent-3 {
    border-color: #00e5ff !important;
    color: #00e5ff !important;
}
.btn-outline-cyan.btn-outline-accent-3:hover {
    background-color: #00e5ff !important;
}
.progress-cyan.progress-accent-3 {
    background-color: #00e5ff;
}
.progress-cyan.progress-accent-3[value]::-webkit-progress-value {
    background-color: #00e5ff;
}
.progress-cyan.progress-accent-3[value]::-moz-progress-bar {
    background-color: #00e5ff;
}
.progress-cyan.progress-accent-3[value]::-ms-fill {
    background-color: #00e5ff;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-accent-3 .progress-bar {
        background-color: #00e5ff;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00e5ff !important;
}
.border-cyan.border-accent-3 {
    border: 1px solid #00e5ff !important;
}
.border-top-cyan.border-top-accent-3 {
    border-top: 1px solid #00e5ff !important;
}
.border-bottom-cyan.border-bottom-accent-3 {
    border-bottom: 1px solid #00e5ff !important;
}
[dir='ltr'] .border-left-cyan.border-left-accent-3 {
    border-left: 1px solid #00e5ff !important;
}
[dir='ltr'] .border-right-cyan.border-right-accent-3,
[dir='rtl'] .border-left-cyan.border-left-accent-3 {
    border-right: 1px solid #00e5ff !important;
}
[dir='rtl'] .border-right-cyan.border-right-accent-3 {
    border-left: 1px solid #00e5ff !important;
}
.overlay-cyan.overlay-accent-3 {
    background: #00e5ff;
    background: rgba(0, 229, 255, 0.8);
}
.cyan.accent-4 {
    color: #00b8d4 !important;
}
.bg-cyan.bg-accent-4,
.btn-cyan.btn-accent-4 {
    background-color: #00b8d4 !important;
}
.btn-cyan.btn-accent-4,
.btn-cyan.btn-accent-4:hover {
    border-color: #0097a7 !important;
}
.btn-cyan.btn-accent-4:hover {
    background-color: #00838f !important;
}
.btn-cyan.btn-accent-4:active,
.btn-cyan.btn-accent-4:focus {
    border-color: #00838f !important;
    background-color: #006064 !important;
}
.btn-outline-cyan.btn-outline-accent-4 {
    border-color: #00b8d4 !important;
    color: #00b8d4 !important;
}
.btn-outline-cyan.btn-outline-accent-4:hover {
    background-color: #00b8d4 !important;
}
.progress-cyan.progress-accent-4 {
    background-color: #00b8d4;
}
.progress-cyan.progress-accent-4[value]::-webkit-progress-value {
    background-color: #00b8d4;
}
.progress-cyan.progress-accent-4[value]::-moz-progress-bar {
    background-color: #00b8d4;
}
.progress-cyan.progress-accent-4[value]::-ms-fill {
    background-color: #00b8d4;
}
@media screen and (min-width: 0\0) {
    .progress-cyan.progress-accent-4 .progress-bar {
        background-color: #00b8d4;
    }
}
input:focus ~ .bg-cyan {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00b8d4 !important;
}
.border-cyan.border-accent-4 {
    border: 1px solid #00b8d4 !important;
}
.border-top-cyan.border-top-accent-4 {
    border-top: 1px solid #00b8d4 !important;
}
.border-bottom-cyan.border-bottom-accent-4 {
    border-bottom: 1px solid #00b8d4 !important;
}
[dir='ltr'] .border-left-cyan.border-left-accent-4 {
    border-left: 1px solid #00b8d4 !important;
}
[dir='ltr'] .border-right-cyan.border-right-accent-4,
[dir='rtl'] .border-left-cyan.border-left-accent-4 {
    border-right: 1px solid #00b8d4 !important;
}
[dir='rtl'] .border-right-cyan.border-right-accent-4 {
    border-left: 1px solid #00b8d4 !important;
}
.overlay-cyan.overlay-accent-4 {
    background: #00b8d4;
    background: rgba(0, 184, 212, 0.8);
}
.teal.lighten-5 {
    color: #e0f2f1 !important;
}
.bg-teal.bg-lighten-5,
.btn-teal.btn-lighten-5 {
    background-color: #e0f2f1 !important;
}
.btn-teal.btn-lighten-5,
.btn-teal.btn-lighten-5:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-lighten-5:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-5:active,
.btn-teal.btn-lighten-5:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-lighten-5 {
    border-color: #e0f2f1 !important;
    color: #e0f2f1 !important;
}
.btn-outline-teal.btn-outline-lighten-5:hover {
    background-color: #e0f2f1 !important;
}
.progress-teal.progress-lighten-5 {
    background-color: #e0f2f1;
}
.progress-teal.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e0f2f1;
}
.progress-teal.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e0f2f1;
}
.progress-teal.progress-lighten-5[value]::-ms-fill {
    background-color: #e0f2f1;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-lighten-5 .progress-bar {
        background-color: #e0f2f1;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0f2f1 !important;
}
.border-teal.border-lighten-5 {
    border: 1px solid #e0f2f1 !important;
}
.border-top-teal.border-top-lighten-5 {
    border-top: 1px solid #e0f2f1 !important;
}
.border-bottom-teal.border-bottom-lighten-5 {
    border-bottom: 1px solid #e0f2f1 !important;
}
[dir='ltr'] .border-left-teal.border-left-lighten-5 {
    border-left: 1px solid #e0f2f1 !important;
}
[dir='ltr'] .border-right-teal.border-right-lighten-5,
[dir='rtl'] .border-left-teal.border-left-lighten-5 {
    border-right: 1px solid #e0f2f1 !important;
}
[dir='rtl'] .border-right-teal.border-right-lighten-5 {
    border-left: 1px solid #e0f2f1 !important;
}
.overlay-teal.overlay-lighten-5 {
    background: #e0f2f1;
    background: rgba(224, 242, 241, 0.8);
}
.teal.lighten-4 {
    color: #b2dfdb !important;
}
.bg-teal.bg-lighten-4,
.btn-teal.btn-lighten-4 {
    background-color: #b2dfdb !important;
}
.btn-teal.btn-lighten-4,
.btn-teal.btn-lighten-4:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-lighten-4:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-4:active,
.btn-teal.btn-lighten-4:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-lighten-4 {
    border-color: #b2dfdb !important;
    color: #b2dfdb !important;
}
.btn-outline-teal.btn-outline-lighten-4:hover {
    background-color: #b2dfdb !important;
}
.progress-teal.progress-lighten-4 {
    background-color: #b2dfdb;
}
.progress-teal.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b2dfdb;
}
.progress-teal.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b2dfdb;
}
.progress-teal.progress-lighten-4[value]::-ms-fill {
    background-color: #b2dfdb;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-lighten-4 .progress-bar {
        background-color: #b2dfdb;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b2dfdb !important;
}
.border-teal.border-lighten-4 {
    border: 1px solid #b2dfdb !important;
}
.border-top-teal.border-top-lighten-4 {
    border-top: 1px solid #b2dfdb !important;
}
.border-bottom-teal.border-bottom-lighten-4 {
    border-bottom: 1px solid #b2dfdb !important;
}
[dir='ltr'] .border-left-teal.border-left-lighten-4 {
    border-left: 1px solid #b2dfdb !important;
}
[dir='ltr'] .border-right-teal.border-right-lighten-4,
[dir='rtl'] .border-left-teal.border-left-lighten-4 {
    border-right: 1px solid #b2dfdb !important;
}
[dir='rtl'] .border-right-teal.border-right-lighten-4 {
    border-left: 1px solid #b2dfdb !important;
}
.overlay-teal.overlay-lighten-4 {
    background: #b2dfdb;
    background: rgba(178, 223, 219, 0.8);
}
.teal.lighten-3 {
    color: #80cbc4 !important;
}
.bg-teal.bg-lighten-3,
.btn-teal.btn-lighten-3 {
    background-color: #80cbc4 !important;
}
.btn-teal.btn-lighten-3,
.btn-teal.btn-lighten-3:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-lighten-3:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-3:active,
.btn-teal.btn-lighten-3:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-lighten-3 {
    border-color: #80cbc4 !important;
    color: #80cbc4 !important;
}
.btn-outline-teal.btn-outline-lighten-3:hover {
    background-color: #80cbc4 !important;
}
.progress-teal.progress-lighten-3 {
    background-color: #80cbc4;
}
.progress-teal.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #80cbc4;
}
.progress-teal.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #80cbc4;
}
.progress-teal.progress-lighten-3[value]::-ms-fill {
    background-color: #80cbc4;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-lighten-3 .progress-bar {
        background-color: #80cbc4;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80cbc4 !important;
}
.border-teal.border-lighten-3 {
    border: 1px solid #80cbc4 !important;
}
.border-top-teal.border-top-lighten-3 {
    border-top: 1px solid #80cbc4 !important;
}
.border-bottom-teal.border-bottom-lighten-3 {
    border-bottom: 1px solid #80cbc4 !important;
}
[dir='ltr'] .border-left-teal.border-left-lighten-3 {
    border-left: 1px solid #80cbc4 !important;
}
[dir='ltr'] .border-right-teal.border-right-lighten-3,
[dir='rtl'] .border-left-teal.border-left-lighten-3 {
    border-right: 1px solid #80cbc4 !important;
}
[dir='rtl'] .border-right-teal.border-right-lighten-3 {
    border-left: 1px solid #80cbc4 !important;
}
.overlay-teal.overlay-lighten-3 {
    background: #80cbc4;
    background: rgba(128, 203, 196, 0.8);
}
.teal.lighten-2 {
    color: #4db6ac !important;
}
.bg-teal.bg-lighten-2,
.btn-teal.btn-lighten-2 {
    background-color: #4db6ac !important;
}
.btn-teal.btn-lighten-2,
.btn-teal.btn-lighten-2:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-lighten-2:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-2:active,
.btn-teal.btn-lighten-2:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-lighten-2 {
    border-color: #4db6ac !important;
    color: #4db6ac !important;
}
.btn-outline-teal.btn-outline-lighten-2:hover {
    background-color: #4db6ac !important;
}
.progress-teal.progress-lighten-2 {
    background-color: #4db6ac;
}
.progress-teal.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4db6ac;
}
.progress-teal.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4db6ac;
}
.progress-teal.progress-lighten-2[value]::-ms-fill {
    background-color: #4db6ac;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-lighten-2 .progress-bar {
        background-color: #4db6ac;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4db6ac !important;
}
.border-teal.border-lighten-2 {
    border: 1px solid #4db6ac !important;
}
.border-top-teal.border-top-lighten-2 {
    border-top: 1px solid #4db6ac !important;
}
.border-bottom-teal.border-bottom-lighten-2 {
    border-bottom: 1px solid #4db6ac !important;
}
[dir='ltr'] .border-left-teal.border-left-lighten-2 {
    border-left: 1px solid #4db6ac !important;
}
[dir='ltr'] .border-right-teal.border-right-lighten-2,
[dir='rtl'] .border-left-teal.border-left-lighten-2 {
    border-right: 1px solid #4db6ac !important;
}
[dir='rtl'] .border-right-teal.border-right-lighten-2 {
    border-left: 1px solid #4db6ac !important;
}
.overlay-teal.overlay-lighten-2 {
    background: #4db6ac;
    background: rgba(77, 182, 172, 0.8);
}
.teal.lighten-1 {
    color: #26a69a !important;
}
.bg-teal.bg-lighten-1,
.btn-teal.btn-lighten-1 {
    background-color: #26a69a !important;
}
.btn-teal.btn-lighten-1,
.btn-teal.btn-lighten-1:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-lighten-1:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-lighten-1:active,
.btn-teal.btn-lighten-1:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-lighten-1 {
    border-color: #26a69a !important;
    color: #26a69a !important;
}
.btn-outline-teal.btn-outline-lighten-1:hover {
    background-color: #26a69a !important;
}
.progress-teal.progress-lighten-1 {
    background-color: #26a69a;
}
.progress-teal.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #26a69a;
}
.progress-teal.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #26a69a;
}
.progress-teal.progress-lighten-1[value]::-ms-fill {
    background-color: #26a69a;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-lighten-1 .progress-bar {
        background-color: #26a69a;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26a69a !important;
}
.border-teal.border-lighten-1 {
    border: 1px solid #26a69a !important;
}
.border-top-teal.border-top-lighten-1 {
    border-top: 1px solid #26a69a !important;
}
.border-bottom-teal.border-bottom-lighten-1 {
    border-bottom: 1px solid #26a69a !important;
}
[dir='ltr'] .border-left-teal.border-left-lighten-1 {
    border-left: 1px solid #26a69a !important;
}
[dir='ltr'] .border-right-teal.border-right-lighten-1,
[dir='rtl'] .border-left-teal.border-left-lighten-1 {
    border-right: 1px solid #26a69a !important;
}
[dir='rtl'] .border-right-teal.border-right-lighten-1 {
    border-left: 1px solid #26a69a !important;
}
.overlay-teal.overlay-lighten-1 {
    background: #26a69a;
    background: rgba(38, 166, 154, 0.8);
}
.teal {
    color: #009688 !important;
}
.bg-teal {
    background-color: #009688 !important;
}
.bg-teal .card-footer,
.bg-teal .card-header {
    background-color: initial;
}
.toast-teal {
    background-color: #009688;
}
.alert-teal {
    border-color: #009688 !important;
    background-color: #00baa8 !important;
}
.alert-teal,
.alert-teal .alert-link {
    color: #000 !important;
}
.border-teal {
    border-color: #009688;
}
.border-top-teal {
    border-top-color: #009688;
}
.border-bottom-teal {
    border-bottom-color: #009688;
}
[dir='ltr'] .border-left-teal {
    border-left-color: #009688;
}
[dir='ltr'] .border-right-teal,
[dir='rtl'] .border-left-teal {
    border-right-color: #009688;
}
[dir='rtl'] .border-right-teal {
    border-left-color: #009688;
}
.badge-teal {
    background-color: #009688;
}
.panel-teal {
    border-color: #009688;
}
.panel-teal .panel-heading {
    color: #fff;
    border-color: #009688;
    background-color: #00b09f;
}
.bg-teal.tag-glow,
.border-teal.tag-glow {
    box-shadow: 0 0 10px #009688;
}
.overlay-teal {
    background: #009688;
    background: rgba(0, 150, 136, 0.8);
}
.card.card-outline-teal {
    border: 1px solid #009688;
}
.card.card-outline-teal,
.card.card-outline-teal .card-footer,
.card.card-outline-teal .card-header {
    background-color: initial;
}
.btn-teal.btn-flat {
    background-color: initial !important;
    color: #009688;
    border: none;
}
.btn-teal.btn-fab,
.btn-teal.btn-raised {
    background-color: #009688 !important;
    color: #fff !important;
    border-color: #009688;
}
.btn-teal.btn-fab.active,
.btn-teal.btn-raised.active {
    background-color: #007268 !important;
    border-color: #007268 !important;
}
.btn-group-raised .btn-teal {
    background-color: #009688 !important;
    color: #fff !important;
}
.btn-outline-teal {
    border: 1px solid #009688;
    background-color: initial;
    color: #009688;
    box-shadow: none !important;
}
.btn-outline-teal:focus {
    background-color: initial !important;
    color: #009688 !important;
    box-shadow: transparent !important;
}
.btn-outline-teal.active {
    background-color: #009688 !important;
    color: #fff !important;
}
.btn-outline-teal:hover {
    background-color: #00877a !important;
    color: #fff !important;
}
.btn-outline-teal:hover svg {
    color: #fff !important;
}
.btn-outline-teal.btn-fab,
.btn-outline-teal.btn-raised {
    border: 1px solid #009688;
    background-color: initial;
    color: #009688;
    box-shadow: none !important;
}
.btn-outline-teal.btn-fab:focus,
.btn-outline-teal.btn-raised:focus {
    background-color: initial;
}
.btn-outline-teal.btn-fab.active,
.btn-outline-teal.btn-raised.active {
    background-color: #009688 !important;
    color: #fff !important;
}
.btn-outline-teal.btn-fab:hover,
.btn-outline-teal.btn-raised:hover {
    background-color: #00877a !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-teal {
    background-color: #009688;
}
.progress .progress-bar.progress-bar-teal[value]::-webkit-progress-value {
    background-color: #009688;
}
.progress .progress-bar.progress-bar-teal[value]::-moz-progress-bar {
    background-color: #009688;
}
.progress .progress-bar.progress-bar-teal[value]::-ms-fill {
    background-color: #009688;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-teal .progress-bar {
        background-color: #009688;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009688 !important;
}
.teal.darken-1 {
    color: #00897b !important;
}
.bg-teal.bg-darken-1,
.btn-teal.btn-darken-1 {
    background-color: #00897b !important;
}
.btn-teal.btn-darken-1,
.btn-teal.btn-darken-1:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-darken-1:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-darken-1:active,
.btn-teal.btn-darken-1:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-darken-1 {
    border-color: #00897b !important;
    color: #00897b !important;
}
.btn-outline-teal.btn-outline-darken-1:hover {
    background-color: #00897b !important;
}
.progress-teal.progress-darken-1 {
    background-color: #00897b;
}
.progress-teal.progress-darken-1[value]::-webkit-progress-value {
    background-color: #00897b;
}
.progress-teal.progress-darken-1[value]::-moz-progress-bar {
    background-color: #00897b;
}
.progress-teal.progress-darken-1[value]::-ms-fill {
    background-color: #00897b;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-darken-1 .progress-bar {
        background-color: #00897b;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00897b !important;
}
.border-teal.border-darken-1 {
    border: 1px solid #00897b !important;
}
.border-top-teal.border-top-darken-1 {
    border-top: 1px solid #00897b !important;
}
.border-bottom-teal.border-bottom-darken-1 {
    border-bottom: 1px solid #00897b !important;
}
[dir='ltr'] .border-left-teal.border-left-darken-1 {
    border-left: 1px solid #00897b !important;
}
[dir='ltr'] .border-right-teal.border-right-darken-1,
[dir='rtl'] .border-left-teal.border-left-darken-1 {
    border-right: 1px solid #00897b !important;
}
[dir='rtl'] .border-right-teal.border-right-darken-1 {
    border-left: 1px solid #00897b !important;
}
.overlay-teal.overlay-darken-1 {
    background: #00897b;
    background: rgba(0, 137, 123, 0.8);
}
.teal.darken-2 {
    color: #00796b !important;
}
.bg-teal.bg-darken-2,
.btn-teal.btn-darken-2 {
    background-color: #00796b !important;
}
.btn-teal.btn-darken-2,
.btn-teal.btn-darken-2:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-darken-2:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-darken-2:active,
.btn-teal.btn-darken-2:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-darken-2 {
    border-color: #00796b !important;
    color: #00796b !important;
}
.btn-outline-teal.btn-outline-darken-2:hover {
    background-color: #00796b !important;
}
.progress-teal.progress-darken-2 {
    background-color: #00796b;
}
.progress-teal.progress-darken-2[value]::-webkit-progress-value {
    background-color: #00796b;
}
.progress-teal.progress-darken-2[value]::-moz-progress-bar {
    background-color: #00796b;
}
.progress-teal.progress-darken-2[value]::-ms-fill {
    background-color: #00796b;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-darken-2 .progress-bar {
        background-color: #00796b;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00796b !important;
}
.border-teal.border-darken-2 {
    border: 1px solid #00796b !important;
}
.border-top-teal.border-top-darken-2 {
    border-top: 1px solid #00796b !important;
}
.border-bottom-teal.border-bottom-darken-2 {
    border-bottom: 1px solid #00796b !important;
}
[dir='ltr'] .border-left-teal.border-left-darken-2 {
    border-left: 1px solid #00796b !important;
}
[dir='ltr'] .border-right-teal.border-right-darken-2,
[dir='rtl'] .border-left-teal.border-left-darken-2 {
    border-right: 1px solid #00796b !important;
}
[dir='rtl'] .border-right-teal.border-right-darken-2 {
    border-left: 1px solid #00796b !important;
}
.overlay-teal.overlay-darken-2 {
    background: #00796b;
    background: rgba(0, 121, 107, 0.8);
}
.teal.darken-3 {
    color: #00695c !important;
}
.bg-teal.bg-darken-3 {
    background-color: #00695c !important;
}
.btn-teal.btn-darken-3,
.btn-teal.btn-darken-3:hover {
    border-color: #00796b !important;
    background-color: #00695c !important;
}
.btn-teal.btn-darken-3:active,
.btn-teal.btn-darken-3:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-darken-3 {
    border-color: #00695c !important;
    color: #00695c !important;
}
.btn-outline-teal.btn-outline-darken-3:hover {
    background-color: #00695c !important;
}
.progress-teal.progress-darken-3 {
    background-color: #00695c;
}
.progress-teal.progress-darken-3[value]::-webkit-progress-value {
    background-color: #00695c;
}
.progress-teal.progress-darken-3[value]::-moz-progress-bar {
    background-color: #00695c;
}
.progress-teal.progress-darken-3[value]::-ms-fill {
    background-color: #00695c;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-darken-3 .progress-bar {
        background-color: #00695c;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00695c !important;
}
.border-teal.border-darken-3 {
    border: 1px solid #00695c !important;
}
.border-top-teal.border-top-darken-3 {
    border-top: 1px solid #00695c !important;
}
.border-bottom-teal.border-bottom-darken-3 {
    border-bottom: 1px solid #00695c !important;
}
[dir='ltr'] .border-left-teal.border-left-darken-3 {
    border-left: 1px solid #00695c !important;
}
[dir='ltr'] .border-right-teal.border-right-darken-3,
[dir='rtl'] .border-left-teal.border-left-darken-3 {
    border-right: 1px solid #00695c !important;
}
[dir='rtl'] .border-right-teal.border-right-darken-3 {
    border-left: 1px solid #00695c !important;
}
.overlay-teal.overlay-darken-3 {
    background: #00695c;
    background: rgba(0, 105, 92, 0.8);
}
.teal.darken-4 {
    color: #004d40 !important;
}
.bg-teal.bg-darken-4,
.btn-teal.btn-darken-4 {
    background-color: #004d40 !important;
}
.btn-teal.btn-darken-4,
.btn-teal.btn-darken-4:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-darken-4:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-darken-4:active,
.btn-teal.btn-darken-4:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-darken-4 {
    border-color: #004d40 !important;
    color: #004d40 !important;
}
.btn-outline-teal.btn-outline-darken-4:hover {
    background-color: #004d40 !important;
}
.progress-teal.progress-darken-4 {
    background-color: #004d40;
}
.progress-teal.progress-darken-4[value]::-webkit-progress-value {
    background-color: #004d40;
}
.progress-teal.progress-darken-4[value]::-moz-progress-bar {
    background-color: #004d40;
}
.progress-teal.progress-darken-4[value]::-ms-fill {
    background-color: #004d40;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-darken-4 .progress-bar {
        background-color: #004d40;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #004d40 !important;
}
.border-teal.border-darken-4 {
    border: 1px solid #004d40 !important;
}
.border-top-teal.border-top-darken-4 {
    border-top: 1px solid #004d40 !important;
}
.border-bottom-teal.border-bottom-darken-4 {
    border-bottom: 1px solid #004d40 !important;
}
[dir='ltr'] .border-left-teal.border-left-darken-4 {
    border-left: 1px solid #004d40 !important;
}
[dir='ltr'] .border-right-teal.border-right-darken-4,
[dir='rtl'] .border-left-teal.border-left-darken-4 {
    border-right: 1px solid #004d40 !important;
}
[dir='rtl'] .border-right-teal.border-right-darken-4 {
    border-left: 1px solid #004d40 !important;
}
.overlay-teal.overlay-darken-4 {
    background: #004d40;
    background: rgba(0, 77, 64, 0.8);
}
.teal.accent-1 {
    color: #a7ffeb !important;
}
.bg-teal.bg-accent-1,
.btn-teal.btn-accent-1 {
    background-color: #a7ffeb !important;
}
.btn-teal.btn-accent-1,
.btn-teal.btn-accent-1:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-accent-1:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-accent-1:active,
.btn-teal.btn-accent-1:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-accent-1 {
    border-color: #a7ffeb !important;
    color: #a7ffeb !important;
}
.btn-outline-teal.btn-outline-accent-1:hover {
    background-color: #a7ffeb !important;
}
.progress-teal.progress-accent-1 {
    background-color: #a7ffeb;
}
.progress-teal.progress-accent-1[value]::-webkit-progress-value {
    background-color: #a7ffeb;
}
.progress-teal.progress-accent-1[value]::-moz-progress-bar {
    background-color: #a7ffeb;
}
.progress-teal.progress-accent-1[value]::-ms-fill {
    background-color: #a7ffeb;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-accent-1 .progress-bar {
        background-color: #a7ffeb;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a7ffeb !important;
}
.border-teal.border-accent-1 {
    border: 1px solid #a7ffeb !important;
}
.border-top-teal.border-top-accent-1 {
    border-top: 1px solid #a7ffeb !important;
}
.border-bottom-teal.border-bottom-accent-1 {
    border-bottom: 1px solid #a7ffeb !important;
}
[dir='ltr'] .border-left-teal.border-left-accent-1 {
    border-left: 1px solid #a7ffeb !important;
}
[dir='ltr'] .border-right-teal.border-right-accent-1,
[dir='rtl'] .border-left-teal.border-left-accent-1 {
    border-right: 1px solid #a7ffeb !important;
}
[dir='rtl'] .border-right-teal.border-right-accent-1 {
    border-left: 1px solid #a7ffeb !important;
}
.overlay-teal.overlay-accent-1 {
    background: #a7ffeb;
    background: rgba(167, 255, 235, 0.8);
}
.teal.accent-2 {
    color: #64ffda !important;
}
.bg-teal.bg-accent-2,
.btn-teal.btn-accent-2 {
    background-color: #64ffda !important;
}
.btn-teal.btn-accent-2,
.btn-teal.btn-accent-2:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-accent-2:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-accent-2:active,
.btn-teal.btn-accent-2:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-accent-2 {
    border-color: #64ffda !important;
    color: #64ffda !important;
}
.btn-outline-teal.btn-outline-accent-2:hover {
    background-color: #64ffda !important;
}
.progress-teal.progress-accent-2 {
    background-color: #64ffda;
}
.progress-teal.progress-accent-2[value]::-webkit-progress-value {
    background-color: #64ffda;
}
.progress-teal.progress-accent-2[value]::-moz-progress-bar {
    background-color: #64ffda;
}
.progress-teal.progress-accent-2[value]::-ms-fill {
    background-color: #64ffda;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-accent-2 .progress-bar {
        background-color: #64ffda;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64ffda !important;
}
.border-teal.border-accent-2 {
    border: 1px solid #64ffda !important;
}
.border-top-teal.border-top-accent-2 {
    border-top: 1px solid #64ffda !important;
}
.border-bottom-teal.border-bottom-accent-2 {
    border-bottom: 1px solid #64ffda !important;
}
[dir='ltr'] .border-left-teal.border-left-accent-2 {
    border-left: 1px solid #64ffda !important;
}
[dir='ltr'] .border-right-teal.border-right-accent-2,
[dir='rtl'] .border-left-teal.border-left-accent-2 {
    border-right: 1px solid #64ffda !important;
}
[dir='rtl'] .border-right-teal.border-right-accent-2 {
    border-left: 1px solid #64ffda !important;
}
.overlay-teal.overlay-accent-2 {
    background: #64ffda;
    background: rgba(100, 255, 218, 0.8);
}
.teal.accent-3 {
    color: #1de9b6 !important;
}
.bg-teal.bg-accent-3,
.btn-teal.btn-accent-3 {
    background-color: #1de9b6 !important;
}
.btn-teal.btn-accent-3,
.btn-teal.btn-accent-3:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-accent-3:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-accent-3:active,
.btn-teal.btn-accent-3:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-accent-3 {
    border-color: #1de9b6 !important;
    color: #1de9b6 !important;
}
.btn-outline-teal.btn-outline-accent-3:hover {
    background-color: #1de9b6 !important;
}
.progress-teal.progress-accent-3 {
    background-color: #1de9b6;
}
.progress-teal.progress-accent-3[value]::-webkit-progress-value {
    background-color: #1de9b6;
}
.progress-teal.progress-accent-3[value]::-moz-progress-bar {
    background-color: #1de9b6;
}
.progress-teal.progress-accent-3[value]::-ms-fill {
    background-color: #1de9b6;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-accent-3 .progress-bar {
        background-color: #1de9b6;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1de9b6 !important;
}
.border-teal.border-accent-3 {
    border: 1px solid #1de9b6 !important;
}
.border-top-teal.border-top-accent-3 {
    border-top: 1px solid #1de9b6 !important;
}
.border-bottom-teal.border-bottom-accent-3 {
    border-bottom: 1px solid #1de9b6 !important;
}
[dir='ltr'] .border-left-teal.border-left-accent-3 {
    border-left: 1px solid #1de9b6 !important;
}
[dir='ltr'] .border-right-teal.border-right-accent-3,
[dir='rtl'] .border-left-teal.border-left-accent-3 {
    border-right: 1px solid #1de9b6 !important;
}
[dir='rtl'] .border-right-teal.border-right-accent-3 {
    border-left: 1px solid #1de9b6 !important;
}
.overlay-teal.overlay-accent-3 {
    background: #1de9b6;
    background: rgba(29, 233, 182, 0.8);
}
.teal.accent-4 {
    color: #00bfa5 !important;
}
.bg-teal.bg-accent-4,
.btn-teal.btn-accent-4 {
    background-color: #00bfa5 !important;
}
.btn-teal.btn-accent-4,
.btn-teal.btn-accent-4:hover {
    border-color: #00796b !important;
}
.btn-teal.btn-accent-4:hover {
    background-color: #00695c !important;
}
.btn-teal.btn-accent-4:active,
.btn-teal.btn-accent-4:focus {
    border-color: #00695c !important;
    background-color: #004d40 !important;
}
.btn-outline-teal.btn-outline-accent-4 {
    border-color: #00bfa5 !important;
    color: #00bfa5 !important;
}
.btn-outline-teal.btn-outline-accent-4:hover {
    background-color: #00bfa5 !important;
}
.progress-teal.progress-accent-4 {
    background-color: #00bfa5;
}
.progress-teal.progress-accent-4[value]::-webkit-progress-value {
    background-color: #00bfa5;
}
.progress-teal.progress-accent-4[value]::-moz-progress-bar {
    background-color: #00bfa5;
}
.progress-teal.progress-accent-4[value]::-ms-fill {
    background-color: #00bfa5;
}
@media screen and (min-width: 0\0) {
    .progress-teal.progress-accent-4 .progress-bar {
        background-color: #00bfa5;
    }
}
input:focus ~ .bg-teal {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00bfa5 !important;
}
.border-teal.border-accent-4 {
    border: 1px solid #00bfa5 !important;
}
.border-top-teal.border-top-accent-4 {
    border-top: 1px solid #00bfa5 !important;
}
.border-bottom-teal.border-bottom-accent-4 {
    border-bottom: 1px solid #00bfa5 !important;
}
[dir='ltr'] .border-left-teal.border-left-accent-4 {
    border-left: 1px solid #00bfa5 !important;
}
[dir='ltr'] .border-right-teal.border-right-accent-4,
[dir='rtl'] .border-left-teal.border-left-accent-4 {
    border-right: 1px solid #00bfa5 !important;
}
[dir='rtl'] .border-right-teal.border-right-accent-4 {
    border-left: 1px solid #00bfa5 !important;
}
.overlay-teal.overlay-accent-4 {
    background: #00bfa5;
    background: rgba(0, 191, 165, 0.8);
}
.green.lighten-5 {
    color: #e8f5e9 !important;
}
.bg-green.bg-lighten-5,
.btn-green.btn-lighten-5 {
    background-color: #e8f5e9 !important;
}
.btn-green.btn-lighten-5 {
    border-color: #388e3c !important;
}
.btn-green.btn-lighten-5:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-5:active,
.btn-green.btn-lighten-5:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-lighten-5 {
    border-color: #e8f5e9 !important;
    color: #e8f5e9 !important;
}
.btn-outline-green.btn-outline-lighten-5:hover {
    background-color: #e8f5e9 !important;
}
.progress-green.progress-lighten-5 {
    background-color: #e8f5e9;
}
.progress-green.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e8f5e9;
}
.progress-green.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e8f5e9;
}
.progress-green.progress-lighten-5[value]::-ms-fill {
    background-color: #e8f5e9;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-lighten-5 .progress-bar {
        background-color: #e8f5e9;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e8f5e9 !important;
}
.border-green.border-lighten-5 {
    border: 1px solid #e8f5e9 !important;
}
.border-top-green.border-top-lighten-5 {
    border-top: 1px solid #e8f5e9 !important;
}
.border-bottom-green.border-bottom-lighten-5 {
    border-bottom: 1px solid #e8f5e9 !important;
}
[dir='ltr'] .border-left-green.border-left-lighten-5 {
    border-left: 1px solid #e8f5e9 !important;
}
[dir='ltr'] .border-right-green.border-right-lighten-5,
[dir='rtl'] .border-left-green.border-left-lighten-5 {
    border-right: 1px solid #e8f5e9 !important;
}
[dir='rtl'] .border-right-green.border-right-lighten-5 {
    border-left: 1px solid #e8f5e9 !important;
}
.overlay-green.overlay-lighten-5 {
    background: #e8f5e9;
    background: rgba(232, 245, 233, 0.8);
}
.green.lighten-4 {
    color: #c8e6c9 !important;
}
.bg-green.bg-lighten-4,
.btn-green.btn-lighten-4 {
    background-color: #c8e6c9 !important;
}
.btn-green.btn-lighten-4 {
    border-color: #388e3c !important;
}
.btn-green.btn-lighten-4:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-4:active,
.btn-green.btn-lighten-4:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-lighten-4 {
    border-color: #c8e6c9 !important;
    color: #c8e6c9 !important;
}
.btn-outline-green.btn-outline-lighten-4:hover {
    background-color: #c8e6c9 !important;
}
.progress-green.progress-lighten-4 {
    background-color: #c8e6c9;
}
.progress-green.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #c8e6c9;
}
.progress-green.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #c8e6c9;
}
.progress-green.progress-lighten-4[value]::-ms-fill {
    background-color: #c8e6c9;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-lighten-4 .progress-bar {
        background-color: #c8e6c9;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c8e6c9 !important;
}
.border-green.border-lighten-4 {
    border: 1px solid #c8e6c9 !important;
}
.border-top-green.border-top-lighten-4 {
    border-top: 1px solid #c8e6c9 !important;
}
.border-bottom-green.border-bottom-lighten-4 {
    border-bottom: 1px solid #c8e6c9 !important;
}
[dir='ltr'] .border-left-green.border-left-lighten-4 {
    border-left: 1px solid #c8e6c9 !important;
}
[dir='ltr'] .border-right-green.border-right-lighten-4,
[dir='rtl'] .border-left-green.border-left-lighten-4 {
    border-right: 1px solid #c8e6c9 !important;
}
[dir='rtl'] .border-right-green.border-right-lighten-4 {
    border-left: 1px solid #c8e6c9 !important;
}
.overlay-green.overlay-lighten-4 {
    background: #c8e6c9;
    background: rgba(200, 230, 201, 0.8);
}
.green.lighten-3 {
    color: #a5d6a7 !important;
}
.bg-green.bg-lighten-3,
.btn-green.btn-lighten-3 {
    background-color: #a5d6a7 !important;
}
.btn-green.btn-lighten-3 {
    border-color: #388e3c !important;
}
.btn-green.btn-lighten-3:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-3:active,
.btn-green.btn-lighten-3:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-lighten-3 {
    border-color: #a5d6a7 !important;
    color: #a5d6a7 !important;
}
.btn-outline-green.btn-outline-lighten-3:hover {
    background-color: #a5d6a7 !important;
}
.progress-green.progress-lighten-3 {
    background-color: #a5d6a7;
}
.progress-green.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #a5d6a7;
}
.progress-green.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #a5d6a7;
}
.progress-green.progress-lighten-3[value]::-ms-fill {
    background-color: #a5d6a7;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-lighten-3 .progress-bar {
        background-color: #a5d6a7;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a5d6a7 !important;
}
.border-green.border-lighten-3 {
    border: 1px solid #a5d6a7 !important;
}
.border-top-green.border-top-lighten-3 {
    border-top: 1px solid #a5d6a7 !important;
}
.border-bottom-green.border-bottom-lighten-3 {
    border-bottom: 1px solid #a5d6a7 !important;
}
[dir='ltr'] .border-left-green.border-left-lighten-3 {
    border-left: 1px solid #a5d6a7 !important;
}
[dir='ltr'] .border-right-green.border-right-lighten-3,
[dir='rtl'] .border-left-green.border-left-lighten-3 {
    border-right: 1px solid #a5d6a7 !important;
}
[dir='rtl'] .border-right-green.border-right-lighten-3 {
    border-left: 1px solid #a5d6a7 !important;
}
.overlay-green.overlay-lighten-3 {
    background: #a5d6a7;
    background: rgba(165, 214, 167, 0.8);
}
.green.lighten-2 {
    color: #81c784 !important;
}
.bg-green.bg-lighten-2,
.btn-green.btn-lighten-2 {
    background-color: #81c784 !important;
}
.btn-green.btn-lighten-2 {
    border-color: #388e3c !important;
}
.btn-green.btn-lighten-2:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-2:active,
.btn-green.btn-lighten-2:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-lighten-2 {
    border-color: #81c784 !important;
    color: #81c784 !important;
}
.btn-outline-green.btn-outline-lighten-2:hover {
    background-color: #81c784 !important;
}
.progress-green.progress-lighten-2 {
    background-color: #81c784;
}
.progress-green.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #81c784;
}
.progress-green.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #81c784;
}
.progress-green.progress-lighten-2[value]::-ms-fill {
    background-color: #81c784;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-lighten-2 .progress-bar {
        background-color: #81c784;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #81c784 !important;
}
.border-green.border-lighten-2 {
    border: 1px solid #81c784 !important;
}
.border-top-green.border-top-lighten-2 {
    border-top: 1px solid #81c784 !important;
}
.border-bottom-green.border-bottom-lighten-2 {
    border-bottom: 1px solid #81c784 !important;
}
[dir='ltr'] .border-left-green.border-left-lighten-2 {
    border-left: 1px solid #81c784 !important;
}
[dir='ltr'] .border-right-green.border-right-lighten-2,
[dir='rtl'] .border-left-green.border-left-lighten-2 {
    border-right: 1px solid #81c784 !important;
}
[dir='rtl'] .border-right-green.border-right-lighten-2 {
    border-left: 1px solid #81c784 !important;
}
.overlay-green.overlay-lighten-2 {
    background: #81c784;
    background: rgba(129, 199, 132, 0.8);
}
.green.lighten-1 {
    color: #66bb6a !important;
}
.bg-green.bg-lighten-1,
.btn-green.btn-lighten-1 {
    background-color: #66bb6a !important;
}
.btn-green.btn-lighten-1 {
    border-color: #388e3c !important;
}
.btn-green.btn-lighten-1:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-lighten-1:active,
.btn-green.btn-lighten-1:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-lighten-1 {
    border-color: #66bb6a !important;
    color: #66bb6a !important;
}
.btn-outline-green.btn-outline-lighten-1:hover {
    background-color: #66bb6a !important;
}
.progress-green.progress-lighten-1 {
    background-color: #66bb6a;
}
.progress-green.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #66bb6a;
}
.progress-green.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #66bb6a;
}
.progress-green.progress-lighten-1[value]::-ms-fill {
    background-color: #66bb6a;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-lighten-1 .progress-bar {
        background-color: #66bb6a;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #66bb6a !important;
}
.border-green.border-lighten-1 {
    border: 1px solid #66bb6a !important;
}
.border-top-green.border-top-lighten-1 {
    border-top: 1px solid #66bb6a !important;
}
.border-bottom-green.border-bottom-lighten-1 {
    border-bottom: 1px solid #66bb6a !important;
}
[dir='ltr'] .border-left-green.border-left-lighten-1 {
    border-left: 1px solid #66bb6a !important;
}
[dir='ltr'] .border-right-green.border-right-lighten-1,
[dir='rtl'] .border-left-green.border-left-lighten-1 {
    border-right: 1px solid #66bb6a !important;
}
[dir='rtl'] .border-right-green.border-right-lighten-1 {
    border-left: 1px solid #66bb6a !important;
}
.overlay-green.overlay-lighten-1 {
    background: #66bb6a;
    background: rgba(102, 187, 106, 0.8);
}
.green {
    color: #4caf50 !important;
}
.bg-green {
    background-color: #4caf50 !important;
}
.bg-green .card-footer,
.bg-green .card-header {
    background-color: initial;
}
.toast-green {
    background-color: #4caf50;
}
.alert-green {
    border-color: #4caf50 !important;
    background-color: #63bb67 !important;
    color: #163317 !important;
}
.alert-green .alert-link {
    color: #0a160a !important;
}
.border-green {
    border-color: #4caf50;
}
.border-top-green {
    border-top-color: #4caf50;
}
.border-bottom-green {
    border-bottom-color: #4caf50;
}
[dir='ltr'] .border-left-green {
    border-left-color: #4caf50;
}
[dir='ltr'] .border-right-green,
[dir='rtl'] .border-left-green {
    border-right-color: #4caf50;
}
[dir='rtl'] .border-right-green {
    border-left-color: #4caf50;
}
.badge-green {
    background-color: #4caf50;
}
.panel-green {
    border-color: #4caf50;
}
.panel-green .panel-heading {
    color: #fff;
    border-color: #4caf50;
    background-color: #5cb860;
}
.bg-green.tag-glow,
.border-green.tag-glow {
    box-shadow: 0 0 10px #4caf50;
}
.overlay-green {
    background: #4caf50;
    background: rgba(76, 175, 80, 0.8);
}
.card.card-outline-green {
    border: 1px solid #4caf50;
    background-color: initial;
}
.card.card-outline-green .card-footer,
.card.card-outline-green .card-header {
    background-color: initial;
}
.btn-green.btn-flat {
    background-color: initial !important;
    color: #4caf50;
    border: none;
}
.btn-green.btn-fab,
.btn-green.btn-raised {
    background-color: #4caf50 !important;
    color: #fff !important;
    border-color: #4caf50;
}
.btn-green.btn-fab.active,
.btn-green.btn-raised.active {
    background-color: #419645 !important;
    border-color: #419645 !important;
}
.btn-group-raised .btn-green {
    background-color: #4caf50 !important;
    color: #fff !important;
}
.btn-outline-green {
    border: 1px solid #4caf50;
    background-color: initial;
    color: #4caf50;
    box-shadow: none !important;
}
.btn-outline-green:focus {
    background-color: initial !important;
    color: #4caf50 !important;
    box-shadow: transparent !important;
}
.btn-outline-green.active {
    background-color: #4caf50 !important;
    color: #fff !important;
}
.btn-outline-green:hover {
    background-color: #47a44b !important;
    color: #fff !important;
}
.btn-outline-green:hover svg {
    color: #fff !important;
}
.btn-outline-green.btn-fab,
.btn-outline-green.btn-raised {
    border: 1px solid #4caf50;
    background-color: initial;
    color: #4caf50;
    box-shadow: none !important;
}
.btn-outline-green.btn-fab:focus,
.btn-outline-green.btn-raised:focus {
    background-color: initial;
}
.btn-outline-green.btn-fab.active,
.btn-outline-green.btn-raised.active {
    background-color: #4caf50 !important;
    color: #fff !important;
}
.btn-outline-green.btn-fab:hover,
.btn-outline-green.btn-raised:hover {
    background-color: #47a44b !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-green {
    background-color: #4caf50;
}
.progress .progress-bar.progress-bar-green[value]::-webkit-progress-value {
    background-color: #4caf50;
}
.progress .progress-bar.progress-bar-green[value]::-moz-progress-bar {
    background-color: #4caf50;
}
.progress .progress-bar.progress-bar-green[value]::-ms-fill {
    background-color: #4caf50;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-green .progress-bar {
        background-color: #4caf50;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4caf50 !important;
}
.green.darken-1 {
    color: #43a047 !important;
}
.bg-green.bg-darken-1,
.btn-green.btn-darken-1 {
    background-color: #43a047 !important;
}
.btn-green.btn-darken-1,
.btn-green.btn-darken-1:hover {
    border-color: #388e3c !important;
}
.btn-green.btn-darken-1:hover {
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-1:active,
.btn-green.btn-darken-1:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-darken-1 {
    border-color: #43a047 !important;
    color: #43a047 !important;
}
.btn-outline-green.btn-outline-darken-1:hover {
    background-color: #43a047 !important;
}
.progress-green.progress-darken-1 {
    background-color: #43a047;
}
.progress-green.progress-darken-1[value]::-webkit-progress-value {
    background-color: #43a047;
}
.progress-green.progress-darken-1[value]::-moz-progress-bar {
    background-color: #43a047;
}
.progress-green.progress-darken-1[value]::-ms-fill {
    background-color: #43a047;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-darken-1 .progress-bar {
        background-color: #43a047;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #43a047 !important;
}
.border-green.border-darken-1 {
    border: 1px solid #43a047 !important;
}
.border-top-green.border-top-darken-1 {
    border-top: 1px solid #43a047 !important;
}
.border-bottom-green.border-bottom-darken-1 {
    border-bottom: 1px solid #43a047 !important;
}
[dir='ltr'] .border-left-green.border-left-darken-1 {
    border-left: 1px solid #43a047 !important;
}
[dir='ltr'] .border-right-green.border-right-darken-1,
[dir='rtl'] .border-left-green.border-left-darken-1 {
    border-right: 1px solid #43a047 !important;
}
[dir='rtl'] .border-right-green.border-right-darken-1 {
    border-left: 1px solid #43a047 !important;
}
.overlay-green.overlay-darken-1 {
    background: #43a047;
    background: rgba(67, 160, 71, 0.8);
}
.green.darken-2 {
    color: #388e3c !important;
}
.bg-green.bg-darken-2,
.btn-green.btn-darken-2 {
    background-color: #388e3c !important;
}
.btn-green.btn-darken-2,
.btn-green.btn-darken-2:hover {
    border-color: #388e3c !important;
}
.btn-green.btn-darken-2:hover {
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-2:active,
.btn-green.btn-darken-2:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-darken-2 {
    border-color: #388e3c !important;
    color: #388e3c !important;
}
.btn-outline-green.btn-outline-darken-2:hover {
    background-color: #388e3c !important;
}
.progress-green.progress-darken-2 {
    background-color: #388e3c;
}
.progress-green.progress-darken-2[value]::-webkit-progress-value {
    background-color: #388e3c;
}
.progress-green.progress-darken-2[value]::-moz-progress-bar {
    background-color: #388e3c;
}
.progress-green.progress-darken-2[value]::-ms-fill {
    background-color: #388e3c;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-darken-2 .progress-bar {
        background-color: #388e3c;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #388e3c !important;
}
.border-green.border-darken-2 {
    border: 1px solid #388e3c !important;
}
.border-top-green.border-top-darken-2 {
    border-top: 1px solid #388e3c !important;
}
.border-bottom-green.border-bottom-darken-2 {
    border-bottom: 1px solid #388e3c !important;
}
[dir='ltr'] .border-left-green.border-left-darken-2 {
    border-left: 1px solid #388e3c !important;
}
[dir='ltr'] .border-right-green.border-right-darken-2,
[dir='rtl'] .border-left-green.border-left-darken-2 {
    border-right: 1px solid #388e3c !important;
}
[dir='rtl'] .border-right-green.border-right-darken-2 {
    border-left: 1px solid #388e3c !important;
}
.overlay-green.overlay-darken-2 {
    background: #388e3c;
    background: rgba(56, 142, 60, 0.8);
}
.green.darken-3 {
    color: #2e7d32 !important;
}
.bg-green.bg-darken-3 {
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-3,
.btn-green.btn-darken-3:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-3:active,
.btn-green.btn-darken-3:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-darken-3 {
    border-color: #2e7d32 !important;
    color: #2e7d32 !important;
}
.btn-outline-green.btn-outline-darken-3:hover {
    background-color: #2e7d32 !important;
}
.progress-green.progress-darken-3 {
    background-color: #2e7d32;
}
.progress-green.progress-darken-3[value]::-webkit-progress-value {
    background-color: #2e7d32;
}
.progress-green.progress-darken-3[value]::-moz-progress-bar {
    background-color: #2e7d32;
}
.progress-green.progress-darken-3[value]::-ms-fill {
    background-color: #2e7d32;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-darken-3 .progress-bar {
        background-color: #2e7d32;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2e7d32 !important;
}
.border-green.border-darken-3 {
    border: 1px solid #2e7d32 !important;
}
.border-top-green.border-top-darken-3 {
    border-top: 1px solid #2e7d32 !important;
}
.border-bottom-green.border-bottom-darken-3 {
    border-bottom: 1px solid #2e7d32 !important;
}
[dir='ltr'] .border-left-green.border-left-darken-3 {
    border-left: 1px solid #2e7d32 !important;
}
[dir='ltr'] .border-right-green.border-right-darken-3,
[dir='rtl'] .border-left-green.border-left-darken-3 {
    border-right: 1px solid #2e7d32 !important;
}
[dir='rtl'] .border-right-green.border-right-darken-3 {
    border-left: 1px solid #2e7d32 !important;
}
.overlay-green.overlay-darken-3 {
    background: #2e7d32;
    background: rgba(46, 125, 50, 0.8);
}
.green.darken-4 {
    color: #1b5e20 !important;
}
.bg-green.bg-darken-4,
.btn-green.btn-darken-4 {
    background-color: #1b5e20 !important;
}
.btn-green.btn-darken-4,
.btn-green.btn-darken-4:hover {
    border-color: #388e3c !important;
}
.btn-green.btn-darken-4:hover {
    background-color: #2e7d32 !important;
}
.btn-green.btn-darken-4:active,
.btn-green.btn-darken-4:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-darken-4 {
    border-color: #1b5e20 !important;
    color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-darken-4:hover {
    background-color: #1b5e20 !important;
}
.progress-green.progress-darken-4 {
    background-color: #1b5e20;
}
.progress-green.progress-darken-4[value]::-webkit-progress-value {
    background-color: #1b5e20;
}
.progress-green.progress-darken-4[value]::-moz-progress-bar {
    background-color: #1b5e20;
}
.progress-green.progress-darken-4[value]::-ms-fill {
    background-color: #1b5e20;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-darken-4 .progress-bar {
        background-color: #1b5e20;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1b5e20 !important;
}
.border-green.border-darken-4 {
    border: 1px solid #1b5e20 !important;
}
.border-top-green.border-top-darken-4 {
    border-top: 1px solid #1b5e20 !important;
}
.border-bottom-green.border-bottom-darken-4 {
    border-bottom: 1px solid #1b5e20 !important;
}
[dir='ltr'] .border-left-green.border-left-darken-4 {
    border-left: 1px solid #1b5e20 !important;
}
[dir='ltr'] .border-right-green.border-right-darken-4,
[dir='rtl'] .border-left-green.border-left-darken-4 {
    border-right: 1px solid #1b5e20 !important;
}
[dir='rtl'] .border-right-green.border-right-darken-4 {
    border-left: 1px solid #1b5e20 !important;
}
.overlay-green.overlay-darken-4 {
    background: #1b5e20;
    background: rgba(27, 94, 32, 0.8);
}
.green.accent-1 {
    color: #b9f6ca !important;
}
.bg-green.bg-accent-1,
.btn-green.btn-accent-1 {
    background-color: #b9f6ca !important;
}
.btn-green.btn-accent-1,
.btn-green.btn-accent-1:hover {
    border-color: #388e3c !important;
}
.btn-green.btn-accent-1:hover {
    background-color: #2e7d32 !important;
}
.btn-green.btn-accent-1:active,
.btn-green.btn-accent-1:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-accent-1 {
    border-color: #b9f6ca !important;
    color: #b9f6ca !important;
}
.btn-outline-green.btn-outline-accent-1:hover {
    background-color: #b9f6ca !important;
}
.progress-green.progress-accent-1 {
    background-color: #b9f6ca;
}
.progress-green.progress-accent-1[value]::-webkit-progress-value {
    background-color: #b9f6ca;
}
.progress-green.progress-accent-1[value]::-moz-progress-bar {
    background-color: #b9f6ca;
}
.progress-green.progress-accent-1[value]::-ms-fill {
    background-color: #b9f6ca;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-accent-1 .progress-bar {
        background-color: #b9f6ca;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b9f6ca !important;
}
.border-green.border-accent-1 {
    border: 1px solid #b9f6ca !important;
}
.border-top-green.border-top-accent-1 {
    border-top: 1px solid #b9f6ca !important;
}
.border-bottom-green.border-bottom-accent-1 {
    border-bottom: 1px solid #b9f6ca !important;
}
[dir='ltr'] .border-left-green.border-left-accent-1 {
    border-left: 1px solid #b9f6ca !important;
}
[dir='ltr'] .border-right-green.border-right-accent-1,
[dir='rtl'] .border-left-green.border-left-accent-1 {
    border-right: 1px solid #b9f6ca !important;
}
[dir='rtl'] .border-right-green.border-right-accent-1 {
    border-left: 1px solid #b9f6ca !important;
}
.overlay-green.overlay-accent-1 {
    background: #b9f6ca;
    background: rgba(185, 246, 202, 0.8);
}
.green.accent-2 {
    color: #69f0ae !important;
}
.bg-green.bg-accent-2,
.btn-green.btn-accent-2 {
    background-color: #69f0ae !important;
}
.btn-green.btn-accent-2,
.btn-green.btn-accent-2:hover {
    border-color: #388e3c !important;
}
.btn-green.btn-accent-2:hover {
    background-color: #2e7d32 !important;
}
.btn-green.btn-accent-2:active,
.btn-green.btn-accent-2:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-accent-2 {
    border-color: #69f0ae !important;
    color: #69f0ae !important;
}
.btn-outline-green.btn-outline-accent-2:hover {
    background-color: #69f0ae !important;
}
.progress-green.progress-accent-2 {
    background-color: #69f0ae;
}
.progress-green.progress-accent-2[value]::-webkit-progress-value {
    background-color: #69f0ae;
}
.progress-green.progress-accent-2[value]::-moz-progress-bar {
    background-color: #69f0ae;
}
.progress-green.progress-accent-2[value]::-ms-fill {
    background-color: #69f0ae;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-accent-2 .progress-bar {
        background-color: #69f0ae;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #69f0ae !important;
}
.border-green.border-accent-2 {
    border: 1px solid #69f0ae !important;
}
.border-top-green.border-top-accent-2 {
    border-top: 1px solid #69f0ae !important;
}
.border-bottom-green.border-bottom-accent-2 {
    border-bottom: 1px solid #69f0ae !important;
}
[dir='ltr'] .border-left-green.border-left-accent-2 {
    border-left: 1px solid #69f0ae !important;
}
[dir='ltr'] .border-right-green.border-right-accent-2,
[dir='rtl'] .border-left-green.border-left-accent-2 {
    border-right: 1px solid #69f0ae !important;
}
[dir='rtl'] .border-right-green.border-right-accent-2 {
    border-left: 1px solid #69f0ae !important;
}
.overlay-green.overlay-accent-2 {
    background: #69f0ae;
    background: rgba(105, 240, 174, 0.8);
}
.green.accent-3 {
    color: #00e676 !important;
}
.bg-green.bg-accent-3,
.btn-green.btn-accent-3 {
    background-color: #00e676 !important;
}
.btn-green.btn-accent-3,
.btn-green.btn-accent-3:hover {
    border-color: #388e3c !important;
}
.btn-green.btn-accent-3:hover {
    background-color: #2e7d32 !important;
}
.btn-green.btn-accent-3:active,
.btn-green.btn-accent-3:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-accent-3 {
    border-color: #00e676 !important;
    color: #00e676 !important;
}
.btn-outline-green.btn-outline-accent-3:hover {
    background-color: #00e676 !important;
}
.progress-green.progress-accent-3 {
    background-color: #00e676;
}
.progress-green.progress-accent-3[value]::-webkit-progress-value {
    background-color: #00e676;
}
.progress-green.progress-accent-3[value]::-moz-progress-bar {
    background-color: #00e676;
}
.progress-green.progress-accent-3[value]::-ms-fill {
    background-color: #00e676;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-accent-3 .progress-bar {
        background-color: #00e676;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00e676 !important;
}
.border-green.border-accent-3 {
    border: 1px solid #00e676 !important;
}
.border-top-green.border-top-accent-3 {
    border-top: 1px solid #00e676 !important;
}
.border-bottom-green.border-bottom-accent-3 {
    border-bottom: 1px solid #00e676 !important;
}
[dir='ltr'] .border-left-green.border-left-accent-3 {
    border-left: 1px solid #00e676 !important;
}
[dir='ltr'] .border-right-green.border-right-accent-3,
[dir='rtl'] .border-left-green.border-left-accent-3 {
    border-right: 1px solid #00e676 !important;
}
[dir='rtl'] .border-right-green.border-right-accent-3 {
    border-left: 1px solid #00e676 !important;
}
.overlay-green.overlay-accent-3 {
    background: #00e676;
    background: rgba(0, 230, 118, 0.8);
}
.green.accent-4 {
    color: #00c853 !important;
}
.bg-green.bg-accent-4,
.btn-green.btn-accent-4 {
    background-color: #00c853 !important;
}
.btn-green.btn-accent-4,
.btn-green.btn-accent-4:hover {
    border-color: #388e3c !important;
}
.btn-green.btn-accent-4:hover {
    background-color: #2e7d32 !important;
}
.btn-green.btn-accent-4:active,
.btn-green.btn-accent-4:focus {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important;
}
.btn-outline-green.btn-outline-accent-4 {
    border-color: #00c853 !important;
    color: #00c853 !important;
}
.btn-outline-green.btn-outline-accent-4:hover {
    background-color: #00c853 !important;
}
.progress-green.progress-accent-4 {
    background-color: #00c853;
}
.progress-green.progress-accent-4[value]::-webkit-progress-value {
    background-color: #00c853;
}
.progress-green.progress-accent-4[value]::-moz-progress-bar {
    background-color: #00c853;
}
.progress-green.progress-accent-4[value]::-ms-fill {
    background-color: #00c853;
}
@media screen and (min-width: 0\0) {
    .progress-green.progress-accent-4 .progress-bar {
        background-color: #00c853;
    }
}
input:focus ~ .bg-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00c853 !important;
}
.border-green.border-accent-4 {
    border: 1px solid #00c853 !important;
}
.border-top-green.border-top-accent-4 {
    border-top: 1px solid #00c853 !important;
}
.border-bottom-green.border-bottom-accent-4 {
    border-bottom: 1px solid #00c853 !important;
}
[dir='ltr'] .border-left-green.border-left-accent-4 {
    border-left: 1px solid #00c853 !important;
}
[dir='ltr'] .border-right-green.border-right-accent-4,
[dir='rtl'] .border-left-green.border-left-accent-4 {
    border-right: 1px solid #00c853 !important;
}
[dir='rtl'] .border-right-green.border-right-accent-4 {
    border-left: 1px solid #00c853 !important;
}
.overlay-green.overlay-accent-4 {
    background: #00c853;
    background: rgba(0, 200, 83, 0.8);
}
.light-green.lighten-5 {
    color: #f1f8e9 !important;
}
.bg-light-green.bg-lighten-5,
.btn-light-green.btn-lighten-5 {
    background-color: #f1f8e9 !important;
}
.btn-light-green.btn-lighten-5 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-lighten-5:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-5:active,
.btn-light-green.btn-lighten-5:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-lighten-5 {
    border-color: #f1f8e9 !important;
    color: #f1f8e9 !important;
}
.btn-outline-light-green.btn-outline-lighten-5:hover {
    background-color: #f1f8e9 !important;
}
.progress-light-green.progress-lighten-5 {
    background-color: #f1f8e9;
}
.progress-light-green.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #f1f8e9;
}
.progress-light-green.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #f1f8e9;
}
.progress-light-green.progress-lighten-5[value]::-ms-fill {
    background-color: #f1f8e9;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-lighten-5 .progress-bar {
        background-color: #f1f8e9;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f1f8e9 !important;
}
.border-light-green.border-lighten-5 {
    border: 1px solid #f1f8e9 !important;
}
.border-top-light-green.border-top-lighten-5 {
    border-top: 1px solid #f1f8e9 !important;
}
.border-bottom-light-green.border-bottom-lighten-5 {
    border-bottom: 1px solid #f1f8e9 !important;
}
[dir='ltr'] .border-left-light-green.border-left-lighten-5 {
    border-left: 1px solid #f1f8e9 !important;
}
[dir='ltr'] .border-right-light-green.border-right-lighten-5,
[dir='rtl'] .border-left-light-green.border-left-lighten-5 {
    border-right: 1px solid #f1f8e9 !important;
}
[dir='rtl'] .border-right-light-green.border-right-lighten-5 {
    border-left: 1px solid #f1f8e9 !important;
}
.overlay-light-green.overlay-lighten-5 {
    background: #f1f8e9;
    background: rgba(241, 248, 233, 0.8);
}
.light-green.lighten-4 {
    color: #dcedc8 !important;
}
.bg-light-green.bg-lighten-4,
.btn-light-green.btn-lighten-4 {
    background-color: #dcedc8 !important;
}
.btn-light-green.btn-lighten-4 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-lighten-4:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-4:active,
.btn-light-green.btn-lighten-4:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-lighten-4 {
    border-color: #dcedc8 !important;
    color: #dcedc8 !important;
}
.btn-outline-light-green.btn-outline-lighten-4:hover {
    background-color: #dcedc8 !important;
}
.progress-light-green.progress-lighten-4 {
    background-color: #dcedc8;
}
.progress-light-green.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #dcedc8;
}
.progress-light-green.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #dcedc8;
}
.progress-light-green.progress-lighten-4[value]::-ms-fill {
    background-color: #dcedc8;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-lighten-4 .progress-bar {
        background-color: #dcedc8;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #dcedc8 !important;
}
.border-light-green.border-lighten-4 {
    border: 1px solid #dcedc8 !important;
}
.border-top-light-green.border-top-lighten-4 {
    border-top: 1px solid #dcedc8 !important;
}
.border-bottom-light-green.border-bottom-lighten-4 {
    border-bottom: 1px solid #dcedc8 !important;
}
[dir='ltr'] .border-left-light-green.border-left-lighten-4 {
    border-left: 1px solid #dcedc8 !important;
}
[dir='ltr'] .border-right-light-green.border-right-lighten-4,
[dir='rtl'] .border-left-light-green.border-left-lighten-4 {
    border-right: 1px solid #dcedc8 !important;
}
[dir='rtl'] .border-right-light-green.border-right-lighten-4 {
    border-left: 1px solid #dcedc8 !important;
}
.overlay-light-green.overlay-lighten-4 {
    background: #dcedc8;
    background: rgba(220, 237, 200, 0.8);
}
.light-green.lighten-3 {
    color: #c5e1a5 !important;
}
.bg-light-green.bg-lighten-3,
.btn-light-green.btn-lighten-3 {
    background-color: #c5e1a5 !important;
}
.btn-light-green.btn-lighten-3 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-lighten-3:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-3:active,
.btn-light-green.btn-lighten-3:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-lighten-3 {
    border-color: #c5e1a5 !important;
    color: #c5e1a5 !important;
}
.btn-outline-light-green.btn-outline-lighten-3:hover {
    background-color: #c5e1a5 !important;
}
.progress-light-green.progress-lighten-3 {
    background-color: #c5e1a5;
}
.progress-light-green.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #c5e1a5;
}
.progress-light-green.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #c5e1a5;
}
.progress-light-green.progress-lighten-3[value]::-ms-fill {
    background-color: #c5e1a5;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-lighten-3 .progress-bar {
        background-color: #c5e1a5;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c5e1a5 !important;
}
.border-light-green.border-lighten-3 {
    border: 1px solid #c5e1a5 !important;
}
.border-top-light-green.border-top-lighten-3 {
    border-top: 1px solid #c5e1a5 !important;
}
.border-bottom-light-green.border-bottom-lighten-3 {
    border-bottom: 1px solid #c5e1a5 !important;
}
[dir='ltr'] .border-left-light-green.border-left-lighten-3 {
    border-left: 1px solid #c5e1a5 !important;
}
[dir='ltr'] .border-right-light-green.border-right-lighten-3,
[dir='rtl'] .border-left-light-green.border-left-lighten-3 {
    border-right: 1px solid #c5e1a5 !important;
}
[dir='rtl'] .border-right-light-green.border-right-lighten-3 {
    border-left: 1px solid #c5e1a5 !important;
}
.overlay-light-green.overlay-lighten-3 {
    background: #c5e1a5;
    background: rgba(197, 225, 165, 0.8);
}
.light-green.lighten-2 {
    color: #aed581 !important;
}
.bg-light-green.bg-lighten-2,
.btn-light-green.btn-lighten-2 {
    background-color: #aed581 !important;
}
.btn-light-green.btn-lighten-2 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-lighten-2:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-2:active,
.btn-light-green.btn-lighten-2:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-lighten-2 {
    border-color: #aed581 !important;
    color: #aed581 !important;
}
.btn-outline-light-green.btn-outline-lighten-2:hover {
    background-color: #aed581 !important;
}
.progress-light-green.progress-lighten-2 {
    background-color: #aed581;
}
.progress-light-green.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #aed581;
}
.progress-light-green.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #aed581;
}
.progress-light-green.progress-lighten-2[value]::-ms-fill {
    background-color: #aed581;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-lighten-2 .progress-bar {
        background-color: #aed581;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #aed581 !important;
}
.border-light-green.border-lighten-2 {
    border: 1px solid #aed581 !important;
}
.border-top-light-green.border-top-lighten-2 {
    border-top: 1px solid #aed581 !important;
}
.border-bottom-light-green.border-bottom-lighten-2 {
    border-bottom: 1px solid #aed581 !important;
}
[dir='ltr'] .border-left-light-green.border-left-lighten-2 {
    border-left: 1px solid #aed581 !important;
}
[dir='ltr'] .border-right-light-green.border-right-lighten-2,
[dir='rtl'] .border-left-light-green.border-left-lighten-2 {
    border-right: 1px solid #aed581 !important;
}
[dir='rtl'] .border-right-light-green.border-right-lighten-2 {
    border-left: 1px solid #aed581 !important;
}
.overlay-light-green.overlay-lighten-2 {
    background: #aed581;
    background: rgba(174, 213, 129, 0.8);
}
.light-green.lighten-1 {
    color: #9ccc65 !important;
}
.bg-light-green.bg-lighten-1,
.btn-light-green.btn-lighten-1 {
    background-color: #9ccc65 !important;
}
.btn-light-green.btn-lighten-1 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-lighten-1:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-lighten-1:active,
.btn-light-green.btn-lighten-1:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-lighten-1 {
    border-color: #9ccc65 !important;
    color: #9ccc65 !important;
}
.btn-outline-light-green.btn-outline-lighten-1:hover {
    background-color: #9ccc65 !important;
}
.progress-light-green.progress-lighten-1 {
    background-color: #9ccc65;
}
.progress-light-green.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #9ccc65;
}
.progress-light-green.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #9ccc65;
}
.progress-light-green.progress-lighten-1[value]::-ms-fill {
    background-color: #9ccc65;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-lighten-1 .progress-bar {
        background-color: #9ccc65;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9ccc65 !important;
}
.border-light-green.border-lighten-1 {
    border: 1px solid #9ccc65 !important;
}
.border-top-light-green.border-top-lighten-1 {
    border-top: 1px solid #9ccc65 !important;
}
.border-bottom-light-green.border-bottom-lighten-1 {
    border-bottom: 1px solid #9ccc65 !important;
}
[dir='ltr'] .border-left-light-green.border-left-lighten-1 {
    border-left: 1px solid #9ccc65 !important;
}
[dir='ltr'] .border-right-light-green.border-right-lighten-1,
[dir='rtl'] .border-left-light-green.border-left-lighten-1 {
    border-right: 1px solid #9ccc65 !important;
}
[dir='rtl'] .border-right-light-green.border-right-lighten-1 {
    border-left: 1px solid #9ccc65 !important;
}
.overlay-light-green.overlay-lighten-1 {
    background: #9ccc65;
    background: rgba(156, 204, 101, 0.8);
}
.light-green {
    color: #8bc34a !important;
}
.bg-light-green {
    background-color: #8bc34a !important;
}
.bg-light-green .card-footer,
.bg-light-green .card-header {
    background-color: initial;
}
.toast-light-green {
    background-color: #8bc34a;
}
.alert-light-green {
    border-color: #8bc34a !important;
    background-color: #9ccc65 !important;
    color: #2f4417 !important;
}
.alert-light-green .alert-link {
    color: #1a250c !important;
}
.border-light-green {
    border-color: #8bc34a;
}
.border-top-light-green {
    border-top-color: #8bc34a;
}
.border-bottom-light-green {
    border-bottom-color: #8bc34a;
}
[dir='ltr'] .border-left-light-green {
    border-left-color: #8bc34a;
}
[dir='ltr'] .border-right-light-green,
[dir='rtl'] .border-left-light-green {
    border-right-color: #8bc34a;
}
[dir='rtl'] .border-right-light-green {
    border-left-color: #8bc34a;
}
.badge-light-green {
    background-color: #8bc34a;
}
.panel-light-green {
    border-color: #8bc34a;
}
.panel-light-green .panel-heading {
    color: #fff;
    border-color: #8bc34a;
    background-color: #97c95d;
}
.bg-light-green.tag-glow,
.border-light-green.tag-glow {
    box-shadow: 0 0 10px #8bc34a;
}
.overlay-light-green {
    background: #8bc34a;
    background: rgba(139, 195, 74, 0.8);
}
.card.card-outline-light-green {
    border: 1px solid #8bc34a;
    background-color: initial;
}
.card.card-outline-light-green .card-footer,
.card.card-outline-light-green .card-header {
    background-color: initial;
}
.btn-light-green.btn-flat {
    background-color: initial !important;
    color: #8bc34a;
    border: none;
}
.btn-light-green.btn-fab,
.btn-light-green.btn-raised {
    background-color: #8bc34a !important;
    color: #fff !important;
    border-color: #8bc34a;
}
.btn-light-green.btn-fab.active,
.btn-light-green.btn-raised.active {
    background-color: #79af3a !important;
    border-color: #79af3a !important;
}
.btn-group-raised .btn-light-green {
    background-color: #8bc34a !important;
    color: #fff !important;
}
.btn-outline-light-green {
    border: 1px solid #8bc34a;
    background-color: initial;
    color: #8bc34a;
    box-shadow: none !important;
}
.btn-outline-light-green:focus {
    background-color: initial !important;
    color: #8bc34a !important;
    box-shadow: transparent !important;
}
.btn-outline-light-green.active {
    background-color: #8bc34a !important;
    color: #fff !important;
}
.btn-outline-light-green:hover {
    background-color: #84bf3f !important;
    color: #fff !important;
}
.btn-outline-light-green:hover svg {
    color: #fff !important;
}
.btn-outline-light-green.btn-fab,
.btn-outline-light-green.btn-raised {
    border: 1px solid #8bc34a;
    background-color: initial;
    color: #8bc34a;
    box-shadow: none !important;
}
.btn-outline-light-green.btn-fab:focus,
.btn-outline-light-green.btn-raised:focus {
    background-color: initial;
}
.btn-outline-light-green.btn-fab.active,
.btn-outline-light-green.btn-raised.active {
    background-color: #8bc34a !important;
    color: #fff !important;
}
.btn-outline-light-green.btn-fab:hover,
.btn-outline-light-green.btn-raised:hover {
    background-color: #84bf3f !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-light-green {
    background-color: #8bc34a;
}
.progress
    .progress-bar.progress-bar-light-green[value]::-webkit-progress-value {
    background-color: #8bc34a;
}
.progress .progress-bar.progress-bar-light-green[value]::-moz-progress-bar {
    background-color: #8bc34a;
}
.progress .progress-bar.progress-bar-light-green[value]::-ms-fill {
    background-color: #8bc34a;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-light-green .progress-bar {
        background-color: #8bc34a;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8bc34a !important;
}
.light-green.darken-1 {
    color: #7cb342 !important;
}
.bg-light-green.bg-darken-1,
.btn-light-green.btn-darken-1 {
    background-color: #7cb342 !important;
}
.btn-light-green.btn-darken-1 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-darken-1:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-1:active,
.btn-light-green.btn-darken-1:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-darken-1 {
    border-color: #7cb342 !important;
    color: #7cb342 !important;
}
.btn-outline-light-green.btn-outline-darken-1:hover {
    background-color: #7cb342 !important;
}
.progress-light-green.progress-darken-1 {
    background-color: #7cb342;
}
.progress-light-green.progress-darken-1[value]::-webkit-progress-value {
    background-color: #7cb342;
}
.progress-light-green.progress-darken-1[value]::-moz-progress-bar {
    background-color: #7cb342;
}
.progress-light-green.progress-darken-1[value]::-ms-fill {
    background-color: #7cb342;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-darken-1 .progress-bar {
        background-color: #7cb342;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7cb342 !important;
}
.border-light-green.border-darken-1 {
    border: 1px solid #7cb342 !important;
}
.border-top-light-green.border-top-darken-1 {
    border-top: 1px solid #7cb342 !important;
}
.border-bottom-light-green.border-bottom-darken-1 {
    border-bottom: 1px solid #7cb342 !important;
}
[dir='ltr'] .border-left-light-green.border-left-darken-1 {
    border-left: 1px solid #7cb342 !important;
}
[dir='ltr'] .border-right-light-green.border-right-darken-1,
[dir='rtl'] .border-left-light-green.border-left-darken-1 {
    border-right: 1px solid #7cb342 !important;
}
[dir='rtl'] .border-right-light-green.border-right-darken-1 {
    border-left: 1px solid #7cb342 !important;
}
.overlay-light-green.overlay-darken-1 {
    background: #7cb342;
    background: rgba(124, 179, 66, 0.8);
}
.light-green.darken-2 {
    color: #689f38 !important;
}
.bg-light-green.bg-darken-2,
.btn-light-green.btn-darken-2 {
    background-color: #689f38 !important;
}
.btn-light-green.btn-darken-2 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-darken-2:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-2:active,
.btn-light-green.btn-darken-2:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-darken-2 {
    border-color: #689f38 !important;
    color: #689f38 !important;
}
.btn-outline-light-green.btn-outline-darken-2:hover {
    background-color: #689f38 !important;
}
.progress-light-green.progress-darken-2 {
    background-color: #689f38;
}
.progress-light-green.progress-darken-2[value]::-webkit-progress-value {
    background-color: #689f38;
}
.progress-light-green.progress-darken-2[value]::-moz-progress-bar {
    background-color: #689f38;
}
.progress-light-green.progress-darken-2[value]::-ms-fill {
    background-color: #689f38;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-darken-2 .progress-bar {
        background-color: #689f38;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #689f38 !important;
}
.border-light-green.border-darken-2 {
    border: 1px solid #689f38 !important;
}
.border-top-light-green.border-top-darken-2 {
    border-top: 1px solid #689f38 !important;
}
.border-bottom-light-green.border-bottom-darken-2 {
    border-bottom: 1px solid #689f38 !important;
}
[dir='ltr'] .border-left-light-green.border-left-darken-2 {
    border-left: 1px solid #689f38 !important;
}
[dir='ltr'] .border-right-light-green.border-right-darken-2,
[dir='rtl'] .border-left-light-green.border-left-darken-2 {
    border-right: 1px solid #689f38 !important;
}
[dir='rtl'] .border-right-light-green.border-right-darken-2 {
    border-left: 1px solid #689f38 !important;
}
.overlay-light-green.overlay-darken-2 {
    background: #689f38;
    background: rgba(104, 159, 56, 0.8);
}
.light-green.darken-3 {
    color: #558b2f !important;
}
.bg-light-green.bg-darken-3 {
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-3,
.btn-light-green.btn-darken-3:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-3:active,
.btn-light-green.btn-darken-3:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-darken-3 {
    border-color: #558b2f !important;
    color: #558b2f !important;
}
.btn-outline-light-green.btn-outline-darken-3:hover {
    background-color: #558b2f !important;
}
.progress-light-green.progress-darken-3 {
    background-color: #558b2f;
}
.progress-light-green.progress-darken-3[value]::-webkit-progress-value {
    background-color: #558b2f;
}
.progress-light-green.progress-darken-3[value]::-moz-progress-bar {
    background-color: #558b2f;
}
.progress-light-green.progress-darken-3[value]::-ms-fill {
    background-color: #558b2f;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-darken-3 .progress-bar {
        background-color: #558b2f;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #558b2f !important;
}
.border-light-green.border-darken-3 {
    border: 1px solid #558b2f !important;
}
.border-top-light-green.border-top-darken-3 {
    border-top: 1px solid #558b2f !important;
}
.border-bottom-light-green.border-bottom-darken-3 {
    border-bottom: 1px solid #558b2f !important;
}
[dir='ltr'] .border-left-light-green.border-left-darken-3 {
    border-left: 1px solid #558b2f !important;
}
[dir='ltr'] .border-right-light-green.border-right-darken-3,
[dir='rtl'] .border-left-light-green.border-left-darken-3 {
    border-right: 1px solid #558b2f !important;
}
[dir='rtl'] .border-right-light-green.border-right-darken-3 {
    border-left: 1px solid #558b2f !important;
}
.overlay-light-green.overlay-darken-3 {
    background: #558b2f;
    background: rgba(85, 139, 47, 0.8);
}
.light-green.darken-4 {
    color: #33691e !important;
}
.bg-light-green.bg-darken-4,
.btn-light-green.btn-darken-4 {
    background-color: #33691e !important;
}
.btn-light-green.btn-darken-4 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-darken-4:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-darken-4:active,
.btn-light-green.btn-darken-4:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-darken-4 {
    border-color: #33691e !important;
    color: #33691e !important;
}
.btn-outline-light-green.btn-outline-darken-4:hover {
    background-color: #33691e !important;
}
.progress-light-green.progress-darken-4 {
    background-color: #33691e;
}
.progress-light-green.progress-darken-4[value]::-webkit-progress-value {
    background-color: #33691e;
}
.progress-light-green.progress-darken-4[value]::-moz-progress-bar {
    background-color: #33691e;
}
.progress-light-green.progress-darken-4[value]::-ms-fill {
    background-color: #33691e;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-darken-4 .progress-bar {
        background-color: #33691e;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #33691e !important;
}
.border-light-green.border-darken-4 {
    border: 1px solid #33691e !important;
}
.border-top-light-green.border-top-darken-4 {
    border-top: 1px solid #33691e !important;
}
.border-bottom-light-green.border-bottom-darken-4 {
    border-bottom: 1px solid #33691e !important;
}
[dir='ltr'] .border-left-light-green.border-left-darken-4 {
    border-left: 1px solid #33691e !important;
}
[dir='ltr'] .border-right-light-green.border-right-darken-4,
[dir='rtl'] .border-left-light-green.border-left-darken-4 {
    border-right: 1px solid #33691e !important;
}
[dir='rtl'] .border-right-light-green.border-right-darken-4 {
    border-left: 1px solid #33691e !important;
}
.overlay-light-green.overlay-darken-4 {
    background: #33691e;
    background: rgba(51, 105, 30, 0.8);
}
.light-green.accent-1 {
    color: #ccff90 !important;
}
.bg-light-green.bg-accent-1,
.btn-light-green.btn-accent-1 {
    background-color: #ccff90 !important;
}
.btn-light-green.btn-accent-1 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-accent-1:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-accent-1:active,
.btn-light-green.btn-accent-1:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-accent-1 {
    border-color: #ccff90 !important;
    color: #ccff90 !important;
}
.btn-outline-light-green.btn-outline-accent-1:hover {
    background-color: #ccff90 !important;
}
.progress-light-green.progress-accent-1 {
    background-color: #ccff90;
}
.progress-light-green.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ccff90;
}
.progress-light-green.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ccff90;
}
.progress-light-green.progress-accent-1[value]::-ms-fill {
    background-color: #ccff90;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-accent-1 .progress-bar {
        background-color: #ccff90;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ccff90 !important;
}
.border-light-green.border-accent-1 {
    border: 1px solid #ccff90 !important;
}
.border-top-light-green.border-top-accent-1 {
    border-top: 1px solid #ccff90 !important;
}
.border-bottom-light-green.border-bottom-accent-1 {
    border-bottom: 1px solid #ccff90 !important;
}
[dir='ltr'] .border-left-light-green.border-left-accent-1 {
    border-left: 1px solid #ccff90 !important;
}
[dir='ltr'] .border-right-light-green.border-right-accent-1,
[dir='rtl'] .border-left-light-green.border-left-accent-1 {
    border-right: 1px solid #ccff90 !important;
}
[dir='rtl'] .border-right-light-green.border-right-accent-1 {
    border-left: 1px solid #ccff90 !important;
}
.overlay-light-green.overlay-accent-1 {
    background: #ccff90;
    background: rgba(204, 255, 144, 0.8);
}
.light-green.accent-2 {
    color: #b2ff59 !important;
}
.bg-light-green.bg-accent-2,
.btn-light-green.btn-accent-2 {
    background-color: #b2ff59 !important;
}
.btn-light-green.btn-accent-2 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-accent-2:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-accent-2:active,
.btn-light-green.btn-accent-2:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-accent-2 {
    border-color: #b2ff59 !important;
    color: #b2ff59 !important;
}
.btn-outline-light-green.btn-outline-accent-2:hover {
    background-color: #b2ff59 !important;
}
.progress-light-green.progress-accent-2 {
    background-color: #b2ff59;
}
.progress-light-green.progress-accent-2[value]::-webkit-progress-value {
    background-color: #b2ff59;
}
.progress-light-green.progress-accent-2[value]::-moz-progress-bar {
    background-color: #b2ff59;
}
.progress-light-green.progress-accent-2[value]::-ms-fill {
    background-color: #b2ff59;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-accent-2 .progress-bar {
        background-color: #b2ff59;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b2ff59 !important;
}
.border-light-green.border-accent-2 {
    border: 1px solid #b2ff59 !important;
}
.border-top-light-green.border-top-accent-2 {
    border-top: 1px solid #b2ff59 !important;
}
.border-bottom-light-green.border-bottom-accent-2 {
    border-bottom: 1px solid #b2ff59 !important;
}
[dir='ltr'] .border-left-light-green.border-left-accent-2 {
    border-left: 1px solid #b2ff59 !important;
}
[dir='ltr'] .border-right-light-green.border-right-accent-2,
[dir='rtl'] .border-left-light-green.border-left-accent-2 {
    border-right: 1px solid #b2ff59 !important;
}
[dir='rtl'] .border-right-light-green.border-right-accent-2 {
    border-left: 1px solid #b2ff59 !important;
}
.overlay-light-green.overlay-accent-2 {
    background: #b2ff59;
    background: rgba(178, 255, 89, 0.8);
}
.light-green.accent-3 {
    color: #76ff03 !important;
}
.bg-light-green.bg-accent-3,
.btn-light-green.btn-accent-3 {
    background-color: #76ff03 !important;
}
.btn-light-green.btn-accent-3 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-accent-3:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-accent-3:active,
.btn-light-green.btn-accent-3:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-accent-3 {
    border-color: #76ff03 !important;
    color: #76ff03 !important;
}
.btn-outline-light-green.btn-outline-accent-3:hover {
    background-color: #76ff03 !important;
}
.progress-light-green.progress-accent-3 {
    background-color: #76ff03;
}
.progress-light-green.progress-accent-3[value]::-webkit-progress-value {
    background-color: #76ff03;
}
.progress-light-green.progress-accent-3[value]::-moz-progress-bar {
    background-color: #76ff03;
}
.progress-light-green.progress-accent-3[value]::-ms-fill {
    background-color: #76ff03;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-accent-3 .progress-bar {
        background-color: #76ff03;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #76ff03 !important;
}
.border-light-green.border-accent-3 {
    border: 1px solid #76ff03 !important;
}
.border-top-light-green.border-top-accent-3 {
    border-top: 1px solid #76ff03 !important;
}
.border-bottom-light-green.border-bottom-accent-3 {
    border-bottom: 1px solid #76ff03 !important;
}
[dir='ltr'] .border-left-light-green.border-left-accent-3 {
    border-left: 1px solid #76ff03 !important;
}
[dir='ltr'] .border-right-light-green.border-right-accent-3,
[dir='rtl'] .border-left-light-green.border-left-accent-3 {
    border-right: 1px solid #76ff03 !important;
}
[dir='rtl'] .border-right-light-green.border-right-accent-3 {
    border-left: 1px solid #76ff03 !important;
}
.overlay-light-green.overlay-accent-3 {
    background: #76ff03;
    background: rgba(118, 255, 3, 0.8);
}
.light-green.accent-4 {
    color: #64dd17 !important;
}
.bg-light-green.bg-accent-4,
.btn-light-green.btn-accent-4 {
    background-color: #64dd17 !important;
}
.btn-light-green.btn-accent-4 {
    border-color: #689f38 !important;
}
.btn-light-green.btn-accent-4:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important;
}
.btn-light-green.btn-accent-4:active,
.btn-light-green.btn-accent-4:focus {
    border-color: #558b2f !important;
    background-color: #33691e !important;
}
.btn-outline-light-green.btn-outline-accent-4 {
    border-color: #64dd17 !important;
    color: #64dd17 !important;
}
.btn-outline-light-green.btn-outline-accent-4:hover {
    background-color: #64dd17 !important;
}
.progress-light-green.progress-accent-4 {
    background-color: #64dd17;
}
.progress-light-green.progress-accent-4[value]::-webkit-progress-value {
    background-color: #64dd17;
}
.progress-light-green.progress-accent-4[value]::-moz-progress-bar {
    background-color: #64dd17;
}
.progress-light-green.progress-accent-4[value]::-ms-fill {
    background-color: #64dd17;
}
@media screen and (min-width: 0\0) {
    .progress-light-green.progress-accent-4 .progress-bar {
        background-color: #64dd17;
    }
}
input:focus ~ .bg-light-green {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64dd17 !important;
}
.border-light-green.border-accent-4 {
    border: 1px solid #64dd17 !important;
}
.border-top-light-green.border-top-accent-4 {
    border-top: 1px solid #64dd17 !important;
}
.border-bottom-light-green.border-bottom-accent-4 {
    border-bottom: 1px solid #64dd17 !important;
}
[dir='ltr'] .border-left-light-green.border-left-accent-4 {
    border-left: 1px solid #64dd17 !important;
}
[dir='ltr'] .border-right-light-green.border-right-accent-4,
[dir='rtl'] .border-left-light-green.border-left-accent-4 {
    border-right: 1px solid #64dd17 !important;
}
[dir='rtl'] .border-right-light-green.border-right-accent-4 {
    border-left: 1px solid #64dd17 !important;
}
.overlay-light-green.overlay-accent-4 {
    background: #64dd17;
    background: rgba(100, 221, 23, 0.8);
}
.lime.lighten-5 {
    color: #f9fbe7 !important;
}
.bg-lime.bg-lighten-5,
.btn-lime.btn-lighten-5 {
    background-color: #f9fbe7 !important;
}
.btn-lime.btn-lighten-5,
.btn-lime.btn-lighten-5:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-lighten-5:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-5:active,
.btn-lime.btn-lighten-5:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-lighten-5 {
    border-color: #f9fbe7 !important;
    color: #f9fbe7 !important;
}
.btn-outline-lime.btn-outline-lighten-5:hover {
    background-color: #f9fbe7 !important;
}
.progress-lime.progress-lighten-5 {
    background-color: #f9fbe7;
}
.progress-lime.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #f9fbe7;
}
.progress-lime.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #f9fbe7;
}
.progress-lime.progress-lighten-5[value]::-ms-fill {
    background-color: #f9fbe7;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-lighten-5 .progress-bar {
        background-color: #f9fbe7;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f9fbe7 !important;
}
.border-lime.border-lighten-5 {
    border: 1px solid #f9fbe7 !important;
}
.border-top-lime.border-top-lighten-5 {
    border-top: 1px solid #f9fbe7 !important;
}
.border-bottom-lime.border-bottom-lighten-5 {
    border-bottom: 1px solid #f9fbe7 !important;
}
[dir='ltr'] .border-left-lime.border-left-lighten-5 {
    border-left: 1px solid #f9fbe7 !important;
}
[dir='ltr'] .border-right-lime.border-right-lighten-5,
[dir='rtl'] .border-left-lime.border-left-lighten-5 {
    border-right: 1px solid #f9fbe7 !important;
}
[dir='rtl'] .border-right-lime.border-right-lighten-5 {
    border-left: 1px solid #f9fbe7 !important;
}
.overlay-lime.overlay-lighten-5 {
    background: #f9fbe7;
    background: rgba(249, 251, 231, 0.8);
}
.lime.lighten-4 {
    color: #f0f4c3 !important;
}
.bg-lime.bg-lighten-4,
.btn-lime.btn-lighten-4 {
    background-color: #f0f4c3 !important;
}
.btn-lime.btn-lighten-4,
.btn-lime.btn-lighten-4:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-lighten-4:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-4:active,
.btn-lime.btn-lighten-4:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-lighten-4 {
    border-color: #f0f4c3 !important;
    color: #f0f4c3 !important;
}
.btn-outline-lime.btn-outline-lighten-4:hover {
    background-color: #f0f4c3 !important;
}
.progress-lime.progress-lighten-4 {
    background-color: #f0f4c3;
}
.progress-lime.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #f0f4c3;
}
.progress-lime.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #f0f4c3;
}
.progress-lime.progress-lighten-4[value]::-ms-fill {
    background-color: #f0f4c3;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-lighten-4 .progress-bar {
        background-color: #f0f4c3;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f0f4c3 !important;
}
.border-lime.border-lighten-4 {
    border: 1px solid #f0f4c3 !important;
}
.border-top-lime.border-top-lighten-4 {
    border-top: 1px solid #f0f4c3 !important;
}
.border-bottom-lime.border-bottom-lighten-4 {
    border-bottom: 1px solid #f0f4c3 !important;
}
[dir='ltr'] .border-left-lime.border-left-lighten-4 {
    border-left: 1px solid #f0f4c3 !important;
}
[dir='ltr'] .border-right-lime.border-right-lighten-4,
[dir='rtl'] .border-left-lime.border-left-lighten-4 {
    border-right: 1px solid #f0f4c3 !important;
}
[dir='rtl'] .border-right-lime.border-right-lighten-4 {
    border-left: 1px solid #f0f4c3 !important;
}
.overlay-lime.overlay-lighten-4 {
    background: #f0f4c3;
    background: rgba(240, 244, 195, 0.8);
}
.lime.lighten-3 {
    color: #e6ee9c !important;
}
.bg-lime.bg-lighten-3,
.btn-lime.btn-lighten-3 {
    background-color: #e6ee9c !important;
}
.btn-lime.btn-lighten-3,
.btn-lime.btn-lighten-3:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-lighten-3:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-3:active,
.btn-lime.btn-lighten-3:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-lighten-3 {
    border-color: #e6ee9c !important;
    color: #e6ee9c !important;
}
.btn-outline-lime.btn-outline-lighten-3:hover {
    background-color: #e6ee9c !important;
}
.progress-lime.progress-lighten-3 {
    background-color: #e6ee9c;
}
.progress-lime.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #e6ee9c;
}
.progress-lime.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #e6ee9c;
}
.progress-lime.progress-lighten-3[value]::-ms-fill {
    background-color: #e6ee9c;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-lighten-3 .progress-bar {
        background-color: #e6ee9c;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e6ee9c !important;
}
.border-lime.border-lighten-3 {
    border: 1px solid #e6ee9c !important;
}
.border-top-lime.border-top-lighten-3 {
    border-top: 1px solid #e6ee9c !important;
}
.border-bottom-lime.border-bottom-lighten-3 {
    border-bottom: 1px solid #e6ee9c !important;
}
[dir='ltr'] .border-left-lime.border-left-lighten-3 {
    border-left: 1px solid #e6ee9c !important;
}
[dir='ltr'] .border-right-lime.border-right-lighten-3,
[dir='rtl'] .border-left-lime.border-left-lighten-3 {
    border-right: 1px solid #e6ee9c !important;
}
[dir='rtl'] .border-right-lime.border-right-lighten-3 {
    border-left: 1px solid #e6ee9c !important;
}
.overlay-lime.overlay-lighten-3 {
    background: #e6ee9c;
    background: rgba(230, 238, 156, 0.8);
}
.lime.lighten-2 {
    color: #dce775 !important;
}
.bg-lime.bg-lighten-2,
.btn-lime.btn-lighten-2 {
    background-color: #dce775 !important;
}
.btn-lime.btn-lighten-2,
.btn-lime.btn-lighten-2:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-lighten-2:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-2:active,
.btn-lime.btn-lighten-2:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-lighten-2 {
    border-color: #dce775 !important;
    color: #dce775 !important;
}
.btn-outline-lime.btn-outline-lighten-2:hover {
    background-color: #dce775 !important;
}
.progress-lime.progress-lighten-2 {
    background-color: #dce775;
}
.progress-lime.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #dce775;
}
.progress-lime.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #dce775;
}
.progress-lime.progress-lighten-2[value]::-ms-fill {
    background-color: #dce775;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-lighten-2 .progress-bar {
        background-color: #dce775;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #dce775 !important;
}
.border-lime.border-lighten-2 {
    border: 1px solid #dce775 !important;
}
.border-top-lime.border-top-lighten-2 {
    border-top: 1px solid #dce775 !important;
}
.border-bottom-lime.border-bottom-lighten-2 {
    border-bottom: 1px solid #dce775 !important;
}
[dir='ltr'] .border-left-lime.border-left-lighten-2 {
    border-left: 1px solid #dce775 !important;
}
[dir='ltr'] .border-right-lime.border-right-lighten-2,
[dir='rtl'] .border-left-lime.border-left-lighten-2 {
    border-right: 1px solid #dce775 !important;
}
[dir='rtl'] .border-right-lime.border-right-lighten-2 {
    border-left: 1px solid #dce775 !important;
}
.overlay-lime.overlay-lighten-2 {
    background: #dce775;
    background: rgba(220, 231, 117, 0.8);
}
.lime.lighten-1 {
    color: #d4e157 !important;
}
.bg-lime.bg-lighten-1,
.btn-lime.btn-lighten-1 {
    background-color: #d4e157 !important;
}
.btn-lime.btn-lighten-1,
.btn-lime.btn-lighten-1:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-lighten-1:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-lighten-1:active,
.btn-lime.btn-lighten-1:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-lighten-1 {
    border-color: #d4e157 !important;
    color: #d4e157 !important;
}
.btn-outline-lime.btn-outline-lighten-1:hover {
    background-color: #d4e157 !important;
}
.progress-lime.progress-lighten-1 {
    background-color: #d4e157;
}
.progress-lime.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #d4e157;
}
.progress-lime.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #d4e157;
}
.progress-lime.progress-lighten-1[value]::-ms-fill {
    background-color: #d4e157;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-lighten-1 .progress-bar {
        background-color: #d4e157;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d4e157 !important;
}
.border-lime.border-lighten-1 {
    border: 1px solid #d4e157 !important;
}
.border-top-lime.border-top-lighten-1 {
    border-top: 1px solid #d4e157 !important;
}
.border-bottom-lime.border-bottom-lighten-1 {
    border-bottom: 1px solid #d4e157 !important;
}
[dir='ltr'] .border-left-lime.border-left-lighten-1 {
    border-left: 1px solid #d4e157 !important;
}
[dir='ltr'] .border-right-lime.border-right-lighten-1,
[dir='rtl'] .border-left-lime.border-left-lighten-1 {
    border-right: 1px solid #d4e157 !important;
}
[dir='rtl'] .border-right-lime.border-right-lighten-1 {
    border-left: 1px solid #d4e157 !important;
}
.overlay-lime.overlay-lighten-1 {
    background: #d4e157;
    background: rgba(212, 225, 87, 0.8);
}
.lime {
    color: #cddc39 !important;
}
.bg-lime {
    background-color: #cddc39 !important;
}
.bg-lime .card-footer,
.bg-lime .card-header {
    background-color: initial;
}
.toast-lime {
    background-color: #cddc39;
}
.alert-lime {
    border-color: #cddc39 !important;
    background-color: #d5e157 !important;
    color: #4d540f !important;
}
.alert-lime .alert-link {
    color: #2d3109 !important;
}
.border-lime {
    border-color: #cddc39;
}
.border-top-lime {
    border-top-color: #cddc39;
}
.border-bottom-lime {
    border-bottom-color: #cddc39;
}
[dir='ltr'] .border-left-lime {
    border-left-color: #cddc39;
}
[dir='ltr'] .border-right-lime,
[dir='rtl'] .border-left-lime {
    border-right-color: #cddc39;
}
[dir='rtl'] .border-right-lime {
    border-left-color: #cddc39;
}
.badge-lime {
    background-color: #cddc39;
}
.panel-lime {
    border-color: #cddc39;
}
.panel-lime .panel-heading {
    color: #fff;
    border-color: #cddc39;
    background-color: #d2e04f;
}
.bg-lime.tag-glow,
.border-lime.tag-glow {
    box-shadow: 0 0 10px #cddc39;
}
.overlay-lime {
    background: #cddc39;
    background: rgba(205, 220, 57, 0.8);
}
.card.card-outline-lime {
    border: 1px solid #cddc39;
}
.card.card-outline-lime,
.card.card-outline-lime .card-footer,
.card.card-outline-lime .card-header {
    background-color: initial;
}
.btn-lime.btn-flat {
    background-color: initial !important;
    color: #cddc39;
    border: none;
}
.btn-lime.btn-fab,
.btn-lime.btn-raised {
    background-color: #cddc39 !important;
    color: #fff !important;
    border-color: #cddc39;
}
.btn-lime.btn-fab.active,
.btn-lime.btn-raised.active {
    background-color: #becd24 !important;
    border-color: #becd24 !important;
}
.btn-group-raised .btn-lime {
    background-color: #cddc39 !important;
    color: #fff !important;
}
.btn-outline-lime {
    border: 1px solid #cddc39;
    background-color: initial;
    color: #cddc39;
    box-shadow: none !important;
}
.btn-outline-lime:focus {
    background-color: initial !important;
    color: #cddc39 !important;
    box-shadow: transparent !important;
}
.btn-outline-lime.active {
    background-color: #cddc39 !important;
    color: #fff !important;
}
.btn-outline-lime:hover {
    background-color: #cada2c !important;
    color: #fff !important;
}
.btn-outline-lime:hover svg {
    color: #fff !important;
}
.btn-outline-lime.btn-fab,
.btn-outline-lime.btn-raised {
    border: 1px solid #cddc39;
    background-color: initial;
    color: #cddc39;
    box-shadow: none !important;
}
.btn-outline-lime.btn-fab:focus,
.btn-outline-lime.btn-raised:focus {
    background-color: initial;
}
.btn-outline-lime.btn-fab.active,
.btn-outline-lime.btn-raised.active {
    background-color: #cddc39 !important;
    color: #fff !important;
}
.btn-outline-lime.btn-fab:hover,
.btn-outline-lime.btn-raised:hover {
    background-color: #cada2c !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-lime {
    background-color: #cddc39;
}
.progress .progress-bar.progress-bar-lime[value]::-webkit-progress-value {
    background-color: #cddc39;
}
.progress .progress-bar.progress-bar-lime[value]::-moz-progress-bar {
    background-color: #cddc39;
}
.progress .progress-bar.progress-bar-lime[value]::-ms-fill {
    background-color: #cddc39;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-lime .progress-bar {
        background-color: #cddc39;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #cddc39 !important;
}
.lime.darken-1 {
    color: #c0ca33 !important;
}
.bg-lime.bg-darken-1,
.btn-lime.btn-darken-1 {
    background-color: #c0ca33 !important;
}
.btn-lime.btn-darken-1,
.btn-lime.btn-darken-1:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-darken-1:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-1:active,
.btn-lime.btn-darken-1:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-darken-1 {
    border-color: #c0ca33 !important;
    color: #c0ca33 !important;
}
.btn-outline-lime.btn-outline-darken-1:hover {
    background-color: #c0ca33 !important;
}
.progress-lime.progress-darken-1 {
    background-color: #c0ca33;
}
.progress-lime.progress-darken-1[value]::-webkit-progress-value {
    background-color: #c0ca33;
}
.progress-lime.progress-darken-1[value]::-moz-progress-bar {
    background-color: #c0ca33;
}
.progress-lime.progress-darken-1[value]::-ms-fill {
    background-color: #c0ca33;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-darken-1 .progress-bar {
        background-color: #c0ca33;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c0ca33 !important;
}
.border-lime.border-darken-1 {
    border: 1px solid #c0ca33 !important;
}
.border-top-lime.border-top-darken-1 {
    border-top: 1px solid #c0ca33 !important;
}
.border-bottom-lime.border-bottom-darken-1 {
    border-bottom: 1px solid #c0ca33 !important;
}
[dir='ltr'] .border-left-lime.border-left-darken-1 {
    border-left: 1px solid #c0ca33 !important;
}
[dir='ltr'] .border-right-lime.border-right-darken-1,
[dir='rtl'] .border-left-lime.border-left-darken-1 {
    border-right: 1px solid #c0ca33 !important;
}
[dir='rtl'] .border-right-lime.border-right-darken-1 {
    border-left: 1px solid #c0ca33 !important;
}
.overlay-lime.overlay-darken-1 {
    background: #c0ca33;
    background: rgba(192, 202, 51, 0.8);
}
.lime.darken-2 {
    color: #afb42b !important;
}
.bg-lime.bg-darken-2,
.btn-lime.btn-darken-2 {
    background-color: #afb42b !important;
}
.btn-lime.btn-darken-2,
.btn-lime.btn-darken-2:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-darken-2:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-2:active,
.btn-lime.btn-darken-2:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-darken-2 {
    border-color: #afb42b !important;
    color: #afb42b !important;
}
.btn-outline-lime.btn-outline-darken-2:hover {
    background-color: #afb42b !important;
}
.progress-lime.progress-darken-2 {
    background-color: #afb42b;
}
.progress-lime.progress-darken-2[value]::-webkit-progress-value {
    background-color: #afb42b;
}
.progress-lime.progress-darken-2[value]::-moz-progress-bar {
    background-color: #afb42b;
}
.progress-lime.progress-darken-2[value]::-ms-fill {
    background-color: #afb42b;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-darken-2 .progress-bar {
        background-color: #afb42b;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #afb42b !important;
}
.border-lime.border-darken-2 {
    border: 1px solid #afb42b !important;
}
.border-top-lime.border-top-darken-2 {
    border-top: 1px solid #afb42b !important;
}
.border-bottom-lime.border-bottom-darken-2 {
    border-bottom: 1px solid #afb42b !important;
}
[dir='ltr'] .border-left-lime.border-left-darken-2 {
    border-left: 1px solid #afb42b !important;
}
[dir='ltr'] .border-right-lime.border-right-darken-2,
[dir='rtl'] .border-left-lime.border-left-darken-2 {
    border-right: 1px solid #afb42b !important;
}
[dir='rtl'] .border-right-lime.border-right-darken-2 {
    border-left: 1px solid #afb42b !important;
}
.overlay-lime.overlay-darken-2 {
    background: #afb42b;
    background: rgba(175, 180, 43, 0.8);
}
.lime.darken-3 {
    color: #9e9d24 !important;
}
.bg-lime.bg-darken-3 {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-3,
.btn-lime.btn-darken-3:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-3:active,
.btn-lime.btn-darken-3:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-darken-3 {
    border-color: #9e9d24 !important;
    color: #9e9d24 !important;
}
.btn-outline-lime.btn-outline-darken-3:hover {
    background-color: #9e9d24 !important;
}
.progress-lime.progress-darken-3 {
    background-color: #9e9d24;
}
.progress-lime.progress-darken-3[value]::-webkit-progress-value {
    background-color: #9e9d24;
}
.progress-lime.progress-darken-3[value]::-moz-progress-bar {
    background-color: #9e9d24;
}
.progress-lime.progress-darken-3[value]::-ms-fill {
    background-color: #9e9d24;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-darken-3 .progress-bar {
        background-color: #9e9d24;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9e9d24 !important;
}
.border-lime.border-darken-3 {
    border: 1px solid #9e9d24 !important;
}
.border-top-lime.border-top-darken-3 {
    border-top: 1px solid #9e9d24 !important;
}
.border-bottom-lime.border-bottom-darken-3 {
    border-bottom: 1px solid #9e9d24 !important;
}
[dir='ltr'] .border-left-lime.border-left-darken-3 {
    border-left: 1px solid #9e9d24 !important;
}
[dir='ltr'] .border-right-lime.border-right-darken-3,
[dir='rtl'] .border-left-lime.border-left-darken-3 {
    border-right: 1px solid #9e9d24 !important;
}
[dir='rtl'] .border-right-lime.border-right-darken-3 {
    border-left: 1px solid #9e9d24 !important;
}
.overlay-lime.overlay-darken-3 {
    background: #9e9d24;
    background: rgba(158, 157, 36, 0.8);
}
.lime.darken-4 {
    color: #827717 !important;
}
.bg-lime.bg-darken-4,
.btn-lime.btn-darken-4 {
    background-color: #827717 !important;
}
.btn-lime.btn-darken-4,
.btn-lime.btn-darken-4:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-darken-4:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-darken-4:active,
.btn-lime.btn-darken-4:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-darken-4 {
    border-color: #827717 !important;
    color: #827717 !important;
}
.btn-outline-lime.btn-outline-darken-4:hover {
    background-color: #827717 !important;
}
.progress-lime.progress-darken-4 {
    background-color: #827717;
}
.progress-lime.progress-darken-4[value]::-webkit-progress-value {
    background-color: #827717;
}
.progress-lime.progress-darken-4[value]::-moz-progress-bar {
    background-color: #827717;
}
.progress-lime.progress-darken-4[value]::-ms-fill {
    background-color: #827717;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-darken-4 .progress-bar {
        background-color: #827717;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #827717 !important;
}
.border-lime.border-darken-4 {
    border: 1px solid #827717 !important;
}
.border-top-lime.border-top-darken-4 {
    border-top: 1px solid #827717 !important;
}
.border-bottom-lime.border-bottom-darken-4 {
    border-bottom: 1px solid #827717 !important;
}
[dir='ltr'] .border-left-lime.border-left-darken-4 {
    border-left: 1px solid #827717 !important;
}
[dir='ltr'] .border-right-lime.border-right-darken-4,
[dir='rtl'] .border-left-lime.border-left-darken-4 {
    border-right: 1px solid #827717 !important;
}
[dir='rtl'] .border-right-lime.border-right-darken-4 {
    border-left: 1px solid #827717 !important;
}
.overlay-lime.overlay-darken-4 {
    background: #827717;
    background: rgba(130, 119, 23, 0.8);
}
.lime.accent-1 {
    color: #f4ff81 !important;
}
.bg-lime.bg-accent-1,
.btn-lime.btn-accent-1 {
    background-color: #f4ff81 !important;
}
.btn-lime.btn-accent-1,
.btn-lime.btn-accent-1:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-accent-1:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-accent-1:active,
.btn-lime.btn-accent-1:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-accent-1 {
    border-color: #f4ff81 !important;
    color: #f4ff81 !important;
}
.btn-outline-lime.btn-outline-accent-1:hover {
    background-color: #f4ff81 !important;
}
.progress-lime.progress-accent-1 {
    background-color: #f4ff81;
}
.progress-lime.progress-accent-1[value]::-webkit-progress-value {
    background-color: #f4ff81;
}
.progress-lime.progress-accent-1[value]::-moz-progress-bar {
    background-color: #f4ff81;
}
.progress-lime.progress-accent-1[value]::-ms-fill {
    background-color: #f4ff81;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-accent-1 .progress-bar {
        background-color: #f4ff81;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f4ff81 !important;
}
.border-lime.border-accent-1 {
    border: 1px solid #f4ff81 !important;
}
.border-top-lime.border-top-accent-1 {
    border-top: 1px solid #f4ff81 !important;
}
.border-bottom-lime.border-bottom-accent-1 {
    border-bottom: 1px solid #f4ff81 !important;
}
[dir='ltr'] .border-left-lime.border-left-accent-1 {
    border-left: 1px solid #f4ff81 !important;
}
[dir='ltr'] .border-right-lime.border-right-accent-1,
[dir='rtl'] .border-left-lime.border-left-accent-1 {
    border-right: 1px solid #f4ff81 !important;
}
[dir='rtl'] .border-right-lime.border-right-accent-1 {
    border-left: 1px solid #f4ff81 !important;
}
.overlay-lime.overlay-accent-1 {
    background: #f4ff81;
    background: rgba(244, 255, 129, 0.8);
}
.lime.accent-2 {
    color: #eeff41 !important;
}
.bg-lime.bg-accent-2,
.btn-lime.btn-accent-2 {
    background-color: #eeff41 !important;
}
.btn-lime.btn-accent-2,
.btn-lime.btn-accent-2:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-accent-2:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-accent-2:active,
.btn-lime.btn-accent-2:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-accent-2 {
    border-color: #eeff41 !important;
    color: #eeff41 !important;
}
.btn-outline-lime.btn-outline-accent-2:hover {
    background-color: #eeff41 !important;
}
.progress-lime.progress-accent-2 {
    background-color: #eeff41;
}
.progress-lime.progress-accent-2[value]::-webkit-progress-value {
    background-color: #eeff41;
}
.progress-lime.progress-accent-2[value]::-moz-progress-bar {
    background-color: #eeff41;
}
.progress-lime.progress-accent-2[value]::-ms-fill {
    background-color: #eeff41;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-accent-2 .progress-bar {
        background-color: #eeff41;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eeff41 !important;
}
.border-lime.border-accent-2 {
    border: 1px solid #eeff41 !important;
}
.border-top-lime.border-top-accent-2 {
    border-top: 1px solid #eeff41 !important;
}
.border-bottom-lime.border-bottom-accent-2 {
    border-bottom: 1px solid #eeff41 !important;
}
[dir='ltr'] .border-left-lime.border-left-accent-2 {
    border-left: 1px solid #eeff41 !important;
}
[dir='ltr'] .border-right-lime.border-right-accent-2,
[dir='rtl'] .border-left-lime.border-left-accent-2 {
    border-right: 1px solid #eeff41 !important;
}
[dir='rtl'] .border-right-lime.border-right-accent-2 {
    border-left: 1px solid #eeff41 !important;
}
.overlay-lime.overlay-accent-2 {
    background: #eeff41;
    background: rgba(238, 255, 65, 0.8);
}
.lime.accent-3 {
    color: #c6ff00 !important;
}
.bg-lime.bg-accent-3,
.btn-lime.btn-accent-3 {
    background-color: #c6ff00 !important;
}
.btn-lime.btn-accent-3,
.btn-lime.btn-accent-3:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-accent-3:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-accent-3:active,
.btn-lime.btn-accent-3:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-accent-3 {
    border-color: #c6ff00 !important;
    color: #c6ff00 !important;
}
.btn-outline-lime.btn-outline-accent-3:hover {
    background-color: #c6ff00 !important;
}
.progress-lime.progress-accent-3 {
    background-color: #c6ff00;
}
.progress-lime.progress-accent-3[value]::-webkit-progress-value {
    background-color: #c6ff00;
}
.progress-lime.progress-accent-3[value]::-moz-progress-bar {
    background-color: #c6ff00;
}
.progress-lime.progress-accent-3[value]::-ms-fill {
    background-color: #c6ff00;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-accent-3 .progress-bar {
        background-color: #c6ff00;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c6ff00 !important;
}
.border-lime.border-accent-3 {
    border: 1px solid #c6ff00 !important;
}
.border-top-lime.border-top-accent-3 {
    border-top: 1px solid #c6ff00 !important;
}
.border-bottom-lime.border-bottom-accent-3 {
    border-bottom: 1px solid #c6ff00 !important;
}
[dir='ltr'] .border-left-lime.border-left-accent-3 {
    border-left: 1px solid #c6ff00 !important;
}
[dir='ltr'] .border-right-lime.border-right-accent-3,
[dir='rtl'] .border-left-lime.border-left-accent-3 {
    border-right: 1px solid #c6ff00 !important;
}
[dir='rtl'] .border-right-lime.border-right-accent-3 {
    border-left: 1px solid #c6ff00 !important;
}
.overlay-lime.overlay-accent-3 {
    background: #c6ff00;
    background: rgba(198, 255, 0, 0.8);
}
.lime.accent-4 {
    color: #aeea00 !important;
}
.bg-lime.bg-accent-4,
.btn-lime.btn-accent-4 {
    background-color: #aeea00 !important;
}
.btn-lime.btn-accent-4,
.btn-lime.btn-accent-4:hover {
    border-color: #afb42b !important;
}
.btn-lime.btn-accent-4:hover {
    background-color: #9e9d24 !important;
}
.btn-lime.btn-accent-4:active,
.btn-lime.btn-accent-4:focus {
    border-color: #9e9d24 !important;
    background-color: #827717 !important;
}
.btn-outline-lime.btn-outline-accent-4 {
    border-color: #aeea00 !important;
    color: #aeea00 !important;
}
.btn-outline-lime.btn-outline-accent-4:hover {
    background-color: #aeea00 !important;
}
.progress-lime.progress-accent-4 {
    background-color: #aeea00;
}
.progress-lime.progress-accent-4[value]::-webkit-progress-value {
    background-color: #aeea00;
}
.progress-lime.progress-accent-4[value]::-moz-progress-bar {
    background-color: #aeea00;
}
.progress-lime.progress-accent-4[value]::-ms-fill {
    background-color: #aeea00;
}
@media screen and (min-width: 0\0) {
    .progress-lime.progress-accent-4 .progress-bar {
        background-color: #aeea00;
    }
}
input:focus ~ .bg-lime {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #aeea00 !important;
}
.border-lime.border-accent-4 {
    border: 1px solid #aeea00 !important;
}
.border-top-lime.border-top-accent-4 {
    border-top: 1px solid #aeea00 !important;
}
.border-bottom-lime.border-bottom-accent-4 {
    border-bottom: 1px solid #aeea00 !important;
}
[dir='ltr'] .border-left-lime.border-left-accent-4 {
    border-left: 1px solid #aeea00 !important;
}
[dir='ltr'] .border-right-lime.border-right-accent-4,
[dir='rtl'] .border-left-lime.border-left-accent-4 {
    border-right: 1px solid #aeea00 !important;
}
[dir='rtl'] .border-right-lime.border-right-accent-4 {
    border-left: 1px solid #aeea00 !important;
}
.overlay-lime.overlay-accent-4 {
    background: #aeea00;
    background: rgba(174, 234, 0, 0.8);
}
.yellow.lighten-5 {
    color: #fffde7 !important;
}
.bg-yellow.bg-lighten-5,
.btn-yellow.btn-lighten-5 {
    background-color: #fffde7 !important;
}
.btn-yellow.btn-lighten-5 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-lighten-5:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-5:active,
.btn-yellow.btn-lighten-5:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-lighten-5 {
    border-color: #fffde7 !important;
    color: #fffde7 !important;
}
.btn-outline-yellow.btn-outline-lighten-5:hover {
    background-color: #fffde7 !important;
}
.progress-yellow.progress-lighten-5 {
    background-color: #fffde7;
}
.progress-yellow.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fffde7;
}
.progress-yellow.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fffde7;
}
.progress-yellow.progress-lighten-5[value]::-ms-fill {
    background-color: #fffde7;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-lighten-5 .progress-bar {
        background-color: #fffde7;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fffde7 !important;
}
.border-yellow.border-lighten-5 {
    border: 1px solid #fffde7 !important;
}
.border-top-yellow.border-top-lighten-5 {
    border-top: 1px solid #fffde7 !important;
}
.border-bottom-yellow.border-bottom-lighten-5 {
    border-bottom: 1px solid #fffde7 !important;
}
[dir='ltr'] .border-left-yellow.border-left-lighten-5 {
    border-left: 1px solid #fffde7 !important;
}
[dir='ltr'] .border-right-yellow.border-right-lighten-5,
[dir='rtl'] .border-left-yellow.border-left-lighten-5 {
    border-right: 1px solid #fffde7 !important;
}
[dir='rtl'] .border-right-yellow.border-right-lighten-5 {
    border-left: 1px solid #fffde7 !important;
}
.overlay-yellow.overlay-lighten-5 {
    background: #fffde7;
    background: rgba(255, 253, 231, 0.8);
}
.yellow.lighten-4 {
    color: #fff9c4 !important;
}
.bg-yellow.bg-lighten-4,
.btn-yellow.btn-lighten-4 {
    background-color: #fff9c4 !important;
}
.btn-yellow.btn-lighten-4 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-lighten-4:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-4:active,
.btn-yellow.btn-lighten-4:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-lighten-4 {
    border-color: #fff9c4 !important;
    color: #fff9c4 !important;
}
.btn-outline-yellow.btn-outline-lighten-4:hover {
    background-color: #fff9c4 !important;
}
.progress-yellow.progress-lighten-4 {
    background-color: #fff9c4;
}
.progress-yellow.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #fff9c4;
}
.progress-yellow.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #fff9c4;
}
.progress-yellow.progress-lighten-4[value]::-ms-fill {
    background-color: #fff9c4;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-lighten-4 .progress-bar {
        background-color: #fff9c4;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff9c4 !important;
}
.border-yellow.border-lighten-4 {
    border: 1px solid #fff9c4 !important;
}
.border-top-yellow.border-top-lighten-4 {
    border-top: 1px solid #fff9c4 !important;
}
.border-bottom-yellow.border-bottom-lighten-4 {
    border-bottom: 1px solid #fff9c4 !important;
}
[dir='ltr'] .border-left-yellow.border-left-lighten-4 {
    border-left: 1px solid #fff9c4 !important;
}
[dir='ltr'] .border-right-yellow.border-right-lighten-4,
[dir='rtl'] .border-left-yellow.border-left-lighten-4 {
    border-right: 1px solid #fff9c4 !important;
}
[dir='rtl'] .border-right-yellow.border-right-lighten-4 {
    border-left: 1px solid #fff9c4 !important;
}
.overlay-yellow.overlay-lighten-4 {
    background: #fff9c4;
    background: rgba(255, 249, 196, 0.8);
}
.yellow.lighten-3 {
    color: #fff59d !important;
}
.bg-yellow.bg-lighten-3,
.btn-yellow.btn-lighten-3 {
    background-color: #fff59d !important;
}
.btn-yellow.btn-lighten-3 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-lighten-3:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-3:active,
.btn-yellow.btn-lighten-3:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-lighten-3 {
    border-color: #fff59d !important;
    color: #fff59d !important;
}
.btn-outline-yellow.btn-outline-lighten-3:hover {
    background-color: #fff59d !important;
}
.progress-yellow.progress-lighten-3 {
    background-color: #fff59d;
}
.progress-yellow.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #fff59d;
}
.progress-yellow.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #fff59d;
}
.progress-yellow.progress-lighten-3[value]::-ms-fill {
    background-color: #fff59d;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-lighten-3 .progress-bar {
        background-color: #fff59d;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff59d !important;
}
.border-yellow.border-lighten-3 {
    border: 1px solid #fff59d !important;
}
.border-top-yellow.border-top-lighten-3 {
    border-top: 1px solid #fff59d !important;
}
.border-bottom-yellow.border-bottom-lighten-3 {
    border-bottom: 1px solid #fff59d !important;
}
[dir='ltr'] .border-left-yellow.border-left-lighten-3 {
    border-left: 1px solid #fff59d !important;
}
[dir='ltr'] .border-right-yellow.border-right-lighten-3,
[dir='rtl'] .border-left-yellow.border-left-lighten-3 {
    border-right: 1px solid #fff59d !important;
}
[dir='rtl'] .border-right-yellow.border-right-lighten-3 {
    border-left: 1px solid #fff59d !important;
}
.overlay-yellow.overlay-lighten-3 {
    background: #fff59d;
    background: rgba(255, 245, 157, 0.8);
}
.yellow.lighten-2 {
    color: #fff176 !important;
}
.bg-yellow.bg-lighten-2,
.btn-yellow.btn-lighten-2 {
    background-color: #fff176 !important;
}
.btn-yellow.btn-lighten-2 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-lighten-2:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-2:active,
.btn-yellow.btn-lighten-2:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-lighten-2 {
    border-color: #fff176 !important;
    color: #fff176 !important;
}
.btn-outline-yellow.btn-outline-lighten-2:hover {
    background-color: #fff176 !important;
}
.progress-yellow.progress-lighten-2 {
    background-color: #fff176;
}
.progress-yellow.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #fff176;
}
.progress-yellow.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #fff176;
}
.progress-yellow.progress-lighten-2[value]::-ms-fill {
    background-color: #fff176;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-lighten-2 .progress-bar {
        background-color: #fff176;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff176 !important;
}
.border-yellow.border-lighten-2 {
    border: 1px solid #fff176 !important;
}
.border-top-yellow.border-top-lighten-2 {
    border-top: 1px solid #fff176 !important;
}
.border-bottom-yellow.border-bottom-lighten-2 {
    border-bottom: 1px solid #fff176 !important;
}
[dir='ltr'] .border-left-yellow.border-left-lighten-2 {
    border-left: 1px solid #fff176 !important;
}
[dir='ltr'] .border-right-yellow.border-right-lighten-2,
[dir='rtl'] .border-left-yellow.border-left-lighten-2 {
    border-right: 1px solid #fff176 !important;
}
[dir='rtl'] .border-right-yellow.border-right-lighten-2 {
    border-left: 1px solid #fff176 !important;
}
.overlay-yellow.overlay-lighten-2 {
    background: #fff176;
    background: rgba(255, 241, 118, 0.8);
}
.yellow.lighten-1 {
    color: #ffee58 !important;
}
.bg-yellow.bg-lighten-1,
.btn-yellow.btn-lighten-1 {
    background-color: #ffee58 !important;
}
.btn-yellow.btn-lighten-1 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-lighten-1:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-lighten-1:active,
.btn-yellow.btn-lighten-1:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-lighten-1 {
    border-color: #ffee58 !important;
    color: #ffee58 !important;
}
.btn-outline-yellow.btn-outline-lighten-1:hover {
    background-color: #ffee58 !important;
}
.progress-yellow.progress-lighten-1 {
    background-color: #ffee58;
}
.progress-yellow.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ffee58;
}
.progress-yellow.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ffee58;
}
.progress-yellow.progress-lighten-1[value]::-ms-fill {
    background-color: #ffee58;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-lighten-1 .progress-bar {
        background-color: #ffee58;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffee58 !important;
}
.border-yellow.border-lighten-1 {
    border: 1px solid #ffee58 !important;
}
.border-top-yellow.border-top-lighten-1 {
    border-top: 1px solid #ffee58 !important;
}
.border-bottom-yellow.border-bottom-lighten-1 {
    border-bottom: 1px solid #ffee58 !important;
}
[dir='ltr'] .border-left-yellow.border-left-lighten-1 {
    border-left: 1px solid #ffee58 !important;
}
[dir='ltr'] .border-right-yellow.border-right-lighten-1,
[dir='rtl'] .border-left-yellow.border-left-lighten-1 {
    border-right: 1px solid #ffee58 !important;
}
[dir='rtl'] .border-right-yellow.border-right-lighten-1 {
    border-left: 1px solid #ffee58 !important;
}
.overlay-yellow.overlay-lighten-1 {
    background: #ffee58;
    background: rgba(255, 238, 88, 0.8);
}
.yellow {
    color: #ffeb3b !important;
}
.bg-yellow {
    background-color: #ffeb3b !important;
}
.bg-yellow .card-footer,
.bg-yellow .card-header {
    background-color: initial;
}
.toast-yellow {
    background-color: #ffeb3b;
}
.alert-yellow {
    border-color: #ffeb3b !important;
    background-color: #ffef5f !important;
    color: #887a00 !important;
}
.alert-yellow .alert-link {
    color: #5f5500 !important;
}
.border-yellow {
    border-color: #ffeb3b;
}
.border-top-yellow {
    border-top-color: #ffeb3b;
}
.border-bottom-yellow {
    border-bottom-color: #ffeb3b;
}
[dir='ltr'] .border-left-yellow {
    border-left-color: #ffeb3b;
}
[dir='ltr'] .border-right-yellow,
[dir='rtl'] .border-left-yellow {
    border-right-color: #ffeb3b;
}
[dir='rtl'] .border-right-yellow {
    border-left-color: #ffeb3b;
}
.badge-yellow {
    background-color: #ffeb3b;
}
.panel-yellow {
    border-color: #ffeb3b;
}
.panel-yellow .panel-heading {
    color: #fff;
    border-color: #ffeb3b;
    background-color: #fe5;
}
.bg-yellow.tag-glow,
.border-yellow.tag-glow {
    box-shadow: 0 0 10px #ffeb3b;
}
.overlay-yellow {
    background: #ffeb3b;
    background: rgba(255, 235, 59, 0.8);
}
.card.card-outline-yellow {
    border: 1px solid #ffeb3b;
    background-color: initial;
}
.card.card-outline-yellow .card-footer,
.card.card-outline-yellow .card-header {
    background-color: initial;
}
.btn-yellow.btn-flat {
    background-color: initial !important;
    color: #ffeb3b;
    border: none;
}
.btn-yellow.btn-fab,
.btn-yellow.btn-raised {
    background-color: #ffeb3b !important;
    color: #fff !important;
    border-color: #ffeb3b;
}
.btn-yellow.btn-fab.active,
.btn-yellow.btn-raised.active {
    background-color: #ffe717 !important;
    border-color: #ffe717 !important;
}
.btn-group-raised .btn-yellow {
    background-color: #ffeb3b !important;
    color: #fff !important;
}
.btn-outline-yellow {
    border: 1px solid #ffeb3b;
    background-color: initial;
    color: #ffeb3b;
    box-shadow: none !important;
}
.btn-outline-yellow:focus {
    background-color: initial !important;
    color: #ffeb3b !important;
    box-shadow: transparent !important;
}
.btn-outline-yellow.active {
    background-color: #ffeb3b !important;
    color: #fff !important;
}
.btn-outline-yellow:hover {
    background-color: #ffe92c !important;
    color: #fff !important;
}
.btn-outline-yellow:hover svg {
    color: #fff !important;
}
.btn-outline-yellow.btn-fab,
.btn-outline-yellow.btn-raised {
    border: 1px solid #ffeb3b;
    background-color: initial;
    color: #ffeb3b;
    box-shadow: none !important;
}
.btn-outline-yellow.btn-fab:focus,
.btn-outline-yellow.btn-raised:focus {
    background-color: initial;
}
.btn-outline-yellow.btn-fab.active,
.btn-outline-yellow.btn-raised.active {
    background-color: #ffeb3b !important;
    color: #fff !important;
}
.btn-outline-yellow.btn-fab:hover,
.btn-outline-yellow.btn-raised:hover {
    background-color: #ffe92c !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-yellow {
    background-color: #ffeb3b;
}
.progress .progress-bar.progress-bar-yellow[value]::-webkit-progress-value {
    background-color: #ffeb3b;
}
.progress .progress-bar.progress-bar-yellow[value]::-moz-progress-bar {
    background-color: #ffeb3b;
}
.progress .progress-bar.progress-bar-yellow[value]::-ms-fill {
    background-color: #ffeb3b;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-yellow .progress-bar {
        background-color: #ffeb3b;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffeb3b !important;
}
.yellow.darken-1 {
    color: #fdd835 !important;
}
.bg-yellow.bg-darken-1,
.btn-yellow.btn-darken-1 {
    background-color: #fdd835 !important;
}
.btn-yellow.btn-darken-1 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-darken-1:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-1:active,
.btn-yellow.btn-darken-1:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-darken-1 {
    border-color: #fdd835 !important;
    color: #fdd835 !important;
}
.btn-outline-yellow.btn-outline-darken-1:hover {
    background-color: #fdd835 !important;
}
.progress-yellow.progress-darken-1 {
    background-color: #fdd835;
}
.progress-yellow.progress-darken-1[value]::-webkit-progress-value {
    background-color: #fdd835;
}
.progress-yellow.progress-darken-1[value]::-moz-progress-bar {
    background-color: #fdd835;
}
.progress-yellow.progress-darken-1[value]::-ms-fill {
    background-color: #fdd835;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-darken-1 .progress-bar {
        background-color: #fdd835;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fdd835 !important;
}
.border-yellow.border-darken-1 {
    border: 1px solid #fdd835 !important;
}
.border-top-yellow.border-top-darken-1 {
    border-top: 1px solid #fdd835 !important;
}
.border-bottom-yellow.border-bottom-darken-1 {
    border-bottom: 1px solid #fdd835 !important;
}
[dir='ltr'] .border-left-yellow.border-left-darken-1 {
    border-left: 1px solid #fdd835 !important;
}
[dir='ltr'] .border-right-yellow.border-right-darken-1,
[dir='rtl'] .border-left-yellow.border-left-darken-1 {
    border-right: 1px solid #fdd835 !important;
}
[dir='rtl'] .border-right-yellow.border-right-darken-1 {
    border-left: 1px solid #fdd835 !important;
}
.overlay-yellow.overlay-darken-1 {
    background: #fdd835;
    background: rgba(253, 216, 53, 0.8);
}
.yellow.darken-2 {
    color: #fbc02d !important;
}
.bg-yellow.bg-darken-2,
.btn-yellow.btn-darken-2 {
    background-color: #fbc02d !important;
}
.btn-yellow.btn-darken-2 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-darken-2:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-2:active,
.btn-yellow.btn-darken-2:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-darken-2 {
    border-color: #fbc02d !important;
    color: #fbc02d !important;
}
.btn-outline-yellow.btn-outline-darken-2:hover {
    background-color: #fbc02d !important;
}
.progress-yellow.progress-darken-2 {
    background-color: #fbc02d;
}
.progress-yellow.progress-darken-2[value]::-webkit-progress-value {
    background-color: #fbc02d;
}
.progress-yellow.progress-darken-2[value]::-moz-progress-bar {
    background-color: #fbc02d;
}
.progress-yellow.progress-darken-2[value]::-ms-fill {
    background-color: #fbc02d;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-darken-2 .progress-bar {
        background-color: #fbc02d;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fbc02d !important;
}
.border-yellow.border-darken-2 {
    border: 1px solid #fbc02d !important;
}
.border-top-yellow.border-top-darken-2 {
    border-top: 1px solid #fbc02d !important;
}
.border-bottom-yellow.border-bottom-darken-2 {
    border-bottom: 1px solid #fbc02d !important;
}
[dir='ltr'] .border-left-yellow.border-left-darken-2 {
    border-left: 1px solid #fbc02d !important;
}
[dir='ltr'] .border-right-yellow.border-right-darken-2,
[dir='rtl'] .border-left-yellow.border-left-darken-2 {
    border-right: 1px solid #fbc02d !important;
}
[dir='rtl'] .border-right-yellow.border-right-darken-2 {
    border-left: 1px solid #fbc02d !important;
}
.overlay-yellow.overlay-darken-2 {
    background: #fbc02d;
    background: rgba(251, 192, 45, 0.8);
}
.yellow.darken-3 {
    color: #f9a825 !important;
}
.bg-yellow.bg-darken-3 {
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-3,
.btn-yellow.btn-darken-3:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-3:active,
.btn-yellow.btn-darken-3:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-darken-3 {
    border-color: #f9a825 !important;
    color: #f9a825 !important;
}
.btn-outline-yellow.btn-outline-darken-3:hover {
    background-color: #f9a825 !important;
}
.progress-yellow.progress-darken-3 {
    background-color: #f9a825;
}
.progress-yellow.progress-darken-3[value]::-webkit-progress-value {
    background-color: #f9a825;
}
.progress-yellow.progress-darken-3[value]::-moz-progress-bar {
    background-color: #f9a825;
}
.progress-yellow.progress-darken-3[value]::-ms-fill {
    background-color: #f9a825;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-darken-3 .progress-bar {
        background-color: #f9a825;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f9a825 !important;
}
.border-yellow.border-darken-3 {
    border: 1px solid #f9a825 !important;
}
.border-top-yellow.border-top-darken-3 {
    border-top: 1px solid #f9a825 !important;
}
.border-bottom-yellow.border-bottom-darken-3 {
    border-bottom: 1px solid #f9a825 !important;
}
[dir='ltr'] .border-left-yellow.border-left-darken-3 {
    border-left: 1px solid #f9a825 !important;
}
[dir='ltr'] .border-right-yellow.border-right-darken-3,
[dir='rtl'] .border-left-yellow.border-left-darken-3 {
    border-right: 1px solid #f9a825 !important;
}
[dir='rtl'] .border-right-yellow.border-right-darken-3 {
    border-left: 1px solid #f9a825 !important;
}
.overlay-yellow.overlay-darken-3 {
    background: #f9a825;
    background: rgba(249, 168, 37, 0.8);
}
.yellow.darken-4 {
    color: #f57f17 !important;
}
.bg-yellow.bg-darken-4,
.btn-yellow.btn-darken-4 {
    background-color: #f57f17 !important;
}
.btn-yellow.btn-darken-4 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-darken-4:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-darken-4:active,
.btn-yellow.btn-darken-4:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-darken-4 {
    border-color: #f57f17 !important;
    color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-darken-4:hover {
    background-color: #f57f17 !important;
}
.progress-yellow.progress-darken-4 {
    background-color: #f57f17;
}
.progress-yellow.progress-darken-4[value]::-webkit-progress-value {
    background-color: #f57f17;
}
.progress-yellow.progress-darken-4[value]::-moz-progress-bar {
    background-color: #f57f17;
}
.progress-yellow.progress-darken-4[value]::-ms-fill {
    background-color: #f57f17;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-darken-4 .progress-bar {
        background-color: #f57f17;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f57f17 !important;
}
.border-yellow.border-darken-4 {
    border: 1px solid #f57f17 !important;
}
.border-top-yellow.border-top-darken-4 {
    border-top: 1px solid #f57f17 !important;
}
.border-bottom-yellow.border-bottom-darken-4 {
    border-bottom: 1px solid #f57f17 !important;
}
[dir='ltr'] .border-left-yellow.border-left-darken-4 {
    border-left: 1px solid #f57f17 !important;
}
[dir='ltr'] .border-right-yellow.border-right-darken-4,
[dir='rtl'] .border-left-yellow.border-left-darken-4 {
    border-right: 1px solid #f57f17 !important;
}
[dir='rtl'] .border-right-yellow.border-right-darken-4 {
    border-left: 1px solid #f57f17 !important;
}
.overlay-yellow.overlay-darken-4 {
    background: #f57f17;
    background: rgba(245, 127, 23, 0.8);
}
.yellow.accent-1 {
    color: #ffff8d !important;
}
.bg-yellow.bg-accent-1,
.btn-yellow.btn-accent-1 {
    background-color: #ffff8d !important;
}
.btn-yellow.btn-accent-1 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-accent-1:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-1:active,
.btn-yellow.btn-accent-1:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-accent-1 {
    border-color: #ffff8d !important;
    color: #ffff8d !important;
}
.btn-outline-yellow.btn-outline-accent-1:hover {
    background-color: #ffff8d !important;
}
.progress-yellow.progress-accent-1 {
    background-color: #ffff8d;
}
.progress-yellow.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ffff8d;
}
.progress-yellow.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ffff8d;
}
.progress-yellow.progress-accent-1[value]::-ms-fill {
    background-color: #ffff8d;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-accent-1 .progress-bar {
        background-color: #ffff8d;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffff8d !important;
}
.border-yellow.border-accent-1 {
    border: 1px solid #ffff8d !important;
}
.border-top-yellow.border-top-accent-1 {
    border-top: 1px solid #ffff8d !important;
}
.border-bottom-yellow.border-bottom-accent-1 {
    border-bottom: 1px solid #ffff8d !important;
}
[dir='ltr'] .border-left-yellow.border-left-accent-1 {
    border-left: 1px solid #ffff8d !important;
}
[dir='ltr'] .border-right-yellow.border-right-accent-1,
[dir='rtl'] .border-left-yellow.border-left-accent-1 {
    border-right: 1px solid #ffff8d !important;
}
[dir='rtl'] .border-right-yellow.border-right-accent-1 {
    border-left: 1px solid #ffff8d !important;
}
.overlay-yellow.overlay-accent-1 {
    background: #ffff8d;
    background: rgba(255, 255, 141, 0.8);
}
.yellow.accent-2 {
    color: #ff0 !important;
}
.bg-yellow.bg-accent-2,
.btn-yellow.btn-accent-2 {
    background-color: #ff0 !important;
}
.btn-yellow.btn-accent-2 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-accent-2:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-2:active,
.btn-yellow.btn-accent-2:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-accent-2 {
    border-color: #ff0 !important;
    color: #ff0 !important;
}
.btn-outline-yellow.btn-outline-accent-2:hover {
    background-color: #ff0 !important;
}
.progress-yellow.progress-accent-2 {
    background-color: #ff0;
}
.progress-yellow.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff0;
}
.progress-yellow.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff0;
}
.progress-yellow.progress-accent-2[value]::-ms-fill {
    background-color: #ff0;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-accent-2 .progress-bar {
        background-color: #ff0;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff0 !important;
}
.border-yellow.border-accent-2 {
    border: 1px solid #ff0 !important;
}
.border-top-yellow.border-top-accent-2 {
    border-top: 1px solid #ff0 !important;
}
.border-bottom-yellow.border-bottom-accent-2 {
    border-bottom: 1px solid #ff0 !important;
}
[dir='ltr'] .border-left-yellow.border-left-accent-2 {
    border-left: 1px solid #ff0 !important;
}
[dir='ltr'] .border-right-yellow.border-right-accent-2,
[dir='rtl'] .border-left-yellow.border-left-accent-2 {
    border-right: 1px solid #ff0 !important;
}
[dir='rtl'] .border-right-yellow.border-right-accent-2 {
    border-left: 1px solid #ff0 !important;
}
.overlay-yellow.overlay-accent-2 {
    background: #ff0;
    background: rgba(255, 255, 0, 0.8);
}
.yellow.accent-3 {
    color: #ffea00 !important;
}
.bg-yellow.bg-accent-3,
.btn-yellow.btn-accent-3 {
    background-color: #ffea00 !important;
}
.btn-yellow.btn-accent-3 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-accent-3:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-3:active,
.btn-yellow.btn-accent-3:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-accent-3 {
    border-color: #ffea00 !important;
    color: #ffea00 !important;
}
.btn-outline-yellow.btn-outline-accent-3:hover {
    background-color: #ffea00 !important;
}
.progress-yellow.progress-accent-3 {
    background-color: #ffea00;
}
.progress-yellow.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ffea00;
}
.progress-yellow.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ffea00;
}
.progress-yellow.progress-accent-3[value]::-ms-fill {
    background-color: #ffea00;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-accent-3 .progress-bar {
        background-color: #ffea00;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffea00 !important;
}
.border-yellow.border-accent-3 {
    border: 1px solid #ffea00 !important;
}
.border-top-yellow.border-top-accent-3 {
    border-top: 1px solid #ffea00 !important;
}
.border-bottom-yellow.border-bottom-accent-3 {
    border-bottom: 1px solid #ffea00 !important;
}
[dir='ltr'] .border-left-yellow.border-left-accent-3 {
    border-left: 1px solid #ffea00 !important;
}
[dir='ltr'] .border-right-yellow.border-right-accent-3,
[dir='rtl'] .border-left-yellow.border-left-accent-3 {
    border-right: 1px solid #ffea00 !important;
}
[dir='rtl'] .border-right-yellow.border-right-accent-3 {
    border-left: 1px solid #ffea00 !important;
}
.overlay-yellow.overlay-accent-3 {
    background: #ffea00;
    background: rgba(255, 234, 0, 0.8);
}
.yellow.accent-4 {
    color: #ffd600 !important;
}
.bg-yellow.bg-accent-4,
.btn-yellow.btn-accent-4 {
    background-color: #ffd600 !important;
}
.btn-yellow.btn-accent-4 {
    border-color: #fbc02d !important;
}
.btn-yellow.btn-accent-4:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important;
}
.btn-yellow.btn-accent-4:active,
.btn-yellow.btn-accent-4:focus {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important;
}
.btn-outline-yellow.btn-outline-accent-4 {
    border-color: #ffd600 !important;
    color: #ffd600 !important;
}
.btn-outline-yellow.btn-outline-accent-4:hover {
    background-color: #ffd600 !important;
}
.progress-yellow.progress-accent-4 {
    background-color: #ffd600;
}
.progress-yellow.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ffd600;
}
.progress-yellow.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ffd600;
}
.progress-yellow.progress-accent-4[value]::-ms-fill {
    background-color: #ffd600;
}
@media screen and (min-width: 0\0) {
    .progress-yellow.progress-accent-4 .progress-bar {
        background-color: #ffd600;
    }
}
input:focus ~ .bg-yellow {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd600 !important;
}
.border-yellow.border-accent-4 {
    border: 1px solid #ffd600 !important;
}
.border-top-yellow.border-top-accent-4 {
    border-top: 1px solid #ffd600 !important;
}
.border-bottom-yellow.border-bottom-accent-4 {
    border-bottom: 1px solid #ffd600 !important;
}
[dir='ltr'] .border-left-yellow.border-left-accent-4 {
    border-left: 1px solid #ffd600 !important;
}
[dir='ltr'] .border-right-yellow.border-right-accent-4,
[dir='rtl'] .border-left-yellow.border-left-accent-4 {
    border-right: 1px solid #ffd600 !important;
}
[dir='rtl'] .border-right-yellow.border-right-accent-4 {
    border-left: 1px solid #ffd600 !important;
}
.overlay-yellow.overlay-accent-4 {
    background: #ffd600;
    background: rgba(255, 214, 0, 0.8);
}
.amber.lighten-5 {
    color: #fff8e1 !important;
}
.bg-amber.bg-lighten-5,
.btn-amber.btn-lighten-5 {
    background-color: #fff8e1 !important;
}
.btn-amber.btn-lighten-5 {
    border-color: #ffa000 !important;
}
.btn-amber.btn-lighten-5:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-5:active,
.btn-amber.btn-lighten-5:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-lighten-5 {
    border-color: #fff8e1 !important;
    color: #fff8e1 !important;
}
.btn-outline-amber.btn-outline-lighten-5:hover {
    background-color: #fff8e1 !important;
}
.progress-amber.progress-lighten-5 {
    background-color: #fff8e1;
}
.progress-amber.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fff8e1;
}
.progress-amber.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fff8e1;
}
.progress-amber.progress-lighten-5[value]::-ms-fill {
    background-color: #fff8e1;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-lighten-5 .progress-bar {
        background-color: #fff8e1;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff8e1 !important;
}
.border-amber.border-lighten-5 {
    border: 1px solid #fff8e1 !important;
}
.border-top-amber.border-top-lighten-5 {
    border-top: 1px solid #fff8e1 !important;
}
.border-bottom-amber.border-bottom-lighten-5 {
    border-bottom: 1px solid #fff8e1 !important;
}
[dir='ltr'] .border-left-amber.border-left-lighten-5 {
    border-left: 1px solid #fff8e1 !important;
}
[dir='ltr'] .border-right-amber.border-right-lighten-5,
[dir='rtl'] .border-left-amber.border-left-lighten-5 {
    border-right: 1px solid #fff8e1 !important;
}
[dir='rtl'] .border-right-amber.border-right-lighten-5 {
    border-left: 1px solid #fff8e1 !important;
}
.overlay-amber.overlay-lighten-5 {
    background: #fff8e1;
    background: rgba(255, 248, 225, 0.8);
}
.amber.lighten-4 {
    color: #ffecb3 !important;
}
.bg-amber.bg-lighten-4,
.btn-amber.btn-lighten-4 {
    background-color: #ffecb3 !important;
}
.btn-amber.btn-lighten-4 {
    border-color: #ffa000 !important;
}
.btn-amber.btn-lighten-4:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-4:active,
.btn-amber.btn-lighten-4:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-lighten-4 {
    border-color: #ffecb3 !important;
    color: #ffecb3 !important;
}
.btn-outline-amber.btn-outline-lighten-4:hover {
    background-color: #ffecb3 !important;
}
.progress-amber.progress-lighten-4 {
    background-color: #ffecb3;
}
.progress-amber.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffecb3;
}
.progress-amber.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffecb3;
}
.progress-amber.progress-lighten-4[value]::-ms-fill {
    background-color: #ffecb3;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-lighten-4 .progress-bar {
        background-color: #ffecb3;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffecb3 !important;
}
.border-amber.border-lighten-4 {
    border: 1px solid #ffecb3 !important;
}
.border-top-amber.border-top-lighten-4 {
    border-top: 1px solid #ffecb3 !important;
}
.border-bottom-amber.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffecb3 !important;
}
[dir='ltr'] .border-left-amber.border-left-lighten-4 {
    border-left: 1px solid #ffecb3 !important;
}
[dir='ltr'] .border-right-amber.border-right-lighten-4,
[dir='rtl'] .border-left-amber.border-left-lighten-4 {
    border-right: 1px solid #ffecb3 !important;
}
[dir='rtl'] .border-right-amber.border-right-lighten-4 {
    border-left: 1px solid #ffecb3 !important;
}
.overlay-amber.overlay-lighten-4 {
    background: #ffecb3;
    background: rgba(255, 236, 179, 0.8);
}
.amber.lighten-3 {
    color: #ffe082 !important;
}
.bg-amber.bg-lighten-3,
.btn-amber.btn-lighten-3 {
    background-color: #ffe082 !important;
}
.btn-amber.btn-lighten-3 {
    border-color: #ffa000 !important;
}
.btn-amber.btn-lighten-3:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-3:active,
.btn-amber.btn-lighten-3:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-lighten-3 {
    border-color: #ffe082 !important;
    color: #ffe082 !important;
}
.btn-outline-amber.btn-outline-lighten-3:hover {
    background-color: #ffe082 !important;
}
.progress-amber.progress-lighten-3 {
    background-color: #ffe082;
}
.progress-amber.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffe082;
}
.progress-amber.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffe082;
}
.progress-amber.progress-lighten-3[value]::-ms-fill {
    background-color: #ffe082;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-lighten-3 .progress-bar {
        background-color: #ffe082;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe082 !important;
}
.border-amber.border-lighten-3 {
    border: 1px solid #ffe082 !important;
}
.border-top-amber.border-top-lighten-3 {
    border-top: 1px solid #ffe082 !important;
}
.border-bottom-amber.border-bottom-lighten-3 {
    border-bottom: 1px solid #ffe082 !important;
}
[dir='ltr'] .border-left-amber.border-left-lighten-3 {
    border-left: 1px solid #ffe082 !important;
}
[dir='ltr'] .border-right-amber.border-right-lighten-3,
[dir='rtl'] .border-left-amber.border-left-lighten-3 {
    border-right: 1px solid #ffe082 !important;
}
[dir='rtl'] .border-right-amber.border-right-lighten-3 {
    border-left: 1px solid #ffe082 !important;
}
.overlay-amber.overlay-lighten-3 {
    background: #ffe082;
    background: rgba(255, 224, 130, 0.8);
}
.amber.lighten-2 {
    color: #ffd54f !important;
}
.bg-amber.bg-lighten-2,
.btn-amber.btn-lighten-2 {
    background-color: #ffd54f !important;
}
.btn-amber.btn-lighten-2 {
    border-color: #ffa000 !important;
}
.btn-amber.btn-lighten-2:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-2:active,
.btn-amber.btn-lighten-2:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-lighten-2 {
    border-color: #ffd54f !important;
    color: #ffd54f !important;
}
.btn-outline-amber.btn-outline-lighten-2:hover {
    background-color: #ffd54f !important;
}
.progress-amber.progress-lighten-2 {
    background-color: #ffd54f;
}
.progress-amber.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ffd54f;
}
.progress-amber.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ffd54f;
}
.progress-amber.progress-lighten-2[value]::-ms-fill {
    background-color: #ffd54f;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-lighten-2 .progress-bar {
        background-color: #ffd54f;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd54f !important;
}
.border-amber.border-lighten-2 {
    border: 1px solid #ffd54f !important;
}
.border-top-amber.border-top-lighten-2 {
    border-top: 1px solid #ffd54f !important;
}
.border-bottom-amber.border-bottom-lighten-2 {
    border-bottom: 1px solid #ffd54f !important;
}
[dir='ltr'] .border-left-amber.border-left-lighten-2 {
    border-left: 1px solid #ffd54f !important;
}
[dir='ltr'] .border-right-amber.border-right-lighten-2,
[dir='rtl'] .border-left-amber.border-left-lighten-2 {
    border-right: 1px solid #ffd54f !important;
}
[dir='rtl'] .border-right-amber.border-right-lighten-2 {
    border-left: 1px solid #ffd54f !important;
}
.overlay-amber.overlay-lighten-2 {
    background: #ffd54f;
    background: rgba(255, 213, 79, 0.8);
}
.amber.lighten-1 {
    color: #ffca28 !important;
}
.bg-amber.bg-lighten-1,
.btn-amber.btn-lighten-1 {
    background-color: #ffca28 !important;
}
.btn-amber.btn-lighten-1 {
    border-color: #ffa000 !important;
}
.btn-amber.btn-lighten-1:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-lighten-1:active,
.btn-amber.btn-lighten-1:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-lighten-1 {
    border-color: #ffca28 !important;
    color: #ffca28 !important;
}
.btn-outline-amber.btn-outline-lighten-1:hover {
    background-color: #ffca28 !important;
}
.progress-amber.progress-lighten-1 {
    background-color: #ffca28;
}
.progress-amber.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ffca28;
}
.progress-amber.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ffca28;
}
.progress-amber.progress-lighten-1[value]::-ms-fill {
    background-color: #ffca28;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-lighten-1 .progress-bar {
        background-color: #ffca28;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffca28 !important;
}
.border-amber.border-lighten-1 {
    border: 1px solid #ffca28 !important;
}
.border-top-amber.border-top-lighten-1 {
    border-top: 1px solid #ffca28 !important;
}
.border-bottom-amber.border-bottom-lighten-1 {
    border-bottom: 1px solid #ffca28 !important;
}
[dir='ltr'] .border-left-amber.border-left-lighten-1 {
    border-left: 1px solid #ffca28 !important;
}
[dir='ltr'] .border-right-amber.border-right-lighten-1,
[dir='rtl'] .border-left-amber.border-left-lighten-1 {
    border-right: 1px solid #ffca28 !important;
}
[dir='rtl'] .border-right-amber.border-right-lighten-1 {
    border-left: 1px solid #ffca28 !important;
}
.overlay-amber.overlay-lighten-1 {
    background: #ffca28;
    background: rgba(255, 202, 40, 0.8);
}
.amber {
    color: #ffc107 !important;
}
.bg-amber {
    background-color: #ffc107 !important;
}
.bg-amber .card-footer,
.bg-amber .card-header {
    background-color: initial;
}
.toast-amber {
    background-color: #ffc107;
}
.alert-amber {
    border-color: #ffc107 !important;
    background-color: #ffca2b !important;
    color: #543f00 !important;
}
.alert-amber .alert-link {
    color: #2b2000 !important;
}
.border-amber {
    border-color: #ffc107;
}
.border-top-amber {
    border-top-color: #ffc107;
}
.border-bottom-amber {
    border-bottom-color: #ffc107;
}
[dir='ltr'] .border-left-amber {
    border-left-color: #ffc107;
}
[dir='ltr'] .border-right-amber,
[dir='rtl'] .border-left-amber {
    border-right-color: #ffc107;
}
[dir='rtl'] .border-right-amber {
    border-left-color: #ffc107;
}
.badge-amber {
    background-color: #ffc107;
}
.panel-amber {
    border-color: #ffc107;
}
.panel-amber .panel-heading {
    color: #fff;
    border-color: #ffc107;
    background-color: #ffc721;
}
.bg-amber.tag-glow,
.border-amber.tag-glow {
    box-shadow: 0 0 10px #ffc107;
}
.overlay-amber {
    background: #ffc107;
    background: rgba(255, 193, 7, 0.8);
}
.card.card-outline-amber {
    border: 1px solid #ffc107;
    background-color: initial;
}
.card.card-outline-amber .card-footer,
.card.card-outline-amber .card-header {
    background-color: initial;
}
.btn-amber.btn-flat {
    background-color: initial !important;
    color: #ffc107;
    border: none;
}
.btn-amber.btn-fab,
.btn-amber.btn-raised {
    background-color: #ffc107 !important;
    color: #fff !important;
    border-color: #ffc107;
}
.btn-amber.btn-fab.active,
.btn-amber.btn-raised.active {
    background-color: #e2aa00 !important;
    border-color: #e2aa00 !important;
}
.btn-group-raised .btn-amber {
    background-color: #ffc107 !important;
    color: #fff !important;
}
.btn-outline-amber {
    border: 1px solid #ffc107;
    background-color: initial;
    color: #ffc107;
    box-shadow: none !important;
}
.btn-outline-amber:focus {
    background-color: initial !important;
    color: #ffc107 !important;
    box-shadow: transparent !important;
}
.btn-outline-amber.active {
    background-color: #ffc107 !important;
    color: #fff !important;
}
.btn-outline-amber:hover {
    background-color: #f7b900 !important;
    color: #fff !important;
}
.btn-outline-amber:hover svg {
    color: #fff !important;
}
.btn-outline-amber.btn-fab,
.btn-outline-amber.btn-raised {
    border: 1px solid #ffc107;
    background-color: initial;
    color: #ffc107;
    box-shadow: none !important;
}
.btn-outline-amber.btn-fab:focus,
.btn-outline-amber.btn-raised:focus {
    background-color: initial;
}
.btn-outline-amber.btn-fab.active,
.btn-outline-amber.btn-raised.active {
    background-color: #ffc107 !important;
    color: #fff !important;
}
.btn-outline-amber.btn-fab:hover,
.btn-outline-amber.btn-raised:hover {
    background-color: #f7b900 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-amber {
    background-color: #ffc107;
}
.progress .progress-bar.progress-bar-amber[value]::-webkit-progress-value {
    background-color: #ffc107;
}
.progress .progress-bar.progress-bar-amber[value]::-moz-progress-bar {
    background-color: #ffc107;
}
.progress .progress-bar.progress-bar-amber[value]::-ms-fill {
    background-color: #ffc107;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-amber .progress-bar {
        background-color: #ffc107;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffc107 !important;
}
.amber.darken-1 {
    color: #ffb300 !important;
}
.bg-amber.bg-darken-1,
.btn-amber.btn-darken-1 {
    background-color: #ffb300 !important;
}
.btn-amber.btn-darken-1,
.btn-amber.btn-darken-1:hover {
    border-color: #ffa000 !important;
}
.btn-amber.btn-darken-1:hover {
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-1:active,
.btn-amber.btn-darken-1:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-darken-1 {
    border-color: #ffb300 !important;
    color: #ffb300 !important;
}
.btn-outline-amber.btn-outline-darken-1:hover {
    background-color: #ffb300 !important;
}
.progress-amber.progress-darken-1 {
    background-color: #ffb300;
}
.progress-amber.progress-darken-1[value]::-webkit-progress-value {
    background-color: #ffb300;
}
.progress-amber.progress-darken-1[value]::-moz-progress-bar {
    background-color: #ffb300;
}
.progress-amber.progress-darken-1[value]::-ms-fill {
    background-color: #ffb300;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-darken-1 .progress-bar {
        background-color: #ffb300;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffb300 !important;
}
.border-amber.border-darken-1 {
    border: 1px solid #ffb300 !important;
}
.border-top-amber.border-top-darken-1 {
    border-top: 1px solid #ffb300 !important;
}
.border-bottom-amber.border-bottom-darken-1 {
    border-bottom: 1px solid #ffb300 !important;
}
[dir='ltr'] .border-left-amber.border-left-darken-1 {
    border-left: 1px solid #ffb300 !important;
}
[dir='ltr'] .border-right-amber.border-right-darken-1,
[dir='rtl'] .border-left-amber.border-left-darken-1 {
    border-right: 1px solid #ffb300 !important;
}
[dir='rtl'] .border-right-amber.border-right-darken-1 {
    border-left: 1px solid #ffb300 !important;
}
.overlay-amber.overlay-darken-1 {
    background: #ffb300;
    background: rgba(255, 179, 0, 0.8);
}
.amber.darken-2 {
    color: #ffa000 !important;
}
.bg-amber.bg-darken-2,
.btn-amber.btn-darken-2 {
    background-color: #ffa000 !important;
}
.btn-amber.btn-darken-2,
.btn-amber.btn-darken-2:hover {
    border-color: #ffa000 !important;
}
.btn-amber.btn-darken-2:hover {
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-2:active,
.btn-amber.btn-darken-2:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-darken-2 {
    border-color: #ffa000 !important;
    color: #ffa000 !important;
}
.btn-outline-amber.btn-outline-darken-2:hover {
    background-color: #ffa000 !important;
}
.progress-amber.progress-darken-2 {
    background-color: #ffa000;
}
.progress-amber.progress-darken-2[value]::-webkit-progress-value {
    background-color: #ffa000;
}
.progress-amber.progress-darken-2[value]::-moz-progress-bar {
    background-color: #ffa000;
}
.progress-amber.progress-darken-2[value]::-ms-fill {
    background-color: #ffa000;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-darken-2 .progress-bar {
        background-color: #ffa000;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffa000 !important;
}
.border-amber.border-darken-2 {
    border: 1px solid #ffa000 !important;
}
.border-top-amber.border-top-darken-2 {
    border-top: 1px solid #ffa000 !important;
}
.border-bottom-amber.border-bottom-darken-2 {
    border-bottom: 1px solid #ffa000 !important;
}
[dir='ltr'] .border-left-amber.border-left-darken-2 {
    border-left: 1px solid #ffa000 !important;
}
[dir='ltr'] .border-right-amber.border-right-darken-2,
[dir='rtl'] .border-left-amber.border-left-darken-2 {
    border-right: 1px solid #ffa000 !important;
}
[dir='rtl'] .border-right-amber.border-right-darken-2 {
    border-left: 1px solid #ffa000 !important;
}
.overlay-amber.overlay-darken-2 {
    background: #ffa000;
    background: rgba(255, 160, 0, 0.8);
}
.amber.darken-3 {
    color: #ff8f00 !important;
}
.bg-amber.bg-darken-3 {
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-3,
.btn-amber.btn-darken-3:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-3:active,
.btn-amber.btn-darken-3:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-darken-3 {
    border-color: #ff8f00 !important;
    color: #ff8f00 !important;
}
.btn-outline-amber.btn-outline-darken-3:hover {
    background-color: #ff8f00 !important;
}
.progress-amber.progress-darken-3 {
    background-color: #ff8f00;
}
.progress-amber.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ff8f00;
}
.progress-amber.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ff8f00;
}
.progress-amber.progress-darken-3[value]::-ms-fill {
    background-color: #ff8f00;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-darken-3 .progress-bar {
        background-color: #ff8f00;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8f00 !important;
}
.border-amber.border-darken-3 {
    border: 1px solid #ff8f00 !important;
}
.border-top-amber.border-top-darken-3 {
    border-top: 1px solid #ff8f00 !important;
}
.border-bottom-amber.border-bottom-darken-3 {
    border-bottom: 1px solid #ff8f00 !important;
}
[dir='ltr'] .border-left-amber.border-left-darken-3 {
    border-left: 1px solid #ff8f00 !important;
}
[dir='ltr'] .border-right-amber.border-right-darken-3,
[dir='rtl'] .border-left-amber.border-left-darken-3 {
    border-right: 1px solid #ff8f00 !important;
}
[dir='rtl'] .border-right-amber.border-right-darken-3 {
    border-left: 1px solid #ff8f00 !important;
}
.overlay-amber.overlay-darken-3 {
    background: #ff8f00;
    background: rgba(255, 143, 0, 0.8);
}
.amber.darken-4 {
    color: #ff6f00 !important;
}
.bg-amber.bg-darken-4,
.btn-amber.btn-darken-4 {
    background-color: #ff6f00 !important;
}
.btn-amber.btn-darken-4,
.btn-amber.btn-darken-4:hover {
    border-color: #ffa000 !important;
}
.btn-amber.btn-darken-4:hover {
    background-color: #ff8f00 !important;
}
.btn-amber.btn-darken-4:active,
.btn-amber.btn-darken-4:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-darken-4 {
    border-color: #ff6f00 !important;
    color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-darken-4:hover {
    background-color: #ff6f00 !important;
}
.progress-amber.progress-darken-4 {
    background-color: #ff6f00;
}
.progress-amber.progress-darken-4[value]::-webkit-progress-value {
    background-color: #ff6f00;
}
.progress-amber.progress-darken-4[value]::-moz-progress-bar {
    background-color: #ff6f00;
}
.progress-amber.progress-darken-4[value]::-ms-fill {
    background-color: #ff6f00;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-darken-4 .progress-bar {
        background-color: #ff6f00;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff6f00 !important;
}
.border-amber.border-darken-4 {
    border: 1px solid #ff6f00 !important;
}
.border-top-amber.border-top-darken-4 {
    border-top: 1px solid #ff6f00 !important;
}
.border-bottom-amber.border-bottom-darken-4 {
    border-bottom: 1px solid #ff6f00 !important;
}
[dir='ltr'] .border-left-amber.border-left-darken-4 {
    border-left: 1px solid #ff6f00 !important;
}
[dir='ltr'] .border-right-amber.border-right-darken-4,
[dir='rtl'] .border-left-amber.border-left-darken-4 {
    border-right: 1px solid #ff6f00 !important;
}
[dir='rtl'] .border-right-amber.border-right-darken-4 {
    border-left: 1px solid #ff6f00 !important;
}
.overlay-amber.overlay-darken-4 {
    background: #ff6f00;
    background: rgba(255, 111, 0, 0.8);
}
.amber.accent-1 {
    color: #ffe57f !important;
}
.bg-amber.bg-accent-1,
.btn-amber.btn-accent-1 {
    background-color: #ffe57f !important;
}
.btn-amber.btn-accent-1,
.btn-amber.btn-accent-1:hover {
    border-color: #ffa000 !important;
}
.btn-amber.btn-accent-1:hover {
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-1:active,
.btn-amber.btn-accent-1:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-accent-1 {
    border-color: #ffe57f !important;
    color: #ffe57f !important;
}
.btn-outline-amber.btn-outline-accent-1:hover {
    background-color: #ffe57f !important;
}
.progress-amber.progress-accent-1 {
    background-color: #ffe57f;
}
.progress-amber.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ffe57f;
}
.progress-amber.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ffe57f;
}
.progress-amber.progress-accent-1[value]::-ms-fill {
    background-color: #ffe57f;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-accent-1 .progress-bar {
        background-color: #ffe57f;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe57f !important;
}
.border-amber.border-accent-1 {
    border: 1px solid #ffe57f !important;
}
.border-top-amber.border-top-accent-1 {
    border-top: 1px solid #ffe57f !important;
}
.border-bottom-amber.border-bottom-accent-1 {
    border-bottom: 1px solid #ffe57f !important;
}
[dir='ltr'] .border-left-amber.border-left-accent-1 {
    border-left: 1px solid #ffe57f !important;
}
[dir='ltr'] .border-right-amber.border-right-accent-1,
[dir='rtl'] .border-left-amber.border-left-accent-1 {
    border-right: 1px solid #ffe57f !important;
}
[dir='rtl'] .border-right-amber.border-right-accent-1 {
    border-left: 1px solid #ffe57f !important;
}
.overlay-amber.overlay-accent-1 {
    background: #ffe57f;
    background: rgba(255, 229, 127, 0.8);
}
.amber.accent-2 {
    color: #ffd740 !important;
}
.bg-amber.bg-accent-2,
.btn-amber.btn-accent-2 {
    background-color: #ffd740 !important;
}
.btn-amber.btn-accent-2,
.btn-amber.btn-accent-2:hover {
    border-color: #ffa000 !important;
}
.btn-amber.btn-accent-2:hover {
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-2:active,
.btn-amber.btn-accent-2:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-accent-2 {
    border-color: #ffd740 !important;
    color: #ffd740 !important;
}
.btn-outline-amber.btn-outline-accent-2:hover {
    background-color: #ffd740 !important;
}
.progress-amber.progress-accent-2 {
    background-color: #ffd740;
}
.progress-amber.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ffd740;
}
.progress-amber.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ffd740;
}
.progress-amber.progress-accent-2[value]::-ms-fill {
    background-color: #ffd740;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-accent-2 .progress-bar {
        background-color: #ffd740;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd740 !important;
}
.border-amber.border-accent-2 {
    border: 1px solid #ffd740 !important;
}
.border-top-amber.border-top-accent-2 {
    border-top: 1px solid #ffd740 !important;
}
.border-bottom-amber.border-bottom-accent-2 {
    border-bottom: 1px solid #ffd740 !important;
}
[dir='ltr'] .border-left-amber.border-left-accent-2 {
    border-left: 1px solid #ffd740 !important;
}
[dir='ltr'] .border-right-amber.border-right-accent-2,
[dir='rtl'] .border-left-amber.border-left-accent-2 {
    border-right: 1px solid #ffd740 !important;
}
[dir='rtl'] .border-right-amber.border-right-accent-2 {
    border-left: 1px solid #ffd740 !important;
}
.overlay-amber.overlay-accent-2 {
    background: #ffd740;
    background: rgba(255, 215, 64, 0.8);
}
.amber.accent-3 {
    color: #ffc400 !important;
}
.bg-amber.bg-accent-3,
.btn-amber.btn-accent-3 {
    background-color: #ffc400 !important;
}
.btn-amber.btn-accent-3,
.btn-amber.btn-accent-3:hover {
    border-color: #ffa000 !important;
}
.btn-amber.btn-accent-3:hover {
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-3:active,
.btn-amber.btn-accent-3:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-accent-3 {
    border-color: #ffc400 !important;
    color: #ffc400 !important;
}
.btn-outline-amber.btn-outline-accent-3:hover {
    background-color: #ffc400 !important;
}
.progress-amber.progress-accent-3 {
    background-color: #ffc400;
}
.progress-amber.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ffc400;
}
.progress-amber.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ffc400;
}
.progress-amber.progress-accent-3[value]::-ms-fill {
    background-color: #ffc400;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-accent-3 .progress-bar {
        background-color: #ffc400;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffc400 !important;
}
.border-amber.border-accent-3 {
    border: 1px solid #ffc400 !important;
}
.border-top-amber.border-top-accent-3 {
    border-top: 1px solid #ffc400 !important;
}
.border-bottom-amber.border-bottom-accent-3 {
    border-bottom: 1px solid #ffc400 !important;
}
[dir='ltr'] .border-left-amber.border-left-accent-3 {
    border-left: 1px solid #ffc400 !important;
}
[dir='ltr'] .border-right-amber.border-right-accent-3,
[dir='rtl'] .border-left-amber.border-left-accent-3 {
    border-right: 1px solid #ffc400 !important;
}
[dir='rtl'] .border-right-amber.border-right-accent-3 {
    border-left: 1px solid #ffc400 !important;
}
.overlay-amber.overlay-accent-3 {
    background: #ffc400;
    background: rgba(255, 196, 0, 0.8);
}
.amber.accent-4 {
    color: #ffab00 !important;
}
.bg-amber.bg-accent-4,
.btn-amber.btn-accent-4 {
    background-color: #ffab00 !important;
}
.btn-amber.btn-accent-4,
.btn-amber.btn-accent-4:hover {
    border-color: #ffa000 !important;
}
.btn-amber.btn-accent-4:hover {
    background-color: #ff8f00 !important;
}
.btn-amber.btn-accent-4:active,
.btn-amber.btn-accent-4:focus {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important;
}
.btn-outline-amber.btn-outline-accent-4 {
    border-color: #ffab00 !important;
    color: #ffab00 !important;
}
.btn-outline-amber.btn-outline-accent-4:hover {
    background-color: #ffab00 !important;
}
.progress-amber.progress-accent-4 {
    background-color: #ffab00;
}
.progress-amber.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ffab00;
}
.progress-amber.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ffab00;
}
.progress-amber.progress-accent-4[value]::-ms-fill {
    background-color: #ffab00;
}
@media screen and (min-width: 0\0) {
    .progress-amber.progress-accent-4 .progress-bar {
        background-color: #ffab00;
    }
}
input:focus ~ .bg-amber {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffab00 !important;
}
.border-amber.border-accent-4 {
    border: 1px solid #ffab00 !important;
}
.border-top-amber.border-top-accent-4 {
    border-top: 1px solid #ffab00 !important;
}
.border-bottom-amber.border-bottom-accent-4 {
    border-bottom: 1px solid #ffab00 !important;
}
[dir='ltr'] .border-left-amber.border-left-accent-4 {
    border-left: 1px solid #ffab00 !important;
}
[dir='ltr'] .border-right-amber.border-right-accent-4,
[dir='rtl'] .border-left-amber.border-left-accent-4 {
    border-right: 1px solid #ffab00 !important;
}
[dir='rtl'] .border-right-amber.border-right-accent-4 {
    border-left: 1px solid #ffab00 !important;
}
.overlay-amber.overlay-accent-4 {
    background: #ffab00;
    background: rgba(255, 171, 0, 0.8);
}
.orange.lighten-5 {
    color: #fff3e0 !important;
}
.bg-orange.bg-lighten-5,
.btn-orange.btn-lighten-5 {
    background-color: #fff3e0 !important;
}
.btn-orange.btn-lighten-5 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-lighten-5:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-5:active,
.btn-orange.btn-lighten-5:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-lighten-5 {
    border-color: #fff3e0 !important;
    color: #fff3e0 !important;
}
.btn-outline-orange.btn-outline-lighten-5:hover {
    background-color: #fff3e0 !important;
}
.progress-orange.progress-lighten-5 {
    background-color: #fff3e0;
}
.progress-orange.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fff3e0;
}
.progress-orange.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fff3e0;
}
.progress-orange.progress-lighten-5[value]::-ms-fill {
    background-color: #fff3e0;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-lighten-5 .progress-bar {
        background-color: #fff3e0;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff3e0 !important;
}
.border-orange.border-lighten-5 {
    border: 1px solid #fff3e0 !important;
}
.border-top-orange.border-top-lighten-5 {
    border-top: 1px solid #fff3e0 !important;
}
.border-bottom-orange.border-bottom-lighten-5 {
    border-bottom: 1px solid #fff3e0 !important;
}
[dir='ltr'] .border-left-orange.border-left-lighten-5 {
    border-left: 1px solid #fff3e0 !important;
}
[dir='ltr'] .border-right-orange.border-right-lighten-5,
[dir='rtl'] .border-left-orange.border-left-lighten-5 {
    border-right: 1px solid #fff3e0 !important;
}
[dir='rtl'] .border-right-orange.border-right-lighten-5 {
    border-left: 1px solid #fff3e0 !important;
}
.overlay-orange.overlay-lighten-5 {
    background: #fff3e0;
    background: rgba(255, 243, 224, 0.8);
}
.orange.lighten-4 {
    color: #ffe0b2 !important;
}
.bg-orange.bg-lighten-4,
.btn-orange.btn-lighten-4 {
    background-color: #ffe0b2 !important;
}
.btn-orange.btn-lighten-4 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-lighten-4:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-4:active,
.btn-orange.btn-lighten-4:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-lighten-4 {
    border-color: #ffe0b2 !important;
    color: #ffe0b2 !important;
}
.btn-outline-orange.btn-outline-lighten-4:hover {
    background-color: #ffe0b2 !important;
}
.progress-orange.progress-lighten-4 {
    background-color: #ffe0b2;
}
.progress-orange.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffe0b2;
}
.progress-orange.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffe0b2;
}
.progress-orange.progress-lighten-4[value]::-ms-fill {
    background-color: #ffe0b2;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-lighten-4 .progress-bar {
        background-color: #ffe0b2;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe0b2 !important;
}
.border-orange.border-lighten-4 {
    border: 1px solid #ffe0b2 !important;
}
.border-top-orange.border-top-lighten-4 {
    border-top: 1px solid #ffe0b2 !important;
}
.border-bottom-orange.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffe0b2 !important;
}
[dir='ltr'] .border-left-orange.border-left-lighten-4 {
    border-left: 1px solid #ffe0b2 !important;
}
[dir='ltr'] .border-right-orange.border-right-lighten-4,
[dir='rtl'] .border-left-orange.border-left-lighten-4 {
    border-right: 1px solid #ffe0b2 !important;
}
[dir='rtl'] .border-right-orange.border-right-lighten-4 {
    border-left: 1px solid #ffe0b2 !important;
}
.overlay-orange.overlay-lighten-4 {
    background: #ffe0b2;
    background: rgba(255, 224, 178, 0.8);
}
.orange.lighten-3 {
    color: #ffcc80 !important;
}
.bg-orange.bg-lighten-3,
.btn-orange.btn-lighten-3 {
    background-color: #ffcc80 !important;
}
.btn-orange.btn-lighten-3 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-lighten-3:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-3:active,
.btn-orange.btn-lighten-3:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-lighten-3 {
    border-color: #ffcc80 !important;
    color: #ffcc80 !important;
}
.btn-outline-orange.btn-outline-lighten-3:hover {
    background-color: #ffcc80 !important;
}
.progress-orange.progress-lighten-3 {
    background-color: #ffcc80;
}
.progress-orange.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffcc80;
}
.progress-orange.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffcc80;
}
.progress-orange.progress-lighten-3[value]::-ms-fill {
    background-color: #ffcc80;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-lighten-3 .progress-bar {
        background-color: #ffcc80;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffcc80 !important;
}
.border-orange.border-lighten-3 {
    border: 1px solid #ffcc80 !important;
}
.border-top-orange.border-top-lighten-3 {
    border-top: 1px solid #ffcc80 !important;
}
.border-bottom-orange.border-bottom-lighten-3 {
    border-bottom: 1px solid #ffcc80 !important;
}
[dir='ltr'] .border-left-orange.border-left-lighten-3 {
    border-left: 1px solid #ffcc80 !important;
}
[dir='ltr'] .border-right-orange.border-right-lighten-3,
[dir='rtl'] .border-left-orange.border-left-lighten-3 {
    border-right: 1px solid #ffcc80 !important;
}
[dir='rtl'] .border-right-orange.border-right-lighten-3 {
    border-left: 1px solid #ffcc80 !important;
}
.overlay-orange.overlay-lighten-3 {
    background: #ffcc80;
    background: rgba(255, 204, 128, 0.8);
}
.orange.lighten-2 {
    color: #ffb74d !important;
}
.bg-orange.bg-lighten-2,
.btn-orange.btn-lighten-2 {
    background-color: #ffb74d !important;
}
.btn-orange.btn-lighten-2 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-lighten-2:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-2:active,
.btn-orange.btn-lighten-2:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-lighten-2 {
    border-color: #ffb74d !important;
    color: #ffb74d !important;
}
.btn-outline-orange.btn-outline-lighten-2:hover {
    background-color: #ffb74d !important;
}
.progress-orange.progress-lighten-2 {
    background-color: #ffb74d;
}
.progress-orange.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ffb74d;
}
.progress-orange.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ffb74d;
}
.progress-orange.progress-lighten-2[value]::-ms-fill {
    background-color: #ffb74d;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-lighten-2 .progress-bar {
        background-color: #ffb74d;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffb74d !important;
}
.border-orange.border-lighten-2 {
    border: 1px solid #ffb74d !important;
}
.border-top-orange.border-top-lighten-2 {
    border-top: 1px solid #ffb74d !important;
}
.border-bottom-orange.border-bottom-lighten-2 {
    border-bottom: 1px solid #ffb74d !important;
}
[dir='ltr'] .border-left-orange.border-left-lighten-2 {
    border-left: 1px solid #ffb74d !important;
}
[dir='ltr'] .border-right-orange.border-right-lighten-2,
[dir='rtl'] .border-left-orange.border-left-lighten-2 {
    border-right: 1px solid #ffb74d !important;
}
[dir='rtl'] .border-right-orange.border-right-lighten-2 {
    border-left: 1px solid #ffb74d !important;
}
.overlay-orange.overlay-lighten-2 {
    background: #ffb74d;
    background: rgba(255, 183, 77, 0.8);
}
.orange.lighten-1 {
    color: #ffa726 !important;
}
.bg-orange.bg-lighten-1,
.btn-orange.btn-lighten-1 {
    background-color: #ffa726 !important;
}
.btn-orange.btn-lighten-1 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-lighten-1:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-lighten-1:active,
.btn-orange.btn-lighten-1:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-lighten-1 {
    border-color: #ffa726 !important;
    color: #ffa726 !important;
}
.btn-outline-orange.btn-outline-lighten-1:hover {
    background-color: #ffa726 !important;
}
.progress-orange.progress-lighten-1 {
    background-color: #ffa726;
}
.progress-orange.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ffa726;
}
.progress-orange.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ffa726;
}
.progress-orange.progress-lighten-1[value]::-ms-fill {
    background-color: #ffa726;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-lighten-1 .progress-bar {
        background-color: #ffa726;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffa726 !important;
}
.border-orange.border-lighten-1 {
    border: 1px solid #ffa726 !important;
}
.border-top-orange.border-top-lighten-1 {
    border-top: 1px solid #ffa726 !important;
}
.border-bottom-orange.border-bottom-lighten-1 {
    border-bottom: 1px solid #ffa726 !important;
}
[dir='ltr'] .border-left-orange.border-left-lighten-1 {
    border-left: 1px solid #ffa726 !important;
}
[dir='ltr'] .border-right-orange.border-right-lighten-1,
[dir='rtl'] .border-left-orange.border-left-lighten-1 {
    border-right: 1px solid #ffa726 !important;
}
[dir='rtl'] .border-right-orange.border-right-lighten-1 {
    border-left: 1px solid #ffa726 !important;
}
.overlay-orange.overlay-lighten-1 {
    background: #ffa726;
    background: rgba(255, 167, 38, 0.8);
}
.orange {
    color: #ff9800 !important;
}
.bg-orange {
    background-color: #ff9800 !important;
}
.bg-orange .card-footer,
.bg-orange .card-header {
    background-color: initial;
}
.toast-orange {
    background-color: #ff9800;
}
.alert-orange {
    border-color: #ff9800 !important;
    background-color: #ffa624 !important;
    color: #4d2e00 !important;
}
.alert-orange .alert-link {
    color: #241500 !important;
}
.border-orange {
    border-color: #ff9800;
}
.border-top-orange {
    border-top-color: #ff9800;
}
.border-bottom-orange {
    border-bottom-color: #ff9800;
}
[dir='ltr'] .border-left-orange {
    border-left-color: #ff9800;
}
[dir='ltr'] .border-right-orange,
[dir='rtl'] .border-left-orange {
    border-right-color: #ff9800;
}
[dir='rtl'] .border-right-orange {
    border-left-color: #ff9800;
}
.badge-orange {
    background-color: #ff9800;
}
.panel-orange {
    border-color: #ff9800;
}
.panel-orange .panel-heading {
    color: #fff;
    border-color: #ff9800;
    background-color: #ffa21a;
}
.bg-orange.tag-glow,
.border-orange.tag-glow {
    box-shadow: 0 0 10px #ff9800;
}
.overlay-orange {
    background: #ff9800;
    background: rgba(255, 152, 0, 0.8);
}
.card.card-outline-orange {
    border: 1px solid #ff9800;
    background-color: initial;
}
.card.card-outline-orange .card-footer,
.card.card-outline-orange .card-header {
    background-color: initial;
}
.btn-orange.btn-flat {
    background-color: initial !important;
    color: #ff9800;
    border: none;
}
.btn-orange.btn-fab,
.btn-orange.btn-raised {
    background-color: #ff9800 !important;
    color: #fff !important;
    border-color: #ff9800;
}
.btn-orange.btn-fab.active,
.btn-orange.btn-raised.active {
    background-color: #db8300 !important;
    border-color: #db8300 !important;
}
.btn-group-raised .btn-orange {
    background-color: #ff9800 !important;
    color: #fff !important;
}
.btn-outline-orange {
    border: 1px solid #ff9800;
    background-color: initial;
    color: #ff9800;
    box-shadow: none !important;
}
.btn-outline-orange:focus {
    background-color: initial !important;
    color: #ff9800 !important;
    box-shadow: transparent !important;
}
.btn-outline-orange.active {
    background-color: #ff9800 !important;
    color: #fff !important;
}
.btn-outline-orange:hover {
    background-color: #f08f00 !important;
    color: #fff !important;
}
.btn-outline-orange:hover svg {
    color: #fff !important;
}
.btn-outline-orange.btn-fab,
.btn-outline-orange.btn-raised {
    border: 1px solid #ff9800;
    background-color: initial;
    color: #ff9800;
    box-shadow: none !important;
}
.btn-outline-orange.btn-fab:focus,
.btn-outline-orange.btn-raised:focus {
    background-color: initial;
}
.btn-outline-orange.btn-fab.active,
.btn-outline-orange.btn-raised.active {
    background-color: #ff9800 !important;
    color: #fff !important;
}
.btn-outline-orange.btn-fab:hover,
.btn-outline-orange.btn-raised:hover {
    background-color: #f08f00 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-orange {
    background-color: #ff9800;
}
.progress .progress-bar.progress-bar-orange[value]::-webkit-progress-value {
    background-color: #ff9800;
}
.progress .progress-bar.progress-bar-orange[value]::-moz-progress-bar {
    background-color: #ff9800;
}
.progress .progress-bar.progress-bar-orange[value]::-ms-fill {
    background-color: #ff9800;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-orange .progress-bar {
        background-color: #ff9800;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9800 !important;
}
.orange.darken-1 {
    color: #fb8c00 !important;
}
.bg-orange.bg-darken-1,
.btn-orange.btn-darken-1 {
    background-color: #fb8c00 !important;
}
.btn-orange.btn-darken-1 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-darken-1:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-1:active,
.btn-orange.btn-darken-1:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-darken-1 {
    border-color: #fb8c00 !important;
    color: #fb8c00 !important;
}
.btn-outline-orange.btn-outline-darken-1:hover {
    background-color: #fb8c00 !important;
}
.progress-orange.progress-darken-1 {
    background-color: #fb8c00;
}
.progress-orange.progress-darken-1[value]::-webkit-progress-value {
    background-color: #fb8c00;
}
.progress-orange.progress-darken-1[value]::-moz-progress-bar {
    background-color: #fb8c00;
}
.progress-orange.progress-darken-1[value]::-ms-fill {
    background-color: #fb8c00;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-darken-1 .progress-bar {
        background-color: #fb8c00;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fb8c00 !important;
}
.border-orange.border-darken-1 {
    border: 1px solid #fb8c00 !important;
}
.border-top-orange.border-top-darken-1 {
    border-top: 1px solid #fb8c00 !important;
}
.border-bottom-orange.border-bottom-darken-1 {
    border-bottom: 1px solid #fb8c00 !important;
}
[dir='ltr'] .border-left-orange.border-left-darken-1 {
    border-left: 1px solid #fb8c00 !important;
}
[dir='ltr'] .border-right-orange.border-right-darken-1,
[dir='rtl'] .border-left-orange.border-left-darken-1 {
    border-right: 1px solid #fb8c00 !important;
}
[dir='rtl'] .border-right-orange.border-right-darken-1 {
    border-left: 1px solid #fb8c00 !important;
}
.overlay-orange.overlay-darken-1 {
    background: #fb8c00;
    background: rgba(251, 140, 0, 0.8);
}
.orange.darken-2 {
    color: #f57c00 !important;
}
.bg-orange.bg-darken-2,
.btn-orange.btn-darken-2 {
    background-color: #f57c00 !important;
}
.btn-orange.btn-darken-2 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-darken-2:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-2:active,
.btn-orange.btn-darken-2:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-darken-2 {
    border-color: #f57c00 !important;
    color: #f57c00 !important;
}
.btn-outline-orange.btn-outline-darken-2:hover {
    background-color: #f57c00 !important;
}
.progress-orange.progress-darken-2 {
    background-color: #f57c00;
}
.progress-orange.progress-darken-2[value]::-webkit-progress-value {
    background-color: #f57c00;
}
.progress-orange.progress-darken-2[value]::-moz-progress-bar {
    background-color: #f57c00;
}
.progress-orange.progress-darken-2[value]::-ms-fill {
    background-color: #f57c00;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-darken-2 .progress-bar {
        background-color: #f57c00;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f57c00 !important;
}
.border-orange.border-darken-2 {
    border: 1px solid #f57c00 !important;
}
.border-top-orange.border-top-darken-2 {
    border-top: 1px solid #f57c00 !important;
}
.border-bottom-orange.border-bottom-darken-2 {
    border-bottom: 1px solid #f57c00 !important;
}
[dir='ltr'] .border-left-orange.border-left-darken-2 {
    border-left: 1px solid #f57c00 !important;
}
[dir='ltr'] .border-right-orange.border-right-darken-2,
[dir='rtl'] .border-left-orange.border-left-darken-2 {
    border-right: 1px solid #f57c00 !important;
}
[dir='rtl'] .border-right-orange.border-right-darken-2 {
    border-left: 1px solid #f57c00 !important;
}
.overlay-orange.overlay-darken-2 {
    background: #f57c00;
    background: rgba(245, 124, 0, 0.8);
}
.orange.darken-3 {
    color: #ef6c00 !important;
}
.bg-orange.bg-darken-3 {
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-3,
.btn-orange.btn-darken-3:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-3:active,
.btn-orange.btn-darken-3:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-darken-3 {
    border-color: #ef6c00 !important;
    color: #ef6c00 !important;
}
.btn-outline-orange.btn-outline-darken-3:hover {
    background-color: #ef6c00 !important;
}
.progress-orange.progress-darken-3 {
    background-color: #ef6c00;
}
.progress-orange.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ef6c00;
}
.progress-orange.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ef6c00;
}
.progress-orange.progress-darken-3[value]::-ms-fill {
    background-color: #ef6c00;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-darken-3 .progress-bar {
        background-color: #ef6c00;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef6c00 !important;
}
.border-orange.border-darken-3 {
    border: 1px solid #ef6c00 !important;
}
.border-top-orange.border-top-darken-3 {
    border-top: 1px solid #ef6c00 !important;
}
.border-bottom-orange.border-bottom-darken-3 {
    border-bottom: 1px solid #ef6c00 !important;
}
[dir='ltr'] .border-left-orange.border-left-darken-3 {
    border-left: 1px solid #ef6c00 !important;
}
[dir='ltr'] .border-right-orange.border-right-darken-3,
[dir='rtl'] .border-left-orange.border-left-darken-3 {
    border-right: 1px solid #ef6c00 !important;
}
[dir='rtl'] .border-right-orange.border-right-darken-3 {
    border-left: 1px solid #ef6c00 !important;
}
.overlay-orange.overlay-darken-3 {
    background: #ef6c00;
    background: rgba(239, 108, 0, 0.8);
}
.orange.darken-4 {
    color: #e65100 !important;
}
.bg-orange.bg-darken-4,
.btn-orange.btn-darken-4 {
    background-color: #e65100 !important;
}
.btn-orange.btn-darken-4 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-darken-4:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-darken-4:active,
.btn-orange.btn-darken-4:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-darken-4 {
    border-color: #e65100 !important;
    color: #e65100 !important;
}
.btn-outline-orange.btn-outline-darken-4:hover {
    background-color: #e65100 !important;
}
.progress-orange.progress-darken-4 {
    background-color: #e65100;
}
.progress-orange.progress-darken-4[value]::-webkit-progress-value {
    background-color: #e65100;
}
.progress-orange.progress-darken-4[value]::-moz-progress-bar {
    background-color: #e65100;
}
.progress-orange.progress-darken-4[value]::-ms-fill {
    background-color: #e65100;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-darken-4 .progress-bar {
        background-color: #e65100;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e65100 !important;
}
.border-orange.border-darken-4 {
    border: 1px solid #e65100 !important;
}
.border-top-orange.border-top-darken-4 {
    border-top: 1px solid #e65100 !important;
}
.border-bottom-orange.border-bottom-darken-4 {
    border-bottom: 1px solid #e65100 !important;
}
[dir='ltr'] .border-left-orange.border-left-darken-4 {
    border-left: 1px solid #e65100 !important;
}
[dir='ltr'] .border-right-orange.border-right-darken-4,
[dir='rtl'] .border-left-orange.border-left-darken-4 {
    border-right: 1px solid #e65100 !important;
}
[dir='rtl'] .border-right-orange.border-right-darken-4 {
    border-left: 1px solid #e65100 !important;
}
.overlay-orange.overlay-darken-4 {
    background: #e65100;
    background: rgba(230, 81, 0, 0.8);
}
.orange.accent-1 {
    color: #ffd180 !important;
}
.bg-orange.bg-accent-1,
.btn-orange.btn-accent-1 {
    background-color: #ffd180 !important;
}
.btn-orange.btn-accent-1 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-accent-1:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-accent-1:active,
.btn-orange.btn-accent-1:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-accent-1 {
    border-color: #ffd180 !important;
    color: #ffd180 !important;
}
.btn-outline-orange.btn-outline-accent-1:hover {
    background-color: #ffd180 !important;
}
.progress-orange.progress-accent-1 {
    background-color: #ffd180;
}
.progress-orange.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ffd180;
}
.progress-orange.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ffd180;
}
.progress-orange.progress-accent-1[value]::-ms-fill {
    background-color: #ffd180;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-accent-1 .progress-bar {
        background-color: #ffd180;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd180 !important;
}
.border-orange.border-accent-1 {
    border: 1px solid #ffd180 !important;
}
.border-top-orange.border-top-accent-1 {
    border-top: 1px solid #ffd180 !important;
}
.border-bottom-orange.border-bottom-accent-1 {
    border-bottom: 1px solid #ffd180 !important;
}
[dir='ltr'] .border-left-orange.border-left-accent-1 {
    border-left: 1px solid #ffd180 !important;
}
[dir='ltr'] .border-right-orange.border-right-accent-1,
[dir='rtl'] .border-left-orange.border-left-accent-1 {
    border-right: 1px solid #ffd180 !important;
}
[dir='rtl'] .border-right-orange.border-right-accent-1 {
    border-left: 1px solid #ffd180 !important;
}
.overlay-orange.overlay-accent-1 {
    background: #ffd180;
    background: rgba(255, 209, 128, 0.8);
}
.orange.accent-2 {
    color: #ffab40 !important;
}
.bg-orange.bg-accent-2,
.btn-orange.btn-accent-2 {
    background-color: #ffab40 !important;
}
.btn-orange.btn-accent-2 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-accent-2:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-accent-2:active,
.btn-orange.btn-accent-2:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-accent-2 {
    border-color: #ffab40 !important;
    color: #ffab40 !important;
}
.btn-outline-orange.btn-outline-accent-2:hover {
    background-color: #ffab40 !important;
}
.progress-orange.progress-accent-2 {
    background-color: #ffab40;
}
.progress-orange.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ffab40;
}
.progress-orange.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ffab40;
}
.progress-orange.progress-accent-2[value]::-ms-fill {
    background-color: #ffab40;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-accent-2 .progress-bar {
        background-color: #ffab40;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffab40 !important;
}
.border-orange.border-accent-2 {
    border: 1px solid #ffab40 !important;
}
.border-top-orange.border-top-accent-2 {
    border-top: 1px solid #ffab40 !important;
}
.border-bottom-orange.border-bottom-accent-2 {
    border-bottom: 1px solid #ffab40 !important;
}
[dir='ltr'] .border-left-orange.border-left-accent-2 {
    border-left: 1px solid #ffab40 !important;
}
[dir='ltr'] .border-right-orange.border-right-accent-2,
[dir='rtl'] .border-left-orange.border-left-accent-2 {
    border-right: 1px solid #ffab40 !important;
}
[dir='rtl'] .border-right-orange.border-right-accent-2 {
    border-left: 1px solid #ffab40 !important;
}
.overlay-orange.overlay-accent-2 {
    background: #ffab40;
    background: rgba(255, 171, 64, 0.8);
}
.orange.accent-3 {
    color: #ff9100 !important;
}
.bg-orange.bg-accent-3,
.btn-orange.btn-accent-3 {
    background-color: #ff9100 !important;
}
.btn-orange.btn-accent-3 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-accent-3:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-accent-3:active,
.btn-orange.btn-accent-3:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-accent-3 {
    border-color: #ff9100 !important;
    color: #ff9100 !important;
}
.btn-outline-orange.btn-outline-accent-3:hover {
    background-color: #ff9100 !important;
}
.progress-orange.progress-accent-3 {
    background-color: #ff9100;
}
.progress-orange.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ff9100;
}
.progress-orange.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ff9100;
}
.progress-orange.progress-accent-3[value]::-ms-fill {
    background-color: #ff9100;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-accent-3 .progress-bar {
        background-color: #ff9100;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9100 !important;
}
.border-orange.border-accent-3 {
    border: 1px solid #ff9100 !important;
}
.border-top-orange.border-top-accent-3 {
    border-top: 1px solid #ff9100 !important;
}
.border-bottom-orange.border-bottom-accent-3 {
    border-bottom: 1px solid #ff9100 !important;
}
[dir='ltr'] .border-left-orange.border-left-accent-3 {
    border-left: 1px solid #ff9100 !important;
}
[dir='ltr'] .border-right-orange.border-right-accent-3,
[dir='rtl'] .border-left-orange.border-left-accent-3 {
    border-right: 1px solid #ff9100 !important;
}
[dir='rtl'] .border-right-orange.border-right-accent-3 {
    border-left: 1px solid #ff9100 !important;
}
.overlay-orange.overlay-accent-3 {
    background: #ff9100;
    background: rgba(255, 145, 0, 0.8);
}
.orange.accent-4 {
    color: #ff6d00 !important;
}
.bg-orange.bg-accent-4,
.btn-orange.btn-accent-4 {
    background-color: #ff6d00 !important;
}
.btn-orange.btn-accent-4 {
    border-color: #f57c00 !important;
}
.btn-orange.btn-accent-4:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important;
}
.btn-orange.btn-accent-4:active,
.btn-orange.btn-accent-4:focus {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important;
}
.btn-outline-orange.btn-outline-accent-4 {
    border-color: #ff6d00 !important;
    color: #ff6d00 !important;
}
.btn-outline-orange.btn-outline-accent-4:hover {
    background-color: #ff6d00 !important;
}
.progress-orange.progress-accent-4 {
    background-color: #ff6d00;
}
.progress-orange.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ff6d00;
}
.progress-orange.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ff6d00;
}
.progress-orange.progress-accent-4[value]::-ms-fill {
    background-color: #ff6d00;
}
@media screen and (min-width: 0\0) {
    .progress-orange.progress-accent-4 .progress-bar {
        background-color: #ff6d00;
    }
}
input:focus ~ .bg-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff6d00 !important;
}
.border-orange.border-accent-4 {
    border: 1px solid #ff6d00 !important;
}
.border-top-orange.border-top-accent-4 {
    border-top: 1px solid #ff6d00 !important;
}
.border-bottom-orange.border-bottom-accent-4 {
    border-bottom: 1px solid #ff6d00 !important;
}
[dir='ltr'] .border-left-orange.border-left-accent-4 {
    border-left: 1px solid #ff6d00 !important;
}
[dir='ltr'] .border-right-orange.border-right-accent-4,
[dir='rtl'] .border-left-orange.border-left-accent-4 {
    border-right: 1px solid #ff6d00 !important;
}
[dir='rtl'] .border-right-orange.border-right-accent-4 {
    border-left: 1px solid #ff6d00 !important;
}
.overlay-orange.overlay-accent-4 {
    background: #ff6d00;
    background: rgba(255, 109, 0, 0.8);
}
.deep-orange.lighten-5 {
    color: #fbe9e7 !important;
}
.bg-deep-orange.bg-lighten-5,
.btn-deep-orange.btn-lighten-5 {
    background-color: #fbe9e7 !important;
}
.btn-deep-orange.btn-lighten-5 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-lighten-5:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-5:active,
.btn-deep-orange.btn-lighten-5:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-lighten-5 {
    border-color: #fbe9e7 !important;
    color: #fbe9e7 !important;
}
.btn-outline-deep-orange.btn-outline-lighten-5:hover {
    background-color: #fbe9e7 !important;
}
.progress-deep-orange.progress-lighten-5 {
    background-color: #fbe9e7;
}
.progress-deep-orange.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fbe9e7;
}
.progress-deep-orange.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fbe9e7;
}
.progress-deep-orange.progress-lighten-5[value]::-ms-fill {
    background-color: #fbe9e7;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-lighten-5 .progress-bar {
        background-color: #fbe9e7;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fbe9e7 !important;
}
.border-deep-orange.border-lighten-5 {
    border: 1px solid #fbe9e7 !important;
}
.border-top-deep-orange.border-top-lighten-5 {
    border-top: 1px solid #fbe9e7 !important;
}
.border-bottom-deep-orange.border-bottom-lighten-5 {
    border-bottom: 1px solid #fbe9e7 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-lighten-5 {
    border-left: 1px solid #fbe9e7 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-lighten-5,
[dir='rtl'] .border-left-deep-orange.border-left-lighten-5 {
    border-right: 1px solid #fbe9e7 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-lighten-5 {
    border-left: 1px solid #fbe9e7 !important;
}
.overlay-deep-orange.overlay-lighten-5 {
    background: #fbe9e7;
    background: rgba(251, 233, 231, 0.8);
}
.deep-orange.lighten-4 {
    color: #ffccbc !important;
}
.bg-deep-orange.bg-lighten-4,
.btn-deep-orange.btn-lighten-4 {
    background-color: #ffccbc !important;
}
.btn-deep-orange.btn-lighten-4 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-lighten-4:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-4:active,
.btn-deep-orange.btn-lighten-4:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-lighten-4 {
    border-color: #ffccbc !important;
    color: #ffccbc !important;
}
.btn-outline-deep-orange.btn-outline-lighten-4:hover {
    background-color: #ffccbc !important;
}
.progress-deep-orange.progress-lighten-4 {
    background-color: #ffccbc;
}
.progress-deep-orange.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffccbc;
}
.progress-deep-orange.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffccbc;
}
.progress-deep-orange.progress-lighten-4[value]::-ms-fill {
    background-color: #ffccbc;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-lighten-4 .progress-bar {
        background-color: #ffccbc;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffccbc !important;
}
.border-deep-orange.border-lighten-4 {
    border: 1px solid #ffccbc !important;
}
.border-top-deep-orange.border-top-lighten-4 {
    border-top: 1px solid #ffccbc !important;
}
.border-bottom-deep-orange.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffccbc !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-lighten-4 {
    border-left: 1px solid #ffccbc !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-lighten-4,
[dir='rtl'] .border-left-deep-orange.border-left-lighten-4 {
    border-right: 1px solid #ffccbc !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-lighten-4 {
    border-left: 1px solid #ffccbc !important;
}
.overlay-deep-orange.overlay-lighten-4 {
    background: #ffccbc;
    background: rgba(255, 204, 188, 0.8);
}
.deep-orange.lighten-3 {
    color: #ffab91 !important;
}
.bg-deep-orange.bg-lighten-3,
.btn-deep-orange.btn-lighten-3 {
    background-color: #ffab91 !important;
}
.btn-deep-orange.btn-lighten-3 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-lighten-3:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-3:active,
.btn-deep-orange.btn-lighten-3:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-lighten-3 {
    border-color: #ffab91 !important;
    color: #ffab91 !important;
}
.btn-outline-deep-orange.btn-outline-lighten-3:hover {
    background-color: #ffab91 !important;
}
.progress-deep-orange.progress-lighten-3 {
    background-color: #ffab91;
}
.progress-deep-orange.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffab91;
}
.progress-deep-orange.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffab91;
}
.progress-deep-orange.progress-lighten-3[value]::-ms-fill {
    background-color: #ffab91;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-lighten-3 .progress-bar {
        background-color: #ffab91;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffab91 !important;
}
.border-deep-orange.border-lighten-3 {
    border: 1px solid #ffab91 !important;
}
.border-top-deep-orange.border-top-lighten-3 {
    border-top: 1px solid #ffab91 !important;
}
.border-bottom-deep-orange.border-bottom-lighten-3 {
    border-bottom: 1px solid #ffab91 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-lighten-3 {
    border-left: 1px solid #ffab91 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-lighten-3,
[dir='rtl'] .border-left-deep-orange.border-left-lighten-3 {
    border-right: 1px solid #ffab91 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-lighten-3 {
    border-left: 1px solid #ffab91 !important;
}
.overlay-deep-orange.overlay-lighten-3 {
    background: #ffab91;
    background: rgba(255, 171, 145, 0.8);
}
.deep-orange.lighten-2 {
    color: #ff8a65 !important;
}
.bg-deep-orange.bg-lighten-2,
.btn-deep-orange.btn-lighten-2 {
    background-color: #ff8a65 !important;
}
.btn-deep-orange.btn-lighten-2 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-lighten-2:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-2:active,
.btn-deep-orange.btn-lighten-2:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-lighten-2 {
    border-color: #ff8a65 !important;
    color: #ff8a65 !important;
}
.btn-outline-deep-orange.btn-outline-lighten-2:hover {
    background-color: #ff8a65 !important;
}
.progress-deep-orange.progress-lighten-2 {
    background-color: #ff8a65;
}
.progress-deep-orange.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ff8a65;
}
.progress-deep-orange.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ff8a65;
}
.progress-deep-orange.progress-lighten-2[value]::-ms-fill {
    background-color: #ff8a65;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-lighten-2 .progress-bar {
        background-color: #ff8a65;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8a65 !important;
}
.border-deep-orange.border-lighten-2 {
    border: 1px solid #ff8a65 !important;
}
.border-top-deep-orange.border-top-lighten-2 {
    border-top: 1px solid #ff8a65 !important;
}
.border-bottom-deep-orange.border-bottom-lighten-2 {
    border-bottom: 1px solid #ff8a65 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-lighten-2 {
    border-left: 1px solid #ff8a65 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-lighten-2,
[dir='rtl'] .border-left-deep-orange.border-left-lighten-2 {
    border-right: 1px solid #ff8a65 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-lighten-2 {
    border-left: 1px solid #ff8a65 !important;
}
.overlay-deep-orange.overlay-lighten-2 {
    background: #ff8a65;
    background: rgba(255, 138, 101, 0.8);
}
.deep-orange.lighten-1 {
    color: #ff7043 !important;
}
.bg-deep-orange.bg-lighten-1,
.btn-deep-orange.btn-lighten-1 {
    background-color: #ff7043 !important;
}
.btn-deep-orange.btn-lighten-1 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-lighten-1:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-lighten-1:active,
.btn-deep-orange.btn-lighten-1:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-lighten-1 {
    border-color: #ff7043 !important;
    color: #ff7043 !important;
}
.btn-outline-deep-orange.btn-outline-lighten-1:hover {
    background-color: #ff7043 !important;
}
.progress-deep-orange.progress-lighten-1 {
    background-color: #ff7043;
}
.progress-deep-orange.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ff7043;
}
.progress-deep-orange.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ff7043;
}
.progress-deep-orange.progress-lighten-1[value]::-ms-fill {
    background-color: #ff7043;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-lighten-1 .progress-bar {
        background-color: #ff7043;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff7043 !important;
}
.border-deep-orange.border-lighten-1 {
    border: 1px solid #ff7043 !important;
}
.border-top-deep-orange.border-top-lighten-1 {
    border-top: 1px solid #ff7043 !important;
}
.border-bottom-deep-orange.border-bottom-lighten-1 {
    border-bottom: 1px solid #ff7043 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-lighten-1 {
    border-left: 1px solid #ff7043 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-lighten-1,
[dir='rtl'] .border-left-deep-orange.border-left-lighten-1 {
    border-right: 1px solid #ff7043 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-lighten-1 {
    border-left: 1px solid #ff7043 !important;
}
.overlay-deep-orange.overlay-lighten-1 {
    background: #ff7043;
    background: rgba(255, 112, 67, 0.8);
}
.deep-orange {
    color: #ff5722 !important;
}
.bg-deep-orange {
    background-color: #ff5722 !important;
}
.bg-deep-orange .card-footer,
.bg-deep-orange .card-header {
    background-color: initial;
}
.toast-deep-orange {
    background-color: #ff5722;
}
.alert-deep-orange {
    border-color: #ff5722 !important;
    background-color: #ff7246 !important;
    color: #6f1b00 !important;
}
.alert-deep-orange .alert-link {
    color: #461100 !important;
}
.border-deep-orange {
    border-color: #ff5722;
}
.border-top-deep-orange {
    border-top-color: #ff5722;
}
.border-bottom-deep-orange {
    border-bottom-color: #ff5722;
}
[dir='ltr'] .border-left-deep-orange {
    border-left-color: #ff5722;
}
[dir='ltr'] .border-right-deep-orange,
[dir='rtl'] .border-left-deep-orange {
    border-right-color: #ff5722;
}
[dir='rtl'] .border-right-deep-orange {
    border-left-color: #ff5722;
}
.badge-deep-orange {
    background-color: #ff5722;
}
.panel-deep-orange {
    border-color: #ff5722;
}
.panel-deep-orange .panel-heading {
    color: #fff;
    border-color: #ff5722;
    background-color: #ff6a3c;
}
.bg-deep-orange.tag-glow,
.border-deep-orange.tag-glow {
    box-shadow: 0 0 10px #ff5722;
}
.overlay-deep-orange {
    background: #ff5722;
    background: rgba(255, 87, 34, 0.8);
}
.card.card-outline-deep-orange {
    border: 1px solid #ff5722;
    background-color: initial;
}
.card.card-outline-deep-orange .card-footer,
.card.card-outline-deep-orange .card-header {
    background-color: initial;
}
.btn-deep-orange.btn-flat {
    background-color: initial !important;
    color: #ff5722;
    border: none;
}
.btn-deep-orange.btn-fab,
.btn-deep-orange.btn-raised {
    background-color: #ff5722 !important;
    color: #fff !important;
    border-color: #ff5722;
}
.btn-deep-orange.btn-fab.active,
.btn-deep-orange.btn-raised.active {
    background-color: #fd3d00 !important;
    border-color: #fd3d00 !important;
}
.btn-group-raised .btn-deep-orange {
    background-color: #ff5722 !important;
    color: #fff !important;
}
.btn-outline-deep-orange {
    border: 1px solid #ff5722;
    background-color: initial;
    color: #ff5722;
    box-shadow: none !important;
}
.btn-outline-deep-orange:focus {
    background-color: initial !important;
    color: #ff5722 !important;
    box-shadow: transparent !important;
}
.btn-outline-deep-orange.active {
    background-color: #ff5722 !important;
    color: #fff !important;
}
.btn-outline-deep-orange:hover {
    background-color: #ff4b13 !important;
    color: #fff !important;
}
.btn-outline-deep-orange:hover svg {
    color: #fff !important;
}
.btn-outline-deep-orange.btn-fab,
.btn-outline-deep-orange.btn-raised {
    border: 1px solid #ff5722;
    background-color: initial;
    color: #ff5722;
    box-shadow: none !important;
}
.btn-outline-deep-orange.btn-fab:focus,
.btn-outline-deep-orange.btn-raised:focus {
    background-color: initial;
}
.btn-outline-deep-orange.btn-fab.active,
.btn-outline-deep-orange.btn-raised.active {
    background-color: #ff5722 !important;
    color: #fff !important;
}
.btn-outline-deep-orange.btn-fab:hover,
.btn-outline-deep-orange.btn-raised:hover {
    background-color: #ff4b13 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-deep-orange {
    background-color: #ff5722;
}
.progress
    .progress-bar.progress-bar-deep-orange[value]::-webkit-progress-value {
    background-color: #ff5722;
}
.progress .progress-bar.progress-bar-deep-orange[value]::-moz-progress-bar {
    background-color: #ff5722;
}
.progress .progress-bar.progress-bar-deep-orange[value]::-ms-fill {
    background-color: #ff5722;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-deep-orange .progress-bar {
        background-color: #ff5722;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff5722 !important;
}
.deep-orange.darken-1 {
    color: #f4511e !important;
}
.bg-deep-orange.bg-darken-1,
.btn-deep-orange.btn-darken-1 {
    background-color: #f4511e !important;
}
.btn-deep-orange.btn-darken-1 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-darken-1:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-1:active,
.btn-deep-orange.btn-darken-1:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-darken-1 {
    border-color: #f4511e !important;
    color: #f4511e !important;
}
.btn-outline-deep-orange.btn-outline-darken-1:hover {
    background-color: #f4511e !important;
}
.progress-deep-orange.progress-darken-1 {
    background-color: #f4511e;
}
.progress-deep-orange.progress-darken-1[value]::-webkit-progress-value {
    background-color: #f4511e;
}
.progress-deep-orange.progress-darken-1[value]::-moz-progress-bar {
    background-color: #f4511e;
}
.progress-deep-orange.progress-darken-1[value]::-ms-fill {
    background-color: #f4511e;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-darken-1 .progress-bar {
        background-color: #f4511e;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f4511e !important;
}
.border-deep-orange.border-darken-1 {
    border: 1px solid #f4511e !important;
}
.border-top-deep-orange.border-top-darken-1 {
    border-top: 1px solid #f4511e !important;
}
.border-bottom-deep-orange.border-bottom-darken-1 {
    border-bottom: 1px solid #f4511e !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-darken-1 {
    border-left: 1px solid #f4511e !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-darken-1,
[dir='rtl'] .border-left-deep-orange.border-left-darken-1 {
    border-right: 1px solid #f4511e !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-darken-1 {
    border-left: 1px solid #f4511e !important;
}
.overlay-deep-orange.overlay-darken-1 {
    background: #f4511e;
    background: rgba(244, 81, 30, 0.8);
}
.deep-orange.darken-2 {
    color: #e64a19 !important;
}
.bg-deep-orange.bg-darken-2,
.btn-deep-orange.btn-darken-2 {
    background-color: #e64a19 !important;
}
.btn-deep-orange.btn-darken-2 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-darken-2:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-2:active,
.btn-deep-orange.btn-darken-2:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-darken-2 {
    border-color: #e64a19 !important;
    color: #e64a19 !important;
}
.btn-outline-deep-orange.btn-outline-darken-2:hover {
    background-color: #e64a19 !important;
}
.progress-deep-orange.progress-darken-2 {
    background-color: #e64a19;
}
.progress-deep-orange.progress-darken-2[value]::-webkit-progress-value {
    background-color: #e64a19;
}
.progress-deep-orange.progress-darken-2[value]::-moz-progress-bar {
    background-color: #e64a19;
}
.progress-deep-orange.progress-darken-2[value]::-ms-fill {
    background-color: #e64a19;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-darken-2 .progress-bar {
        background-color: #e64a19;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e64a19 !important;
}
.border-deep-orange.border-darken-2 {
    border: 1px solid #e64a19 !important;
}
.border-top-deep-orange.border-top-darken-2 {
    border-top: 1px solid #e64a19 !important;
}
.border-bottom-deep-orange.border-bottom-darken-2 {
    border-bottom: 1px solid #e64a19 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-darken-2 {
    border-left: 1px solid #e64a19 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-darken-2,
[dir='rtl'] .border-left-deep-orange.border-left-darken-2 {
    border-right: 1px solid #e64a19 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-darken-2 {
    border-left: 1px solid #e64a19 !important;
}
.overlay-deep-orange.overlay-darken-2 {
    background: #e64a19;
    background: rgba(230, 74, 25, 0.8);
}
.deep-orange.darken-3 {
    color: #d84315 !important;
}
.bg-deep-orange.bg-darken-3 {
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-3,
.btn-deep-orange.btn-darken-3:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-3:active,
.btn-deep-orange.btn-darken-3:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-darken-3 {
    border-color: #d84315 !important;
    color: #d84315 !important;
}
.btn-outline-deep-orange.btn-outline-darken-3:hover {
    background-color: #d84315 !important;
}
.progress-deep-orange.progress-darken-3 {
    background-color: #d84315;
}
.progress-deep-orange.progress-darken-3[value]::-webkit-progress-value {
    background-color: #d84315;
}
.progress-deep-orange.progress-darken-3[value]::-moz-progress-bar {
    background-color: #d84315;
}
.progress-deep-orange.progress-darken-3[value]::-ms-fill {
    background-color: #d84315;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-darken-3 .progress-bar {
        background-color: #d84315;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d84315 !important;
}
.border-deep-orange.border-darken-3 {
    border: 1px solid #d84315 !important;
}
.border-top-deep-orange.border-top-darken-3 {
    border-top: 1px solid #d84315 !important;
}
.border-bottom-deep-orange.border-bottom-darken-3 {
    border-bottom: 1px solid #d84315 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-darken-3 {
    border-left: 1px solid #d84315 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-darken-3,
[dir='rtl'] .border-left-deep-orange.border-left-darken-3 {
    border-right: 1px solid #d84315 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-darken-3 {
    border-left: 1px solid #d84315 !important;
}
.overlay-deep-orange.overlay-darken-3 {
    background: #d84315;
    background: rgba(216, 67, 21, 0.8);
}
.deep-orange.darken-4 {
    color: #bf360c !important;
}
.bg-deep-orange.bg-darken-4,
.btn-deep-orange.btn-darken-4 {
    background-color: #bf360c !important;
}
.btn-deep-orange.btn-darken-4 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-darken-4:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-darken-4:active,
.btn-deep-orange.btn-darken-4:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-darken-4 {
    border-color: #bf360c !important;
    color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-darken-4:hover {
    background-color: #bf360c !important;
}
.progress-deep-orange.progress-darken-4 {
    background-color: #bf360c;
}
.progress-deep-orange.progress-darken-4[value]::-webkit-progress-value {
    background-color: #bf360c;
}
.progress-deep-orange.progress-darken-4[value]::-moz-progress-bar {
    background-color: #bf360c;
}
.progress-deep-orange.progress-darken-4[value]::-ms-fill {
    background-color: #bf360c;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-darken-4 .progress-bar {
        background-color: #bf360c;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bf360c !important;
}
.border-deep-orange.border-darken-4 {
    border: 1px solid #bf360c !important;
}
.border-top-deep-orange.border-top-darken-4 {
    border-top: 1px solid #bf360c !important;
}
.border-bottom-deep-orange.border-bottom-darken-4 {
    border-bottom: 1px solid #bf360c !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-darken-4 {
    border-left: 1px solid #bf360c !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-darken-4,
[dir='rtl'] .border-left-deep-orange.border-left-darken-4 {
    border-right: 1px solid #bf360c !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-darken-4 {
    border-left: 1px solid #bf360c !important;
}
.overlay-deep-orange.overlay-darken-4 {
    background: #bf360c;
    background: rgba(191, 54, 12, 0.8);
}
.deep-orange.accent-1 {
    color: #ff9e80 !important;
}
.bg-deep-orange.bg-accent-1,
.btn-deep-orange.btn-accent-1 {
    background-color: #ff9e80 !important;
}
.btn-deep-orange.btn-accent-1 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-accent-1:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-accent-1:active,
.btn-deep-orange.btn-accent-1:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-accent-1 {
    border-color: #ff9e80 !important;
    color: #ff9e80 !important;
}
.btn-outline-deep-orange.btn-outline-accent-1:hover {
    background-color: #ff9e80 !important;
}
.progress-deep-orange.progress-accent-1 {
    background-color: #ff9e80;
}
.progress-deep-orange.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ff9e80;
}
.progress-deep-orange.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ff9e80;
}
.progress-deep-orange.progress-accent-1[value]::-ms-fill {
    background-color: #ff9e80;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-accent-1 .progress-bar {
        background-color: #ff9e80;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9e80 !important;
}
.border-deep-orange.border-accent-1 {
    border: 1px solid #ff9e80 !important;
}
.border-top-deep-orange.border-top-accent-1 {
    border-top: 1px solid #ff9e80 !important;
}
.border-bottom-deep-orange.border-bottom-accent-1 {
    border-bottom: 1px solid #ff9e80 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-accent-1 {
    border-left: 1px solid #ff9e80 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-accent-1,
[dir='rtl'] .border-left-deep-orange.border-left-accent-1 {
    border-right: 1px solid #ff9e80 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-accent-1 {
    border-left: 1px solid #ff9e80 !important;
}
.overlay-deep-orange.overlay-accent-1 {
    background: #ff9e80;
    background: rgba(255, 158, 128, 0.8);
}
.deep-orange.accent-2 {
    color: #ff6e40 !important;
}
.bg-deep-orange.bg-accent-2,
.btn-deep-orange.btn-accent-2 {
    background-color: #ff6e40 !important;
}
.btn-deep-orange.btn-accent-2 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-accent-2:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-accent-2:active,
.btn-deep-orange.btn-accent-2:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-accent-2 {
    border-color: #ff6e40 !important;
    color: #ff6e40 !important;
}
.btn-outline-deep-orange.btn-outline-accent-2:hover {
    background-color: #ff6e40 !important;
}
.progress-deep-orange.progress-accent-2 {
    background-color: #ff6e40;
}
.progress-deep-orange.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff6e40;
}
.progress-deep-orange.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff6e40;
}
.progress-deep-orange.progress-accent-2[value]::-ms-fill {
    background-color: #ff6e40;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-accent-2 .progress-bar {
        background-color: #ff6e40;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff6e40 !important;
}
.border-deep-orange.border-accent-2 {
    border: 1px solid #ff6e40 !important;
}
.border-top-deep-orange.border-top-accent-2 {
    border-top: 1px solid #ff6e40 !important;
}
.border-bottom-deep-orange.border-bottom-accent-2 {
    border-bottom: 1px solid #ff6e40 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-accent-2 {
    border-left: 1px solid #ff6e40 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-accent-2,
[dir='rtl'] .border-left-deep-orange.border-left-accent-2 {
    border-right: 1px solid #ff6e40 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-accent-2 {
    border-left: 1px solid #ff6e40 !important;
}
.overlay-deep-orange.overlay-accent-2 {
    background: #ff6e40;
    background: rgba(255, 110, 64, 0.8);
}
.deep-orange.accent-3 {
    color: #ff3d00 !important;
}
.bg-deep-orange.bg-accent-3,
.btn-deep-orange.btn-accent-3 {
    background-color: #ff3d00 !important;
}
.btn-deep-orange.btn-accent-3 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-accent-3:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-accent-3:active,
.btn-deep-orange.btn-accent-3:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-accent-3 {
    border-color: #ff3d00 !important;
    color: #ff3d00 !important;
}
.btn-outline-deep-orange.btn-outline-accent-3:hover {
    background-color: #ff3d00 !important;
}
.progress-deep-orange.progress-accent-3 {
    background-color: #ff3d00;
}
.progress-deep-orange.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ff3d00;
}
.progress-deep-orange.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ff3d00;
}
.progress-deep-orange.progress-accent-3[value]::-ms-fill {
    background-color: #ff3d00;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-accent-3 .progress-bar {
        background-color: #ff3d00;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff3d00 !important;
}
.border-deep-orange.border-accent-3 {
    border: 1px solid #ff3d00 !important;
}
.border-top-deep-orange.border-top-accent-3 {
    border-top: 1px solid #ff3d00 !important;
}
.border-bottom-deep-orange.border-bottom-accent-3 {
    border-bottom: 1px solid #ff3d00 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-accent-3 {
    border-left: 1px solid #ff3d00 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-accent-3,
[dir='rtl'] .border-left-deep-orange.border-left-accent-3 {
    border-right: 1px solid #ff3d00 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-accent-3 {
    border-left: 1px solid #ff3d00 !important;
}
.overlay-deep-orange.overlay-accent-3 {
    background: #ff3d00;
    background: rgba(255, 61, 0, 0.8);
}
.deep-orange.accent-4 {
    color: #dd2c00 !important;
}
.bg-deep-orange.bg-accent-4,
.btn-deep-orange.btn-accent-4 {
    background-color: #dd2c00 !important;
}
.btn-deep-orange.btn-accent-4 {
    border-color: #e64a19 !important;
}
.btn-deep-orange.btn-accent-4:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important;
}
.btn-deep-orange.btn-accent-4:active,
.btn-deep-orange.btn-accent-4:focus {
    border-color: #d84315 !important;
    background-color: #bf360c !important;
}
.btn-outline-deep-orange.btn-outline-accent-4 {
    border-color: #dd2c00 !important;
    color: #dd2c00 !important;
}
.btn-outline-deep-orange.btn-outline-accent-4:hover {
    background-color: #dd2c00 !important;
}
.progress-deep-orange.progress-accent-4 {
    background-color: #dd2c00;
}
.progress-deep-orange.progress-accent-4[value]::-webkit-progress-value {
    background-color: #dd2c00;
}
.progress-deep-orange.progress-accent-4[value]::-moz-progress-bar {
    background-color: #dd2c00;
}
.progress-deep-orange.progress-accent-4[value]::-ms-fill {
    background-color: #dd2c00;
}
@media screen and (min-width: 0\0) {
    .progress-deep-orange.progress-accent-4 .progress-bar {
        background-color: #dd2c00;
    }
}
input:focus ~ .bg-deep-orange {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #dd2c00 !important;
}
.border-deep-orange.border-accent-4 {
    border: 1px solid #dd2c00 !important;
}
.border-top-deep-orange.border-top-accent-4 {
    border-top: 1px solid #dd2c00 !important;
}
.border-bottom-deep-orange.border-bottom-accent-4 {
    border-bottom: 1px solid #dd2c00 !important;
}
[dir='ltr'] .border-left-deep-orange.border-left-accent-4 {
    border-left: 1px solid #dd2c00 !important;
}
[dir='ltr'] .border-right-deep-orange.border-right-accent-4,
[dir='rtl'] .border-left-deep-orange.border-left-accent-4 {
    border-right: 1px solid #dd2c00 !important;
}
[dir='rtl'] .border-right-deep-orange.border-right-accent-4 {
    border-left: 1px solid #dd2c00 !important;
}
.overlay-deep-orange.overlay-accent-4 {
    background: #dd2c00;
    background: rgba(221, 44, 0, 0.8);
}
.brown.lighten-5 {
    color: #efebe9 !important;
}
.bg-brown.bg-lighten-5,
.btn-brown.btn-lighten-5 {
    background-color: #efebe9 !important;
}
.btn-brown.btn-lighten-5 {
    border-color: #5d4037 !important;
}
.btn-brown.btn-lighten-5:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-5:active,
.btn-brown.btn-lighten-5:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-lighten-5 {
    border-color: #efebe9 !important;
    color: #efebe9 !important;
}
.btn-outline-brown.btn-outline-lighten-5:hover {
    background-color: #efebe9 !important;
}
.progress-brown.progress-lighten-5 {
    background-color: #efebe9;
}
.progress-brown.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #efebe9;
}
.progress-brown.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #efebe9;
}
.progress-brown.progress-lighten-5[value]::-ms-fill {
    background-color: #efebe9;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-lighten-5 .progress-bar {
        background-color: #efebe9;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #efebe9 !important;
}
.border-brown.border-lighten-5 {
    border: 1px solid #efebe9 !important;
}
.border-top-brown.border-top-lighten-5 {
    border-top: 1px solid #efebe9 !important;
}
.border-bottom-brown.border-bottom-lighten-5 {
    border-bottom: 1px solid #efebe9 !important;
}
[dir='ltr'] .border-left-brown.border-left-lighten-5 {
    border-left: 1px solid #efebe9 !important;
}
[dir='ltr'] .border-right-brown.border-right-lighten-5,
[dir='rtl'] .border-left-brown.border-left-lighten-5 {
    border-right: 1px solid #efebe9 !important;
}
[dir='rtl'] .border-right-brown.border-right-lighten-5 {
    border-left: 1px solid #efebe9 !important;
}
.overlay-brown.overlay-lighten-5 {
    background: #efebe9;
    background: rgba(239, 235, 233, 0.8);
}
.brown.lighten-4 {
    color: #d7ccc8 !important;
}
.bg-brown.bg-lighten-4,
.btn-brown.btn-lighten-4 {
    background-color: #d7ccc8 !important;
}
.btn-brown.btn-lighten-4 {
    border-color: #5d4037 !important;
}
.btn-brown.btn-lighten-4:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-4:active,
.btn-brown.btn-lighten-4:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-lighten-4 {
    border-color: #d7ccc8 !important;
    color: #d7ccc8 !important;
}
.btn-outline-brown.btn-outline-lighten-4:hover {
    background-color: #d7ccc8 !important;
}
.progress-brown.progress-lighten-4 {
    background-color: #d7ccc8;
}
.progress-brown.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #d7ccc8;
}
.progress-brown.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #d7ccc8;
}
.progress-brown.progress-lighten-4[value]::-ms-fill {
    background-color: #d7ccc8;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-lighten-4 .progress-bar {
        background-color: #d7ccc8;
    }
}
.border-brown.border-lighten-4 {
    border: 1px solid #d7ccc8 !important;
}
.border-top-brown.border-top-lighten-4 {
    border-top: 1px solid #d7ccc8 !important;
}
.border-bottom-brown.border-bottom-lighten-4 {
    border-bottom: 1px solid #d7ccc8 !important;
}
[dir='ltr'] .border-left-brown.border-left-lighten-4 {
    border-left: 1px solid #d7ccc8 !important;
}
[dir='ltr'] .border-right-brown.border-right-lighten-4,
[dir='rtl'] .border-left-brown.border-left-lighten-4 {
    border-right: 1px solid #d7ccc8 !important;
}
[dir='rtl'] .border-right-brown.border-right-lighten-4 {
    border-left: 1px solid #d7ccc8 !important;
}
.overlay-brown.overlay-lighten-4 {
    background: #d7ccc8;
    background: rgba(215, 204, 200, 0.8);
}
.brown.lighten-3 {
    color: #bcaaa4 !important;
}
.bg-brown.bg-lighten-3,
.btn-brown.btn-lighten-3 {
    background-color: #bcaaa4 !important;
}
.btn-brown.btn-lighten-3 {
    border-color: #5d4037 !important;
}
.btn-brown.btn-lighten-3:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-3:active,
.btn-brown.btn-lighten-3:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-lighten-3 {
    border-color: #bcaaa4 !important;
    color: #bcaaa4 !important;
}
.btn-outline-brown.btn-outline-lighten-3:hover {
    background-color: #bcaaa4 !important;
}
.progress-brown.progress-lighten-3 {
    background-color: #bcaaa4;
}
.progress-brown.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #bcaaa4;
}
.progress-brown.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #bcaaa4;
}
.progress-brown.progress-lighten-3[value]::-ms-fill {
    background-color: #bcaaa4;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-lighten-3 .progress-bar {
        background-color: #bcaaa4;
    }
}
.border-brown.border-lighten-3 {
    border: 1px solid #bcaaa4 !important;
}
.border-top-brown.border-top-lighten-3 {
    border-top: 1px solid #bcaaa4 !important;
}
.border-bottom-brown.border-bottom-lighten-3 {
    border-bottom: 1px solid #bcaaa4 !important;
}
[dir='ltr'] .border-left-brown.border-left-lighten-3 {
    border-left: 1px solid #bcaaa4 !important;
}
[dir='ltr'] .border-right-brown.border-right-lighten-3,
[dir='rtl'] .border-left-brown.border-left-lighten-3 {
    border-right: 1px solid #bcaaa4 !important;
}
[dir='rtl'] .border-right-brown.border-right-lighten-3 {
    border-left: 1px solid #bcaaa4 !important;
}
.overlay-brown.overlay-lighten-3 {
    background: #bcaaa4;
    background: rgba(188, 170, 164, 0.8);
}
.brown.lighten-2 {
    color: #a1887f !important;
}
.bg-brown.bg-lighten-2,
.btn-brown.btn-lighten-2 {
    background-color: #a1887f !important;
}
.btn-brown.btn-lighten-2 {
    border-color: #5d4037 !important;
}
.btn-brown.btn-lighten-2:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-2:active,
.btn-brown.btn-lighten-2:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-lighten-2 {
    border-color: #a1887f !important;
    color: #a1887f !important;
}
.btn-outline-brown.btn-outline-lighten-2:hover {
    background-color: #a1887f !important;
}
.progress-brown.progress-lighten-2 {
    background-color: #a1887f;
}
.progress-brown.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #a1887f;
}
.progress-brown.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #a1887f;
}
.progress-brown.progress-lighten-2[value]::-ms-fill {
    background-color: #a1887f;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-lighten-2 .progress-bar {
        background-color: #a1887f;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a1887f !important;
}
.border-brown.border-lighten-2 {
    border: 1px solid #a1887f !important;
}
.border-top-brown.border-top-lighten-2 {
    border-top: 1px solid #a1887f !important;
}
.border-bottom-brown.border-bottom-lighten-2 {
    border-bottom: 1px solid #a1887f !important;
}
[dir='ltr'] .border-left-brown.border-left-lighten-2 {
    border-left: 1px solid #a1887f !important;
}
[dir='ltr'] .border-right-brown.border-right-lighten-2,
[dir='rtl'] .border-left-brown.border-left-lighten-2 {
    border-right: 1px solid #a1887f !important;
}
[dir='rtl'] .border-right-brown.border-right-lighten-2 {
    border-left: 1px solid #a1887f !important;
}
.overlay-brown.overlay-lighten-2 {
    background: #a1887f;
    background: rgba(161, 136, 127, 0.8);
}
.brown.lighten-1 {
    color: #8d6e63 !important;
}
.bg-brown.bg-lighten-1,
.btn-brown.btn-lighten-1 {
    background-color: #8d6e63 !important;
}
.btn-brown.btn-lighten-1 {
    border-color: #5d4037 !important;
}
.btn-brown.btn-lighten-1:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-lighten-1:active,
.btn-brown.btn-lighten-1:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-lighten-1 {
    border-color: #8d6e63 !important;
    color: #8d6e63 !important;
}
.btn-outline-brown.btn-outline-lighten-1:hover {
    background-color: #8d6e63 !important;
}
.progress-brown.progress-lighten-1 {
    background-color: #8d6e63;
}
.progress-brown.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #8d6e63;
}
.progress-brown.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #8d6e63;
}
.progress-brown.progress-lighten-1[value]::-ms-fill {
    background-color: #8d6e63;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-lighten-1 .progress-bar {
        background-color: #8d6e63;
    }
}
.border-brown.border-lighten-1 {
    border: 1px solid #8d6e63 !important;
}
.border-top-brown.border-top-lighten-1 {
    border-top: 1px solid #8d6e63 !important;
}
.border-bottom-brown.border-bottom-lighten-1 {
    border-bottom: 1px solid #8d6e63 !important;
}
[dir='ltr'] .border-left-brown.border-left-lighten-1 {
    border-left: 1px solid #8d6e63 !important;
}
[dir='ltr'] .border-right-brown.border-right-lighten-1,
[dir='rtl'] .border-left-brown.border-left-lighten-1 {
    border-right: 1px solid #8d6e63 !important;
}
[dir='rtl'] .border-right-brown.border-right-lighten-1 {
    border-left: 1px solid #8d6e63 !important;
}
.overlay-brown.overlay-lighten-1 {
    background: #8d6e63;
    background: rgba(141, 110, 99, 0.8);
}
.brown {
    color: #795548 !important;
}
.bg-brown {
    background-color: #795548 !important;
}
.bg-brown .card-footer,
.bg-brown .card-header {
    background-color: initial;
}
.toast-brown {
    background-color: #795548;
}
.alert-brown {
    border-color: #795548 !important;
    background-color: #8f6555 !important;
    color: #090605 !important;
}
.alert-brown .alert-link {
    color: #000 !important;
}
.border-brown {
    border-color: #795548;
}
.border-top-brown {
    border-top-color: #795548;
}
.border-bottom-brown {
    border-bottom-color: #795548;
}
[dir='ltr'] .border-left-brown {
    border-left-color: #795548;
}
[dir='ltr'] .border-right-brown,
[dir='rtl'] .border-left-brown {
    border-right-color: #795548;
}
[dir='rtl'] .border-right-brown {
    border-left-color: #795548;
}
.badge-brown {
    background-color: #795548;
}
.panel-brown {
    border-color: #795548;
}
.panel-brown .panel-heading {
    color: #fff;
    border-color: #795548;
    background-color: #896052;
}
.bg-brown.tag-glow,
.border-brown.tag-glow {
    box-shadow: 0 0 10px #795548;
}
.overlay-brown {
    background: #795548;
    background: rgba(121, 85, 72, 0.8);
}
.card.card-outline-brown {
    border: 1px solid #795548;
    background-color: initial;
}
.card.card-outline-brown .card-footer,
.card.card-outline-brown .card-header {
    background-color: initial;
}
.btn-brown.btn-flat {
    background-color: initial !important;
    color: #795548;
    border: none;
}
.btn-brown.btn-fab,
.btn-brown.btn-raised {
    background-color: #795548 !important;
    color: #fff !important;
    border-color: #795548;
}
.btn-brown.btn-fab.active,
.btn-brown.btn-raised.active {
    background-color: #63453b !important;
    border-color: #63453b !important;
}
.btn-group-raised .btn-brown {
    background-color: #795548 !important;
    color: #fff !important;
}
.btn-outline-brown {
    border: 1px solid #795548;
    background-color: initial;
    color: #795548;
    box-shadow: none !important;
}
.btn-outline-brown:focus {
    background-color: initial !important;
    color: #795548 !important;
    box-shadow: transparent !important;
}
.btn-outline-brown.active {
    background-color: #795548 !important;
    color: #fff !important;
}
.btn-outline-brown:hover {
    background-color: #6f4e42 !important;
    color: #fff !important;
}
.btn-outline-brown:hover svg {
    color: #fff !important;
}
.btn-outline-brown.btn-fab,
.btn-outline-brown.btn-raised {
    border: 1px solid #795548;
    background-color: initial;
    color: #795548;
    box-shadow: none !important;
}
.btn-outline-brown.btn-fab:focus,
.btn-outline-brown.btn-raised:focus {
    background-color: initial;
}
.btn-outline-brown.btn-fab.active,
.btn-outline-brown.btn-raised.active {
    background-color: #795548 !important;
    color: #fff !important;
}
.btn-outline-brown.btn-fab:hover,
.btn-outline-brown.btn-raised:hover {
    background-color: #6f4e42 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-brown {
    background-color: #795548;
}
.progress .progress-bar.progress-bar-brown[value]::-webkit-progress-value {
    background-color: #795548;
}
.progress .progress-bar.progress-bar-brown[value]::-moz-progress-bar {
    background-color: #795548;
}
.progress .progress-bar.progress-bar-brown[value]::-ms-fill {
    background-color: #795548;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-brown .progress-bar {
        background-color: #795548;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #795548 !important;
}
.brown.darken-1 {
    color: #6d4c41 !important;
}
.bg-brown.bg-darken-1,
.btn-brown.btn-darken-1 {
    background-color: #6d4c41 !important;
}
.btn-brown.btn-darken-1,
.btn-brown.btn-darken-1:hover {
    border-color: #5d4037 !important;
}
.btn-brown.btn-darken-1:hover {
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-1:active,
.btn-brown.btn-darken-1:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-darken-1 {
    border-color: #6d4c41 !important;
    color: #6d4c41 !important;
}
.btn-outline-brown.btn-outline-darken-1:hover {
    background-color: #6d4c41 !important;
}
.progress-brown.progress-darken-1 {
    background-color: #6d4c41;
}
.progress-brown.progress-darken-1[value]::-webkit-progress-value {
    background-color: #6d4c41;
}
.progress-brown.progress-darken-1[value]::-moz-progress-bar {
    background-color: #6d4c41;
}
.progress-brown.progress-darken-1[value]::-ms-fill {
    background-color: #6d4c41;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-darken-1 .progress-bar {
        background-color: #6d4c41;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6d4c41 !important;
}
.border-brown.border-darken-1 {
    border: 1px solid #6d4c41 !important;
}
.border-top-brown.border-top-darken-1 {
    border-top: 1px solid #6d4c41 !important;
}
.border-bottom-brown.border-bottom-darken-1 {
    border-bottom: 1px solid #6d4c41 !important;
}
[dir='ltr'] .border-left-brown.border-left-darken-1 {
    border-left: 1px solid #6d4c41 !important;
}
[dir='ltr'] .border-right-brown.border-right-darken-1,
[dir='rtl'] .border-left-brown.border-left-darken-1 {
    border-right: 1px solid #6d4c41 !important;
}
[dir='rtl'] .border-right-brown.border-right-darken-1 {
    border-left: 1px solid #6d4c41 !important;
}
.overlay-brown.overlay-darken-1 {
    background: #6d4c41;
    background: rgba(109, 76, 65, 0.8);
}
.brown.darken-2 {
    color: #5d4037 !important;
}
.bg-brown.bg-darken-2,
.btn-brown.btn-darken-2 {
    background-color: #5d4037 !important;
}
.btn-brown.btn-darken-2,
.btn-brown.btn-darken-2:hover {
    border-color: #5d4037 !important;
}
.btn-brown.btn-darken-2:hover {
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-2:active,
.btn-brown.btn-darken-2:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-darken-2 {
    border-color: #5d4037 !important;
    color: #5d4037 !important;
}
.btn-outline-brown.btn-outline-darken-2:hover {
    background-color: #5d4037 !important;
}
.progress-brown.progress-darken-2 {
    background-color: #5d4037;
}
.progress-brown.progress-darken-2[value]::-webkit-progress-value {
    background-color: #5d4037;
}
.progress-brown.progress-darken-2[value]::-moz-progress-bar {
    background-color: #5d4037;
}
.progress-brown.progress-darken-2[value]::-ms-fill {
    background-color: #5d4037;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-darken-2 .progress-bar {
        background-color: #5d4037;
    }
}
.border-brown.border-darken-2 {
    border: 1px solid #5d4037 !important;
}
.border-top-brown.border-top-darken-2 {
    border-top: 1px solid #5d4037 !important;
}
.border-bottom-brown.border-bottom-darken-2 {
    border-bottom: 1px solid #5d4037 !important;
}
[dir='ltr'] .border-left-brown.border-left-darken-2 {
    border-left: 1px solid #5d4037 !important;
}
[dir='ltr'] .border-right-brown.border-right-darken-2,
[dir='rtl'] .border-left-brown.border-left-darken-2 {
    border-right: 1px solid #5d4037 !important;
}
[dir='rtl'] .border-right-brown.border-right-darken-2 {
    border-left: 1px solid #5d4037 !important;
}
.overlay-brown.overlay-darken-2 {
    background: #5d4037;
    background: rgba(93, 64, 55, 0.8);
}
.brown.darken-3 {
    color: #4e342e !important;
}
.bg-brown.bg-darken-3 {
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-3,
.btn-brown.btn-darken-3:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-3:active,
.btn-brown.btn-darken-3:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-darken-3 {
    border-color: #4e342e !important;
    color: #4e342e !important;
}
.btn-outline-brown.btn-outline-darken-3:hover {
    background-color: #4e342e !important;
}
.progress-brown.progress-darken-3 {
    background-color: #4e342e;
}
.progress-brown.progress-darken-3[value]::-webkit-progress-value {
    background-color: #4e342e;
}
.progress-brown.progress-darken-3[value]::-moz-progress-bar {
    background-color: #4e342e;
}
.progress-brown.progress-darken-3[value]::-ms-fill {
    background-color: #4e342e;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-darken-3 .progress-bar {
        background-color: #4e342e;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4e342e !important;
}
.border-brown.border-darken-3 {
    border: 1px solid #4e342e !important;
}
.border-top-brown.border-top-darken-3 {
    border-top: 1px solid #4e342e !important;
}
.border-bottom-brown.border-bottom-darken-3 {
    border-bottom: 1px solid #4e342e !important;
}
[dir='ltr'] .border-left-brown.border-left-darken-3 {
    border-left: 1px solid #4e342e !important;
}
[dir='ltr'] .border-right-brown.border-right-darken-3,
[dir='rtl'] .border-left-brown.border-left-darken-3 {
    border-right: 1px solid #4e342e !important;
}
[dir='rtl'] .border-right-brown.border-right-darken-3 {
    border-left: 1px solid #4e342e !important;
}
.overlay-brown.overlay-darken-3 {
    background: #4e342e;
    background: rgba(78, 52, 46, 0.8);
}
.brown.darken-4 {
    color: #3e2723 !important;
}
.bg-brown.bg-darken-4,
.btn-brown.btn-darken-4 {
    background-color: #3e2723 !important;
}
.btn-brown.btn-darken-4,
.btn-brown.btn-darken-4:hover {
    border-color: #5d4037 !important;
}
.btn-brown.btn-darken-4:hover {
    background-color: #4e342e !important;
}
.btn-brown.btn-darken-4:active,
.btn-brown.btn-darken-4:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-darken-4 {
    border-color: #3e2723 !important;
    color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-darken-4:hover {
    background-color: #3e2723 !important;
}
.progress-brown.progress-darken-4 {
    background-color: #3e2723;
}
.progress-brown.progress-darken-4[value]::-webkit-progress-value {
    background-color: #3e2723;
}
.progress-brown.progress-darken-4[value]::-moz-progress-bar {
    background-color: #3e2723;
}
.progress-brown.progress-darken-4[value]::-ms-fill {
    background-color: #3e2723;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-darken-4 .progress-bar {
        background-color: #3e2723;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3e2723 !important;
}
.border-brown.border-darken-4 {
    border: 1px solid #3e2723 !important;
}
.border-top-brown.border-top-darken-4 {
    border-top: 1px solid #3e2723 !important;
}
.border-bottom-brown.border-bottom-darken-4 {
    border-bottom: 1px solid #3e2723 !important;
}
[dir='ltr'] .border-left-brown.border-left-darken-4 {
    border-left: 1px solid #3e2723 !important;
}
[dir='ltr'] .border-right-brown.border-right-darken-4,
[dir='rtl'] .border-left-brown.border-left-darken-4 {
    border-right: 1px solid #3e2723 !important;
}
[dir='rtl'] .border-right-brown.border-right-darken-4 {
    border-left: 1px solid #3e2723 !important;
}
.overlay-brown.overlay-darken-4 {
    background: #3e2723;
    background: rgba(62, 39, 35, 0.8);
}
.brown.accent-1 {
    color: #d7ccc8 !important;
}
.bg-brown.bg-accent-1,
.btn-brown.btn-accent-1 {
    background-color: #d7ccc8 !important;
}
.btn-brown.btn-accent-1,
.btn-brown.btn-accent-1:hover {
    border-color: #5d4037 !important;
}
.btn-brown.btn-accent-1:hover {
    background-color: #4e342e !important;
}
.btn-brown.btn-accent-1:active,
.btn-brown.btn-accent-1:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-accent-1 {
    border-color: #d7ccc8 !important;
    color: #d7ccc8 !important;
}
.btn-outline-brown.btn-outline-accent-1:hover {
    background-color: #d7ccc8 !important;
}
.progress-brown.progress-accent-1 {
    background-color: #d7ccc8;
}
.progress-brown.progress-accent-1[value]::-webkit-progress-value {
    background-color: #d7ccc8;
}
.progress-brown.progress-accent-1[value]::-moz-progress-bar {
    background-color: #d7ccc8;
}
.progress-brown.progress-accent-1[value]::-ms-fill {
    background-color: #d7ccc8;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-accent-1 .progress-bar {
        background-color: #d7ccc8;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d7ccc8 !important;
}
.border-brown.border-accent-1 {
    border: 1px solid #d7ccc8 !important;
}
.border-top-brown.border-top-accent-1 {
    border-top: 1px solid #d7ccc8 !important;
}
.border-bottom-brown.border-bottom-accent-1 {
    border-bottom: 1px solid #d7ccc8 !important;
}
[dir='ltr'] .border-left-brown.border-left-accent-1 {
    border-left: 1px solid #d7ccc8 !important;
}
[dir='ltr'] .border-right-brown.border-right-accent-1,
[dir='rtl'] .border-left-brown.border-left-accent-1 {
    border-right: 1px solid #d7ccc8 !important;
}
[dir='rtl'] .border-right-brown.border-right-accent-1 {
    border-left: 1px solid #d7ccc8 !important;
}
.overlay-brown.overlay-accent-1 {
    background: #d7ccc8;
    background: rgba(215, 204, 200, 0.8);
}
.brown.accent-2 {
    color: #bcaaa4 !important;
}
.bg-brown.bg-accent-2,
.btn-brown.btn-accent-2 {
    background-color: #bcaaa4 !important;
}
.btn-brown.btn-accent-2,
.btn-brown.btn-accent-2:hover {
    border-color: #5d4037 !important;
}
.btn-brown.btn-accent-2:hover {
    background-color: #4e342e !important;
}
.btn-brown.btn-accent-2:active,
.btn-brown.btn-accent-2:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-accent-2 {
    border-color: #bcaaa4 !important;
    color: #bcaaa4 !important;
}
.btn-outline-brown.btn-outline-accent-2:hover {
    background-color: #bcaaa4 !important;
}
.progress-brown.progress-accent-2 {
    background-color: #bcaaa4;
}
.progress-brown.progress-accent-2[value]::-webkit-progress-value {
    background-color: #bcaaa4;
}
.progress-brown.progress-accent-2[value]::-moz-progress-bar {
    background-color: #bcaaa4;
}
.progress-brown.progress-accent-2[value]::-ms-fill {
    background-color: #bcaaa4;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-accent-2 .progress-bar {
        background-color: #bcaaa4;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bcaaa4 !important;
}
.border-brown.border-accent-2 {
    border: 1px solid #bcaaa4 !important;
}
.border-top-brown.border-top-accent-2 {
    border-top: 1px solid #bcaaa4 !important;
}
.border-bottom-brown.border-bottom-accent-2 {
    border-bottom: 1px solid #bcaaa4 !important;
}
[dir='ltr'] .border-left-brown.border-left-accent-2 {
    border-left: 1px solid #bcaaa4 !important;
}
[dir='ltr'] .border-right-brown.border-right-accent-2,
[dir='rtl'] .border-left-brown.border-left-accent-2 {
    border-right: 1px solid #bcaaa4 !important;
}
[dir='rtl'] .border-right-brown.border-right-accent-2 {
    border-left: 1px solid #bcaaa4 !important;
}
.overlay-brown.overlay-accent-2 {
    background: #bcaaa4;
    background: rgba(188, 170, 164, 0.8);
}
.brown.accent-3 {
    color: #8d6e63 !important;
}
.bg-brown.bg-accent-3,
.btn-brown.btn-accent-3 {
    background-color: #8d6e63 !important;
}
.btn-brown.btn-accent-3,
.btn-brown.btn-accent-3:hover {
    border-color: #5d4037 !important;
}
.btn-brown.btn-accent-3:hover {
    background-color: #4e342e !important;
}
.btn-brown.btn-accent-3:active,
.btn-brown.btn-accent-3:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-accent-3 {
    border-color: #8d6e63 !important;
    color: #8d6e63 !important;
}
.btn-outline-brown.btn-outline-accent-3:hover {
    background-color: #8d6e63 !important;
}
.progress-brown.progress-accent-3 {
    background-color: #8d6e63;
}
.progress-brown.progress-accent-3[value]::-webkit-progress-value {
    background-color: #8d6e63;
}
.progress-brown.progress-accent-3[value]::-moz-progress-bar {
    background-color: #8d6e63;
}
.progress-brown.progress-accent-3[value]::-ms-fill {
    background-color: #8d6e63;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-accent-3 .progress-bar {
        background-color: #8d6e63;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8d6e63 !important;
}
.border-brown.border-accent-3 {
    border: 1px solid #8d6e63 !important;
}
.border-top-brown.border-top-accent-3 {
    border-top: 1px solid #8d6e63 !important;
}
.border-bottom-brown.border-bottom-accent-3 {
    border-bottom: 1px solid #8d6e63 !important;
}
[dir='ltr'] .border-left-brown.border-left-accent-3 {
    border-left: 1px solid #8d6e63 !important;
}
[dir='ltr'] .border-right-brown.border-right-accent-3,
[dir='rtl'] .border-left-brown.border-left-accent-3 {
    border-right: 1px solid #8d6e63 !important;
}
[dir='rtl'] .border-right-brown.border-right-accent-3 {
    border-left: 1px solid #8d6e63 !important;
}
.overlay-brown.overlay-accent-3 {
    background: #8d6e63;
    background: rgba(141, 110, 99, 0.8);
}
.brown.accent-4 {
    color: #5d4037 !important;
}
.bg-brown.bg-accent-4,
.btn-brown.btn-accent-4 {
    background-color: #5d4037 !important;
}
.btn-brown.btn-accent-4,
.btn-brown.btn-accent-4:hover {
    border-color: #5d4037 !important;
}
.btn-brown.btn-accent-4:hover {
    background-color: #4e342e !important;
}
.btn-brown.btn-accent-4:active,
.btn-brown.btn-accent-4:focus {
    border-color: #4e342e !important;
    background-color: #3e2723 !important;
}
.btn-outline-brown.btn-outline-accent-4 {
    border-color: #5d4037 !important;
    color: #5d4037 !important;
}
.btn-outline-brown.btn-outline-accent-4:hover {
    background-color: #5d4037 !important;
}
.progress-brown.progress-accent-4 {
    background-color: #5d4037;
}
.progress-brown.progress-accent-4[value]::-webkit-progress-value {
    background-color: #5d4037;
}
.progress-brown.progress-accent-4[value]::-moz-progress-bar {
    background-color: #5d4037;
}
.progress-brown.progress-accent-4[value]::-ms-fill {
    background-color: #5d4037;
}
@media screen and (min-width: 0\0) {
    .progress-brown.progress-accent-4 .progress-bar {
        background-color: #5d4037;
    }
}
input:focus ~ .bg-brown {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5d4037 !important;
}
.border-brown.border-accent-4 {
    border: 1px solid #5d4037 !important;
}
.border-top-brown.border-top-accent-4 {
    border-top: 1px solid #5d4037 !important;
}
.border-bottom-brown.border-bottom-accent-4 {
    border-bottom: 1px solid #5d4037 !important;
}
[dir='ltr'] .border-left-brown.border-left-accent-4 {
    border-left: 1px solid #5d4037 !important;
}
[dir='ltr'] .border-right-brown.border-right-accent-4,
[dir='rtl'] .border-left-brown.border-left-accent-4 {
    border-right: 1px solid #5d4037 !important;
}
[dir='rtl'] .border-right-brown.border-right-accent-4 {
    border-left: 1px solid #5d4037 !important;
}
.overlay-brown.overlay-accent-4 {
    background: #5d4037;
    background: rgba(93, 64, 55, 0.8);
}
.grey.lighten-5 {
    color: #fafafa !important;
}
.bg-grey.bg-lighten-5,
.btn-grey.btn-lighten-5 {
    background-color: #fafafa !important;
}
.btn-grey.btn-lighten-5,
.btn-grey.btn-lighten-5:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-lighten-5:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-5:active,
.btn-grey.btn-lighten-5:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-lighten-5 {
    border-color: #fafafa !important;
    color: #fafafa !important;
}
.btn-outline-grey.btn-outline-lighten-5:hover {
    background-color: #fafafa !important;
}
.progress-grey.progress-lighten-5 {
    background-color: #fafafa;
}
.progress-grey.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fafafa;
}
.progress-grey.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fafafa;
}
.progress-grey.progress-lighten-5[value]::-ms-fill {
    background-color: #fafafa;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-lighten-5 .progress-bar {
        background-color: #fafafa;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fafafa !important;
}
.border-grey.border-lighten-5 {
    border: 1px solid #fafafa !important;
}
.border-top-grey.border-top-lighten-5 {
    border-top: 1px solid #fafafa !important;
}
.border-bottom-grey.border-bottom-lighten-5 {
    border-bottom: 1px solid #fafafa !important;
}
[dir='ltr'] .border-left-grey.border-left-lighten-5 {
    border-left: 1px solid #fafafa !important;
}
[dir='ltr'] .border-right-grey.border-right-lighten-5,
[dir='rtl'] .border-left-grey.border-left-lighten-5 {
    border-right: 1px solid #fafafa !important;
}
[dir='rtl'] .border-right-grey.border-right-lighten-5 {
    border-left: 1px solid #fafafa !important;
}
.overlay-grey.overlay-lighten-5 {
    background: #fafafa;
    background: hsla(0, 0%, 98%, 0.8);
}
.grey.lighten-4 {
    color: #f5f5f5 !important;
}
.bg-grey.bg-lighten-4,
.btn-grey.btn-lighten-4 {
    background-color: #f5f5f5 !important;
}
.btn-grey.btn-lighten-4,
.btn-grey.btn-lighten-4:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-lighten-4:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-4:active,
.btn-grey.btn-lighten-4:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-lighten-4 {
    border-color: #f5f5f5 !important;
    color: #f5f5f5 !important;
}
.btn-outline-grey.btn-outline-lighten-4:hover {
    background-color: #f5f5f5 !important;
}
.progress-grey.progress-lighten-4 {
    background-color: #f5f5f5;
}
.progress-grey.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #f5f5f5;
}
.progress-grey.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #f5f5f5;
}
.progress-grey.progress-lighten-4[value]::-ms-fill {
    background-color: #f5f5f5;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-lighten-4 .progress-bar {
        background-color: #f5f5f5;
    }
}
.border-grey.border-lighten-4 {
    border: 1px solid #f5f5f5 !important;
}
.border-top-grey.border-top-lighten-4 {
    border-top: 1px solid #f5f5f5 !important;
}
.border-bottom-grey.border-bottom-lighten-4 {
    border-bottom: 1px solid #f5f5f5 !important;
}
[dir='ltr'] .border-left-grey.border-left-lighten-4 {
    border-left: 1px solid #f5f5f5 !important;
}
[dir='ltr'] .border-right-grey.border-right-lighten-4,
[dir='rtl'] .border-left-grey.border-left-lighten-4 {
    border-right: 1px solid #f5f5f5 !important;
}
[dir='rtl'] .border-right-grey.border-right-lighten-4 {
    border-left: 1px solid #f5f5f5 !important;
}
.overlay-grey.overlay-lighten-4 {
    background: #f5f5f5;
    background: hsla(0, 0%, 96.1%, 0.8);
}
.grey.lighten-3 {
    color: #eee !important;
}
.bg-grey.bg-lighten-3,
.btn-grey.btn-lighten-3 {
    background-color: #eee !important;
}
.btn-grey.btn-lighten-3,
.btn-grey.btn-lighten-3:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-lighten-3:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-3:active,
.btn-grey.btn-lighten-3:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-lighten-3 {
    border-color: #eee !important;
    color: #eee !important;
}
.btn-outline-grey.btn-outline-lighten-3:hover {
    background-color: #eee !important;
}
.progress-grey.progress-lighten-3 {
    background-color: #eee;
}
.progress-grey.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #eee;
}
.progress-grey.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #eee;
}
.progress-grey.progress-lighten-3[value]::-ms-fill {
    background-color: #eee;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-lighten-3 .progress-bar {
        background-color: #eee;
    }
}
.border-grey.border-lighten-3 {
    border: 1px solid #eee !important;
}
.border-top-grey.border-top-lighten-3 {
    border-top: 1px solid #eee !important;
}
.border-bottom-grey.border-bottom-lighten-3 {
    border-bottom: 1px solid #eee !important;
}
[dir='ltr'] .border-left-grey.border-left-lighten-3 {
    border-left: 1px solid #eee !important;
}
[dir='ltr'] .border-right-grey.border-right-lighten-3,
[dir='rtl'] .border-left-grey.border-left-lighten-3 {
    border-right: 1px solid #eee !important;
}
[dir='rtl'] .border-right-grey.border-right-lighten-3 {
    border-left: 1px solid #eee !important;
}
.overlay-grey.overlay-lighten-3 {
    background: #eee;
    background: hsla(0, 0%, 93.3%, 0.8);
}
.grey.lighten-2 {
    color: #e0e0e0 !important;
}
.bg-grey.bg-lighten-2,
.btn-grey.btn-lighten-2 {
    background-color: #e0e0e0 !important;
}
.btn-grey.btn-lighten-2,
.btn-grey.btn-lighten-2:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-lighten-2:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-2:active,
.btn-grey.btn-lighten-2:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-lighten-2 {
    border-color: #e0e0e0 !important;
    color: #e0e0e0 !important;
}
.btn-outline-grey.btn-outline-lighten-2:hover {
    background-color: #e0e0e0 !important;
}
.progress-grey.progress-lighten-2 {
    background-color: #e0e0e0;
}
.progress-grey.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #e0e0e0;
}
.progress-grey.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #e0e0e0;
}
.progress-grey.progress-lighten-2[value]::-ms-fill {
    background-color: #e0e0e0;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-lighten-2 .progress-bar {
        background-color: #e0e0e0;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0e0e0 !important;
}
.border-grey.border-lighten-2 {
    border: 1px solid #e0e0e0 !important;
}
.border-top-grey.border-top-lighten-2 {
    border-top: 1px solid #e0e0e0 !important;
}
.border-bottom-grey.border-bottom-lighten-2 {
    border-bottom: 1px solid #e0e0e0 !important;
}
[dir='ltr'] .border-left-grey.border-left-lighten-2 {
    border-left: 1px solid #e0e0e0 !important;
}
[dir='ltr'] .border-right-grey.border-right-lighten-2,
[dir='rtl'] .border-left-grey.border-left-lighten-2 {
    border-right: 1px solid #e0e0e0 !important;
}
[dir='rtl'] .border-right-grey.border-right-lighten-2 {
    border-left: 1px solid #e0e0e0 !important;
}
.overlay-grey.overlay-lighten-2 {
    background: #e0e0e0;
    background: hsla(0, 0%, 87.8%, 0.8);
}
.grey.lighten-1 {
    color: #bdbdbd !important;
}
.bg-grey.bg-lighten-1,
.btn-grey.btn-lighten-1 {
    background-color: #bdbdbd !important;
}
.btn-grey.btn-lighten-1,
.btn-grey.btn-lighten-1:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-lighten-1:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-lighten-1:active,
.btn-grey.btn-lighten-1:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-lighten-1 {
    border-color: #bdbdbd !important;
    color: #bdbdbd !important;
}
.btn-outline-grey.btn-outline-lighten-1:hover {
    background-color: #bdbdbd !important;
}
.progress-grey.progress-lighten-1 {
    background-color: #bdbdbd;
}
.progress-grey.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #bdbdbd;
}
.progress-grey.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #bdbdbd;
}
.progress-grey.progress-lighten-1[value]::-ms-fill {
    background-color: #bdbdbd;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-lighten-1 .progress-bar {
        background-color: #bdbdbd;
    }
}
.border-grey.border-lighten-1 {
    border: 1px solid #bdbdbd !important;
}
.border-top-grey.border-top-lighten-1 {
    border-top: 1px solid #bdbdbd !important;
}
.border-bottom-grey.border-bottom-lighten-1 {
    border-bottom: 1px solid #bdbdbd !important;
}
[dir='ltr'] .border-left-grey.border-left-lighten-1 {
    border-left: 1px solid #bdbdbd !important;
}
[dir='ltr'] .border-right-grey.border-right-lighten-1,
[dir='rtl'] .border-left-grey.border-left-lighten-1 {
    border-right: 1px solid #bdbdbd !important;
}
[dir='rtl'] .border-right-grey.border-right-lighten-1 {
    border-left: 1px solid #bdbdbd !important;
}
.overlay-grey.overlay-lighten-1 {
    background: #bdbdbd;
    background: hsla(0, 0%, 74.1%, 0.8);
}
.grey {
    color: #9e9e9e !important;
}
.bg-grey {
    background-color: #9e9e9e !important;
}
.bg-grey .card-footer,
.bg-grey .card-header {
    background-color: initial;
}
.toast-grey {
    background-color: #9e9e9e;
}
.alert-grey {
    border-color: #9e9e9e !important;
    background-color: #b0b0b0 !important;
    color: #454545 !important;
}
.alert-grey .alert-link {
    color: #303030 !important;
}
.border-grey {
    border-color: #9e9e9e;
}
.border-top-grey {
    border-top-color: #9e9e9e;
}
.border-bottom-grey {
    border-bottom-color: #9e9e9e;
}
[dir='ltr'] .border-left-grey {
    border-left-color: #9e9e9e;
}
[dir='ltr'] .border-right-grey,
[dir='rtl'] .border-left-grey {
    border-right-color: #9e9e9e;
}
[dir='rtl'] .border-right-grey {
    border-left-color: #9e9e9e;
}
.badge-grey {
    background-color: #9e9e9e;
}
.panel-grey {
    border-color: #9e9e9e;
}
.panel-grey .panel-heading {
    color: #fff;
    border-color: #9e9e9e;
    background-color: #ababab;
}
.bg-grey.tag-glow,
.border-grey.tag-glow {
    box-shadow: 0 0 10px #9e9e9e;
}
.overlay-grey {
    background: #9e9e9e;
    background: hsla(0, 0%, 62%, 0.8);
}
.card.card-outline-grey {
    border: 1px solid #9e9e9e;
}
.card.card-outline-grey,
.card.card-outline-grey .card-footer,
.card.card-outline-grey .card-header {
    background-color: initial;
}
.btn-grey.btn-flat {
    background-color: initial !important;
    color: #9e9e9e;
    border: none;
}
.btn-grey.btn-fab,
.btn-grey.btn-raised {
    background-color: #9e9e9e !important;
    color: #fff !important;
    border-color: #9e9e9e;
}
.btn-grey.btn-fab.active,
.btn-grey.btn-raised.active {
    background-color: #8c8c8c !important;
    border-color: #8c8c8c !important;
}
.btn-group-raised .btn-grey {
    background-color: #9e9e9e !important;
    color: #fff !important;
}
.btn-outline-grey {
    border: 1px solid #9e9e9e;
    background-color: initial;
    color: #9e9e9e;
    box-shadow: none !important;
}
.btn-outline-grey:focus {
    background-color: initial !important;
    color: #9e9e9e !important;
    box-shadow: transparent !important;
}
.btn-outline-grey.active {
    background-color: #9e9e9e !important;
    color: #fff !important;
}
.btn-outline-grey:hover {
    background-color: #969696 !important;
    color: #fff !important;
}
.btn-outline-grey:hover svg {
    color: #fff !important;
}
.btn-outline-grey.btn-fab,
.btn-outline-grey.btn-raised {
    border: 1px solid #9e9e9e;
    background-color: initial;
    color: #9e9e9e;
    box-shadow: none !important;
}
.btn-outline-grey.btn-fab:focus,
.btn-outline-grey.btn-raised:focus {
    background-color: initial;
}
.btn-outline-grey.btn-fab.active,
.btn-outline-grey.btn-raised.active {
    background-color: #9e9e9e !important;
    color: #fff !important;
}
.btn-outline-grey.btn-fab:hover,
.btn-outline-grey.btn-raised:hover {
    background-color: #969696 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-grey {
    background-color: #9e9e9e;
}
.progress .progress-bar.progress-bar-grey[value]::-webkit-progress-value {
    background-color: #9e9e9e;
}
.progress .progress-bar.progress-bar-grey[value]::-moz-progress-bar {
    background-color: #9e9e9e;
}
.progress .progress-bar.progress-bar-grey[value]::-ms-fill {
    background-color: #9e9e9e;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-grey .progress-bar {
        background-color: #9e9e9e;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9e9e9e !important;
}
.grey.darken-1 {
    color: #757575 !important;
}
.bg-grey.bg-darken-1,
.btn-grey.btn-darken-1 {
    background-color: #757575 !important;
}
.btn-grey.btn-darken-1,
.btn-grey.btn-darken-1:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-darken-1:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-darken-1:active,
.btn-grey.btn-darken-1:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-darken-1 {
    border-color: #757575 !important;
    color: #757575 !important;
}
.btn-outline-grey.btn-outline-darken-1:hover {
    background-color: #757575 !important;
}
.progress-grey.progress-darken-1 {
    background-color: #757575;
}
.progress-grey.progress-darken-1[value]::-webkit-progress-value {
    background-color: #757575;
}
.progress-grey.progress-darken-1[value]::-moz-progress-bar {
    background-color: #757575;
}
.progress-grey.progress-darken-1[value]::-ms-fill {
    background-color: #757575;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-darken-1 .progress-bar {
        background-color: #757575;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #757575 !important;
}
.border-grey.border-darken-1 {
    border: 1px solid #757575 !important;
}
.border-top-grey.border-top-darken-1 {
    border-top: 1px solid #757575 !important;
}
.border-bottom-grey.border-bottom-darken-1 {
    border-bottom: 1px solid #757575 !important;
}
[dir='ltr'] .border-left-grey.border-left-darken-1 {
    border-left: 1px solid #757575 !important;
}
[dir='ltr'] .border-right-grey.border-right-darken-1,
[dir='rtl'] .border-left-grey.border-left-darken-1 {
    border-right: 1px solid #757575 !important;
}
[dir='rtl'] .border-right-grey.border-right-darken-1 {
    border-left: 1px solid #757575 !important;
}
.overlay-grey.overlay-darken-1 {
    background: #757575;
    background: hsla(0, 0%, 45.9%, 0.8);
}
.grey.darken-2 {
    color: #616161 !important;
}
.bg-grey.bg-darken-2,
.btn-grey.btn-darken-2 {
    background-color: #616161 !important;
}
.btn-grey.btn-darken-2,
.btn-grey.btn-darken-2:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-darken-2:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-darken-2:active,
.btn-grey.btn-darken-2:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-darken-2 {
    border-color: #616161 !important;
    color: #616161 !important;
}
.btn-outline-grey.btn-outline-darken-2:hover {
    background-color: #616161 !important;
}
.progress-grey.progress-darken-2 {
    background-color: #616161;
}
.progress-grey.progress-darken-2[value]::-webkit-progress-value {
    background-color: #616161;
}
.progress-grey.progress-darken-2[value]::-moz-progress-bar {
    background-color: #616161;
}
.progress-grey.progress-darken-2[value]::-ms-fill {
    background-color: #616161;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-darken-2 .progress-bar {
        background-color: #616161;
    }
}
.border-grey.border-darken-2 {
    border: 1px solid #616161 !important;
}
.border-top-grey.border-top-darken-2 {
    border-top: 1px solid #616161 !important;
}
.border-bottom-grey.border-bottom-darken-2 {
    border-bottom: 1px solid #616161 !important;
}
[dir='ltr'] .border-left-grey.border-left-darken-2 {
    border-left: 1px solid #616161 !important;
}
[dir='ltr'] .border-right-grey.border-right-darken-2,
[dir='rtl'] .border-left-grey.border-left-darken-2 {
    border-right: 1px solid #616161 !important;
}
[dir='rtl'] .border-right-grey.border-right-darken-2 {
    border-left: 1px solid #616161 !important;
}
.overlay-grey.overlay-darken-2 {
    background: #616161;
    background: rgba(97, 97, 97, 0.8);
}
.grey.darken-3 {
    color: #424242 !important;
}
.bg-grey.bg-darken-3 {
    background-color: #424242 !important;
}
.btn-grey.btn-darken-3,
.btn-grey.btn-darken-3:hover {
    border-color: #616161 !important;
    background-color: #424242 !important;
}
.btn-grey.btn-darken-3:active,
.btn-grey.btn-darken-3:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-darken-3 {
    border-color: #424242 !important;
    color: #424242 !important;
}
.btn-outline-grey.btn-outline-darken-3:hover {
    background-color: #424242 !important;
}
.progress-grey.progress-darken-3 {
    background-color: #424242;
}
.progress-grey.progress-darken-3[value]::-webkit-progress-value {
    background-color: #424242;
}
.progress-grey.progress-darken-3[value]::-moz-progress-bar {
    background-color: #424242;
}
.progress-grey.progress-darken-3[value]::-ms-fill {
    background-color: #424242;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-darken-3 .progress-bar {
        background-color: #424242;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #424242 !important;
}
.border-grey.border-darken-3 {
    border: 1px solid #424242 !important;
}
.border-top-grey.border-top-darken-3 {
    border-top: 1px solid #424242 !important;
}
.border-bottom-grey.border-bottom-darken-3 {
    border-bottom: 1px solid #424242 !important;
}
[dir='ltr'] .border-left-grey.border-left-darken-3 {
    border-left: 1px solid #424242 !important;
}
[dir='ltr'] .border-right-grey.border-right-darken-3,
[dir='rtl'] .border-left-grey.border-left-darken-3 {
    border-right: 1px solid #424242 !important;
}
[dir='rtl'] .border-right-grey.border-right-darken-3 {
    border-left: 1px solid #424242 !important;
}
.overlay-grey.overlay-darken-3 {
    background: #424242;
    background: rgba(66, 66, 66, 0.8);
}
.grey.darken-4 {
    color: #212121 !important;
}
.bg-grey.bg-darken-4,
.btn-grey.btn-darken-4 {
    background-color: #212121 !important;
}
.btn-grey.btn-darken-4,
.btn-grey.btn-darken-4:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-darken-4:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-darken-4:active,
.btn-grey.btn-darken-4:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-darken-4 {
    border-color: #212121 !important;
    color: #212121 !important;
}
.btn-outline-grey.btn-outline-darken-4:hover {
    background-color: #212121 !important;
}
.progress-grey.progress-darken-4 {
    background-color: #212121;
}
.progress-grey.progress-darken-4[value]::-webkit-progress-value {
    background-color: #212121;
}
.progress-grey.progress-darken-4[value]::-moz-progress-bar {
    background-color: #212121;
}
.progress-grey.progress-darken-4[value]::-ms-fill {
    background-color: #212121;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-darken-4 .progress-bar {
        background-color: #212121;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #212121 !important;
}
.border-grey.border-darken-4 {
    border: 1px solid #212121 !important;
}
.border-top-grey.border-top-darken-4 {
    border-top: 1px solid #212121 !important;
}
.border-bottom-grey.border-bottom-darken-4 {
    border-bottom: 1px solid #212121 !important;
}
[dir='ltr'] .border-left-grey.border-left-darken-4 {
    border-left: 1px solid #212121 !important;
}
[dir='ltr'] .border-right-grey.border-right-darken-4,
[dir='rtl'] .border-left-grey.border-left-darken-4 {
    border-right: 1px solid #212121 !important;
}
[dir='rtl'] .border-right-grey.border-right-darken-4 {
    border-left: 1px solid #212121 !important;
}
.overlay-grey.overlay-darken-4 {
    background: #212121;
    background: rgba(33, 33, 33, 0.8);
}
.grey.accent-1 {
    color: #f5f5f5 !important;
}
.bg-grey.bg-accent-1,
.btn-grey.btn-accent-1 {
    background-color: #f5f5f5 !important;
}
.btn-grey.btn-accent-1,
.btn-grey.btn-accent-1:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-accent-1:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-accent-1:active,
.btn-grey.btn-accent-1:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-accent-1 {
    border-color: #f5f5f5 !important;
    color: #f5f5f5 !important;
}
.btn-outline-grey.btn-outline-accent-1:hover {
    background-color: #f5f5f5 !important;
}
.progress-grey.progress-accent-1 {
    background-color: #f5f5f5;
}
.progress-grey.progress-accent-1[value]::-webkit-progress-value {
    background-color: #f5f5f5;
}
.progress-grey.progress-accent-1[value]::-moz-progress-bar {
    background-color: #f5f5f5;
}
.progress-grey.progress-accent-1[value]::-ms-fill {
    background-color: #f5f5f5;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-accent-1 .progress-bar {
        background-color: #f5f5f5;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f5f5f5 !important;
}
.border-grey.border-accent-1 {
    border: 1px solid #f5f5f5 !important;
}
.border-top-grey.border-top-accent-1 {
    border-top: 1px solid #f5f5f5 !important;
}
.border-bottom-grey.border-bottom-accent-1 {
    border-bottom: 1px solid #f5f5f5 !important;
}
[dir='ltr'] .border-left-grey.border-left-accent-1 {
    border-left: 1px solid #f5f5f5 !important;
}
[dir='ltr'] .border-right-grey.border-right-accent-1,
[dir='rtl'] .border-left-grey.border-left-accent-1 {
    border-right: 1px solid #f5f5f5 !important;
}
[dir='rtl'] .border-right-grey.border-right-accent-1 {
    border-left: 1px solid #f5f5f5 !important;
}
.overlay-grey.overlay-accent-1 {
    background: #f5f5f5;
    background: hsla(0, 0%, 96.1%, 0.8);
}
.grey.accent-2 {
    color: #eee !important;
}
.bg-grey.bg-accent-2,
.btn-grey.btn-accent-2 {
    background-color: #eee !important;
}
.btn-grey.btn-accent-2,
.btn-grey.btn-accent-2:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-accent-2:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-accent-2:active,
.btn-grey.btn-accent-2:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-accent-2 {
    border-color: #eee !important;
    color: #eee !important;
}
.btn-outline-grey.btn-outline-accent-2:hover {
    background-color: #eee !important;
}
.progress-grey.progress-accent-2 {
    background-color: #eee;
}
.progress-grey.progress-accent-2[value]::-webkit-progress-value {
    background-color: #eee;
}
.progress-grey.progress-accent-2[value]::-moz-progress-bar {
    background-color: #eee;
}
.progress-grey.progress-accent-2[value]::-ms-fill {
    background-color: #eee;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-accent-2 .progress-bar {
        background-color: #eee;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eee !important;
}
.border-grey.border-accent-2 {
    border: 1px solid #eee !important;
}
.border-top-grey.border-top-accent-2 {
    border-top: 1px solid #eee !important;
}
.border-bottom-grey.border-bottom-accent-2 {
    border-bottom: 1px solid #eee !important;
}
[dir='ltr'] .border-left-grey.border-left-accent-2 {
    border-left: 1px solid #eee !important;
}
[dir='ltr'] .border-right-grey.border-right-accent-2,
[dir='rtl'] .border-left-grey.border-left-accent-2 {
    border-right: 1px solid #eee !important;
}
[dir='rtl'] .border-right-grey.border-right-accent-2 {
    border-left: 1px solid #eee !important;
}
.overlay-grey.overlay-accent-2 {
    background: #eee;
    background: hsla(0, 0%, 93.3%, 0.8);
}
.grey.accent-3 {
    color: #bdbdbd !important;
}
.bg-grey.bg-accent-3,
.btn-grey.btn-accent-3 {
    background-color: #bdbdbd !important;
}
.btn-grey.btn-accent-3,
.btn-grey.btn-accent-3:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-accent-3:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-accent-3:active,
.btn-grey.btn-accent-3:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-accent-3 {
    border-color: #bdbdbd !important;
    color: #bdbdbd !important;
}
.btn-outline-grey.btn-outline-accent-3:hover {
    background-color: #bdbdbd !important;
}
.progress-grey.progress-accent-3 {
    background-color: #bdbdbd;
}
.progress-grey.progress-accent-3[value]::-webkit-progress-value {
    background-color: #bdbdbd;
}
.progress-grey.progress-accent-3[value]::-moz-progress-bar {
    background-color: #bdbdbd;
}
.progress-grey.progress-accent-3[value]::-ms-fill {
    background-color: #bdbdbd;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-accent-3 .progress-bar {
        background-color: #bdbdbd;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bdbdbd !important;
}
.border-grey.border-accent-3 {
    border: 1px solid #bdbdbd !important;
}
.border-top-grey.border-top-accent-3 {
    border-top: 1px solid #bdbdbd !important;
}
.border-bottom-grey.border-bottom-accent-3 {
    border-bottom: 1px solid #bdbdbd !important;
}
[dir='ltr'] .border-left-grey.border-left-accent-3 {
    border-left: 1px solid #bdbdbd !important;
}
[dir='ltr'] .border-right-grey.border-right-accent-3,
[dir='rtl'] .border-left-grey.border-left-accent-3 {
    border-right: 1px solid #bdbdbd !important;
}
[dir='rtl'] .border-right-grey.border-right-accent-3 {
    border-left: 1px solid #bdbdbd !important;
}
.overlay-grey.overlay-accent-3 {
    background: #bdbdbd;
    background: hsla(0, 0%, 74.1%, 0.8);
}
.grey.accent-4 {
    color: #616161 !important;
}
.bg-grey.bg-accent-4,
.btn-grey.btn-accent-4 {
    background-color: #616161 !important;
}
.btn-grey.btn-accent-4,
.btn-grey.btn-accent-4:hover {
    border-color: #616161 !important;
}
.btn-grey.btn-accent-4:hover {
    background-color: #424242 !important;
}
.btn-grey.btn-accent-4:active,
.btn-grey.btn-accent-4:focus {
    border-color: #424242 !important;
    background-color: #212121 !important;
}
.btn-outline-grey.btn-outline-accent-4 {
    border-color: #616161 !important;
    color: #616161 !important;
}
.btn-outline-grey.btn-outline-accent-4:hover {
    background-color: #616161 !important;
}
.progress-grey.progress-accent-4 {
    background-color: #616161;
}
.progress-grey.progress-accent-4[value]::-webkit-progress-value {
    background-color: #616161;
}
.progress-grey.progress-accent-4[value]::-moz-progress-bar {
    background-color: #616161;
}
.progress-grey.progress-accent-4[value]::-ms-fill {
    background-color: #616161;
}
@media screen and (min-width: 0\0) {
    .progress-grey.progress-accent-4 .progress-bar {
        background-color: #616161;
    }
}
input:focus ~ .bg-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #616161 !important;
}
.border-grey.border-accent-4 {
    border: 1px solid #616161 !important;
}
.border-top-grey.border-top-accent-4 {
    border-top: 1px solid #616161 !important;
}
.border-bottom-grey.border-bottom-accent-4 {
    border-bottom: 1px solid #616161 !important;
}
[dir='ltr'] .border-left-grey.border-left-accent-4 {
    border-left: 1px solid #616161 !important;
}
[dir='ltr'] .border-right-grey.border-right-accent-4,
[dir='rtl'] .border-left-grey.border-left-accent-4 {
    border-right: 1px solid #616161 !important;
}
[dir='rtl'] .border-right-grey.border-right-accent-4 {
    border-left: 1px solid #616161 !important;
}
.overlay-grey.overlay-accent-4 {
    background: #616161;
    background: rgba(97, 97, 97, 0.8);
}
.blue-grey.lighten-5 {
    color: #eceff1 !important;
}
.bg-blue-grey.bg-lighten-5,
.btn-blue-grey.btn-lighten-5 {
    background-color: #eceff1 !important;
}
.btn-blue-grey.btn-lighten-5 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-lighten-5:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-5:active,
.btn-blue-grey.btn-lighten-5:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-5 {
    border-color: #eceff1 !important;
    color: #eceff1 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-5:hover {
    background-color: #eceff1 !important;
}
.progress-blue-grey.progress-lighten-5 {
    background-color: #eceff1;
}
.progress-blue-grey.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #eceff1;
}
.progress-blue-grey.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #eceff1;
}
.progress-blue-grey.progress-lighten-5[value]::-ms-fill {
    background-color: #eceff1;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-lighten-5 .progress-bar {
        background-color: #eceff1;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eceff1 !important;
}
.border-blue-grey.border-lighten-5 {
    border: 1px solid #eceff1 !important;
}
.border-top-blue-grey.border-top-lighten-5 {
    border-top: 1px solid #eceff1 !important;
}
.border-bottom-blue-grey.border-bottom-lighten-5 {
    border-bottom: 1px solid #eceff1 !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-lighten-5 {
    border-left: 1px solid #eceff1 !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-lighten-5,
[dir='rtl'] .border-left-blue-grey.border-left-lighten-5 {
    border-right: 1px solid #eceff1 !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-lighten-5 {
    border-left: 1px solid #eceff1 !important;
}
.overlay-blue-grey.overlay-lighten-5 {
    background: #eceff1;
    background: rgba(236, 239, 241, 0.8);
}
.blue-grey.lighten-4 {
    color: #cfd8dc !important;
}
.bg-blue-grey.bg-lighten-4,
.btn-blue-grey.btn-lighten-4 {
    background-color: #cfd8dc !important;
}
.btn-blue-grey.btn-lighten-4 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-lighten-4:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-4:active,
.btn-blue-grey.btn-lighten-4:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-4 {
    border-color: #cfd8dc !important;
    color: #cfd8dc !important;
}
.btn-outline-blue-grey.btn-outline-lighten-4:hover {
    background-color: #cfd8dc !important;
}
.progress-blue-grey.progress-lighten-4 {
    background-color: #cfd8dc;
}
.progress-blue-grey.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #cfd8dc;
}
.progress-blue-grey.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #cfd8dc;
}
.progress-blue-grey.progress-lighten-4[value]::-ms-fill {
    background-color: #cfd8dc;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-lighten-4 .progress-bar {
        background-color: #cfd8dc;
    }
}
.border-blue-grey.border-lighten-4 {
    border: 1px solid #cfd8dc !important;
}
.border-top-blue-grey.border-top-lighten-4 {
    border-top: 1px solid #cfd8dc !important;
}
.border-bottom-blue-grey.border-bottom-lighten-4 {
    border-bottom: 1px solid #cfd8dc !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-lighten-4 {
    border-left: 1px solid #cfd8dc !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-lighten-4,
[dir='rtl'] .border-left-blue-grey.border-left-lighten-4 {
    border-right: 1px solid #cfd8dc !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-lighten-4 {
    border-left: 1px solid #cfd8dc !important;
}
.overlay-blue-grey.overlay-lighten-4 {
    background: #cfd8dc;
    background: rgba(207, 216, 220, 0.8);
}
.blue-grey.lighten-3 {
    color: #b0bec5 !important;
}
.bg-blue-grey.bg-lighten-3,
.btn-blue-grey.btn-lighten-3 {
    background-color: #b0bec5 !important;
}
.btn-blue-grey.btn-lighten-3 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-lighten-3:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-3:active,
.btn-blue-grey.btn-lighten-3:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-3 {
    border-color: #b0bec5 !important;
    color: #b0bec5 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-3:hover {
    background-color: #b0bec5 !important;
}
.progress-blue-grey.progress-lighten-3 {
    background-color: #b0bec5;
}
.progress-blue-grey.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #b0bec5;
}
.progress-blue-grey.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #b0bec5;
}
.progress-blue-grey.progress-lighten-3[value]::-ms-fill {
    background-color: #b0bec5;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-lighten-3 .progress-bar {
        background-color: #b0bec5;
    }
}
.border-blue-grey.border-lighten-3 {
    border: 1px solid #b0bec5 !important;
}
.border-top-blue-grey.border-top-lighten-3 {
    border-top: 1px solid #b0bec5 !important;
}
.border-bottom-blue-grey.border-bottom-lighten-3 {
    border-bottom: 1px solid #b0bec5 !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-lighten-3 {
    border-left: 1px solid #b0bec5 !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-lighten-3,
[dir='rtl'] .border-left-blue-grey.border-left-lighten-3 {
    border-right: 1px solid #b0bec5 !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-lighten-3 {
    border-left: 1px solid #b0bec5 !important;
}
.overlay-blue-grey.overlay-lighten-3 {
    background: #b0bec5;
    background: rgba(176, 190, 197, 0.8);
}
.blue-grey.lighten-2 {
    color: #90a4ae !important;
}
.bg-blue-grey.bg-lighten-2,
.btn-blue-grey.btn-lighten-2 {
    background-color: #90a4ae !important;
}
.btn-blue-grey.btn-lighten-2 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-lighten-2:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-2:active,
.btn-blue-grey.btn-lighten-2:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-2 {
    border-color: #90a4ae !important;
    color: #90a4ae !important;
}
.btn-outline-blue-grey.btn-outline-lighten-2:hover {
    background-color: #90a4ae !important;
}
.progress-blue-grey.progress-lighten-2 {
    background-color: #90a4ae;
}
.progress-blue-grey.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #90a4ae;
}
.progress-blue-grey.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #90a4ae;
}
.progress-blue-grey.progress-lighten-2[value]::-ms-fill {
    background-color: #90a4ae;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-lighten-2 .progress-bar {
        background-color: #90a4ae;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90a4ae !important;
}
.border-blue-grey.border-lighten-2 {
    border: 1px solid #90a4ae !important;
}
.border-top-blue-grey.border-top-lighten-2 {
    border-top: 1px solid #90a4ae !important;
}
.border-bottom-blue-grey.border-bottom-lighten-2 {
    border-bottom: 1px solid #90a4ae !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-lighten-2 {
    border-left: 1px solid #90a4ae !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-lighten-2,
[dir='rtl'] .border-left-blue-grey.border-left-lighten-2 {
    border-right: 1px solid #90a4ae !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-lighten-2 {
    border-left: 1px solid #90a4ae !important;
}
.overlay-blue-grey.overlay-lighten-2 {
    background: #90a4ae;
    background: rgba(144, 164, 174, 0.8);
}
.blue-grey.lighten-1 {
    color: #78909c !important;
}
.bg-blue-grey.bg-lighten-1,
.btn-blue-grey.btn-lighten-1 {
    background-color: #78909c !important;
}
.btn-blue-grey.btn-lighten-1 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-lighten-1:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-lighten-1:active,
.btn-blue-grey.btn-lighten-1:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-lighten-1 {
    border-color: #78909c !important;
    color: #78909c !important;
}
.btn-outline-blue-grey.btn-outline-lighten-1:hover {
    background-color: #78909c !important;
}
.progress-blue-grey.progress-lighten-1 {
    background-color: #78909c;
}
.progress-blue-grey.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #78909c;
}
.progress-blue-grey.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #78909c;
}
.progress-blue-grey.progress-lighten-1[value]::-ms-fill {
    background-color: #78909c;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-lighten-1 .progress-bar {
        background-color: #78909c;
    }
}
.border-blue-grey.border-lighten-1 {
    border: 1px solid #78909c !important;
}
.border-top-blue-grey.border-top-lighten-1 {
    border-top: 1px solid #78909c !important;
}
.border-bottom-blue-grey.border-bottom-lighten-1 {
    border-bottom: 1px solid #78909c !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-lighten-1 {
    border-left: 1px solid #78909c !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-lighten-1,
[dir='rtl'] .border-left-blue-grey.border-left-lighten-1 {
    border-right: 1px solid #78909c !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-lighten-1 {
    border-left: 1px solid #78909c !important;
}
.overlay-blue-grey.overlay-lighten-1 {
    background: #78909c;
    background: rgba(120, 144, 156, 0.8);
}
.blue-grey {
    color: #607d8b !important;
}
.bg-blue-grey {
    background-color: #607d8b !important;
}
.bg-blue-grey .card-footer,
.bg-blue-grey .card-header {
    background-color: initial;
}
.toast-blue-grey {
    background-color: #607d8b;
}
.alert-blue-grey {
    border-color: #607d8b !important;
    background-color: #718f9d !important;
    color: #171e21 !important;
}
.alert-blue-grey .alert-link {
    color: #060809 !important;
}
.border-blue-grey {
    border-color: #607d8b;
}
.border-top-blue-grey {
    border-top-color: #607d8b;
}
.border-bottom-blue-grey {
    border-bottom-color: #607d8b;
}
[dir='ltr'] .border-left-blue-grey {
    border-left-color: #607d8b;
}
[dir='ltr'] .border-right-blue-grey,
[dir='rtl'] .border-left-blue-grey {
    border-right-color: #607d8b;
}
[dir='rtl'] .border-right-blue-grey {
    border-left-color: #607d8b;
}
.badge-blue-grey {
    background-color: #607d8b;
}
.panel-blue-grey {
    border-color: #607d8b;
}
.panel-blue-grey .panel-heading {
    color: #fff;
    border-color: #607d8b;
    background-color: #6b8a99;
}
.bg-blue-grey.tag-glow,
.border-blue-grey.tag-glow {
    box-shadow: 0 0 10px #607d8b;
}
.overlay-blue-grey {
    background: #607d8b;
    background: rgba(96, 125, 139, 0.8);
}
.card.card-outline-blue-grey {
    border: 1px solid #607d8b;
    background-color: initial;
}
.card.card-outline-blue-grey .card-footer,
.card.card-outline-blue-grey .card-header {
    background-color: initial;
}
.btn-blue-grey.btn-flat {
    background-color: initial !important;
    color: #607d8b;
    border: none;
}
.btn-blue-grey.btn-fab,
.btn-blue-grey.btn-raised {
    background-color: #607d8b !important;
    color: #fff !important;
    border-color: #607d8b;
}
.btn-blue-grey.btn-fab.active,
.btn-blue-grey.btn-raised.active {
    background-color: #516a76 !important;
    border-color: #516a76 !important;
}
.btn-group-raised .btn-blue-grey {
    background-color: #607d8b !important;
    color: #fff !important;
}
.btn-outline-blue-grey {
    border: 1px solid #607d8b;
    background-color: initial;
    color: #607d8b;
    box-shadow: none !important;
}
.btn-outline-blue-grey:focus {
    background-color: initial !important;
    color: #607d8b !important;
    box-shadow: transparent !important;
}
.btn-outline-blue-grey.active {
    background-color: #607d8b !important;
    color: #fff !important;
}
.btn-outline-blue-grey:hover {
    background-color: #5a7582 !important;
    color: #fff !important;
}
.btn-outline-blue-grey:hover svg {
    color: #fff !important;
}
.btn-outline-blue-grey.btn-fab,
.btn-outline-blue-grey.btn-raised {
    border: 1px solid #607d8b;
    background-color: initial;
    color: #607d8b;
    box-shadow: none !important;
}
.btn-outline-blue-grey.btn-fab:focus,
.btn-outline-blue-grey.btn-raised:focus {
    background-color: initial;
}
.btn-outline-blue-grey.btn-fab.active,
.btn-outline-blue-grey.btn-raised.active {
    background-color: #607d8b !important;
    color: #fff !important;
}
.btn-outline-blue-grey.btn-fab:hover,
.btn-outline-blue-grey.btn-raised:hover {
    background-color: #5a7582 !important;
    color: #fff !important;
}
.progress .progress-bar.progress-bar-blue-grey {
    background-color: #607d8b;
}
.progress .progress-bar.progress-bar-blue-grey[value]::-webkit-progress-value {
    background-color: #607d8b;
}
.progress .progress-bar.progress-bar-blue-grey[value]::-moz-progress-bar {
    background-color: #607d8b;
}
.progress .progress-bar.progress-bar-blue-grey[value]::-ms-fill {
    background-color: #607d8b;
}
@media screen and (min-width: 0\0) {
    .progress .progress-bar.progress-bar-blue-grey .progress-bar {
        background-color: #607d8b;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #607d8b !important;
}
.blue-grey.darken-1 {
    color: #546e7a !important;
}
.bg-blue-grey.bg-darken-1,
.btn-blue-grey.btn-darken-1 {
    background-color: #546e7a !important;
}
.btn-blue-grey.btn-darken-1 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-darken-1:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-1:active,
.btn-blue-grey.btn-darken-1:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-darken-1 {
    border-color: #546e7a !important;
    color: #546e7a !important;
}
.btn-outline-blue-grey.btn-outline-darken-1:hover {
    background-color: #546e7a !important;
}
.progress-blue-grey.progress-darken-1 {
    background-color: #546e7a;
}
.progress-blue-grey.progress-darken-1[value]::-webkit-progress-value {
    background-color: #546e7a;
}
.progress-blue-grey.progress-darken-1[value]::-moz-progress-bar {
    background-color: #546e7a;
}
.progress-blue-grey.progress-darken-1[value]::-ms-fill {
    background-color: #546e7a;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-darken-1 .progress-bar {
        background-color: #546e7a;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #546e7a !important;
}
.border-blue-grey.border-darken-1 {
    border: 1px solid #546e7a !important;
}
.border-top-blue-grey.border-top-darken-1 {
    border-top: 1px solid #546e7a !important;
}
.border-bottom-blue-grey.border-bottom-darken-1 {
    border-bottom: 1px solid #546e7a !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-darken-1 {
    border-left: 1px solid #546e7a !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-darken-1,
[dir='rtl'] .border-left-blue-grey.border-left-darken-1 {
    border-right: 1px solid #546e7a !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-darken-1 {
    border-left: 1px solid #546e7a !important;
}
.overlay-blue-grey.overlay-darken-1 {
    background: #546e7a;
    background: rgba(84, 110, 122, 0.8);
}
.blue-grey.darken-2 {
    color: #455a64 !important;
}
.bg-blue-grey.bg-darken-2,
.btn-blue-grey.btn-darken-2 {
    background-color: #455a64 !important;
}
.btn-blue-grey.btn-darken-2 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-darken-2:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-2:active,
.btn-blue-grey.btn-darken-2:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-darken-2 {
    border-color: #455a64 !important;
    color: #455a64 !important;
}
.btn-outline-blue-grey.btn-outline-darken-2:hover {
    background-color: #455a64 !important;
}
.progress-blue-grey.progress-darken-2 {
    background-color: #455a64;
}
.progress-blue-grey.progress-darken-2[value]::-webkit-progress-value {
    background-color: #455a64;
}
.progress-blue-grey.progress-darken-2[value]::-moz-progress-bar {
    background-color: #455a64;
}
.progress-blue-grey.progress-darken-2[value]::-ms-fill {
    background-color: #455a64;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-darken-2 .progress-bar {
        background-color: #455a64;
    }
}
.border-blue-grey.border-darken-2 {
    border: 1px solid #455a64 !important;
}
.border-top-blue-grey.border-top-darken-2 {
    border-top: 1px solid #455a64 !important;
}
.border-bottom-blue-grey.border-bottom-darken-2 {
    border-bottom: 1px solid #455a64 !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-darken-2 {
    border-left: 1px solid #455a64 !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-darken-2,
[dir='rtl'] .border-left-blue-grey.border-left-darken-2 {
    border-right: 1px solid #455a64 !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-darken-2 {
    border-left: 1px solid #455a64 !important;
}
.overlay-blue-grey.overlay-darken-2 {
    background: #455a64;
    background: rgba(69, 90, 100, 0.8);
}
.blue-grey.darken-3 {
    color: #37474f !important;
}
.bg-blue-grey.bg-darken-3 {
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-3,
.btn-blue-grey.btn-darken-3:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-3:active,
.btn-blue-grey.btn-darken-3:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-darken-3 {
    border-color: #37474f !important;
    color: #37474f !important;
}
.btn-outline-blue-grey.btn-outline-darken-3:hover {
    background-color: #37474f !important;
}
.progress-blue-grey.progress-darken-3 {
    background-color: #37474f;
}
.progress-blue-grey.progress-darken-3[value]::-webkit-progress-value {
    background-color: #37474f;
}
.progress-blue-grey.progress-darken-3[value]::-moz-progress-bar {
    background-color: #37474f;
}
.progress-blue-grey.progress-darken-3[value]::-ms-fill {
    background-color: #37474f;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-darken-3 .progress-bar {
        background-color: #37474f;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #37474f !important;
}
.border-blue-grey.border-darken-3 {
    border: 1px solid #37474f !important;
}
.border-top-blue-grey.border-top-darken-3 {
    border-top: 1px solid #37474f !important;
}
.border-bottom-blue-grey.border-bottom-darken-3 {
    border-bottom: 1px solid #37474f !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-darken-3 {
    border-left: 1px solid #37474f !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-darken-3,
[dir='rtl'] .border-left-blue-grey.border-left-darken-3 {
    border-right: 1px solid #37474f !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-darken-3 {
    border-left: 1px solid #37474f !important;
}
.overlay-blue-grey.overlay-darken-3 {
    background: #37474f;
    background: rgba(55, 71, 79, 0.8);
}
.blue-grey.darken-4 {
    color: #263238 !important;
}
.bg-blue-grey.bg-darken-4,
.btn-blue-grey.btn-darken-4 {
    background-color: #263238 !important;
}
.btn-blue-grey.btn-darken-4 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-darken-4:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-darken-4:active,
.btn-blue-grey.btn-darken-4:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-darken-4 {
    border-color: #263238 !important;
    color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-darken-4:hover {
    background-color: #263238 !important;
}
.progress-blue-grey.progress-darken-4 {
    background-color: #263238;
}
.progress-blue-grey.progress-darken-4[value]::-webkit-progress-value {
    background-color: #263238;
}
.progress-blue-grey.progress-darken-4[value]::-moz-progress-bar {
    background-color: #263238;
}
.progress-blue-grey.progress-darken-4[value]::-ms-fill {
    background-color: #263238;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-darken-4 .progress-bar {
        background-color: #263238;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #263238 !important;
}
.border-blue-grey.border-darken-4 {
    border: 1px solid #263238 !important;
}
.border-top-blue-grey.border-top-darken-4 {
    border-top: 1px solid #263238 !important;
}
.border-bottom-blue-grey.border-bottom-darken-4 {
    border-bottom: 1px solid #263238 !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-darken-4 {
    border-left: 1px solid #263238 !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-darken-4,
[dir='rtl'] .border-left-blue-grey.border-left-darken-4 {
    border-right: 1px solid #263238 !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-darken-4 {
    border-left: 1px solid #263238 !important;
}
.overlay-blue-grey.overlay-darken-4 {
    background: #263238;
    background: rgba(38, 50, 56, 0.8);
}
.blue-grey.accent-1 {
    color: #cfd8dc !important;
}
.bg-blue-grey.bg-accent-1,
.btn-blue-grey.btn-accent-1 {
    background-color: #cfd8dc !important;
}
.btn-blue-grey.btn-accent-1 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-accent-1:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-accent-1:active,
.btn-blue-grey.btn-accent-1:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-accent-1 {
    border-color: #cfd8dc !important;
    color: #cfd8dc !important;
}
.btn-outline-blue-grey.btn-outline-accent-1:hover {
    background-color: #cfd8dc !important;
}
.progress-blue-grey.progress-accent-1 {
    background-color: #cfd8dc;
}
.progress-blue-grey.progress-accent-1[value]::-webkit-progress-value {
    background-color: #cfd8dc;
}
.progress-blue-grey.progress-accent-1[value]::-moz-progress-bar {
    background-color: #cfd8dc;
}
.progress-blue-grey.progress-accent-1[value]::-ms-fill {
    background-color: #cfd8dc;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-accent-1 .progress-bar {
        background-color: #cfd8dc;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #cfd8dc !important;
}
.border-blue-grey.border-accent-1 {
    border: 1px solid #cfd8dc !important;
}
.border-top-blue-grey.border-top-accent-1 {
    border-top: 1px solid #cfd8dc !important;
}
.border-bottom-blue-grey.border-bottom-accent-1 {
    border-bottom: 1px solid #cfd8dc !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-accent-1 {
    border-left: 1px solid #cfd8dc !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-accent-1,
[dir='rtl'] .border-left-blue-grey.border-left-accent-1 {
    border-right: 1px solid #cfd8dc !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-accent-1 {
    border-left: 1px solid #cfd8dc !important;
}
.overlay-blue-grey.overlay-accent-1 {
    background: #cfd8dc;
    background: rgba(207, 216, 220, 0.8);
}
.blue-grey.accent-2 {
    color: #b0bec5 !important;
}
.bg-blue-grey.bg-accent-2,
.btn-blue-grey.btn-accent-2 {
    background-color: #b0bec5 !important;
}
.btn-blue-grey.btn-accent-2 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-accent-2:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-accent-2:active,
.btn-blue-grey.btn-accent-2:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-accent-2 {
    border-color: #b0bec5 !important;
    color: #b0bec5 !important;
}
.btn-outline-blue-grey.btn-outline-accent-2:hover {
    background-color: #b0bec5 !important;
}
.progress-blue-grey.progress-accent-2 {
    background-color: #b0bec5;
}
.progress-blue-grey.progress-accent-2[value]::-webkit-progress-value {
    background-color: #b0bec5;
}
.progress-blue-grey.progress-accent-2[value]::-moz-progress-bar {
    background-color: #b0bec5;
}
.progress-blue-grey.progress-accent-2[value]::-ms-fill {
    background-color: #b0bec5;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-accent-2 .progress-bar {
        background-color: #b0bec5;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b0bec5 !important;
}
.border-blue-grey.border-accent-2 {
    border: 1px solid #b0bec5 !important;
}
.border-top-blue-grey.border-top-accent-2 {
    border-top: 1px solid #b0bec5 !important;
}
.border-bottom-blue-grey.border-bottom-accent-2 {
    border-bottom: 1px solid #b0bec5 !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-accent-2 {
    border-left: 1px solid #b0bec5 !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-accent-2,
[dir='rtl'] .border-left-blue-grey.border-left-accent-2 {
    border-right: 1px solid #b0bec5 !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-accent-2 {
    border-left: 1px solid #b0bec5 !important;
}
.overlay-blue-grey.overlay-accent-2 {
    background: #b0bec5;
    background: rgba(176, 190, 197, 0.8);
}
.blue-grey.accent-3 {
    color: #78909c !important;
}
.bg-blue-grey.bg-accent-3,
.btn-blue-grey.btn-accent-3 {
    background-color: #78909c !important;
}
.btn-blue-grey.btn-accent-3 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-accent-3:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-accent-3:active,
.btn-blue-grey.btn-accent-3:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-accent-3 {
    border-color: #78909c !important;
    color: #78909c !important;
}
.btn-outline-blue-grey.btn-outline-accent-3:hover {
    background-color: #78909c !important;
}
.progress-blue-grey.progress-accent-3 {
    background-color: #78909c;
}
.progress-blue-grey.progress-accent-3[value]::-webkit-progress-value {
    background-color: #78909c;
}
.progress-blue-grey.progress-accent-3[value]::-moz-progress-bar {
    background-color: #78909c;
}
.progress-blue-grey.progress-accent-3[value]::-ms-fill {
    background-color: #78909c;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-accent-3 .progress-bar {
        background-color: #78909c;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909c !important;
}
.border-blue-grey.border-accent-3 {
    border: 1px solid #78909c !important;
}
.border-top-blue-grey.border-top-accent-3 {
    border-top: 1px solid #78909c !important;
}
.border-bottom-blue-grey.border-bottom-accent-3 {
    border-bottom: 1px solid #78909c !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-accent-3 {
    border-left: 1px solid #78909c !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-accent-3,
[dir='rtl'] .border-left-blue-grey.border-left-accent-3 {
    border-right: 1px solid #78909c !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-accent-3 {
    border-left: 1px solid #78909c !important;
}
.overlay-blue-grey.overlay-accent-3 {
    background: #78909c;
    background: rgba(120, 144, 156, 0.8);
}
.blue-grey.accent-4 {
    color: #455a64 !important;
}
.bg-blue-grey.bg-accent-4,
.btn-blue-grey.btn-accent-4 {
    background-color: #455a64 !important;
}
.btn-blue-grey.btn-accent-4 {
    border-color: #455a64 !important;
}
.btn-blue-grey.btn-accent-4:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important;
}
.btn-blue-grey.btn-accent-4:active,
.btn-blue-grey.btn-accent-4:focus {
    border-color: #37474f !important;
    background-color: #263238 !important;
}
.btn-outline-blue-grey.btn-outline-accent-4 {
    border-color: #455a64 !important;
    color: #455a64 !important;
}
.btn-outline-blue-grey.btn-outline-accent-4:hover {
    background-color: #455a64 !important;
}
.progress-blue-grey.progress-accent-4 {
    background-color: #455a64;
}
.progress-blue-grey.progress-accent-4[value]::-webkit-progress-value {
    background-color: #455a64;
}
.progress-blue-grey.progress-accent-4[value]::-moz-progress-bar {
    background-color: #455a64;
}
.progress-blue-grey.progress-accent-4[value]::-ms-fill {
    background-color: #455a64;
}
@media screen and (min-width: 0\0) {
    .progress-blue-grey.progress-accent-4 .progress-bar {
        background-color: #455a64;
    }
}
input:focus ~ .bg-blue-grey {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #455a64 !important;
}
.border-blue-grey.border-accent-4 {
    border: 1px solid #455a64 !important;
}
.border-top-blue-grey.border-top-accent-4 {
    border-top: 1px solid #455a64 !important;
}
.border-bottom-blue-grey.border-bottom-accent-4 {
    border-bottom: 1px solid #455a64 !important;
}
[dir='ltr'] .border-left-blue-grey.border-left-accent-4 {
    border-left: 1px solid #455a64 !important;
}
[dir='ltr'] .border-right-blue-grey.border-right-accent-4,
[dir='rtl'] .border-left-blue-grey.border-left-accent-4 {
    border-right: 1px solid #455a64 !important;
}
[dir='rtl'] .border-right-blue-grey.border-right-accent-4 {
    border-left: 1px solid #455a64 !important;
}
.overlay-blue-grey.overlay-accent-4 {
    background: #455a64;
    background: rgba(69, 90, 100, 0.8);
}
.shades.black {
    color: #000 !important;
}
.bg-shades.bg-black,
.btn-shades.btn-black {
    background-color: #000 !important;
}
.btn-shades.btn-black {
    border-color: #000!important;
}
.btn-shades.btn-black:active,
.btn-shades.btn-black:focus,
.btn-shades.btn-black:hover {
    border-color: #000000!important;
    background-color: #000000!important;
}
.btn-outline-shades.btn-outline-black {
    border-color: #000 !important;
    color: #000 !important;
}
.btn-outline-shades.btn-outline-black:hover {
    background-color: #000 !important;
}
.progress-shades.progress-black {
    background-color: #000;
}
.progress-shades.progress-black[value]::-webkit-progress-value {
    background-color: #000;
}
.progress-shades.progress-black[value]::-moz-progress-bar {
    background-color: #000;
}
.progress-shades.progress-black[value]::-ms-fill {
    background-color: #000;
}
@media screen and (min-width: 0\0) {
    .progress-shades.progress-black .progress-bar {
        background-color: #000;
    }
}
input:focus ~ .bg-shades {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000 !important;
}
.border-shades.border-black {
    border: 1px solid #000 !important;
}
.border-top-shades.border-top-black {
    border-top: 1px solid #000 !important;
}
.border-bottom-shades.border-bottom-black {
    border-bottom: 1px solid #000 !important;
}
[dir='ltr'] .border-left-shades.border-left-black {
    border-left: 1px solid #000 !important;
}
[dir='ltr'] .border-right-shades.border-right-black,
[dir='rtl'] .border-left-shades.border-left-black {
    border-right: 1px solid #000 !important;
}
[dir='rtl'] .border-right-shades.border-right-black {
    border-left: 1px solid #000 !important;
}
.overlay-shades.overlay-black {
    background: #000;
    background: rgba(0, 0, 0, 0.8);
}
.shades.white {
    color: #fff !important;
}
.bg-shades.bg-white,
.btn-shades.btn-white {
    background-color: #fff !important;
}
.btn-shades.btn-white {
    border-color: #fff!important;
}
.btn-shades.btn-white:active,
.btn-shades.btn-white:focus,
.btn-shades.btn-white:hover {
    border-color: #fff!important;
    background-color: #fff!important;
}
.btn-outline-shades.btn-outline-white {
    border-color: #fff !important;
    color: #fff !important;
}
.btn-outline-shades.btn-outline-white:hover {
    background-color: #fff !important;
}
.progress-shades.progress-white {
    background-color: #fff;
}
.progress-shades.progress-white[value]::-webkit-progress-value {
    background-color: #fff;
}
.progress-shades.progress-white[value]::-moz-progress-bar {
    background-color: #fff;
}
.progress-shades.progress-white[value]::-ms-fill {
    background-color: #fff;
}
@media screen and (min-width: 0\0) {
    .progress-shades.progress-white .progress-bar {
        background-color: #fff;
    }
}
input:focus ~ .bg-shades {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff !important;
}
.border-shades.border-white {
    border: 1px solid #fff !important;
}
.border-top-shades.border-top-white {
    border-top: 1px solid #fff !important;
}
.border-bottom-shades.border-bottom-white {
    border-bottom: 1px solid #fff !important;
}
[dir='ltr'] .border-left-shades.border-left-white {
    border-left: 1px solid #fff !important;
}
[dir='ltr'] .border-right-shades.border-right-white,
[dir='rtl'] .border-left-shades.border-left-white {
    border-right: 1px solid #fff !important;
}
[dir='rtl'] .border-right-shades.border-right-white {
    border-left: 1px solid #fff !important;
}
.overlay-shades.overlay-white {
    background: #fff;
    background: hsla(0, 0%, 100%, 0.8);
}
.shades.transparent {
    color: transparent !important;
}
.bg-shades.bg-transparent,
.btn-shades.btn-transparent {
    background-color: initial !important;
}
.btn-shades.btn-transparent {
    border-color: transparent!important;
}
.btn-shades.btn-transparent:active,
.btn-shades.btn-transparent:focus,
.btn-shades.btn-transparent:hover {
    border-color: transparent!important;
    background-color: transparent!important;
}
.btn-outline-shades.btn-outline-transparent {
    border-color: transparent !important;
    color: transparent !important;
}
.btn-outline-shades.btn-outline-transparent:hover {
    background-color: initial !important;
}
.progress-shades.progress-transparent {
    background-color: initial;
}
.progress-shades.progress-transparent[value]::-webkit-progress-value {
    background-color: initial;
}
.progress-shades.progress-transparent[value]::-moz-progress-bar {
    background-color: initial;
}
.progress-shades.progress-transparent[value]::-ms-fill {
    background-color: initial;
}
@media screen and (min-width: 0\0) {
    .progress-shades.progress-transparent .progress-bar {
        background-color: initial;
    }
}
input:focus ~ .bg-shades {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent !important;
}
.border-shades.border-transparent {
    border: 1px solid transparent !important;
}
.border-top-shades.border-top-transparent {
    border-top: 1px solid transparent !important;
}
.border-bottom-shades.border-bottom-transparent {
    border-bottom: 1px solid transparent !important;
}
[dir='ltr'] .border-left-shades.border-left-transparent {
    border-left: 1px solid transparent !important;
}
[dir='ltr'] .border-right-shades.border-right-transparent,
[dir='rtl'] .border-left-shades.border-left-transparent {
    border-right: 1px solid transparent !important;
}
[dir='rtl'] .border-right-shades.border-right-transparent {
    border-left: 1px solid transparent !important;
}
.overlay-shades.overlay-transparent {
    background: transparent;
    background: rgba(0, 0, 0, 0.8);
}
.black {
    color: #000;
}
.bg-black {
    background-color: #000;
}
input:focus ~ .bg-black {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000;
}
.border-black {
    border: 1px solid #000;
}
.border-top-black {
    border-top: 1px solid #000;
}
.border-bottom-black {
    border-bottom: 1px solid #000;
}
[dir='ltr'] .border-left-black {
    border-left: 1px solid #000;
}
[dir='ltr'] .border-right-black,
[dir='rtl'] .border-left-black {
    border-right: 1px solid #000;
}
[dir='rtl'] .border-right-black {
    border-left: 1px solid #000;
}
.white {
    color: #fff;
}
.bg-white {
    background-color: #fff!important;
}
.overlap-white{
    border-color:#fff !important;
    color:#fff !important;
}
input:focus ~ .bg-white {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff;
}
.border-white {
    border: 1px solid #fff;
}
.border-top-white {
    border-top: 1px solid #fff;
}
.border-bottom-white {
    border-bottom: 1px solid #fff;
}
[dir='ltr'] .border-left-white {
    border-left: 1px solid #fff;
}
[dir='ltr'] .border-right-white,
[dir='rtl'] .border-left-white {
    border-right: 1px solid #fff;
}
[dir='rtl'] .border-right-white {
    border-left: 1px solid #fff;
}
.transparent {
    color: transparent;
}
.bg-transparent {
    background-color: initial;
}
input:focus ~ .bg-transparent {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent;
}
.border-transparent {
    border: 1px solid transparent;
}
.border-top-transparent {
    border-top: 1px solid transparent;
}
.border-bottom-transparent {
    border-bottom: 1px solid transparent;
}
[dir='ltr'] .border-left-transparent {
    border-left: 1px solid transparent;
}
[dir='ltr'] .border-right-transparent,
[dir='rtl'] .border-left-transparent {
    border-right: 1px solid transparent;
}
[dir='rtl'] .border-right-transparent {
    border-left: 1px solid transparent;
}
html {
    font-size: 17px;
    width: 100%;
}
body {
    font-weight: 300;
    line-height: 1.5em;
    min-height: 100vh;
}
body.inverse {
    background: #333;
}
body.inverse,
body.inverse .form-control {
    color: #fff;
}
body.inverse .card,
body.inverse .card .form-control,
body.inverse .modal,
body.inverse .modal .form-control,
body.inverse .panel-default,
body.inverse .panel-default .form-control {
    background-color: initial;
    color: #000;
    color: initial;
}
.wrapper.wrapper-full-page {
    height: auto;
    min-height: 100vh;
}
.content-wrapper {
    padding: 0 15px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    line-height: 1.15em;
}
.page-header {
    height: 60vh;
    background-position: 50%;
    background-size: cover;
    margin: 0;
    padding: 0;
    border: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
a {
    color: #009da0;
}
a:focus,
a:hover {
    color: #008487;
    text-decoration: none;
}
a.text-info:focus,
a.text-info:hover {
    color: #19a8c1;
}
p {
    font-size: 1rem;
    letter-spacing: 0.3px;
}
.badge-toolbar .badge {
    margin-right: 15px;
}
.badge {
    padding: 0.1em 0.5em;
    font-weight: 500;
    letter-spacing: 0.3px;
    cursor: default;
}
.badge-pill {
    padding: 5px 6px 3px;
}
.badge-warning {
    color: #fff;
}
.btn[class*='btn-'] {
    margin-bottom: 1rem;
}
.btn-toolbar > .btn {
    margin-right: 15px;
}
.btn-group-vertical .btn[class*='btn-'] {
    margin-bottom: 0;
}
.input-group {
    margin-bottom: 1rem;
}
.input-group .btn[class*='btn-'] {
    margin-bottom: 0;
}
.btn-square {
    border-radius: 0;
}
.btn.active,
.btn:active {
    background-image: none;
}
button:hover {
    cursor: pointer;
}
.btn-group > .btn.active {
    z-index: 0;
}
.btn-floating {
    position: fixed;
    bottom: 20px;
    right: 20px;
}
.btn-secondary {
    border-color: transparent;
}
.card {
    border: 0;
    box-shadow: 0 6px 0 0 rgba(0, 0, 0, 0.01), 0 15px 32px 0 rgba(0, 0, 0, 0.06);
    border-radius: 2px;
}
.card .card-header,
.card[class*='gradient-'] .card-footer,
.card[class*='gradient-'] .card-header {
    background-color: initial;
}
.card .card-header {
    padding: 1em 1.25em 0em 2rem;
    border-bottom: none;
}
.card .card-subtitle {
    margin-top: 10px;
    margin-bottom: 10px;
}
.card .card-block {
    padding: 0.5rem 1.5rem 1.5rem;
}
.card .card-bordered {
    border: 1px solid rgba(0, 0, 0, 0.06);
}
.card.card-transparent {
    background-color: initial !important;
}
.card .card {
    box-shadow: none;
}
.card .card-title {
    margin: 0.5em 0em;
}
.card .card-img {
    position: relative;
}
.card .card-img .card-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding: 24px;
}
.card .card-img.overlap {
    margin-top: -60px;
}
.card .btn-floating {
    display: inline-block;
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    background-color: #009da0;
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    vertical-align: middle;
    font-size: 1rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.card .btn-floating.btn {
    margin: 0;
}
.card .btn-floating i {
    width: inherit;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 2rem;
    line-height: 40px;
}
.card .btn-floating.btn-large {
    width: 56px;
    height: 56px;
}
.card .btn-floating.btn-large.halfway-fab {
    bottom: -28px;
    padding: 6px;
}
.card .btn-floating.btn-large i {
    line-height: 56px;
}
.card .btn-floating.halfway-fab {
    position: absolute;
    right: 24px;
    bottom: -20px;
}
.card .card-reveal {
    padding: 24px;
    position: absolute;
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    left: 0;
    top: 100%;
    height: 100%;
    z-index: 3;
    transition: height 1s ease-in-out;
    display: none;
}
.card .card-reveal .card-title {
    cursor: pointer;
}
.card.horizontal .card-body {
    display: flex;
}
.card.horizontal .card-img {
    max-width: 50%;
}
.card.horizontal .card-img img {
    border-radius: 2px 0 0 2px;
    max-width: 100%;
    width: auto;
}
.card .card-stacked {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: relative;
}
.card h1.card-title,
.card h2.card-title,
.card h3.card-title,
.card h4.card-title,
.card h5.card-title,
.card h6.card-title {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: -0.01rem;
    font-size: 1em;
}
.card .card-footer {
    padding: 1em 1.25em 1.2em 2rem;
    overflow: hidden;
}
.card.card-inverse .card-blockquote .blockquote-footer,
.card.card-inverse .card-footer,
.card.card-inverse .card-link,
.card.card-inverse .card-subtitle,
.card.card-inverse .card-text,
.card.card-inverse .card-title,
.card.card-inverse .form-group.is-focused .checkbox label,
.card.card-inverse .form-group.is-focused .radio label,
.card.card-inverse .form-group.is-focused label.checkbox-inline,
.card.card-inverse .form-group.is-focused label.radio-inline,
.card.card-inverse .form-group:hover,
.card.card-inverse a,
.card.card-inverse a:hover,
.card.card-inverse div,
.card.card-inverse input,
.card.card-inverse label,
.card.card-inverse span {
    color: #fff;
}
.card.card-inverse hr {
    border-color: #fff;
}
.card.card-inverse .checkbox label {
    color: #fff;
}
.card.card-inverse .checkbox label .checkbox-decorator .check {
    border-color: hsla(0, 0%, 100%, 0.54);
}
.card.card-inverse input:-moz-placeholder,
.card.card-inverse input:-ms-input-placeholder,
.card.card-inverse input::-moz-placeholder,
.card.card-inverse input::-webkit-input-placeholder {
    color: #fff;
}
[dir='rtl'] .card {
    text-align: right;
}
[dir='rtl'] .card .card-img .card-title {
    right: 0;
    left: auto;
}
[dir='rtl'] .card .btn-floating.halfway-fab {
    left: 24px;
    right: auto;
}
[dir='rtl'] .card .card-reveal {
    right: 0;
    left: auto;
}
.nav-tabs-vc > ul.nav {
    position: absolute;
    right: 20px;
    top: 8px;
    border: none;
    background: #fff;
}
.nav-tabs-vc > ul.nav .nav-link {
    padding: 0.5rem 0.8rem 0.2rem;
    border: none;
}
.nav-tabs-vc > ul.nav .nav-link.active {
    border-bottom: 2px solid #009da0;
}
.nav-pills .nav-item .nav-link.active:hover {
    color: #fff;
}
.nav-tabs.nav-border-bottom .nav-link:hover {
    border-color: transparent;
    border-bottom: 1px solid #dee2e6;
}
.nav-tabs.nav-border-bottom .nav-link.active {
    border-radius: 0;
    border-color: transparent;
    border-bottom: 2px solid #009da0;
}
.tabs-vertical {
    display: flex;
}
.tabs-vertical .nav-tabs {
    border-bottom: none;
    width: 100px;
    height: 100%;
    flex-wrap: wrap;
}
.tabs-vertical .nav-tabs .nav-item {
    width: 100%;
}
.tabs-vertical .nav-tabs .nav-item .nav-link {
    border-right: 1px solid #dee2e6;
}
.tabs-vertical .nav-tabs .nav-item .nav-link:hover {
    border-color: transparent;
    border-right: 1px solid #dee2e6;
}
.tabs-vertical .nav-tabs .nav-item .nav-link.active {
    border-color: transparent;
    border-right: 2px solid #009da0;
}
.wrapper .form-control {
    background-color: #404258;
    color: #bdbdc7;
}
.wrapper label,
.wrapper input {
    color: #bdbdc7;
    font-size: 0.9rem;
    font-weight: 400;
}
form .labelfocus {
    color: #000;
}
form .col-form-label {
    font-size: 0.75rem;
    text-align: right;
}
form .form-group {
    margin-bottom: 1.5rem;
}
form .form-control {
    border: 1px solid #a6a9ae;
    color: #75787d;
}
form .form-control::-webkit-input-placeholder {
    color: #b1c1d8;
}
form .form-control:-moz-placeholder,
form .form-control::-moz-placeholder {
    color: #b1c1d8;
}
form .form-control:-ms-input-placeholder {
    color: #b1c1d8;
}
form .form-control:focus {
    border-color: #000;
}
form .form-control-position {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.2rem;
    text-align: center;
}
form .has-icon-left .form-control-position {
    right: auto;
    left: inherit;
}
form .has-icon-left .form-control {
    padding-right: 0.85rem;
    padding-left: 2.95rem;
}
form .has-icon-right .form-control {
    padding-right: 2.5rem;
}
form .form-section {
    color: #343a40;
    line-height: 3rem;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin-bottom: 20px;
    border-bottom: 1px solid #343a40;
}
form .form-section i {
    font-size: 20px;
    margin-left: 5px;
    margin-right: 5px;
}
form .form-actions {
    border-top: 1px solid #d3dce9;
    padding: 20px 0;
    margin-top: 20px;
}
form .form-actions.filled {
    background-color: #f5f7fa;
}
form .form-actions.center {
    text-align: center;
}
form .form-actions.right {
    text-align: right;
}
form .form-actions.top {
    border-top: 0;
    border-bottom: 1px solid #d3dce9;
    margin-top: 0;
    margin-bottom: 20px;
}
@media (max-width: 767.98px) {
    form .form-actions .buttons-group {
        float: left !important;
        margin-bottom: 10px;
    }
}
@media (min-width: 576px) {
    form.form-horizontal .form-group .label-control {
        text-align: right;
    }
}
form.row-separator .form-group {
    margin: 0;
    border-bottom: 1px solid #ebeff5;
}
form.row-separator .form-group .label-control {
    padding-top: 1.5rem;
}
form.row-separator .form-group > div {
    padding: 1.2rem 0 1.2rem 1.2rem;
}
form.row-separator .form-group.last {
    border-bottom: 0;
}
form.form-bordered .form-group {
    margin: 0;
    border-bottom: 1px solid #ebeff5;
}
form.form-bordered .form-group .label-control {
    padding-top: 1.5rem;
}
form.form-bordered .form-group > div {
    padding: 1.2rem 0 1.2rem 1.2rem;
    border-left: 1px solid #ebeff5;
}
form.form-bordered .form-group.last {
    border-bottom: 0;
}
form.striped-rows .form-group {
    margin: 0;
}
form.striped-rows .form-group .col-form-label,
form.striped-rows .form-group .label-control {
    padding-top: 1.5rem;
}
form.striped-rows .form-group > div {
    padding: 1.2rem 0 1.2rem 1.2rem;
}
form.striped-rows .form-group:nth-child(2n) {
    background-color: #f5f7fa;
}
form.striped-labels .form-group {
    margin: 0;
}
form.striped-labels .form-group .label-control {
    padding-top: 1.5rem;
}
form.striped-labels .form-group > div {
    padding: 1.2rem 0 1.2rem 1.2rem;
    background-color: #fff;
}
form.striped-labels .form-group:nth-child(2n) {
    background-color: #f5f7fa;
}
select.form-control {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.custom-control {
    display: block;
}
.custom-control.inline {
    display: inline;
}
.custom-checkbox input:disabled:checked ~ .custom-control-indicator {
    opacity: 0.5;
}
.custom-checkbox input:disabled ~ .custom-control-indicator {
    cursor: not-allowed;
}
.custom-control-input > input:focus ~ .custom-control-indicator {
    padding: 0.51rem;
}
.custom-control-indicator {
    width: 1.1rem;
    height: 1.075rem;
    line-height: 1.05rem;
    top: 0.085rem;
}
.custom-control-indicator + .custom-control-description {
    margin-left: 1rem;
}
.form-control-xl {
    padding: 0.8rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
select.form-control-xl:not([size]):not([multiple]) {
    height: calc(3.25rem + 2px);
}
.input-group-addon input[type='checkbox'],
.input-group-addon input[type='radio'] {
    margin-top: -4px;
    margin-left: -1rem;
}
.form-control-xl,
.input-group-xl > .form-control,
.input-group-xl > .input-group-addon,
.input-group-xl > .input-group-btn > .btn {
    padding: 0.8rem 1rem;
}
.ng-touched.ng-invalid {
    border-color: #ff586b;
}
[dir='rtl'] form .form-control-position {
    left: 0;
    right: auto;
}
[dir='rtl'] form .has-icon-left .form-control-position {
    left: auto;
    right: inherit;
}
[dir='rtl'] form .has-icon-left .form-control {
    padding-left: 0.85rem;
    padding-right: 2.95rem;
}
[dir='rtl'] form .has-icon-right .form-control {
    padding-left: 2.5rem;
    padding-right: auto;
}
[dir='rtl'] form .form-actions.right {
    text-align: left;
}
@media (max-width: 767.98px) {
    [dir='rtl'] form .form-actions .buttons-group {
        float: right !important;
    }
}
@media (min-width: 576px) {
    [dir='rtl'] form.form-horizontal .form-group .label-control {
        text-align: left;
    }
}
[dir='rtl'] form.row-separator .form-group > div {
    padding-left: 0;
    padding-right: auto;
}
[dir='rtl'] form.form-bordered .form-group > div {
    padding-left: 0;
    padding-right: auto;
    border-right: 1px solid #ebeff5;
}
[dir='rtl'] form.striped-labels .form-group > div,
[dir='rtl'] form.striped-rows .form-group > div {
    padding-left: 0;
    padding-right: auto;
}
[dir='rtl'] .custom-control-indicator + .custom-control-description {
    margin-right: 1rem;
    margin-left: auto;
}
[dir='rtl'] .input-group-addon input[type='checkbox'],
[dir='rtl'] .input-group-addon input[type='radio'] {
    margin-right: -1rem;
    margin-left: auto;
}
.carousel-inner > .carousel-item > img {
    display: block;
    max-width: 100%;
    height: auto;
    line-height: 1;
}
.modal-content {
    border-radius: 0.25rem;
}
.modal-content .modal-footer .btn {
    margin: 0;
}
.modal-content .modal-footer .btn + .btn {
    margin-left: 8px;
}
.navbar {
    z-index: 4;
    border: 0;
    border-radius: 0;
    padding: 1rem 0;
}
.navbar .navbar-brand {
    position: relative;
}
.navbar .navbar-nav {
    display: block;
    margin-right: -15px;
}
.navbar .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar .navbar-toggle {
    background-color: initial;
    border: none;
    cursor:pointer;
}
.navbar .navbar-toggle .icon-bar {
    background-color: inherit;
    border: 1px solid;
}
.navbar .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 4px;
}
.navbar .navbar-search {
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: scroll;
}
.navbar .notification {
    position: absolute;
    top: -1px;
    right: 13px;
}
@media (max-width: 767px) {
    #navbar-mobile.navbar-collapse .navbar-nav {
        margin: 0;
        flex-flow: row wrap;
    }
    #navbar-mobile.navbar-collapse .navbar-nav .dropdown-menu {
        position: absolute;
    }
    #navbar-mobile.navbar-collapse .navbar-nav .nav-item {
        float: left;
    }
}
@media (max-width: 991.98px) {
    .navbar-header {
        width: 60% !important;
    }
}
@media (max-width: 991.98px) {
    .header-navbar .navbar-container ul.navbar-nav li {
        float: right !important;
    }
    .header-navbar .navbar-container ul.navbar-nav li > a.nav-link {
        padding: 0.5rem;
    }
}
.popover {
    border-color: rgba(0, 157, 160, 0.2);
    padding: 0;
}
.popover .popover-header {
    background-color: #009da0;
    border-color: #009da0;
    color: #fff;
}
.progress {
    margin-bottom: 1rem;
}
.h1,
h1 {
    font-size: 2.5rem;
}
.h2,
h2 {
    font-size: 2rem;
}
.h3,
h3 {
    font-size: 1.75rem;
}
.h4,
h4 {
    font-size: 1.5rem;
}
.h5,
h5 {
    font-size: 1.25rem;
}
.h6,
h6 {
    font-size: 1rem;
    font-weight: 300;
}
.blockquote,
blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 1.1rem;
    border-left: 5px solid #eee;
}
.blockquote p,
blockquote p {
    font-style: italic;
}
.blockquote .small,
.blockquote footer,
.blockquote small,
blockquote .small,
blockquote footer,
blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;
}
.blockquote-reverse,
blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid #eee;
    border-left: 0;
    text-align: right;
}
dd,
li {
    line-height: 2.2rem;
}
h2.title {
    margin-bottom: 2rem;
}
.text-warning {
    color: #ff8d60 !important;
}
.text-primary {
    color: #009da0 !important;
}
.text-danger {
    color: #ff586b !important;
}
.text-success {
    color: #00a887 !important;
}
.text-info {
    color: #1cbcd8 !important;
}
.text-gray {
    color: gray!important;
}
.font-small-1 {
    font-size: 0.7rem !important;
}
.font-small-2 {
    font-size: 0.8rem !important;
}
.font-small-3 {
    font-size: 0.9rem !important;
}
.font-medium-1 {
    font-size: 1.1rem !important;
}
.font-medium-2 {
    font-size: 1.2rem !important;
}
.font-medium-3 {
    font-size: 1.3rem !important;
}
.font-medium-4 {
    font-size: 1.4rem !important;
}
.font-medium-5 {
    font-size: 1.5rem !important;
}
.font-large-1 {
    font-size: 2rem !important;
    line-height: 2rem;
}
.font-large-2 {
    font-size: 3rem !important;
    line-height: 2.95rem;
}
.font-large-3 {
    font-size: 4rem !important;
    line-height: 3.5rem;
}
.font-large-4 {
    font-size: 5rem !important;
    line-height: 4.2rem;
}
.font-large-5 {
    font-size: 6rem !important;
    line-height: 5.2rem;
}
.text-bold-300 {
    font-weight: 300;
}
.text-bold-400 {
    font-weight: 400;
}
.text-bold-500 {
    font-weight: 500;
}
.text-bold-600 {
    font-weight: 600;
}
.text-bold-700 {
    font-weight: 700;
}
.text-bold-800 {
    font-weight: 800;
}
.text-italic {
    font-style: italic;
}
.text-highlight {
    padding: 4px 6px;
}
[dir='rtl'] .float-left {
    float: right !important;
}
[dir='rtl'] .float-right {
    float: left !important;
}
[dir='rtl'] .text-right {
    text-align: left !important;
}
[dir='rtl'] .text-left {
    text-align: right !important;
}

.main-layout main {
    flex: 1;
}
.main-layout footer p {
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.3px;
    font-weight: 300;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.sidebar-lg.main-layout {
    margin-left: 280px;
}
.sidebar-md.main-layout {
    margin-left: 250px;
}
.sidebar-sm.main-layout {
    margin-left: 220px;
}
.menu-collapsed {
    margin-left: 60px;
    padding: 0 16px;
}
.menu-collapsed main {
    margin-top: 15px;
    color: #464855;
}
.menu-collapsed main p {
    color: #6b6f82;
}
.menu-collapsed footer p {
    color: #464855;
    font-size: 1rem;
    letter-spacing: 0.3px;
    font-weight: 300;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.menu-collapsed footer p a {
    color: #009da0;
    cursor: pointer;
    text-decoration: none;
    background-color: initial;
}
@media (max-width: 768px) {
    .menu-collapsed {
        margin-left: 0;
    }
}
@media (max-width: 990px) {
    .main-layout {
        margin-left: 0 !important;
    }
}
.animate-shake {
    animation: animate-shake 0.15s ease-in 2s infinite alternate;
    animation-fill-mode: none;
    top: 1px;
    position: relative;
}
@keyframes animate-shake {
    0% {
        transform: rotate(12deg);
    }
    to {
        transform: rotate(-12deg);
    }
}
.wrapper {
    background: #2E2F42;
    height: 100%;
}
.bg-white {
    background-color: #404258;
}
.content-header,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.table th {
    color: #fff;
}
.content-sub-header,
.list-group-item-action,
.nav-tabs .nav-link.active,
.table td {
    color: #f8f9fa;
}
.input-group-text {
    background-color: #404258;
    color: #bdbdc7;
}
.page-item.active .page-link {
    background-color: #2e2f42;
}
.page-item .page-link {
    background-color: #404258;
    color: #bdbdc7;
    border-color: #616279;
}
.breadcrumb,
.progress {
    background-color: #2e2f42;
}
.breadcrumb .active.breadcrumb-item {
    color: #fff;
}
.ct-grid {
    stroke: hsla(0, 0%, 100%, 0.22);
}
.ct-label {
    color: #77798e;
}
.table thead th {
    border-bottom: 2px solid #616279;
}
.table td,
.table th {
    border-top: 2px solid #616279;
}
.text-muted {
    color: #b1b1bb !important;
}
.list-group-item {
    background-color: inherit;
}
.dropdown-normatiza{
    display:inline-block!important;
    .dropdown-menu{
        border-color: #232432;
        background-color: #2b2c3e;
    }
    #excluir{
        color:#ff586b;
        font-size: .7rem;
        text-transform:uppercase;
    }
    #excluir:hover{
        background-color:#F8F9FA!important;
    }
}
.dropdown-menu {
    cursor: default;
    font-weight: 500;
    user-select: none;
    -webkit-user-select: none;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.25);
    background-color: #2f3142;
    border-radius: 2px;
    color: #fff;
    width:auto;
    min-width:200px;
}
.dropdown-menu .dropdown-item {
    color: #fff;
}
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover {
    background-color: #00a887;;
    color: #fff;
}
.navbar .navbar-nav .nav-link {
    color: #bdbdc7;
}
.navbar .list-group-item {
    background-color: #404258;
}
.navbar .scrollbar-container.bg-grey.bg-lighten-5 {
    background-color: #404258 !important;
}
    .navbar
    .scrollbar-container
    .border-bottom-grey.border-bottom-lighten-3 {
    border-color: #616279 !important;
}
form label {
    color: #bdbdc7;
}
form.form-bordered .form-group,
form.form-bordered .form-group > div {
    border-color: #616279;
}
form.striped-rows .form-group:nth-child(2n) {
    background-color: #2e2f42;
}
.form-control {
    background-color: #404258;
    color: #bdbdc7;
}
.card {
    background-color: #404258;
}
.card .card-subtitle,
.card .card-title {
    color: #fff;
}
.card .card-footer,
.card .nav-item .nav-link,
.card .nav-tabs-vc > ul.nav {
    background-color: #404258;
}
.email-application .email-app-list-mails,
.email-application .email-app-mail-content,
.email-application .email-app-sidebar {
    border-right: 1px solid;
    border-color: #616279;
}
.email-application .email-app-list-mails .email-actions,
.email-application .email-app-menu .form-group-compose {
    border-bottom: 1px solid #616279;
}
.email-application .email-app-list-mails .email-actions {
    border-top: 1px solid #616279;
}
.email-application .list-group-item.active,
.email-application .list-group-item.active:focus,
.email-application .list-group-item.active:hover {
    background-color: #009da0;
    border-color: #009da0;
    color: #fff;
}
.email-application .list-group-item-action {
    color: #bdbdc7;
}
.wrapper
    .email-application
    .list-group-item-action.bg-grey.bg-lighten-4 {
    background-color: #4f5069 !important;
}
.chat-application .chat-app-window,
.chat-application .chat-fixed-search,
.chat-application .chat-sidebar {
    background-color: #404258;
}
.chat-application .chat-fixed-search,
.chat-application .chat-name,
.chat-application .chat-sidebar {
    border-color: #616279;
}
.chat-application .bg-blue-grey.bg-lighten-5 {
    background-color: #4f5069 !important;
}
.contact-application .contact-app-list-mails,
.contact-application .contact-app-mail-content,
.contact-application .contact-app-sidebar {
    background-color: #404258;
    border-color: #616279;
}
.wrapper
    .contact-application
    .contact-app-menu
    .form-group-compose {
    border-bottom: 1px solid #616279;
}
.contact-application .list-group-item.active,
.contact-application .list-group-item.active:focus,
.contact-application .list-group-item.active:hover {
    background-color: #009da0;
    border-color: #009da0;
    color: #fff;
}
.contact-application .list-group-item-action {
    color: #bdbdc7;
}
.wrapper
    .contact-application
    .list-group-item-action.bg-grey.bg-lighten-4 {
    background-color: #4f5069 !important;
}
.todo-application .todo-app-list-mails,
.todo-application .todo-app-mail-content,
.todo-application .todo-app-sidebar {
    background-color: #404258;
    border-color: #616279;
}
.todo-application .todo-app-menu .form-group-compose {
    border-bottom: 1px solid #616279;
}
.todo-application .list-group-item.active,
.todo-application .list-group-item.active:focus,
.todo-application .list-group-item.active:hover {
    background-color: #009da0;
    border-color: #009da0;
    color: #fff;
}
.todo-application .list-group-item-action {
    color: #bdbdc7;
}
.wrapper
    .todo-application
    .list-group-item-action.bg-grey.bg-lighten-4 {
    background-color: #4f5069 !important;
}
.horizontal-timeline-wrapper li[data-radium='true'] {
    background-image: none !important;
}
.invoice-template .bg-grey.bg-lighten-4 {
    background-color: #404258 !important;
}
.login-layout {
    background-color: #404258;
}
ol.progtrckr li.progtrckr-doing {
    color: #fff;
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
    background-color: #2e2f42;
}
.ReactTable .-pagination .-btn {
    color: #fff;
}
.rbc-off-range-bg {
    background-color: #404258;
}
.rbc-today {
    background-color: #2e2f42;
}
.rbc-toolbar button {
    color: #bdbdc7;
}
.customizer .customizer-content {
    background-color: #2e2f42;
}
.form-group .checkbox label,
.form-group .radio label {
    color: rgba(0, 0, 0, 0.87);
}
.btn-group span:first-child:not(:last-child) .btn.dropdown-toggle,
.input-group span:first-child:not(:last-child) .btn.dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group span:last-child:not(:first-child) .btn.dropdown-toggle,
.input-group span:last-child:not(:first-child) .btn.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group span:not(:last-child):not(:first-child) .btn.dropdown-toggle,
.input-group span:not(:last-child):not(:first-child) .btn.dropdown-toggle {
    border-radius: 0;
}
.list-group-item:hover {
    z-index: 0;
}
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    background-color: #009da0;
    border-color: #009da0;
}
.label {
    padding-bottom: 0.1em !important;
    letter-spacing: 0.5px;
}
.label.label-default {
    background-color: #777 !important;
}
.media-list.media-bordered .media {
    border-top: 1px solid #e4e7ed;
    padding: 1rem 0;
}
.media-list.media-bordered .media:first-child {
    border-top: 0;
}
.media iframe,
.media img {
    margin-right: 1rem !important;
}
.media .media-body + a img {
    margin-right: 0 !important;
    margin-left: 1rem;
}
.media .media-body.text-right + a img {
    margin-left: 0;
}
.media .media-body,
.media .media-left,
.media .media-right {
    padding-top: 0;
}
.media .round-media {
    border-radius: 50%;
}
.dropdown-menu {
    display: none;
    opacity: inherit;
}
.dropdown-menu .active a,
.dropdown-menu .active a:focus,
.dropdown-menu .active a:hover {
    background-color: #009da0;
}
.dropdown-menu.show,
.form-control.open ~ .dropdown-menu {
    transform: none;
    opacity: 1;
}
.tab-content {
    padding: 0rem;
    margin-top:0.3rem;
    p{
        font-weight:200;
    }
}
.nav-tabs .nav-link {
    height: 100%;
    font-weight:400;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: initial;
    border-bottom: 2px solid #00a887;
}
ngb-accordion [role='tabpanel'] {
    padding: 0 2.5rem !important;
}
ngb-accordion .card {
    border-bottom: 2px solid #f5f7fa;
    margin: 0;
}
.swal2-modal .swal2-confirm {
    background-color: #00a887 !important;
}
.swal2-modal .swal2-cancel {
    background-color: #ff586b !important;
}
[dir='rtl'] .media iframe,
[dir='rtl'] .media img {
    margin-left: 1rem !important;
    margin-right: auto !important;
}
[dir='rtl'] .media .media-body + a img {
    margin-left: 0 !important;
    margin-right: 1rem;
}
[dir='rtl'] .media .media-body.text-right + a img {
    margin-right: 0;
    margin-left: auto;
}
.chat-application {
    overflow: hidden;
}
.chat-application .content-wrapper {
    padding: 0 !important;
}
.chat-application .chat-sidebar {
    vertical-align: top;
    height: calc(100vh - 168px);
    z-index: 1;
}
.chat-application .chat-fixed-search,
.chat-application .chat-sidebar {
    background: #fff;
    position: fixed;
    width: 300px;
    border-right: 1px solid #e4e7ed;
}
.chat-application .chat-fixed-search {
    z-index: 999;
    border-bottom: 1px solid #e4e7ed;
}
.chat-application .users-list-padding {
    padding-top: 63px;
}
.chat-application .list-group-item-heading {
    font-size: 0.8rem;
    font-weight: 500;
}
.chat-application .list-group-item-heading span {
    font-size: 0.8rem;
    font-weight: 400;
}
.chat-application .chat-name {
    margin-left: 300px;
    border-bottom: 1px solid #e4e7ed;
}
.chat-application .chat-app-window {
    margin-left: 300px;
    padding: 20px 30px;
    overflow-y: scroll;
    text-align: center;
    height: calc(100vh - 323px);
    background-color: #fff;
}
.chat-application .chat-app-form {
    margin-left: 300px;
    position: relative;
    padding: 20px 10px;
    overflow: hidden;
}
.chat-application .chats {
    padding: 0;
}
.chat-application .chats .chat-body {
    display: block;
    margin: 10px 30px 0 0;
    overflow: hidden;
}
.chat-application .chats .chat-body .chat-content {
    text-align: right;
    position: relative;
    display: block;
    float: right;
    padding: 8px 15px;
    margin: 0 20px 10px 0;
    clear: both;
    color: #fff;
    background-color: #009da0;
    border-radius: 4px;
}
.chat-application .chats .chat-body .chat-content:before {
    position: absolute;
    top: 10px;
    right: -10px;
    width: 0;
    height: 0;
    content: '';
    border: 5px solid transparent;
    border-left-color: #009da0;
}
.chat-application .chats .chat-body .chat-content + .chat-content:before {
    border-color: transparent;
}
.chat-application .chats .chat-body .chat-content p {
    margin: 0;
    color: #fff;
}
.chat-application .chats .chat-avatar {
    float: right;
}
.chat-application .chats .chat-avatar .avatar {
    width: 50px;
    margin-top: -10px;
}
.chat-application .chats .time {
    color: #adb5bd;
    font-size: 12px;
    text-align: center;
    margin: 40px 0;
}
.chat-application .chats .chat-left .chat-avatar {
    float: left;
}
.chat-application .chats .chat-left .chat-body {
    margin-right: 0;
    margin-left: 30px;
}
.chat-application .chats .chat-left .chat-content {
    text-align: left;
    float: left;
    margin: 0 0 10px 20px;
    color: #343a40;
    background-color: #f5f7fa;
}
.chat-application .chats .chat-left .chat-content + .chat-content:before {
    border-color: transparent;
}
.chat-application .chats .chat-left .chat-content:before {
    right: auto;
    left: -10px;
    border-right-color: #fff;
    border-left-color: transparent;
}
.chat-application .chats .chat-left .chat-content p {
    color: #343a40;
}
.chat-application .form-control-position {
    cursor: pointer;
}
.chat-application .form-control-position.control-position-right {
    right: 18px;
    top: 2px;
    cursor: pointer;
}
@media (max-width: 767.98px) {
    .chat-application .chat-app-window {
        height: calc(100% - 132px);
        margin-left: 0;
    }
    .chat-application .chat-app-form,
    .chat-application .chat-name {
        margin-left: 0;
    }
    .chat-application .chat-sidebar {
        left: 0;
    }
}
.email-application .email-app-sidebar {
    z-index: 3;
    background: #fff;
    position: fixed;
    width: 250px;
    height: calc(100vh - 150px);
    border-right: 1px solid #e4e7ed;
}
.email-application .email-app-sidebar .email-app-sidebar-content {
    position: relative;
    height: 100%;
    padding: 10px 20px;
}
.email-application .email-app-content {
    margin-top: 0;
    padding: 0;
    min-height: 100%;
}
.email-application .email-app-list-mails {
    position: relative;
    padding: 0;
    width: 100%;
    overflow-y: hidden;
    background-color: #fff;
    height: calc(100vh - 150px);
    overflow-x: hidden;
}
.email-application .email-app-list-mails .email-actions {
    border-top: 1px solid #e6ecf5;
    border-bottom: 1px solid #e6ecf5;
}
.email-application .email-app-list-mails .email-actions .email-action-icon {
    margin: 16px 12px;
}
.email-application
    .email-app-list-mails
    .email-actions
    .email-action-icon
    > button {
    margin: 0;
    padding: 0;
}
.email-application .email-app-list-mails .scrollbar-container {
    height: calc(100% - 140px);
}
.email-application .email-app-list-mails .list-group-item {
    padding: 0.75rem;
    cursor: pointer;
}
.email-application .email-app-list-mails .list-group-item .form-check-input {
    position: relative;
    margin: 0;
}
.email-application .email-app-mail-content {
    float: left;
    width: 100%;
    position: relative;
    padding: 0;
    background-color: #fff;
    min-height: calc(100vh - 150px);
    transition: all 0.5s ease-in-out;
}
.email-application .email-app-mail-content-detail {
    position: relative;
    padding: 20px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
}
.email-application .hide-email-content {
    transition: all 0.5s ease-in-out;
    transform: translate3d(-110%, 0, 0);
}
.email-application .email-app-menu .form-group-compose,
.email-application .email-app-menu .list-group-messages {
    border-bottom: 1px solid #e4e7ed;
}
.email-application .email-app-menu .list-group-messages .list-group-item {
    cursor: pointer;
}
.email-application .email-app-menu .list-group-messages a {
    padding: 0.7rem 1rem;
    font-size: 1.1rem;
}
.email-application .email-app-menu .list-group-messages a.active {
    border-radius: 3px !important;
}
.email-application .email-app-menu .list-group-messages a.active svg {
    stroke: #fff;
}
.email-application .email-app-list {
    position: relative;
    height: 100%;
}
.email-application .email-app-list p {
    margin-bottom: 0.2rem;
}
.email-application .list-group-item.active,
.email-application .list-group-item.active:focus,
.email-application .list-group-item.active:hover {
    color: #fff;
}
.email-application .avatar-md {
    width: 40px;
}
.email-application .avatar img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0;
    border-radius: 1000px;
}
.email-application .avatar-md span.text-circle {
    width: 40px;
    height: 40px;
}
.email-application .avatar span.text-circle {
    text-align: center;
    vertical-align: middle;
    color: #fff;
    font-size: 1.2rem;
    background: #babfc7;
    display: table-cell;
}
.email-application .email-app-content-wrapper {
    overflow-y: scroll;
    height: calc(100% - 30px);
}
.email-application .email-app-content-wrapper .email-app-options,
.email-application .email-app-content-wrapper .email-app-title {
    border-bottom: 1px solid #e4e7ed;
}
.email-application .chat-fixed-search {
    z-index: 999;
    background: #fff;
    padding: 2rem 1rem;
}
.email-application .chat-fixed-search fieldset {
    border-bottom: 1px solid #e4e7ed;
}
@media (min-width: 992px) {
    .email-app-list-mails {
        width: 35% !important;
        border-right: 1px solid #e6ecf5;
        float: left;
    }
    .email-application .email-app-mail-content {
        width: 64%;
    }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
    .email-app-list-mails {
        width: 35% !important;
        border-right: 1px solid #e6ecf5;
        float: left;
    }
    .email-application .email-app-mail-content {
        width: 65%;
    }
}
@media (min-width: 1200px) {
    .email-app-content {
        margin-left: 250px;
    }
}
@media (max-width: 767.98px) {
    .email-app-mail-content {
        position: absolute !important;
        top: 0 !important;
        left: 15px !important;
        width: 98% !important;
        height: 100%;
        transition: all 1s ease-in-out;
        -moz-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
        -ms-transition: all 1s ease-in-out;
        max-height: calc(100vh - 150px);
    }
}
@media (max-width: 991.98px) {
    .email-application .email-app-sidebar {
        left: 0;
    }
}
[dir='rtl'] .email-application .email-app-sidebar {
    border-left: 1px solid #e4e7ed;
    border-right: 0;
}
[dir='rtl'] .email-application .email-app-mail-content {
    float: right;
}
@media (min-width: 992px) {
    [dir='rtl'] .email-app-list-mails {
        border-left: 1px solid #e6ecf5;
        float: right;
        border-right: 0;
    }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
    [dir='rtl'] .email-app-list-mails {
        border-left: 1px solid #e6ecf5;
        float: right;
        border-right: 0;
    }
}
@media (min-width: 1200px) {
    [dir='rtl'] .email-app-content {
        margin-right: 250px;
        margin-left: 0;
    }
}
@media (max-width: 767.98px) {
    [dir='rtl'] .email-app-mail-content {
        right: 15px !important;
        left: auto;
    }
}
@media (max-width: 991.98px) {
    [dir='rtl'] .email-application .email-app-sidebar {
        right: 0;
        left: auto;
    }
}
.todo-application .todo-app-sidebar {
    z-index: 3;
    background: #fff;
    position: fixed;
    width: 250px;
    height: calc(100vh - 150px);
    border-right: 1px solid #e4e7ed;
}
.todo-application .todo-app-sidebar .todo-app-sidebar-content {
    position: relative;
    height: 100%;
    padding: 20px 25px;
}
.todo-application .todo-app-content {
    margin-top: 0;
    padding: 0;
    min-height: 100%;
}
.todo-application .todo-app-list-mails {
    position: relative;
    padding: 0;
    width: 100%;
    overflow-y: hidden;
    background-color: #fff;
    height: calc(100vh - 150px);
}
.todo-application .todo-app-mail-content {
    float: left;
    width: 100%;
    position: relative;
    padding: 0;
    background-color: #fff;
    min-height: calc(100vh - 212px);
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
}
.todo-application .todo-app-content-detail {
    position: relative;
    padding: 30px;
    max-height: calc(100vh - 212px);
    overflow-y: auto;
}
.todo-application .hide-email-content {
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transform: translate3d(-110%, 0, 0);
}
.todo-application .todo-app-menu .form-group-compose {
    border-bottom: 1px solid #e4e7ed;
}
.todo-application .todo-app-menu .list-group-messages .list-group-item {
    cursor: pointer;
}
.todo-application .todo-app-menu .list-group-messages .list-group-item.active {
    border-radius: 3px !important;
}
.todo-application
    .todo-app-menu
    .list-group-messages
    .list-group-item.active
    svg {
    color: #fff !important;
}
.todo-application .todo-app-menu .list-group-messages a {
    padding: 0.85rem 1rem;
    font-size: 1.1rem;
}
.todo-application .todo-app-list {
    position: relative;
    height: 90%;
    padding: 1rem 0;
}
.todo-application .todo-app-list p {
    margin-bottom: 0.2rem;
}
.todo-application .todo-app-list .list-group-item {
    padding: 0.75rem;
    cursor: pointer;
}
.todo-application .todo-app-list .list-group-item .form-check-input {
    position: relative;
    margin: 0;
}
.todo-application .list-group-item.active,
.todo-application .list-group-item.active:focus,
.todo-application .list-group-item.active:hover {
    color: #fff;
    border-radius: 3px !important;
}
.todo-application .avatar-md {
    width: 40px;
}
.todo-application .avatar img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0;
    border-radius: 1000px;
}
.todo-application .avatar-md span.text-circle {
    width: 40px;
    height: 40px;
}
.todo-application .avatar span.text-circle {
    text-align: center;
    vertical-align: middle;
    color: #fff;
    font-size: 1.2rem;
    background: #babfc7;
    display: table-cell;
}
.todo-application .todo-app-content-wrapper {
    overflow-y: scroll;
    height: calc(100% - 30px);
}
.todo-application .todo-app-content-wrapper .todo-app-options,
.todo-application .todo-app-content-wrapper .todo-app-title {
    border-bottom: 1px solid #e4e7ed;
}
.todo-application .chat-fixed-search {
    z-index: 999;
    background: #fff;
    padding: 2rem 1rem;
}
.todo-application .chat-fixed-search fieldset {
    border-bottom: 1px solid #e4e7ed;
}
@media (min-width: 992px) {
    .todo-app-list-mails {
        width: 50% !important;
        border-right: 1px solid #e6ecf5;
        float: left;
    }
    .todo-application .todo-app-mail-content {
        width: 49%;
        height: 100%;
    }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
    .todo-app-list-mails {
        width: 35% !important;
        border-right: 1px solid #e6ecf5;
        float: left;
    }
    .todo-application .todo-app-mail-content {
        width: 65%;
    }
}
@media (min-width: 1200px) {
    .todo-app-content {
        margin-left: 250px;
    }
}
@media (max-width: 767.98px) {
    .todo-app-mail-content {
        position: absolute !important;
        top: 0 !important;
        left: 15px !important;
        width: 98% !important;
        height: 100%;
        transition: all 1s ease-in-out;
        -moz-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
        -ms-transition: all 1s ease-in-out;
        max-height: calc(100vh - 150px);
    }
}
@media (max-width: 991.98px) {
    .todo-application .todo-app-sidebar {
        left: 0;
    }
}
[dir='rtl'] .todo-application .todo-app-sidebar {
    border-left: 1px solid #e4e7ed;
    border-right: 0;
}
[dir='rtl'] .todo-application .todo-app-mail-content {
    float: right;
}
@media (min-width: 992px) {
    [dir='rtl'] .todo-app-list-mails {
        border-left: 1px solid #e6ecf5;
        float: right;
        border-right: 0;
    }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
    [dir='rtl'] .todo-app-list-mails {
        border-left: 1px solid #e6ecf5;
        float: right;
        border-right: 0;
    }
}
@media (min-width: 1200px) {
    [dir='rtl'] .todo-app-content {
        margin-right: 250px;
        margin-left: 0;
    }
}
@media (max-width: 767.98px) {
    [dir='rtl'] .todo-app-mail-content {
        right: 15px !important;
        left: auto;
    }
}
@media (max-width: 991.98px) {
    [dir='rtl'] .todo-application .todo-app-sidebar {
        right: 0;
        left: auto;
    }
}
.contact-application .contact-app-sidebar {
    z-index: 3;
    background: #fff;
    position: fixed;
    width: 250px;
    height: calc(100vh - 150px);
    border-right: 1px solid #e4e7ed;
}
.contact-application .contact-app-sidebar .contact-app-sidebar-content {
    position: relative;
    height: 100%;
    padding: 20px 25px;
}
.contact-application .contact-app-content {
    margin-top: 0;
    padding: 0;
    min-height: 100%;
}
.contact-application .contact-app-list-mails {
    position: relative;
    padding: 0;
    width: 100%;
    overflow-y: hidden;
    background-color: #fff;
    height: calc(100vh - 150px);
}
.contact-application .contact-app-mail-content {
    float: left;
    width: 100%;
    position: relative;
    padding: 0;
    background-color: #fff;
    min-height: calc(100vh - 212px);
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
}
.contact-application .contact-app-content-detail {
    position: relative;
    padding: 30px;
    max-height: calc(100vh - 212px);
    overflow-y: auto;
}
.contact-application .hide-email-content {
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transform: translate3d(-110%, 0, 0);
}
.contact-application .contact-app-menu .form-group-compose {
    border-bottom: 1px solid #e4e7ed;
}
.contact-application .contact-app-menu .list-group-messages .list-group-item {
    cursor: pointer;
}
.contact-application
    .contact-app-menu
    .list-group-messages
    .list-group-item.active {
    border-radius: 3px !important;
}
.contact-application
    .contact-app-menu
    .list-group-messages
    .list-group-item.active
    svg {
    color: #fff !important;
}
.contact-application .contact-app-menu .list-group-messages a {
    padding: 0.85rem 1rem;
    font-size: 1.1rem;
}
.contact-application .contact-app-list {
    position: relative;
    height: 90%;
    padding: 1rem 0;
}
.contact-application .contact-app-list p {
    margin-bottom: 0.2rem;
}
.contact-application .contact-app-list .list-group-item {
    cursor: pointer;
}
.contact-application .list-group-item.active,
.contact-application .list-group-item.active:focus,
.contact-application .list-group-item.active:hover {
    color: #fff;
}
.contact-application .avatar-md {
    width: 40px;
}
.contact-application .avatar img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0;
    border-radius: 1000px;
}
.contact-application .avatar-md span.text-circle {
    width: 40px;
    height: 40px;
}
.contact-application .avatar span.text-circle {
    text-align: center;
    vertical-align: middle;
    color: #fff;
    font-size: 1.2rem;
    background: #babfc7;
    display: table-cell;
}
.contact-application .contact-app-content-wrapper {
    overflow-y: scroll;
    height: calc(100% - 30px);
}
.contact-application .contact-app-content-wrapper .contact-app-options,
.contact-application .contact-app-content-wrapper .contact-app-title {
    border-bottom: 1px solid #e4e7ed;
}
.contact-application .chat-fixed-search {
    z-index: 999;
    background: #fff;
    padding: 2rem 1rem;
}
.contact-application .chat-fixed-search fieldset {
    border-bottom: 1px solid #e4e7ed;
}
@media (min-width: 992px) {
    .contact-app-list-mails {
        width: 30% !important;
        border-right: 1px solid #e6ecf5;
        float: left;
    }
    .contact-application .contact-app-mail-content {
        width: 69%;
        height: 100%;
    }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
    .contact-app-list-mails {
        width: 35% !important;
        border-right: 1px solid #e6ecf5;
        float: left;
    }
    .contact-application .contact-app-mail-content {
        width: 65%;
    }
}
@media (min-width: 1200px) {
    .contact-app-content {
        margin-left: 250px;
    }
}
@media (max-width: 767.98px) {
    .contact-app-mail-content {
        position: absolute !important;
        top: 0 !important;
        left: 15px !important;
        width: 98% !important;
        height: 100%;
        transition: all 1s ease-in-out;
        -moz-transition: all 1s ease-in-out;
        -o-transition: all 1s ease-in-out;
        -ms-transition: all 1s ease-in-out;
        max-height: calc(100vh - 150px);
    }
}
@media (max-width: 991.98px) {
    .contact-application .contact-app-sidebar {
        left: 0;
    }
}
[dir='rtl'] .contact-application .contact-app-sidebar {
    border-left: 1px solid #e4e7ed;
    border-right: 0;
}
[dir='rtl'] .contact-application .contact-app-mail-content {
    float: right;
}
@media (min-width: 992px) {
    [dir='rtl'] .contact-app-list-mails {
        border-left: 1px solid #e6ecf5;
        float: right;
        border-right: 0;
    }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
    [dir='rtl'] .contact-app-list-mails {
        border-left: 1px solid #e6ecf5;
        float: right;
        border-right: 0;
    }
}
@media (min-width: 1200px) {
    [dir='rtl'] .contact-app-content {
        margin-right: 250px;
        margin-left: 0;
    }
}
@media (max-width: 767.98px) {
    [dir='rtl'] .contact-app-mail-content {
        right: 15px !important;
        left: auto;
    }
}
@media (max-width: 991.98px) {
    [dir='rtl'] .contact-application .contact-app-sidebar {
        right: 0;
        left: auto;
    }
}
.gallery-thumbnail {
    max-width: 100%;
    height: auto;
}
.grid-item,
.grid-sizer {
    width: 20%;
}
.grid-item > div,
.grid-item figure {
    margin: 0.4rem;
}
@media screen and (max-width: 2200px) {
    .grid-item,
    .grid-sizer {
        width: 20%;
    }
}
@media screen and (max-width: 1800px) {
    .grid-item,
    .grid-sizer {
        width: 30%;
    }
}
@media screen and (max-width: 1600px) {
    .grid-item,
    .grid-sizer {
        width: 33.33%;
    }
}
@media screen and (max-width: 1224px) {
    .grid-item,
    .grid-sizer {
        width: 33.33%;
    }
}
@media screen and (max-width: 980px) {
    .grid-item,
    .grid-sizer {
        width: 50%;
    }
}
@media screen and (max-width: 720px) {
    .grid-item,
    .grid-sizer {
        width: 50%;
    }
}
@media screen and (max-width: 480px) {
    .grid-item,
    .grid-sizer {
        width: 100%;
    }
}
.grid-hover {
    position: relative;
    margin: 0 auto;
    padding: 1em 0 4em;
    list-style: none;
}
.grid-hover figure {
    position: relative;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 320px;
    max-width: 480px;
    max-height: 360px;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
}
.grid-hover figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}
.grid-hover figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.grid-hover figure figcaption:after,
.grid-hover figure figcaption:before {
    pointer-events: none;
}
.grid-hover figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}
.grid-hover figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
    margin: 0;
}
.grid-hover figure h2 span {
    font-weight: 800;
}
.grid-hover figure p {
    margin: 0;
    letter-spacing: 1px;
    font-size: 68.5%;
}
figure.effect-lily img {
    max-width: none;
    width: calc(100% + 50px);
    opacity: 0.7;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(-40px, 0, 0);
}
figure.effect-lily figcaption {
    text-align: left;
}
figure.effect-lily figcaption > div {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    width: 100%;
    height: 50%;
}
figure.effect-lily h2 {
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-lily h2,
figure.effect-lily p {
    transform: translate3d(0, 40px, 0);
}
figure.effect-lily p {
    color: hsla(0, 0%, 100%, 0.8);
    opacity: 0;
    transition: opacity 0.2s, -webkit-transform 0.35s;
    transition: opacity 0.2s, transform 0.35s;
    transition: opacity 0.2s, transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-lily:hover img,
figure.effect-lily:hover p {
    opacity: 1;
    transform: translateZ(0);
}
figure.effect-lily:hover p {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
}
figure.effect-lily:hover h2 {
    transform: translateZ(0);
}
figure.effect-sadie figcaption:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(
        top,
        rgba(72, 76, 97, 0),
        rgba(72, 76, 97, 0.8) 75%
    );
    background: linear-gradient(
        180deg,
        rgba(72, 76, 97, 0) 0,
        rgba(72, 76, 97, 0.8) 75%
    );
    content: '';
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-sadie h2 {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    color: #484c61;
    transition: color 0.35s, -webkit-transform 0.35s;
    transition: transform 0.35s, color 0.35s;
    transition: transform 0.35s, color 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, -50%, 0);
}
figure.effect-sadie p {
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    width: 100%;
    opacity: 0;
    transform: translate3d(0, 10px, 0);
}
figure.effect-sadie:hover h2 {
    color: #fff;
    transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0);
}
figure.effect-sadie:hover figcaption:before,
figure.effect-sadie:hover p {
    opacity: 1;
    transform: translateZ(0);
}
figure.effect-roxy {
    background: -webkit-linear-gradient(45deg, #ff89e9, #05abe0);
    background: linear-gradient(45deg, #ff89e9, #05abe0);
}
figure.effect-roxy img {
    max-width: none;
    width: calc(100% + 60px);
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(-50px, 0, 0);
}
figure.effect-roxy figcaption {
    padding: 3em;
    text-align: left;
}
figure.effect-roxy figcaption:before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 1px solid #fff;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(-20px, 0, 0);
}
figure.effect-roxy h2 {
    padding: 30% 0 10px;
}
figure.effect-roxy p {
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(-10px, 0, 0);
}
figure.effect-roxy:hover img {
    opacity: 0.7;
    transform: translateZ(0);
}
figure.effect-roxy:hover figcaption:before,
figure.effect-roxy:hover p {
    opacity: 1;
    transform: translateZ(0);
}
figure.effect-bubba {
    background: #9e5406;
}
figure.effect-bubba img {
    opacity: 0.7;
    transition: opacity 0.35s;
}
figure.effect-bubba:hover img {
    opacity: 0.4;
}
figure.effect-bubba:hover figcaption:after,
figure.effect-bubba:hover figcaption:before {
    opacity: 1;
    transform: scale(1);
}
figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
    opacity: 1;
    transform: translateZ(0);
}
figure.effect-bubba figcaption:before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: scaleX(0);
}
figure.effect-bubba figcaption:after,
figure.effect-bubba figcaption:before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-bubba figcaption:after {
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    transform: scaleY(0);
}
figure.effect-bubba h2 {
    padding-top: 30%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, -20px, 0);
}
figure.effect-bubba p {
    padding: 20px 2.5em;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, 20px, 0);
}
figure.effect-romeo {
    -webkit-perspective: 1000px;
    perspective: 1000px;
}
figure.effect-romeo img {
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translateZ(300px);
}
figure.effect-romeo:hover img {
    opacity: 0.6;
    transform: translateZ(0);
}
figure.effect-romeo:hover figcaption:before {
    opacity: 0.5;
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
}
figure.effect-romeo:hover figcaption:after {
    opacity: 0.5;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}
figure.effect-romeo:hover h2 {
    transform: translate3d(0, -50%, 0) translate3d(0, -100%, 0);
}
figure.effect-romeo:hover p {
    transform: translate3d(0, -50%, 0) translate3d(0, 100%, 0);
}
figure.effect-romeo figcaption:after,
figure.effect-romeo figcaption:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 1px;
    background: #fff;
    content: '';
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(-50%, -50%, 0);
}
figure.effect-romeo h2 {
    transform: translate3d(0, -50%, 0) translate3d(0, -150%, 0);
}
figure.effect-romeo h2,
figure.effect-romeo p {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-romeo p {
    padding: 0.25em 2em;
    transform: translate3d(0, -50%, 0) translate3d(0, 150%, 0);
}
figure.effect-layla {
    background: #18a367;
}
figure.effect-layla img {
    height: 390px;
    transform: translate3d(0, -30px, 0);
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-layla figcaption {
    padding: 3em;
}
figure.effect-layla figcaption:before {
    top: 50px;
    right: 30px;
    bottom: 50px;
    left: 30px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: scaleX(0);
    transform-origin: 0 0;
}
figure.effect-layla figcaption:after,
figure.effect-layla figcaption:before {
    position: absolute;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-layla figcaption:after {
    top: 30px;
    right: 50px;
    bottom: 30px;
    left: 50px;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    transform: scaleY(0);
    transform-origin: 100% 0;
}
figure.effect-layla h2 {
    padding-top: 26%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, -30px, 0);
}
figure.effect-layla p {
    padding: 0.5em 2em;
    text-transform: none;
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-layla:hover img {
    opacity: 0.7;
    transform: translateZ(0);
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}
figure.effect-layla:hover figcaption:after,
figure.effect-layla:hover figcaption:before {
    opacity: 1;
    transform: scale(1);
}
figure.effect-layla:hover figcaption:after {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}
figure.effect-layla:hover h2,
figure.effect-layla:hover p {
    opacity: 1;
    transform: translateZ(0);
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}
figure.effect-honey {
    background: #4a3753;
}
figure.effect-honey img {
    opacity: 0.9;
    transition: opacity 0.35s;
}
figure.effect-honey:hover img {
    opacity: 0.5;
}
figure.effect-honey:hover figcaption:before,
figure.effect-honey:hover h2,
figure.effect-honey:hover h2 i {
    opacity: 1;
    transform: translateZ(0);
}
figure.effect-honey figcaption:before {
    height: 10px;
    background: #fff;
    content: '';
    transform: translate3d(0, 10px, 0);
}
figure.effect-honey figcaption:before,
figure.effect-honey h2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-honey h2 {
    padding: 1em 1.5em;
    text-align: left;
}
figure.effect-honey h2,
figure.effect-honey h2 i {
    transform: translate3d(0, -30px, 0);
}
figure.effect-honey h2 i {
    font-style: normal;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-oscar {
    background: -webkit-linear-gradient(45deg, #22682a, #9b4a1b 40%, #3a342a);
    background: linear-gradient(45deg, #22682a, #9b4a1b 40%, #3a342a);
}
figure.effect-oscar img {
    opacity: 0.9;
    transition: opacity 0.35s;
}
figure.effect-oscar figcaption {
    padding: 3em;
    background-color: rgba(58, 52, 42, 0.7);
    transition: background-color 0.35s;
}
figure.effect-oscar figcaption:before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 1px solid #fff;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: scale(0);
}
figure.effect-oscar h2 {
    margin: 20% 0 10px;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, 100%, 0);
}
figure.effect-oscar p {
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: scale(0);
}
figure.effect-oscar:hover h2 {
    transform: translateZ(0);
}
figure.effect-oscar:hover figcaption {
    background-color: rgba(58, 52, 42, 0);
}
figure.effect-oscar:hover figcaption:before,
figure.effect-oscar:hover p {
    opacity: 1;
    transform: scale(1);
}
figure.effect-oscar:hover img {
    opacity: 0.4;
}
figure.effect-marley figcaption {
    text-align: right;
}
figure.effect-marley h2 {
    position: absolute;
    right: 30px;
    left: 30px;
    padding: 10px 0;
    top: 30px;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, 20px, 0);
}
figure.effect-marley h2:after {
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    background: #fff;
    content: '';
    transform: translate3d(0, 40px, 0);
}
figure.effect-marley h2:after,
figure.effect-marley p {
    position: absolute;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
figure.effect-marley p {
    right: 30px;
    left: 30px;
    padding: 10px 0;
    bottom: 30px;
    line-height: 1.5;
    transform: translate3d(0, 100%, 0);
}
figure.effect-marley:hover h2 {
    transform: translateZ(0);
}
figure.effect-marley:hover h2:after,
figure.effect-marley:hover p {
    opacity: 1;
    transform: translateZ(0);
}
figure.effect-ruby {
    background-color: #17819c;
}
figure.effect-ruby img {
    opacity: 0.7;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: scale(1.15);
}
figure.effect-ruby:hover img {
    opacity: 0.5;
    transform: scale(1);
}
figure.effect-ruby:hover h2 {
    transform: translateZ(0);
}
figure.effect-ruby:hover p {
    opacity: 1;
    transform: translateZ(0) scale(1);
}
figure.effect-ruby h2 {
    margin-top: 20%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, 20px, 0);
}
figure.effect-ruby p {
    margin: 1em 0 0;
    padding: 3em;
    border: 1px solid #fff;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, 20px, 0) scale(1.1);
}
figure.effect-milo {
    background: #2e5d5a;
}
figure.effect-milo img {
    max-width: none;
    width: calc(100% + 60px);
    opacity: 1;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(-30px, 0, 0) scale(1.12);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
figure.effect-milo:hover img {
    opacity: 0.5;
    transform: translateZ(0) scale(1);
}
figure.effect-milo:hover p {
    opacity: 1;
    transform: translateZ(0);
}
figure.effect-milo h2 {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 1em 1.2em;
}
figure.effect-milo p {
    padding: 0 10px 0 0;
    width: 50%;
    border-right: 1px solid #fff;
    text-align: right;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(-40px, 0, 0);
}
figure.effect-dexter {
    background: -webkit-linear-gradient(top, #258dc8, #683c13);
    background: linear-gradient(180deg, #258dc8 0, #683c13);
}
figure.effect-dexter img {
    transition: opacity 0.35s;
}
figure.effect-dexter:hover img {
    opacity: 0.4;
}
figure.effect-dexter:hover figcaption:after,
figure.effect-dexter:hover p {
    transform: translateZ(0);
}
figure.effect-dexter:hover p {
    opacity: 1;
}
figure.effect-dexter figcaption {
    padding: 3em;
    text-align: left;
}
figure.effect-dexter figcaption:after {
    position: absolute;
    right: 30px;
    bottom: 30px;
    left: 30px;
    height: calc(50% - 30px);
    border: 7px solid #fff;
    content: '';
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, -100%, 0);
}
figure.effect-dexter p {
    position: absolute;
    right: 60px;
    bottom: 60px;
    left: 60px;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, -100px, 0);
}
figure.effect-sarah {
    background: #42b078;
}
figure.effect-sarah img {
    max-width: none;
    width: calc(100% + 20px);
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(-10px, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
figure.effect-sarah:hover img {
    opacity: 0.4;
}
figure.effect-sarah:hover h2:after,
figure.effect-sarah:hover img,
figure.effect-sarah:hover p {
    transform: translateZ(0);
}
figure.effect-sarah:hover p {
    opacity: 1;
}
figure.effect-sarah figcaption {
    text-align: left;
}
figure.effect-sarah h2 {
    position: relative;
    overflow: hidden;
    padding: 0.5em 0;
}
figure.effect-sarah h2:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #fff;
    content: '';
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(-100%, 0, 0);
}
figure.effect-sarah p {
    padding: 1em 0;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(100%, 0, 0);
}
figure.effect-zoe figcaption {
    top: auto;
    bottom: 0;
    padding: 1em;
    height: 3.75em;
    background: #fff;
    color: #3c4a50;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, 100%, 0);
}
figure.effect-zoe h2 {
    float: left;
    display: block;
}
figure.effect-zoe h2,
figure.effect-zoe p.icon-links a {
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    transform: translate3d(0, 200%, 0);
}
figure.effect-zoe p.icon-links a {
    float: right;
    color: #3c4a50;
    font-size: 1.4em;
}
figure.effect-zoe p.icon-links a span:before {
    display: inline-block;
    padding: 8px 10px;
    font-family: feathericons;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
figure.effect-zoe:hover p.icon-links a {
    transform: translateZ(0);
}
figure.effect-zoe:hover p.icon-links a:focus,
figure.effect-zoe:hover p.icon-links a:hover {
    color: #252d31;
}
figure.effect-zoe:hover p.icon-links a:nth-child(3) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}
figure.effect-zoe:hover p.icon-links a:nth-child(2) {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}
figure.effect-zoe:hover p.icon-links a:first-child {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}
figure.effect-zoe:hover p.description {
    opacity: 1;
}
figure.effect-zoe:hover figcaption,
figure.effect-zoe:hover h2 {
    transform: translateZ(0);
}
figure.effect-zoe:hover h2 {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}
figure.effect-zoe p.description {
    position: absolute;
    bottom: 8em;
    padding: 2em;
    color: #fff;
    text-transform: none;
    font-size: 90%;
    opacity: 0;
    transition: opacity 0.35s;
    backface-visibility: hidden;
}
figure.effect-chico img {
    transition: opacity 0.35s, transform 0.35s;
    transform: scale(1.12);
}
figure.effect-chico:hover img {
    opacity: 0.5;
    transform: scale(1);
}
figure.effect-chico:hover figcaption:before,
figure.effect-chico:hover p {
    opacity: 1;
    transform: scale(1);
}
figure.effect-chico figcaption {
    padding: 3em;
}
figure.effect-chico figcaption:before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 1px solid #fff;
    content: '';
    transform: scale(1.1);
}
figure.effect-chico figcaption:before,
figure.effect-chico p {
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
}
figure.effect-chico p {
    margin: 0 auto;
    max-width: 200px;
    transform: scale(1.5);
}
figure.effect-chico h2 {
    padding: 20% 0 20px;
}
@media screen and (max-width: 50em) {
    .content {
        padding: 0 10px;
    }
    .grid figure {
        display: inline-block;
        float: none;
        margin: 10px auto;
        width: 100%;
    }
}
.flex-direction-column{
    flex-direction: column;
}
.display-inline {
    display: inline !important;
}
.display-flex{
    display:flex!important
}
.display-block {
    display: block !important;
}
.display-inline-block {
    display: inline-block !important;
}
.display-inline-table {
    display: inline-table !important;
    vertical-align:-webkit-baseline-middle;
}
.display-hidden {
    display: none !important;
}
.display-table-cell {
    display: table-cell !important;
}
.position-fixed {
    position: fixed !important;
}
.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.position-static {
    position: static !important;
}
.position-top-0 {
    top: 0;
}
.position-right-0 {
    right: 0;
}
.position-bottom-0 {
    bottom: 0;
}
.position-left-0 {
    left: 0;
}
.no-edge-top {
    top: 0 !important;
}
.no-edge-bottom {
    bottom: 0 !important;
}
.no-edge-left {
    left: 0 !important;
}
.no-edge-right {
    right: 0 !important;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-move {
    cursor: move;
}
.cursor-default {
    cursor: default;
}
.cursor-progress {
    cursor: progress;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.valign-top {
    vertical-align: top !important;
}
.valign-middle {
    vertical-align: middle !important;
}
.valign-bottom {
    vertical-align: bottom !important;
}
.valign-baseline {
    vertical-align: initial !important;
}
.valign-text-top {
    vertical-align: top !important;
}
.valign-text-bottom {
    vertical-align: text-bottom !important;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-visible {
    overflow: visible;
}
.overflow-auto {
    overflow: auto;
}
.overflow-scroll,
.overflow-x-scroll,
.overflow-y-scroll {
    overflow: scroll;
}
.bullets-inside {
    list-style: inside;
}
.list-style-circle {
    list-style: circle;
}
.list-style-square {
    list-style: square;
}
.list-style-icons {
    padding-left: 10px;
    margin-left: 0;
    list-style: none;
}
.list-style-icons > li i {
    float: left;
    width: 1em;
    margin: 5px 12px 0 0;
}
.border {
    border: 1px solid;
}
.border-top {
    border-top: 1px solid;
}
.border-bottom {
    border-bottom: 1px solid;
}
.border-left {
    border-left: 1px solid;
}
.border-right {
    border-right: 1px solid;
}
.border-2 {
    border-width: 2px !important;
}
.border-top-2 {
    border-top-width: 2px !important;
}
.border-bottom-2 {
    border-bottom-width: 2px !important;
}
.border-left-2 {
    border-left-width: 2px !important;
}
.border-right-2 {
    border-right-width: 2px !important;
}
.border-3 {
    border-width: 3px !important;
}
.border-top-3 {
    border-top-width: 3px !important;
}
.border-bottom-3 {
    border-bottom-width: 3px !important;
}
.border-left-3 {
    border-left-width: 3px !important;
}
.border-right-3 {
    border-right-width: 3px !important;
}
.no-border {
    border: 0 !important;
}
.no-border-top {
    border-top: 0 !important;
}
.no-border-bottom {
    border-bottom: 0 !important;
}
.no-border-left {
    border-left: 0 !important;
}
.no-border-right {
    border-right: 0 !important;
}
.no-border-top-radius {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.no-border-bottom-radius {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.no-border-top-left-radius {
    border-top-left-radius: 0 !important;
}
.no-border-top-right-radius {
    border-top-right-radius: 0 !important;
}
.no-border-bottom-left-radius {
    border-bottom-left-radius: 0 !important;
}
.no-border-bottom-right-radius {
    border-bottom-right-radius: 0 !important;
}
.box-shadow-0 {
    box-shadow: none !important;
}
.box-shadow-1 {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.box-shadow-2 {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.box-shadow-3 {
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.box-shadow-4 {
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
        0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.box-shadow-5 {
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
        0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
.fit {
    max-width: 100% !important;
}
.half-width {
    width: 50% !important;
}
.full-width {
    width: 100% !important;
}
.full-height {
    height: 100% !important;
}
.full-height-vh {
    height: 100vh !important;
}
.width-25 {
    width: 25px !important;
}
.width-35 {
    width: 35px !important;
}
.width-39 {
    width: 39px !important;
}
.width-40 {
    width: 40px !important;
}
.width-50 {
    width: 50px !important;
}
.width-100 {
    width: 100px !important;
}
.width-150 {
    width: 150px !important;
}
.width-200 {
    width: 200px !important;
}
.width-250 {
    width: 250px !important;
}
.width-300 {
    width: 300px !important;
}
.width-350 {
    width: 350px !important;
}
.width-400 {
    width: 400px !important;
}
.width-450 {
    width: 450px !important;
}
.width-500 {
    width: 500px !important;
}
.width-550 {
    width: 550px !important;
}
.width-600 {
    width: 600px !important;
}
.width-650 {
    width: 650px !important;
}
.width-700 {
    width: 700px !important;
}
.width-750 {
    width: 750px !important;
}
.width-800 {
    width: 800px !important;
}
.width-5-per {
    width: 5% !important;
}
.width-10-per {
    width: 10% !important;
}
.width-15-per {
    width: 15% !important;
}
.width-20-per {
    width: 20% !important;
}
.width-25-per, .width-25-per-mobile{
    width: 25% !important;
}
.width-30-per {
    width: 30% !important;
}
.width-35-per {
    width: 35% !important;
}
.width-40-per {
    width: 40% !important;
}
.width-45-per {
    width: 45% !important;
}
.width-50-per {
    width: 50% !important;
}
.width-55-per {
    width: 55% !important;
}
.width-60-per {
    width: 60% !important;
}
.width-65-per {
    width: 65% !important;
}
.width-70-per {
    width: 70% !important;
}
.width-75-per {
    width: 75% !important;
}
.width-80-per {
    width: 80% !important;
}
.width-90-per {
    width: 90% !important;
}
.width-95-per {
    width: 95% !important;
}
.height-0 {
    height: 0 !important;
}
.height-25 {
    height: 25px !important;
}
.height-35 {
    height: 35px !important;
}
.height-39 {
    height: 39px !important;
}
.height-40 {
    height: 40px !important;
}
.height-50 {
    height: 50px !important;
}
.height-75 {
    height: 75px !important;
}
.height-100 {
    height: 100px !important;
}
.height-150 {
    height: 150px !important;
}
.height-200 {
    height: 200px !important;
}
.height-250 {
    height: 250px !important;
}
.height-300 {
    height: 300px !important;
}
.height-350 {
    height: 350px !important;
}
.height-400 {
    height: 400px !important;
}
.height-450 {
    height: 450px !important;
}
.height-500 {
    height: 500px !important;
}
.height-550 {
    height: 550px !important;
}
.height-600 {
    height: 600px !important;
}
.height-650 {
    height: 650px !important;
}
.height-700 {
    height: 700px !important;
}
.height-750 {
    height: 750px !important;
}
.height-800 {
    height: 800px !important;
}
.height-5-per {
    height: 5% !important;
}
.height-10-per {
    height: 10% !important;
}
.height-15-per {
    height: 15% !important;
}
.height-20-per {
    height: 20% !important;
}
.height-25-per {
    height: 25% !important;
}
.height-30-per {
    height: 30% !important;
}
.height-35-per {
    height: 35% !important;
}
.height-40-per {
    height: 40% !important;
}
.height-45-per {
    height: 45% !important;
}
.height-50-per {
    height: 50% !important;
}
.height-55-per {
    height: 55% !important;
}
.height-60-per {
    height: 60% !important;
}
.height-65-per {
    height: 65% !important;
}
.height-70-per {
    height: 70% !important;
}
.height-75-per {
    height: 75% !important;
}
.height-80-per {
    height: 80% !important;
}
.line-height-0 {
    line-height: 0 !important;
}
.line-height-1 {
    line-height: 1 !important;
}
.line-height-2 {
    line-height: 1.5 !important;
    line-height: 2 !important;
}
.rotate-45 {
    transform: rotate(45deg);
}
.rotate-45-inverse {
    transform: rotate(-45deg);
}
.rotate-90 {
    transform: rotate(45deg);
}
.rotate-90-inverse {
    transform: rotate(-45deg);
}
.rotate-180 {
    transform: rotate(45deg);
}
.rotate-180-inverse {
    transform: rotate(-45deg);
}
.spinner {
    display: inline-block;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1turn);
    }
}
.spinner-reverse {
    display: inline-block;
    animation: spin-reverse 1s linear infinite;
}
@keyframes spin-reverse {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-1turn);
    }
}
.bg-cover {
    background-size: cover !important;
}
.bg-repeat {
    background-repeat: repeat !important;
}
.bg-no-repeat {
    background-repeat: no-repeat !important;
}
.img-xl {
    width: 64px !important;
    height: 64px !important;
}
.img-lg {
    width: 44px !important;
    height: 44px !important;
}
.img-sm {
    width: 36px !important;
    height: 36px !important;
}
.img-xs {
    width: 32px !important;
    height: 32px !important;
}
[dir='rtl'] .position-right-0 {
    right: auto;
    left: 0;
}
[dir='rtl'] .position-left-0 {
    left: auto;
    right: 0;
}
[dir='rtl'] .no-edge-left {
    right: 0 !important;
    left: auto !important;
}
[dir='rtl'] .no-edge-right {
    left: 0 !important;
    right: auto !important;
}
[dir='rtl'] .list-style-icons {
    padding-left: auto;
    padding-right: 10px;
    margin-right: 0;
    margin-left: auto;
}
[dir='rtl'] .list-style-icons > li i {
    float: right;
    margin: 5px 0 0 12px;
}
[dir='rtl'] .border-left {
    border-right: 1px solid;
}
[dir='rtl'] .border-right {
    border-left: 1px solid;
}
[dir='rtl'] .border-left-2 {
    border-right-width: 2px !important;
}
[dir='rtl'] .border-right-2 {
    border-left-width: 2px !important;
}
[dir='rtl'] .border-left-3 {
    border-right-width: 3px !important;
}
[dir='rtl'] .border-right-3 {
    border-left-width: 3px !important;
}
[dir='rtl'] .no-border-left {
    border-right: 0 !important;
}
[dir='rtl'] .no-border-right {
    border-left: 0 !important;
}
[dir='rtl'] .no-border-top-left-radius {
    border-top-right-radius: 0 !important;
}
[dir='rtl'] .no-border-top-right-radius {
    border-top-left-radius: 0 !important;
}
[dir='rtl'] .no-border-bottom-left-radius {
    border-bottom-right-radius: 0 !important;
}
[dir='rtl'] .no-border-bottom-right-radius {
    border-bottom-left-radius: 0 !important;
}
.notification-bell-blink {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    opacity: 0.1;
    background-color: #ff4566;
    position: absolute;
    top: 6px;
    left: 16px;
    animation: up-right 2s infinite;
}
@keyframes up-right {
    0% {
        transform: scale(1);
        opacity: 0.1;
    }
    50% {
        transform: scale 1, 5;
        opacity: 1;
    }
    to {
        transform: scale(1);
        opacity: 0.1;
    }
}
.margin-left-3 {
    margin-left: 3px;
}
.cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    transition: opacity 0.2s;
}
.cd-horizontal-timeline:before {
    content: 'mobile';
    display: none;
}
.cd-horizontal-timeline.loaded {
    opacity: 1;
}
.cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
}
.cd-horizontal-timeline .timeline ol li,
.cd-horizontal-timeline .timeline ul li {
    list-style: none;
}
.cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
}
.cd-horizontal-timeline .events-wrapper:after,
.cd-horizontal-timeline .events-wrapper:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px;
}
.cd-horizontal-timeline .events-wrapper:before {
    left: 0;
}
.cd-horizontal-timeline .events-wrapper:after {
    right: 0;
}
.cd-horizontal-timeline .events {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    background: #dfdfdf;
    transition: transform 0.4s;
}
.cd-horizontal-timeline .filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #009da0;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.3s;
}
.cd-horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding-bottom: 15px;
    color: #383838;
    transform: translateZ(0);
}
.cd-horizontal-timeline .events a:after {
    content: '';
    position: absolute;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid #dfdede;
    background-color: #fff;
    transition: background-color 0.3s, border-color 0.3s;
}
.no-touch .cd-horizontal-timeline .events a:hover:after {
    background-color: #009da0;
    border-color: #009da0;
}
.cd-horizontal-timeline .events a.selected {
    pointer-events: none;
}
.cd-horizontal-timeline .events a.selected:after {
    background-color: #009da0;
    border-color: #009da0;
}
.cd-horizontal-timeline .events a.older-event:after {
    border-color: #009da0;
}
@media only screen and (min-width: 1100px) {
    .cd-horizontal-timeline {
        margin: 6em auto;
    }
    .cd-horizontal-timeline:before {
        content: 'desktop';
    }
}
.cd-timeline-navigation a {
    position: absolute;
    z-index: 1;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    transition: border-color 0.3s;
}
.cd-timeline-navigation a:after {
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 25%;
    top: 25%;
    bottom: auto;
    right: auto;
}
.cd-timeline-navigation a.prev {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
}
.cd-timeline-navigation a.next {
    right: 0;
}
.no-touch .cd-timeline-navigation a:hover {
    border-color: #009da0;
}
.cd-timeline-navigation a.inactive {
    cursor: not-allowed;
}
.cd-timeline-navigation a.inactive:after {
    background-position: 0 -16px;
}
.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf;
}
.cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    transition: height 0.4s;
}
.cd-horizontal-timeline .events-content li {
    list-style: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0 5%;
    opacity: 0;
}
.cd-horizontal-timeline .events-content li.selected {
    position: relative;
    z-index: 2;
    opacity: 1;
    transform: translateX(0);
}
.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
    animation-name: cd-enter-right;
}
.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
    animation-name: cd-enter-left;
}
.cd-horizontal-timeline .events-content li.leave-left,
.cd-horizontal-timeline .events-content li.leave-right {
    animation-direction: reverse;
}
.cd-horizontal-timeline .events-content li > * {
    margin: 0 auto;
    max-width: 800px;
}
.cd-horizontal-timeline .events-content em {
    display: block;
    font-style: italic;
    margin: 10px auto;
}
.cd-horizontal-timeline .events-content em:before {
    content: '- ';
}
.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
    line-height: 1.6;
}
@keyframes cd-enter-right {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes cd-enter-left {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
[dir='rtl'] .cd-horizontal-timeline .events-wrapper:before {
    right: 0;
    left: auto;
}
[dir='rtl'] .cd-horizontal-timeline .events-wrapper:after {
    left: 0;
    right: auto;
}
[dir='rtl'] .cd-horizontal-timeline .events,
[dir='rtl'] .cd-horizontal-timeline .filling-line {
    right: 0;
    left: auto;
}
[dir='rtl'] .cd-timeline-navigation a:after {
    right: 25%;
    left: auto;
}
[dir='rtl'] .cd-timeline-navigation a.prev {
    right: 0;
    left: auto;
}
[dir='rtl'] .cd-timeline-navigation a.next {
    left: 0;
    right: auto;
}
[dir='rtl'] .cd-horizontal-timeline .events-content li {
    right: 0;
    left: auto;
}
#cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
}
#cd-timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #d7e4ed;
}
@media only screen and (min-width: 1100px) {
    #cd-timeline {
        margin-top: 3em;
        margin-bottom: 3em;
    }
    #cd-timeline:before {
        left: 50%;
        margin-left: -2px;
    }
}
.cd-timeline-block {
    position: relative;
    margin: 2em 0;
}
.cd-timeline-block:after {
    display: block;
    clear: both;
    content: '';
}
.cd-timeline-block:first-child {
    margin-top: 0;
}
.cd-timeline-block:last-child {
    margin-bottom: 0;
}
@media only screen and (min-width: 1100px) {
    .cd-timeline-block {
        margin: 4em 0;
    }
    .cd-timeline-block:first-child {
        margin-top: 0;
    }
    .cd-timeline-block:last-child {
        margin-bottom: 0;
    }
}
.cd-timeline-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
        0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.cd-timeline-img img {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
}
.cd-timeline-img.cd-picture {
    background: #75ce66;
}
.cd-timeline-img.cd-movie {
    background: #7b9d6f;
}
.cd-timeline-img.cd-location {
    background: #f0ca45;
}
@media only screen and (min-width: 1100px) {
    .cd-timeline-img {
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        transform: translateZ(0);
        backface-visibility: hidden;
    }
    .cssanimations .cd-timeline-img.is-hidden {
        visibility: hidden;
    }
    .cssanimations .cd-timeline-img.bounce-in {
        visibility: visible;
        animation: cd-bounce-1 0.6s;
    }
}
@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    60% {
        opacity: 1;
        transform: scale(1.2);
    }
    to {
        transform: scale(1);
    }
}
.cd-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #fff;
    border-radius: 0.25em;
    padding: 1em;
    box-shadow: 0 3px 0 #d7e4ed;
}
.cd-timeline-content:after {
    display: block;
    clear: both;
    content: '';
}
.cd-timeline-content h2 {
    color: #383838;
}
.cd-timeline-content .cd-date,
.cd-timeline-content .cd-read-more {
    display: inline-block;
}
.cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
}
.cd-timeline-content .cd-read-more {
    float: right;
    padding: 0.8em 1em;
    background: #acb7c0;
    color: #fff;
    border-radius: 0.25em;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
    background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
    float: left;
    padding: 0.8em 0;
    opacity: 0.7;
}
.cd-timeline-content:before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 16px solid transparent;
    border-right-color: #fff;
}
@media only screen and (min-width: 1100px) {
    .cd-timeline-content {
        margin-left: 0;
        padding: 1.6em;
        width: 45%;
    }
    .cd-timeline-content:before {
        top: 24px;
        left: 100%;
        border-color: transparent transparent transparent #fff;
    }
    .cd-timeline-content .cd-read-more {
        float: left;
    }
    .cd-timeline-content .cd-date {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 6px;
    }
    .cd-timeline-block:nth-child(2n) .cd-timeline-content {
        float: right;
    }
    .cd-timeline-block:nth-child(2n) .cd-timeline-content:before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent #fff transparent transparent;
    }
    .cd-timeline-block:nth-child(2n) .cd-timeline-content .cd-read-more {
        float: right;
    }
    .cd-timeline-block:nth-child(2n) .cd-timeline-content .cd-date {
        left: auto;
        right: 122%;
        text-align: right;
    }
    .cssanimations .cd-timeline-content.is-hidden {
        visibility: hidden;
    }
    .cssanimations .cd-timeline-content.bounce-in {
        visibility: visible;
        animation: cd-bounce-2 0.6s;
    }
}
@media only screen and (min-width: 1100px) {
    .cssanimations
        .cd-timeline-block:nth-child(2n)
        .cd-timeline-content.bounce-in {
        animation: cd-bounce-2-inverse 0.6s;
    }
}
@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    60% {
        opacity: 1;
        transform: translateX(20px);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    60% {
        opacity: 1;
        transform: translateX(-20px);
    }
    to {
        transform: translateX(0);
    }
}
.timeline {
    position: relative;
    display: block;
    margin: 0;
    padding: 10px 0;
    list-style: none;
}
.timeline:after {
    clear: both;
}
.timeline:after,
.timeline:before {
    content: ' ';
    display: table;
}
.timeline > li {
    list-style: none;
}
.timeline > li:nth-child(2n) {
    float: left;
    clear: left;
}
.timeline > li:nth-child(odd) {
    float: right;
    clear: right;
}
.timeline .media {
    border-bottom: 1px solid #eee;
    font-size: 13px;
}
.timeline .media p {
    font-size: 13px;
}
.timeline .media:last-child {
    border-bottom: 0;
}
.timeline-line + .timeline-item {
    margin-top: -20px;
}
.timeline-group {
    display: block;
    position: relative;
    margin: 20px 0;
    text-align: center;
    float: none !important;
    z-index: 1;
}
.timeline-poster {
    margin-top: -20px;
}
.timeline-poster .btn-link {
    color: #a1aab0;
}
.timeline-poster .btn-link.active,
.timeline-poster .btn-link:active,
.timeline-poster .btn-link:focus,
.timeline-poster .btn-link:hover {
    color: #3e5771;
}
#timeline.timeline-center .timeline-line {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 4px;
    background-color: #ddd;
    transform: translate(-50%);
}
#timeline.timeline-center .timeline-item {
    position: relative;
    display: inline-block;
    width: 50%;
    padding: 0 50px 35px;
}
#timeline.timeline-center .timeline-item:nth-child(2n) {
    padding: 0 35px 50px 0;
}
#timeline.timeline-center .timeline-item:nth-child(2n):after {
    content: '';
    position: absolute;
    right: 19px;
    top: 10px;
    width: 0;
    height: 0;
    z-index: 1;
    right: 25px;
    top: 27px;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-left: 11px solid #fff;
}
#timeline.timeline-center .timeline-item:nth-child(2n):before {
    content: '';
    position: absolute;
    right: 24px;
    top: 27px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #ccc;
    z-index: 1;
}
#timeline.timeline-center .timeline-item:nth-child(2n) > .timeline-badge {
    right: -20px;
}
#timeline.timeline-center .timeline-item:nth-child(odd) {
    padding: 0 0 50px 35px;
}
#timeline.timeline-center .timeline-item:nth-child(odd):after {
    content: '';
    position: absolute;
    left: 19px;
    top: 10px;
    width: 0;
    height: 0;
    z-index: 1;
    left: 25px;
    top: 27px;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 11px solid #fff;
}
#timeline.timeline-center .timeline-item:nth-child(odd):before {
    content: '';
    position: absolute;
    left: 24px;
    top: 27px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    z-index: 1;
}
#timeline.timeline-center .timeline-item:nth-child(odd) > .timeline-badge {
    left: -20px;
}
#timeline.timeline-center .timeline-item > .timeline-badge {
    position: absolute;
    top: 12px;
    z-index: 1;
}
#timeline.timeline-center .timeline-item > .timeline-badge > span {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease;
}
#timeline.timeline-center .timeline-item > .timeline-badge > span i {
    color: #fff;
    font-size: 1.6rem;
    display: block;
    line-height: 40px;
    top: 0;
}
#timeline.timeline-center .timeline-item > .timeline-badge > a.active,
#timeline.timeline-center .timeline-item > .timeline-badge > span:active,
#timeline.timeline-center .timeline-item > .timeline-badge > span:focus,
#timeline.timeline-center .timeline-item > .timeline-badge > span:hover {
    transform: scale(1.1);
}
#timeline.timeline-center .timeline-item > .timeline-panel {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}
#timeline.timeline-center
    .timeline-item
    > .timeline-panel:hover
    .timeline-actions {
    display: block;
}
#timeline.timeline-right .timeline-line {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    width: 4px;
    background-color: #ddd;
    transform: translate(-50%);
}
#timeline.timeline-right .timeline-group {
    text-align: right;
}
#timeline.timeline-right .timeline-item {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0 43px 35px 0;
}
#timeline.timeline-right .timeline-item:after {
    content: '';
    position: absolute;
    right: 33px;
    top: 21px;
    width: 0;
    height: 0;
    z-index: 1;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-left: 11px solid #fff;
}
#timeline.timeline-right .timeline-item:before {
    content: '';
    position: absolute;
    right: 32px;
    top: 20px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #ccc;
    z-index: 1;
}
#timeline.timeline-right .timeline-item > .timeline-badge {
    right: -8px;
    position: absolute;
    top: 12px;
    z-index: 1;
}
#timeline.timeline-right .timeline-item > .timeline-badge > span {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease;
}
#timeline.timeline-right .timeline-item > .timeline-badge > span i {
    color: #fff;
    font-size: 1.6rem;
    display: block;
    line-height: 40px;
    top: 0;
}
#timeline.timeline-right .timeline-item > .timeline-badge > a.active,
#timeline.timeline-right .timeline-item > .timeline-badge > span:active,
#timeline.timeline-right .timeline-item > .timeline-badge > span:focus,
#timeline.timeline-right .timeline-item > .timeline-badge > span:hover {
    transform: scale(1.1);
}
#timeline.timeline-right .timeline-item > .timeline-panel {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}
#timeline.timeline-right
    .timeline-item
    > .timeline-panel:hover
    .timeline-actions {
    display: block;
}
#timeline.timeline-right .timeline-item.block:nth-child(2n):before {
    right: 8px;
    left: auto;
}
#timeline.timeline-right .timeline-item.block:nth-child(2n):after {
    right: 11px;
    left: auto;
}
#timeline.timeline-right .timeline-item.block:nth-child(odd):after {
    right: 8px;
    right: auto;
}
#timeline.timeline-left .timeline-line {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    width: 4px;
    background-color: #ddd;
    transform: translate(-50%);
}
#timeline.timeline-left .timeline-group {
    text-align: left;
}
#timeline.timeline-left .timeline-item {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0 0 35px 43px;
}
#timeline.timeline-left .timeline-item:after {
    content: '';
    position: absolute;
    left: 33px;
    top: 21px;
    width: 0;
    height: 0;
    z-index: 1;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 11px solid #fff;
}
#timeline.timeline-left .timeline-item:before {
    content: '';
    position: absolute;
    left: 32px;
    top: 20px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    z-index: 1;
}
#timeline.timeline-left .timeline-item > .timeline-badge {
    left: -8px;
    position: absolute;
    top: 12px;
    z-index: 1;
}
#timeline.timeline-left .timeline-item > .timeline-badge > span {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease;
}
#timeline.timeline-left .timeline-item > .timeline-badge > span i {
    color: #fff;
    font-size: 1.6rem;
    display: block;
    line-height: 40px;
    top: 0;
}
#timeline.timeline-left .timeline-item > .timeline-badge > a.active,
#timeline.timeline-left .timeline-item > .timeline-badge > span:active,
#timeline.timeline-left .timeline-item > .timeline-badge > span:focus,
#timeline.timeline-left .timeline-item > .timeline-badge > span:hover {
    transform: scale(1.1);
}
#timeline.timeline-left .timeline-item > .timeline-panel {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}
#timeline.timeline-left
    .timeline-item
    > .timeline-panel:hover
    .timeline-actions {
    display: block;
}
#timeline .timeline-item.block:nth-child(2n) {
    width: 100% !important;
    margin-top: 5px;
}
#timeline .timeline-item.block:nth-child(2n):after {
    left: 50%;
    right: auto;
    transform: translate(-50%);
    top: 8px;
    border: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #fff;
}
#timeline .timeline-item.block:nth-child(2n):before {
    left: 50%;
    right: auto;
    top: 7px;
    border: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ccc;
    transform: translate(-50%);
}
#timeline .timeline-item.block:nth-child(2n) > .timeline-badge {
    top: -28px;
    left: 50%;
    right: auto;
    transform: translate(-50%);
}
#timeline .timeline-item.block:nth-child(odd) {
    width: 100% !important;
    margin-top: 5px;
}
#timeline .timeline-item.block:nth-child(odd):after {
    left: 50%;
    right: auto;
    transform: translate(-50%);
    top: -10px;
    border: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #fff;
}
#timeline .timeline-item.block:nth-child(odd):before {
    left: 50%;
    right: auto;
    top: -11px;
    border: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ccc;
    transform: translate(-50%);
}
#timeline .timeline-item.block:nth-child(odd) > .timeline-badge {
    top: -28px;
    left: 50%;
    right: auto;
    transform: translate(-50%);
}
.timeline-actions {
    display: none;
}
.timeline-content {
    padding: 15px;
}
.timeline-footer {
    padding: 15px;
    border-top: 1px solid #eee;
    background-color: #fbfcfc;
    border-radius: 0 0 5px 5px;
}
.timeline-heading {
    padding: 15px;
    font-size: 20px;
}
.timeline-liveliness {
    padding: 15px;
    border-top: 1px solid #eee;
    background-color: #fbfcfc;
}
.timeline-heading + .timeline-content {
    padding-top: 0;
}
.timeline-date {
    font-size: 14px;
    color: #aaa;
}
.timeline-embed {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}
.timeline-embed .embed-element,
.timeline-embed embed,
.timeline-embed iframe,
.timeline-embed object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.timeline-img {
    display: block;
    padding: 5px 0;
}
.timeline-img.first {
    margin-right: -10px;
}
.timeline-img.middle {
    margin-right: -10px;
    margin-left: -10px;
}
.timeline-img.last {
    margin-left: -10px;
}
.timeline-resume {
    border-top: 1px solid #eee;
    background-color: #fbfcfc;
}
.timeline-avatar {
    margin-top: -2px;
    margin-right: 10px;
}
.timeline-title {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.42857;
    font-weight: 600;
    color: #3e5771;
    text-decoration: none;
}
.timeline-title > small {
    display: block;
    font-size: 12px;
    line-height: 1.5;
    color: #a1aab0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.wrapkit-content-rtl .timeline-avatar {
    margin-right: 0;
    margin-left: 10px;
}
@media (min-width: 992px) {
    .timeline-item:nth-child(2n) {
        padding: 0 35px 50px 0;
    }
    .timeline-item:nth-child(odd) {
        padding: 0 0 50px 35px;
    }
    .timeline-item.block {
        padding-right: 0 !important;
    }
}
@media (max-width: 991px) {
    .width-25-per-mobile{
        width:auto!important;
    }
    .timeline {
        padding-left: 15px;
    }
    .timeline-line {
        left: 15px;
    }
    .timeline-group {
        display: inline-block;
        margin-left: -22px;
    }
    .timeline-item {
        width: 100% !important;
        margin-top: 0 !important;
        padding-right: 10px;
    }
    .timeline-item:nth-child(2n):after {
        left: 19px;
        top: 10px;
        border: 0;
        transform: translate(0);
        left: 40px;
        top: 21px;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-right: 11px solid #fff;
    }
    .timeline-item:nth-child(2n):before {
        left: 39px;
        top: 20px;
        border: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid #ccc;
        transform: translate(0);
    }
    .timeline-item:nth-child(2n) > .timeline-badge {
        top: 12px;
        left: -20px;
        right: auto;
        transform: translate(0);
    }
    .timeline-item:nth-child(odd):after {
        left: 19px;
        top: 10px;
        border: 0;
        transform: translate(0);
        left: 40px;
        top: 21px;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-right: 11px solid #fff;
    }
    .timeline-item:nth-child(odd):before {
        left: 39px;
        top: 20px;
        border: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid #ccc;
        transform: translate(0);
    }
    .timeline-item:nth-child(odd) > .timeline-badge {
        top: 12px;
        left: -20px;
        right: auto;
        transform: translate(0);
    }
    .timeline-item.block:nth-child(2n) {
        padding-bottom: 0;
    }
    .timeline-item.block:nth-child(2n):after {
        left: 19px;
        top: 10px;
        border: 0;
        transform: translate(0);
        left: 20px;
        top: 11px;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-right: 11px solid #fff;
    }
    .timeline-item.block:nth-child(2n):before {
        left: 19px;
        top: 10px;
        border: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid #ccc;
        transform: translate(0);
    }
    .timeline-item.block:nth-child(2n) > .timeline-badge {
        top: 12px;
        left: -6px;
        right: auto;
        transform: translate(0);
    }
    .timeline-item.block:nth-child(odd) {
        padding-bottom: 0;
    }
    .timeline-item.block:nth-child(odd):after {
        left: 19px;
        top: 10px;
        border: 0;
        transform: translate(0);
        left: 20px;
        top: 11px;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-right: 11px solid #fff;
    }
    .timeline-item.block:nth-child(odd):before {
        left: 19px;
        top: 10px;
        border: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid #ccc;
        transform: translate(0);
    }
    .timeline-item.block:nth-child(odd) > .timeline-badge {
        top: 12px;
        left: -6px;
        right: auto;
        transform: translate(0);
    }
}
@media (max-width: 991px) {
    #timeline.timeline-center .timeline {
        padding: 0;
    }
    #timeline.timeline-center .timeline .card-block {
        text-align: left;
    }
    #timeline.timeline-center .timeline-line {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 4px;
        background-color: #ddd;
        transform: translate(-50%);
    }
    #timeline.timeline-center .timeline-group {
        display: block;
        margin-left: -6px;
        text-align: center;
    }
    #timeline.timeline-center .timeline-item {
        display: block;
        padding: 20px 0 !important;
    }
    #timeline.timeline-center .timeline-item:nth-child(2n) {
        float: none;
        clear: both;
    }
    #timeline.timeline-center .timeline-item:nth-child(2n):after,
    #timeline.timeline-center .timeline-item:nth-child(2n):before {
        display: none;
    }
    #timeline.timeline-center .timeline-item:nth-child(odd) {
        float: none;
        clear: both;
    }
    #timeline.timeline-center .timeline-item:nth-child(odd):after,
    #timeline.timeline-center .timeline-item:nth-child(odd):before {
        display: none;
    }
    #timeline.timeline-center .timeline-item > .timeline-badge {
        top: 0 !important;
        left: 47% !important;
    }
    #timeline.timeline-center .timeline-item.block:nth-child(2n) {
        float: none;
        clear: both;
    }
    #timeline.timeline-center .timeline-item.block:nth-child(2n):after,
    #timeline.timeline-center .timeline-item.block:nth-child(2n):before {
        display: none;
    }
    #timeline.timeline-center .timeline-item.block:nth-child(odd) {
        float: none;
        clear: both;
    }
    #timeline.timeline-center .timeline-item.block:nth-child(odd):after,
    #timeline.timeline-center .timeline-item.block:nth-child(odd):before,
    #timeline.timeline-center .timeline-item.timeline-poster > .timeline-badge {
        display: none;
    }
}
@media (max-width: 543px) {
    #timeline.timeline-center .timeline-item > .timeline-badge {
        top: 0 !important;
        left: 44% !important;
    }
}
.avatar{
    font-family:inherit!important;
    letter-spacing: 0.1px;
    font-size:0.7em;
}
.account-avatar{
    border:0!important;
    padding: 0em 0.5em;
    span{
            font-family:inherit!important;

    }
    :hover{
        opacity:0.9;
    }
}
[dir='rtl'] #cd-timeline:before {
    right: 18px;
    left: auto;
}
@media only screen and (min-width: 1100px) {
    [dir='rtl'] #cd-timeline:before {
        right: 50%;
        margin-right: -2px;
        left: auto;
        margin-left: auto;
    }
}
[dir='rtl'] .cd-timeline-img {
    right: 0;
    left: auto;
}
[dir='rtl'] .cd-timeline-img img {
    right: 50%;
    margin-right: -12px;
    left: auto;
    margin-left: auto;
}
@media only screen and (min-width: 1100px) {
    [dir='rtl'] .cd-timeline-img {
        right: 50%;
        margin-right: -30px;
        left: auto;
        margin-left: auto;
    }
}
[dir='rtl'] .cd-timeline-content {
    margin-right: 60px;
    margin-left: auto;
}
[dir='rtl'] .cd-timeline-content .cd-read-more {
    float: left;
}
[dir='rtl'] .cd-timeline-content .cd-date {
    float: right;
}
[dir='rtl'] .cd-timeline-content:before {
    left: 100%;
    right: auto;
}
@media only screen and (min-width: 1100px) {
    [dir='rtl'] .cd-timeline-content {
        margin-right: 0;
        margin-left: auto;
    }
    [dir='rtl'] .cd-timeline-content:before {
        right: 100%;
        left: auto;
    }
    [dir='rtl'] .cd-timeline-content .cd-read-more {
        float: right;
    }
    [dir='rtl'] .cd-timeline-content .cd-date {
        right: 122%;
        left: auto;
    }
    .cd-timeline-block:nth-child(2n) [dir='rtl'] .cd-timeline-content {
        float: left;
    }
    .cd-timeline-block:nth-child(2n) [dir='rtl'] .cd-timeline-content:before {
        right: auto;
        left: 100%;
    }
    .cd-timeline-block:nth-child(2n)
        [dir='rtl']
        .cd-timeline-content
        .cd-read-more {
        float: left;
    }
    .cd-timeline-block:nth-child(2n) [dir='rtl'] .cd-timeline-content .cd-date {
        right: auto;
        left: 122%;
        text-align: left;
    }
}
[dir='rtl'] .timeline > li:nth-child(2n) {
    float: right;
    clear: right;
}
[dir='rtl'] .timeline > li:nth-child(odd) {
    float: left;
    clear: left;
}
[dir='rtl'] #timeline.timeline-center .timeline-line {
    left: auto;
    right: 50%;
}
[dir='rtl'] #timeline.timeline-center .timeline-item:nth-child(2n) {
    padding: 0 0 50px 35px;
}
[dir='rtl'] #timeline.timeline-center .timeline-item:nth-child(2n):after {
    left: 25px;
    right: auto;
    border-left: 0;
    border-right: 11px solid #fff;
}
[dir='rtl'] #timeline.timeline-center .timeline-item:nth-child(2n):before {
    left: 24px;
    right: auto;
    border-left: 0;
    border-right: 12px solid #ccc;
}
[dir='rtl']
    #timeline.timeline-center
    .timeline-item:nth-child(2n)
    > .timeline-badge {
    left: -20px;
    right: auto;
}
[dir='rtl'] #timeline.timeline-center .timeline-item:nth-child(odd) {
    padding: 0 35px 50px 0;
}
[dir='rtl'] #timeline.timeline-center .timeline-item:nth-child(odd):after {
    right: 19px;
    right: 25px;
    left: auto;
    border-left: 11px solid #fff;
    border-right: 0;
}
[dir='rtl'] #timeline.timeline-center .timeline-item:nth-child(odd):before {
    right: 24px;
    border-left: 12px solid #ccc;
    left: auto;
    border-right: 0;
}
[dir='rtl']
    #timeline.timeline-center
    .timeline-item:nth-child(odd)
    > .timeline-badge {
    right: -20px;
    left: auto;
}
[dir='rtl'] #timeline.timeline-center .timeline-item > .timeline-badge {
    right: 12px;
    left: auto;
}
[dir='rtl'] #timeline.timeline-right .timeline-line {
    left: 12px;
    right: auto;
}
[dir='rtl'] #timeline.timeline-right .timeline-group {
    text-align: left;
}
[dir='rtl'] #timeline.timeline-right .timeline-item {
    padding: 0 0 35px 43px;
}
[dir='rtl'] #timeline.timeline-right .timeline-item:after {
    left: 33px;
    border-right: 11px solid #fff;
    right: auto;
    border-left: 0;
}
[dir='rtl'] #timeline.timeline-right .timeline-item:before {
    left: 32px;
    border-right: 12px solid #ccc;
    right: auto;
    border-left: 0;
}
[dir='rtl'] #timeline.timeline-right .timeline-item > .timeline-badge {
    left: -8px;
    right: auto;
}
[dir='rtl'] #timeline.timeline-right .timeline-item.block:nth-child(2n):before {
    left: 8px;
    right: auto;
}
[dir='rtl'] #timeline.timeline-right .timeline-item.block:nth-child(2n):after {
    left: 11px;
    right: auto;
}
[dir='rtl'] #timeline.timeline-right .timeline-item.block:nth-child(odd):after {
    left: 8px;
    right: auto;
}
[dir='rtl'] #timeline.timeline-left .timeline-line {
    right: 12px;
    left: auto;
}
[dir='rtl'] #timeline.timeline-left .timeline-group {
    text-align: right;
}
[dir='rtl'] #timeline.timeline-left .timeline-item {
    padding: 0 43px 35px 0;
}
[dir='rtl'] #timeline.timeline-left .timeline-item:after {
    right: 33px;
    left: 0;
    border-right: 11px solid #fff;
    border-left: 0;
}
[dir='rtl'] #timeline.timeline-left .timeline-item:before {
    right: 32px;
    border-left: 12px solid #ccc;
    left: auto;
    border-right: 0;
}
[dir='rtl'] #timeline.timeline-left .timeline-item > .timeline-badge {
    right: -8px;
    left: auto;
}
[dir='rtl'] #timeline .timeline-item.block:nth-child(2n):after,
[dir='rtl'] #timeline .timeline-item.block:nth-child(2n):before,
[dir='rtl'] #timeline .timeline-item.block:nth-child(2n) > .timeline-badge,
[dir='rtl'] #timeline .timeline-item.block:nth-child(odd):after,
[dir='rtl'] #timeline .timeline-item.block:nth-child(odd):before,
[dir='rtl'] #timeline .timeline-item.block:nth-child(odd) > .timeline-badge {
    right: 50%;
    left: auto;
}
[dir='rtl'] .timeline-embed .embed-element,
[dir='rtl'] .timeline-embed embed,
[dir='rtl'] .timeline-embed iframe,
[dir='rtl'] .timeline-embed object {
    right: 0;
    left: auto;
}
[dir='rtl'] .timeline-img.first {
    margin-left: -10px;
    margin-right: 0;
}
[dir='rtl'] .timeline-img.last {
    margin-right: -10px;
    margin-left: 0;
}
[dir='rtl'] .timeline-avatar {
    margin-left: 10px;
    margin-right: 0;
}
[dir='rtl'] .wrapkit-content-rtl .timeline-avatar {
    margin-left: 0;
    margin-right: 10px;
}
@media (min-width: 992px) {
    [dir='rtl'] .timeline-item:nth-child(2n) {
        padding: 0 0 50px 35px;
    }
    [dir='rtl'] .timeline-item:nth-child(odd) {
        padding: 0 35px 50px 0;
    }
    [dir='rtl'] .timeline-item.block {
        padding-left: 0 !important;
        padding-right: auto !important;
    }
}
@media (max-width: 991px) {
    [dir='rtl'] .timeline {
        padding-right: 15px;
        padding-left: 0;
    }
    [dir='rtl'] .timeline-line {
        right: 15px;
        left: 0;
    }
    [dir='rtl'] .timeline-group {
        margin-right: -22px;
        margin-left: 0;
    }
    [dir='rtl'] .timeline-item {
        padding-left: 10px;
        padding-right: 0;
    }
    [dir='rtl'] .timeline-item:nth-child(2n):after {
        right: 19px;
        right: 40px;
        left: 0;
        border-left: 11px solid #fff;
        border-right: 0;
    }
    [dir='rtl'] .timeline-item:nth-child(2n):before {
        right: 39px;
        border-left: 12px solid #ccc;
        left: 0;
        border-right: 0;
    }
    [dir='rtl'] .timeline-item:nth-child(2n) > .timeline-badge {
        right: -20px;
        left: auto;
    }
    [dir='rtl'] .timeline-item:nth-child(odd):after {
        right: 19px;
        right: 40px;
        border-left: 11px solid #fff;
        left: 0;
        border-right: 0 solid #fff;
    }
    [dir='rtl'] .timeline-item:nth-child(odd):before {
        right: 39px;
        border-left: 12px solid #ccc;
        left: 0;
        border-right: 0 solid #ccc;
    }
    [dir='rtl'] .timeline-item:nth-child(odd) > .timeline-badge {
        right: -20px;
        left: auto;
    }
    [dir='rtl'] .timeline-item.block:nth-child(2n) {
        padding-bottom: 0;
    }
    [dir='rtl'] .timeline-item.block:nth-child(2n):after {
        right: 19px;
        right: 20px;
        border-left: 11px solid #fff;
        left: 0;
        border-right: 0 solid #fff;
    }
    [dir='rtl'] .timeline-item.block:nth-child(2n):before {
        right: 19px;
        border-left: 12px solid #ccc;
        left: 0;
        border-right: 0 solid #ccc;
    }
    [dir='rtl'] .timeline-item.block:nth-child(2n) > .timeline-badge {
        right: -6px;
        left: auto;
    }
    [dir='rtl'] .timeline-item.block:nth-child(odd) {
        padding-bottom: 0;
    }
    [dir='rtl'] .timeline-item.block:nth-child(odd):after {
        right: 19px;
        right: 20px;
        border-left: 11px solid #fff;
        left: 0;
        border-right: 0 solid #fff;
    }
    [dir='rtl'] .timeline-item.block:nth-child(odd):before {
        right: 19px;
        border-left: 12px solid #ccc;
        left: 0;
        border-right: 0 solid #ccc;
    }
    [dir='rtl'] .timeline-item.block:nth-child(odd) > .timeline-badge {
        right: -6px;
        left: auto;
    }
}
@media (max-width: 991px) {
    [dir='rtl'] #timeline.timeline-center .timeline .card-block {
        text-align: right;
    }
    [dir='rtl'] #timeline.timeline-center .timeline-line {
        right: 50%;
        left: auto;
    }
    [dir='rtl'] #timeline.timeline-center .timeline-group {
        margin-right: -6px;
        margin-left: auto;
    }
    [dir='rtl'] #timeline.timeline-center .timeline-item > .timeline-badge {
        right: 47% !important;
        left: auto !important;
    }
}
@media (max-width: 543px) {
    [dir='rtl'] .timeline-item > .timeline-badge {
        right: 44% !important;
        left: auto !important;
    }
}
.btn-social,
.btn-social-icon {
    position: relative;
    padding-left: 3.25rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
}
.btn-social-icon > :first-child,
.btn-social > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2.5rem;
    line-height: 2.7rem;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.btn-group-lg > .btn-social.btn,
.btn-group-lg > .btn.btn-social-icon,
.btn-lg.btn-social-icon,
.btn-social.btn-lg {
    padding-left: 3.2rem;
}
.btn-group-lg > .btn-social.btn > :first-child,
.btn-group-lg > .btn.btn-social-icon > :first-child,
.btn-lg.btn-social-icon > :first-child,
.btn-social.btn-lg > :first-child {
    line-height: 2.2rem;
    width: 2.2rem;
    font-size: 1.8em;
}
.btn-group-sm > .btn-social.btn,
.btn-group-sm > .btn.btn-social-icon,
.btn-sm.btn-social-icon,
.btn-social.btn-sm {
    padding-left: 2.55rem;
}
.btn-group-sm > .btn-social.btn > :first-child,
.btn-group-sm > .btn.btn-social-icon > :first-child,
.btn-sm.btn-social-icon > :first-child,
.btn-social.btn-sm > :first-child {
    line-height: 1.8rem;
    width: 1.8rem;
    font-size: 1.4em;
}
.btn-social.btn-xs,
.btn-xs.btn-social-icon {
    padding-left: 3.15rem;
}
.btn-social.btn-xs > :first-child,
.btn-xs.btn-social-icon > :first-child {
    line-height: 2.4rem;
    width: 2.4rem;
    font-size: 1.2em;
}
.btn-social-icon {
    height: 2.75rem;
    width: 2.75rem;
    padding: 0;
}
.btn-social-icon > :first-child {
    border: none;
    text-align: center;
    width: 100% !important;
}
.btn-group-lg > .btn-social-icon.btn,
.btn-social-icon.btn-lg {
    height: 2.2rem;
    width: 2.2rem;
    padding-left: 0;
    padding-right: 0;
}
.btn-group-sm > .btn-social-icon.btn,
.btn-social-icon.btn-sm {
    height: 3.8rem;
    width: 3.8rem;
    padding-left: 0;
    padding-right: 0;
}
.btn-social-icon.btn-xs {
    height: 4.4rem;
    width: 4.4rem;
    padding-left: 0;
    padding-right: 0;
}
.btn-adn {
    color: #fff !important;
    background-color: #d87a68;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-adn.active,
.btn-adn.focus,
.btn-adn:active,
.btn-adn:focus,
.btn-adn:hover,
.open > .btn-adn.dropdown-toggle {
    color: #fff;
    background-color: #ce563f !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-adn.active.focus,
.btn-adn.active:focus,
.btn-adn.active:hover,
.btn-adn:active.focus,
.btn-adn:active:focus,
.btn-adn:active:hover,
.open > .btn-adn.dropdown-toggle.focus,
.open > .btn-adn.dropdown-toggle:focus,
.open > .btn-adn.dropdown-toggle:hover {
    color: #fff;
    background-color: #b94630 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-adn.active,
.btn-adn:active,
.open > .btn-adn.dropdown-toggle {
    background-image: none;
}
.btn-adn.disabled.focus,
.btn-adn.disabled:focus,
.btn-adn.disabled:hover,
.btn-adn[disabled].focus,
.btn-adn[disabled]:focus,
.btn-adn[disabled]:hover,
fieldset[disabled] .btn-adn.focus,
fieldset[disabled] .btn-adn:focus,
fieldset[disabled] .btn-adn:hover {
    background-color: #d87a68;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-adn .badge {
    color: #d87a68;
    background-color: #fff;
}
.btn-bitbucket {
    color: #fff !important;
    background-color: #205081;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-bitbucket.active,
.btn-bitbucket.focus,
.btn-bitbucket:active,
.btn-bitbucket:focus,
.btn-bitbucket:hover,
.open > .btn-bitbucket.dropdown-toggle {
    color: #fff;
    background-color: #163758 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-bitbucket.active.focus,
.btn-bitbucket.active:focus,
.btn-bitbucket.active:hover,
.btn-bitbucket:active.focus,
.btn-bitbucket:active:focus,
.btn-bitbucket:active:hover,
.open > .btn-bitbucket.dropdown-toggle.focus,
.open > .btn-bitbucket.dropdown-toggle:focus,
.open > .btn-bitbucket.dropdown-toggle:hover {
    color: #fff;
    background-color: #0f253c !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-bitbucket.active,
.btn-bitbucket:active,
.open > .btn-bitbucket.dropdown-toggle {
    background-image: none;
}
.btn-bitbucket.disabled.focus,
.btn-bitbucket.disabled:focus,
.btn-bitbucket.disabled:hover,
.btn-bitbucket[disabled].focus,
.btn-bitbucket[disabled]:focus,
.btn-bitbucket[disabled]:hover,
fieldset[disabled] .btn-bitbucket.focus,
fieldset[disabled] .btn-bitbucket:focus,
fieldset[disabled] .btn-bitbucket:hover {
    background-color: #205081;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-bitbucket .badge {
    color: #205081;
    background-color: #fff;
}
.btn-dropbox {
    color: #fff !important;
    background-color: #1087dd;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-dropbox.active,
.btn-dropbox.focus,
.btn-dropbox:active,
.btn-dropbox:focus,
.btn-dropbox:hover,
.open > .btn-dropbox.dropdown-toggle {
    color: #fff;
    background-color: #0d6aad !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-dropbox.active.focus,
.btn-dropbox.active:focus,
.btn-dropbox.active:hover,
.btn-dropbox:active.focus,
.btn-dropbox:active:focus,
.btn-dropbox:active:hover,
.open > .btn-dropbox.dropdown-toggle.focus,
.open > .btn-dropbox.dropdown-toggle:focus,
.open > .btn-dropbox.dropdown-toggle:hover {
    color: #fff;
    background-color: #0a568c !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-dropbox.active,
.btn-dropbox:active,
.open > .btn-dropbox.dropdown-toggle {
    background-image: none;
}
.btn-dropbox.disabled.focus,
.btn-dropbox.disabled:focus,
.btn-dropbox.disabled:hover,
.btn-dropbox[disabled].focus,
.btn-dropbox[disabled]:focus,
.btn-dropbox[disabled]:hover,
fieldset[disabled] .btn-dropbox.focus,
fieldset[disabled] .btn-dropbox:focus,
fieldset[disabled] .btn-dropbox:hover {
    background-color: #1087dd;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-dropbox .badge {
    color: #1087dd;
    background-color: #fff;
}
.btn-facebook {
    color: #fff !important;
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook.active,
.btn-facebook.focus,
.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover,
.open > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook.active.focus,
.btn-facebook.active:focus,
.btn-facebook.active:hover,
.btn-facebook:active.focus,
.btn-facebook:active:focus,
.btn-facebook:active:hover,
.open > .btn-facebook.dropdown-toggle.focus,
.open > .btn-facebook.dropdown-toggle:focus,
.open > .btn-facebook.dropdown-toggle:hover {
    color: #fff;
    background-color: #23345a !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook.active,
.btn-facebook:active,
.open > .btn-facebook.dropdown-toggle {
    background-image: none;
}
.btn-facebook.disabled.focus,
.btn-facebook.disabled:focus,
.btn-facebook.disabled:hover,
.btn-facebook[disabled].focus,
.btn-facebook[disabled]:focus,
.btn-facebook[disabled]:hover,
fieldset[disabled] .btn-facebook.focus,
fieldset[disabled] .btn-facebook:focus,
fieldset[disabled] .btn-facebook:hover {
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-facebook .badge {
    color: #3b5998;
    background-color: #fff;
}
.btn-flickr {
    color: #fff !important;
    background-color: #ff0084;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-flickr.active,
.btn-flickr.focus,
.btn-flickr:active,
.btn-flickr:focus,
.btn-flickr:hover,
.open > .btn-flickr.dropdown-toggle {
    color: #fff;
    background-color: #cc006a !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-flickr.active.focus,
.btn-flickr.active:focus,
.btn-flickr.active:hover,
.btn-flickr:active.focus,
.btn-flickr:active:focus,
.btn-flickr:active:hover,
.open > .btn-flickr.dropdown-toggle.focus,
.open > .btn-flickr.dropdown-toggle:focus,
.open > .btn-flickr.dropdown-toggle:hover {
    color: #fff;
    background-color: #a80057 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-flickr.active,
.btn-flickr:active,
.open > .btn-flickr.dropdown-toggle {
    background-image: none;
}
.btn-flickr.disabled.focus,
.btn-flickr.disabled:focus,
.btn-flickr.disabled:hover,
.btn-flickr[disabled].focus,
.btn-flickr[disabled]:focus,
.btn-flickr[disabled]:hover,
fieldset[disabled] .btn-flickr.focus,
fieldset[disabled] .btn-flickr:focus,
fieldset[disabled] .btn-flickr:hover {
    background-color: #ff0084;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-flickr .badge {
    color: #ff0084;
    background-color: #fff;
}
.btn-foursquare {
    color: #fff !important;
    background-color: #f94877;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-foursquare.active,
.btn-foursquare.focus,
.btn-foursquare:active,
.btn-foursquare:focus,
.btn-foursquare:hover,
.open > .btn-foursquare.dropdown-toggle {
    color: #fff;
    background-color: #f71752 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-foursquare.active.focus,
.btn-foursquare.active:focus,
.btn-foursquare.active:hover,
.btn-foursquare:active.focus,
.btn-foursquare:active:focus,
.btn-foursquare:active:hover,
.open > .btn-foursquare.dropdown-toggle.focus,
.open > .btn-foursquare.dropdown-toggle:focus,
.open > .btn-foursquare.dropdown-toggle:hover {
    color: #fff;
    background-color: #e30742 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-foursquare.active,
.btn-foursquare:active,
.open > .btn-foursquare.dropdown-toggle {
    background-image: none;
}
.btn-foursquare.disabled.focus,
.btn-foursquare.disabled:focus,
.btn-foursquare.disabled:hover,
.btn-foursquare[disabled].focus,
.btn-foursquare[disabled]:focus,
.btn-foursquare[disabled]:hover,
fieldset[disabled] .btn-foursquare.focus,
fieldset[disabled] .btn-foursquare:focus,
fieldset[disabled] .btn-foursquare:hover {
    background-color: #f94877;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-foursquare .badge {
    color: #f94877;
    background-color: #fff;
}
.btn-github {
    color: #fff !important;
    background-color: #444;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-github.active,
.btn-github.focus,
.btn-github:active,
.btn-github:focus,
.btn-github:hover,
.open > .btn-github.dropdown-toggle {
    color: #fff;
    background-color: #2b2b2b !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-github.active.focus,
.btn-github.active:focus,
.btn-github.active:hover,
.btn-github:active.focus,
.btn-github:active:focus,
.btn-github:active:hover,
.open > .btn-github.dropdown-toggle.focus,
.open > .btn-github.dropdown-toggle:focus,
.open > .btn-github.dropdown-toggle:hover {
    color: #fff;
    background-color: #191919 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-github.active,
.btn-github:active,
.open > .btn-github.dropdown-toggle {
    background-image: none;
}
.btn-github.disabled.focus,
.btn-github.disabled:focus,
.btn-github.disabled:hover,
.btn-github[disabled].focus,
.btn-github[disabled]:focus,
.btn-github[disabled]:hover,
fieldset[disabled] .btn-github.focus,
fieldset[disabled] .btn-github:focus,
fieldset[disabled] .btn-github:hover {
    background-color: #444;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-github .badge {
    color: #444;
    background-color: #fff;
}
.btn-google {
    color: #fff !important;
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-google.active,
.btn-google.focus,
.btn-google:active,
.btn-google:focus,
.btn-google:hover,
.open > .btn-google.dropdown-toggle {
    color: #fff;
    background-color: #c23321 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-google.active.focus,
.btn-google.active:focus,
.btn-google.active:hover,
.btn-google:active.focus,
.btn-google:active:focus,
.btn-google:active:hover,
.open > .btn-google.dropdown-toggle.focus,
.open > .btn-google.dropdown-toggle:focus,
.open > .btn-google.dropdown-toggle:hover {
    color: #fff;
    background-color: #a32b1c !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-google.active,
.btn-google:active,
.open > .btn-google.dropdown-toggle {
    background-image: none;
}
.btn-google.disabled.focus,
.btn-google.disabled:focus,
.btn-google.disabled:hover,
.btn-google[disabled].focus,
.btn-google[disabled]:focus,
.btn-google[disabled]:hover,
fieldset[disabled] .btn-google.focus,
fieldset[disabled] .btn-google:focus,
fieldset[disabled] .btn-google:hover {
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-google .badge {
    color: #dd4b39;
    background-color: #fff;
}
.btn-instagram {
    color: #fff !important;
    background-color: #3f729b;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-instagram.active,
.btn-instagram.focus,
.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover,
.open > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #305777 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-instagram.active.focus,
.btn-instagram.active:focus,
.btn-instagram.active:hover,
.btn-instagram:active.focus,
.btn-instagram:active:focus,
.btn-instagram:active:hover,
.open > .btn-instagram.dropdown-toggle.focus,
.open > .btn-instagram.dropdown-toggle:focus,
.open > .btn-instagram.dropdown-toggle:hover {
    color: #fff;
    background-color: #26455d !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-instagram.active,
.btn-instagram:active,
.open > .btn-instagram.dropdown-toggle {
    background-image: none;
}
.btn-instagram.disabled.focus,
.btn-instagram.disabled:focus,
.btn-instagram.disabled:hover,
.btn-instagram[disabled].focus,
.btn-instagram[disabled]:focus,
.btn-instagram[disabled]:hover,
fieldset[disabled] .btn-instagram.focus,
fieldset[disabled] .btn-instagram:focus,
fieldset[disabled] .btn-instagram:hover {
    background-color: #3f729b;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-instagram .badge {
    color: #3f729b;
    background-color: #fff;
}
.btn-linkedin {
    color: #fff !important;
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin.active,
.btn-linkedin.focus,
.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover,
.open > .btn-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #005983 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin.active.focus,
.btn-linkedin.active:focus,
.btn-linkedin.active:hover,
.btn-linkedin:active.focus,
.btn-linkedin:active:focus,
.btn-linkedin:active:hover,
.open > .btn-linkedin.dropdown-toggle.focus,
.open > .btn-linkedin.dropdown-toggle:focus,
.open > .btn-linkedin.dropdown-toggle:hover {
    color: #fff;
    background-color: #00405f !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin.active,
.btn-linkedin:active,
.open > .btn-linkedin.dropdown-toggle {
    background-image: none;
}
.btn-linkedin.disabled.focus,
.btn-linkedin.disabled:focus,
.btn-linkedin.disabled:hover,
.btn-linkedin[disabled].focus,
.btn-linkedin[disabled]:focus,
.btn-linkedin[disabled]:hover,
fieldset[disabled] .btn-linkedin.focus,
fieldset[disabled] .btn-linkedin:focus,
fieldset[disabled] .btn-linkedin:hover {
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin .badge {
    color: #007bb6;
    background-color: #fff;
}
.btn-microsoft {
    color: #fff !important;
    background-color: #2672ec;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-microsoft.active,
.btn-microsoft.focus,
.btn-microsoft:active,
.btn-microsoft:focus,
.btn-microsoft:hover,
.open > .btn-microsoft.dropdown-toggle {
    color: #fff;
    background-color: #125acd !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-microsoft.active.focus,
.btn-microsoft.active:focus,
.btn-microsoft.active:hover,
.btn-microsoft:active.focus,
.btn-microsoft:active:focus,
.btn-microsoft:active:hover,
.open > .btn-microsoft.dropdown-toggle.focus,
.open > .btn-microsoft.dropdown-toggle:focus,
.open > .btn-microsoft.dropdown-toggle:hover {
    color: #fff;
    background-color: #0f4bac !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-microsoft.active,
.btn-microsoft:active,
.open > .btn-microsoft.dropdown-toggle {
    background-image: none;
}
.btn-microsoft.disabled.focus,
.btn-microsoft.disabled:focus,
.btn-microsoft.disabled:hover,
.btn-microsoft[disabled].focus,
.btn-microsoft[disabled]:focus,
.btn-microsoft[disabled]:hover,
fieldset[disabled] .btn-microsoft.focus,
fieldset[disabled] .btn-microsoft:focus,
fieldset[disabled] .btn-microsoft:hover {
    background-color: #2672ec;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-microsoft .badge {
    color: #2672ec;
    background-color: #fff;
}
.btn-odnoklassniki {
    color: #fff !important;
    background-color: #f4731c;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-odnoklassniki.active,
.btn-odnoklassniki.focus,
.btn-odnoklassniki:active,
.btn-odnoklassniki:focus,
.btn-odnoklassniki:hover,
.open > .btn-odnoklassniki.dropdown-toggle {
    color: #fff;
    background-color: #d35b0a !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-odnoklassniki.active.focus,
.btn-odnoklassniki.active:focus,
.btn-odnoklassniki.active:hover,
.btn-odnoklassniki:active.focus,
.btn-odnoklassniki:active:focus,
.btn-odnoklassniki:active:hover,
.open > .btn-odnoklassniki.dropdown-toggle.focus,
.open > .btn-odnoklassniki.dropdown-toggle:focus,
.open > .btn-odnoklassniki.dropdown-toggle:hover {
    color: #fff;
    background-color: #b14c09 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-odnoklassniki.active,
.btn-odnoklassniki:active,
.open > .btn-odnoklassniki.dropdown-toggle {
    background-image: none;
}
.btn-odnoklassniki.disabled.focus,
.btn-odnoklassniki.disabled:focus,
.btn-odnoklassniki.disabled:hover,
.btn-odnoklassniki[disabled].focus,
.btn-odnoklassniki[disabled]:focus,
.btn-odnoklassniki[disabled]:hover,
fieldset[disabled] .btn-odnoklassniki.focus,
fieldset[disabled] .btn-odnoklassniki:focus,
fieldset[disabled] .btn-odnoklassniki:hover {
    background-color: #f4731c;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-odnoklassniki .badge {
    color: #f4731c;
    background-color: #fff;
}
.btn-openid {
    color: #fff !important;
    background-color: #f7931e;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-openid.active,
.btn-openid.focus,
.btn-openid:active,
.btn-openid:focus,
.btn-openid:hover,
.open > .btn-openid.dropdown-toggle {
    color: #fff;
    background-color: #da7908 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-openid.active.focus,
.btn-openid.active:focus,
.btn-openid.active:hover,
.btn-openid:active.focus,
.btn-openid:active:focus,
.btn-openid:active:hover,
.open > .btn-openid.dropdown-toggle.focus,
.open > .btn-openid.dropdown-toggle:focus,
.open > .btn-openid.dropdown-toggle:hover {
    color: #fff;
    background-color: #b86607 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-openid.active,
.btn-openid:active,
.open > .btn-openid.dropdown-toggle {
    background-image: none;
}
.btn-openid.disabled.focus,
.btn-openid.disabled:focus,
.btn-openid.disabled:hover,
.btn-openid[disabled].focus,
.btn-openid[disabled]:focus,
.btn-openid[disabled]:hover,
fieldset[disabled] .btn-openid.focus,
fieldset[disabled] .btn-openid:focus,
fieldset[disabled] .btn-openid:hover {
    background-color: #f7931e;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-openid .badge {
    color: #f7931e;
    background-color: #fff;
}
.btn-pinterest {
    color: #fff !important;
    background-color: #cb2027;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-pinterest.active,
.btn-pinterest.focus,
.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover,
.open > .btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #9f191f !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-pinterest.active.focus,
.btn-pinterest.active:focus,
.btn-pinterest.active:hover,
.btn-pinterest:active.focus,
.btn-pinterest:active:focus,
.btn-pinterest:active:hover,
.open > .btn-pinterest.dropdown-toggle.focus,
.open > .btn-pinterest.dropdown-toggle:focus,
.open > .btn-pinterest.dropdown-toggle:hover {
    color: #fff;
    background-color: #801419 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-pinterest.active,
.btn-pinterest:active,
.open > .btn-pinterest.dropdown-toggle {
    background-image: none;
}
.btn-pinterest.disabled.focus,
.btn-pinterest.disabled:focus,
.btn-pinterest.disabled:hover,
.btn-pinterest[disabled].focus,
.btn-pinterest[disabled]:focus,
.btn-pinterest[disabled]:hover,
fieldset[disabled] .btn-pinterest.focus,
fieldset[disabled] .btn-pinterest:focus,
fieldset[disabled] .btn-pinterest:hover {
    background-color: #cb2027;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-pinterest .badge {
    color: #cb2027;
    background-color: #fff;
}
.btn-reddit {
    color: #000 !important;
    background-color: #eff7ff;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-reddit.active,
.btn-reddit.focus,
.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover,
.open > .btn-reddit.dropdown-toggle {
    color: #000;
    background-color: #bcdeff !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-reddit.active.focus,
.btn-reddit.active:focus,
.btn-reddit.active:hover,
.btn-reddit:active.focus,
.btn-reddit:active:focus,
.btn-reddit:active:hover,
.open > .btn-reddit.dropdown-toggle.focus,
.open > .btn-reddit.dropdown-toggle:focus,
.open > .btn-reddit.dropdown-toggle:hover {
    color: #000;
    background-color: #98ccff !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-reddit.active,
.btn-reddit:active,
.open > .btn-reddit.dropdown-toggle {
    background-image: none;
}
.btn-reddit.disabled.focus,
.btn-reddit.disabled:focus,
.btn-reddit.disabled:hover,
.btn-reddit[disabled].focus,
.btn-reddit[disabled]:focus,
.btn-reddit[disabled]:hover,
fieldset[disabled] .btn-reddit.focus,
fieldset[disabled] .btn-reddit:focus,
fieldset[disabled] .btn-reddit:hover {
    background-color: #eff7ff;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-reddit .badge {
    color: #eff7ff;
    background-color: #000;
}
.btn-soundcloud {
    color: #fff !important;
    background-color: #f50;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-soundcloud.active,
.btn-soundcloud.focus,
.btn-soundcloud:active,
.btn-soundcloud:focus,
.btn-soundcloud:hover,
.open > .btn-soundcloud.dropdown-toggle {
    color: #fff;
    background-color: #c40 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-soundcloud.active.focus,
.btn-soundcloud.active:focus,
.btn-soundcloud.active:hover,
.btn-soundcloud:active.focus,
.btn-soundcloud:active:focus,
.btn-soundcloud:active:hover,
.open > .btn-soundcloud.dropdown-toggle.focus,
.open > .btn-soundcloud.dropdown-toggle:focus,
.open > .btn-soundcloud.dropdown-toggle:hover {
    color: #fff;
    background-color: #a83800 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-soundcloud.active,
.btn-soundcloud:active,
.open > .btn-soundcloud.dropdown-toggle {
    background-image: none;
}
.btn-soundcloud.disabled.focus,
.btn-soundcloud.disabled:focus,
.btn-soundcloud.disabled:hover,
.btn-soundcloud[disabled].focus,
.btn-soundcloud[disabled]:focus,
.btn-soundcloud[disabled]:hover,
fieldset[disabled] .btn-soundcloud.focus,
fieldset[disabled] .btn-soundcloud:focus,
fieldset[disabled] .btn-soundcloud:hover {
    background-color: #f50;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-soundcloud .badge {
    color: #f50;
    background-color: #fff;
}
.btn-tumblr {
    color: #fff !important;
    background-color: #2c4762;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-tumblr.active,
.btn-tumblr.focus,
.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover,
.open > .btn-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #1c2e3f !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-tumblr.active.focus,
.btn-tumblr.active:focus,
.btn-tumblr.active:hover,
.btn-tumblr:active.focus,
.btn-tumblr:active:focus,
.btn-tumblr:active:hover,
.open > .btn-tumblr.dropdown-toggle.focus,
.open > .btn-tumblr.dropdown-toggle:focus,
.open > .btn-tumblr.dropdown-toggle:hover {
    color: #fff;
    background-color: #111c26 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-tumblr.active,
.btn-tumblr:active,
.open > .btn-tumblr.dropdown-toggle {
    background-image: none;
}
.btn-tumblr.disabled.focus,
.btn-tumblr.disabled:focus,
.btn-tumblr.disabled:hover,
.btn-tumblr[disabled].focus,
.btn-tumblr[disabled]:focus,
.btn-tumblr[disabled]:hover,
fieldset[disabled] .btn-tumblr.focus,
fieldset[disabled] .btn-tumblr:focus,
fieldset[disabled] .btn-tumblr:hover {
    background-color: #2c4762;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-tumblr .badge {
    color: #2c4762;
    background-color: #fff;
}
.btn-twitter {
    color: #fff !important;
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter.active,
.btn-twitter.focus,
.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover,
.open > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #2795e9 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter.active.focus,
.btn-twitter.active:focus,
.btn-twitter.active:hover,
.btn-twitter:active.focus,
.btn-twitter:active:focus,
.btn-twitter:active:hover,
.open > .btn-twitter.dropdown-toggle.focus,
.open > .btn-twitter.dropdown-toggle:focus,
.open > .btn-twitter.dropdown-toggle:hover {
    color: #fff;
    background-color: #1583d7 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter.active,
.btn-twitter:active,
.open > .btn-twitter.dropdown-toggle {
    background-image: none;
}
.btn-twitter.disabled.focus,
.btn-twitter.disabled:focus,
.btn-twitter.disabled:hover,
.btn-twitter[disabled].focus,
.btn-twitter[disabled]:focus,
.btn-twitter[disabled]:hover,
fieldset[disabled] .btn-twitter.focus,
fieldset[disabled] .btn-twitter:focus,
fieldset[disabled] .btn-twitter:hover {
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-twitter .badge {
    color: #55acee;
    background-color: #fff;
}
.btn-vimeo {
    color: #fff !important;
    background-color: #1ab7ea;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-vimeo.active,
.btn-vimeo.focus,
.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover,
.open > .btn-vimeo.dropdown-toggle {
    color: #fff;
    background-color: #1295bf !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-vimeo.active.focus,
.btn-vimeo.active:focus,
.btn-vimeo.active:hover,
.btn-vimeo:active.focus,
.btn-vimeo:active:focus,
.btn-vimeo:active:hover,
.open > .btn-vimeo.dropdown-toggle.focus,
.open > .btn-vimeo.dropdown-toggle:focus,
.open > .btn-vimeo.dropdown-toggle:hover {
    color: #fff;
    background-color: #0f7b9f !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-vimeo.active,
.btn-vimeo:active,
.open > .btn-vimeo.dropdown-toggle {
    background-image: none;
}
.btn-vimeo.disabled.focus,
.btn-vimeo.disabled:focus,
.btn-vimeo.disabled:hover,
.btn-vimeo[disabled].focus,
.btn-vimeo[disabled]:focus,
.btn-vimeo[disabled]:hover,
fieldset[disabled] .btn-vimeo.focus,
fieldset[disabled] .btn-vimeo:focus,
fieldset[disabled] .btn-vimeo:hover {
    background-color: #1ab7ea;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-vimeo .badge {
    color: #1ab7ea;
    background-color: #fff;
}
.btn-vk {
    color: #fff !important;
    background-color: #587ea3;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-vk.active,
.btn-vk.focus,
.btn-vk:active,
.btn-vk:focus,
.btn-vk:hover,
.open > .btn-vk.dropdown-toggle {
    color: #fff;
    background-color: #466482 !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-vk.active.focus,
.btn-vk.active:focus,
.btn-vk.active:hover,
.btn-vk:active.focus,
.btn-vk:active:focus,
.btn-vk:active:hover,
.open > .btn-vk.dropdown-toggle.focus,
.open > .btn-vk.dropdown-toggle:focus,
.open > .btn-vk.dropdown-toggle:hover {
    color: #fff;
    background-color: #3a526b !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-vk.active,
.btn-vk:active,
.open > .btn-vk.dropdown-toggle {
    background-image: none;
}
.btn-vk.disabled.focus,
.btn-vk.disabled:focus,
.btn-vk.disabled:hover,
.btn-vk[disabled].focus,
.btn-vk[disabled]:focus,
.btn-vk[disabled]:hover,
fieldset[disabled] .btn-vk.focus,
fieldset[disabled] .btn-vk:focus,
fieldset[disabled] .btn-vk:hover {
    background-color: #587ea3;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-vk .badge {
    color: #587ea3;
    background-color: #fff;
}
.btn-yahoo {
    color: #fff !important;
    background-color: #720e9e;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-yahoo.active,
.btn-yahoo.focus,
.btn-yahoo:active,
.btn-yahoo:focus,
.btn-yahoo:hover,
.open > .btn-yahoo.dropdown-toggle {
    color: #fff;
    background-color: #500a6f !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-yahoo.active.focus,
.btn-yahoo.active:focus,
.btn-yahoo.active:hover,
.btn-yahoo:active.focus,
.btn-yahoo:active:focus,
.btn-yahoo:active:hover,
.open > .btn-yahoo.dropdown-toggle.focus,
.open > .btn-yahoo.dropdown-toggle:focus,
.open > .btn-yahoo.dropdown-toggle:hover {
    color: #fff;
    background-color: #39074e !important;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-yahoo.active,
.btn-yahoo:active,
.open > .btn-yahoo.dropdown-toggle {
    background-image: none;
}
.btn-yahoo.disabled.focus,
.btn-yahoo.disabled:focus,
.btn-yahoo.disabled:hover,
.btn-yahoo[disabled].focus,
.btn-yahoo[disabled]:focus,
.btn-yahoo[disabled]:hover,
fieldset[disabled] .btn-yahoo.focus,
fieldset[disabled] .btn-yahoo:focus,
fieldset[disabled] .btn-yahoo:hover {
    background-color: #720e9e;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-yahoo .badge {
    color: #720e9e;
    background-color: #fff;
}
.btn-outline-adn {
    border: 1px solid #d87a68 !important;
    color: #d87a68 !important;
}
.btn-outline-bitbucket {
    border: 1px solid #205081 !important;
    color: #205081 !important;
}
.btn-outline-dropbox {
    border: 1px solid #1087dd !important;
    color: #1087dd !important;
}
.btn-outline-facebook {
    border: 1px solid #3b5998 !important;
    color: #3b5998 !important;
}
.btn-outline-flickr {
    border: 1px solid #ff0084 !important;
    color: #ff0084 !important;
}
.btn-outline-foursquare {
    border: 1px solid #f94877 !important;
    color: #f94877 !important;
}
.btn-outline-github {
    border: 1px solid #444 !important;
    color: #444 !important;
}
.btn-outline-google {
    border: 1px solid #dd4b39 !important;
    color: #dd4b39 !important;
}
.btn-outline-instagram {
    border: 1px solid #3f729b !important;
    color: #3f729b !important;
}
.btn-outline-linkedin {
    border: 1px solid #007bb6 !important;
    color: #007bb6 !important;
}
.btn-outline-microsoft {
    border: 1px solid #2672ec !important;
    color: #2672ec !important;
}
.btn-outline-odnoklassniki {
    border: 1px solid #f4731c !important;
    color: #f4731c !important;
}
.btn-outline-openid {
    border: 1px solid #f7931e !important;
    color: #f7931e !important;
}
.btn-outline-pinterest {
    border: 1px solid #cb2027 !important;
    color: #cb2027 !important;
}
.btn-outline-reddit {
    border: 1px solid #ff4500 !important;
    color: #ff4500 !important;
}
.btn-outline-soundcloud {
    border: 1px solid #f50 !important;
    color: #f50 !important;
}
.btn-outline-tumblr {
    border: 1px solid #2c4762 !important;
    color: #2c4762 !important;
}
.btn-outline-twitter {
    border: 1px solid #55acee !important;
    color: #55acee !important;
}
.btn-outline-vimeo {
    border: 1px solid #1ab7ea !important;
    color: #1ab7ea !important;
}
.btn-outline-vk {
    border: 1px solid #587ea3 !important;
    color: #587ea3 !important;
}
.btn-outline-yahoo {
    border: 1px solid #720e9e !important;
    color: #720e9e !important;
}
.btn-outline-adn:hover {
    color: #ad412d;
    border: 1px solid #ad412d !important;
    background-color: initial !important;
}
.btn-outline-bitbucket:hover {
    color: #0c1d2f;
    border: 1px solid #0c1d2f !important;
    background-color: initial !important;
}
.btn-outline-dropbox:hover {
    color: #094d7e;
    border: 1px solid #094d7e !important;
    background-color: initial !important;
}
.btn-outline-facebook:hover {
    color: #1e2e4f;
    border: 1px solid #1e2e4f !important;
    background-color: initial !important;
}
.btn-outline-flickr:hover {
    color: #99004f;
    border: 1px solid #99004f !important;
    background-color: initial !important;
}
.btn-outline-foursquare:hover {
    color: #d4073d;
    border: 1px solid #d4073d !important;
    background-color: initial !important;
}
.btn-outline-github:hover {
    color: #111;
    border: 1px solid #111 !important;
    background-color: initial !important;
}
.btn-outline-google:hover {
    color: #96271a;
    border: 1px solid #96271a !important;
    background-color: initial !important;
}
.btn-outline-instagram:hover {
    color: #223d52;
    border: 1px solid #223d52 !important;
    background-color: initial !important;
}
.btn-outline-linkedin:hover {
    color: #003650;
    border: 1px solid #003650 !important;
    background-color: initial !important;
}
.btn-outline-microsoft:hover {
    color: #0e459e;
    border: 1px solid #0e459e !important;
    background-color: initial !important;
}
.btn-outline-odnoklassniki:hover {
    color: #a24608;
    border: 1px solid #a24608 !important;
    background-color: initial !important;
}
.btn-outline-openid:hover {
    color: #a95e06;
    border: 1px solid #a95e06 !important;
    background-color: initial !important;
}
.btn-outline-pinterest:hover {
    color: #731216;
    border: 1px solid #731216 !important;
    background-color: initial !important;
}
.btn-outline-reddit:hover {
    color: #992900;
    border: 1px solid #992900 !important;
    background-color: initial !important;
}
.btn-outline-soundcloud:hover {
    color: #930;
    border: 1px solid #930 !important;
    background-color: initial !important;
}
.btn-outline-tumblr:hover {
    color: #0c141c;
    border: 1px solid #0c141c !important;
    background-color: initial !important;
}
.btn-outline-twitter:hover {
    color: #147bc9;
    border: 1px solid #147bc9 !important;
    background-color: initial !important;
}
.btn-outline-vimeo:hover {
    color: #0d7091;
    border: 1px solid #0d7091 !important;
    background-color: initial !important;
}
.btn-outline-vk:hover {
    color: #344b61;
    border: 1px solid #344b61 !important;
    background-color: initial !important;
}
.btn-outline-yahoo:hover {
    color: #2e0640;
    border: 1px solid #2e0640 !important;
    background-color: initial !important;
}
.btn-adn-flat {
    color: #d87a68 !important;
    background-color: initial !important;
}
.btn-adn-flat,
.btn-adn-flat span {
    border: none !important;
}
.btn-adn-flat:hover {
    color: #993a28 !important;
    background-color: initial !important;
}
.btn-bitbucket-flat {
    color: #205081 !important;
    background-color: initial !important;
}
.btn-bitbucket-flat,
.btn-bitbucket-flat span {
    border: none !important;
}
.btn-bitbucket-flat:hover {
    color: #07111b !important;
    background-color: initial !important;
}
.btn-dropbox-flat {
    color: #1087dd !important;
    background-color: initial !important;
}
.btn-dropbox-flat,
.btn-dropbox-flat span {
    border: none !important;
}
.btn-dropbox-flat:hover {
    color: #073e66 !important;
    background-color: initial !important;
}
.btn-facebook-flat {
    color: #3b5998 !important;
    background-color: initial !important;
}
.btn-facebook-flat,
.btn-facebook-flat span {
    border: none !important;
}
.btn-facebook-flat:hover {
    color: #17233c !important;
    background-color: initial !important;
}
.btn-flickr-flat {
    color: #ff0084 !important;
    background-color: initial !important;
}
.btn-flickr-flat,
.btn-flickr-flat span {
    border: none !important;
}
.btn-flickr-flat:hover {
    color: #800042 !important;
    background-color: initial !important;
}
.btn-foursquare-flat {
    color: #f94877 !important;
    background-color: initial !important;
}
.btn-foursquare-flat,
.btn-foursquare-flat span {
    border: none !important;
}
.btn-foursquare-flat:hover {
    color: #bb0636 !important;
    background-color: initial !important;
}
.btn-github-flat {
    color: #444 !important;
    background-color: initial !important;
}
.btn-github-flat,
.btn-github-flat span {
    border: none !important;
}
.btn-github-flat:hover {
    color: #040404 !important;
    background-color: initial !important;
}
.btn-google-flat {
    color: #dd4b39 !important;
    background-color: initial !important;
}
.btn-google-flat,
.btn-google-flat span {
    border: none !important;
}
.btn-google-flat:hover {
    color: #802216 !important;
    background-color: initial !important;
}
.btn-instagram-flat {
    color: #3f729b !important;
    background-color: initial !important;
}
.btn-instagram-flat,
.btn-instagram-flat span {
    border: none !important;
}
.btn-instagram-flat:hover {
    color: #1a2f40 !important;
    background-color: initial !important;
}
.btn-linkedin-flat {
    color: #007bb6 !important;
    background-color: initial !important;
}
.btn-linkedin-flat,
.btn-linkedin-flat span {
    border: none !important;
}
.btn-linkedin-flat:hover {
    color: #002537 !important;
    background-color: initial !important;
}
.btn-microsoft-flat {
    color: #2672ec !important;
    background-color: initial !important;
}
.btn-microsoft-flat,
.btn-microsoft-flat span {
    border: none !important;
}
.btn-microsoft-flat:hover {
    color: #0c3b87 !important;
    background-color: initial !important;
}
.btn-odnoklassniki-flat {
    color: #f4731c !important;
    background-color: initial !important;
    border: none !important;
}
.btn-odnoklassniki-flat span {
    border: none !important;
}
.btn-odnoklassniki-flat:hover {
    color: #8a3c07 !important;
    background-color: initial !important;
}
.btn-openid-flat {
    color: #f7931e !important;
    background-color: initial !important;
}
.btn-openid-flat,
.btn-openid-flat span {
    border: none !important;
}
.btn-openid-flat:hover {
    color: #905005 !important;
    background-color: initial !important;
}
.btn-pinterest-flat {
    color: #cb2027 !important;
    background-color: initial !important;
}
.btn-pinterest-flat,
.btn-pinterest-flat span {
    border: none !important;
}
.btn-pinterest-flat:hover {
    color: #5d0f12 !important;
    background-color: initial !important;
}
.btn-reddit-flat {
    color: #ff4500 !important;
    background-color: initial !important;
}
.btn-reddit-flat,
.btn-reddit-flat span {
    border: none !important;
}
.btn-reddit-flat:hover {
    color: #802300 !important;
    background-color: initial !important;
}
.btn-soundcloud-flat {
    color: #f50 !important;
    background-color: initial !important;
}
.btn-soundcloud-flat,
.btn-soundcloud-flat span {
    border: none !important;
}
.btn-soundcloud-flat:hover {
    color: #802b00 !important;
    background-color: initial !important;
}
.btn-tumblr-flat {
    color: #2c4762 !important;
    background-color: initial !important;
}
.btn-tumblr-flat,
.btn-tumblr-flat span {
    border: none !important;
}
.btn-tumblr-flat:hover {
    color: #04070a !important;
    background-color: initial !important;
}
.btn-twitter-flat {
    color: #55acee !important;
    background-color: initial !important;
}
.btn-twitter-flat,
.btn-twitter-flat span {
    border: none !important;
}
.btn-twitter-flat:hover {
    color: #126db2 !important;
    background-color: initial !important;
}
.btn-vimeo-flat {
    color: #1ab7ea !important;
    background-color: initial !important;
}
.btn-vimeo-flat,
.btn-vimeo-flat span {
    border: none !important;
}
.btn-vimeo-flat:hover {
    color: #0b5e79 !important;
    background-color: initial !important;
}
.btn-vk-flat {
    color: #587ea3 !important;
    background-color: initial !important;
}
.btn-vk-flat,
.btn-vk-flat span {
    border: none !important;
}
.btn-vk-flat:hover {
    color: #2b3e50 !important;
    background-color: initial !important;
}
.btn-yahoo-flat {
    color: #720e9e !important;
    background-color: initial !important;
}
.btn-yahoo-flat,
.btn-yahoo-flat span {
    border: none !important;
}
.btn-yahoo-flat:hover {
    color: #1d0429 !important;
}
.btn-adn-flat.active,
.btn-adn-flat:active,
.btn-adn-flat:active:focus,
.btn-adn-flat:active:hover,
.btn-adn-flat:focus,
.btn-bitbucket-flat.active,
.btn-bitbucket-flat:active,
.btn-bitbucket-flat:active:focus,
.btn-bitbucket-flat:active:hover,
.btn-bitbucket-flat:focus,
.btn-dropbox-flat.active,
.btn-dropbox-flat:active,
.btn-dropbox-flat:active:focus,
.btn-dropbox-flat:active:hover,
.btn-dropbox-flat:focus,
.btn-facebook-flat.active,
.btn-facebook-flat:active,
.btn-facebook-flat:active:focus,
.btn-facebook-flat:active:hover,
.btn-facebook-flat:focus,
.btn-flickr-flat.active,
.btn-flickr-flat:active,
.btn-flickr-flat:active:focus,
.btn-flickr-flat:active:hover,
.btn-flickr-flat:focus,
.btn-foursquare-flat.active,
.btn-foursquare-flat:active,
.btn-foursquare-flat:active:focus,
.btn-foursquare-flat:active:hover,
.btn-foursquare-flat:focus,
.btn-github-flat.active,
.btn-github-flat:active,
.btn-github-flat:active:focus,
.btn-github-flat:active:hover,
.btn-github-flat:focus,
.btn-google-flat.active,
.btn-google-flat:active,
.btn-google-flat:active:focus,
.btn-google-flat:active:hover,
.btn-google-flat:focus,
.btn-instagram-flat.active,
.btn-instagram-flat:active,
.btn-instagram-flat:active:focus,
.btn-instagram-flat:active:hover,
.btn-instagram-flat:focus,
.btn-linkedin-flat.active,
.btn-linkedin-flat:active,
.btn-linkedin-flat:active:focus,
.btn-linkedin-flat:active:hover,
.btn-linkedin-flat:focus,
.btn-microsoft-flat.active,
.btn-microsoft-flat:active,
.btn-microsoft-flat:active:focus,
.btn-microsoft-flat:active:hover,
.btn-microsoft-flat:focus,
.btn-odnoklassniki-flat.active,
.btn-odnoklassniki-flat:active,
.btn-odnoklassniki-flat:active:focus,
.btn-odnoklassniki-flat:active:hover,
.btn-odnoklassniki-flat:focus,
.btn-openid-flat.active,
.btn-openid-flat:active,
.btn-openid-flat:active:focus,
.btn-openid-flat:active:hover,
.btn-openid-flat:focus,
.btn-pinterest-flat.active,
.btn-pinterest-flat:active,
.btn-pinterest-flat:active:focus,
.btn-pinterest-flat:active:hover,
.btn-pinterest-flat:focus,
.btn-reddit-flat.active,
.btn-reddit-flat:active,
.btn-reddit-flat:active:focus,
.btn-reddit-flat:active:hover,
.btn-reddit-flat:focus,
.btn-soundcloud-flat.active,
.btn-soundcloud-flat:active,
.btn-soundcloud-flat:active:focus,
.btn-soundcloud-flat:active:hover,
.btn-soundcloud-flat:focus,
.btn-tumblr-flat.active,
.btn-tumblr-flat:active,
.btn-tumblr-flat:active:focus,
.btn-tumblr-flat:active:hover,
.btn-tumblr-flat:focus,
.btn-twitter-flat.active,
.btn-twitter-flat:active,
.btn-twitter-flat:active:focus,
.btn-twitter-flat:active:hover,
.btn-twitter-flat:focus,
.btn-vimeo-flat.active,
.btn-vimeo-flat:active,
.btn-vimeo-flat:active:focus,
.btn-vimeo-flat:active:hover,
.btn-vimeo-flat:focus,
.btn-vk-flat.active,
.btn-vk-flat:active,
.btn-vk-flat:active:focus,
.btn-vk-flat:active:hover,
.btn-vk-flat:focus,
.btn-yahoo-flat.active,
.btn-yahoo-flat:active,
.btn-yahoo-flat:active:focus,
.btn-yahoo-flat:active:hover,
.btn-yahoo-flat:focus,
.btn-yahoo-flat:hover {
    background-color: initial !important;
}
.comingsoonVideo .app-content .coming-soon-content {
    z-index: 99;
}
.clockCard {
    float: left;
    border-radius: 0.5rem;
}
.getting-started {
    font-size: 2.4rem;
    border-radius: 60px;
    min-width: 360px;
}
.countdownFormat {
    font-size: 16px;
    padding-bottom: 0;
}
.socialIcon i {
    font-size: 28px;
    padding: 5px;
}
.clockFormat {
    border-radius: 60px;
}
#search-nav .nav {
    border-bottom: 2px solid #e0e0e0;
}
#search-nav .nav-link.active {
    padding-bottom: 6px;
    border-bottom: 2px solid #009da0;
    margin-bottom: -2px;
}
#search-results .media-search {
    padding-top: 5px;
}
#search-results p {
    font-size: 0.9rem;
}
#search-results .media-list {
    padding: 0;
}
#search-results .media-list .media {
    padding: 1.25rem;
    margin-top: 0;
}
hr {
    border-color: #a8a8a8;
}
ul.list-inline i {
    line-height: 18px;
}
ul.list-inline li {
    display: inline-block;
}
ul.list-inline.list-inline-pipe > li + li:before {
    content: ' | ';
    color: #868e96;
}
.circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 2s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    to {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
@keyframes color {
    0%,
    to {
        stroke: #009da0;
    }
    50% {
        stroke: #ff8d60;
    }
    to {
        stroke: #009da0;
    }
}
.animation-transition-general {
    transition: all 0.3s linear;
}
.animation-transition-slow {
    transition: all 0.37s linear;
}
.animation-transition-fast {
    transition: all 0.15s ease 0s;
}
legend {
    border-bottom: 0;
}
* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}
:focus {
    outline: 0;
}
a:active,
a:focus,
button::-moz-focus-inner,
button:active,
button:focus,
button:hover,
input[type='button']::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner {
    outline: 0 !important;
}
.table-borderless tbody tr td,
.table-borderless tbody tr th {
    border: 0;
}
.table tr{
    border: 0;
    &:hover {
        background-color: #37394c;
        transition: background-color linear .3s;
    }
}
datetime .form-group {
    margin: 0;
}
.btn-round {
    border-radius: 30px;
}
.fonticon-container {
    padding: 10px;
}
.fonticon-container > .fonticon-wrap {
    float: left;
    width: 40px;
    height: 40px;
    line-height: 4.8rem;
    text-align: center;
    border-radius: 0.1875rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    transition: all 0.2s ease-in-out;
}
.fonticon-container > .fonticon-wrap > svg {
    font-size: 2rem;
    transition: all 0.2s ease-in-out;
}
.fonticon-container > .fonticon-wrap > svg.fa {
    font-size: 2rem !important;
}
.fonticon-container > .fonticon-wrap.youtube {
    width: 100px;
}
.fonticon-container:hover svg {
    color: #009da0;
    font-size: 2.9rem;
    transform: scale(1.3);
}
.fonticon-container > .fonticon-classname,
.fonticon-container > .fonticon-unit {
    display: block;
    font-size: 1.5rem;
    line-height: 1.8;
}
.fonticon-container > .fonticon-unit {
    font-size: 1rem;
    font-style: italic;
}
.langimg {
    width: 30px;
    margin-right: 5px;
}
.notification-dropdown {
    width: 400px;
}
.notification-dropdown .noti-list {
    position: relative;
    height: 290px;
}
.notification-dropdown.dropdown-menu {
    padding: 0;
}
.notification-dropdown .noti-text {
    color: #6c757d;
    font-size: 90%;
}
.notification-dropdown .dropdown-item:focus i,
.notification-dropdown .dropdown-item:hover i {
    color: #009da0 !important;
}
.notification-dropdown .dropdown-item.active .noti-text,
.notification-dropdown .dropdown-item.active .noti-title,
.notification-dropdown .dropdown-item.active i,
.notification-dropdown .dropdown-item:active .noti-text,
.notification-dropdown .dropdown-item:active .noti-title,
.notification-dropdown .dropdown-item:active i {
    color: #fff !important;
}
.custom-control-input:active ~ .custom-control-indicator {
    background-color: #009da0;
}
.form-control:focus {
    border-color: #009da0 !important;
}
.dragdrop-container {
    min-height: 200px;
}
.content-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: block;
    z-index: 30;
    visibility: hidden;
}
.content-overlay.show {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.4);
    cursor:pointer;
}
.ReactTable .rt-thead {
    overflow-y: scroll;
}
.loader-bg {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #3c4252;
}
.loader-content {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
}
.loader-content .logo {
    margin-left: -72px;
    margin-top: -152px;
}
.loader-content .loading {
    margin-left: -30px;
    margin-top: 30px;
}
.sweet-loading {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
}
#user-profile .profile-with-cover {
    position: relative;
}
#user-profile .profile-with-cover .profil-cover-details {
    margin-top: -50px;
}
#user-profile
    .profile-with-cover
    .profil-cover-details
    .profile-image
    img.img-border {
    border: 5px solid #fff;
}
.profile-section .profile-menu {
    position: relative;
    top: -30px;
    padding-bottom: 0;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.profile-section .profile-menu li a {
    display: block;
}
.profile-section .profile-menu li a.active {
    border-bottom: 1px solid #009da0;
}
@media (max-width: 550px) {
    #user-profile .profile-section .profile-menu {
        top: 0;
    }
    #user-profile .profile-with-cover .profile-cover-buttons {
        top: 15px;
        right: 0;
    }
    #user-profile h3.card-title {
        font-size: 1rem;
    }
}
[dir='rtl'] #user-profile .profile-with-cover .profile-cover-buttons {
    left: 0;
    right: auto;
}
[dir='rtl'] .profile-section .profile-menu {
    padding-right: 15px;
    padding-left: auto;
}
@media (max-width: 550px) {
    [dir='rtl'] #user-profile .profile-with-cover .profile-cover-buttons {
        left: 0;
        right: auto;
    }
}
.wrapper {
    position: relative;
    top: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.wrapper .content-header {
    font-weight: 800;
    font-size: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}
.wrapper .content-sub-header {
    color: #bdbdc7;
    margin-bottom: 1rem;
}
.wrapper .collapsed.app-sidebar {
    width: 60px;
}
.wrapper .collapsed.app-sidebar:hover {
    width: 250px;
}
.wrapper .collapsed.app-sidebar:hover .nav li.has-sub > a:after {
    display: inherit;
}
.wrapper .collapsed.app-sidebar:hover .badge,
.wrapper .collapsed.app-sidebar:hover .logo .text,
.wrapper .collapsed.app-sidebar:hover .menu-item-text {
    display: inline-block;
}
.wrapper .collapsed.app-sidebar:hover .nav-toggle {
    display: block !important;
}
.wrapper .collapsed.app-sidebar .badge,
.wrapper .collapsed.app-sidebar .item-arrow,
.wrapper .collapsed.app-sidebar .logo .text,
.wrapper .collapsed.app-sidebar .menu-item-text {
    visibility: hidden;
}
.wrapper .collapsed.app-sidebar .nav-close,
.wrapper .collapsed.app-sidebar .nav-toggle {
    visibility: hidden !important;
}
.wrapper .collapsed .navbar .navbar-header {
    padding-left: 50px;
}
.wrapper .collapsed .main-panel .main-content,
.wrapper .collapsed footer {
    padding-left: 60px;
}
.wrapper.menu-collapsed .app-sidebar .navigation li.has-sub > a:after {
    display: none;
}
.hamburguer-menu{
    cursor:pointer;
}
.bootstrap-navbar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    display: block;
    z-index: 1;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    background-position: 50%;
}
.logo-img-normatiza{
    min-width: 8em;
    max-width: 8em;
    position: relative;
    display: inline-block;
}
.app-sidebar,
.off-canvas-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 65px;
    height: 100vh;
    margin-right: 80px;
    box-shadow: 2px 10px 30px 0 rgba(0, 0, 0, 0.42),
        0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
    transform: translateZ(0);
    visibility: visible;
}
.app-sidebar.hide-sidebar,
.off-canvas-sidebar.hide-sidebar {
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate3d(-100%, 0, 0);
}
.app-sidebar .sidebar-content,
.off-canvas-sidebar .sidebar-content {
    position: relative;
    height: calc(80vh);
    overflow: auto;
    z-index: 4;
}
.app-sidebar .logo-img,
.sidebar-recommended .logo-img,
.off-canvas-sidebar .logo-img {
    width: 45px;
    display: inline-block;
    max-height: 55px;   
}
.app-sidebar .navigation,
.off-canvas-sidebar .navigation {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}
.app-sidebar .navigation li,
.off-canvas-sidebar .navigation li {
    position: relative;
    display: block;
}
.app-sidebar .navigation li.has-sub > a > .item-arrow svg,
.off-canvas-sidebar .navigation li.has-sub > a > .item-arrow svg {
    transform: rotate(0);
    transition: -webkit-transform 0.2s ease-in-out;
    position: absolute;
    right: 15px;
    top: 18px;
}
.app-sidebar .navigation li.has-sub > a,
.off-canvas-sidebar .navigation li.has-sub > a {
    margin-bottom: 0;
}
.app-sidebar .navigation li.open > a > .item-arrow svg,
.off-canvas-sidebar .navigation li.open > a > .item-arrow svg {
    transform: rotate(90deg);
}
.app-sidebar .navigation li.open > a,
.off-canvas-sidebar .navigation li.open > a {
    background: hsla(0, 0%, 78.4%, 0.2);
}
.app-sidebar .navigation li > a,
.off-canvas-sidebar .navigation li > a {
    display: block;
    overflow: hidden;
    line-height: 2rem;
    padding: 10px 30px 10px 20px;
    margin: 10px 0;
    background-color: initial;
    transition: all 0.3s linear;
}
.app-sidebar .navigation li > a:before,
.off-canvas-sidebar .navigation li > a:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    border-right: 5px solid #ff586b;
    background: hsla(0, 0%, 78.4%, 0.2);
    transition: left 0.35s ease;
}
.app-sidebar .navigation li:not(.open) > ul,
.off-canvas-sidebar .navigation li:not(.open) > ul {
    display: none;
}
.app-sidebar .navigation li:hover > a:hover,
.off-canvas-sidebar .navigation li:hover > a:hover {
    background: hsla(0, 0%, 78.4%, 0.2);
}
.app-sidebar .navigation li > a.active:before,
.off-canvas-sidebar .navigation li > a.active:before {
    left: 0;
}
.app-sidebar .navigation li ul,
.off-canvas-sidebar .navigation li ul {
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-size: 1rem;
}
.app-sidebar .navigation li ul li a,
.off-canvas-sidebar .navigation li ul li a {
    display: block;
    padding: 8px 18px 8px 60px;
}
.app-sidebar .navigation li ul li ul li a,
.off-canvas-sidebar .navigation li ul li ul li a {
    padding: 8px 18px 8px 70px;
}
.app-sidebar .navigation .menu-item-text,
.off-canvas-sidebar .navigation .menu-item-text {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 0px;
    opacity: 1;
    height: auto;
    transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
    text-transform: uppercase;
}
.app-sidebar .navigation .badge.badge-pill,
.off-canvas-sidebar .navigation .badge.badge-pill {
    position: absolute;
    top: 16px;
    right: 32px;
    padding: 4px 8px;
    font-weight: 400;
    transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.app-sidebar .navigation i,
.off-canvas-sidebar .navigation i {
    float: left;
    margin-right: 10px;
    line-height: 2rem;
    width: 30px;
    text-align: center;
    color: #a9afbb;
}
.app-sidebar .sidebar-background,
.off-canvas-sidebar .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50%;
}
.app-sidebar .sidebar-background:after,
.off-canvas-sidebar .sidebar-background:after {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    background: #fff;
    opacity: 0.93;
}
.app-sidebar .logo,
.off-canvas-sidebar .logo {
    position: relative;
    padding-top: 20px;
    padding-bottom: 10px;
    z-index: 4;
}
.app-sidebar .logo p,
.off-canvas-sidebar .logo p {
    float: left;
    font-size: 20px;
    margin: 10px;
    color: #fff;
    line-height: 20px;
}
.app-sidebar .logo .logo-text,
.off-canvas-sidebar .logo .logo-text {
    text-transform: uppercase;
    padding: 0.3em 0.375em;
    display: block;
    font-size: 1.6rem;
    letter-spacing: 2px;
    color: #000;
    text-align: left;
    font-weight: 400;
}
.app-sidebar .logo-tim,
.off-canvas-sidebar .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;
}
.app-sidebar .logo-tim img,
.off-canvas-sidebar .logo-tim img {
    width: 60px;
    height: 60px;
}
.app-sidebar:after,
.app-sidebar:before,
.off-canvas-sidebar:after,
.off-canvas-sidebar:before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}
.app-sidebar:before,
.off-canvas-sidebar:before {
    opacity: 0.33;
}
.app-sidebar:after,
.off-canvas-sidebar:after {
    z-index: 3;
    opacity: 1;
}
.app-sidebar.has-image:after,
.app-sidebar[data-image]:after,
.off-canvas-sidebar.has-image:after,
.off-canvas-sidebar[data-image]:after {
    opacity: 0.77;
}
.app-sidebar,
.off-canvas-sidebar {
    background-image: linear-gradient(150deg, #4c078c 35%, #00A887 100%);
}
.app-sidebar .navigation i,
.app-sidebar .navigation li > a {
    color: #ffffff;
}
.app-sidebar[data-color='blue'] .moving-tab,
.off-canvas-sidebar[data-color='blue'] .moving-tab {
    background-color: #1cbcd8;
}
.app-sidebar[data-color='green'] .moving-tab,
.off-canvas-sidebar[data-color='green'] .moving-tab {
    background-color: #00a887;
}
.app-sidebar[data-color='orange'] .moving-tab,
.off-canvas-sidebar[data-color='orange'] .moving-tab {
    background-color: #ff8d60;
}
.app-sidebar[data-color='red'] .moving-tab,
.off-canvas-sidebar[data-color='red'] .moving-tab {
    background-color: #ff586b;
}
.app-sidebar[data-color='purple'] .moving-tab,
.off-canvas-sidebar[data-color='purple'] .moving-tab {
    background-color: #009da0;
}
@media (max-width: 767.98px) {
    .app-sidebar,
    .off-canvas-sidebar {
        box-shadow: none;
    }
}
.sidebar-header .nav-toggle {
    right: 17px;
}
.sidebar-header .nav-close,
.sidebar-header .nav-toggle {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    top: 32px;
    color: #fff;
}
.sidebar-header .nav-close {
    right: 14px;
}
.off-canvas-sidebar .navigation > li > a,
.off-canvas-sidebar .navigation > li > a:hover {
    color: #fff;
}
.off-canvas-sidebar .navigation > li > a:focus {
    background: hsla(0, 0%, 78.4%, 0.2);
}
.main-panel {
    position: relative;
    z-index: 2;
    float: right;
    min-height: 100%;
    transform: translateZ(0);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
.main-panel .main-content {
    position: relative;
    min-height: calc(100% - 123px);
    transition: all 0.3s ease;
}
.main-panel div .footer {
    border-top: 1px solid #e7e7e7;
}
.main-panel > .navbar {
    margin-bottom: 0;
}
.bootstrap-navbar .navigation {
    margin-top: 20px;
    float: none;
}
.main-panel {
    max-height: 100%;
    height: 100%;
    width: 100%;
}
.sidebar-sm .app-sidebar {
    width: 220px;
}
.sidebar-lg .app-sidebar {
    width: 280px;
}
@media (min-width: 992px) {
    .main-panel .main-content {
        padding-left: 250px;
    }
}
@media (max-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .app-sidebar {
        transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        box-shadow: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-toggle .icon-bar {
        display: block;
        position: relative;
        background: #fff;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        margin: 0 auto;
    }
    .navbar-header .navbar-toggle {
        margin: 14px 15px 14px 0;
        width: 30px;
        height: 30px;
    }
    .navbar-header .navbar-collapse-toggle {
        margin: 20px 0 10px 15px;
    }
    .navbar-form {
        margin-top: 0.75rem !important;
    }
    .bar1,
    .bar2,
    .bar3 {
        outline: 1px solid transparent;
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
@media (max-width: 776px) {
    .navbar-form {
        width: 250px;
    }
    .navbar {
        padding: 0.75em 0;
    }
}
@media (min-width: 320px) {
    .navbar-right {
        float: right !important;
    }
    .navbar-nav > li {
        float: left;
    }
}
@media (max-width: 640px) {
    .navbar-form {
        width: 200px;
    }
    .notification-dropdown {
        width: 300px;
    }
    .notification-dropdown .noti-wrapper {
        white-space: normal;
    }
}
@media (max-width: 320px) {
    .navbar-right {
        display: none;
    }
    .notification-dropdown {
        width: 250px;
    }
}
.row-eq-height [class^='col'] {
    display: flex;
    flex-direction: column;
}
.row-eq-height [class^='col'] > div {
    flex-grow: 1;
}
.w-100 {
    width: 100% !important;
}
.h-100 {
    height: 100% !important;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
a {
    cursor: pointer;
}
.fonticon-wrap {
    width: 2%;
    float: left;
    margin: 0.25rem;
}
.fonticon-wrap i.fa {
    font-size: 1.35rem !important;
}
.round {
    border-radius: 1.5rem;
}
.square {
    border-radius: 0;
}
img.img-border {
    border: 5px solid #fff;
}
ul.no-list-style {
    list-style: none;
}
[class*='gradient-'] {
    background-repeat: repeat !important;
}
.shadow-z-1 {
    box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);
}
.shadow-z-1-hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.shadow-z-2 {
    box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);
}
.shadow-z-3 {
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.shadow-z-4 {
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
        0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.shadow-z-5 {
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
        0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
.big-shadow {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
}
.shadow-big {
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
        0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.shadow-big-navbar {
    box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.42),
        0 3px 20px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.sidebar-shadow {
    box-shadow: 2px 10px 30px 0 rgba(0, 0, 0, 0.42),
        0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card-shadow {
    box-shadow: 0 6px 0 0 rgba(0, 0, 0, 0.01), 0 15px 32px 0 rgba(0, 0, 0, 0.06);
}
@media (max-width: 550px) {
    .rbc-btn-group {
        white-space: pre-wrap !important;
    }
}
.table thead th {
    font-weight: 400;
}
.customizer {
    width: 400px;
    right: -400px;
    padding: 0;
    background-color: #fff;
    z-index: 1051;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100vh;
    transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.customizer.open {
    right: 0;
}
.customizer .customizer-content {
    position: relative;
    height: 100%;
}
.customizer a.customizer-toggle {
    background: #fff;
    color: #009da0;
    display: block;
    box-shadow: -3px 0 8px rgba(0, 0, 0, 0.1);
}
.customizer a.customizer-close {
    color: #000;
}
.customizer .customizer-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 7px;
    width: auto;
    z-index: 10;
}
.customizer #rtl-icon {
    right: -1px;
    margin-top: 50px;
}
.customizer #rtl-icon,
.customizer .customizer-toggle {
    position: absolute;
    top: 35%;
    width: 54px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    line-height: 50px;
}
.customizer .customizer-toggle {
    left: -54px;
}
.customizer .color-options a {
    white-space: pre;
}
.customizer .cz-bg-color {
    margin: 0 auto;
}
.customizer .cz-bg-color span:hover {
    cursor: pointer;
}
.customizer .cz-bg-color span.white {
    color: #ddd !important;
}
.customizer .cz-bg-color .selected {
    border: 3px solid #314fe5;
}
.customizer .cz-bg-image:hover {
    cursor: pointer;
}
.customizer .cz-bg-image img.rounded {
    border-radius: 1rem !important;
    border: 2px solid #e6e6e6;
    margin-right: 8px;
}
.customizer .cz-bg-image img.rounded.selected {
    border: 2px solid #ff586b;
}
.color-palette .color-group li {
    list-style-type: none;
}
.color-palette .color-group li.main-color {
    border-bottom: 4px solid #fafafa;
}
.color-palette .color-group li.color {
    padding: 10px 15px 11px;
    margin: 0;
}
.color-palette .color-group li.color.main-color .name {
    display: block;
    margin-bottom: 53px;
    font-size: 2rem;
}
.color-palette .color-group li.color .hex {
    float: right;
    text-transform: uppercase;
    display: block;
}
.color-palette .color-group:last-child li.main-color {
    border-bottom: 4px solid #fafafa;
}
.color-palette .color-group:last-child li.color {
    padding: 10px 15px 11px;
    margin: 0;
}
.color-palette .color-group:last-child li.color.main-color .name {
    display: block;
    margin-bottom: 53px;
    font-size: 2rem;
}
.color-palette .color-group:last-child li.color .hex {
    float: right;
    text-transform: uppercase;
    display: block;
}
.color-palette .light-strong span {
    color: #fff;
}
.color-palette .dark span {
    color: rgba(0, 0, 0, 0.87);
}
.color-palette span {
    color: hsla(0, 0%, 100%, 0.87);
    font-size: 14px;
    font-weight: 500;
}
.color-palette span.light-strong {
    color: #fff;
}
.color-palette span.dark {
    color: rgba(0, 0, 0, 0.87);
}
#kbModal-body {
    height: 500px;
    overflow-y: scroll;
}
.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}
.animated.bounceIn {
    animation-duration: 0.7s;
}
.animated.bounceOut {
    animation-duration: 0.5s;
}
.animated.bounceIn {
    animation-name: bounceIn;
}
.animated.bounceOut {
    animation-name: bounceOut;
}
.animated.fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.7s;
}
.animated.fadeOut {
    animation-name: fadeOut;
    animation-duration: 0.3s;
}
.animated.bounceInDown {
    animation-name: bounceInDown;
}
.animated.bounceOutUp {
    animation-name: bounceOutUp;
}
@keyframes bounceIn {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
    }
    20% {
        transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
    }
    40% {
        transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
    }
    80% {
        transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        transform: perspective(1px) scaleX(1);
    }
}
@keyframes bounceOut {
    20% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    50%,
    55% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }
    75% {
        transform: translate3d(0, -10px, 0);
    }
    90% {
        transform: translate3d(0, 5px, 0);
    }
    to {
        transform: none;
    }
}
@keyframes bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}
.rrt-confirm-holder {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
}
.rrt-confirm-holder .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(50, 58, 68, 0.8);
}
.rrt-confirm-holder .rrt-confirm {
    width: 320px;
    background-color: #fff;
    position: absolute;
    z-index: 9;
    top: 20%;
    left: 50%;
    margin-left: -160px;
    box-shadow: 3px 3px 20px #333;
    border-radius: 4px;
    overflow: hidden;
}
.rrt-confirm-holder .rrt-confirm .rrt-message {
    width: 100%;
    padding: 5%;
    min-height: 50px;
    font-size: 1em;
    background-color: #fff;
    text-align: center;
    clear: both;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
    display: flex;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
    flex-grow: 1;
    height: 50px;
    text-transform: capitalize;
    background-color: initial;
    padding: 0;
    margin: 0;
    float: left;
    border: none;
    border-top: 1px solid #f0f0f0;
    font-size: 14px;
    overflow: hidden;
    cursor: pointer;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
    background-color: #f5f5f5;
}
.rrt-confirm-holder
    .rrt-confirm
    .rrt-buttons-holder
    .rrt-button.rrt-ok-btn:active {
    background-color: #60bb71;
    color: #fff;
}
.rrt-confirm-holder
    .rrt-confirm
    .rrt-buttons-holder
    .rrt-button.rrt-cancel-btn:active {
    background-color: #db6a64;
    color: #fff;
}
.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
    outline: none;
}
body.toastr-confirm-active {
    overflow: hidden;
}
.redux-toastr *,
.redux-toastr :after,
.redux-toastr :before {
    box-sizing: border-box;
}
.redux-toastr .bottom-center,
.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .top-center,
.redux-toastr .top-left,
.redux-toastr .top-right {
    width: 350px;
    position: fixed;
    z-index: 99999999;
    padding: 0 10px;
}
.redux-toastr .top-center,
.redux-toastr .top-left,
.redux-toastr .top-right {
    top: 0;
}
.redux-toastr .bottom-right,
.redux-toastr .top-right {
    right: 0;
}
.redux-toastr .bottom-center,
.redux-toastr .bottom-left,
.redux-toastr .bottom-right {
    bottom: 0;
}
.redux-toastr .bottom-left,
.redux-toastr .top-left {
    left: 0;
}
.redux-toastr .bottom-center,
.redux-toastr .top-center {
    left: 50%;
    margin-left: -175px;
}
@media (max-width: 320px) {
    .redux-toastr .bottom-center,
    .redux-toastr .bottom-left,
    .redux-toastr .bottom-right,
    .redux-toastr .top-center,
    .redux-toastr .top-left,
    .redux-toastr .top-right {
        width: 320px;
    }
    .redux-toastr .bottom-center,
    .redux-toastr .top-center {
        margin-left: -160px;
    }
}
.redux-toastr .toastr {
    background-color: #fcfcfc;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 4px;
    position: relative;
    z-index: 2;
    color: #333;
    opacity: 0.94;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}
.redux-toastr .toastr:hover:not(.rrt-message) {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    opacity: 1;
}
.redux-toastr .toastr .toastr-status {
    width: 100%;
    height: 5px;
}
.redux-toastr .toastr .toastr-status.success {
    background-color: #60bb71;
}
.redux-toastr .toastr .toastr-status.warning {
    background-color: #f7a336;
}
.redux-toastr .toastr .toastr-status.info {
    background-color: #58abc3;
}
.redux-toastr .toastr .toastr-status.error {
    background-color: #db6a64;
}
.redux-toastr .toastr .rrt-left-container,
.redux-toastr .toastr .rrt-right-container {
    float: left;
    text-align: center;
    overflow: hidden;
}
.redux-toastr .toastr .rrt-left-container {
    width: 80px;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0;
}
.redux-toastr .toastr .rrt-left-container .rrt-holder {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 50%;
    margin-top: -35px;
    left: 5px;
    line-height: 60px;
}
.redux-toastr .toastr .rrt-left-container .toastr-icon {
    fill: #fff;
    vertical-align: middle;
    margin-top: 5px;
}
.redux-toastr .toastr .rrt-middle-container {
    width: 65%;
    margin-left: 80px;
    position: relative;
    float: left;
    font-size: 1em;
    text-align: left;
    padding: 10px 5px;
}
.redux-toastr .toastr .rrt-middle-container .rrt-title {
    font-size: 1.1em;
    font-weight: 700;
    margin-bottom: 5px;
}
.redux-toastr .toastr .rrt-right-container {
    width: 10%;
}
.redux-toastr .toastr .close-toastr {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: initial;
    font-size: 22px;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
}
.redux-toastr .toastr .close-toastr:hover {
    opacity: 1;
}
.redux-toastr .toastr .close-toastr:focus {
    outline: none;
}
.redux-toastr .toastr.rrt-error,
.redux-toastr .toastr.rrt-info,
.redux-toastr .toastr.rrt-success,
.redux-toastr .toastr.rrt-warning {
    color: #fff;
}
.redux-toastr .toastr.rrt-info {
    background-color: #58abc3;
}
.redux-toastr .toastr.rrt-info .rrt-progressbar {
    background-color: #378298;
}
.redux-toastr .toastr.rrt-success {
    background-color: #60bb71;
}
.redux-toastr .toastr.rrt-success .rrt-progressbar {
    background-color: #3e914d;
}
.redux-toastr .toastr.rrt-warning {
    background-color: #f7a336;
}
.redux-toastr .toastr.rrt-warning .rrt-progressbar {
    background-color: #d87e09;
}
.redux-toastr .toastr.rrt-error {
    background-color: #db6a64;
}
.redux-toastr .toastr.rrt-error .rrt-progressbar {
    background-color: #c5352e;
}
.redux-toastr .toastr.rrt-light .rrt-progressbar {
    background-color: #ccc;
}
.redux-toastr .toastr.rrt-light .toastr-icon {
    fill: #333 !important;
}
.redux-toastr .toastr.rrt-message {
    opacity: 1;
    border: 1px solid #dbdbdb;
}
.redux-toastr .toastr.rrt-message .rrt-title {
    width: 90%;
    height: 50px;
    text-align: center;
    font-size: 1.2em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 50px;
    padding: 0 20px;
}
.redux-toastr .toastr.rrt-message .rrt-text {
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    background-color: #fff;
    padding: 15px;
    font-size: 1.1em;
    margin-bottom: 20px;
}
.redux-toastr .toastr.rrt-message .rrt-text img {
    display: block;
    margin: 10px auto;
    max-width: 100%;
}
.redux-toastr .toastr.rrt-message .close-toastr {
    height: 50px;
}
.redux-toastr .toastr .rrt-progress-container {
    height: 5px;
    margin: 0 -20px -20px -60px;
    position: absolute;
    bottom: 20px;
    width: 100%;
}
.redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
    border-radius: 0 0 0 4px;
    height: 100%;
}
.redux-toastr .toastr-attention {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}
