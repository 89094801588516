$body-family: "Roboto", sans-serif;

@import "utils";
@import "apex";
@import "reset";
@import "form";
@import "modal";
@import "login";
@import "tooltip";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

body {
  font-family: $body-family;
  font-weight: 400;
  color: #bdbdc7;
  z-index: 1;
}

body,
html,
#root,
.login-page {
  width: 100%;
  min-height: 100vh;
  float: left;
}

button.nav-link {
  border: none;
  background: transparent;
  outline: 0;
}

.btn span {
  padding: 0px 6px;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: -0.1px;
}

.modal {
  color: rgba(0, 0, 0, 0.87);
}

.fa-fw {
  vertical-align: unset;
}

/* Icon pulse */
.fa-pulse {
  display: inline-block;
  -moz-animation: pulse 2s infinite linear;
  -o-animation: pulse 2s infinite linear;
  -webkit-animation: pulse 2s infinite linear;
  animation: pulse 2s infinite linear;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.customer,
.menu-label {
  .suffix {
    color: #fff !important;
    padding: 0.3em;
    border-radius: 3px;
  }

  a:hover {
    background-color: #343648;
  }

  .dash {
    flex-shrink: 0;
    line-height: 20px;
    font-weight: 500;
    color: rgb(195, 207, 217);
    margin: 0em 0.3em;
  }

  .data {
    text-transform: initial;
    color: #d4d0d0;
  }

  .title {
    clear: both;
    text-align: left;
    background-color: initial;
    border: 0;
    width: 550px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
  }

  .cut {
    clear: both;
    text-align: inherit;
    background-color: initial;
    border: 0;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: right;
  }
}

.customer-analytics {
  text-align: left;
  padding-bottom: 0.8em;

  h4 {
    font-size: 2em;
    font-weight: 800;

    span {
      font-size: 0.5em;
      font-weight: 400;
    }
  }

  h5 {
    font-size: 1.5em;
    font-weight: 800;
  }

  sub {
    font-size: 0.5em;
    font-weight: 400;
    bottom: inherit;
  }

  .card {
    background-color: #c5c5c5;
    color: #2e2f42;
    font-weight: 400;
  }

  .card-footer.client-list {
    background-color: #20212B !important;
  }

  .card-primary {
    background-color: #4e008b;
    color: #fff;
  }

  .progress {
    margin-bottom: 0;
    height: 0.8em;
    border-radius: 0;
  }
}

.add-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.table .table-action {
  background: transparent;
  border: none;
  margin-right: 5px;
  outline: none;
}

.loader-text,
.loader-percent {
  position: absolute;
  left: 50%;
  top: 50%;
}

.loader-percent {
  display: block;
  width: 50px;
  text-align: center;
  margin-top: -8px;
  margin-left: -25px;
}

.loader-text {
  margin-left: -60px;
  margin-top: 90px;
}

.loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  margin-left: -60px;
  margin-top: -60px;
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
  animation: rotate 1.2s infinite linear;
}

.loader-line-mask .loader-line {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.card-analysis {
  &:last-child .card {
    margin-bottom: 0;
  }

  .card {
    background: rgba(255, 255, 255, 0.15);
    margin-bottom: 20px;
  }

  h3 {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 3px;
  }

  .card-body>span {
    display: block;
    line-height: 28px;
    font-size: 14px;
  }
}

.buttons .btn {
  width: 100%;
  margin-top: 10px;
}

.riskDescription {
  font-size: initial;
  font-weight: 100;
  vertical-align: bottom;
  padding-left: 0.3em;
}

.riskLevel {
  width: 100%;
  cursor: help;
}

.aceitavel {
  background-color: transparent;
  color: #006600;
}

.progress.riskLevel.aceitavel {
  background-color: #006600;
}

td.aceitavel,
th.aceitavel {
  background-color: #006600;
  color: white;
}

.muitoBaixo {
  background-color: transparent;
  color: #059bd2;
}

.progress.riskLevel.muitoBaixo {
  background-color: #059bd2;
}

td.muitoBaixo,
th.muitoBaixo {
  background-color: #059bd2;
  color: white;
}

.baixo {
  background-color: transparent;
  color: #0000CC;
}

.progress.riskLevel.baixo {
  background-color: #0000CC;
}

td.baixo,
th.baixo {
  background-color: #0000CC;
  color: white;
}

.significante {
  background-color: transparent;
  color: #FFC000;
}

.progress.riskLevel.significante {
  background-color: #FFC000;
}

td.significante,
th.significante {
  background-color: #FFC000;
  color: white;
}

.alto {
  background-color: translarent;
  color: #FF0000;
}

.progress.riskLevel.alto {
  background-color: #FF0000;
}

td.alto,
th.alto {
  background-color: #FF0000;
  color: white;
}

.muitoAlto {
  background-color: transparent;
  color: #C00000;
}

.progress.riskLevel.muitoAlto {
  background-color: #C00000;
}

td.muitoAlto,
th.muitoAlto {
  background-color: #C00000;
  color: white;
}

.extremo {
  background-color: transparent;
  color: #800000;
}

.progress.riskLevel.extremo {
  background-color: #800000;
}

td.extremo,
th.extremo {
  background-color: #800000;
  color: white;
}

.inaceitavel {
  background-color: transparent;
  color: #660066;
}

.progress.riskLevel.inaceitavel {
  background-color: #660066;
}

td.inaceitavel,
th.inaceitavel {
  background-color: #660066;
  color: white;
}

.risk-spans {
  padding: 1px;
}

.risk-info {
  text-align: center;
  margin: 5px 0 20px;

  >div {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);
    padding: 8px 20px;
    display: inline-block;

    >p {
      font-size: 14px;
      text-align: center;
    }
  }
}

.main-layout {
  padding: 5px 15px;
}

.collapsed-card .table-responsive {
  height: 13rem;
  overflow: hidden;
}

.msg-example {
  padding-left: 8px;
  margin-left: 12px;
  border-left: 4px solid #2a2b3c;
  display: inline-block;
  cursor: default;

  span {
    font-size: 14px;
    display: block;
  }
}

.risk-slider .box:hover,
.photo-slider .box:hover {
  transition: 0.2s;
  background-color: #2e2f3a;
}

i.fa-2x {
  font-size: 2.2em !important;
}

i.fas.fa-angle-left,
i.fas.fa-angle-right,
i.fas.fa-check,
i.fas.fa-times {
  vertical-align: middle;
}


.risk-slider,
.photo-slider {
  background-color: rgba(220, 220, 220, 0.15);
  position: relative;
  padding: 6px 10px;
  scroll-padding: 10px;
  margin: 10px 0 20px;
  display: flex;
  overflow-y: auto;

  .box p {
    font-size: 1em;
  }

  .box-add {
    min-height: 6em;

    span {
      text-align: center;
      float: left;
      width: 100%;
      font-weight: 400;
      color: #bdbdbd;
    }
  }

  .box-title {
    margin: 1em auto;
    position: relative;
    text-transform: uppercase;
  }

  .box-footer {
    background-color: #222225;
    width: 100%;
    float: left;
    position: relative;
    color: white;
    text-align: center;
    padding: 0.2em;
  }

  .box {
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
    margin-right: 10px;
    width: 15em;
    height: auto;
    font-size: 13px;
    cursor: pointer;
    user-select: none;
    flex: 0 0 auto;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -12px;
      width: 12px;
      height: 1px;
    }
  }

  .box-light {
    position: relative;
    margin-right: 10px;
    width: 15em;
    height: 3.4em;
    padding: 0 4px;
    font-size: 13px;
    cursor: pointer;
    user-select: none;
    flex: 0 0 auto;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -12px;
      width: 12px;
      height: 1px;
    }
  }

  .box .icon {
    font-size: 44px;
    color: #bdbdbd;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon {
    font-size: 44px;
    color: #bdbdbd;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span#ExcluirRisk,
  span#PreviewRisk,
  span#clonarRisk {
    float: right;
    margin: 0px 5px;
  }

  .clone,
  .remove,
  .preview,
  .local,
  .edit {
    display: inline-block;
    font-size: 1.4em;
    vertical-align: middle;
    cursor: pointer;
  }

  .box-spans span {
    display: block;
  }

  .hrn {
    font-weight: bold;
    color: #41be41;

    &.hrn-level-2 {
      color: #00b0f0;
    }

    &.hrn-level-3 {
      color: #0909e0;
    }

    &.hrn-level-4 {
      color: #ffc000;
    }

    &.hrn-level-5 {
      color: #ff0000;
    }

    &.hrn-level-6 {
      color: #ca0000;
    }

    &.hrn-level-7 {
      color: #800000;
    }

    &.hrn-level-8 {
      color: #660066;
    }
  }

  i {
    font-size: 12px;
  }
}

.photo-slider {
  background-color: rgba(220, 220, 220, 0.05);
  margin: 0 0 20px;

  .box,
  .box-image {
    width: 230px;
    height: 230px;
  }

  .box-image {
    position: relative;
    border: 1px solid #000;
    margin-right: 10px;
    background-position: center center;
    background-size: cover;
    padding: 0 4px;
    user-select: none;
    flex: 0 0 auto;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -12px;
      width: 12px;
      height: 1px;
    }
  }
}

.fixed-actions {
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  left: 0;
  bottom: 10px;
  margin-bottom: 0;
  z-index: 997;

  .btn-action {
    flex: 0 0 auto;
    display: inline-block;
    color: #fff;
    position: relative;
    z-index: 60;
    padding: 0;
    border-radius: 50%;
    transition: background-color 0.3s;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    width: 56px;
    height: 56px;
    line-height: 54px;
    font-size: 20px;
  }

  .btn-status {
    cursor: initial;
    background-color: #c1392b;

    &.online {
      background-color: #27ae61;
    }
  }

  .notify-action {
    position: relative;
    background-color: #27ae61;
    color: #fff;
    text-align: center;
    width: 100%;
    height: 56px;
    line-height: 56px;
    margin: 0 8px;
    border-radius: 60px;
  }

  .btn-sync {
    background-color: #297fb8;

    &.has-sync::after {
      font-family: Arial;
      font-size: 0.7em;
      font-weight: 700;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 8px;
      line-height: 100%;
      border-radius: 100%;
      background: #c1392b;
      content: "";
    }
  }
}

footer {
  padding-top: 30px;
}

.plr .item {
  color: #fff;
  position: relative;
  z-index: 2;
  display: block;
  margin-bottom: 20px;
  padding: 16px;
  border: 1px solid #bdbdbd;
  font-size: 16px;

  h2 {
    margin: 0 0 2px 0;
    font-size: 16px;
    font-weight: normal;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  p {
    color: #ccc;
    font-size: 14px;
    margin-bottom: 2px;
  }
}

.fotos {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;

  &.left {
    justify-content: flex-start;
  }

  .quadrado {
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: inline-block;
    margin: 0 10px 0px 0px;
    white-space: normal;
    width: 230px;
    height: 230px;
    font-size: 14px;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      bottom: 0;
      background-color: #222225;
      color: white;
      width: 100%;
      text-align: center;
      padding: 3px 0;
    }

    >i {
      font-size: 105px;
      color: rgba(255, 255, 255, 0.15);
      position: absolute;
      top: calc(50% - 10px);
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .remove {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 40%;
      font-size: 0.8em;
      background: #c62828;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.quadrado:hover {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  background-color: #2e2f3a;
}

.no-width {
  width: 1%;
}

.no-wrap {
  white-space: nowrap;
}

.module-header {
  color: #fff;
  margin: 0.2em 0em 1em 0em;
  display: block;
  justify-content: space-between;
  align-items: center;
  font-size: 1.35em;

  h3 {
    font-weight: 100;
  }
}

.module-title {
  h2 {
    font-size: 1.15em;
  }

  h2,
  p {
    line-height: 28px;
  }
}

.hrn-bar {
  background: #006600;
  color: #fff;
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  float: left;
  text-align: center;
  font-weight: 400;

  h2 {
    font-size: 1.3em;
  }

  &.hrn-level-2 {
    background: #00b0f0;
  }

  &.hrn-level-3 {
    background: #0000cc;
  }

  &.hrn-level-4 {
    background: #ffc000;
    color: #000;
  }

  &.hrn-level-5 {
    background: #ff0000;
  }

  &.hrn-level-6 {
    background: #c00000;
  }

  &.hrn-level-7 {
    background: #800000;
  }

  &.hrn-level-8 {
    background: #660066;
  }

  &.category {
    background: rgba(0, 0, 0, 0.25);
  }

  span {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.question-tooltip {
  display: inline-block;
  color: #fff;
  padding: 8px;
  margin-left: 6px;
  border-radius: 100%;
  cursor: pointer;
  line-height: 0.5;
}

.table td {
  .btn+.btn {
    margin-left: 10px;
  }
}

.tac {
  text-align: center;
}

@include tablet() {
  .buttons {
    .btn {
      width: auto;
    }

    .btn+.btn {
      margin-left: 10px;
    }
  }

  .fixed-actions {
    width: auto;
    left: auto;
    right: 0;
    bottom: 15px;
    padding: 0 15px;
    flex-direction: column;
    align-items: flex-end;

    .btn-sync {
      margin-bottom: 10px;
    }

    .notify-action {
      position: absolute;
      bottom: 0;
      right: 70px;
      width: 300px;
    }
  }

  .main-layout {
    padding: 10px 40px 10px 70px;
  }

  .card .card-header {
    display: flex;
    justify-content: space-between;
  }
}

/* Added after 2020 */
.calc-description {
  ul {
    list-style: inside;
  }

  p {
    font-weight: 300;
  }
}

h6.list-title {
  font-size: 0.5em;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 0.4em;
}

th.list-machine-name {
  width: 10%;
}

div.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
  white-space: nowrap;
}

div.cut-text-mini {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
  white-space: nowrap;
}

div.cut-text-small {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  white-space: nowrap;
}

div.cut-text-medium {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
  white-space: nowrap;
}

div.cut-text-large {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 600px;
  white-space: nowrap;
}

span.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
  white-space: nowrap;
}

input.switch {
  font-size: 1.7em;
  width: 3em;
  margin-left: 0;
}

.table td,
.table th {
  border-bottom: 2px solid #616279;
  border-top: 0;
}

.table th .btn+.btn {
  margin-left: 10px;
}

.btn-group-sm>.btn,
.btn-sm {
  font-size: 1rem;
}

.btn-group-sm>.btn,
.btn-smn {
  font-size: 0.5rem;
}

.btn-group-xs>.btn,
.btn-xs {
  font-size: .7rem;
}

.app-sidebar .logo p,
.off-canvas-sidebar .logo p {
  font-size: 0.4em;
  text-align: center;
  float: initial;
  margin: 0;
  color: white;
  margin-right: 1rem;
}

.announcekit-widget {
  top: 0.1em;
  margin: 0em 0.6em;
}

.announcekit-frame-wrapper {
  margin: 1em !important;
}

.info-no-content {
  text-align: center;

  .image {
    width: 5em;
  }

  .inventory {
    padding-top: 1em;
  }
}

.percentage-completed {
  vertical-align: bottom;
}

.user-class {
  background-color: transparent;
  color: white;
  cursor: default;

  .default-class {
    background-color: grey;
    ;
  }

  .trial {
    background-color: #f68d60;
    cursor: pointer;

    &:not(.loading):hover {
      background-color: #d87449;
    }
  }

  .engenheiro {
    background-color: #4E008B;
  }

  .analista {
    background-color: #404258;
  }

  .gestor {
    background-color: #008F73;
  }

  .admin {
    background-color: red;
  }

  span {
    font-size: 0.7em;
    text-transform: uppercase;
  }
}

span.badge.badge-pill.badge-primary.normatiza {
  position: absolute;
  right: -30px;
  top: -7px;
  cursor: default;
}

#excluirDoc,
#download,
#excluirseg {
  cursor: pointer;
}

.lista {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.client-overlay {
  background-image: linear-gradient(130deg, #4c078c 25%, #6f00aa 100%);
  filter: grayscale(100%) sepia(10%);
  -webkit-filter: grayscale(100%) sepia(10%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}

.client-overlay:hover {
  background-image: linear-gradient(160deg, #4c078c 35%, #00A887 100%);
  filter: grayscale(0%) sepia(0%);
  -webkit-filter: grayscale(0%) sepia(0%);
}

.card-text.cut {
  clear: both;
  text-align: inherit;
  background-color: initial;
  border: 0;
  width: 88%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
}

.card-title.cut {
  clear: both;
  text-align: inherit;
  background-color: initial;
  border: 0;
  width: 88%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
}

.sidebar-recommended {
  position: relative;
  z-index: 4;

  img {
    width: 32px;
  }
}

.login-help {
  font-size: 80% !important;
  color: #4c078c;
  font-weight: bold;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

sub.soon {
  color: #4e008b;
  display: block;
  bottom: 0.5em;
}

.my-profile {
  background-color: #13131a;

  li {
    border-radius: 4px;
    transition: .3s cubic-bezier(.25, .8, .5, 1);

    :hover {
      background-color: #1f1f2a !important;
      border: 0 !important;
    }
  }

  a {
    padding: 4px 20px;
    background-color: inherit !important;
    color: #bdbdc7;
    border: 0 !important;
    font-weight: bold;
  }

  .active {
    background-color: #1a1a26 !important;
  }

  .disabled {
    cursor: no-drop !important;
  }
}

.menu-label {
  color: #7a7a7a;
  font-size: .75em;
  letter-spacing: .1em !important;
  text-transform: uppercase;
}

.status {
  color: inherit;

  .confirmed {
    color: #63b02d !important;
  }

  .waiting {
    color: #f57c00;
  }
}

.rank-caption {
  font-weight: 500;
  font-size: 0.8em;

  span {
    padding-left: 0.8em;
  }
}

/* Toast custom */
.Toastify__toast--success {
  background-color: #4caf50 !important;
}

.Toastify__toast-body {
  font-size: 0.9em !important;
  font-family: inherit !important;
}

/* Seletor modelo */

.select-model {
  img {
    background-color: #121317;
    width: 100%;
  }

  h5 {
    font-size: 1.3em;
    font-weight: bold;
  }

  label {
    width: 100%;
  }

  .card-input {
    margin: 10px;
    background-color: #24242a;
    color: #afafb8;
    transition: all 0.2s;
  }

  .model {
    opacity: 0.9;

    .name {
      background-color: #121317;
      width: 100%;
      text-align: center;
      display: inline-block;
    }
  }

  .model:hover {
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    opacity: 1 !important;
    transition: all 0.2s;
  }

  .card-input-element {
    display: none;
  }

  .card-input-element:checked+.card-input {
    -webkit-box-shadow: 0px 9px 18px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 9px 18px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 9px 18px -5px rgba(0, 0, 0, 0.75);
  }
}

/* FIM Seletor modelo */

@media (min-width: 54em) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 0.8rem;
    -moz-column-gap: 0.8rem;
    column-gap: 0.8rem;
    orphans: 1 !important;
    widows: 1 !important;
  }
}

@media (max-width: 54em) {

  .card h1.card-title,
  .card h2.card-title,
  .card h3.card-title,
  .card h4.card-title,
  .card h5.card-title,
  .card h6.card-title {
    font-size: 0.9em;
  }

  .customer {
    .cut {
      width: 200px;
    }
  }

  .custom-input-field {
    margin: 0 !important;
    padding-left: 0 !important;
  }

  .user-class {
    display: none;
  }

  .ptm-1 {
    padding-top: 0.375rem !important;
  }

  .ptm-2 {
    padding-top: 0.75rem !important;
  }

  .ptm-3 {
    padding-top: 1.5rem !important;
  }

  .mtm-1 {
    margin-top: 0.375rem !important;
  }

  .mtm-2 {
    margin-top: 0.75rem !important;
  }

  .mtm-3 {
    margin-top: 1.5rem !important;
  }
}