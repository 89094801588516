.login-box {
    color: rgb(91, 105, 135);
    text-transform: uppercase;
    background: rgb(255, 255, 255);
    padding: 0px 1rem;

    h5 {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.7;
    }
}

.login-page {
    background: linear-gradient(150deg, #4c078c 35%, #00A887 100%);
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.login-logo,
.login-box {
    margin-top: 1rem;
    padding-left: 20px;
    padding-right: 20px;
}

.login-logo {
    text-align: center;
    max-width: 400px;

    p {
        font-size: .8em;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        display: block;
        font-weight: 400;
    }
}

.login-divider {
    display: block;
    text-align: center;
    position: relative;
    margin-top: 1em;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        left: 0px;
        top: calc(50% - 1px);
        z-index: -1;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(229, 232, 237);
        border-image: initial;
    }

    span {
        color: rgb(91, 105, 135);
        text-transform: uppercase;
        background: rgb(255, 255, 255);
        padding: 0px 1rem;
    }

    h5 {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.7;
    }
}

.login-form {
    h2 {
        font-weight: 500;
        color: rgb(91, 105, 135);
        font-size: 1.2em;
    }
}

.login-box {
    position: relative;
    width: 92%;
    max-width: 28rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 1.8rem rgba(0, 0, 0, 0.15);
    padding: 2rem 1.5rem;
    z-index: 1;
}

.login-box-big {
    max-width: 28rem !important;
    padding: 0.5rem 0.5rem !important;
    color: #fff !important;
    text-decoration-color: #fff;
    margin-bottom:1em
}

.register-link, .backtologin-link {
    padding-top: 1em;
}

.recover-link,
.register-link,
.backtologin-link,
.login-link {
    width: 100%;
    text-align: center;
    background: transparent;
    border: none;

    span {
        margin: 0 4px;
    }

    &:hover {

        i,
        span {
            color: #000;
        }
    }

    i,
    span {
        font-size: 0.9rem;
        color: #4e008b;
        font-weight: 600;
        transition: color 0.3s ease;
        cursor: pointer;
    }
}

.btn.login-btn {
    margin: 1em 0em 1em 0em;
    width: 100%;
    font-weight: bold;
    line-height: 2rem;
    height: auto;
    text-transform: uppercase;
    background-color: rgb(255, 255, 255);
    border-color: #4e008b;
    color: #4e008b;
    border-radius: 3px;
    border-width: 3px;
    border-style: solid;
    transition: all 0.25s ease;
    padding: 1rem 2rem 1.0625rem;
    position: relative;
    font-size: 1.2em;

    &:not(.loading):hover {
        background-color: #4e008b;
        color: #fff;
    }
}

.btn.register-btn {
    margin: 1em 0em 1em 0em;
    width: 100%;
    font-weight: bold;
    line-height: 2rem;
    height: auto;
    text-transform: uppercase;
    background-color: #3ADE5A;
    border-color: #3ADE5A;
    color: #ffffff;
    border-radius: 3px;
    border-width: 3px;
    border-style: solid;
    transition: all 0.25s ease;
    padding: 0.25rem 2rem 0.25rem;
    position: relative;
    font-size: 1.2em;

    &:not(.loading):hover {
        background-color: hwb(132 16% 26%);
        border-color: hwb(132 16% 26%);
        color: #fff;
    }
}

.errormsg {
    border-left: 5px solid #fff;
    color: #fff;
    background-color: #d4473a;
    display: block;
    font-weight: 500;
    text-align:center;
    position: relative;
    padding: 0.75rem 1.25rem;
    border: 0px solid transparent;
    border-radius: 2px;
    box-shadow: 0 5px 8px -6px rgba(0,0,0,.2);
        -webkit-box-shadow: 0 5px 8px -6px rgba(0,0,0,.2);
        -moz-box-shadow: 0 5px 8px -6px rgba(0,0,0,.2);
    font-size: 0.9em;
    line-height: 1.3!important;
}

.wrong-login {
    margin-bottom: 1rem;
}

@include tablet() {
    .login-page {
        justify-content: center;
    }

    .login-box {
        padding: 2rem 3.5rem;
    }
}